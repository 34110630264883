<template>
  <div>
    <div v-for="tab in tabs" :key="tab.id">
      <small-tab-row :tab="tab" @click.native="onTabSelected(tab.id)" />
    </div>
  </div>
</template>

<script>
import SmallTabRow from '@/components/tabs/SmallTabRow.vue'

export default {
  name: 'TabList',
  emits: ['update:selectedTabId'],
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    onTabSelected(tabId) {
      this.$emit('update:selectedTabId', tabId)
    }
  },
  components: {
    SmallTabRow
  }
}
</script>

<style lang="scss" scoped></style>
