<template>
  <div class="overflow-y-scroll scrolling-touch pb-4">
    <div
      v-for="(bill, index) in bills"
      :key="bill.id"
      class="bill bg-white rounded-small mb-2 shadow-md transition border border-red relative cursor-pointer"
      :style="{ 'z-index': bills.length - index }"
      @click="selectBill(bill.id)"
      :class="{
        'shadow-none bg-gray-100 scale-down border-none':
          selectedBillId !== bill.id,
        'border-green': bill.pending === 0
      }"
    >
      <div class="flex items-center justify-between p-4">
        <div class="flex name flex-1 flex-shrink-0">
          <icon v-if="bill.pending === 0" name="done" class="mr-3 text-green" />
          <div class="text-blue font-bold" v-if="bill.number">
            <span v-if="bill.customerCompany">{{
              $t('checkout.invoice')
            }}</span>
            <span v-else>{{ $t('checkout.bill') }}</span>
            {{ bill.number }}
          </div>
          <div
            v-else-if="bill.id === currentBillId"
            class="text-blue uppercase font-bold"
          >
            {{ $t('checkout.pending-bill') }}
          </div>
          <div v-else-if="currentBillId" class="text-blue uppercase font-bold">
            {{ index }} / {{ bills.length - 1 }}
          </div>
          <div v-else class="text-blue uppercase font-bold">
            {{ index + 1 }} / {{ bills.length }}
          </div>
        </div>
        <div class="text-gray-400 flex total">
          {{ bill.total | currency }}
        </div>
        <l-dropdown v-if="bill.id !== currentBillId">
          <template slot="trigger" slot-scope="{ toggle }">
            <icon
              @click.native.stop="toggle"
              name="dots"
              class="text-gray-400 p-1 ml-2"
            />
          </template>
          <div class="option" @click="$emit('invalidate', bill.id)">
            {{ $t('checkout.delete') }}
          </div>
          <div class="option" @click="$emit('generateInvoice', bill.id)">
            {{ $t('checkout.generate-invoice') }}
          </div>
          <div
            class="option"
            v-if="bill.customerCompany"
            @click="$emit('cancelInvoice', bill.id)"
          >
            {{ $t('checkout.cancel-invoice') }}
          </div>
          <div
            v-if="bill.customerCompany"
            class="option"
            @click="showInvoiceModal(bill.id)"
          >
            {{ $t('checkout.send-invoice') }}
          </div>
        </l-dropdown>
      </div>
      <div
        class="px-4 pb-4"
        v-if="selectedBillId === bill.id || bill.pending > 0"
      >
        <div class="uppercase text-blue text-lg">
          {{ $t('checkout.payments') }}
        </div>
        <payments :bill-id="bill.id" :paid="bill.pending === 0" />
      </div>
      <div
        class="flex border-t border-gray-200 p-2"
        v-if="selectedBillId === bill.id || bill.pending > 0"
      >
        <div class="flex-1 text-center border-r border-gray-200 text-blue text">
          <div class="uppercase text-sm">{{ $t('checkout.paid') }}</div>
          <div class="font-bold paid">
            {{ bill.paid | currency }}
          </div>
        </div>
        <div
          v-if="getTip(bill) > 0"
          class="flex-1 text-center border-r border-gray-200 text-blue text"
        >
          <div class="uppercase text-sm">{{ $t('checkout.tip') }}</div>
          <div class="font-bold paid">
            {{ getTip(bill) | currency }}
          </div>
        </div>
        <div
          class="flex-1 text-center text-red"
          :class="{ 'text-green': bill.pending === 0 }"
        >
          <div class="uppercase text-sm">{{ $t('checkout.pending') }}</div>
          <div class="font-bold pending">
            {{ bill.pending | currency }}
          </div>
        </div>
      </div>
    </div>
    <l-invoice-modal
      v-if="selectedBillId && isInvoiceModalVisible"
      :bill-id="invoiceBillId"
      @close="closeInvoiceModal"
      @success="sendEmail"
    />
  </div>
</template>

<script>
import sync from '@/sync/service.js'
import Payments from './Payments.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LDropdown from '@last/core-ui/components/LDropdown.vue'
import LInvoiceModal from '@last/core-ui/components/LInvoiceModal'

export default {
  name: 'BillsList',
  props: {
    bills: {
      type: Array,
      default: () => []
    },
    selectedBillId: {
      type: String,
      default: null
    },
    currentBillId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isInvoiceModalVisible: false,
      invoiceBillId: null
    }
  },
  methods: {
    getTip(bill) {
      let asTip = bill.payments.reduce(
        (total, payment) => total + (payment.tip || 0),
        0
      )
      let asExtra =
        bill.payments.reduce(
          (total, payment) => total + (payment.amount - (payment.tip || 0)),
          0
        ) - bill.total
      return asTip + Math.max(asExtra, 0)
    },
    selectBill(id) {
      this.$emit('billSelected', id)
    },
    showInvoiceModal(billId) {
      this.invoiceBillId = billId
      this.isInvoiceModalVisible = true
    },
    closeInvoiceModal() {
      this.isInvoiceModalVisible = false
      this.invoiceBillId = null
    },
    sendEmail({ email, billId }) {
      this.closeInvoiceModal()
      sync.record('sendInvoiceEmailRequested', {
        billId,
        email,
        locale: this.$i18n.locale
      })
    }
  },
  components: {
    Payments,
    Icon,
    LDropdown,
    LInvoiceModal
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

.bill {
  width: 17.5rem;
}

.name {
  font-weight: bold;
  margin-bottom: 0.312rem;
  text-align: center;
}

.scale-down {
  transform: scale(0.95);
}

.transition {
  transition: all 0.3s;
}
</style>
