<template>
  <l-modal
    size="small"
    :title="$t('reservations.modal-title')"
    class="modal-padding"
    @close="$emit('close')"
  >
    <template slot="body">
      <h3 class="w-full text-center text-blue text-base font-medium">
        {{
          $t('reservations.modal-text-info', {
            date: dateTime
          }).toUpperCase()
        }}
      </h3>
      <div></div>
      <h3 class="w-full text-center text-blue text-base font-medium">
        {{ $t('reservations.modal-text-question').toUpperCase() }}
      </h3>
      <l-modal-ctas>
        <l-modal-button
          class="reservations__button flex-1 mr-4"
          type="secondary"
          :label="$t('reservations.cta-use-meanwhile')"
          @click.native="$emit('use')"
        />
        <l-modal-button
          class="reservations__button flex-1 text-base"
          type="main"
          :label="$t('reservations.cta-open-reservation')"
          @click.native="$emit('link')"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import moment from 'moment'
import LModal from '@last/core-ui/components/LModal.vue'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
export default {
  name: 'LinkTabWithReservationModal',
  components: {
    LModal,
    LModalCtas,
    LModalButton
  },
  props: {
    reservation: {
      type: Object,
      required: true
    }
  },
  computed: {
    dateTime() {
      return moment(this.reservation.dateTime).format('HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
.reservations__button {
  @apply text-sm;
}
</style>
