<template>
  <div>
    <div class="flex-1 text-center text-red" @click="fullAmount()">
      <div class="text-sm uppercase">{{ $t('checkout.pending') }}</div>
      <div class="text-xl font-bold pending">
        {{ pendingAmount | currency }}
      </div>
    </div>
    <div
      class="flex-1 text-center text-gray-400 border-l border-r border-gray-300"
    >
      <div class="text-sm uppercase">{{ $t('checkout.to-pay') }}</div>
      <div class="text-xl font-bold current-operation" v-if="operation.symbol">
        {{ toPay / 100 }} {{ operation.symbol }}
        {{ value }}
      </div>
      <div class="text-xl font-bold to-pay" v-else>
        {{ toPay | currency }}
      </div>
    </div>
    <div class="flex-1 text-center text-green">
      <div class="text-sm uppercase" v-if="!isTip">
        {{ $t('checkout.change') }}
      </div>
      <div class="text-sm uppercase" v-else>{{ $t('checkout.tip') }}</div>
      <div class="text-xl font-bold">{{ change | currency }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'CheckoutAmounts',
  emits: ['full-amount'],
  props: {
    pendingAmount: {
      type: Number,
      default: 0
    },
    toPay: {
      type: Number,
      default: 0
    },
    isTip: {
      type: Boolean,
      default: false
    },
    operation: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    fullAmount() {
      this.$emit('full-amount')
    }
  },
  computed: {
    change() {
      return Math.max(0, this.toPay - this.pendingAmount)
    },
    value() {
      return this.operation.filter
        ? Vue.filter(this.operation.filter, this.operation.number)
        : this.operation.number
    }
  }
}
</script>
