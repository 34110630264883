import i18n from '@/i18n.js'
import {
  DoubleTitle,
  InfoItem,
  Label,
  EmptySeparator,
  If
} from './components.js'

function generatePaperTicket(tab) {
  let kitchenOrderTitle = tab.pickupType ? tab.code : tab.name
  return [
    new Label(tab.source?.toUpperCase(), {
      align: 'center',
      style: '25px'
    }),
    new If(
      kitchenOrderTitle,
      new DoubleTitle(
        kitchenOrderTitle,
        i18n.t('tabs.cancelled').toUpperCase(),
        {
          firstStyle: 'bold 60px',
          secondStyle: '40px',
          firstLineHeight: 70,
          secondLineHeight: 45
        }
      )
    ),
    new If(!kitchenOrderTitle, new EmptySeparator()),
    new If(
      tab.pickupType,
      new InfoItem(`${i18n.t('bill.code')} ${tab.source}`, tab.name, {
        important: true,
        fontSize: 20
      })
    ),
    new Label(tab.deliveryOrder.cancelTime, {
      style: '18px',
      lineHeight: 30,
      valueType: 'date'
    })
  ]
}

function generateCancelTab(tab) {
  return generatePaperTicket(tab)
}

export default generateCancelTab
