<template>
  <div
    class="h-screen flex flex-col justify-center items-center bg-cover bg-image"
  >
    <div class="h-full overflow-y-scroll">
      <div class="flex flex-wrap px-8 sm:px-24 py-4 justify-center">
        <div
          v-for="location in locations"
          class="employee-box bg-white m-2 sm:m-5 rounded-big uppercase font-title text-blue text-xl font-bold flex justify-center items-center shadow-xl text-center p-4"
          @click="selectLocationAndContinue(location)"
          :key="location.id"
        >
          {{ location.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api.js'
import sync from '@/sync/service.js'
import { mapActions } from 'vuex'
import { Device } from '@capacitor/device'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'LocationSelector',
  data() {
    return {
      locations: []
    }
  },
  async mounted() {
    let response = await api.get('/locations')
    this.locations = response.data
    if (this.locations.length === 1) {
      this.selectLocationAndContinue(this.locations[0])
    }
  },
  methods: {
    ...mapActions('auth', ['selectLocation']),
    async selectLocationAndContinue(location) {
      let platform = Capacitor.getPlatform()
      let isExtraDevice = false
      if (platform !== 'web') {
        let headers = {
          'Location-ID': location.id
        }
        let response = await api.get('/devices', {
          headers
        })
        let deviceId = (await Device.getId()).identifier
        let alreadyRegisteredDeviceIds = response.data.ids || []
        isExtraDevice =
          !alreadyRegisteredDeviceIds.includes(deviceId) &&
          alreadyRegisteredDeviceIds.length > 0
      }
      if (isExtraDevice) {
        this.$router.push({
          name: 'extraDeviceWarning',
          params: { locationId: location.id }
        })
      } else {
        this.selectLocation(location)
        sync.resync()
        this.$router.push({ name: 'employees' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

.bg-image {
  background-image: url('../assets/background-employees.jpg');
}

.employee-box {
  width: 171px;
  min-height: 109px;
}
@media screen and (max-width: 640px) {
  .employee-box {
    width: 90%;
    min-height: 50px;
  }
}

.pin-square {
  width: 56px;
  height: 56px;
}

#numbers-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  flex-wrap: wrap-reverse;
  justify-content: center;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  width: 260px;
  height: 260px;
}

.number-button {
  @include card;
  width: 70px;
  line-height: 70px;
  text-align: center;
  margin: 4px;
  flex-grow: 1;
  &.selected {
    background: $orange;
  }
  &.double-size {
    flex-grow: 8;
  }
}

.blue {
  background: $blue;
  color: white;
}

.red {
  background: $red;
  color: white;
}

.pin-preview {
  font-size: 24px;
  display: flex;
  width: 100%;
  padding: 20px 40px;
  & > span {
    flex: 1;
    text-align: center;
  }
}
</style>
