function format(bill) {
  return {
    ...bill,
    products: mapProducts(bill)
  }
}

function mapProducts(bill) {
  let mappedProducts = []
  let shouldShowModifiers =
    !!bill.pickupType || bill.source === 'QR' || !bill.tableName
  mappedProducts = bill.products.map(product => {
    let filteredProduct = mapProduct(product, shouldShowModifiers)
    return filteredProduct
  })

  let groupedProducts = Object.values(mapToGroupedProducts(mappedProducts))
  return groupedProducts
}

function mapProduct(product, shouldShowModifiers) {
  let filteredComboProducts = (product.comboProducts || []).filter(
    comboProduct =>
      comboProduct.priceImpact > 0 ||
      comboProduct.modifiers.some(m => m.priceImpact > 0) ||
      shouldShowModifiers
  )
  let filteredModifiers = (product.modifiers || []).filter(
    modifier => modifier.priceImpact > 0 || shouldShowModifiers
  )
  return {
    ...product,
    comboProducts: filteredComboProducts,
    modifiers: filteredModifiers
  }
}

function mapToGroupedComboProducts(comboProducts) {
  return comboProducts.reduce((groupedComboProducts, comboProduct) => {
    let groupKey = comboProductKey(comboProduct)
    if (!groupedComboProducts[groupKey]) {
      groupedComboProducts[groupKey] = { ...comboProduct }
    } else {
      groupedComboProducts[groupKey].quantity += comboProduct.quantity
      groupedComboProducts[groupKey].priceImpact += comboProduct.priceImpact
    }
    return groupedComboProducts
  }, {})
}

function mapToGroupedProducts(products) {
  return products.reduce((groupedProducts, product) => {
    let groupKey = productKey(product)
    if (!groupedProducts[groupKey]) {
      groupedProducts[groupKey] = product
    } else {
      groupedProducts[groupKey].quantity += product.quantity
      groupedProducts[groupKey].originalPrice += product.originalPrice
      groupedProducts[groupKey].price += product.price
    }

    groupedProducts[groupKey].comboProducts = Object.values(
      mapToGroupedComboProducts(product.comboProducts || [])
    )
    return groupedProducts
  }, {})
}

function productKey(product) {
  return JSON.stringify({
    price: product.price,
    name: product.name,
    originalPrice: product.originalPrice,
    discountAmount: product.discountAmount,
    discountType: product.discountType,
    catalogProductId: product.catalogProductId,
    comboProducts: comboProductsKey(product.comboProducts),
    modifiers: modifiersKey(product.modifiers)
  })
}

function comboProductKey(comboProduct) {
  return JSON.stringify({
    name: comboProduct.name,
    quantity: comboProduct.quantity,
    priceImpact: comboProduct.priceImpact,
    modifiers: modifiersKey(comboProduct.modifiers)
  })
}

function comboProductsKey(comboProducts) {
  return JSON.stringify(
    (comboProducts || [])
      .map(cp => {
        return {
          name: cp.name,
          quantity: cp.quantity,
          priceImpact: cp.priceImpact,
          modifiers: modifiersKey(cp.modifiers)
        }
      })
      .sort((c1, c2) => c1.name < c2.name)
  )
}

function modifiersKey(modifiers) {
  return JSON.stringify(
    (modifiers || [])
      .map(m => {
        return {
          name: m.name,
          quantity: m.quantity,
          priceImpact: m.priceImpact
        }
      })
      .sort((m1, m2) => m1.name < m2.name)
  )
}

export default {
  format
}
