<template>
  <div>
    <div class="mb-4 text-white uppercase text-center mx-4">
      <div class="flex flex-row mb-3">
        <div
          class="border-blue bg-blue py-3 flex-1 rounded-small mr-3 flex flex-row justify-center items-center"
          :class="{
            'opacity-50': !pendingOrders
          }"
          @click="handleKitchenOrder()"
        >
          <div class="mr-2">
            <icon name="kitchen" />
          </div>
          {{ $t('tabs.order') }}
        </div>
        <div
          class="border-blue bg-blue py-3 flex-1 rounded-small flex flex-row justify-center items-center"
          :class="{
            'opacity-50': !allowedToPrintBill
          }"
          @click="handleBill()"
        >
          <div class="mr-2">
            <icon name="ticket" />
          </div>
          {{ $t('tabs.bill') }}
        </div>
      </div>
      <div class="flex flex-row">
        <div
          class="border-green-b bg-green py-3 flex-1 rounded-small mr-3"
          @click="goToCheckout()"
        >
          {{$t('tabs.checkout')}}
        </div>
        <div
          class="border-light-blue-b bg-light-blue py-3 flex-1 rounded-small"
          @click="goToBillsList()"
        >
          {{$t('tabs.payments')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kitchenOrdersMixin from '@/components/ordering/kitchenOrdersMixin.js'
import billsMixin from '@/components/ordering/billsMixin.js'
import Icon from '@last/core-ui/components/Icon'

export default {
  name: 'TabActions',
  mixins: [kitchenOrdersMixin, billsMixin],
  props: {
    tabId: {
      type: String,
      default: ''
    }
  },
  components: {
    Icon
  },
  methods: {
    handleKitchenOrder() {
      if (!this.pendingOrders) return
      this.sendKitchenOrder('all')
      this.$router.push({ name: 'pos' })
    },
    handleBill() {
      if (!this.allowedToPrintBill) return
      this.printBill()
      this.$router.push({ name: 'pos' })
    },
    goToCheckout() {
      this.$router.push({
        name: 'checkout',
        params: { tabId: this.tabId }
      })
    },
    goToBillsList() {
      this.$router.push({
        name: 'billsList',
        params: { tabId: this.tabId }
      })
    }
  }
}
</script>

<style scoped>
.center-floatting {
  left: 50%;
  transform: translateX(-50%);
}
</style>
