<template>
  <div class="overflow-y-scroll scrolling-touch flex flex-col h-screen">
    <top-bar show-back>
      <template slot="center">
        <div class="flex items-center text-xl">
          <div class="flex">
            <div v-if="tab.code" class="tab-code whitespace-no-wrap">
              <span class="font-bold">{{ tab.code }}</span>
              <span v-if="tab.tableName"> / {{ tab.tableName }}</span>
              <span v-else-if="tab.name"> / {{ tab.name }}</span>
            </div>
            <div v-else-if="tab.tableName">{{ tab.tableName }}</div>
            <div v-else>{{ tab.name }}</div>
            <div v-if="tab.activationTime" class="ml-4 text-gray-400">
              {{ tab.activationTime | time }}
            </div>
          </div>
        </div>
      </template>
    </top-bar>
    <body class="flex flex-col items-center mt-4 overflow-y-scroll flex-1">
      <div
        v-for="(bill, index) in bills"
        :key="bill.id"
        class="bill rounded-small mb-2 border transition relative cursor-pointer"
        @click="selectBill(bill.id)"
        :class="{
          'border-red bg-white':
            selectedBillId === bill.id,
          'bg-gray-200 border-gray-200 scale-down':
            selectedBillId !== bill.id,
          'border-green': bill.pending === 0
        }"
      >
        <div class="flex items-center justify-between p-4">
          <div class="flex name flex-1 flex-shrink-0">
            <icon v-if="bill.pending === 0" name="done" class="mr-3 text-green" />
            <div class="text-blue font-bold" v-if="bill.number">
              <span v-if="bill.customerCompany">{{
                $t('checkout.invoice')
              }}</span>
              <span v-else>{{ $t('checkout.bill') }}</span>
              {{ bill.number }}
            </div>
            <div
              v-else-if="bill.id === currentBillId"
              class="text-blue uppercase font-bold"
            >
              {{ $t('checkout.pending-bill') }}
            </div>
            <div v-else-if="currentBillId" class="text-blue uppercase font-bold">
              {{ index }} / {{ bills.length - 1 }}
            </div>
            <div v-else class="text-blue uppercase font-bold">
              {{ index + 1 }} / {{ bills.length }}
            </div>
          </div>
          <div class="text-gray-400 flex total">
            {{ bill.total | currency }}
          </div>
          <icon v-if="bill.id !== currentBillId" name="trash" class="text-red ml-2" @click="() => invalidateBill(bill.id)"/>
        </div>
        <div
          class="px-4 pb-4"
          v-if="selectedBillId === bill.id || bill.pending > 0"
        >
          <div class="uppercase text-blue text-lg">
            {{ $t('checkout.payments') }}
          </div>
          <payments :bill-id="bill.id" :paid="bill.pending === 0" />
        </div>
        <div
          class="flex border-t border-gray-200 p-2"
          v-if="selectedBillId === bill.id || bill.pending > 0"
        >
          <div class="flex-1 text-center border-r border-gray-200 text-blue text">
            <div class="uppercase text-sm">{{ $t('checkout.paid') }}</div>
            <div class="font-bold paid">
              {{ bill.paid | currency }}
            </div>
          </div>
          <div
            v-if="getTip(bill) > 0"
            class="flex-1 text-center border-r border-gray-200 text-blue text"
          >
            <div class="uppercase text-sm">{{ $t('checkout.tip') }}</div>
            <div class="font-bold paid">
              {{ getTip(bill) | currency }}
            </div>
          </div>
          <div
            class="flex-1 text-center text-red"
            :class="{ 'text-green': bill.pending === 0 }"
          >
            <div class="uppercase text-sm">{{ $t('checkout.pending') }}</div>
            <div class="font-bold pending">
              {{ bill.pending | currency }}
            </div>
          </div>
        </div>
      </div>
    </body>
    <footer class="p-5">
      <l-button class="bg-accent w-full flex items-center justify-center" @click="goToCheckout" :disabled="!selectedBillIsPending">
        <icon name="till" class="mr-2" />
        <p> {{  $t('checkout.charge') }} </p>
      </l-button>
      
    </footer>
  </div>
</template>

<script>
/* eslint-disable vue/no-deprecated-filter */
import TopBar from '@/mobile/components/TopBar.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LButton from '@last/core-ui/components/LButton.vue'
import Payments from '@/components/checkout/Payments.vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import Bills from '@last/core/src/billsGenerator'

export default {
  name: 'BillsList',
  mounted() {
    this.preselectBill()
  },
  data() {
    return {
      isInvoiceModalVisible: false,
      invoiceBillId: null,
      selectedBillId: null
    }
  },
  methods: {
    ...mapActions('tabs', ['removeBill']),
    getTip(bill) {
      let asTip = bill.payments.reduce(
        (total, payment) => total + (payment.tip || 0),
        0
      )
      let asExtra =
        bill.payments.reduce(
          (total, payment) => total + (payment.amount - (payment.tip || 0)),
          0
        ) - bill.total
      return asTip + Math.max(asExtra, 0)
    },
    selectBill(billId) {
      if (this.selectedBillId !== billId) {
        this.selectedBillId = billId
      }
    },
    invalidateBill(billId) {
      this.removeBill({ tabId: this.tabId, billId })
      this.preselectBill()
    },
    preselectBill() {
      if (this.currentBill) {
      this.selectedBillId = this.currentBill.id
      } else if (this.bills && this.bills.length > 0) {
        this.selectedBillId = this.bills[0].id
      }
    },
    goToCheckout() {
      const billToCheckout = this.selectedBillId === this.currentBill?.id ? null : this.selectedBillId
      this.$router.push({
        name: 'checkout',
        params: {
          tabId: this.tabId
        },
        query: billToCheckout && {
          billId: billToCheckout
        }
      })
    }
  },
  computed: {
    ...mapGetters('billing', ['getCurrentBill']),
    ...mapGetters('tabs', ['getBills', 'getAllProducts']),
    ...mapGetters('promotions', ['getTabGlobalPromotion']),
    ...mapState('tabs', ['tabs']),
    tabId() {
      return this.$route.params.tabId
    },
    tab() {
      return this.tabs[this.tabId]
    },
    bills() {
      let existingBills = this.getBills(this.tabId)
      if (this.checkoutComplete) {
        return existingBills
      } else {
        let currentBillWithPaymentInfo = Bills.addPaymentInfo(
          this.currentBill,
          []
        )
        return [currentBillWithPaymentInfo, ...existingBills]
      }
    },
    currentBill() {
      if (this.checkoutComplete) return null
      return this.getCurrentBill({
        tabId: this.tabId,
        currentDiscount: this.currentDiscount
      })
    },
    checkoutComplete() {
      let tabProducts = this.getAllProducts(this.tabId)
      return (
        tabProducts
          .map(product => product.notBilledQuantity)
          .reduce((total, quantity) => total + quantity, 0) === 0
      )
    },
    currentBillId() {
      return this.currentBill?.id
    },
    selectedBillIsPending() {
      return this.bills.find(bill => bill.id === this.selectedBillId)?.pending > 0
    },
    tabGlobalPromotion() {
      return this.getTabGlobalPromotion(this.tabId)
    },
    currentDiscount() {
      if (!this.tabGlobalPromotion) return null
      return {
        type: this.tabGlobalPromotion.discountType,
        amount: this.tabGlobalPromotion.discountAmount,
        promotionId: this.tabGlobalPromotion.promotionId,
        concept: this.tabGlobalPromotion.concept
      }
    }
  },
  components: {
    Icon,
    Payments,
    TopBar,
    LButton
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

.bill {
  width: 17.5rem;
}

.name {
  font-weight: bold;
  margin-bottom: 0.312rem;
  text-align: center;
}

.scale-down {
  transform: scale(0.95);
}

.transition {
  transition: all 0.3s;
}
</style>
