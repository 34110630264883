import Vue from 'vue'
import store from './store/index.js'
import i18n from './i18n'
import UpdateModal from '@/components/core/UpdateModal'
import { Howl } from 'howler'
import { getSerial } from '@/SerialPlugin.js'
import { Device } from '@capacitor/device'

import { Capacitor } from '@capacitor/core'
import { getUpdater } from '@/updater'

class App {
  constructor() {
    this.platform = Capacitor.getPlatform()

    this.updater = getUpdater(this.platform)
    this.updater.notifyAppReady()
    this.updater.on('updateReady', ({ deprecated }) => {
      this.mountUpdateModalComponent({ mandatory: deprecated })
    })

    this.componentMounted = false
    this.isMobile = window.innerWidth <= 640

    try {
      this.ipcRenderer = window.require('electron').ipcRenderer
      this.isElectron = true
    } catch (e) {
      this.isElectron = false
    }
    this.initDeviceSerial()
  }

  async getDeviceInfo() {
    const deviceInfo = await Device.getInfo()
    const version = await this.updater.getVersion()
    const deviceId = await Device.getId()

    return {
      id: deviceId.identifier,
      platform: this.platform,
      model: deviceInfo.model,
      os: deviceInfo.operatingSystem,
      osVersion: deviceInfo.osVersion,
      appVersion: version.name,
      appBuild: version.build
    }
  }

  async checkForUpdates(channel) {
    const device = await this.getDeviceInfo()
    this.updater.checkUpdates({ device, channel })
  }

  async initDeviceSerial() {
    try {
      const info = await Device.getInfo()
      if (info.model === 'Saturn1000F2') {
        this.isDataphone = true
        this.serial = await getSerial()
      }
    } catch (e) {
      this.serial = null
    }
  }

  quit() {
    this.ipcRenderer.invoke('window:close')
  }

  minimize() {
    this.ipcRenderer.invoke('window:minimize')
  }

  mountUpdateModalComponent(propsData) {
    if (this.componentMounted) return
    this.componentMounted = true
    let Component = Vue.extend(UpdateModal)
    var updateModal = new Component({ i18n, propsData }).$mount()
    Vue.nextTick(() =>
      document.getElementById('app').appendChild(updateModal.$el)
    )
    updateModal.$on('close', () => {
      this.closeUpdateModalComponent(updateModal)
    })
    updateModal.$on('confirm', () => {
      this.confirmUpdate(updateModal)
    })
  }

  closeUpdateModalComponent(updateModal) {
    this.componentMounted = false
    updateModal.$destroy()
    updateModal.$el.parentNode.removeChild(updateModal.$el)
  }

  confirmUpdate() {
    this.updater.installUpdate()
  }

  playSound(name) {
    if (!store.state.config.mute) {
      let sound = new Howl({
        src: [`${name}.mp3`]
      })
      sound.play()
    }
  }
}

export default new App()
