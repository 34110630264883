<template>
  <l-modal
    :title="$t('update-modal.title')"
    size="small"
    @close="$emit('close')"
    :hidden-close="mandatory"
    :disable-click-outside="mandatory"
  >
    <template #body>
      <l-modal-section-title
        :title="subtitle"
        class="text-center uppercase update-modal__title"
      />

      <l-modal-ctas>
        <l-modal-button
          class="flex-1"
          type="main"
          :label="$t('update-modal.update-cta')"
          @click.native="$emit('confirm')"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal'
import LModalSectionTitle from '@last/core-ui/components/LModalSectionTitle'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
export default {
  name: 'UpdateModal',
  components: {
    LModal,
    LModalSectionTitle,
    LModalCtas,
    LModalButton
  },
  props: {
    mandatory: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    subtitle() {
      return this.mandatory
        ? this.$t('update-modal.subtitle-mandatory')
        : this.$t('update-modal.subtitle-optional')
    }
  }
}
</script>

<style lang="scss" scoped>
.update-modal {
  &__title {
    margin-bottom: 0 !important;
  }
}
</style>
