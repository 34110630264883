<template>
  <l-modal
    :title="$t('ordering.move-products-modal-title')"
    size="small"
    @close="$emit('close')"
  >
    <template slot="body">
      <l-modal-section-title
        :title="$t('ordering.move-products-modal-description')"
        class="text-center text-lg uppercase"
      />

      <div class="flex justify-center items-center">
        <div class="flex flex-1 flex-col justify-center items-center relative">
          <span class="text-xs text-gray-400 mb-1">{{
            $t('ordering.from')
          }}</span>
          <div class="w-full move-products__table">
            <div class="text-center text-xs line-height">
              {{ ellapsedTime(getLastInteraction(fromTab.id)) }}
            </div>
            <div
              class="text-lg font-bold text-center uppercase font-title line-height mx-2"
            >
              {{ name(fromTab) }}
            </div>
            <div
              class="flex items-center justify-center text-xs"
              v-if="seats(fromTab) > 1"
            >
              <icon name="diners" class="p-1 opacity-50 diners-size" />
              {{ seats(fromTab) }}
            </div>
          </div>
        </div>
        <icon name="forward" class="mx-4 mt-6 w-10 y-10" />
        <div class="flex flex-1 flex-col justify-center items-center relative">
          <span class="text-xs text-gray-400 mb-1">{{
            $t('ordering.to')
          }}</span>
          <div class="w-full move-products__table">
            <div v-if="toTab" class="text-center text-xs line-height">
              {{ ellapsedTime(getLastInteraction(toTab.id)) }}
            </div>
            <div
              class="text-lg font-bold text-center uppercase font-title line-height mx-2"
            >
              {{ name(toTab) }}
            </div>
            <div
              class="flex items-center justify-center text-xs"
              v-if="seats(toTab) > 1"
            >
              <icon name="diners" class="p-1 opacity-50 diners-size" />
              {{ seats(toTab) }}
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-center items-start mt-6 relative">
        <span class="text-xs text-gray-400 mb-1 w-full">{{
          $t('ordering.products')
        }}</span>
        <div class="flex flex-col w-full">
          <div
            v-for="product in products"
            :key="product.id"
            class="bg-gray-100 flex mt-1 py-2 px-4 flex-1 items-center"
          >
            <div
              class="flex justify-center items-center rounded-full w-6 h-6 bg-gray-200 text-red mr-4"
            >
              {{ product.quantity }}
            </div>
            <div class="flex flex-col">
              <div>
                {{ product.name }}
              </div>
              <div class="text-xs text-gray-400">
                {{ description(product) || '-' }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <l-modal-ctas>
        <l-modal-button
          type="main"
          :label="$t('ctas.move')"
          @click.native="$emit('confirm')"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import Icon from '@last/core-ui/components/Icon'
import LModal from '@last/core-ui/components/LModal'
import LModalSectionTitle from '@last/core-ui/components/LModalSectionTitle'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'

export default {
  components: {
    LModal,
    LModalSectionTitle,
    Icon,
    LModalCtas,
    LModalButton
  },
  props: {
    fromTab: {
      type: Object,
      required: true
    },
    toTable: {
      type: Object,
      required: true
    },
    toTab: {
      type: Object
    },
    products: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('tabs', ['getLastInteraction']),
    ...mapState('tabs', ['tabs']),
    ...mapGetters('reservations', ['getReservation'])
  },
  methods: {
    ellapsedTime(date) {
      if (date) {
        var now = moment()
        var start = moment(date)
        var duration = moment.duration(Math.max(now.diff(start), 0))
        return (
          Math.floor(duration.asHours()) +
          moment.utc(duration.asMilliseconds()).format(':mm')
        )
      }
    },
    description(product) {
      return (product.comboProducts || product.modifiers || [])
        .map(modifier => {
          let quantity = modifier.quantity > 1 ? ` x ${modifier.quantity}` : ''
          return modifier.name + quantity
        })
        .join(', ')
    },
    name(tab) {
      if (tab && tab.lang) {
        return `${tab.lang} - ${tab.name}`
      }
      return tab?.name || 'tab'
    },
    seats(tab) {
      if (tab) {
        return tab.seats.length
      }
      return 0
    }
  }
}
</script>
<style lang="scss" scoped>
.move-products {
  &__table {
    @apply bg-light-blue text-white flex justify-around items-center py-2 px-5 rounded-small;
  }
}
</style>
