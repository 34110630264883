<template>
  <div>
    <product-editor
      v-if="editingProduct"
      :product="editingProduct"
      :catalog-id="catalogId"
      @saveProduct="saveEditedProduct"
      @close="editingProduct = null"
      show-course
    />
    <div
      v-else
      class="absolute top-0 left-0 h-screen w-screen bg-blur flex justify-center items-center z-30"
    >
      <div>
        <div class="flex justify-between">
          <div class="flex">
            <div
              class="text-white uppercase text-size font-bold pb-3 px-3 font-title"
            >
              {{ catalogCombo.name }}
            </div>
            <div class="text-gray-300 uppercase text-size pb-3 font-title">
              {{ `x${savedCombos.length}` }}
            </div>
          </div>
          <div class="flex items-center">
            <div
              @click="addCombo"
              class="text-white text-lg font-bold p-1 rounded-full bg-green mr-4"
            >
              <icon name="plus" small />
            </div>
          </div>
        </div>
        <div class="flex flex-row dialog-w">
          <div
            class="whitespace-pre-line bg-white rounded-l-big border-gray-200 border-r text-color relative w-2/3 p-12"
            @click.stop
          >
            <div class="flex py-1">
              <div
                v-horizontal-scroll
                class="w-full flex flex-row overflow-scroll scrolling-touch"
              >
                <l-pill
                  v-for="(category, index) in catalogCombo.categories"
                  @input="selectCategory(index)"
                  :key="category.id"
                  :label="category.name"
                  :value="selectedCategoryIndex === index"
                  class="mr-4"
                />
              </div>
            </div>
            <div
              v-if="categoryMin(selectedCategoryIndex) > 0"
              class="text-gray-400 mb-2"
            >
              {{
                $t('combo-editor.select-min-max-products', {
                  min: categoryMin(selectedCategoryIndex),
                  max: categoryMax(selectedCategoryIndex)
                })
              }}
            </div>
            <div
              v-else
              class="text-gray-400 mb-2"
            >
              {{
                $t('combo-editor.select-max-of-products', {
                  max: categoryMax(selectedCategoryIndex)
                })
              }}
            </div>
            <div
              class="flex flex-wrap overflow-y-scroll scrolling-touch max-product-h -mx-2 content-start"
            >
              <menu-product
                v-for="product in filteredProducts"
                :key="product.id"
                :product="product"
                :class="{
                  'opacity-50': categoryIsMaxed(
                    selectedComboIndex,
                    selectedCategoryIndex
                  )
                }"
                @selected="saveProduct(product)"
                component-style="row"
                :selected-quantity="
                  productTotalSelected(selectedComboIndex, product.id)
                "
              />
            </div>
            <div
              v-if="manualInterventionIds.length > 0"
              class="text-gray-400 mt-8 -mb-5"
            >
              {{ $t('combo-editor.products-with-required-modifiers-error') }}
            </div>
          </div>
          <div
            class="w-1/3 bg-white border-transparent-light-gray rounded-r-big whitespace-pre-line shadow py-12"
          >
            <div
              v-if="savedCombos.length > 1"
              class="overflow-y-scroll scrolling-touch max-cart-h"
              :ref="'combo-wrapper'"
            >
              <l-section-side-menu
                v-for="(combo, comboIndex) in savedCombos"
                :key="comboIndex"
                :section-name="
                  $t('combo-editor.combo') + ' ' + (comboIndex + 1)
                "
                :section-selected="selectedComboIndex === comboIndex"
                :elements="combo.categories"
                :show-remove="showRemoveButton(comboIndex)"
                :ref="`${comboIndex}-anchor`"
                @selectedSection="selectComboIndex(comboIndex)"
                @onRemove="event => onRemove(comboIndex)"
              >
                <div
                  class="mx-4"
                  v-for="(category, index) in catalogCombo.categories"
                  :key="category.id"
                >
                  <template
                    v-if="
                      categorySelectedProductsQuantity(comboIndex, index) > 0
                    "
                  >
                    <div class="flex flex-row">
                      <div class="text-blue text-xs uppercase mt-3">
                        {{ category.name }}
                      </div>
                    </div>
                    <combo-product
                      v-for="product in categorySelectedProducts(
                        comboIndex,
                        index
                      )"
                      :key="product.id"
                      :product="product"
                      @delete="deleteProduct(comboIndex, product.id)"
                      @updateQuantity="
                        newQuantity =>
                          updateProductQuantity(
                            product.id,
                            newQuantity,
                            comboIndex,
                            category.id
                          )
                      "
                      class="mt-3"
                      :quantity-is-maxed="categoryIsMaxed(comboIndex, index)"
                      @customClick="editProduct(comboIndex, product)"
                    />
                    <div class="separator bg-gray-200 -mx- mt-3" />
                  </template>
                </div>
              </l-section-side-menu>
            </div>
            <div
              v-else
              class="overflow-y-scroll scrolling-touch max-cart-h px-6"
            >
              <div class="text-blue uppercase font-title font-bold text-xl">
                {{ $t('combo-editor.combo') }}
              </div>
              <div
                v-for="(category, index) in catalogCombo.categories"
                :key="category.id"
              >
                <div class="flex flex-row">
                  <div class="text-blue text-xs uppercase mt-3">
                    {{ category.name }}
                  </div>
                </div>
                <combo-product
                  v-for="product in categorySelectedProducts(0, index)"
                  :key="product.id"
                  :product="product"
                  @delete="deleteProduct(0, product.id)"
                  @updateQuantity="
                    newQuantity =>
                      updateProductQuantity(
                        product.id,
                        newQuantity,
                        0,
                        category.id
                      )
                  "
                  class="mt-3"
                  :quantity-is-maxed="categoryIsMaxed(0, index)"
                  @customClick="editProduct(0, product)"
                />
                <div class="separator bg-gray-200 -mx- mt-3" />
              </div>
            </div>
          </div>
        </div>

        <div class="flex pt-4 justify-end">
          <div
            class="p-3 text-white bg-blue border border-blue-600 uppercase rounded-small font-bold text-center px-16"
            @click="close()"
          >
            {{ $t('combo-editor.cancel') }}
          </div>
          <div
            class="p-3 text-white bg-red border border-red-b uppercase rounded-small font-bold ml-4 text-center px-16"
            :class="{
              'opacity-50 pointer-events-none': !allCategoriesReachesMin(
                selectedComboIndex
              )
            }"
            @click="saveCombo()"
          >
            {{ $t('combo-editor.accept') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuProduct from '@/components/ordering/MenuProduct.vue'
import ComboProduct from '@/components/ordering/ComboProduct.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import ProductEditor from './ProductEditor.vue'
import comboEditorMixin from '@/components/ordering/comboEditorMixin.js'
import uuid4 from 'uuid/v4'
import { mapState } from 'vuex'
import LPill from '@last/core-ui/components/LPill.vue'
import LSectionSideMenu from '@last/core-ui/components/LSectionSideMenu'
import Vue from 'vue'

export default {
  name: 'ComboEditor',
  mixins: [comboEditorMixin],
  props: {
    catalogCombo: {
      type: Object,
      default: null
    },
    editingCombo: {
      type: Object,
      default: null
    },
    comboProduct: {
      type: Object,
      default: null
    },
    catalogId: {
      type: String,
      default: null
    },
    tabId: {
      type: String,
      default: null
    },
    seat: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selectedCategoryIndex: 0,
      selectedComboIndex: 0,
      editingProduct: null,
      selectedProducts: {},
      savedCatalogCombo: null,
      savedEditingCombo: null,
      manualInterventionIds: [],
      savedCombos: []
    }
  },
  mounted() {
    this.savedCatalogCombo = this.catalogCombo
    this.savedEditingCombo = this.editingCombo
    if (this.editingCombo) {
      this.savedCombos = [{ ...this.editingCombo }]
    } else {
      this.savedCombos = [{ ...this.catalogCombo }]
    }

    if (this.editingCombo) {
      this.preselectSelectedProducts()
    } else {
      this.preselectSelectedByDefault()
    }
  },
  computed: {
    ...mapState('tabs', ['tabs'])
  },
  methods: {
    close() {
      this.$emit('close')
    },
    editProduct(comboIndex, product) {
      this.selectedComboIndex = comboIndex
      this.editingProduct = product
    },
    addCombo() {
      this.savedCombos.push({ ...this.catalogCombo })
      this.selectedComboIndex = 0
      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      )
        this.selectedComboIndex++
      this.goTo(this.selectedComboIndex)
      this.preselectSelectedByDefault()
    },
    showRemoveButton(comboIndex) {
      return (
        !this.selectedProducts[comboIndex] ||
        Object.keys(this.selectedProducts[comboIndex]).length == 0
      )
    },
    selectCategory(category) {
      this.selectedCategoryIndex = category
      this.selectedComboIndex = 0
      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(this.selectedComboIndex, category)
      )
        this.selectedComboIndex++
      this.goTo(this.selectedComboIndex)
    },
    saveEditedProduct(product) {
      this.selectedProducts = {
        ...this.selectedProducts,
        [this.selectedComboIndex]: {
          ...this.selectedProducts[this.selectedComboIndex],
          [product.id]: product
        }
      }
      this.editingProduct = null
      let id = this.manualInterventionIds.findIndex(id => product.id === id)
      this.manualInterventionIds.splice(id, 1)

      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      )
        this.selectedComboIndex++
      if (
        this.selectedComboIndex == this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        ) &&
        this.selectedCategoryIndex !=
          this.savedCatalogCombo.categories.length - 1
      ) {
        this.selectCategory(this.selectedCategoryIndex + 1)
      }
      this.goTo(this.selectedComboIndex)
    },
    saveProduct(product) {
      if (
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      ) {
        return
      }

      let comboProduct = {
        id: uuid4(),
        name: product.name,
        parentProduct: product.id,
        priceImpact: product.priceImpact,
        modifiers: product.modifiers,
        modifierGroups: product.modifierGroups,
        comments: product.comments,
        quantity: 1,
        course: product.course || 'Main',
        categoryId: this.getCategory(this.selectedCategoryIndex).id
      }

      let mandatory = product.modifierGroups.some(group => group.min > 0)
      if (mandatory) {
        this.editingProduct = comboProduct
        return
      } else {
        this.editingProduct = null
      }

      if (!this.selectedProducts[this.selectedComboIndex]) {
        this.selectedProducts[this.selectedComboIndex] = {}
      }

      this.selectedProducts = {
        ...this.selectedProducts,
        [this.selectedComboIndex]: {
          ...this.selectedProducts[this.selectedComboIndex],
          [comboProduct.id]: comboProduct
        }
      }

      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      )
        this.selectedComboIndex++

      if (
        this.selectedComboIndex == this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        ) &&
        this.selectedCategoryIndex !=
          this.savedCatalogCombo.categories.length - 1
      ) {
        this.selectCategory(this.selectedCategoryIndex + 1)
      }
      this.goTo(this.selectedComboIndex)
    },
    goTo(comboIndex) {
      this.$nextTick(() => {
        let scrollWrapper = this.$refs['combo-wrapper']
        if (!scrollWrapper) return
        this.scrollingToCategoryId = comboIndex
        let target = this.$refs[`${comboIndex}-anchor`][0].$el
        scrollWrapper.scrollTo({
          behavior: 'smooth',
          top: target.offsetTop - scrollWrapper.offsetTop
        })
      })
    },
    selectComboIndex(comboIndex) {
      this.selectedComboIndex = comboIndex
      this.goTo(comboIndex)
    },
    onRemove(comboIndex) {
      if (this.savedCombos.length == 1) this.close()

      for (let i = comboIndex; i < this.savedCombos.length - 1; i++) {
        this.selectedProducts[i] = {
          ...this.selectedProducts[i + 1]
        }
      }

      delete this.selectedProducts[this.savedCombos.length - 1]

      Vue.delete(this.savedCombos, comboIndex)

      this.selectedComboIndex = 0
      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      )
        this.selectedComboIndex++
      this.goTo(this.selectedComboIndex)
    },
    preselectProduct(product) {
      const comboIndex = this.savedCombos.length - 1
      let mandatory = product.modifierGroups.some(group => group.min > 0)
      if (mandatory) {
        this.manualInterventionIds.push(product.id)
        return
      }
      let comboProduct = {
        id: uuid4(),
        name: product.name,
        parentProduct: product.id,
        priceImpact: product.priceImpact,
        modifiers: product.modifiers,
        modifierGroups: product.modifierGroups,
        comments: product.comments,
        quantity: 1,
        course: product.course || 'Main',
        categoryId: product.categoryId
      }

      this.selectedProducts = {
        ...this.selectedProducts,
        [comboIndex]: {
          ...this.selectedProducts[comboIndex],
          [comboProduct.id]: comboProduct
        }
      }
    },
    preselectSelectedByDefault() {
      this.catalogCombo.categories
        .filter(category => category.selectedByDefault)
        .flatMap(category => category.products)
        .forEach(categoryProduct => this.preselectProduct(categoryProduct))
    }
  },
  components: {
    Icon,
    MenuProduct,
    ProductEditor,
    ComboProduct,
    LSectionSideMenu,
    LPill
  }
}
</script>

<style>
.text-size {
  font-size: 26px;
}

.dialog-w {
  width: 59.625rem;
}

.category-w {
  width: 16.875rem;
}

.max-product-h {
  height: 20rem;
}

.max-cart-h {
  height: 26.25rem;
}

.separator {
  height: 1px;
}
</style>
