<template>
  <div id="open-tab" class="flex flex-col h-full bg-white shadow-md" v-if="tab">
    <l-row-selector
      v-if="tabCourses && tabCourses.length > 1"
      class="m-4"
      :options="listOptions"
      :option-selected="listSelected"
      @onSelect="setListSelected"
    />
    <div
      class="overflow-y-scroll scrolling-touch product-list"
      id="scroller"
      v-dragscroll="!disableDragscroll && isElectron"
      @dragscrollstart="startDragscroll"
      @dragscrollend="endDragscroll"
    >
      <div v-if="listSelected === 'seats'">
        <l-section-side-menu
          :section-name="$t('ordering.shared')"
          :section-selected="selectedSeat === null"
          :elements="tabSharedProducts"
          @draggableStart="startDraggable"
          @draggableEnd="endDraggable"
          @selectedSection="$emit('update:selectedSeat', null)"
          @onAdd="event => onAdd(null, event)"
          @onUpdate="event => onUpdate(null, event)"
          @scroll="scrollToElement"
        >
          <tab-product
            :id="product.id"
            class="tab-product"
            v-for="product in tabSharedProducts"
            :key="product.id"
            :product="product"
            :disabled-by-parent="disableSwipe"
            :is-delivery="isDelivery"
            @selected="selectProduct(product, null)"
          />
        </l-section-side-menu>
        <template v-for="(seat, index) in tabSeats">
          <l-section-side-menu
            :key="index"
            :section-name="`${$t('ordering.seat')} ${index + 1}`"
            :section-selected="selectedSeat === index"
            :elements="seat"
            @draggableStart="startDraggable"
            @draggableEnd="endDraggable"
            :show-remove="showRemoveButton(seat)"
            @selectedSection="$emit('update:selectedSeat', index)"
            @onAdd="event => onAdd(index, event)"
            @onUpdate="event => onUpdate(index, event)"
            @onRemove="event => onRemove(index)"
            @scroll="scrollToElement"
          >
            <tab-product
              :id="product.id"
              class="tab-product"
              v-for="product in seat"
              :key="product.id"
              :product="product"
              :disabled-by-parent="disableSwipe"
              :is-delivery="isDelivery"
              @selected="selectProduct(product, null)"
            />
          </l-section-side-menu>
        </template>
        <div
          class="p-3 m-5 text-center text-gray-400 uppercase bg-gray-100 border border-gray-200 rounded-small add-seat"
          @click="addSeat(tabId)"
        >
          + {{ $t('ordering.add-seat') }}
        </div>
      </div>
      <div v-else>
        <template v-for="(course, index) in tabCourses">
          <l-section-side-menu
            :section-name="course.name"
            :section-selected="selectedCourse === course.name"
            :key="index"
            :elements="course.products"
            @draggableStart="startDraggable"
            @draggableEnd="endDraggable"
            @onAdd="event => onAddCourse(course.name, event)"
            @onUpdate="event => onUpdateCourse(course.name, event)"
            @selectedSection="updateSelectedCourse(course.name)"
            @scroll="scrollToElement"
          >
            <tab-product
              v-for="product in course.products"
              :key="getProductId(product)"
              :id="getProductId(product)"
              :combo-id="product.comboProducts ? product.id : null"
              class="tab-product"
              :product="{
                ...product,
                comboProducts: product.shownCombo
                  ? [product.shownCombo]
                  : product.comboProducts
              }"
              :disabled-by-parent="disableSwipe"
              :is-delivery="isDelivery"
              @selected="selectProduct(product, null, course.name)"
            />
          </l-section-side-menu>
        </template>
        <div class="h-16" />
      </div>
    </div>
    <div class="px-4 border-t border-gray-200 text-blue">
      <div
        class="flex justify-between items-center mb-2"
        v-if="deliveryFee.isFree || deliveryFee.value > 0"
      >
        <div class="uppercase text-sm">{{ $t('ordering.delivery-fee') }}</div>
        <div v-if="deliveryFee.isFree" class="uppercase text-sm">
          {{ $t('ordering.free-delivery') }}
        </div>
        <div v-else>{{ deliveryFee.value | currency }}</div>
      </div>
    </div>
    <balance
      :terrace-surcharge-percentage="terraceSurchargePercentage"
      :bills="bills"
      class="z-10"
    />
    <tab-actions :tab-id="tabId" small />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Bills from '@last/core/src/billsGenerator'
import LSectionSideMenu from '@last/core-ui/components/LSectionSideMenu'
import LRowSelector from '@last/core-ui/components/LRowSelector'
import Balance from './Balance.vue'
import TabActions from '@/components/tabs/TabActions.vue'
import TabProduct from './TabProduct.vue'
import app from '@/app.js'

export default {
  name: 'OpenTab',
  props: {
    tabId: {
      type: String,
      default: null
    },
    catalogId: {
      type: String,
      default: null
    },
    selectedSeat: {
      type: Number,
      default: null
    },
    selectedCourse: {
      type: String,
      default: null
    },
    selectedProductId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fastCheckout: null,
      disableSwipe: false,
      disableDragscroll: false,
      disableProductSelection: false,
      delayTime: 300,
      listSelected: 'seats',
      listOptions: [
        { label: this.$t('tabs.seats'), value: 'seats' },
        { label: this.$t('tabs.courses'), value: 'courses' }
      ]
    }
  },
  mounted() {
    this.listSelected =
      ['seats', 'courses'].includes(this.tab.orderingMode) &&
      this.tabCourses &&
      this.tabCourses.length > 1
        ? this.tab.orderingMode
        : 'seats'

    if (this.listSelected === 'seats') this.$emit('update:selectedCourse', null)
  },
  methods: {
    ...mapActions('tabs', [
      'addSeat',
      'createKitchenOrders',
      'moveProduct',
      'removeSeat',
      'updateCourse',
      'updateComboProducts',
      'updateTabOrdering'
    ]),
    scrollToElement(scrollTarget) {
      if (!scrollTarget) return
      const container = document.getElementById('scroller')
      container.scrollTo({
        top:
          scrollTarget.offsetTop -
          (container.clientHeight - scrollTarget.clientHeight) / 2,
        behavior: 'smooth'
      })
    },
    updateSelectedCourse(course) {
      this.$emit(
        'update:selectedCourse',
        course === this.selectedCourse ? null : course
      )
    },
    setListSelected(option) {
      this.updateTabOrdering({ tabId: this.tabId, orderingMode: option })
      this.listSelected = option
      if (option === 'seats') this.$emit('update:selectedCourse', null)
    },
    showRemoveButton(seat) {
      return seat.length === 0 && this.tabSeats.length > 1
    },
    onRemove(index) {
      this.removeSeat({ tabId: this.tabId, selectedSeatIndex: index })
    },
    selectProduct(product, seat, course) {
      if (this.disableProductSelection) return
      if (product.comboProducts) {
        if (course) product.shownCombo.course = course
        this.$emit('selectedComboProduct', {
          product,
          comboProduct: product.shownCombo
        })
      } else {
        this.$emit('selectedProduct', product)
      }
      if (seat) this.$emit('update:selectedSeat', seat)
      if (course) this.$emit('update:selectedCourse', course)
    },
    onUpdate(seat, event) {
      let productId = event.item.id
      let position = event.newIndex
      this.moveProduct({ tabId: this.tabId, seat, position, productId })
    },
    onAdd(seat, event) {
      let productId = event.item.id
      let position = event.newIndex
      this.moveProduct({ tabId: this.tabId, seat, position, productId })
    },
    onUpdateCourse(course, event) {
      const comboId = event.item.getAttribute('combo-id')
      const productId = comboId || event.item.id
      this.updateCourse({
        course,
        productId: productId,
        comboProductId: comboId ? event.item.id : null
      })
    },
    onAddCourse(course, event) {
      const comboId = event.item.getAttribute('combo-id')
      const productId = comboId || event.item.id
      this.updateCourse({
        course,
        productId: productId,
        comboProductId: comboId ? event.item.id : null
      })
    },
    getProductId(product) {
      return product.comboProducts ? product.shownCombo.id : product.id
    },
    startDraggable() {
      this.disableSwipe = true
      this.disableDragscroll = true
    },
    endDraggable() {
      this.disableSwipe = false
      this.disableDragscroll = false
    },
    startDragscroll() {
      this.disableProductSelection = true
    },
    endDragscroll() {
      setTimeout(() => {
        this.disableProductSelection = false
      }, 100)
    }
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('catalog', ['catalogs']),
    ...mapState('tabs', ['tabs', 'products', 'kitchenOrders', 'bills']),
    ...mapState('tables', ['tables']),
    ...mapGetters('billing', ['getCurrentBill']),
    ...mapGetters('promotions', ['getTabGlobalPromotion']),
    ...mapGetters('tabs', [
      'getAllProducts',
      'getCourseProducts',
      'getSeatProducts',
      'getSharedProducts',
      'getTotal',
      'getDeliveryFee',
      'getBills'
    ]),
    bills() {
      let existingBills = this.getBills(this.tabId)
      if (this.checkoutComplete) {
        return existingBills
      } else {
        let currentBillWithPaymentInfo = Bills.addPaymentInfo(
          this.currentBill,
          []
        )
        return [currentBillWithPaymentInfo, ...existingBills]
      }
    },
    checkoutComplete() {
      let tabProducts = this.getAllProducts(this.tabId)
      return (
        tabProducts
          .map(product => product.notBilledQuantity)
          .reduce((total, quantity) => total + quantity, 0) === 0
      )
    },
    currentBill() {
      if (this.checkoutComplete) return null
      let currentDiscount = this.getTabGlobalPromotion(this.tabId)
      return this.getCurrentBill({
        tabId: this.tabId,
        currentDiscount,
        selectedProductIds: this.selectedProductsIds
      })
    },
    isElectron() {
      return app.isElectron
    },
    tab() {
      return this.tabs[this.tabId]
    },
    tabSeats() {
      return this.getSeatProducts(this.tabId)
    },
    tabCourses() {
      return this.getCourseProducts({
        catalogId: this.catalogId,
        tabId: this.tabId
      })
    },
    tabSharedProducts() {
      return this.getSharedProducts(this.tabId)
    },
    terraceSurchargePercentage() {
      return this.tabs[this.tabId].terraceSurchargePercentage
    },
    total() {
      return this.getTotal(this.tabId)
    },
    deliveryFee() {
      return this.getDeliveryFee(this.tabId)
    },
    isDelivery() {
      return ['delivery', 'ownDelivery'].includes(this.tab.pickupType)
    }
  },
  components: {
    Balance,
    LSectionSideMenu,
    LRowSelector,
    TabProduct,
    TabActions
  }
}
</script>

<style lang="scss" scoped>
#open-tab {
  height: 100%;
  width: 19.375rem;
}

.product-list {
  flex-grow: 1;
  flex-basis: 0;
}

.product-list::-webkit-scrollbar {
  display: none;
}
</style>
