<template>
  <div class="w-full">
    <div
      class="flex w-full justify-between items-center"
      v-for="product in tabProducts"
      :key="product.uniqueId"
    >
      <delivery-checkout-product :product="product" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DeliveryCheckoutProduct from './DeliveryCheckoutProduct.vue'

export default {
  name: 'DeliveryCheckoutProducts',
  props: {
    tabId: {
      type: String
    }
  },
  computed: {
    ...mapGetters('tabs', ['getSharedProducts']),
    tabProducts() {
      return this.splitProducts(this.getSharedProducts(this.tabId))
    }
  },
  methods: {
    splitProducts(products) {
      return products.flatMap(product => {
        return Array(product.quantity)
          .fill()
          .map((_, index) => {
            let discount2x1 =
              product.discountType === '2x1' &&
              index + 1 > product.quantity - product.discountAmount
            return {
              ...product,
              uniqueId: product.id + index,
              discount2x1,
              disabled:
                product.quantity - product.notBilledQuantity > index ||
                discount2x1
            }
          })
      })
    }
  },
  components: {
    DeliveryCheckoutProduct
  }
}
</script>

<style lang="scss" scoped>
.icon {
  @apply h-4 w-4;
}
.text-labeled {
  font-size: 12px;
  text-decoration: line-through;
}
</style>
