<template>
  <div class="home-container">
    <top-bar>
      <template slot="right">
        <icon
          name="calculator"
          @click.native.stop="goToTabBrowser"
        /> </template
    ></top-bar>
    <nav-bar
      :floorplans="floorplanList"
      :tab-count="tabCount"
      :selected-tab="selectedTab"
      @update:selectedTab="value => updateSelectedTab(value)"
    />
    <table-tabs v-if="showingFloorplan" :floorplan-id.sync="selectedTab" />
    <tab-browser
      v-else
      :tabs-type="selectedTab"
      :pre-selected-tab-id="tabBrowserSelectedTabId"
    />
  </div>
</template>

<script>
import TableTabs from '@/components/tables/TableTabs.vue'
import TabBrowser from '@/components/tabs/TabBrowser.vue'
import TopBar from '@/mobile/components/TopBar.vue'
import NavBar from '@/mobile/components/NavBar.vue'
import idle from '@/idle.js'
import homeMixin from '@/components/homeMixin.js'
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'Home',
  mixins: [idle, homeMixin],
  components: {
    TableTabs,
    TabBrowser,
    TopBar,
    NavBar,
    Icon
  },
  methods: {
    goToTabBrowser() {
      this.$router.push({ name: 'tabBrowser' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.button {
  margin-left: 20px;
}
.logo-height {
  height: 2rem;
}
</style>
