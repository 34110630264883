<template>
  <div id="tab" class="h-full overflow-y-scroll scrolling-touch bg-white">
    <div>
      <div
        class="flex items-center p-4 px-8 text-xl font-bold uppercase font-title seat-header"
        @click="selectProducts(tabSharedProducts)"
      >
        <icon name="select-all" class="mr-4 text-red" />
        <div class="text-blue">{{ $t('ordering.shared') }}</div>
      </div>
      <checkout-product
        v-for="product in tabSharedProducts"
        :key="product.uniqueId"
        :product="product"
        :is-selected="selectedUniqueIds.includes(product.uniqueId)"
        :is-disabled="product.disabled"
        @selected="toggleProduct(product)"
      />
    </div>
    <template v-for="(seat, index) in tabSeats">
      <div :key="index">
        <div
          class="flex items-center p-4 px-8 text-xl font-bold uppercase font-title seat-header"
          @click="selectProducts(seat)"
        >
          <icon name="select-all" class="mr-4 text-red" />
          <div class="text-blue">{{ $t('ordering.seat') }} {{ index + 1 }}</div>
        </div>
        <checkout-product
          v-for="product in seat"
          :key="product.uniqueId"
          :product="{ ...product, seat: index }"
          :is-selected="selectedUniqueIds.includes(product.uniqueId)"
          :is-disabled="product.disabled"
          @selected="toggleProduct(product)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CheckoutProduct from './CheckoutProduct.vue'
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'CheckoutTab',
  props: {
    tabId: {
      type: String,
      default: null
    },
    selectedProductIds: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapActions('tabs', ['addSeat']),
    toggleProduct(product) {
      this.$emit('toggleProduct', {
        id: product.id,
        uniqueId: product.uniqueId
      })
    },
    splitProducts(products) {
      return products.flatMap(product => {
        return Array(product.quantity)
          .fill()
          .map((quantity, index) => {
            let discount2x1 =
              product.discountType === '2x1' &&
              index + 1 > product.quantity - product.discountAmount
            return {
              ...product,
              uniqueId: product.id + index,
              discount2x1,
              paid:
                product.quantity - product.notPaidQuantity > index ||
                discount2x1,
              disabled:
                product.quantity - product.notBilledQuantity > index ||
                discount2x1
            }
          })
      })
    },
    selectProducts(products) {
      let enabledProducts = products.filter(product => !product.disabled)
      let unselected = enabledProducts.filter(
        product => !this.selectedUniqueIds.includes(product.uniqueId)
      )
      if (unselected.length > 0) {
        unselected.forEach(this.toggleProduct)
      } else {
        enabledProducts.forEach(this.toggleProduct)
      }
    }
  },
  computed: {
    ...mapState('tables', ['tables']),
    ...mapGetters('tabs', ['getSeatProducts', 'getSharedProducts']),
    tabSeats() {
      return this.getSeatProducts(this.tabId).map(seat =>
        this.splitProducts(seat)
      )
    },
    tabSharedProducts() {
      return this.splitProducts(this.getSharedProducts(this.tabId))
    },
    allProducts() {
      return [...this.tabSeats.flat(), ...this.tabSharedProducts]
    },
    allSelected() {
      return (
        this.allProducts.filter(product => !product.disabled).length ===
        this.selectedProductIds.length
      )
    },
    selectedUniqueIds() {
      return this.selectedProductIds.map(id => id.uniqueId)
    }
  },
  components: {
    CheckoutProduct,
    Icon
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

#tab {
  width: 19.375rem;
}

#tab::-webkit-scrollbar {
  display: none;
}

.seat {
  border-radius: 0.188rem;
  margin: 0.625rem;
  border: 0.062rem solid #e0e0e0;
  background: white;
}
</style>
