<template>
  <div class="flex flex-row h-screen">
    <div class="w-full md:w-1/2 flex justify-center items-center">
      <div class="w-3/4 md:w-1/2 xl:max-w-xs flex flex-col">
        <h1 class="text-blue title uppercase mb-6">{{ $t('login.title') }}</h1>

        <div v-if="usePIN">
          <l-field :label="$t('inputs.pin-label')">
            <l-pin-input :length="4" v-model="pin" theme="light"></l-pin-input>
          </l-field>
          <div
            class="text-center text-red underline cursor-pointer mt-4"
            @click="usePIN = false"
          >
            {{ $t('login.login-email') }}
          </div>
        </div>

        <div v-else>
          <l-field :label="$t('inputs.email-label')">
            <l-input
              type="text"
              :placeholder="$t('inputs.email-placeholder')"
              v-model="email"
              icon="mail"
            />
          </l-field>
          <l-field :label="$t('inputs.password-label')">
            <l-input-password
              :placeholder="$t('inputs.password-placeholder')"
              v-model="password"
            />
          </l-field>
          <section :class="{ 'opacity-0': !error }" class="flex">
            <p class="text-red">{{ error }}</p>
            <router-link :to="{ name: 'login' }" class="ml-1 underline">
              {{ $t('ctas.login') }}
            </router-link>
          </section>
          <div class="flex justify-center">
            <l-button
              class="text-white bg-red border-red shadow rounded-lg uppercase w-full"
              :class="{ 'is-loading': loggingIn, 'opacity-50': !isComplete }"
              @click="startLogin"
            >
              {{ $t('ctas.login') }}
            </l-button>
          </div>
          <div
            v-if="showPINOption"
            class="text-center text-red underline cursor-pointer mt-4"
            @click="usePIN = true"
          >
            {{ $t('login.login-pin') }}
          </div>
        </div>
      </div>
    </div>
    <l-image
      class="w-1/2 h-full bg-no-repeat bg-cover hidden md:block"
      style="object-fit: cover"
      asset="LoginBackground.png"
      local
    >
    </l-image>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import LField from '@last/core-ui/components/LField'
import LInput from '@last/core-ui/components/LInput'
import LInputPassword from '@last/core-ui/components/LInputPassword'
import LButton from '@last/core-ui/components/LButton'
import LImage from '@last/core-ui/components/LImage.vue'
import LPinInput from '@last/core-ui/components/LPinInput.vue'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'Login',
  data() {
    return {
      email: null,
      password: null,
      loggingIn: false,
      error: null,
      usePIN: false,
      pinError: false,
      pinLengthError: false,
      pin: '',
      showPINOption: false
    }
  },
  async mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'pos' })
    }
    let platform = Capacitor.getPlatform()
    this.showPINOption = platform !== 'web'
  },
  methods: {
    ...mapActions('auth', ['login']),
    async startLogin() {
      if (!this.isComplete) return
      this.loggingIn = true
      try {
        if (this.usePIN) this.login({ pin: this.pin })
        else await this.login({ email: this.email, password: this.password })
      } catch (error) {
        this.error = this.$t('login.error')
      }
      this.loggingIn = false
    },
    async selectLocationAndContinue(locationId) {
      let platform = Capacitor.getPlatform()
      if (platform !== 'web') {
        this.$router.push({
          name: 'extraDeviceWarning',
          params: { locationId }
        })
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapState('auth', ['locationId']),
    isComplete() {
      return this.usePIN ? this.pin.length == 4 : this.email && this.password
    }
  },
  watch: {
    async isAuthenticated(authenticated) {
      if (authenticated) {
        if (this.locationId) {
          if (this.usePIN) this.selectLocationAndContinue(this.locationId)
          else this.$router.push({ name: 'employees' })
        } else {
          this.$router.push({ name: 'locations' })
        }
      }
    },
    pin() {
      if (this.isComplete) {
        this.startLogin()
      }
    }
  },
  components: {
    LField,
    LInput,
    LInputPassword,
    LButton,
    LImage,
    LPinInput
  }
}
</script>

<style scoped></style>
