var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.name === 'comboEditor' && _vm.savedCatalogCombo)?_c('div',{staticClass:"h-screen flex flex-col items-center relative"},[_c('div',{staticClass:"w-full h-full pt-10 px-3 overflow-hidden flex flex-col"},[_c('div',{staticClass:"flex flex-row w-full items-center justify-center relative"},[_c('div',{staticClass:"absolute top-0 left-0 pr-3 pt-1"},[_c('icon',{staticClass:"text-gray-400",attrs:{"name":"arrow-left"},nativeOn:{"click":function($event){return _vm.close()}}})],1),_c('div',{staticClass:"text-blue uppercase text-xl font-bold font-title mr-3"},[_vm._v(" "+_vm._s(_vm.savedCatalogCombo.name)+" ")]),_c('div',{staticClass:"text-blue uppercase text-xl font-title"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.savedCatalogCombo.price))+" ")]),_c('div',{staticClass:"absolute top-0 right-0 p-1 rounded-full bg-green"},[_c('icon',{staticClass:"text-white",attrs:{"small":"","name":"plus"},nativeOn:{"click":function($event){return _vm.addCombo.apply(null, arguments)}}})],1)]),_c('div',{staticClass:"flex py-1"}),_c('div',{staticClass:"flex-1 flex flex-row w-full h-full"},[_c('div',{staticClass:"bg-white relative w-full flex flex-col overflow-hidden",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{directives:[{name:"horizontal-scroll",rawName:"v-horizontal-scroll"}],ref:'combo-wrapper',staticClass:"w-full flex flex-row overflow-scroll scrolling-touch mt-3"},_vm._l((_vm.savedCombos),function(combo,comboIndex){return _c('div',{key:comboIndex,ref:(comboIndex + "-anchor"),refInFor:true,staticClass:"mr-4 uppercase text-blue font-title px-1",class:{
                'border-b border-blue': _vm.selectedComboIndex === comboIndex
              },staticStyle:{"min-width":"fit-content"},attrs:{"label":combo.name},on:{"click":function($event){return _vm.selectComboIndex(comboIndex)}}},[_vm._v(" "+_vm._s(_vm.$t('combo-editor.combo') + ' ' + (comboIndex + 1))+" ")])}),0),_c('div',{directives:[{name:"horizontal-scroll",rawName:"v-horizontal-scroll"}],staticClass:"w-full flex flex-row overflow-scroll scrolling-touch"},[(_vm.savedCatalogCombo)?_vm._l((_vm.savedCatalogCombo.categories),function(category,index){return _c('l-pill',{key:category.id,staticClass:"mr-4",attrs:{"label":category.name,"value":_vm.selectedCategoryIndex === index},on:{"input":function($event){return _vm.selectCategory(index)}}})}):_vm._e()],2),(_vm.manualInterventionIds.length > 0)?_c('div',{staticClass:"text-gray-400 mt-8 -mb-5"},[_vm._v(" "+_vm._s(_vm.$t('combo-editor.products-with-required-modifiers-error'))+" ")]):_vm._e(),(_vm.categoryMin(_vm.selectedCategoryIndex) > 0)?_c('div',{staticClass:"text-gray-400 mb-2"},[_vm._v(" "+_vm._s(_vm.$t('combo-editor.select-min-max-products', { min: _vm.categoryMin(_vm.selectedCategoryIndex), max: _vm.categoryMax(_vm.selectedCategoryIndex) }))+" ")]):_c('div',{staticClass:"text-gray-400 mt-2"},[_vm._v(" "+_vm._s(_vm.$t('combo-editor.select-max-of-products', { max: _vm.categoryMax(_vm.selectedCategoryIndex) }))+" ")]),_c('div',{staticClass:"flex flex-wrap w-full overflow-scroll scrolling-touch content-start mt-5 mb-5 flex-1"},_vm._l((_vm.filteredProducts),function(product){return _c('menu-product',{key:product.id,staticClass:"w-full",class:{
                'opacity-50': _vm.categoryIsMaxed(
                  _vm.selectedComboIndex,
                  _vm.selectedCategoryIndex
                )
              },attrs:{"product":product,"component-style":"row","selected-quantity":_vm.productTotalSelected(_vm.selectedComboIndex, product.id)},on:{"selected":function($event){return _vm.saveProduct(product)}}})}),1)])])]),_c('div',{staticClass:"pb-24 w-full px-8 pt-5 bg-white flex flex-col justify-center items-center"},[_c('floating-button',{staticClass:"mb-16",class:{
          'opacity-50 pointer-events-none': !_vm.allCategoriesReachesMin(
            _vm.selectedComboIndex
          )
        },attrs:{"primary-label":this.$t('ordering.confirm')},on:{"primary":function($event){return _vm.saveCombo()}}}),_c('div',{staticClass:"text-red uppercase fixed bottom-0 mb-5",on:{"click":_vm.goToComboDetails}},[_vm._v(" "+_vm._s(_vm.$t('combo-editor.see-combo-detail'))+" ")])],1)]):_c('router-view',{attrs:{"editing-product":_vm.editingProduct,"saved-catalog-combo":_vm.savedCatalogCombo,"map-category-selected-products":_vm.mapCategorySelectedProducts,"map-category-maxed":_vm.mapCategoryMaxed,"saved-combos":_vm.savedCombos},on:{"saveProduct":_vm.saveEditedProduct,"updateProductQuantity":_vm.updateProductQuantity,"deleteProduct":_vm.deleteProduct,"editProduct":_vm.editProduct,"onRemove":_vm.onRemove}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }