var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-item",class:{ 'w-1/2': _vm.componentStyle === 'row' },on:{"click":function($event){return _vm.$emit('selected')}}},[(_vm.componentStyle === 'square')?_c('div',{staticClass:"menu-product flex flex-col relative bg-white rounded-big shadow flex-shrink-0 px-4 pt-12 pb-3 m-2 items-center justify-center text-center cursor-pointer"},[_c('div',{staticClass:"circle rounded-full absolute top-0 bg-blue-600 border flex items-center justify-center",style:({
        background: _vm.background,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderColor: _vm.borderColor
      })},[(!_vm.hasImage)?_c('div',{staticClass:"font-bold font-title uppercase",style:({
          color: _vm.getTextColor(_vm.product.color)
        })},[_vm._v(" "+_vm._s(_vm.shortName)+" ")]):_vm._e()]),_c('div',{staticClass:"text-gray-400 text-sm max-two-lines"},[_vm._v(_vm._s(_vm.product.name))])]):_c('div',{staticClass:"flex flex-row bg-white rounded-lg shadow items-center justify-center text-center h-12 m-1 sm:m-2"},[_c('div',{staticClass:"w-1/5 items-center justify-center flex"},[_c('div',{staticClass:"h-8 w-8 rounded-full bg-blue-600 border flex items-center justify-center",style:({
          background: _vm.background,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderColor: _vm.borderColor
        })},[(!_vm.hasImage)?_c('div',{staticClass:"font-bold font-title uppercase",style:({
            color: _vm.getTextColor(_vm.product.color)
          })},[_vm._v(" "+_vm._s(_vm.shortName)+" ")]):_vm._e()])]),_c('div',{staticClass:"text-blue text-sm flex-1 text-left leading-4 my-2 max-two-lines"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),(_vm.product.priceImpact)?_c('div',{staticClass:"text-blue text-sm mr-3 ml-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.product.priceImpact))+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedQuantity > 0),expression:"selectedQuantity > 0"}],staticClass:"w-2/12 flex justify-center"},[_c('div',{staticClass:"rounded-full bg-red h-6 w-6 text-white flex items-center justify-center"},[_vm._v(" "+_vm._s(_vm.selectedQuantity)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }