var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scale-to-fit',[_c(_vm.isMobile ? 'pinch-zoom' : 'div',{tag:"component",attrs:{"background-color":"rgba(0,0,0,0,0)","limit-pan":true,"overflow":"visible","limit-zoom":3}},[_c('div',{ref:"planCanvas",staticStyle:{"width":"580px","height":"400px","position":"relative"},attrs:{"id":"plan-container"}},_vm._l((_vm.tables),function(table){return _c('div',{key:table.id,staticClass:"relative table",class:[
          table.type,
          {
            'has-tab': table.tabIds.length > 0,
            'border border-white': _vm.pendingKitchenOrders(table),
            'has-bills': _vm.hasBills(table),
            'has-reservation':
              table.tabIds.length === 0 && _vm.hasReservation(table.id)
          }
        ],style:(_vm.getStyle(table)),attrs:{"parent":true},on:{"click":function($event){return _vm.selectTable(table)}}},[_c('div',{staticClass:"flex flex-col justify-between w-full h-full"},[(table.tabIds.length > 0)?_c('div',{staticClass:"pt-2 text-center line-height"},[_vm._v(" "+_vm._s(_vm.ellapsedTime(_vm.getLastInteraction(table.tabIds[0])))+" ")]):(_vm.hasReservation(table.id))?_c('div',{staticClass:"flex items-center justify-center pt-2 text-center line-height"},[_c('icon',{staticClass:"mr-1 opacity-75 clock-size",attrs:{"name":"time"}}),_c('div',[_vm._v(_vm._s(_vm.timeUntil(_vm.reservationTime(table.id))))])],1):_vm._e(),_c('div',{staticClass:"flex items-center justify-center h-full"},[_c('div',{staticClass:"text-xs font-bold text-center uppercase font-title line-height"},[_vm._v(" "+_vm._s(_vm.name(table))+" ")])]),(_vm.seats(table) > 1)?_c('div',{staticClass:"flex items-center justify-center pb-2"},[_c('icon',{staticClass:"mr-1 diners-size",attrs:{"name":"diners"}}),_vm._v(" "+_vm._s(_vm.seats(table))+" ")],1):_vm._e(),(_vm.pendingKitchenOrders(table))?_c('div',{staticClass:"absolute right-0 bg-white rounded-full circle"},[_c('div',{staticClass:"w-full h-full mr-2 rounded-full bg-yellow"})]):_vm._e()])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }