<template>
  <section id="seats-selector">
    <div
      v-click-outside="finishCalculator"
      @click.stop="initCalculator"
      class="p-2 sm:p-3 text-center border border-gray-200 rounded-lg text-green"
    >
      <span :class="{ 'text-gray-300': !value }">{{ currentValue }}</span>
    </div>
    <div
      class="flex flex-row flex-wrap mt-4 text-xl sm:text-3xl border-gray-300 border rounded-lg overflow-hidden text-blue"
    >
      <div
        v-for="(number, index) in 9"
        :key="number"
        class="flex items-center justify-center py-2  cursor-pointer digit border-b border-gray-300 w-1/3"
        :class="{ 'border-r': (index + 1) % 3 !== 0 }"
        @click="setValue(number)"
      >
        {{ number }}
      </div>
      <div
        class="flex items-center justify-center py-2  cursor-pointer digit border-r border-gray-300 w-1/3"
        @click="removeLastNumber()"
      >
        <icon name="back-arrow" class="icon" />
      </div>
      <div
        class="flex items-center justify-center py-2  cursor-pointer digit border-r border-gray-300 w-1/3"
        @click="setValue(0)"
      >
        0
      </div>
      <div
        class="flex items-center justify-center py-2  cursor-pointer digit border-gray-300 w-1/3"
        @click="removeAllNumbers()"
      >
        <icon name="clean" class="icon small" />
      </div>
    </div>
  </section>
</template>

<script>
import Icon from '@last/core-ui/components/Icon'
import numberKeysMixin from '@last/core-ui/mixins/numberKeysMixin'

export default {
  name: 'NewTabSeatsSelector',
  mixins: [numberKeysMixin],
  mounted() {
    this.initCalculator()
  },
  components: {
    Icon
  },
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    currentValue() {
      return this.value || this.$t('new-tab.seats-selector-default')
    }
  },
  methods: {
    initCalculator() {
      this.initNumberKeysListener(
        this.setValue.bind(this),
        this.removeLastNumber.bind(this),
        this.removeAllNumbers.bind(this),
        this.emitOnSave.bind(this)
      )
    },
    finishCalculator() {
      this.destroyNumberKeysListener()
    },
    setValue(value) {
      let temporallyValue = this.value ? this.value.toString() : ''
      temporallyValue = Math.min(temporallyValue + value, 100)
      this.$emit('input', parseInt(temporallyValue))
    },
    removeLastNumber() {
      let temporallyValue = this.value ? this.value.toString() : ''
      this.$emit('input', parseInt(temporallyValue.slice(0, -1)))
    },
    removeAllNumbers() {
      this.$emit('input', undefined)
    },
    emitOnSave() {
      this.$emit('save')
    },
    svg(name) {
      try {
        return require(`@last/core-ui/components/assets/svg/${name}.svg`)
      } catch (e) {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  @apply w-4 h-4 text-green;
  &.small {
    @apply w-3 h-3;
  }
}
.-m1 {
  margin: -1px;
}
</style>
