<template>
  <info-screen
    :title="$t('grace-period-expired.title')"
    :message="$t('grace-period-expired.message')"
    :show-exit-button="false"
    :image="'dizzy-robot.svg'"
  />
</template>

<script>
import InfoScreen from '@/components/core/InfoScreen.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GracePeriodExpired',
  mounted() {
    this.refreshBillingStatus()
    if (!this.gracePeriodExpired) {
      this.$router.push({
        name: 'employees'
      })
    }
  },
  methods: {
    ...mapActions('auth', ['refreshBillingStatus'])
  },
  computed: {
    ...mapGetters('auth', ['gracePeriodExpired'])
  },
  watch: {
    gracePeriodExpired() {
      if (!this.gracePeriodExpired) {
        this.$router.push({
          name: 'employees'
        })
      }
    }
  },
  components: {
    InfoScreen
  }
}
</script>
