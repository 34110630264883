<template>
  <div
    class="w-4 h-4 rounded-full p-1 border"
    :class="[borderColorClass]"
    @click.stop="$emit('input', !value)"
  >
    <div
      class="rounded-full h-full w-full"
      :class="[circleColorClass]"
      v-if="value"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'LSmallRadio',
  props: {
    value: Boolean,
    circleColorClass: {
      type: String,
      default: 'bg-red'
    },
    borderColorClass: {
      type: String,
      default: 'border-gray-300'
    }
  }
}
</script>
