const iconv = require('iconv-lite')
const Dither = require('canvas-dither')
const Flatten = require('canvas-flatten')

var Canvas = function Canvas(w, h) {
  var canvas = document.createElement('canvas')
  canvas.width = w || 300
  canvas.height = h || 150
  return canvas
}

Canvas.Image = function() {
  var img = document.createElement('img')
  return img
}

/**
 * Create a byte stream based on commands for ESC/POS printers
 */
class EscPosEncoder {
  /**
   * Create a new object
   *
   */
  constructor() {
    this._reset()
  }

  /**
   * Reset the state of the object
   *
   */
  _reset() {
    this._buffer = []
    this._codepage = 'ascii'

    this._state = {
      bold: false,
      italic: false,
      underline: false,
      hanzi: false
    }
  }

  /**
   * Encode a string with the current code page
   *
   * @param  {string}   value  String to encode
   * @return {object}          Encoded string as a ArrayBuffer
   *
   */
  _encode(value) {
    return iconv.encode(value, this._codepage)
  }

  /**
   * Add commands to the buffer
   *
   * @param  {array}   value  And array of numbers, arrays, buffers or Uint8Arrays to add to the buffer
   *
   */
  _queue(value) {
    value.forEach(item => this._buffer.push(item))
  }

  /**
   * Initialize the printer
   *
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  initialize() {
    this._queue([0x1b, 0x40])

    return this
  }

  /**
   * Change the code page
   *
   * @param  {string}   value  The codepage that we set the printer to
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  codepage(value) {
    const codepages = {
      cp437: [0x00, false],
      cp737: [0x40, false],
      cp850: [0x02, false],
      cp775: [0x5f, false],
      cp852: [0x12, false],
      cp855: [0x3c, false],
      cp857: [0x3d, false],
      cp858: [0x13, false],
      cp860: [0x03, false],
      cp861: [0x38, false],
      cp862: [0x3e, false],
      cp863: [0x04, false],
      cp864: [0x1c, false],
      cp865: [0x05, false],
      cp866: [0x11, false],
      cp869: [0x42, false],
      cp936: [0xff, true],
      cp949: [0xfd, true],
      cp950: [0xfe, true],
      cp1252: [0x10, false],
      iso88596: [0x16, false],
      shiftjis: [0xfc, true],
      windows1250: [0x48, false],
      windows1251: [0x49, false],
      windows1252: [0x47, false],
      windows1253: [0x5a, false],
      windows1254: [0x5b, false],
      windows1255: [0x20, false],
      windows1256: [0x5c, false],
      windows1257: [0x19, false],
      windows1258: [0x5e, false]
    }

    let codepage

    if (!iconv.encodingExists(value)) {
      throw new Error('Unknown codepage')
    }

    if (value in iconv.encodings) {
      if (typeof iconv.encodings[value] === 'string') {
        codepage = iconv.encodings[value]
      } else {
        codepage = value
      }
    } else {
      throw new Error('Unknown codepage')
    }

    if (typeof codepages[codepage] !== 'undefined') {
      this._codepage = codepage
      this._state.hanzi = codepages[codepage][1]

      this._queue([0x1b, 0x74, codepages[codepage][0]])
    } else {
      throw new Error('Codepage not supported by printer')
    }

    return this
  }

  /**
   * Print a newline
   *
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  newline() {
    this._queue([0x0a, 0x0d])

    return this
  }

  /**
   * Change text alignment
   *
   * @param  {string}          value   left, center or right
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  align(value) {
    const alignments = {
      left: 0x00,
      center: 0x01,
      right: 0x02
    }

    if (value in alignments) {
      this._queue([0x1b, 0x61, alignments[value]])
    } else {
      throw new Error('Unknown alignment')
    }

    return this
  }

  /**
   * Image
   *
   * @param  {object}         element  an element, like a canvas or image that needs to be printed
   * @param  {number}         width  width of the image on the printer
   * @param  {number}         height  height of the image on the printer
   * @param  {string}         algorithm  the dithering algorithm for making the image black and white
   * @param  {number}         threshold  threshold for the dithering algorithm
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  image(element, width, height, algorithm, threshold) {
    if (width % 8 !== 0) {
      throw new Error('Width must be a multiple of 8')
    }

    if (height % 8 !== 0) {
      throw new Error('Height must be a multiple of 8')
    }

    if (typeof algorithm === 'undefined') {
      algorithm = 'threshold'
    }

    if (typeof threshold === 'undefined') {
      threshold = 128
    }

    let canvas = new Canvas(width, height)
    let context = canvas.getContext('2d')
    context.drawImage(element, 0, 0, width, height)
    let image = context.getImageData(0, 0, width, height)

    image = Flatten.flatten(image, [0xff, 0xff, 0xff])

    switch (algorithm) {
      case 'threshold':
        image = Dither.threshold(image, threshold)
        break
      case 'bayer':
        image = Dither.bayer(image, threshold)
        break
      case 'floydsteinberg':
        image = Dither.floydsteinberg(image)
        break
      case 'atkinson':
        image = Dither.atkinson(image)
        break
    }

    let getPixel = (x, y) => (image.data[(width * y + x) * 4] > 0 ? 0 : 1)

    let bytes = new Uint8Array((width * height) >> 3)

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x = x + 8) {
        let i = y * (width >> 3) + (x >> 3)
        bytes[i] =
          (getPixel(x + 0, y) << 7) |
          (getPixel(x + 1, y) << 6) |
          (getPixel(x + 2, y) << 5) |
          (getPixel(x + 3, y) << 4) |
          (getPixel(x + 4, y) << 3) |
          (getPixel(x + 5, y) << 2) |
          (getPixel(x + 6, y) << 1) |
          getPixel(x + 7, y)
      }
    }
    let offset = 0
    let maxHeight = 2048
    let lineBytes = Math.round(width / 8)
    let chunkSize = Math.round(lineBytes * maxHeight)
    while (offset < bytes.length) {
      let chunk = bytes.slice(offset, offset + chunkSize)
      let chunkHeight = Math.round(chunk.length / lineBytes)
      this._queue([
        0x1d,
        0x76,
        0x30,
        0x00,
        (width >> 3) & 0xff,
        ((width >> 3) >> 8) & 0xff,
        chunkHeight & 0xff,
        (chunkHeight >> 8) & 0xff,
        chunk
      ])
      offset += chunkSize
    }

    return this
  }

  imageEsc(element, width, height, algorithm, threshold) {
    if (width % 8 !== 0) {
      throw new Error('Width must be a multiple of 8')
    }

    if (height % 8 !== 0) {
      throw new Error('Height must be a multiple of 8')
    }

    if (typeof algorithm === 'undefined') {
      algorithm = 'threshold'
    }

    if (typeof threshold === 'undefined') {
      threshold = 128
    }

    let canvas = new Canvas(width, height)
    let context = canvas.getContext('2d')
    context.drawImage(element, 0, 0, width, height)
    let image = context.getImageData(0, 0, width, height)

    image = Flatten.flatten(image, [0xff, 0xff, 0xff])

    switch (algorithm) {
      case 'threshold':
        image = Dither.threshold(image, threshold)
        break
      case 'bayer':
        image = Dither.bayer(image, threshold)
        break
      case 'floydsteinberg':
        image = Dither.floydsteinberg(image)
        break
      case 'atkinson':
        image = Dither.atkinson(image)
        break
    }

    let getPixel = (x, y) => (image.data[(width * y + x) * 4] > 0 ? 0 : 1)

    this._queue([0x1b, 0x33, 0x10])
    for (let y = 0; y < height; y = y + 8) {
      let bytes = new Uint8Array(width)
      for (let x = 0; x < width; x++) {
        bytes[x] =
          (getPixel(x, y + 0) << 7) |
          (getPixel(x, y + 1) << 6) |
          (getPixel(x, y + 2) << 5) |
          (getPixel(x, y + 3) << 4) |
          (getPixel(x, y + 4) << 3) |
          (getPixel(x, y + 5) << 2) |
          (getPixel(x, y + 6) << 1) |
          getPixel(x, y + 7)
      }
      this._queue([0x1b, 0x2a, 0x00, width & 0xff, (width >> 8) & 0xff, bytes])
    }
    this._queue([0x1b, 0x32])
    return this
  }

  /**
   * Cut paper
   *
   * @param  {string}          value   full or partial. When not specified a full cut will be assumed
   * @return {object}                  Return the object, for easy chaining commands
   *
   */
  cut(value) {
    let data = 0x00

    if (value == 'partial') {
      data = 0x01
    }

    this._queue([0x1b, 0x56, data])

    return this
  }

  /**
   * Add raw printer commands
   *
   * @param  {array}           data   raw bytes to be included
   * @return {object}          Return the object, for easy chaining commands
   *
   */
  raw(data) {
    this._queue(data)

    return this
  }

  /**
   * Encode all previous commands
   *
   * @return {Uint8Array}         Return the encoded bytes
   *
   */
  encode() {
    let length = 0

    this._buffer.forEach(item => {
      if (typeof item === 'number') {
        length++
      } else {
        length += item.length
      }
    })

    let result = new Uint8Array(length)

    let index = 0

    this._buffer.forEach(item => {
      if (typeof item === 'number') {
        result[index] = item
        index++
      } else {
        result.set(item, index)
        index += item.length
      }
    })

    this._reset()

    return result
  }
}

export default EscPosEncoder
