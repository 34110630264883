<template>
  <div
    class="h-screen flex flex-col justify-center items-center bg-cover bg-image"
  >
    <div>
      <l-dialog
        :title="$t('extra-device.title')"
        :transparent-bg="false"
        @confirm="onDeviceAdded"
        @cancel="logout"
      >
        <div class="message text-center px-2">
          {{ $t('extra-device.message') }}
        </div>
        <div class="message text-gray-400 text-center mt-4 px-2">
          {{ $t('extra-device.contact-us') }} <br />
          {{ contactInfo }}
        </div>
      </l-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import LDialog from '@last/core-ui/components/LDialog.vue'
import app from '@/app.js'
import api from '@/api.js'
import sync from '@/sync/service.js'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'ExtraDeviceWarning',
  data() {
    return {
      locations: []
    }
  },
  async mounted() {
    let response = await api.get('/locations')
    this.locations = response.data
  },
  methods: {
    ...mapActions('auth', ['logout', 'selectLocation']),
    async onDeviceAdded() {
      let selectedLocation = this.locations.find(
        l => l.id === this.locationIdToBeSelected
      )
      let platform = Capacitor.getPlatform()
      if (platform !== 'web') {
        const device = { deleted: false, ...(await app.getDeviceInfo()) }
        try {
          await api.put(`/devices/${device.id}`, device)
        } catch (e) {
          const error = e.response?.data
          if (error.type === 'devices_limit') {
            this.$router.push({
              name: 'planSelector',
              params: {
                plan: error.plan,
                locationId: this.locationIdToBeSelected,
                permission: true
              }
            })
          } else if (error.type === 'no_permission') {
            this.$router.push({
              name: 'planSelector',
              params: {
                plan: error.plan,
                locationId: this.locationIdToBeSelected,
                permission: false
              }
            })
          }
          return
        }
      }
      this.selectLocation(selectedLocation)
      sync.resync()
      this.$router.push({ name: 'employees' })
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapState('auth', ['locationId']),
    isMobile() {
      return app.isMobile
    },
    contactInfo() {
      return 'billing@last.app - (+34) 93 737 78 21'
    },
    locationIdToBeSelected() {
      return this.$route.params.locationId
    }
  },
  watch: {
    isAuthenticated(authenticated) {
      if (!authenticated) {
        this.$router.push({ name: 'login' })
      }
    }
  },
  components: {
    LDialog
  }
}
</script>

<style lang="scss" scoped>
.bg-image {
  background-image: url('../assets/background-employees.jpg');
}
.message {
  text-transform: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
</style>
