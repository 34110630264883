import Vue from 'vue'
import '@/logsSetup.js'
import App from './App.vue'
import router from './router'
import './style.scss'
import store from './store/index.js'
import './filters.js'
import './directives.js'
import ManagerConfirmationPlugin from '@/plugins/managerConfirmation.js'
import sync from '@/sync/service.js'
import vClickOutside from 'v-click-outside'
import LDialogPlugin from '@last/core-ui/plugins/dialog.js'
import VScrollLock from 'v-scroll-lock'
import mqtt from '@/sync/mqtt.js'
import * as VueGoogleMaps from 'vue2-google-maps'
import jobs from '@/jobs.js'
import VueBarcode from 'vue-barcode'
import { datadogLogs } from '@datadog/browser-logs'
import VueDragscroll from 'vue-dragscroll'
import LNotificationPlugin from '@last/core-ui/plugins/notification'
import localDb from '@/localDb.js'
import PinchZoom from 'vue-pinch-zoom'

import { App as CapacitorApp } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { Network } from '@capacitor/network'
import PortalVue from 'portal-vue'
import i18n from './i18n'
import './datadogRum.js'

Network.addListener('networkStatusChange', status => {
  if (status.connected) {
    store.dispatch('status/updateNetworkStatus', true)
  } else {
    store.dispatch('status/updateNetworkStatus', false)
  }
})

Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV !== 'production'

Vue.use(ManagerConfirmationPlugin)
Vue.use(vClickOutside)
Vue.use(LDialogPlugin)
Vue.use(PortalVue)
Vue.use(VScrollLock)
Vue.use(VueDragscroll)
Vue.use(LNotificationPlugin)
Vue.component('Barcode', VueBarcode)
Vue.component('PinchZoom', PinchZoom)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_KEY,
    libraries: 'places'
  },
  installComponents: true
})

let app = new Vue({
  render: h => h(App),
  store,
  i18n,
  router
}).$mount('#app')

if (window.Cypress) {
  window.app = app
}

// For backward compat. To remove.
async function clearOldTables() {
  await localDb.updateTables({
    tabs: [],
    products: [],
    bills: [],
    payments: [],
    kitchenOrders: [],
    'tabs-tabs': [],
    'tabs-products': [],
    'tabs-bills': [],
    'tabs-payments': [],
    'tabs-kitchenOrders': [],
    'catalog-catalogs': [],
    'catalog-categories': [],
    'catalog-combos': [],
    'catalog-products': [],
    'catalog-defaultPosCatalog': [],
    'catalog-virtualBrandCatalogs': [],
    'catalog:virtualBrandCatalogs': [],
    'catalog-locationBrandCatalogsBySource': []
  })
}

const init = async () => {
  await store.restored
  await clearOldTables()
  let tabsTables
  let catalogTables
  if (!sync.isInitialized()) {
    let tabKeys = Object.keys(store.state.tabs).map(key => `tabs:${key}`)
    tabsTables = await localDb.loadTables(tabKeys)
    tabsTables = Object.keys(tabsTables).reduce((res, key) => {
      res[key.replace('tabs:', '')] = tabsTables[key]
      return res
    }, {})
  }
  if (!sync.isInitialized()) {
    store.commit('tabs/replaceTabs', tabsTables)
  }
  if (Object.keys(store.state.catalog.catalogs).length === 0) {
    let catalogKeys = Object.keys(store.state.catalog).map(
      key => `catalog:${key}`
    )
    catalogTables = await localDb.loadTables(catalogKeys)
    catalogTables = Object.keys(catalogTables).reduce((res, key) => {
      res[key.replace('catalog:', '')] = catalogTables[key]
      return res
    }, {})
  }
  if (Object.keys(store.state.catalog.catalogs).length === 0) {
    store.commit('catalog/updateCatalogs', catalogTables)
    store.commit('catalog/updateProducts', catalogTables.products)
  }
  store.dispatch('catalog/refreshCatalogs')
  store.dispatch('config/refreshConfig')
  SplashScreen.hide()
  let locationId = store.state.auth.locationId
  await store.dispatch('status/updateCurrentStatus')
  if (locationId) {
    mqtt.subscribe(locationId)
    store.dispatch('auth/refreshCurrentLocation')
    store.dispatch('status/refreshErrorMessages')
    store.dispatch('auth/refreshBillingStatus')
    datadogLogs.addLoggerGlobalContext('locationId', locationId)
  }
  CapacitorApp.addListener('appStateChange', state => {
    if (state.isActive) {
      sync.resync()
      store.dispatch('catalog/refreshCatalogs')
      if (store.state.config.config.organizationConfig.promotions) {
        store.dispatch('promotions/refreshPromotions')
      }
      if (store.state.config.config.organizationConfig.deliveryFleet) {
        store.dispatch('couriers/refreshCouriers')
      }
    }
  })
  jobs.start()
}

init()
