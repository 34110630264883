import Dinero from 'dinero.js'
import uuid4 from 'uuid/v4'

const currency = 'EUR'
const zero = toMoney(0)

function toMoney(amount) {
  return Dinero({ amount, currency })
}

function sum(p1, p2) {
  return p1.add(p2)
}

function addProductsPrice(products) {
  return products.map(p => toMoney(p.price)).reduce(sum, zero)
}

function calculateDiscountAndSurcharge(
  products,
  discount,
  terraceSurchargePercentage
) {
  let productsTotal = addProductsPrice(products)
  let discountTotal = zero
  if (discount) {
    switch (discount.type) {
      case 'percentage':
        discountTotal = productsTotal.percentage(discount.amount)
        break
      case 'currency':
        discountTotal = toMoney(discount.amount)
        break
    }
  }
  let terraceSurcharge = productsTotal
    .subtract(discountTotal)
    .percentage(terraceSurchargePercentage)
  return {
    discountTotal,
    terraceSurcharge
  }
}

function mapTaxCategories(
  products,
  taxRate,
  discount,
  deliveryOrder,
  terraceSurchargePercentage,
  pickupType
) {
  let extrasTotal = zero
  let deliveryFee = zero
  let minimumBasketSurcharge = zero
  let { taxRateType, defaultTaxPercentage } =
    getTaxRateTypeAndDefaultTaxPercentage(pickupType, taxRate)
  let { discountTotal, terraceSurcharge } = calculateDiscountAndSurcharge(
    products,
    discount,
    terraceSurchargePercentage
  )
  if (deliveryOrder) {
    if (!discount?.freeDelivery)
      deliveryFee = toMoney(deliveryOrder.deliveryFee || 0)
    minimumBasketSurcharge = toMoney(deliveryOrder.minimumBasketSurcharge || 0)
    extrasTotal = extrasTotal.add(deliveryFee).add(minimumBasketSurcharge)
  }
  let groupedTaxCategories = products.reduce((taxCategories, product) => {
    let taxCategory = taxCategories[product.taxes[taxRateType]] || 0
    taxCategories[product.taxes[taxRateType]] = taxCategory + product.price
    return taxCategories
  }, {})
  if (groupedTaxCategories && Object.keys(groupedTaxCategories).length === 0) {
    return []
  }
  if (extrasTotal.getAmount() > 0) {
    groupedTaxCategories[defaultTaxPercentage]
      ? (groupedTaxCategories[defaultTaxPercentage] += extrasTotal.getAmount())
      : (groupedTaxCategories[defaultTaxPercentage] = extrasTotal.getAmount())
  }
  let weightedDiscount = []
  let weightedTerraceSurcharge = []
  if (Object.values(groupedTaxCategories).every(value => value === 0)) {
    let allocateForZeros = Object.values(groupedTaxCategories).map(value =>
      toMoney(value)
    )
    weightedDiscount = allocateForZeros
    weightedTerraceSurcharge = allocateForZeros
  } else {
    weightedDiscount = discountTotal.allocate(
      Object.keys(groupedTaxCategories).map(
        taxPercentage => groupedTaxCategories[taxPercentage]
      )
    )
    weightedTerraceSurcharge = terraceSurcharge.allocate(
      Object.keys(groupedTaxCategories).map(
        taxPercentage => groupedTaxCategories[taxPercentage]
      )
    )
  }
  return Object.keys(groupedTaxCategories).map((taxPercentage, i) => {
    let originalTotal = groupedTaxCategories[taxPercentage]
    taxPercentage = parseFloat((taxPercentage * 100).toFixed(2))
    let total = toMoney(originalTotal)
      .subtract(weightedDiscount[i])
      .add(weightedTerraceSurcharge[i])
    total = Dinero.maximum([total, toMoney(0)])
    let taxableBase = total
      .multiply(100)
      .divide(100 + taxPercentage)
      .getAmount()
    return {
      originalTotal,
      total: total.getAmount(),
      discountTotal: weightedDiscount[i].getAmount(),
      terraceSurcharge: weightedTerraceSurcharge[i].getAmount(),
      taxableBase,
      tax: total.getAmount() - taxableBase,
      taxPercentage
    }
  })
}

function addPaymentsPrice(payments) {
  return payments
    .map(payment => toMoney(payment.amount - (payment.tip || 0)))
    .reduce(sum, zero)
}

function breakdown(
  mappedProducts,
  discount,
  deliveryOrder,
  terraceSurchargePercentage,
  taxRate,
  pickupType
) {
  const taxCategories = mapTaxCategories(
    mappedProducts,
    taxRate,
    discount,
    deliveryOrder,
    terraceSurchargePercentage,
    pickupType
  )
  let groupedBreakdown = taxCategories.reduce(
    (taxes, taxCategory) => {
      taxes.total += taxCategory.total
      taxes.discountTotal += taxCategory.discountTotal
      taxes.taxableBase += taxCategory.taxableBase
      taxes.tax += taxCategory.tax
      taxes.originalTotal += taxCategory.originalTotal
      taxes.terraceSurcharge += taxCategory.terraceSurcharge
      return taxes
    },
    {
      total: 0,
      discountTotal: 0,
      taxableBase: 0,
      tax: 0,
      originalTotal: 0,
      terraceSurcharge: 0
    }
  )
  let deliveryFee = discount?.freeDelivery ? 0 : deliveryOrder?.deliveryFee || 0
  return {
    total: groupedBreakdown.total,
    tax: groupedBreakdown.tax,
    taxPercentage: taxRate.value * 100,
    taxableBase: groupedBreakdown.taxableBase,
    taxLabel: taxRate.label,
    originalTotal: groupedBreakdown.originalTotal,
    discountTotal: groupedBreakdown.discountTotal,
    deliveryFee: deliveryFee,
    minimumBasketSurcharge: deliveryOrder?.minimumBasketSurcharge || 0,
    terraceSurcharge: groupedBreakdown.terraceSurcharge || 0,
    terraceSurchargePercentage: terraceSurchargePercentage,
    taxRates: taxCategories
  }
}

function mapModifiers(modifiers = []) {
  return modifiers.map(modifier => ({
    id: uuid4(),
    name: modifier.name,
    priceImpact: modifier.priceImpact,
    catalogModifierId: modifier.parentModifierId,
    quantity: modifier.quantity
  }))
}

function mapComboProductModifiers(modifiers = []) {
  return modifiers.map(modifier => ({
    id: uuid4(),
    name: modifier.name,
    priceImpact: modifier.priceImpact,
    tabComboProductModifierId: modifier.parentModifierId,
    quantity: modifier.quantity
  }))
}

function mapProducts(products, taxRate) {
  return compute2x1Products(products)
    .filter(product => product.quantity > 0)
    .map(product => {
      let price = product.finalPrice
      return {
        tabProductId: product.id,
        catalogProductId: product.parentProduct,
        id: uuid4(),
        name: product.name,
        quantity: product.quantity,
        price: Math.round(price * product.quantity),
        originalPrice: Math.round(product.fullPrice * product.quantity),
        discountType: product.discountType,
        discountAmount: product.discountAmount,
        discountConcept: product.discountConcept,
        promotionId: product.promotionId,
        modifiers: mapModifiers(product.modifiers),
        comboProducts: (product.comboProducts || []).map(comboProduct => ({
          id: uuid4(),
          tabComboProductId: comboProduct.id,
          name: comboProduct.name,
          priceImpact: comboProduct.priceImpact,
          quantity: comboProduct.quantity,
          modifiers: mapComboProductModifiers(comboProduct.modifiers)
        })),
        comments: product.comments,
        taxes: product.taxes || taxRate
      }
    })
}

function compute2x1Products(products) {
  return products.flatMap(product => {
    let products = []
    if (product.discountType === '2x1') {
      products = [
        {
          ...product,
          quantity: product.discountAmount,
          finalPrice: 0,
          discountType: '2x1',
          discountAmount: null
        },
        {
          ...product,
          discountType: null,
          discountAmount: null,
          quantity: product.quantity - product.discountAmount
        }
      ]
    } else {
      products = [product]
    }
    return products
  })
}

function cloneBill(bill) {
  return {
    ...bill,
    id: uuid4(),
    creationTime: new Date(),
    products: bill.products.map(product => ({
      ...product,
      id: uuid4(),
      modifiers: product.modifiers.map(modifier => ({
        ...modifier,
        id: uuid4()
      })),
      comboProducts: (product.comboProducts || []).map(comboProduct => ({
        ...comboProduct,
        id: uuid4(),
        modifiers: (comboProduct.modifiers || []).map(modifier => ({
          ...modifier,
          id: uuid4()
        }))
      }))
    }))
  }
}

function getTaxRateTypeAndDefaultTaxPercentage(pickupType, taxRate) {
  let taxRateType
  let defaultTaxPercentage
  if (!taxRate) {
    return { taxRateType: 'value', defaultTaxPercentage: 10 }
  }
  switch (pickupType) {
    case 'delivery':
    case 'ownDelivery':
      taxRateType = 'delivery'
      defaultTaxPercentage = taxRate.delivery
      break
    case 'takeAway':
      taxRateType = 'takeaway'
      defaultTaxPercentage = taxRate.takeaway
      break
    default:
      taxRateType = 'value'
      defaultTaxPercentage = taxRate.value
  }
  return { taxRateType, defaultTaxPercentage }
}

export default {
  generateProductsBill({
    products,
    company = {},
    tab = {},
    discount = undefined,
    taxRate,
    ticketInfo
  }) {
    let mappedProducts = mapProducts(products, taxRate)
    let diners = 0
    if (Array.isArray(tab.seats)) {
      diners = tab.seats.length
    } else if (tab.seats) {
      diners = tab.seats
    }
    let productsBilled = products ? products.length : 0
    let tabProducts = 0
    if (tab.source) {
      if (tab.source === 'Restaurant') {
        if (Array.isArray(tab.shared)) tabProducts += tab.shared.length
        if (Array.isArray(tab.seats))
          tabProducts += tab.seats.reduce(
            (acc, seat) => acc + seat.length || 0,
            0
          )
      } else if (tab.products) {
        tabProducts = tab.products.reduce(
          (acc, product) => acc + product.quantity,
          0
        )
      }
    }
    let type = productsBilled === tabProducts ? 'total' : 'products'
    let bill = {
      id: uuid4(),
      tabId: tab.id,
      creationTime: new Date(),
      number: null,
      payments: [],
      type,
      title: 'Products',
      products: mappedProducts,
      ...breakdown(
        mappedProducts,
        discount,
        tab.deliveryOrder,
        tab.terraceSurchargePercentage || 0,
        taxRate,
        tab.pickupType
      ),
      company,
      diners,
      customerCompany: tab.customerCompany,
      allergyInfo: tab.allergyInfo,
      discount,
      ...(tab.deliveryOrder && {
        customerName: tab.deliveryOrder.customerName,
        customerAddress: tab.deliveryOrder.address,
        customerAddressDetails: tab.deliveryOrder.addressDetails,
        customerPostalCode: tab.deliveryOrder.postalCode,
        customerPhoneNumber: tab.deliveryOrder.customerPhoneNumber,
        needCutlery: tab.deliveryOrder.needCutlery,
        preferredPaymentMethod: tab.deliveryOrder.preferredPaymentMethod
      }),
      ...(tab.customerInfo && {
        customerName: [tab.customerInfo.name, tab.customerInfo.surname]
          .filter(name => name)
          .join(' '),
        customerPhoneNumber: tab.customerInfo.phoneNumber,
        customerPhoneNumberCode: tab.customerInfo.phoneNumberCode
      }),
      tableName: tab.tableName || tab.name,
      source: tab.source,
      virtualBrandId: tab.virtualBrandId,
      code: tab.code,
      note: tab.customerNote,
      pickupType: tab.pickupType,
      schedulingTime: tab.schedulingTime,
      activationTime: tab.activationTime,
      locationBrandId: tab.virtualBrandId,
      promotionId: tab.promotionId || discount?.promotionId,
      ticketInfo
    }
    return bill
  },
  addPaymentInfo(bill, payments) {
    let paid = addPaymentsPrice(payments).getAmount()
    let pending = Math.max(0, bill.total - paid)
    return {
      ...bill,
      paid: paid,
      pending: pending
    }
  },
  generateInvoice(bill, customerCompany) {
    let invoice = cloneBill(bill)
    invoice.customerCompany = customerCompany
    invoice.number = null
    return invoice
  },
  getTaxRateTypeAndDefaultTaxPercentage
}
