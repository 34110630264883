<template>
  <div
    class="bg-blue-700 h-full w-full px-10 pt-12 text-lg summary-size relative"
  >
    <div class="text-white uppercase mb-5">
      {{ $t('end-shift.shift-summary') }}
    </div>
    <div class="h-full overflow-y-scroll scrolling-touch">
      <div
        v-for="(group, index) in groupedTillsReport"
        :key="index"
        class="text-sm mb-5"
      >
        <div class="text-gray-400 text-base mb-3">
          {{ $t('end-shift.total') }} {{ group.type }}
        </div>
        <div class="flex flex-row justify-between text-gray-300">
          <div>{{ $t('end-shift.expected') }}</div>
          <div>{{ group.expectedEndAmount | currency }}</div>
        </div>
        <div class="flex flex-row justify-between text-gray-300">
          <div>{{ $t('end-shift.declared') }}</div>
          <div v-if="group.endAmount || group.endAmount === 0">
            {{ group.endAmount | currency }}
          </div>
          <div v-else>
            -
          </div>
        </div>
        <div class="flex flex-row justify-between text-gray-300">
          <div class="text-gray-400">{{ $t('end-shift.difference') }}</div>
          <div
            v-if="group.endAmount || group.endAmount === 0"
            :class="{
              'text-green': group.endAmount - group.expectedEndAmount >= 0,
              'text-red': group.endAmount - group.expectedEndAmount < 0
            }"
          >
            {{ (group.endAmount - group.expectedEndAmount) | currency }}
          </div>
          <div v-else>
            -
          </div>
        </div>
      </div>
      <div class="separator bg-gray-400 mb-5 mt-10" />
      <div class="text-sm text-gray-400 mb-40">
        <div class="flex flex-row justify-between">
          <div>{{ $t('end-shift.total-expected') }}</div>
          <div>{{ totalGrouped.totalExpectedEndAmount | currency }}</div>
        </div>
        <div class="flex flex-row justify-between">
          <div>{{ $t('end-shift.total-declared') }}</div>
          <div
            v-if="
              totalGrouped.totalEndAmount || totalGrouped.totalEndAmount === 0
            "
          >
            {{ totalGrouped.totalEndAmount | currency }}
          </div>
          <div v-else>
            -
          </div>
        </div>
        <div class="flex flex-row justify-between">
          <div>{{ $t('end-shift.total-difference') }}</div>
          <div
            v-if="
              totalGrouped.totalEndAmount || totalGrouped.totalEndAmount === 0
            "
            :class="{
              'text-green': totalGrouped.totalDifference >= 0,
              'text-red': totalGrouped.totalDifference < 0
            }"
          >
            {{ totalGrouped.totalDifference | currency }}
          </div>
          <div v-else>-</div>
        </div>
      </div>
    </div>
    <div class="transparent-gradient position-down w-10/12 h-16 absolute" />
    <div
      class="text-center text-white underline absolute bg-blue-700 h-12 pt-3 bottom-0 cursor-pointer text-sm inset-x-0 flex-shrink-0"
      @click="$emit('printPreview')"
    >
      {{ $t('end-shift.print-shift-summary') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TillsViewSummary',
  data() {
    return {}
  },
  props: {
    groupedTillsReport: {
      type: Object,
      default: () => {}
    }
  },
  methods: {},
  computed: {
    totalGrouped() {
      return Object.keys(this.groupedTillsReport).reduce(
        (res, groupKey) => {
          let group = this.groupedTillsReport[groupKey]
          res.totalExpectedEndAmount += group.expectedEndAmount
          res.totalEndAmount += group.endAmount
          res.totalDifference += group.endAmount - group.expectedEndAmount
          return res
        },
        { totalExpectedEndAmount: 0, totalEndAmount: 0, totalDifference: 0 }
      )
    }
  }
}
</script>

<style scoped>
.separator {
  width: 100%;
  position: relative;
  right: 0px;
  height: 1px;
}

.summary-size {
  width: 387px;
}

.transparent-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1d1c48 100%);
}

.position-down {
  bottom: 8%;
}
</style>
