import mqtt from '@/sync/mqtt.js'
import { Network } from '@capacitor/network'
import api from '@/api.js'
import { notification } from '@last/core-ui/plugins/notification'

const state = {
  networkConnected: false,
  mqttConnected: false,
  errorMessages: []
}

const getters = {
  isConnected: (state, getters, rootState) => {
    return (
      (state.mqttConnected && state.networkConnected) ||
      rootState.config.demoMode
    )
  }
}

const actions = {
  async updateCurrentStatus({ commit }) {
    let status = await Network.getStatus()
    let mqttConnected = mqtt.isConnected
    commit('updateNetworkStatus', status.connected)
    commit('updateMqttStatus', mqttConnected)
  },
  updateNetworkStatus({ commit }, connected) {
    commit('updateNetworkStatus', connected)
  },
  updateMqttStatus({ commit }, connected) {
    commit('updateMqttStatus', connected)
  },
  async refreshErrorMessages({ commit }, errorMessages) {
    if (!errorMessages) {
      errorMessages = (await api.get('/error-messages')).data
    }
    commit('updateErrorMessages', errorMessages)
    for (const errorMessage of errorMessages) {
      notification.create({
        title: errorMessage.title,
        subtitle: errorMessage.description,
        type: 'error',
        keepInScreen: true,
        blockDelete: true,
        icon: 'close',
        iconColor: 'red'
      })
    }
  }
}

const mutations = {
  updateNetworkStatus(state, connected) {
    state.networkConnected = connected
  },
  updateMqttStatus(state, connected) {
    state.mqttConnected = connected
  },
  updateErrorMessages(state, errorMessages) {
    state.errorMessages = errorMessages
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
