var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col charge-calculator"},[(!_vm.hidePaymentMethods)?_c('div',{staticClass:"flex overflow-x-scroll scrolling-touch payment-methods"},_vm._l((_vm.paymentMethods),function(method){return _c('div',{key:method.type,staticClass:"flex items-center p-3 mb-4 mr-2 text-sm uppercase whitespace-no-wrap bg-white rounded-small",class:[
        method.type,
        {
          'bg-blue text-white': method.type === _vm.paymentMethod.type,
          'text-blue': method.type != _vm.paymentMethod.type
        }
      ],on:{"click":function($event){return _vm.selectPaymentMethod(method)}}},[_c('icon',{staticClass:"mr-2 text-red",attrs:{"name":method.type}}),_vm._v(" "+_vm._s(method.name)+" ")],1)}),0):_vm._e(),_c('checkout-amounts',{staticClass:"flex p-2 mb-3 bg-white shadow rounded-small",attrs:{"pending-amount":_vm.pendingAmount,"to-pay":_vm.toPay,"is-tip":_vm.paymentMethod.type !== 'cash',"operation":{
      symbol: _vm.currentOperationSymbol,
      number: _vm.currentOperationNumber,
      filter: _vm.currentOperationFilter
    }},on:{"full-amount":function($event){_vm.toPay = _vm.pendingAmount}}}),_c('numbers',{staticClass:"overflow-hidden text-xl font-bold bg-gray-200 border border-gray-200 shadow calculator rounded-small text-blue",on:{"send":_vm.handleButtonClicked}}),_c('div',{staticClass:"flex justify-center p-4 mt-2 font-bold text-white uppercase border bg-red border-red-b rounded-small charge",class:{ disabled: _vm.toPay === 0 },on:{"click":function($event){return _vm.charge()}}},[(_vm.paymentMethod.name)?_c('icon',{staticClass:"mr-2 text-white opacity-50",attrs:{"name":_vm.paymentMethod.name}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('checkout.charge'))+" "+_vm._s(_vm._f("currency")(_vm.toPay))+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }