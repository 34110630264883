<template>
  <div />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'

export default {
  name: 'CloseTab',
  props: {
    tabId: {
      type: String,
      default: null
    },
    shouldAddPaymentForDelivery: {
      type: Boolean,
      default: false
    },
    paymentData: {
      type: Object,
      default: () => {}
    },
    showActionText: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (this.tabId) this.confirmCloseTab()
  },
  methods: {
    ...mapActions('tabs', ['closeTab', 'addPayment']),
    closeTabAndGoHome(withPin = false) {
      this.closeTab({ tabId: this.tabId, closedWithPin: withPin })
      if (this.$route.name !== 'pos') {
        this.$router.push({ name: 'pos' })
      }
      this.$lnotification.create({
        title: this.$t('notifications.tab-closed'),
        actionText: this.showActionText
          ? this.$i18n.t('notifications.view-tab')
          : undefined,
        onAction: () => {
          this.$router.push({
            name: 'checkout',
            params: { tabId: this.tabId }
          })
        }
      })
      this.$emit('tab-closed')
    },
    async confirmCloseTab() {
      if (this.shouldAddPaymentForDelivery) {
        if (CashMachine.methods.includes(this.paymentData.type)) {
          let charged = await CashMachine.charge(this.paymentData.amount)
          if (charged === 0) {
            this.$lnotification.create({
              title: this.$t('notifications.payment-failed')
            })
            this.$emit('cancel')
            return
          }
        }
        this.addPayment({ ...this.paymentData, tabId: this.tabId })
        this.closeTabAndGoHome()
        return
      }
      let pendingCharges = this.bills.some(bill => bill.pending > 0)
      if (pendingCharges) {
        this.$ldialog({
          title: this.$t('close-tab.title'),
          content: this.$t('close-tab.message-pending-payments'),
          onConfirm: () => {
            this.$emit('cancel')
          }
        })
      } else if (!this.checkoutComplete) {
        if (this.hasSentToKitchenProducts(this.tabId)) {
          this.$ldialog({
            title: this.$t('close-tab.title'),
            content: this.$t('close-tab.message-no-kitchen-orders'),
            mainLabel: this.$t('close-tab.enter-pin'),
            onConfirm: async () => {
              const hasPermission = await this.$confirm('CLOSE_TAB_AS_MANAGER')
              if (hasPermission) {
                this.closeTabAndGoHome(true)
              } else {
                this.$emit('cancel')
              }
            }
          })
        } else {
          this.$ldialog({
            title: this.$t('close-tab.title'),
            content: this.$t('close-tab.message-kitchen-orders'),
            mainLabel: this.$t('close-tab.close-it'),
            onConfirm: () => {
              this.closeTabAndGoHome()
            }
          })
        }
      } else {
        this.closeTabAndGoHome()
      }
    }
  },
  computed: {
    ...mapGetters('tabs', [
      'getBills',
      'getAllProducts',
      'hasSentToKitchenProducts'
    ]),
    ...mapState('tabs', ['tabs']),
    bills() {
      if (!this.tabId) return []
      return this.getBills(this.tabId)
    },
    checkoutComplete() {
      if (!this.tabId) return false
      let tabProducts = this.getAllProducts(this.tabId)
      return (
        tabProducts
          .filter(product => product.finalPrice != 0)
          .map(product => product.notBilledQuantity)
          .reduce((total, quantity) => total + quantity, 0) === 0
      )
    }
  },
  watch: {
    tabId(id) {
      if (id) this.confirmCloseTab()
    }
  }
}
</script>

<style lang="scss" scoped></style>
