<template>
  <div
    class="flex w-full justify-start content-start content-stretch px-4 py-2 bg-gray-300"
    :class="{ tall: twoLines }"
  >
    <header class="h-full mr-2" v-if="showPromotions">
      <div
        class="flex w-auto items-center justify-center flex-shrink-0 py-4 m-1 mr-0 font-bold text-center uppercase bg-white shadow rounded-small promotion cursor-pointer"
        @click="selectCategory('promotions')"
        v-if="showPromotions"
        :class="{
          'text-white bg-green': selectedCategoryId === 'promotions',
          'text-green': selectedCategoryId !== 'promotions'
        }"
      >
        <icon name="discount" class="w-8 h-8" />
      </div>
    </header>
    <div
      ref="scrollarea"
      v-horizontal-scroll
      v-dragscroll="isElectron"
      @dragscrollstart="startDragscroll"
      @dragscrollend="endDragscroll"
      class="w-full h-full flex justify-start items-stretch content-start overflow-scroll scrolling-touch text-blue-900 categories"
      :class="[{ 'flex-col flex-wrap': twoLines }]"
    >
      <div
        ref="categories"
        class="flex items-center justify-center flex-shrink-0 px-2 py-4 m-1 text-center uppercase bg-white shadow rounded-small category cursor-pointer"
        v-for="category in categoryList"
        @click="selectCategory(category.id)"
        :class="{
          'bg-red text-white': selectedCategoryId === category.id,
          'text-blue': selectedCategoryId !== category.id
        }"
        :key="category.id"
      >
        <span :ref="category.id">
          {{ category.name }}
        </span>
      </div>
      <div class="h-4 w-4 flex-shrink-0" />
    </div>
  </div>
</template>

<script>
import _ from '@last/core/src/lastUtils'
import Icon from '@last/core-ui/components/Icon.vue'
import { mapState } from 'vuex'
import app from '@/app.js'

export default {
  name: 'Categories',
  components: {
    Icon
  },
  props: {
    catalogId: {
      type: String,
      default: null
    },
    showPromotions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCategoryId: null,
      disableCategorySelection: false,
      windowResizeHandler: null,
      availableSpaceForItems: Infinity
    }
  },
  created() {
    this.windowResizeHandler = _.debounce(this.onResize, 100)
    window.addEventListener('resize', this.windowResizeHandler)
  },
  mounted() {
    this.preselectCategory()
    this.onResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResizeHandler)
  },
  methods: {
    preselectCategory() {
      if (
        (!this.selectedCategoryId ||
          !this.categories[this.selectedCategoryId]) &&
        this.categoryIds.length > 0
      ) {
        const param = this.$route.query.selectedCategoryId
        this.selectedCategoryId =
          (this.$refs[param] && param) || this.categoryIds[0]
        let container = this.$refs[this.selectedCategoryId][0]
        container.scrollIntoView({
          behavior: 'auto',
          block: 'center'
        })
        this.$emit('category-selected', this.selectedCategoryId)
      }
    },
    startDragscroll() {
      this.disableCategorySelection = true
    },
    endDragscroll() {
      setTimeout(() => {
        this.disableCategorySelection = false
      }, 100)
    },
    selectCategory(id) {
      if (this.disableCategorySelection) return
      this.selectedCategoryId = id
      this.$emit('category-selected', this.selectedCategoryId)
    },
    ellipsis(name) {
      let maxLength = 20
      if (name.length > maxLength) {
        return name.slice(0, maxLength) + '...'
      }
      return name
    },
    getWidth(elem) {
      const boundsRect = elem.getBoundingClientRect()
      return boundsRect.width
    },
    onResize() {
      this.availableSpaceForItems = this.getWidth(this.$refs.scrollarea)
    }
  },
  computed: {
    ...mapState('catalog', ['catalogs', 'categories']),
    isElectron() {
      return app.isElectron
    },
    twoLines() {
      const items = this.categoryIds.length
      return items > this.itemsPerLine && !app.isMobile
    },
    itemsPerLine() {
      const firstItem = this.$refs.categories?.[0]
      const itemWidth = firstItem ? this.getWidth(firstItem) : 1
      const itemsThatFit = Math.floor(this.availableSpaceForItems / itemWidth)
      const flipDirectionAt = itemsThatFit * 2 - 2
      return flipDirectionAt
    },
    categoryIds() {
      return this.catalogs[this.catalogId].categories
    },
    categoryList() {
      return this.categoryIds.map(id => ({
        id,
        name: this.ellipsis(this.categories[id].name)
      }))
    }
  },
  watch: {
    categoryIds() {
      this.preselectCategory()
    },
    showPromotions() {
      if (!this.showPromotions) {
        this.preselectCategory()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
$base-font-size: 16px;

@function rems($pixel-size) {
  @return math.div($pixel-size, $base-font-size) * 1rem;
}

.category {
  width: rems(140px);
  height: rems(46px);
  overflow: hidden;

  & > span {
    font-size: rems(14px);
    line-height: 100%;
  }
}

.tall {
  height: rems(124px);
}

.promotion {
  @extend .category;
  width: rems(46px);
}

@media screen and (max-width: 640px) {
  .category {
    height: 3.5rem;
  }

  .promotion {
    height: 3.5rem;
    width: 3.5rem;
  }
}

.categories::-webkit-scrollbar {
  display: none;
}
</style>
