<template>
  <div @click.stop class="h-full w-full">
    <div @click="openDiscount"><slot /></div>
    <portal to="appRoot" v-if="showModal">
      <div
        class="absolute top-0 left-0 h-screen w-screen blur-background flex justify-center items-center z-30"
      >
        <div>
          <div class="text-white uppercase text-2xl text-center pb-6">
            {{ $t('product-discount.title') }}
          </div>
          <div
            class="whitespace-pre-line bg-white rounded-big shadow p-12 text-color-question font-body text-left relative dialog-w"
            @click.stop
          >
            <div class="flex flex-row">
              {{ $t('product-discount.select-discount-type') }}
            </div>
            <icon
              name="trash"
              class="text-red absolute top-0 right-0 m-6"
              @click.native="removeDiscount"
            />
            <div class="flex flex-row items-center">
              <div class="flex flex-row items-center py-3">
                <l-small-radio
                  :value="editingDiscount.type === 'cash'"
                  class="mr-2"
                  @input="editingDiscount.type = 'cash'"
                />
                <div
                  @click="editingDiscount.type = 'cash'"
                  class="text-size text-color-option"
                >
                  {{ $t('product-discount.cash') }}
                </div>
              </div>
              <div class="flex flex-row px-6 items-center">
                <l-small-radio
                  :value="editingDiscount.type === 'percentage'"
                  class="mr-2"
                  @input="editingDiscount.type = 'percentage'"
                />
                <div
                  @click="editingDiscount.type = 'percentage'"
                  class="text-size text-color-option"
                >
                  {{ $t('product-discount.percentage') }}
                </div>
              </div>
            </div>
            <div class="pt-2">
              {{ $t('product-discount.discount-to-apply') }}
            </div>
            <div class="relative">
              <l-input v-model="editingDiscount.amount" />
              <div class="absolute right-0 top-0 m-3 text-red">
                <icon
                  v-if="editingDiscount.type === 'percentage'"
                  name="porcentaje"
                />
                <icon v-else :name="`currencies/${currencyIcon}`" />
              </div>
            </div>
            <div class="pt-2">
              {{ $t('product-discount.concept') }}
            </div>
            <l-input v-model="editingDiscount.concept" />
            <div v-if="message" class="text-center pt-4 text-sm text-red">
              {{ message }}
            </div>
          </div>
          <div class="flex pt-4">
            <div
              class="p-3 px-4 text-white bg-blue border border-blue-700 uppercase rounded-small font-bold flex-1 text-center"
              @click="close"
            >
              {{ $t('dialog.cancel') }}
            </div>
            <div
              class="p-3 px-4 text-white bg-red border border-red-b uppercase rounded-small font-bold flex-1 ml-4 text-center"
              @click="save"
            >
              {{ $t('product-discount.save') }}
            </div>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import Icon from '@last/core-ui/components/Icon'
import LSmallRadio from '@last/core-ui/components/LSmallRadio'
import LInput from '@last/core-ui/components/LInput'

export default {
  name: 'ProductDiscount',
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showModal: false,
      message: null,
      editingDiscount: {
        type: null,
        amount: 0,
        concept: null
      }
    }
  },
  computed: {
    ...mapGetters('config', ['currencyIcon']),
    wholeDiscount() {
      return {
        type: this.product.discountType,
        amount:
          this.product.discountType === 'cash'
            ? this.product.discountAmount / 100
            : this.product.discountAmount,
        concept: this.product.discountConcept
      }
    }
  },
  methods: {
    ...mapActions('tabs', [
      'updateProductQuantity',
      'updateProductDiscount',
      'splitProduct'
    ]),
    openDiscount() {
      this.showModal = true
    },
    discountIsValid() {
      return (
        this.editingDiscount !== null &&
        this.editingDiscount.amount !== null &&
        !isNaN(this.editingDiscount.amount) &&
        ((this.editingDiscount.type === 'cash' &&
          this.editingDiscount.amount * 100 <= this.product.fullPrice) ||
          (this.editingDiscount.type === 'percentage' &&
            this.editingDiscount.amount <= 100)) &&
        this.editingDiscount.type !== null
      )
    },
    async save() {
      const hasPrivilege = await this.$confirm('DISCOUNT_MANAGER')
      if (!hasPrivilege) return
      if (!this.discountIsValid()) {
        this.message = this.$t('product-discount.incorrect-values')
        return
      }
      let sendingAmount =
        this.editingDiscount.type === 'cash'
          ? this.editingDiscount.amount * 100
          : this.editingDiscount.amount
      this.updateDiscount({ ...this.editingDiscount, amount: sendingAmount })
      this.$emit('close')
      this.showModal = false
    },
    close() {
      this.$emit('close')
      this.showModal = false
    },
    cloneDiscount() {
      this.editingDiscount = {
        ...this.wholeDiscount
      }
    },
    removeDiscount() {
      this.removeProductDiscount()
      this.$emit('updated')
      this.$emit('close')
      this.showModal = false
    },
    async updateDiscount(discount) {
      let productPricing = ProductPriceCalculator.calculateProductPricing({
        ...this.product,
        discountType: discount.type,
        discountAmount: discount.amount
      })
      if (this.product.quantity > 1) {
        let splittedProductId = await this.splitProduct({
          productId: this.product.id,
          seat: this.product.seat
        })
        await this.updateProductDiscount({
          productId: splittedProductId,
          discount: {
            discountType: discount.type,
            discountAmount: discount.amount,
            discountConcept: discount.concept,
            promotionId: null
          },
          productPricing: {
            ...productPricing
          }
        })
      } else {
        this.updateProductDiscount({
          productId: this.product.id,
          discount: {
            discountType: discount.type,
            discountAmount: discount.amount,
            discountConcept: discount.concept,
            promotionId: null
          },
          productPricing: {
            ...productPricing
          }
        })
      }
      this.$emit('updated')
    },
    removeProductDiscount() {
      let productPricing = ProductPriceCalculator.calculateProductPricing({
        ...this.product,
        discountType: null,
        discountAmount: null
      })
      this.updateProductDiscount({
        productId: this.product.id,
        discount: {
          discountType: null,
          discountAmount: null,
          discountConcept: null,
          promotionId: null
        },
        productPricing: {
          ...productPricing
        }
      })
    }
  },
  mounted() {
    this.cloneDiscount()
  },
  watch: {
    discount() {
      this.cloneDiscount()
    },
    showModal(value) {
      if (value) {
        this.cloneDiscount()
      }
    }
  },
  components: {
    Icon,
    LSmallRadio,
    LInput
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.text-color-question {
  color: #aaaac9;
}

.text-color-option {
  color: #545383;
}

.text-size {
  font-size: 14px;
}

.dialog-w {
  width: 484px;
}
</style>
