<template>
  <div class="flex flex-col charge-calculator">
    <div
      v-if="!hidePaymentMethods"
      class="flex overflow-x-scroll scrolling-touch payment-methods"
    >
      <div
        v-for="method in paymentMethods"
        :key="method.type"
        class="flex items-center p-3 mb-4 mr-2 text-sm uppercase whitespace-no-wrap bg-white rounded-small"
        :class="[
          method.type,
          {
            'bg-blue text-white': method.type === paymentMethod.type,
            'text-blue': method.type != paymentMethod.type
          }
        ]"
        @click="selectPaymentMethod(method)"
      >
        <icon :name="method.type" class="mr-2 text-red" />
        {{ method.name }}
      </div>
    </div>
    <checkout-amounts
      class="flex p-2 mb-3 bg-white shadow rounded-small"
      @full-amount="toPay = pendingAmount"
      :pending-amount="pendingAmount"
      :to-pay="toPay"
      :is-tip="paymentMethod.type !== 'cash'"
      :operation="{
        symbol: currentOperationSymbol,
        number: currentOperationNumber,
        filter: currentOperationFilter
      }"
    />
    <numbers
      class="overflow-hidden text-xl font-bold bg-gray-200 border border-gray-200 shadow calculator rounded-small text-blue"
      @send="handleButtonClicked"
    />
    <div
      :class="{ disabled: toPay === 0 }"
      class="flex justify-center p-4 mt-2 font-bold text-white uppercase border bg-red border-red-b rounded-small charge"
      @click="charge()"
    >
      <icon
        v-if="paymentMethod.name"
        :name="paymentMethod.name"
        class="mr-2 text-white opacity-50"
      />
      {{ $t('checkout.charge') }} {{ toPay | currency }}
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import { mapState } from 'vuex'
import numberKeysMixin from '@last/core-ui/mixins/numberKeysMixin'
import CheckoutAmounts from '@/components/checkout/CheckoutAmounts.vue'
import Numbers from '@/components/checkout/Numbers.vue'

export default {
  name: 'ChargeCalculator',
  mixins: [numberKeysMixin],
  props: {
    pendingAmount: {
      type: Number,
      default: 0
    },
    billId: {
      type: String,
      default: null
    },
    hidePaymentMethods: {
      type: Boolean,
      default: false
    },
    defaultPaymentMethod: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      paymentMethod: {}
    }
  },
  mounted() {
    if (this.defaultPaymentMethod) {
      this.paymentMethod = this.defaultPaymentMethod
    } else if (
      this.config.paymentMethodsConfig &&
      this.config.paymentMethodsConfig.length > 0
    ) {
      this.paymentMethod = this.paymentMethods[0]
    }
    this.prefillToPay()
    this.initNumberKeysListener(
      this.handleNumberClicked.bind(this),
      this.deleteNumber.bind(this),
      this.clear.bind(this),
      this.charge.bind(this)
    )
  },
  computed: {
    ...mapState('config', ['config']),
    paymentMethods() {
      return this.config.paymentMethodsConfig.map(method => ({
        ...method,
        type: method.name.toLowerCase()
      }))
    },
    currentOperationNumber() {
      if (this.currentOperationFilter) {
        return this.currentOperationFilter(this.currentNumber || 0)
      }
      return this.currentNumber
    }
  },
  methods: {
    selectPaymentMethod(method) {
      this.paymentMethod = method
      this.prefillToPay()
    },
    getPaymentMethodIcon(method) {
      switch (method) {
        case 'cash':
          return 'fa-coins'
        case 'card':
          return 'fa-credit-card'
        case 'izettle':
          return 'fa-credit-card'
        default:
          return ''
      }
    }
  },
  watch: {
    billId() {
      this.prefillToPay()
    },
    pendingAmount(amount) {
      if (this.toPay > amount) {
        this.toPay = amount
      }
    },
    toPay(value) {
      this.toPay = Math.min(value, 100_000_000)
    }
  },
  components: {
    Icon,
    CheckoutAmounts,
    Numbers
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

.number-button {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amount-preview {
  background: #ececec;
  color: #4d4d4d;
  text-align: right;
  padding: 0.625rem;
}

span.disabled {
  color: darken(white, 50%);
}

.calculator {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-row-gap: 0.062rem;
  grid-column-gap: 0.062rem;
  height: 19rem;
  width: 22.75rem;
  max-width: 100%;
}

.payment-methods {
  width: 22.75rem;
}

.multiply {
  grid-row-start: 2;
  grid-column-start: 4;
}

.subtract {
  grid-row-start: 3;
  grid-column-start: 4;
}

.add {
  grid-row-start: 4;
  grid-column-start: 4;
}

.double-cero {
  grid-row-start: 5;
  grid-column-start: 1;
}

.display {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 5;
  border-radius: 0.188rem;
  border: 0.062rem solid $separator;
  background: rgba(white, 0.8);
  display: flex;
  align-items: center;
}

.pending-container {
  flex: 1;
  text-align: center;
}

.pending {
  font-size: 1.25rem;
}

.to-pay-container {
  flex: 1;
  text-align: center;
}

.to-pay {
  font-weight: bold;
  font-size: 1.25rem;
}

.separator {
  width: 0.062rem;
  background: $separator;
  height: 70%;
}

.to-pay-operation {
  display: flex;
  justify-content: center;
  align-items: center;
}

.current-operation {
  margin-left: 0.625rem;
}

.charge-method {
  @include card;
  padding: 0.625rem;
  margin: 0.625rem;
  & > i {
    margin-left: 0.312rem;
  }
  &.selected {
    background: $orange;
    color: white;
  }
}
</style>
