<template>
  <div
    class="relative flex flex-col justify-between flex-shrink-0 px-6 py-4 m-2 bg-white border border-gray-300 menu-product rounded-small cursor-pointer leading-tight font-hairline text-base"
    :class="{
      'opacity-50 pointer-events-none': !product.isValid || product.disabled
    }"
    @click="$emit('selected')"
  >
    <div class="text-blue font-body">{{ name }}</div>
    <div class="text-sm text-gray-400">{{ description }}</div>
    <div class="text-sm text-right text-green">
      {{ product.pointsExpense || 0 }} {{ $t('product.points') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromotionProduct',
  props: {
    product: {
      type: Object,
      default: null
    }
  },
  methods: {
    limitTextOf(text) {
      let maxLength = 30
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...'
      }
      return text
    }
  },
  computed: {
    name() {
      return this.product.name ? this.limitTextOf(this.product.name) : ''
    },
    description() {
      return this.product.description
        ? this.limitTextOf(this.product.description)
        : ''
    }
  }
}
</script>

<style scoped>
.menu-product {
  height: 6.438rem;
  width: 13.25rem;
}
</style>
