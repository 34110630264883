import api from '@/api.js'
import app from '@/app.js'
import imageCache from '@/imageCache.js'
import i18n from '@/i18n.js'
import Printer from '@/printer.js'
import sync from '@/sync/service.js'
import { Capacitor } from '@capacitor/core'
import moment from 'moment-timezone'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import Dataphone from '@/integrations/dataphone/dataphone.js'
import lastUtils from '@last/core/src/lastUtils'

const state = {
  printers: {},
  config: {
    employees: {
      selectionType: 'names'
    },
    virtualBrands: [],
    reservations: {},
    tills: {
      cash: []
    },
    featureToggles: {},
    organizationConfig: {},
    locationCountryCode: ''
  },
  mute: localStorage.getItem('mute') || false,
  device: {
    mode: 'master'
  },
  demoMode: false
}

const getters = {
  virtualBrands: state => state.config.virtualBrands,
  reservations: state => state.config.reservations,
  tillNameByTillId: state => tillId =>
    Object.values(state.config.tills)
      .flat()
      .find(till => till.id === tillId).name,
  tills: state => {
    return state.config.tills
  },
  currencyIcon: state => {
    return lastUtils.getCurrencyIcon(state.config?.currencyCode)
  },
  appVersion: state => {
    return state.device.appVersion || 'web'
  },
  getVirtualBrand: state => virtualBrandId => {
    return state.config.virtualBrands?.find(b => b.id === virtualBrandId)
  },
  shipmentProviders: state => state.config.shipmentProviders,
  currentAdyenDataphone: state => {
    return state.config.adyenDataphones?.[0]
  }
}

const actions = {
  async refreshConfig({ state, commit, dispatch, rootGetters }, config) {
    if (!rootGetters['auth/isAuthenticated']) return
    if (!config) {
      try {
        config = (await api.get('/config')).data
      } catch (e) {
        config = {
          ...state.config,
          printers: Object.values(state.config.printers)
        }
      }
    }
    let stores = config.glovoScrapperStores
    if (stores?.length > 0) {
      dispatch('scrapper/updateStores', stores, { root: true })
    }

    let channel
    if (
      config?.featureToggles &&
      Object.keys(config.featureToggles).includes('release_channel_beta')
    ) {
      channel = 'beta'
    }
    app.checkForUpdates(channel)

    if (config.logoUrl) {
      imageCache.cacheLogo(config.logoUrl)
    }
    config.virtualBrands.forEach(brand => {
      imageCache.cacheCloudinary('brand', brand.id, brand.imageId)
    })
    let language = config.language
    if (state.demoMode) {
      language = globalThis.navigator.language.split('-')[0]
    }
    i18n.locale = language || 'en'
    if (config.workingTime.timezone) {
      moment.tz.setDefault(config.workingTime?.timezone || 'Europe/Madrid')
    }
    moment.locale(i18n.locale)
    moment.updateLocale(i18n.locale, {
      relativeTime: {
        future: i18n.t('relative-time.future', { time: '%s' }),
        past: '%s',
        s: i18n.t('relative-time.now'),
        ss: i18n.t('relative-time.seconds', { time: '%d' }),
        m: i18n.t('relative-time.one-minute'),
        mm: i18n.t('relative-time.minutes', { time: '%d' }),
        h: i18n.t('relative-time.one-hour'),
        hh: i18n.t('relative-time.hours'),
        d: i18n.t('relative-time.hours'),
        dd: i18n.t('relative-time.hours'),
        w: i18n.t('relative-time.hours'),
        ww: i18n.t('relative-time.hours'),
        M: i18n.t('relative-time.hours'),
        MM: i18n.t('relative-time.hours'),
        y: i18n.t('relative-time.hours'),
        yy: i18n.t('relative-time.hours')
      }
    })
    Printer.loadPrinters(config.printers)
    commit('refreshConfig', config)
    dispatch('refreshDeviceInfo')
  },
  async refreshDeviceInfo({ state, getters, commit, dispatch }) {
    let deviceInfo = {}
    let platform = Capacitor.getPlatform()
    if (platform !== 'web') {
      const device = await app.getDeviceInfo()
      // HACK: This is to keep the same behaviour as the old code. If we have a
      // device stored we reuse the same id.
      // This should not be necessary because that seems to be already the behaviour
      // of Capacitor. In Electron it keeps the generated id in the localStorage.
      if (state.device.id) {
        device.id = state.device.id
      }
      let response = await api.put(`/devices/${device.id}`, device)
      deviceInfo = { ...response.data }
      commit('refreshDeviceInfo', deviceInfo)
      dispatch('auth/setAccessToken', deviceInfo.accessToken, { root: true })
    }
    let enabledTills = getters.tills.cash
    let preferredTill = enabledTills?.find(
      till => till.id === deviceInfo.preferredTill
    )
    if (preferredTill) {
      dispatch('till/setTill', preferredTill, {
        root: true
      })
    } else if (enabledTills.length === 1) {
      dispatch('till/setTill', enabledTills[0], {
        root: true
      })
    } else if (enabledTills.length > 1) {
      dispatch('till/updateSelectedTillIfNull', enabledTills[0], {
        root: true
      })
    }
    CashMachine.init(state.config, deviceInfo.preferredCashMachine)
    Dataphone.init(state.config, deviceInfo.preferredDataphone)
  },
  async toggleMute({ commit }) {
    commit('toggleMute')
  },
  async updateShipmentProvider({ commit }, shipmentProvider) {
    sync.record('updateShipmentProvider', shipmentProvider)
    commit('updateShipmentProvider', shipmentProvider)
  },
  async updateManualShipment({ commit }, manualShipment) {
    sync.record('updateManualShipment', manualShipment)
    commit('updateManualShipment', manualShipment)
  },
  async activateDemoMode({ commit }) {
    commit('activateDemoMode')
  },
  async updateHardwarePreferences({ state, commit }, hardwarePreferences) {
    if ('preferredCashMachine' in hardwarePreferences)
      CashMachine.init(state.config, hardwarePreferences.preferredCashMachine)

    if ('preferredDataphone' in hardwarePreferences)
      Dataphone.init(state.config, hardwarePreferences.preferredDataphone)

    commit('updateHardwarePreferences', hardwarePreferences)

    if (state.device.id) {
      await api.put(
        `/devices/hardware-preferences/${state.device.id}`,
        hardwarePreferences
      )
    }
  }
}

const mutations = {
  activateDemoMode(state) {
    state.demoMode = true
  },
  refreshConfig(state, config) {
    let printers = config.printers.reduce((res, printer) => {
      res[printer.id] = printer
      return res
    }, {})
    state.config = {
      ...config,
      printers
    }
  },
  refreshDeviceInfo(state, deviceInfo) {
    state.device = deviceInfo
  },
  toggleMute(state) {
    state.mute = !state.mute
    localStorage.setItem('mute', state.mute)
  },
  updateShipmentProvider(state, shipmentProvider) {
    state.config.shipmentProviders.selected = shipmentProvider.provider
    if ('ownFleetEnabled' in shipmentProvider) {
      state.config.shipmentProviders.providers =
        state.config.shipmentProviders.providers.map(provider => {
          if (provider.name === shipmentProvider.provider)
            provider.config.ownFleetEnabled = shipmentProvider.ownFleetEnabled
          return provider
        })
    }
  },
  updateManualShipment(state, manualShipment) {
    state.config = {
      ...state.config,
      manualShipment
    }
  },
  updateHardwarePreferences(state, hardwarePreferences) {
    state.device = {
      ...state.device,
      ...hardwarePreferences
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
