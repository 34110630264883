<template>
  <div>
    <div class="px-4 py-3 border-t border-b border-gray-200 text-blue">
      <div class="flex justify-between items-center">
        <div>{{ $t('checkout.charge-surcharge-to-tab') }}</div>
        <l-switch :value="isChecked" @input="value => toggleSurcharge(value)" />
      </div>
      <div
        v-if="isChecked"
        class="flex justify-between items-center text-gray-300"
      >
        {{ $t('checkout.terrace-surcharge') }}: +{{
          tab.terraceSurchargePercentage
        }}%
        <div>{{ surchargeAmountForPendingTotal | currency }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LSwitch from '@last/core-ui/components/LSwitch'

export default {
  name: 'TerraceSurcharge',
  props: {
    tab: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isChecked: false
    }
  },
  mounted() {
    this.isChecked = !!this.tab.terraceSurchargePercentage
  },
  emits: ['toggle'],
  methods: {
    toggleSurcharge(value) {
      this.isChecked = value
      this.$emit('toggle', value)
    }
  },
  computed: {
    ...mapGetters('tabs', ['getPendingBill']),
    surchargeAmountForPendingTotal() {
      return this.getPendingBill(this.tab.id)?.terraceSurcharge || 0
    }
  },
  components: {
    LSwitch
  }
}
</script>

<style scoped></style>
