<template>
  <l-modal
    size="extra-small"
    class="py-20 font-body"
    @close="$emit('close')"
    :title="$t('sidemenu.about')"
  >
    <template slot="body">
      <div class="flex justify-center">
        <img src="../assets/logo-dark.svg" class="logo-height" />
      </div>
      <div class="flex justify-center text-blue mt-2">
        {{ $t('sidemenu.version') }}: {{ appVersion }}
      </div>
      <div class="flex justify-center my-6">
        <div>
          <div class="flex items-center">
            <div class="text-gray-400 mr-2">
              {{ $t('sidemenu.software-name') }}:
            </div>
            <div class="text-blue">LastPOS</div>
          </div>
          <div class="flex items-center">
            <div class="text-gray-400 mr-2">
              {{ $t('sidemenu.developer') }}:
            </div>
            <div class="text-blue">Last Systems S.L.</div>
          </div>
          <div class="flex items-center">
            <div class="text-gray-400 mr-2">{{ $t('sidemenu.tax-id') }}:</div>
            <div class="text-blue">B67417758</div>
          </div>
        </div>
      </div>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal'
import { mapGetters } from 'vuex'

export default {
  name: 'AboutPopup',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('config', ['appVersion'])
  },
  methods: {},
  components: {
    LModal
  }
}
</script>

<style scoped></style>
