import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import routes from '@/router/routes.js'
import mobileRoutes from '@/router/mobileRoutes.js'
import app from '@/app.js'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: app.isMobile ? mobileRoutes : routes
})

router.beforeEach(async (to, from, next) => {
  await store.restored
  const needsAuth = !['login', 'signUp', 'supportLogin', 'demoPos'].includes(
    to.name
  )
  const isAuthenticated = store.getters['auth/isAuthenticated']
  const gracePeriodExpired = store.getters['auth/gracePeriodExpired']
  const trialExpired = store.getters['auth/trialExpired']
  if (needsAuth && !isAuthenticated) {
    next({
      name: 'login',
      query: { redirect: to.fullPath }
    })
  } else if (
    needsAuth &&
    gracePeriodExpired &&
    to.name !== 'gracePeriodExpired'
  ) {
    next({
      name: 'gracePeriodExpired'
    })
  } else if (needsAuth && trialExpired && to.name !== 'freeTrialExpired') {
    next({
      name: 'freeTrialExpired'
    })
  } else {
    next()
  }
})

export default router
