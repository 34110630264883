<template>
  <section>
    <l-field
      class="l-field"
      :label="$t('new-delivery.select-enterprise-label')"
    >
      <l-select v-model="internalTab.source" :options="deliveryCompanies" />
    </l-field>
    <l-field class="l-field" :label="$t('new-delivery.client-name-label')">
      <l-input
        v-model="internalCustomer.name"
        :placeholder="$t('new-delivery.client-name-placeholder')"
      />
    </l-field>
    <l-field class="l-field" :label="$t('new-delivery.client-phone-label')">
      <l-input
        type="tel"
        v-model="internalCustomer.phoneNumber"
        :placeholder="$t('new-delivery.client-phone-placeholder')"
      />
    </l-field>
    <l-field
      v-if="internalTab.source !== 'OwnDelivery'"
      class="l-field"
      :label="$t('new-delivery.select-pickup-type-label')"
    >
      <l-select v-model="internalTab.pickupType" :options="pickupTypeOptions" />
    </l-field>
    <div class="input__row">
      <l-field
        class="mr-4 l-field flex-1"
        :label="$t('new-delivery.order-id-label')"
      >
        <l-input
          v-model="internalTab.name"
          :placeholder="$t('new-delivery.order-id-placeholder')"
        />
      </l-field>
      <l-field
        class="l-field pointer flex-1 relative"
        :label="$t('new-delivery.pickup-label')"
      >
        <LDateTimePicker
          @click.stop.native
          v-model="internalTab.schedulingTime"
          :placeholder="$t('new-delivery.schedule-now')"
          :timezone="config.workingTime.timezone"
        />
      </l-field>
    </div>

    <l-field class="l-field" :label="$t('new-delivery.courier-name-label')">
      <l-input
        v-model="internalTab.courierName"
        :placeholder="$t('new-delivery.courier-name-placeholder')"
      />
    </l-field>
    <l-field :label="$t('new-delivery.select-virtual-brand')">
      <l-select
        v-model="internalTab.virtualBrandId"
        :options="enabledBrands"
        option-label="name"
        option-value="id"
      />
    </l-field>
    <l-modal-ctas>
      <l-modal-button
        type="main"
        :label="$t('new-delivery.cta-create')"
        :disabled="!isDeliveryComplete"
        @click.native="next"
      />
    </l-modal-ctas>
  </section>
</template>

<script>
import uuid4 from 'uuid/v4'
import { mapActions, mapState } from 'vuex'

import LField from '@last/core-ui/components/LField.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import LSelect from '@last/core-ui/components/LSelect.vue'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import LDateTimePicker from '@last/core-ui/components/LDateTimePicker'
import newTabMixin from '@/components/ordering/newTabMixin.js'

export default {
  components: {
    LField,
    LInput,
    LSelect,
    LModalCtas,
    LModalButton,
    LDateTimePicker
  },
  mixins: [newTabMixin],
  props: {
    customer: {
      type: Object,
      required: true
    },
    tab: {
      type: Object,
      required: true
    },
    deliveryCompanies: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      internalTab: {},
      internalCustomer: {},
      pickupTypeOptions: [
        {
          label: this.$t('new-delivery.pickup-type-own-delivery'),
          value: 'ownDelivery'
        },
        {
          label: this.$t('new-delivery.pickup-type-delivery'),
          value: 'delivery'
        }
      ]
    }
  },
  computed: {
    ...mapState('config', ['config']),
    isDeliveryComplete() {
      return this.internalTab.source && this.internalTab.name
    }
  },
  mounted() {
    this.internalTab = this.tab
    this.internalCustomer = this.customer
    this.internalTab.id = uuid4()
    this.internalTab.pickupType = 'delivery'
  },
  methods: {
    ...mapActions('tabs', ['openTab']),
    next() {
      if (!this.isDeliveryComplete) return
      if (this.internalTab.pickupType === 'ownDelivery') {
        this.$emit('nextStep', {
          customer: this.internalCustomer,
          pickupType: 'ownDelivery'
        })
      } else {
        let { courierName, pickupTime, ...tab } = this.internalTab
        tab.deliveryOrder = {
          courierName,
          pickupTime,
          deliveryOrderStatuses: [
            {
              status: 'CREATED',
              creationTime: new Date()
            }
          ]
        }
        tab.customerInfo = this.internalCustomer
        tab.pickupType = this.internalTab.pickupType || 'delivery'
        this.openTab({ tableId: null, tab })
        this.$emit('close')
        this.$router.push({
          name: 'orderManagement',
          params: { tabId: this.internalTab.id }
        })
      }
    }
  },
  watch: {
    'internalTab.pickupType': function() {
      this.$emit('update:tab', this.internalTab)
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  &__row {
    display: flex;
    justify-content: space-between;
  }
}

.cta {
  display: flex;
  position: absolute;
  right: 0;
  bottom: -66px;
}
.disabled {
  opacity: 0.5;
}
.l-field {
  padding-bottom: 0;
}
</style>
