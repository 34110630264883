export default class PromotionProductsApplier {
  constructor(availablePromotions, products) {
    this.availablePromotions = availablePromotions
    this.products = products
  }

  updateDiscountInProducts(products) {
    const productsAvailable = this.filterProductsWithManualPromotions(products)
    const productsWithoutDiscount =
      this.removeDiscountInProducts(productsAvailable)
    const productsWithNewDiscount = this.addDiscountInProducts(
      productsWithoutDiscount
    )

    const productsChanged = this.productsDiscountDiff(
      products,
      productsWithNewDiscount
    )

    return productsChanged
  }

  filterProductsWithManualPromotions(products) {
    return products.filter(
      product => product.promotionId || !product.discountType
    )
  }

  removeDiscountInProducts(products) {
    return products.map(product => ({
      ...product,
      finalPrice: product.fullPrice,
      discountType: null,
      discountAmount: product.discountAmount === 0 ? 0 : null,
      discountConcept: null
    }))
  }

  addDiscountInProducts(products) {
    let productsWithNewDiscount = []
    let productsPendingToDiscount = products
    let catalogProducts = this.products
    this.availablePromotions
      .filter(promotion => {
        return ['2x1', 'percentage'].includes(promotion.discountType)
      })
      .forEach(promotion => {
        let promotionProducts = productsPendingToDiscount.filter(product => {
          let parentProduct = catalogProducts[product.parentProduct]
          if (!parentProduct) {
            parentProduct = catalogProducts[product.id]
          }
          if (!parentProduct || product.finalPrice === 0) return false
          if (promotion.products) {
            return promotion.products.includes(
              parentProduct.organizationProductId ||
                parentProduct.organizationComboId
            )
          } else if (promotion.categories) {
            return promotion.categories.includes(parentProduct.categoryId)
          }
        })

        const promotionInstance = PromotionFactory.getPromotion(promotion)

        const productsWithPromotionApplied =
          promotionInstance.applyPromotionIntoProducts(promotionProducts)

        const productsWithPromotionAppliedIds =
          productsWithPromotionApplied.map(product => product.id)

        productsPendingToDiscount = productsPendingToDiscount.filter(
          product => !productsWithPromotionAppliedIds.includes(product.id)
        )

        productsWithNewDiscount = productsWithNewDiscount.concat(
          productsWithPromotionApplied
        )
      })
    return productsWithNewDiscount.concat(productsPendingToDiscount)
  }

  productsDiscountDiff(oldProducts, newProducts) {
    const oldProductsMap = oldProducts.reduce((acc, product) => {
      acc[product.id] = product
      return acc
    }, {})

    return newProducts.filter(newProduct => {
      const oldProduct = oldProductsMap[newProduct.id]
      return (
        oldProduct.discountType !== newProduct.discountType ||
        oldProduct.discountAmount !== newProduct.discountAmount
      )
    })
  }
}

class PromotionFactory {
  static getPromotion(promotion) {
    switch (promotion.discountType) {
      case '2x1':
        return new Promotion2x1(promotion)
      case 'percentage':
        return new PromotionPercentage(promotion)
    }
  }
}

class Promotion2x1 {
  constructor(promotion) {
    this.promotion = promotion
  }
  applyPromotionIntoProducts(promotionProducts) {
    const promotionProductsSortedByPrice = promotionProducts.sort(
      (a, b) => b.fullPrice - a.fullPrice
    )
    const productsWithPromotionApplied = []
    let alreadyApplied = 0
    let paidProducts = 0
    for (let i = 0; i < promotionProducts.length; ++i) {
      if (!alreadyApplied) {
        let product = promotionProductsSortedByPrice[i]
        if (paidProducts + product.quantity > 1) {
          let discount = {
            discountType: '2x1',
            discountAmount: this.promotion.allowRepeat
              ? Math.floor((product.quantity + paidProducts) / 2)
              : 1,
            discountConcept: this.promotion.name,
            promotionId: this.promotion.promotionId
          }
          productsWithPromotionApplied.push({ ...product, ...discount })
          alreadyApplied = this.promotion.allowRepeat ? 0 : 1
        } else {
          productsWithPromotionApplied.push(product)
        }
        paidProducts = (paidProducts + product.quantity) % 2
      }
    }
    return productsWithPromotionApplied
  }
}

class PromotionPercentage {
  constructor(promotion) {
    this.promotion = promotion
  }
  applyPromotionIntoProducts(promotionProducts) {
    return promotionProducts.map(promotionProduct => {
      return {
        ...promotionProduct,
        discountType: 'percentage',
        discountAmount: this.promotion.discountAmount,
        discountConcept: this.promotion.name,
        promotionId: this.promotion.promotionId
      }
    })
  }
}
