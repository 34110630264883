<template>
  <div
    class="border-l-4 border-white cursor-pointer"
    :class="{ 'bg-gray-selected border-red': sectionSelected }"
  >
    <div
      v-if="!hideSectionName"
      class="flex items-center justify-between p-4 text-xl font-bold uppercase text-blue font-title seat-header"
      :class="{ 'bg-gray-200': sectionSelected }"
      @click="$emit('selectedSection')"
    >
      <div class="flex flex-row items-center">
        <l-radio
          v-if="showSelectRadio"
          :value="sectionSelected"
          class="mr-4"
          @input="$emit('selectedSection')"
        />
        {{ sectionName }}
      </div>
      <icon
        v-if="showRemove"
        name="trash"
        class="justify-end text-gray-400"
        @click.native.stop="$emit('onRemove')"
      />
    </div>
    <draggable
      :value="elements"
      @update="event => $emit('onUpdate', event)"
      @add="event => $emit('onAdd', event)"
      class="grow-draggable seat"
      draggable=".tab-product"
      :empty-insert-threshold="40"
      group="products"
      :force-fallback="true"
      :delay="delayTime"
      :delay-on-touch-only="false"
      :touch-start-threshold="5"
      @start="$emit('draggableStart')"
      @end="$emit('draggableEnd')"
      @choose="sendVibration"
    >
      <transition-group name="list">
        <slot />
      </transition-group>
    </draggable>
    <div :ref="sectionName" />
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import LRadio from '@/components/LRadio.vue'
import Icon from '@last/core-ui/components/Icon'
import { Haptics } from '@capacitor/haptics'

export default {
  name: 'LSectionSideMenu',
  components: {
    Draggable,
    LRadio,
    Icon
  },
  props: {
    showRemove: {
      type: Boolean,
      default: false
    },
    showSelectRadio: {
      type: Boolean,
      default: true
    },
    sectionName: {
      type: String,
      default: ''
    },
    hideSectionName: {
      type: Boolean,
      default: false
    },
    sectionSelected: {
      type: Boolean,
      default: false
    },
    elements: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedSection: null,
      delayTime: 300
    }
  },
  computed: {
    elementsLength() {
      return this.elements.length
    }
  },
  methods: {
    sendVibration() {
      try {
        Haptics.vibrate()
      } catch {
        //no vibration
      }
    }
  },
  watch: {
    elementsLength() {
      let scrollTarget = this.$refs[this.sectionName]
      this.$emit('scroll', scrollTarget)
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-gray-selected {
  background-color: #f3f3f8;
}

.transition {
  transition: all 0.3s;
}
.list-enter-active,
.list-leave-active {
  max-height: 400px;
  overflow: hidden;
  transition: all 0.3s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  max-height: 0px;
  transform: translateX(30px);
}

.grow-draggable > span {
  display: block;
  width: 100%;
}

:deep .sortable-fallback {
  background-color: white;
  opacity: 1 !important;
  box-shadow: 2px 4px 6px -1px rgba(0, 0, 0, 0.1),
    2px 2px 4px -1px rgba(0, 0, 0, 0.06);
}
</style>
