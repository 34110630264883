<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen z-20 blur-background overflow-hidden flex justify-center items-center"
    @click="$emit('close')"
  >
    <div class="p-20 max-h-full overflow-y-scroll scrolling-touch">
      <div class="text-white uppercase text-2xl text-center pb-6">
        {{ $t('ordering.new-open-product') }}
      </div>
      <div
        class="bg-white rounded-big shadow p-6 relative dialog-w"
        @click.stop
      >
        <icon
          name="close"
          class="text-gray-400 absolute top-0 right-0 m-4"
          @click.native="previewBill ? (previewBill = null) : $emit('close')"
        />
        <div class="p-8 flex">
          <div class="w-2/3">
            <l-input
              v-model="openProduct.name"
              :placeholder="$t('ordering.name')"
            />
          </div>
          <div class="pl-4 w-1/3">
            <l-input
              v-model="openProduct.price"
              :placeholder="$t('ordering.price')"
              is-currency
            />
          </div>
        </div>
        <div v-if="priceError" class="px-8 text-sm text-red text-center">
          {{ $t('ordering.invalid-price') }}
        </div>
        <div v-if="nameError" class="px-8 text-sm text-red text-center">
          {{ $t('ordering.invalid-name') }}
        </div>
      </div>
      <div class="flex pt-4 justify-end">
        <div
          class="p-3 px-4 text-white bg-red border border-red-b uppercase rounded-small font-bold ml-4 text-center"
          @click.stop="save()"
        >
          {{ $t('ordering.create') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LInput from '@last/core-ui/components/LInput.vue'
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'OpenProduct',
  data() {
    return {
      openProduct: {
        name: '',
        price: null
      },
      priceError: false,
      nameError: false
    }
  },
  methods: {
    save() {
      let price = this.openProduct.price
      this.priceError = false
      this.nameError = false
      if (!this.openProduct.price || this.openProduct.price < 0) {
        this.priceError = true
      } else if (!this.openProduct.name) {
        this.nameError = true
      } else {
        let product = {
          ...this.openProduct,
          price
        }
        this.$emit('save', product)
      }
    }
  },
  components: {
    Icon,
    LInput
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}
.dialog-w {
  width: 484px;
}
</style>
