<template>
  <section class="client-details">
    <div class="client-details__form">
      <h2 class="text-blue">
        {{ $t('new-delivery.basic-info-title') }}
      </h2>
      <div class="flex">
        <l-field
          class="flex-1 mr-4 l-field"
          :label="$t('new-delivery.client-name-label')"
        >
          <h3 class="pb-4" v-if="!edit">{{ internalCustomer.name }}</h3>
          <l-input
            v-model="internalCustomer.name"
            v-if="edit"
            :placeholder="$t('new-delivery.client-name-placeholder')"
          />
        </l-field>
        <l-field
          class="flex-2 l-field"
          :label="$t('new-delivery.client-last-name-label')"
        >
          <h3 class="pb-4" v-if="!edit">{{ internalCustomer.surname }}</h3>
          <l-input
            v-model="internalCustomer.surname"
            v-if="edit"
            :placeholder="$t('new-delivery.client-last-name-placeholder')"
          />
        </l-field>
      </div>
      <div class="flex" ref="phoneNumber">
        <l-field
          class="flex-1 mr-4 l-field"
          :label="$t('new-delivery.client-phone-label')"
          mandatory
        >
          <h3 class="pb-4" v-if="!edit">{{ internalCustomer.phoneNumber }}</h3>
          <l-input
            v-model="internalCustomer.phoneNumber"
            type="tel"
            v-if="edit"
            :placeholder="$t('new-delivery.client-phone-placeholder')"
            :wrong-value="phoneError"
          />
        </l-field>
        <l-field
          class="flex-2 l-field"
          :label="$t('new-delivery.client-email-label')"
        >
          <h3 class="pb-4" v-if="!edit">{{ internalCustomer.email }}</h3>
          <l-input
            type="email"
            v-model="internalCustomer.email"
            v-if="edit"
            :placeholder="$t('new-delivery.client-email-placeholder')"
          />
        </l-field>
      </div>
      <l-field
        v-if="!isEdit"
        class="flex-1 l-field"
        :label="$t('new-delivery.internal-note')"
      >
        <h3 class="pb-4" v-if="!edit">
          {{ internalCustomer.internalNote || '-' }}
        </h3>
        <textarea
          v-if="edit"
          v-model="internalCustomer.internalNote"
          class="border border-gray-400 client-details__textarea"
          maxlength="255"
        ></textarea>
      </l-field>
      <div
        class="flex"
        v-if="config.organizationConfig.promotions && internalCustomer.points"
      >
        <div class="flex-1 flex-col mr-4">
          <div class="text-gray-400">
            {{ $t('new-delivery.actual-points') }}
          </div>
          <div class="text-green">
            {{ internalCustomer.points.actualPoints || 0 }}
          </div>
        </div>
        <div class="flex-2 flex-col">
          <div class="text-gray-400">
            {{ $t('new-delivery.historic-points') }}
          </div>
          <div class="text-green">
            {{ internalCustomer.points.earnedPoints || 0 }}
          </div>
        </div>
      </div>
      <div class="flex justify-end mt-4 mb-10">
        <div
          v-if="!edit"
          class="cursor-pointer flex items-center px-10 py-2 mr-4 text-sm font-bold text-white uppercase border border-blue-600 bg-blue rounded-small done-button"
          @click="startCustomerEdit"
        >
          {{ $t('new-delivery.cta-edit') }}
        </div>
        <div class="flex">
          <div
            v-if="isCustomerFilled"
            @click="cancelCustomerEdit"
            :class="{ disabled: !isCustomerFilled }"
            class="cursor-pointer flex items-center px-6 py-2 mr-4 text-sm font-bold text-blue border border-blue-600 uppercase rounded-small done-button mr-4"
          >
            {{ $t('ctas.cancel') }}
          </div>
          <div
            @click="switchToPreview"
            :class="{ disabled: !isCustomerFilled }"
            class="cursor-pointer flex items-center px-6 py-2 mr-4 text-sm font-bold text-white uppercase border border-blue-600 bg-blue rounded-small done-button"
          >
            {{ $t('ctas.apply') }}
          </div>
        </div>
      </div>
    </div>
    <div ref="delivery">
      <h2 class="text-blue" v-if="showDelivery">
        {{ $t('new-delivery.delivery-address-title') }}
      </h2>
      <delivery-address-picker
        v-if="showDelivery"
        v-model="internalCustomer.addresses"
        :hidde-new-address-cta="isEdit"
        :address-missing="deliveryError"
        @deliveryAddressSelected="setDeliveryAddressOnTab"
      />
    </div>
    <div class="pt-10 client-details__form" ref="seats">
      <h2 class="text-blue">{{ $t('new-delivery.tab-details') }}</h2>
      <section class="flex">
        <l-field
          v-if="!isEdit"
          class="flex-1 l-field"
          :label="$t('new-delivery.client-number-label')"
          :mandatory="seatsRequired"
        >
          <div class="flex flex-col w-full">
            <l-select
              v-model="internalTab.seats"
              :options="numberOfPeople"
              :red-border="dinersError"
              :allow-remove="!seatsRequired"
            />
            <div v-if="dinersError" class="text-xs text-red">
              *{{ $t('new-delivery.diners-missing') }}
            </div>
          </div>
        </l-field>
        <l-field
          class="relative flex-1 l-field pointer"
          :class="{ 'ml-4': !isEdit }"
          :label="$t('new-delivery.pickup-label')"
        >
          <LDateTimePicker
            @click.stop.native
            @input="schedulingTimeEdited = true"
            v-model="internalTab.schedulingTime"
            :placeholder="$t('new-delivery.schedule-now')"
            :timezone="config.workingTime.timezone"
          />
        </l-field>
      </section>
    </div>
    <div class="pt-20 client-details__form">
      <h2 class="text-blue">{{ $t('new-delivery.tab-notes') }}</h2>
      <l-field
        class="flex-1 l-field"
        :label="$t('new-delivery.customer-notes')"
      >
        <textarea
          v-model="internalTab.customerNote"
          class="border border-gray-400 client-details__textarea"
          maxlength="255"
        ></textarea>
      </l-field>
      <l-field class="flex-1 l-field" :label="$t('new-delivery.kitchen-notes')">
        <textarea
          v-model="internalTab.kitchenNote"
          class="border border-gray-400 client-details__textarea"
          maxlength="255"
        ></textarea>
      </l-field>
    </div>
    <div v-if="lastCustomerTabs" class="pt-20 client-details__form">
      <h2 class="text-blue">{{ $t('new-delivery.last-customer-tabs') }}</h2>
      <div
        class="last-tabs--empty bg-gray-100 w-full rounded text-blue"
        v-if="lastCustomerTabs.length === 0"
      >
        {{ $t('new-delivery.last-customer-tabs-empty') }}
      </div>

      <div v-else class="last-tabs">
        <div
          v-for="tab in lastCustomerTabs"
          :key="tab.id"
          class="last-tabs__card text-blue"
          :class="{
            'last-tabs__card--selected': selectedLastTabsId === tab.id
          }"
          @click="setTheLastTab(tab)"
        >
          <section>
            <div class="last-tabs__card__top">
              <div class="last-tabs__card__date">
                {{ tab.creationTime | day }}
              </div>
              <div class="last-tabs__card__time">
                {{ tab.creationTime | time }}
              </div>
            </div>
            <div class="last-tabs__card__body">
              <div
                v-for="product in tab.products"
                :key="product.id"
                class="last-tabs__card__product"
              >
                <div class="mr-3 last-tabs__card__product__left text-red">
                  {{ product.quantity }}
                </div>
                <div class="last-tabs__card__product__right">
                  {{ product.name }}
                </div>
              </div>
            </div>
          </section>
          <div class="last-tabs__card__footer">
            <div class="last-tabs__card__price-label">
              {{ $t('new-delivery.total') }}
            </div>
            <div class="last-tabs__card__price text-red">
              {{ tab.total | currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-red text-xs mt-5">
      * {{ $t('new-delivery.mandatory-fields') }}
    </div>
    <l-modal-ctas>
      <l-modal-button
        v-if="!hiddeBackButton"
        type="secondary"
        :label="$t('ctas.back')"
        @click.native="
          $emit('back', {
            customer: { phoneNumber: internalCustomer.phoneNumber },
            tab: internalTab,
            delivery: internalDelivery
          })
        "
        class="mr-4"
      />
      <l-modal-button
        v-if="!isEdit"
        type="main"
        :label="$t('new-delivery.cta-create')"
        @click.native="createNewTabAndUpdateUserIfNeeded"
      />
      <l-modal-button
        v-else
        type="main"
        :label="$t('ctas.update')"
        @click.native="updateTabAndUpdateUserIfNeeded"
      />
    </l-modal-ctas>
  </section>
</template>

<script>
import uuid4 from 'uuid/v4'
import { mapActions, mapState, mapGetters } from 'vuex'

import sync from '@/sync/service.js'
import LInput from '@last/core-ui/components/LInput'
import LField from '@last/core-ui/components/LField'
import LSelect from '@last/core-ui/components/LSelect'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import LDateTimePicker from '@last/core-ui/components/LDateTimePicker'
import newTabMixin from '@/components/ordering/newTabMixin.js'
import DeliveryAddressPicker from '@/components/tabs/DeliveryAddressPicker'
import TicketPrinter from '@/ticketPrinter.js'
import moment from 'moment'

export default {
  name: 'ClientDetails',
  components: {
    LInput,
    LSelect,
    LField,
    LModalCtas,
    LModalButton,
    LDateTimePicker,
    DeliveryAddressPicker
  },
  mixins: [newTabMixin],
  props: {
    newTab: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    customer: {
      type: Object,
      default: () => ({})
    },
    delivery: {
      type: Object,
      default: () => undefined
    },
    lastCustomerTabs: {
      type: Array,
      default: () => undefined
    },
    hiddeBackButton: {
      type: Boolean,
      default: false
    },
    isOwnDelivery: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedTab: {},
      selectedLastTabsId: null,
      edit: false,
      internalCustomer: {},
      temporallyCustomer: {},
      internalDelivery: {},
      internalTab: {},
      deliveryError: false,
      dinersError: false,
      phoneError: false,
      schedulingTimeEdited: false
    }
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapGetters('tabs', [
      'getTabPreparationMinutes',
      'getBills',
      'getGlobalDiscountByTabId'
    ]),
    ...mapGetters('billing', ['getCurrentBill']),
    billNeedsRegeneration() {
      return (
        this.customer.name !== this.internalCustomer.name ||
        this.customer.surname !== this.internalCustomer.surname ||
        this.customer.phoneNumber !== this.internalCustomer.phoneNumber ||
        this.customer.email !== this.internalCustomer.email ||
        this.customer.addresses[0].address !==
          this.internalCustomer.addresses[0].address ||
        this.customer.addresses[0].details !==
          this.internalCustomer.addresses[0].details ||
        this.customer.addresses[0].postalCode !==
          this.internalCustomer.addresses[0].postalCode
      )
    },
    getPickupTime() {
      let preparationMinutes = this.getTabPreparationMinutes(
        this.internalTab.virtualBrandId
      )
      let asap = moment().add(preparationMinutes || 15, 'minutes')
      let pickupTime = this.internalTab.schedulingTime
        ? ['delivery', 'ownDelivery'].includes(this.internalTab.pickupType)
          ? moment(this.internalTab.schedulingTime).subtract(15, 'minutes')
          : moment(this.internalTab.schedulingTime)
        : asap
      if (asap > pickupTime) {
        pickupTime = asap
      }
      return pickupTime.toDate()
    },
    showDelivery() {
      return ['delivery', 'ownDelivery'].includes(this.internalTab.pickupType)
    },
    isCustomerFilled() {
      return (
        this.internalCustomer &&
        this.internalCustomer.name &&
        this.internalCustomer.surname &&
        this.internalCustomer.phoneNumber
      )
    },
    mainCTADisabled() {
      return (
        ((this.seatsRequired && !this.internalTab.seats) ||
          !this.internalCustomer.addresses ||
          this.internalCustomer.addresses.length < 1 ||
          (this.isOwnDelivery && !this.internalCustomer.phoneNumber)) &&
        this.showDelivery
      )
    },
    seatsRequired() {
      return this.isOwnDelivery && this.config.organizationConfig.seatsRequired
    }
  },
  mounted() {
    this.$nextTick(this.setBasicInformationSection)
    this.internalCustomer = { ...this.customer }
    this.internalDelivery = { ...this.delivery }
    this.internalTab = { ...this.internalTab, ...this.newTab }
    if (!this.isEdit) this.internalTab.id = uuid4()
  },
  methods: {
    ...mapActions('tabs', [
      'openTab',
      'openTabWithCustomer',
      'removeTabBills',
      'generatePendingBill'
    ]),
    startCustomerEdit() {
      this.temporallyCustomer = { ...this.internalCustomer }
      this.edit = true
    },
    cancelCustomerEdit() {
      this.internalCustomer = {
        ...this.internalCustomer,
        ...this.temporallyCustomer
      }
      this.edit = false
    },
    setDeliveryAddressOnTab(address) {
      this.internalDelivery = { ...this.internalDelivery, ...address }
    },
    setBasicInformationSection() {
      if (!this.isCustomerFilled) this.edit = true
    },
    handleWrongValues() {
      let scrollContainer
      if (
        !this.internalCustomer.addresses ||
        this.internalCustomer.addresses.length < 1
      ) {
        this.deliveryError = true
        scrollContainer = this.$refs.delivery
      } else if (this.seatsRequired && !this.internalTab.seats) {
        this.dinersError = true
        scrollContainer = this.$refs.seats
      } else if (this.isOwnDelivery && !this.internalCustomer.phoneNumber) {
        this.phoneError = true
        scrollContainer = this.$refs.phoneNumber
      }
      if (scrollContainer) {
        scrollContainer.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    },
    async createNewTab() {
      this.deliveryError = false
      this.dinersError = false
      if (this.mainCTADisabled) {
        this.handleWrongValues()
        return
      }
      let tab = {
        ...this.internalTab,
        deliveryOrder: {
          ...this.internalTab.deliveryOrder,
          ...this.internalDelivery,
          addressDetails: this.internalDelivery.details,
          source: this.internalTab.source,
          status: 'CREATED',
          deliveryOrderStatuses: [
            {
              status: 'CREATED',
              creationTime: new Date()
            }
          ],
          pickupTime: this.getPickupTime
        },
        customerInfo: this.internalCustomer
      }
      let tabId = await this.openTab({
        tableId: null,
        tab
      })
      this.$emit('tabCreated', tabId)
      this.$emit('close')
      this.$router.push({
        name: 'orderManagement',
        params: { tabId: this.internalTab.id }
      })
    },
    async createNewTabAndUpdateUserIfNeeded() {
      this.deliveryError = false
      this.dinersError = false
      this.phoneError = false
      if (this.mainCTADisabled) {
        this.handleWrongValues()
        return
      }
      let { isCreated, addresses, points, ...customer } = this.internalCustomer
      const { address, details, postalCode } = this.internalDelivery

      if (!this.isEdit) customer.addresses = addresses

      customer.source = this.internalTab.source
      customer = {
        ...customer,
        address,
        addressDetails: details,
        postalCode
      }
      if (!isCreated) {
        customer.id = uuid4()
      }

      this.internalTab.customerId = customer.id
      this.internalTab.name = this.internalTab.name || customer.name
      let tab = {
        ...this.internalTab,
        deliveryOrder: {
          ...this.internalTab.deliveryOrder,
          ...this.internalDelivery,
          addressDetails: this.internalDelivery.details,
          source: this.internalTab.source,
          status: 'CREATED',
          deliveryOrderStatuses: [
            {
              status: 'CREATED',
              creationTime: new Date()
            }
          ],
          pickupTime: this.getPickupTime
        },
        customerInfo: this.internalCustomer
      }
      let tabId = await this.openTabWithCustomer({
        tableId: null,
        tab,
        customer
      })
      this.$emit('tabCreated', tabId)
      this.$emit('close')
      this.$router.push({
        name: 'orderManagement',
        params: { tabId: this.internalTab.id }
      })
    },
    updateTabAndUpdateUserIfNeeded() {
      this.deliveryError = false
      this.dinersError = false
      if (this.mainCTADisabled) {
        this.handleWrongValues()
        return
      }

      this.internalTab.customerInfo = this.internalCustomer
      this.internalTab.deliveryOrder = {
        ...this.internalDelivery,
        addressDetails: this.internalDelivery.details
      }

      if (this.schedulingTimeEdited) {
        sync.record('deliveryOrderStatusUpdated', {
          tabId: this.internalTab.id,
          newStatus: 'CREATED',
          date: new Date()
        })
        this.internalTab = {
          ...this.internalTab,
          activationTime: null,
          deliveryOrder: {
            ...this.internalTab.deliveryOrder,
            pickupTime: this.getPickupTime
          }
        }
      }
      sync.record('tabDetailsUpdated', this.internalTab)
      if (this.billNeedsRegeneration) {
        this.removeTabBills(this.internalTab.id)
        this.printBill()
      }
      this.$emit('close')
    },
    printBill() {
      this.generatePendingBill({
        tabId: this.internalTab.id,
        bill: this.getCurrentBill({
          tabId: this.internalTab.id,
          discount: this.getGlobalDiscountByTabId(this.internalTab.id),
          selectedProductIds: this.internalTab.products,
          includeAlreadyBilled: true
        })
      })
      let bills = this.getBills(this.internalTab.id)
      bills.forEach(TicketPrinter.printBill)
    },
    switchToPreview() {
      if (!this.isCustomerFilled) return
      this.edit = false
    },
    setTheSharedProductsIntoTheNewTab(tab) {
      this.internalTab.shared = tab.shared
        .filter(product => product.price > 0)
        .map(product => {
          let mappedProduct = {
            ...product,
            id: uuid4(),
            tab: this.internalTab.id,
            tabId: this.internalTab.id,
            modifiers: (product.modifiers || []).map(modifier => ({
              ...modifier,
              id: uuid4()
            }))
          }
          if (product.comboProducts) {
            mappedProduct.comboProducts = product.comboProducts.map(
              comboProduct => {
                return {
                  ...comboProduct,
                  id: uuid4(),
                  modifiers: (comboProduct.modifiers || []).map(modifier => ({
                    ...modifier,
                    id: uuid4()
                  }))
                }
              }
            )
          }
          return mappedProduct
        })
    },
    setTheLastTab(tab) {
      this.setTheSharedProductsIntoTheNewTab(tab)
      this.internalTab.kitchenNote = tab.kitchenNote
      this.internalTab.customerNote = tab.customerNote
      this.selectedLastTabsId = tab.id
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

.client-details {
  &__tabs {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 64px;
    align-items: flex-end;
    border-radius: 20px 20px 0 0;
    -ms-overflow-style: none;
    font-size: 14px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
  &__textarea {
    width: 100%;
    min-height: 120px;
    border-radius: 10px;
    margin-top: 10px;
    padding: 20px;
    margin-bottom: 20px;
  }
}

.last-tabs {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  &__card {
    flex-basis: 250px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 220px;
    width: 250px;
    margin: 10px;
    border-radius: 15px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--selected {
      border: 1px solid #ff7878;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px 0 10px;
    }

    &__date {
      font-size: 16px;
      font-weight: bold;
    }

    &__body {
      padding: 20px 20px 10px 20px;
      height: 110px;
      overflow-y: scroll;
    }

    &__product {
      display: flex;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #e4e4eb;
      padding: 14px 20px;
    }

    &__price-label {
      text-transform: uppercase;
    }

    &__price {
      font-weight: bold;
      font-size: 24px;
    }
  }

  &--empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 20px;
  }
}

h2 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.flex-2 {
  flex: 2;
}

.disabled {
  opacity: 0.5;
}
.l-field {
  padding-bottom: 0;
}
.line-1 {
  line-height: 1;
}
</style>
