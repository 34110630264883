import Bills from '@last/core/src/billsGenerator.js'

const state = {}
const getters = {
  getCurrentBill:
    (state, getters, rootState, rootGetters) =>
    ({
      tabId,
      currentDiscount,
      selectedProductIds = [],
      includeAlreadyBilled = false
    }) => {
      let selectedProducts = []
      if (selectedProductIds.length > 0) {
        let tabProducts = rootGetters['tabs/getAllProducts'](tabId)
        selectedProducts = Object.values(
          selectedProductIds
            .map(selection => {
              let product = tabProducts.find(
                product => product.id === selection.id
              )
              if (product.discountType === '2x1') {
                product.discountType = null
                product.discountAmount = null
              }
              return product
            })
            .reduce((products, product) => {
              products[product.id] = products[product.id] || {
                ...product,
                quantity: 0
              }
              products[product.id].quantity += 1
              return products
            }, {})
        )
      } else {
        selectedProducts = rootGetters['tabs/getAllProducts'](tabId).map(
          product => {
            return {
              ...product,
              quantity: includeAlreadyBilled
                ? product.quantity
                : product.notBilledQuantity ?? 0
            }
          }
        )
      }
      return Bills.generateProductsBill({
        products: selectedProducts,
        company: rootState.config.config.company,
        tab: rootState.tabs.tabs[tabId],
        discount: currentDiscount,
        taxRate: rootState.config.config.taxRate,
        ticketInfo: rootState.config.config.ticketInfo
      })
    }
}
const actions = {}
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
