import uuid4 from 'uuid/v4'

let mixin = {
  methods: {
    onSelectProductCourse(course) {
      this.product.course = course
    },
    toggleModifier(group, modifier) {
      if (!group.multipleSelection) {
        let groupModifiers = group.modifiers.map(m => m.id)
        this.selectedModifiers = this.selectedModifiers.filter(
          modifierId => !groupModifiers.includes(modifierId)
        )
      }
      if (this.isModifierSelected(modifier)) {
        this.selectedModifiers = this.selectedModifiers.filter(
          n => n != modifier.id
        )
      } else {
        this.selectedModifiers.push(modifier.id)
      }
    },
    isModifierSelected(modifier) {
      return this.selectedModifiers.includes(modifier.id)
    },
    isCourseSelected(course) {
      return this.product.course == course
    },
    updateSelectedModifiers() {
      let groupQuantity = this.product.modifierGroups
        ? this.product.modifierGroups.length
        : 0
      this.selectedModifiers = Array.from({ length: groupQuantity }, () => ({}))
      if (this.product.modifiers) {
        let modifierIndexes = (this.product.modifierGroups || [])
          .flatMap((group, index) =>
            group.modifiers.map(modifier => ({ id: modifier.id, index }))
          )
          .reduce((res, modifier) => {
            res[modifier.id] = modifier.index
            return res
          }, {})
        this.product.modifiers
          .filter(modifier => modifier.parentModifierId)
          .forEach(modifier => {
            let index = modifierIndexes[modifier.parentModifierId]
            this.selectedModifiers[index][modifier.parentModifierId] =
              modifier.quantity
          })
        let modifierIds = new Set(Object.keys(modifierIndexes))
        this.openModifiers = this.product.modifiers.filter(
          modifier =>
            !modifier.parentModifierId ||
            !modifierIds.has(modifier.parentModifierId)
        )
      } else {
        this.openModifiers = []
      }
      this.comments = this.product.comments
      this.quantity = this.product.quantity
    },
    newOpenModifier() {
      this.openModifier = {
        name: null,
        priceImpact: 0
      }
      this.priceError = false
      this.nameError = false
      this.showingOpenModifier = true
    },
    saveOpenModifier() {
      this.priceError = false
      this.nameError = false
      let modifier = {
        id: uuid4(),
        ...this.openModifier,
        priceImpact: this.openModifier.priceImpact
      }
      if (modifier.priceImpact === null) {
        this.priceError = true
      } else if (!modifier.name) {
        this.nameError = true
      } else {
        this.openModifiers.push(modifier)
        this.showingOpenModifier = false
      }
    },
    deleteOpenModifier(id) {
      this.openModifiers = this.openModifiers.filter(
        modifier => modifier.id != id
      )
    },
    updateQuantity(quantity) {
      this.quantity = quantity
      if (this.product.id) {
        this.updateProductQuantity({
          productId: this.product.id,
          quantity
        })
      }
    }
  },
  computed: {
    allSelectedModifiers() {
      let selectedModifiers = this.selectedModifiers
        .flatMap(groupModifiers =>
          Object.keys(groupModifiers).map(modifierId => ({
            parentModifierId: modifierId,
            quantity: groupModifiers[modifierId]
          }))
        )
        .reduce((res, modifier) => {
          res[modifier.parentModifierId] = modifier
          return res
        }, {})
      let sortedModifiers = (this.product.modifierGroups || [])
        .flatMap(group => group.modifiers)
        .filter(modifier => selectedModifiers[modifier.id])
        .map(modifier => ({
          id: uuid4(),
          name: modifier.name,
          priceImpact: modifier.priceImpact,
          parentModifierId: modifier.id,
          quantity: selectedModifiers[modifier.id].quantity
        }))
      return [...sortedModifiers, ...this.openModifiers]
    },
    allCourses() {
      return (
        Object.values(this.catalogs)[0]?.courses.map(course => ({
          label: course,
          value: course
        })) || []
      )
    },
    tabs() {
      return [
        ...(this.product.modifierGroups || []).map(modifierGroup => ({
          name: modifierGroup.name,
          tabType: modifierGroup.id
        })),
        {
          name: this.$t('ordering.open-modifiers'),
          tabType: this.TAB_OPEN_ATTRIBUTE
        },
        {
          name: this.$t('ordering.comments'),
          tabType: this.TAB_COMMENT
        }
      ]
    },
    modifierGroup() {
      return (
        this.product.modifierGroups.find(
          modifierGroup => modifierGroup.id === this.selectedTab.tabType
        ) || {}
      )
    },
    indexOfSelectedModifier() {
      return this.tabs.map(tab => tab.tabType).indexOf(this.modifierGroup.id)
    }
  }
}

export default mixin
