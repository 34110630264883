<template>
  <l-modal
    v-if="isActive"
    @close="$emit('update:isActive', false)"
    title="Assign a table"
  >
    <template slot="body">
      <option-selector
        :selected.sync="selectedTables"
        :options="availableTables"
      />
      <l-modal-ctas>
        <l-modal-button
          class="flex-1 mr-2"
          type="secondary"
          :label="$t('ctas.cancel')"
          @click.native="$emit('update:isActive', false)"
        />
        <l-modal-button
          class="flex-1"
          type="main"
          :label="$t('ctas.save')"
          @click.native="save()"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal.vue'
import OptionSelector from './OptionSelector.vue'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'AssignTable',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    tabId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedTables: []
    }
  },
  mounted() {
    this.selectedTables = this.tabs[this.tabId].tables
  },
  methods: {
    ...mapActions('tabs', ['assignTables']),
    async save() {
      this.assignTables({ tabId: this.tabId, tables: this.selectedTables })
      this.$emit('update:isActive', false)
    }
  },
  computed: {
    ...mapGetters('tables', ['getAllTables']),
    ...mapState('tabs', ['tabs']),
    availableTables() {
      return this.getAllTables
        .filter(
          table =>
            table.tabIds.length === 0 || table.tabIds.includes(this.tabId)
        )
        .filter(table => table.name && table.type != 'wall')
        .map(table => {
          return {
            label: table.name,
            value: table.id
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    }
  },
  components: {
    LModal,
    OptionSelector,
    LModalCtas,
    LModalButton
  },
  watch: {
    tabId(id) {
      this.selectedTables = this.tabs[id].tables
    }
  }
}
</script>

<style lang="scss" scoped></style>
