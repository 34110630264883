var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen flex flex-col justify-center items-center bg-cover bg-image overflow-y-scroll scrolling-touch relative w-full overflow-hidden"},[(_vm.selectedCashTill)?_c('div',{staticClass:"flex flex-col items-center absolute transition-position",class:{
      'position-center': !_vm.moveModals,
      'slide-left-from-center': _vm.moveModals,
      hidden: _vm.cashTillOpened
    }},[_c('div',{staticClass:"pb-4 font-title text-2xl text-white uppercase text-center"},[_vm._v(" 1/2 "+_vm._s(_vm.$t('start-shift.title'))+" "+_vm._s(_vm.selectedCashTill.name)+" ")]),_c('cash-amount',{attrs:{"done-label":_vm.$t('start-shift.start'),"show-cancel":true,"last-shift-end-amount":_vm.lastShiftCashTillAmount,"use-cash-machine":_vm.useCashMachine},on:{"cancel":_vm.cancel,"done":function (amount) { return _vm.startShiftAndContinue(amount, _vm.selectedCashTill.id); }}}),_c('div',{staticClass:"text-white underline text-center pt-6",on:{"click":_vm.openCashDrawer}},[_vm._v(" "+_vm._s(_vm.$t('start-shift.open-till'))+" ")])],1):_vm._e(),(!_vm.employeeShiftStarted)?_c('div',{staticClass:"flex flex-col items-center absolute",class:{
      'slide-left-to-center': _vm.moveModals,
      'position-hidden-right': !_vm.moveModals && !_vm.cashTillOpened
    }},[_c('div',{staticClass:"pb-4 font-title text-2xl text-white uppercase text-center"},[_vm._v(" 2/2 "+_vm._s(_vm.$t('start-shift.title'))+" "+_vm._s(_vm.currentEmployee.name)+" ")]),_c('cash-amount',{attrs:{"done-label":_vm.$t('start-shift.start'),"show-cancel":true,"last-shift-end-amount":_vm.lastShiftEmployeeTillAmount},on:{"cancel":_vm.cancel,"done":function (amount) { return _vm.startShiftAndContinue(amount, _vm.currentEmployee.tillId); }}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }