<template>
  <div class="rounded-big bg-white shadow flex flex-col">
    <div class="p-2 flex-1 overflow-y-scroll">
      <div
        class="font-bold text-base font-title text-blue uppercase p-4 flex justify-between"
      >
        {{ $t('tabs.order-details') }}
        <icon
          v-if="isOpen"
          name="pen"
          class="opacity-50"
          @click.native="$emit('edit')"
          original-color
        />
      </div>
      <preview-product
        v-for="product in products"
        :key="product.uniqueId"
        :product="product"
      />
    </div>
    <div class="border-t border-gray-200 uppercase p-6">
      <div
        class="flex flex-row justify-between text-blue text-sm"
        v-for="(discount, index) in getBillsDiscounts(tabId)"
        :key="index"
      >
        <div class="flex flex-row">
          <div class="mr-1">
            {{ $t('tabs.discount') }}
          </div>
          <div v-if="discount.type === 'percentage'">
            {{ discount.amount }}%
          </div>
        </div>
        <div class="text-base" v-if="discount.amount > 0">
          <div v-if="discount.type === 'currency'">
            - {{ discount.amount | currency }}
          </div>
          <div v-else>- {{ discount.discountTotal | currency }}</div>
        </div>
        <div v-else>-</div>
      </div>
      <div
        class="flex justify-between items-center"
        v-if="minimumBasketSurcharge"
      >
        <div class="text-blue text-sm">
          {{ $t('tabs.minimum-basket-surcharge') }}
        </div>
        <div class="text-blue">
          {{ minimumBasketSurcharge | currency }}
        </div>
      </div>
      <div
        class="flex justify-between items-center text-blue"
        v-if="deliveryFee.isFree || deliveryFee.value > 0"
      >
        <div class="text-sm">{{ $t('tabs.delivery-fee') }}</div>
        <div v-if="deliveryFee.isFree" class="uppercase">
          {{ $t('tabs.free-delivery') }}
        </div>
        <div v-else>
          {{ deliveryFee.value | currency }}
        </div>
      </div>
      <div class="flex justify-between items-center" v-if="extraCharges">
        <div class="text-blue text-sm">{{ $t('tabs.extra-charges') }}</div>
        <div class="text-blue">
          {{ extraCharges | currency }}
        </div>
      </div>

      <div class="flex justify-between items-center mt-5">
        <div class="text-blue">{{ $t('tabs.total') }}</div>
        <div class="font-bold text-2xl text-red">
          {{ total | currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PreviewProduct from '@/components/tabs/PreviewProduct.vue'
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'PreviewTab',
  props: {
    tabId: {
      type: String,
      default: null
    }
  },
  mounted() {},
  computed: {
    ...mapState('tables', ['tables']),
    ...mapGetters('tabs', [
      'getAllProducts',
      'getBillsDiscounts',
      'getDeliveryFee',
      'getMinimumBasketSurcharge',
      'getBills',
      'getPendingBill',
      'getTab'
    ]),
    products() {
      if (!this.tabId) return []
      return this.getAllProducts(this.tabId)
    },
    deliveryFee() {
      return this.getDeliveryFee(this.tabId)
    },
    minimumBasketSurcharge() {
      return this.getMinimumBasketSurcharge(this.tabId)
    },
    extraCharges() {
      return this.getBills(this.tabId)
        .flatMap(bill => bill.payments)
        .filter(payment => payment.amount < 0)
        .map(payment => -payment.amount)
        .reduce((res, amount) => res + amount, 0)
    },
    total() {
      let pendingTotal = this.getPendingBill(this.tabId)?.total || 0
      let billedTotal = this.getBills(this.tabId)
        .map(bill => bill.total)
        .reduce((res, billTotal) => res + billTotal, 0)

      return pendingTotal + billedTotal
    },
    isOpen() {
      return this.getTab(this.tabId)?.open
    }
  },
  components: {
    PreviewProduct,
    Icon
  }
}
</script>
