<template>
  <div class="flex bg-gray-100 overflow-hidden">
    <div
      class="flex-1 flex overflow-x-scroll scrolling-touch w-full scroll-bar-hidden"
    >
      <div
        v-for="floorplan in floorplans"
        :key="floorplan.id"
        class="uppercase text-sm w-1/3 flex-shrink-0 py-4 flex text-blue justify-center items-center"
        :class="{
          'border-b-4 border-red font-bold': selectedTab === floorplan.id
        }"
        @click="$emit('update:selectedTab', floorplan.id)"
      >
        {{ floorplan.name + ' (' + tabCount[floorplan.id] + ')' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
    floorplans: {
      type: Array,
      default: () => []
    },
    tabCount: {
      type: Object,
      default: () => {}
    },
    selectedTab: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
.scroll-bar-hidden::-webkit-scrollbar {
  display: none;
}
</style>
