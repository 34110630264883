import EscPosEncoder from './esc-pos-encoder.js'
import SocketFactory from '@/socket.js'

class EscPos {
  constructor(config) {
    this.config = config
  }

  printImage(imageData) {
    return new Promise((resolve, reject) => {
      const rejectMiddleware = error => {
        reject(error)
      }
      var socket = SocketFactory.getSocket()
      let config = this.config
      socket.open(
        this.config.address,
        9100,
        () => {
          let encoder = new EscPosEncoder()
          var img = new Image()
          img.onload = function () {
            let width = config.matrixMode ? 200 : 504
            let ratio = width / img.width
            let height = Math.round((img.height * ratio) / 8) * 8
            encoder.align('center')
            if (config.matrixMode) {
              encoder
                .imageEsc(img, width, height)
                .newline()
                .newline()
                .newline()
                .newline()
                .newline()
            } else {
              encoder.image(img, width, height)
            }
            let result = encoder
              .newline()
              .newline()
              .newline()
              .newline()
              .newline()
              .raw([0x1d, 0x56, 0x01])
              .align('left')
              .encode()
            socket.write(
              result,
              () => {
                socket.shutdownWrite(() => {
                  resolve()
                }, rejectMiddleware)
              },
              rejectMiddleware
            )
          }
          img.src = imageData
        },
        rejectMiddleware
      )
    })
  }

  openCashDrawer() {
    return new Promise((resolve, reject) => {
      var socket = SocketFactory.getSocket()
      socket.open(
        this.config.address,
        9100,
        () => {
          let encoder = new EscPosEncoder()
          let result = encoder
            .raw([0x1b, 0x70, 0x00])
            .raw([0x1b, 0x70, 0x01])
            .encode()

          socket.write(
            result,
            () => {
              socket.shutdownWrite(() => {
                resolve()
              }, reject)
            },
            reject
          )
        },
        reject
      )
    })
  }

  getStatus() {
    return new Promise((resolve, reject) => {
      var socket = SocketFactory.getSocket()
      socket.open(
        this.config.address,
        9100,
        () => {
          let encoder = new EscPosEncoder()
          let result = encoder.raw([0x10, 0x04, 0x01]).encode()
          socket.write(result, () => {}, reject)
        },
        reject
      )
      socket.onData(data => {
        socket.shutdownWrite(() => {
          resolve(data)
        }, reject)
      })
      setTimeout(() => {
        socket.shutdownWrite(() => {
          resolve()
        }, reject)
      }, 2000)
    })
  }
}

export default EscPos
