import { mapGetters, mapActions } from 'vuex'
import TicketPrinter from '@/ticketPrinter.js'
import Bills from '@last/core/src/billsGenerator'

let mixin = {
  methods: {
    ...mapActions('tabs', ['startBilling']),
    bill() {
      if (this.allowedToPrintBill) {
        this.printBill()
      } else {
        this.printBills = true
      }
    },
    printBill() {
      let pendingBills = this.getBills(this.tabId).filter(
        bill => bill.pending > 0
      )
      if (pendingBills.length > 0) {
        pendingBills.forEach(TicketPrinter.printBill)
      } else {
        let bill = this.getPendingBill(this.tabId)
        bill = Bills.addPaymentInfo(bill, [])
        TicketPrinter.printBill(bill, true)
        this.startBilling(this.tabId)
      }
    }
  },
  computed: {
    ...mapGetters('tabs', ['getBills', 'getAllProducts', 'getPendingBill']),
    allowedToPrintBill() {
      let bills = this.getBills(this.tabId)
      return this.fastCheckoutAllowed && bills.length === 0
    },
    fastCheckoutAllowed() {
      let pendingBills = this.getBills(this.tabId).filter(
        bill => bill.pending > 0
      ).length
      let pendingToBill = this.getAllProducts(this.tabId)
        .map(product => ({
          ...product,
          quantity: product.notBilledQuantity
        }))
        .filter(product => product.quantity > 0)
      if (pendingToBill.length > 0) {
        pendingBills += 1
      }
      return pendingBills === 1
    }
  }
}

export default mixin
