<template>
  <div class="pt-2">
    <div
      class="flex py-2 payment text-sm"
      :class="{ 'items-center': (payment.tip || 0) == 0 }"
      v-for="payment in payments"
      :key="payment.id"
    >
      <icon
        :name="payment.type"
        class="text-red mr-2"
        :class="{ 'text-green': paid }"
      />
      <div class="text-blue flex-1 payment-type uppercase">
        <div>
          {{ payment.type }}
        </div>
        <div v-if="(payment.tip || 0) > 0" class="text-xs text-gray-400">
          {{ $t('checkout.tip') }}
        </div>
      </div>
      <div class="text-gray-400 payment-amount text-right">
        <div>
          {{ (payment.amount - (payment.tip || 0)) | currency }}
        </div>
        <div v-if="(payment.tip || 0) > 0">
          {{ payment.tip | currency }}
        </div>
      </div>
      <l-dropdown v-if="isDataphonePayment(payment.id)">
        <template #trigger="{ toggle }">
          <icon
            @click.native.stop="toggle"
            name="dots"
            class="text-gray-400 p-1 ml-2"
          />
        </template>
        <div class="option" @click="generateBankReceipt(payment.id)">
          {{ $t('checkout.generate-receipt') }}
        </div>
        <div class="option" @click="deletePaymentWithConfirmation(payment.id)">
          {{ $t('checkout.refund-payment') }}
        </div>
      </l-dropdown>
      <icon
        v-else
        name="close"
        class="text-gray-400 ml-3 p-1 close-button"
        @click.native="deletePaymentWithConfirmation(payment.id)"
      />
    </div>
    <div v-if="payments.length === 0" class="text-gray-300 text-sm py-2">
      {{ $t('checkout.no-payments') }}
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LDropdown from '@last/core-ui/components/LDropdown.vue'
import TicketPrinter from '@/ticketPrinter.js'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Payments',
  props: {
    billId: {
      type: String,
      default: null
    },
    paid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    ...mapActions('tabs', ['deletePayment']),
    getPaymentType(paymentId) {
      let currentPayment = this.getPayment(paymentId)
      return currentPayment.type
    },
    isDataphonePayment(paymentId) {
      let currentPayment = this.getPayment(paymentId)
      if (!currentPayment?.metadata?.receipt) return false
      return currentPayment.type === 'dataphone'
    },
    generateBankReceipt(paymentId) {
      let payment = this.getPayment(paymentId)
      TicketPrinter.printBankReceipt(payment)
    },
    deletePaymentWithConfirmation(paymentId) {
      let paymentType = this.getPaymentType(paymentId)
      this.$ldialog({
        title: this.getDeletePaymentTitleByType(paymentType),
        content: this.getDeletePaymentContentByType(paymentType),
        defaultLabels: true,
        onConfirm: () => this.deletePayment({ billId: this.billId, paymentId })
      })
    },
    getDeletePaymentTitleByType(paymentType) {
      return this.refundablePaymentMethods.includes(paymentType)
        ? this.$t('checkout.refund-payment')
        : this.$t('checkout.delete-payment')
    },
    getDeletePaymentContentByType(paymentType) {
      return this.refundablePaymentMethods.includes(paymentType)
        ? this.$t('checkout.refund-payment-content')
        : this.$t('checkout.delete-payment-content')
    }
  },
  computed: {
    ...mapState('tabs', ['refundablePaymentMethods']),
    ...mapGetters('tabs', ['getBillPayments', 'getPayment']),
    payments() {
      if (!this.billId) return []
      return this.getBillPayments(this.billId)
    }
  },
  components: {
    Icon,
    LDropdown
  }
}
</script>
