<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'
import sync from '@/sync/service.js'

export default {
  name: 'DemoPos',
  async mounted() {
    await this.getDemoAuthData()
    await this.activateDemoMode()
    sync.resync()
    this.$router.push({ name: 'employees' })
  },
  methods: {
    ...mapActions('auth', ['getDemoAuthData']),
    ...mapActions('config', ['activateDemoMode'])
  }
}
</script>
