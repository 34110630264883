<template>
  <div class="w-screen h-screen overflow-hidden order-management">
    <top-bar v-if="!moveProducts" show-home :show-change="false">
      <template slot="center">
        <div class="flex items-center text-xl">
          <icon name="diners" class="mr-2 opacity-50" />
          {{ tab.tableName }}
          <l-dropdown>
            <template slot="trigger" slot-scope="{ toggle }">
              <icon
                @click.native.stop="toggle"
                name="dropdown-menu"
                class="text-gray-400 ml-2"
              />
            </template>
            <div
              class="option"
              v-if="tab.tables && tab.tables.length > 0"
              @click="moveProducts = true"
            >
              {{ $t('ordering.move-products') }}
            </div>
            <div class="option" @click="linkCustomer()">
              {{ $t('ordering.link-customer') }}
            </div>
            <div
              @click="resendKitchenOrders = true"
              class="option"
              v-if="config.enableKitchenOrders"
            >
              {{ $t('resend-kitchen-orders.title') }}
            </div>
            <div class="option" @click="showSendKitchenNotePopup = true">
              {{ $t('ordering.kitchen-note') }}
            </div>
            <div class="option" @click="showAssignTablePopup = true">
              {{ $t('tabs.assign-to-table') }}
            </div>
            <div class="option" @click="linkReservation()">
              {{ $t('ordering.link-reservation') }}
            </div>
            <div class="option" @click="closeTabId = tabId" v-if="tab.open">
              {{ $t('ordering.close') }}
            </div>
          </l-dropdown>
        </div>
      </template>
    </top-bar>
    <div class="flex w-full h-full overflow-hidden" v-if="!moveProducts">
      <div class="flex-1 w-0">
        <menu-browser
          :catalog-id="catalogId"
          :customer-points="availablePoints"
          :customer-used-promotions="customerUsedPromotions"
          @selectedProduct="configureProduct"
        />
      </div>
      <div>
        <open-tab
          :tab-id="tabId"
          :catalog-id="catalogId"
          :selected-seat.sync="selectedSeat"
          :selected-course.sync="selectedCourse"
          :selected-product-id="selectedProductId"
          @selectedProduct="editProduct"
          @selectedComboProduct="editComboProduct"
        />
      </div>
    </div>
    <move-products-between-tabs
      v-else
      :from-tab-id="tabId"
      @productsMoved="goToTheNewTab"
      @close="moveProducts = false"
    />
    <close-tab :tab-id="closeTabId" @cancel="closeTabId = null" />
    <product-editor
      v-if="editingProduct"
      :product="editingProduct"
      :catalog-id="catalogId"
      :tab-id="tabId"
      @saveProduct="saveProduct"
      @close="editingProduct = null"
      :show-course="!selectedCourse"
      show-quantity
    />
    <combo-editor
      v-if="editingCombo"
      :editing-combo="editingCombo.edit"
      :catalog-combo="editingCombo.catalog"
      :combo-product="editingCombo.comboProduct"
      :catalog-id="catalogId"
      :tab-id="tabId"
      :seat="selectedSeat"
      @close="editingCombo = null"
    />
    <resend-kitchen-orders
      v-if="resendKitchenOrders"
      :tab-id="tabId"
      @close="resendKitchenOrders = false"
    />
    <link-customer
      v-if="linkCustomerModal"
      @close="linkCustomerModal = false"
      :tab-id="tabId"
    ></link-customer>
    <link-reservation
      v-if="linkReservationModal"
      @close="linkReservationModal = false"
      :tab-id="tabId"
    ></link-reservation>
    <send-kitchen-note
      v-if="showSendKitchenNotePopup"
      :tab-id="tabId"
      @close="showSendKitchenNotePopup = false"
    />
    <assign-table :is-active.sync="showAssignTablePopup" :tab-id="tabId" />
  </div>
</template>

<script>
import MenuBrowser from './MenuBrowser.vue'
import OpenTab from './OpenTab.vue'
import MoveProductsBetweenTabs from './MoveProductsBetweenTabs'
import ProductEditor from './ProductEditor.vue'
import ComboEditor from './ComboEditor.vue'
import ResendKitchenOrders from './ResendKitchenOrders.vue'
import TopBar from '@/components/core/TopBar.vue'
import CloseTab from '@/components/checkout/CloseTab.vue'
import kitchenOrdersMixin from './kitchenOrdersMixin.js'
import Icon from '@last/core-ui/components/Icon.vue'
import LDropdown from '@last/core-ui/components/LDropdown.vue'
import LinkCustomer from './LinkCustomer.vue'
import LinkReservation from './LinkReservation.vue'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import api from '@/api.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import SendKitchenNote from './SendKitchenNote.vue'
import AssignTable from '../tabs/AssignTable.vue'

export default {
  name: 'OrderManagement',
  mixins: [kitchenOrdersMixin],
  data() {
    return {
      showSendKitchenNotePopup: false,
      showAssignTablePopup: false,
      selectedSeat: null,
      selectedCourse: null,
      editingProduct: null,
      editingCombo: null,
      editingSeat: null,
      closeTabId: null,
      resendKitchenOrders: false,
      customerPoints: 0,
      customerUsedPromotions: [],
      moveProducts: false,
      linkCustomerModal: false,
      linkReservationModal: false
    }
  },
  async mounted() {
    if (!this.isTabOpen(this.tabId)) {
      this.$router.push({ name: 'pos' })
    }
    this.updatePromotionsInfo()
  },
  methods: {
    ...mapActions('tabs', [
      'addProduct',
      'updateProductModifiers',
      'moveProduct',
      'updateCourse'
    ]),
    ...mapActions('promotions', ['updateCustomerPromotions']),
    async updatePromotionsInfo() {
      let points = 0
      if (this.tab?.customerId) {
        const response = await api.get(
          `/customer/${this.tab.customerId}/promotions`
        )
        points = response.data.points
        this.updateCustomerPromotions({
          customerId: this.tab.customerId,
          customerPromotions: response.data.customerPromotions
        })
        this.customerUsedPromotions = response.data.customerUsedPromotions
      }
      this.customerPoints = points
    },
    goToTheNewTab(tabId) {
      this.moveProducts = false
      this.$router.push({
        name: 'orderManagement',
        params: { tabId: tabId }
      })
    },
    saveProduct(product) {
      let productPricing =
        ProductPriceCalculator.calculateProductPricing(product)
      if (product.id) {
        this.updateProductModifiers({
          productId: product.id,
          modifiers: product.modifiers,
          comments: product.comments,
          productPricing
        })
        this.updateCourse({
          tabId: this.tabId,
          productId: product.id,
          course: product.course
        })
      } else {
        this.addProduct({
          tabId: this.tabId,
          seat: this.selectedSeat,
          product: {
            ...product,
            ...productPricing
          }
        })
      }
      this.editingProduct = null
    },
    configureProduct(product) {
      if (product.categories) {
        this.editingCombo = {
          catalog: product,
          edit: null
        }
      } else {
        let newProduct = {
          ...product,
          course: this.selectedCourse || product.course,
          id: null,
          parentProduct: product.id,
          quantity: 1
        }
        let mandatory =
          newProduct.modifierGroups &&
          newProduct.modifierGroups.some(group => group.min > 0)
        if (mandatory) {
          this.editProduct(newProduct)
        } else {
          this.saveProduct({ ...newProduct, modifiers: [] })
        }
      }
    },
    editComboProduct({ product, comboProduct }) {
      const parentProduct = this.$store.getters['catalog/getProductById'](
        product.parentProduct
      )

      if (parentProduct && parentProduct.categories) {
        this.editingCombo = {
          catalog: parentProduct,
          edit: product,
          comboProduct
        }
      }
    },
    editProduct(product) {
      if (!product) {
        this.editingProduct = null
        return
      }
      this.editingSeat = this.selectedSeat
      const parentProduct = this.$store.getters['catalog/getProductById'](
        product.parentProduct
      )

      if (parentProduct && parentProduct.categories) {
        this.editingCombo = {
          catalog: parentProduct,
          edit: product
        }
      } else {
        this.editingProduct = {
          ...parentProduct,
          quantity: product.quantity,
          modifiers: product.modifiers,
          comments: product.comments,
          id: product.id,
          parentProduct: (parentProduct && parentProduct.id) || null,
          course: product.course,
          price: product.price,
          name: product.name,
          discountType: product.discountType,
          discountAmount: product.discountAmount,
          pointsExpense: product.pointsExpense
        }
      }
    },
    linkCustomer() {
      this.linkCustomerModal = true
    },
    linkReservation() {
      this.linkReservationModal = true
    }
  },
  computed: {
    ...mapGetters('tabs', [
      'isTabOpen',
      'getSeatProducts',
      'getSharedProducts'
    ]),
    ...mapState('tabs', ['tabs']),
    ...mapGetters('catalog', ['getCatalogIdByTabVirtualBrandId']),
    ...mapGetters('promotions', ['usedPointsInTab']),
    tabId() {
      return this.$route.params.tabId
    },
    selectedProductId() {
      if (this.editingProduct) return this.editingProduct.id
      return null
    },
    productCount() {
      return (
        this.getSeatProducts(this.tabId).flat().length +
        this.getSharedProducts(this.tabId).length
      )
    },
    tab() {
      return this.tabs[this.tabId]
    },
    tabCustomerId() {
      return this.tab?.customerId
    },
    catalogId() {
      return this.getCatalogIdByTabVirtualBrandId(
        this.tab.virtualBrandId,
        this.tab.pickupType,
        this.tab.source
      )
    },
    availablePoints() {
      return this.customerPoints - this.usedPointsInTab(this.tabId)
    }
  },
  watch: {
    productCount(oldCount, newCount) {
      if (oldCount != newCount) {
        this.editingProduct = null
      }
    },
    tabCustomerId(oldId, newId) {
      if (oldId != newId) {
        this.updatePromotionsInfo()
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.pendingOrders &&
      this.tab.activationTime &&
      this.config.enableKitchenOrders &&
      this.tab.open &&
      to.name === 'pos'
    ) {
      this.$ldialog({
        title: this.$t('unsent-dialog.title'),
        content: this.$t('unsent-dialog.message'),
        secondaryLabel: this.$t('unsent-dialog.cancel'),
        onConfirm: async () => {
          this.sendKitchenOrder('all')
          next()
        },
        onCancel: () => next()
      })
    } else {
      next()
    }
  },
  components: {
    OpenTab,
    MenuBrowser,
    ProductEditor,
    ResendKitchenOrders,
    TopBar,
    Icon,
    CloseTab,
    ComboEditor,
    LDropdown,
    MoveProductsBetweenTabs,
    LinkCustomer,
    LinkReservation,
    SendKitchenNote,
    AssignTable
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.order-management {
  background: black;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.order-container {
  display: flex;
  height: 100%;
}

i {
  color: $text-strong;
}

#tab-name {
  font-size: 23px;
}
</style>
