<template>
  <div>
    <transition name="fade">
      <div
        v-if="open"
        class="z-20 transition blur-background absolute top-0 left-0 w-screen h-screen flex justify-end overflow-hidden"
        @click="$emit('close')"
      >
        <div
          @click.stop
          :class="{ open: open, closed: !open }"
          class="menu transition bg-white menu-w h-full flex flex-col"
        >
          <div class="relative text-white bg-blue-600 name-h flex items-center">
            <div @click="$emit('close')" class="absolute top-0 right-0 p-4">
              <icon name="close" class="text-white" />
            </div>
            <div
              class="uppercase rounded-full bg-white text-blue-600 font-bold w-12 h-12 flex justify-center items-center ml-6 mr-3"
            >
              {{ initials }}
            </div>
            <div>
              <div>
                {{ currentEmployee.name }}
              </div>
              <router-link
                :to="{ name: 'employees' }"
                tag="button"
                class="text-xs"
              >
                {{ $t('sidemenu.change-employee') }}
              </router-link>
            </div>
            <div class="absolute bottom-0 right-0 pr-4 pb-4">
              <div
                class="rounded-full flex items-center justify-center bg-blue w-6 h-6 cursor-pointer"
                :class="{ 'bg-blue': !mute, 'bg-red': mute }"
                @click="toggleMute()"
              >
                <icon name="mute" class="text-white" small />
              </div>
            </div>
          </div>
          <div
            class="flex-1 flex flex-col pt-4 pb-6 pl-6 pr-6 text-blue overflow-hidden"
          >
            <div v-if="shipmentProvidersEnabled.length > 0" class="mb-3">
              <div class="text-gray-400 text-sm">
                {{ $t('sidemenu.delivery-options') }}
              </div>
              <div
                v-if="shouldShowManualShipmentSwitch"
                class="flex flex-row justify-center"
              >
                <div class="w-full">
                  <l-pill
                    :value="!config.manualShipment"
                    @input="setAutomaticShipment"
                    :label="$t('sidemenu.automatic-shipment')"
                    class="m-2 flex justify-center"
                  />
                </div>
                <div class="w-full">
                  <l-pill
                    :value="config.manualShipment"
                    @input="setManualShipment"
                    :label="$t('sidemenu.manual-shipment')"
                    class="m-2 flex justify-center"
                  />
                </div>
              </div>
              <div>
                <l-select
                  v-if="shipmentProvidersEnabled.length > 1"
                  :options="shipmentProvidersEnabled"
                  option-label="name"
                  option-value="name"
                  :value="selectedShipmentProvider"
                  @input="updateSelectedShipmentProvider"
                />
              </div>
              <div
                v-if="shouldShowOwnFleetSwitch"
                class="flex justify-between mt-4 mb-2"
              >
                <div>{{ $t('sidemenu.use-own-fleet') }}</div>
                <l-switch :value="ownFleetEnabled" @input="updateOwnFleet" />
              </div>
            </div>
            <div class="overflow-y-scroll pb-32">
              <div
                v-if="startedShifts.length > 0"
                class="py-3 flex items-center cursor-pointer"
                :class="{
                  'border-t border-gray-200 mt-2':
                    shipmentProvidersEnabled.length > 0
                }"
                @click="endShift()"
              >
                <icon name="end-shift" class="mr-2 text-red" />
                {{ $t('sidemenu.end-shift') }}
              </div>
              <div
                class="py-3 flex items-center border-b border-gray-200 pb-6 mb-2 cursor-pointer"
                @click="
                  popUpPromt('DELIVERY_MANAGER', 'showCloseDeliveryPopup')
                "
              >
                <icon name="close-store" class="mr-2 text-blue" />
                {{ $t('sidemenu.close-delivery') }}
              </div>
              <router-link
                :to="{ name: 'reservations' }"
                class="py-3 flex items-center cursor-pointer"
                @click.native="$emit('close')"
              >
                <icon name="time" class="mr-2 text-blue" />
                {{ $t('sidemenu.reservations') }}
              </router-link>
              <div
                class="py-3 flex items-center cursor-pointer"
                @click="routerPrompt('REAL_TIME_REPORTER', 'realTimeReport')"
              >
                <icon name="reports" class="mr-2 text-blue" />
                {{ $t('sidemenu.real-time-report') }}
              </div>
              <router-link
                v-if="Object.keys(couriers).length > 0"
                :to="{ name: 'couriersReport' }"
                class="py-3 flex items-center cursor-pointer"
                @click.native="$emit('close')"
              >
                <icon name="bike" class="mr-2 text-blue" />
                {{ $t('sidemenu.couriers-report') }}
              </router-link>
              <div
                v-if="selectedCashTill || currentEmployee.tillId"
                class="py-3 flex items-center cursor-pointer"
                @click="routerPrompt('TILL_MANAGER', 'payInPayOut')"
              >
                <icon name="in-out" class="mr-2 text-blue" />
                {{ $t('sidemenu.pay-in-pay-out') }}
              </div>
              <div v-if="!isWeb && this.shiftsEnabled">
                <router-link
                  :to="{ name: 'hardwareSelector' }"
                  class="py-3 flex items-center cursor-pointer"
                  @click.native="$emit('close')"
                >
                  <icon name="till" class="mr-2 text-blue" />
                  {{ $t('sidemenu.hardware-selector') }}
                </router-link>
              </div>
              <div
                class="py-3 flex items-center cursor-pointer"
                @click="routerPrompt('PRODUCT_MANAGER', 'products')"
              >
                <icon name="product" class="mr-2 text-blue" />
                {{ $t('sidemenu.products') }}
              </div>
              <div
                v-if="showBackofficeOption"
                class="py-3 flex items-center cursor-pointer"
                @click="openBackoffice()"
              >
                <icon name="tabs" class="mr-2 text-blue" />
                {{ $t('sidemenu.open-cashlogy') }}
              </div>
              <div
                @click="showSupport = true"
                class="py-3 flex items-center cursor-pointer border-t border-gray-200 mt-2 text-red"
              >
                <icon name="help" class="mr-2" />
                {{ $t('sidemenu.support') }}
              </div>
            </div>
          </div>
          <div
            class="flex flex-col fixed bottom-0 menu-w bg-white pb-6"
            :class="{
              'pb-16': showMinimizeAndClose
            }"
          >
            <div
              @click="openCashDrawer"
              :class="{ 'mb-8': !this.shiftsEnabled }"
              class="rounded-small px-4 py-2 border cursor-pointer uppercase text-sm border-red font-medium ml-8 mr-8 mb-2 text-center text-blue-600"
            >
              {{ $t('topbar.open-register') }}
            </div>
          </div>
          <div class="flex justify-end text-gray-400 mb-2 z-10">
            <div
              class="cursor-pointer flex items-center"
              @click="showAboutPopup = true"
            >
              <div class="text-xs">
                {{ $t('sidemenu.version') }}: {{ appVersion }}
              </div>
              <icon class="px-1" name="info" />
            </div>
          </div>
          <div
            v-if="showMinimizeAndClose"
            class="z-10 flex flex-row justify-between bg-blue-600 text-white text-xs py-3 px-6"
          >
            <div
              class="flex flex-row items-center cursor-pointer"
              @click="minimizeApp"
            >
              <icon small name="minus" class="text-white mr-2" />
              <div>{{ $t('sidemenu.minimize-screen') }}</div>
            </div>
            <div
              class="flex flex-row items-center cursor-pointer"
              @click="closeApp"
            >
              <icon small name="power" class="text-white mr-2" />
              <div>{{ $t('sidemenu.close-application') }}</div>
            </div>
          </div>
        </div>
        <portal to="appRoot" v-if="showCloseDeliveryPopup">
          <close-delivery @close="showCloseDeliveryPopup = false"
        /></portal>
        <portal to="appRoot" v-if="showAboutPopup">
          <about-popup @close="showAboutPopup = false"
        /></portal>
        <transition name="fade">
          <div
            @click.stop
            v-if="showSupport"
            class="z-20 transition absolute top-0 left-0 w-screen h-screen flex justify-end overflow-hidden"
          >
            <div
              class="flex menu transition items-center bg-blue h-16 rounded-l-full py-3 px-4 cursor-pointer"
              @click="showSupport = false"
            >
              <icon name="close" class="text-white" />
            </div>
            <iframe
              @click.stop
              src="https://help.last.app/portal/es/home"
              class="menu transition bg-white support-w h-full"
            >
            </iframe>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import Printer from '@/printer.js'
import Icon from '@last/core-ui/components/Icon.vue'
import LSelect from '@last/core-ui/components/LSelect'
import { mapState, mapGetters, mapActions } from 'vuex'
import CloseDelivery from '../CloseDelivery'
import AboutPopup from '../AboutPopup'
import LPill from '@last/core-ui/components/LPill.vue'
import LSwitch from '@last/core-ui/components/LSwitch.vue'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import { Capacitor } from '@capacitor/core'
import app from '@/app.js'

export default {
  name: 'SideMenu',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showCloseDeliveryPopup: false,
      showSupport: false,
      showAboutPopup: false,
      goingTo: ''
    }
  },
  computed: {
    ...mapGetters('auth', ['currentEmployee', 'getEmployees']),
    ...mapGetters('config', ['tills', 'appVersion', 'shipmentProviders']),
    ...mapState('tabs', ['tabs']),
    ...mapState('config', ['config', 'mute', 'device']),
    ...mapState('couriers', ['couriers']),
    ...mapState('till', ['selectedCashTill', 'shiftsEnabled', 'startedShifts']),
    initials() {
      let initials = this.currentEmployee.name
        .split(/[.,/ -]/)
        .map(n => n[0])
        .join('')
        .slice(0, 2)
      if (initials.length < 2) {
        return this.currentEmployee.name.slice(0, 2)
      } else {
        return initials
      }
    },
    shouldShowOwnFleetSwitch() {
      return (
        this.selectedShipmentProvider?.toUpperCase() === 'STUART' &&
        this.ownFleetAllowed
      )
    },
    showBackofficeOption() {
      return CashMachine.method === 'cashlogy'
    },
    shouldShowManualShipmentSwitch() {
      return (
        this.selectedShipmentProvider &&
        this.config.organizationConfig.showManualShipment
      )
    },
    selectedShipmentProvider() {
      return this.shipmentProviders.selected
    },
    shipmentProvidersEnabled() {
      return (
        this.shipmentProviders.providers?.reduce((res, provider) => {
          if (Object.keys(provider).length > 1) {
            res.push({ name: provider.name })
          }
          return res
        }, []) || []
      )
    },
    ownFleetAllowed() {
      return !!this.shipmentProviders.providers.find(
        p => p.name.toUpperCase() === 'STUART'
      )?.config?.metadata?.fleetId
    },
    ownFleetEnabled() {
      return !!this.shipmentProviders.providers.find(
        p => p.name.toUpperCase() === 'STUART'
      )?.config?.metadata?.ownFleetEnabled
    },
    showMinimizeAndClose() {
      return this.device?.platform == 'electron'
    },
    isWeb() {
      return Capacitor.getPlatform() === 'web'
    },
    employeeTills() {
      return this.getEmployees
        .filter(employee => employee.tillEnabled && employee.tillId)
        .map(employee => employee.tillId)
    }
  },
  methods: {
    ...mapActions('till', ['addMovement']),
    ...mapActions('config', [
      'toggleMute',
      'refreshConfig',
      'updateShipmentProvider',
      'updateManualShipment'
    ]),
    async setManualShipment() {
      this.updateManualShipment(true)
    },
    async setAutomaticShipment() {
      this.updateManualShipment(false)
    },
    async openCashDrawer() {
      if (await this.performActionAskPinIfNeeded('CASH_DRAWER')) {
        Printer.openCashDrawer()
      }
    },
    async performActionAskPinIfNeeded(privilege) {
      const hasPrivilege = await this.$confirm(privilege)
      if (hasPrivilege) {
        return true
      } else {
        this.$emit('close')
        return false
      }
    },
    async routerPrompt(privilege, route) {
      if (await this.performActionAskPinIfNeeded(privilege)) {
        this.$router.push({ name: route })
      }
    },
    async popUpPromt(privilege, popUp) {
      if (await this.performActionAskPinIfNeeded(privilege)) {
        this[popUp] = true
      }
    },
    async endShift() {
      if (!(await this.performActionAskPinIfNeeded('SHIFT_MANAGER'))) return
      let openTabs =
        Object.values(this.tabs).filter(tab => tab.open && tab.activationTime)
          .length > 0
      let cashTillIds = this.tills.cash.map(till => till.id)
      cashTillIds = [...cashTillIds, ...this.employeeTills]
      let cashShifts = this.startedShifts.filter(tillId =>
        cashTillIds.includes(tillId)
      )
      if (openTabs && cashShifts.length > 1) {
        this.$ldialog({
          title: this.$t('open-tabs-dialog.title'),
          content: this.$t('open-tabs-dialog.message'),
          onConfirm: () => this.$router.push({ name: 'endShift' })
        })
      } else if (openTabs) {
        this.$lnotification.create({
          title: this.$t('open-tabs-dialog.title')
        })
      } else {
        this.$router.push({ name: 'endShift' })
      }
      this.$emit('close')
    },
    async updateSelectedShipmentProvider(providerName) {
      this.updateShipmentProvider({ provider: providerName })
    },
    async updateOwnFleet(ownFleetEnabled) {
      this.updateShipmentProvider({
        provider: this.selectedShipmentProvider,
        ownFleetEnabled: ownFleetEnabled ? 1 : 0
      })
    },
    async openBackoffice() {
      if (!(await this.performActionAskPinIfNeeded('TILL_MANAGER'))) return
      await CashMachine.openBackoffice()
    },
    minimizeApp() {
      app.minimize()
    },
    closeApp() {
      app.quit()
    }
  },
  components: {
    CloseDelivery,
    AboutPopup,
    Icon,
    LSelect,
    LPill,
    LSwitch
  }
}
</script>

<style scoped>
.menu-w {
  width: 20rem;
}

.support-w {
  width: 40rem;
}

.name-h {
  height: 6rem;
}

.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter > .menu,
.fade-leave-to > .menu {
  transform: translateX(20rem);
}

.transition {
  transition: all 0.3s;
}

.closed {
  transform: translateX(20rem);
}

.open {
  transform: translateX(0rem);
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.bg-gradient {
  background: linear-gradient(90deg, #4299e1 0%, #21becb 100%);
}
</style>
