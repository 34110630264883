import axios from 'axios'
import store from '@/store/index.js'

let api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

api.interceptors.request.use(
  config => {
    let token = store.state.auth.accessToken
    if (token) {
      config.headers['Authorization'] = token
    }

    let locationId = store.state.auth.locationId
    if (locationId) {
      config.headers['Location-ID'] = locationId
    }

    let organizationId = store.state.auth.organizationId
    if (organizationId) {
      config.headers['Organization-ID'] = organizationId
    }

    let employeeId = store.state.auth.currentEmployeeId
    if (employeeId) {
      config.headers['Employee-ID'] = employeeId
    }
    const isDemo = store.state.config.demoMode
    if (isDemo && (config.method !== 'get' || config.url === '/resync')) {
      return Promise.reject()
    }
    return config
  },

  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    if (401 === error?.response?.status) {
      await store.dispatch('auth/logout')
    } else {
      return Promise.reject(error)
    }
  }
)

export default api
