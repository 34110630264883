<template>
  <section>
    <div class="flex justify-between" v-if="type === 'takeAway'">
      <l-field class="flex-1 mr-4" :label="$t('new-delivery.tab-name-label')">
        <l-input
          v-model="internalTab.name"
          :placeholder="$t('new-delivery.tab-name-placeholder')"
        />
      </l-field>
      <l-field
        class="flex-1 whitespace-no-wrap"
        :label="$t('new-delivery.client-number-label')"
      >
        <l-select v-model="internalTab.seats" :options="numberOfPeople" />
      </l-field>
    </div>
    <l-field :label="$t('new-delivery.select-enterprise-label')" v-else>
      <l-select v-model="internalTab.source" :options="deliveryCompanies" />
    </l-field>
    <l-field :label="$t('new-delivery.client-phone-label')">
      <l-phone-input
        v-model="internalCustomer.phoneNumber"
        :placeholder="$t('new-delivery.client-phone-placeholder')"
        :wrong-value="validating && !validPhoneNumber"
        :default-country-code="config.locationCountryCode"
      />
    </l-field>
    <l-field :label="$t('new-delivery.select-virtual-brand')">
      <l-select
        v-model="internalTab.virtualBrandId"
        :options="enabledBrands"
        option-label="name"
        option-value="id"
      />
    </l-field>
    <l-modal-ctas>
      <l-modal-button
        type="secondary"
        :label="$t('new-delivery.cta-cancel')"
        @click.native="$emit('close')"
        class="mr-4 flex-1"
      />
      <l-modal-button
        type="main"
        :label="$t('new-delivery.cta-continue')"
        :disabled="!isComplete"
        @click.native="checkNextStep"
        class="flex-1"
      />
    </l-modal-ctas>
  </section>
</template>

<script>
import api from '@/api.js'

import LField from '@last/core-ui/components/LField'
import LInput from '@last/core-ui/components/LInput'
import LPhoneInput from '@last/core-ui/components/LPhoneInput'
import LSelect from '@last/core-ui/components/LSelect'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import newTabMixin from '@/components/ordering/newTabMixin.js'
import { mapState } from 'vuex'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  components: {
    LField,
    LInput,
    LSelect,
    LModalCtas,
    LModalButton,
    LPhoneInput
  },
  mixins: [newTabMixin],
  props: {
    customer: {
      type: Object,
      required: true
    },
    tab: {
      type: Object,
      required: true
    },
    deliveryCompanies: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: null
    }
  },
  data: () => ({
    internalTab: {
      virtualBrandId: null
    },
    internalCustomer: {},
    loading: false,
    validating: false
  }),
  computed: {
    ...mapState('config', ['config']),
    isComplete() {
      if (this.type === 'takeAway') {
        return true
      }
      return (
        this.internalTab.source &&
        this.internalCustomer.phoneNumber &&
        this.validPhoneNumber
      )
    },
    validPhoneNumber() {
      let parsed = parsePhoneNumberFromString(
        this.internalCustomer.phoneNumber || ''
      )
      return parsed?.isValid()
    }
  },
  beforeMount() {
    this.internalTab = { ...this.internalTab, ...this.tab }
    this.internalCustomer = { ...this.customer }
    if (!this.internalTab.virtualBrandId) {
      this.internalTab.virtualBrandId = this.enabledBrands[0]?.id
    }
  },
  methods: {
    async checkNextStep() {
      this.validating = true
      if (!this.isComplete) return
      if (this.internalCustomer.phoneNumber && this.validPhoneNumber) {
        if (this.loading) return
        this.loading = true
        let lastCustomerTabs = []
        try {
          const { data } = await api.get('/customers', {
            params: {
              phoneNumber: this.internalCustomer.phoneNumber
            }
          })

          this.internalCustomer = {
            ...data.customer,
            points: data.points,
            isCreated: true
          }
          lastCustomerTabs = data.tabs
        } catch (err) {
          this.internalCustomer.isCreated = false
        } finally {
          this.$emit('nextStep', {
            customer: this.internalCustomer,
            tabs: lastCustomerTabs,
            pickupType: 'ownDelivery'
          })
          this.loading = false
        }
      } else {
        this.$emit('nextStep', {
          customer: this.internalCustomer
        })
      }
    }
  },
  watch: {
    'internalTab.source': function () {
      this.$emit('update:tab', this.internalTab)
    },
    'internalTab.seats': function () {
      this.$emit('update:tab', this.internalTab)
    },
    'internalCustomer.phoneNumber': function () {
      this.$emit('update:phoneNumber', this.internalCustomer.phoneNumber)
    }
  }
}
</script>
