<template>
  <div class="h-screen flex flex-col overflow-hidden">
    <top-bar show-back>
      <template slot="center">
        <div class="text-xl flex items-center">
          {{ $t('pay-in-pay-out.title') }}
        </div>
      </template>
    </top-bar>
    <div
      class="overflow-y-scroll scrolling-touch bg-gray-200 w-full h-full flex flex-col justify-center items-center"
    >
      <div>
        <div
          class="bg-white rounded-big shadow fixed-w overflow-hidden flex flex-col overflow-hidden"
        >
          <tabs
            class="flex-shrink-0"
            :tabs="tabs"
            :selected-tab-idx.sync="selectedTabIdx"
          />
          <div class="p-12 flex flex-col justify-center">
            <div
              v-click-outside="finishCalculator"
              @click.stop="initCalculator"
              class="border border-gray-400 p-3 text-center text-green text-xl rounded-small"
            >
              {{ total | currency }}
            </div>
            <div
              id="numbers-container"
              class="border-gray-400 border rounded-small overflow-hidden mt-3 bg-gray-400 mb-4"
            >
              <div
                v-for="n in numbers"
                :key="n"
                class="bg-white text-blue font-bold flex justify-center items-center font-title text-xl"
                @click="append(n)"
              >
                {{ n }}
              </div>
              <div
                class="bg-white text-green font-bold flex justify-center items-center font-title text-xl"
                @click="deleteNumber()"
              >
                <icon name="delete-arrow" class="p-1" />
              </div>
              <div
                class="bg-white text-blue font-bold flex justify-center items-center font-title text-xl"
                @click="append(0)"
              >
                0
              </div>
              <div
                class="bg-white text-green font-bold flex justify-center items-center font-title text-xl"
                @click="clear()"
              >
                C
              </div>
            </div>
            <div class="text-sm text-gray-400">
              {{ $t('pay-in-pay-out.detail') }}
            </div>
            <l-input class="mb-0" v-model="detail" big />
          </div>
        </div>
        <div class="flex justify-end">
          <div
            class="mt-6 p-3 px-6 shadow-lg text-white bg-red border border-red-b uppercase rounded-small font-bold ml-4 text-center"
            @click="saveMovement()"
          >
            {{
              selectedTab.movementType === 'payIn'
                ? $t('pay-in-pay-out.pay-in')
                : $t('pay-in-pay-out.pay-out')
            }}
            <span>
              {{ total | currency }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import TopBar from '@/components/core/TopBar.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import TicketPrinter from '@/ticketPrinter.js'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import { mapGetters, mapActions } from 'vuex'
import numberKeysMixin from '@last/core-ui/mixins/numberKeysMixin'

export default {
  name: 'PayInPayOut',
  mixins: [numberKeysMixin],
  data() {
    return {
      tabs: [
        {
          name: this.$t('pay-in-pay-out.pay-in'),
          movementType: 'payIn'
        },
        {
          name: this.$t('pay-in-pay-out.pay-out'),
          movementType: 'payOut'
        }
      ],
      selectedTabIdx: 0,
      numbers: [7, 8, 9, 4, 5, 6, 1, 2, 3],
      total: 0,
      detail: null,
      saving: false
    }
  },
  async mounted() {
    if (!this.selectedCashTillIsStarted) {
      this.$router.push({ name: 'employees' })
    }
    if (this.hasCashMachine) {
      this.total = this.machineAmount
    }
    this.initCalculator()
  },
  computed: {
    ...mapGetters('auth', ['currentEmployee']),
    ...mapGetters('till', ['selectedCashTillIsStarted']),
    selectedTab() {
      return this.tabs[this.selectedTabIdx]
    },
    hasCashMachine() {
      return CashMachine.isEnabled()
    },
    machineAmount() {
      if (!CashMachine.status) return 0
      return CashMachine.status.inputAmount
    }
  },
  methods: {
    ...mapActions('till', ['addMovement']),
    initCalculator() {
      this.initNumberKeysListener(
        this.append.bind(this),
        this.deleteNumber.bind(this),
        this.clear.bind(this),
        this.saveMovement.bind(this)
      )
    },
    finishCalculator() {
      this.destroyNumberKeysListener()
    },
    async saveMovement() {
      if (this.saving) return
      this.saving = true
      let method = 'cash'
      if (this.hasCashMachine) {
        await this.cashMachineAction()
        method = CashMachine.method
      }
      if (this.total === 0) {
        return
      }
      let movement = {
        amount: this.total,
        detail: this.detail,
        movementType: this.selectedTab.movementType,
        method,
        tillId: this.currentEmployee.tillEnabled
          ? this.currentEmployee.tillId
          : null
      }
      this.addMovement(movement)
      TicketPrinter.printPayInPayOut({
        date: new Date(),
        employee: this.currentEmployee.name,
        ...movement
      })
      this.$lnotification.create({
        title: this.$t('pay-in-pay-out.movement-added')
      })
      this.$router.go(-1)
    },
    async append(number) {
      this.total = this.total * 10 + number
    },
    deleteNumber() {
      this.total = Math.floor(this.total / 10)
    },
    clear() {
      this.total = 0
    },
    async cashMachineAction() {
      if (this.selectedTab.movementType === 'payIn') {
        this.total = await CashMachine.payIn(this.total)
      } else {
        this.total = await CashMachine.payOut(this.total)
      }
    }
  },
  watch: {
    machineAmount(amount) {
      this.total = amount
    }
  },
  components: {
    LInput,
    Tabs,
    Icon,
    TopBar
  }
}
</script>

<style scoped>
.fixed-w {
  width: 30.25rem;
}

#numbers-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-content: center;
  grid-row-gap: 0.062rem;
  grid-column-gap: 0.062rem;
  height: 16.25rem;
}

.number-button {
  @include card;
  width: 4.375rem;
  line-height: 4.375rem;
  text-align: center;
  margin: 0.25rem;
  flex-grow: 1;
  &.selected {
    background: $orange;
  }
  &.double-size {
    flex-grow: 8;
  }
}

.blue {
  background: $blue;
  color: white;
}

.red {
  background: $red;
  color: white;
}
</style>
