<template>
  <div>
    <gmap-map
      v-if="!!loaded"
      ref="map"
      :center="defaultCenter"
      :options="{
        clickableIcons: false,
        disableDefaultUI: true,
        styles: mapStyle
      }"
      :zoom="16"
      style="width: 100%; height: 100%"
    >
      <gmap-marker
        v-for="(pickup, index) in pickups"
        :key="'p' + index"
        :position="{
          lat: pickup.latitude,
          lng: pickup.longitude
        }"
        :icon="{
          url: pickupIcon
        }"
      />
      <gmap-marker
        v-if="courier && courier.latitude"
        :position="{
          lat: courier.latitude,
          lng: courier.longitude
        }"
        :icon="{
          url: courierIcon
        }"
      />
      <gmap-marker
        v-for="(delivery, index) in deliveries"
        :key="'d' + index"
        :position="{
          lat: delivery.latitude,
          lng: delivery.longitude
        }"
        :icon="{
          url: deliveryIcon
        }"
      />
    </gmap-map>
  </div>
</template>

<script>
//import { gmapApi } from 'vue2-google-maps'
let gmapApi = {}

export default {
  name: 'LGoogleTrackerMap',
  props: {
    pickups: {
      type: Array,
      default: () => []
    },
    deliveries: {
      type: Array,
      default: () => []
    },
    courier: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      defaultCenter: {},
      loaded: null,
      courierIcon:
        'https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto/v1604511069/pos/courier.svg',
      pickupIcon:
        'https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto/v1604511076/pos/pickup.svg',
      deliveryIcon:
        'https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto/v1604511076/pos/delivery.svg',
      deliveryOpacityIcon:
        'https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto/v1604661646/pos/delivery-opacity.svg',
      mapStyle: [
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'transit',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        }
      ]
    }
  },
  async mounted() {
    this.defaultCenter = {
      lat: parseFloat(this.pickups[0].latitude),
      lng: parseFloat(this.pickups[0].longitude)
    }
    this.loaded = await this.$gmapApiPromiseLazy()
  },
  methods: {
    autoCenter() {
      let bounds = new this.google.maps.LatLngBounds()
      this.markers.forEach(marker => {
        if (!marker?.latitude) return
        bounds.extend({
          lat: parseFloat(marker.latitude),
          lng: parseFloat(marker.longitude)
        })
      })
      this.$refs.map.$mapPromise.then(map => {
        map.fitBounds(bounds, 0)
      })
    }
  },
  computed: {
    google: gmapApi,
    shipmentPickedUp() {
      return this.pickups.every(pickup => pickup.pickedUp)
    },
    markers() {
      return [
        this.courier,
        ...(this.shipmentPickedUp !== false ? this.deliveries : []),
        ...(!this.shipmentPickedUp ? this.pickups : [])
      ]
    }
  }
}
</script>

<style scoped></style>
