<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/api.js'
import store from '@/store/index'

export default {
  name: 'SupportLogin',
  async mounted() {
    let isSupport = await this.supportLogin({
      accessToken: this.$route.query.supportToken,
      selectedOrganizationId: this.$route.query.organizationId,
      selectedLocationId: this.$route.query.locationId
    })
    if (isSupport) {
      let locations = (await api.get('/locations'))?.data
      let currentLocation = locations.find(
        l => l.id === this.$route.query.locationId
      )
      if (currentLocation) {
        store.dispatch('auth/selectLocation', currentLocation)
        this.$router.push({ name: 'employees' })
      } else {
        this.$router.push({ name: 'locations' })
      }
    } else {
      this.$router.push({ name: 'login' })
    }
  },
  methods: {
    ...mapActions('auth', ['supportLogin'])
  }
}
</script>

<style scoped></style>
