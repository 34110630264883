<template>
  <l-modal
    :title="$t('invoice-modal.title')"
    size="small"
    @close="$emit('close')"
  >
    <template slot="body">
      <l-modal-section-title
        :title="$t('invoice-modal.section-title')"
        class="uppercase update-modal__title"
      />

      <l-field :label="$t('invoice-modal.email-label')">
        <l-input
          v-model="email"
          :placeholder="$t('invoice-modal.email-placeholder')"
        />
      </l-field>

      <l-modal-ctas>
        <l-modal-button
          class="flex-1"
          type="main"
          :disabled="!isComplete"
          :label="$t('ctas.send')"
          @click.native="sendSuccessEvent"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import validator from 'validator'
import LModal from '@last/core-ui/components/LModal.vue'
import LModalSectionTitle from '@last/core-ui/components/LModalSectionTitle.vue'
import LModalCtas from '@last/core-ui/components/LModalCtas.vue'
import LModalButton from '@last/core-ui/components/LModalButton.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import LField from '@last/core-ui/components/LField.vue'

export default {
  name: 'UpdateModal',
  components: {
    LModal,
    LModalSectionTitle,
    LModalCtas,
    LModalButton,
    LInput,
    LField
  },
  props: {
    billId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      email: ''
    }
  },
  computed: {
    isComplete() {
      return validator.isEmail(this.email)
    }
  },
  methods: {
    sendSuccessEvent() {
      if (!this.isComplete) return
      this.$lnotification.create({
        title: this.$t('generate-invoice.invoice-sent-successfully'),
        icon: 'done',
        iconColor: 'green'
      })
      this.$emit('success', { email: this.email, billId: this.billId })
    }
  }
}
</script>
