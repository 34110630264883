<template>
  <div
    class="absolute top-0 left-0 z-20 flex flex-col justify-start w-screen h-screen p-10 overflow-y-scroll scrolling-touch blur-background"
    @click="$emit('close')"
  >
    <div @click.stop>
      <div class="flex items-center justify-between px-4">
        <div class="flex items-center text-2xl text-white uppercase">
          <div class="text-3xl font-bold text-white uppercase font-title">
            {{ product.name }}
          </div>
          <div class="px-3 text-3xl font-light uppercase text-blue-light">
            {{ (product.price || product.priceImpact) | currency }}
          </div>
          <quantity-selector
            v-if="showQuantity"
            class="pt-2 ml-8 text-base"
            button-color="bg-green-light"
            :quantity="quantity"
            @update:quantity="updateQuantity"
          />
        </div>
      </div>
      <l-row-selector
        v-if="showCourse"
        class="my-4"
        :options="allCourses"
        :option-selected="product.course"
        @onSelect="onSelectProductCourse"
      />
      <div
        class="relative px-16 pb-16 pt-8 bg-gray-100 shadow rounded-big"
        @click.stop
      >
        <div class="absolute top-0 right-0 p-4">
          <icon
            name="close"
            class="text-gray-400"
            @click.native="$emit('close')"
          />
        </div>
        <tabs
          class="flex-shrink-0 product-editor__tabs"
          :limit-width="true"
          :tabs="tabs"
          :selected-tab-idx.sync="selectedTabIdx"
        />
        <div class="product-options" v-if="selectedModifiers.length > 0">
          <modifier-group
            v-if="modifierGroup.id === selectedTab.tabType"
            :key="modifierGroup.name"
            :modifier-group="modifierGroup"
            :modifiers.sync="selectedModifiers[indexOfSelectedModifier]"
            @change="tryGotoNext"
          />
        </div>
        <open-modifiers
          v-if="selectedTab.tabType === TAB_OPEN_ATTRIBUTE"
          :open-modifiers="openModifiers"
          @openModifier:create="handleSaveOpenModifier"
          @openModifier:delete="deleteOpenModifier"
        />
        <template v-if="selectedTab.tabType === TAB_COMMENT">
          <div
            class="pt-10 pb-4 text-xl font-bold uppercase text-blue font-title"
          >
            {{ $t('ordering.comments') }}
          </div>
          <textarea
            class="w-full p-6 border border-blue rounded-small"
            :placeholder="$t('ordering.comments')"
            maxlength="255"
            v-model="comments"
          />
        </template>
      </div>
      <div class="fixed bottom-0 right-0 flex mb-4 mr-6 w-full justify-end">
        <div
          @click="$emit('close')"
          class="flex items-center w-1/5 justify-center mr-4 text-lg font-bold text-white uppercase bg-gray-900 shadow-lg rounded-small done-button"
        >
          {{ $t('ordering.cancel') }}
        </div>
        <div
          @click="saveProduct"
          class="flex items-center w-1/5 justify-center mr-4 text-lg font-bold text-white uppercase border shadow-lg bg-red border-red-b rounded-small done-button"
        >
          {{ $t('ordering.done') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LRowSelector from '@last/core-ui/components/LRowSelector.vue'
import ModifierGroup from '@/components/ordering/ModifierGroup.vue'
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import OpenModifiers from '@/components/ordering/OpenModifiers.vue'
import Tabs from '@/components/Tabs.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import productEditorMixin from '@/components/ordering/productEditorMixin.js'

const TAB_COMMENT = 'TAB_COMMENT'
const TAB_OPEN_ATTRIBUTE = 'TAB_OPEN_ATTRIBUTE'

export default {
  name: 'ProductEditor',
  mixins: [productEditorMixin],
  components: {
    ModifierGroup,
    QuantitySelector,
    Icon,
    LRowSelector,
    Tabs,
    OpenModifiers
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    showQuantity: {
      type: Boolean,
      default: false
    },
    showCourse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedModifiers: [],
      openModifiers: [],
      openModifier: {
        name: null,
        priceImpact: 0
      },
      quantity: 1,
      comments: '',
      priceError: false,
      nameError: false,
      TAB_COMMENT: TAB_COMMENT,
      TAB_OPEN_ATTRIBUTE: TAB_OPEN_ATTRIBUTE,
      selectedTabIdx: 0
    }
  },
  computed: {
    ...mapState('catalog', ['catalogs']),
    ...mapGetters('config', ['currencyIcon']),
    selectedTab() {
      return this.tabs[this.selectedTabIdx]
    }
  },
  mounted() {
    this.updateSelectedModifiers()
    this.resetTab()
  },
  methods: {
    ...mapActions('tabs', ['updateProductQuantity']),
    resetTab() {
      this.selectedTabIdx = 0
    },
    saveProduct() {
      this.$emit('saveProduct', {
        ...this.product,
        quantity: this.quantity,
        modifiers: this.allSelectedModifiers,
        comments: this.comments
      })
    },
    tryGotoNext([selected, max]) {
      if (max && selected === max) {
        if (this.selectedTabIdx < this.tabs.length) {
          this.selectedTabIdx += 1
        }
      }
    },
    handleSaveOpenModifier(openModifier) {
      this.openModifier = openModifier
      this.saveOpenModifier()
    }
  },
  watch: {
    product(newProduct, oldProduct) {
      if (newProduct.id != oldProduct.id) {
        this.updateSelectedModifiers()
        this.resetTab()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

.product-editor {
  &__tabs {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 3.5rem;
    align-items: flex-end;
    border-radius: 1.25rem 1.25rem 0 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
  }
}

.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.modifier-group-title {
  padding: 10px;
  width: 100%;
  color: $text-strong;
}

.modifier {
  @include card;
  padding: 10px;
  margin: 5px;
  color: $text-strong;
  cursor: pointer;
  &.selected {
    background: $orange;
    color: white;
  }
}

.done-button {
  height: 3.5rem;
}

.option {
  height: 56px;
  min-width: 260px;
}

.product-options {
  max-height: 28rem;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}
@media screen and (max-width: 640px) {
  .product-options {
    max-height: 15.625rem;
  }
}

@media screen and (min-height: 850px) {
  .product-options {
    max-height: 38rem;
  }
}
</style>
