import Vue from 'vue'
import router from '@/router.js'
import sync from '@/sync/service.js'

const state = {
  startedShifts: [],
  startedShiftsWithCashAmount: [],
  shiftsEnabled: true,
  selectedCashTill: null
}

const getters = {
  selectedCashTillIsStarted: state =>
    state.selectedCashTill &&
    state.startedShifts.includes(state.selectedCashTill.id),
  tillIsStarted: state => tillId => state.startedShifts.includes(tillId),
  shiftIsStarted: state => state.startedShifts.length > 0,
  askForForTillStartAmount: state =>
    state.shiftsEnabled &&
    !(
      state.selectedCashTill &&
      state.startedShiftsWithCashAmount.includes(state.selectedCashTill.id)
    )
}

const actions = {
  async refreshStartedShifts({ commit }, startedShifts) {
    commit('refreshStartedShifts', startedShifts)
  },
  async refreshStartedShiftsWithCashAmount(
    { commit },
    startedShiftsWithCashAmount
  ) {
    commit('refreshStartedShiftsWithCashAmount', startedShiftsWithCashAmount)
  },
  async refreshShiftsEnabled({ getters, commit }, shiftsEnabled) {
    commit('refreshShiftsEnabled', shiftsEnabled)
    if (shiftsEnabled && !getters.selectedCashTillIsStarted) {
      if (router.currentRoute.name !== 'employees') {
        router.push({ name: 'employees' })
      }
    }
  },
  updateSelectedTillIfNull({ state, commit }, till) {
    if (!state.selectedCashTill) {
      commit('setTill', till)
    }
  },
  setTill({ commit }, till) {
    commit('setTill', till)
  },
  startShift(_, { amount, tillId, method }) {
    sync.record('shiftStarted', {
      amount,
      start: new Date(),
      tillId: tillId,
      method
    })
  },
  endShift({ state }, { amounts, tillId }) {
    sync.record('shiftEnded', {
      amounts,
      end: new Date(),
      tillId: tillId || state.selectedCashTill.id
    })
  },
  addMovement({ state }, { amount, detail, movementType, tillId, method }) {
    sync.record('movementAdded', {
      amount,
      detail,
      movementType,
      method,
      tillId: tillId || state.selectedCashTill.id,
      creationTime: new Date()
    })
  }
}

const mutations = {
  refreshStartedShifts(state, startedShifts) {
    state.startedShifts = startedShifts
  },
  refreshStartedShiftsWithCashAmount(state, startedShiftsWithCashAmount) {
    state.startedShiftsWithCashAmount = startedShiftsWithCashAmount
  },
  refreshShiftsEnabled(state, shiftsEnabled) {
    state.shiftsEnabled = shiftsEnabled
  },
  startShift(state, tillId) {
    if (!state.startedShifts.includes(tillId)) {
      Vue.set(state.startedShifts, state.startedShifts.length, tillId)
    }
  },
  endShift(state, tillId) {
    state.startedShifts = state.startedShifts.filter(id => id !== tillId)
    state.startedShiftsWithCashAmount =
      state.startedShiftsWithCashAmount.filter(id => id !== tillId)
  },
  setTill(state, till) {
    state.selectedCashTill = till
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
