<template>
  <div class="flex flex-col charge-calculator h-full bg-white">
    <div class="flex-1">
      <checkout-amounts
        class="flex p-2 border-b border-gray-300"
        @full-amount="toPay = pendingAmount"
        :pending-amount="pendingAmount"
        :to-pay="toPay"
        :is-tip="paymentMethod !== 'cash'"
        :operation="{
          symbol: currentOperationSymbol,
          number: currentOperationNumber,
          filter: currentOperationFilter
        }"
      />
      <div class="w-full flex justify-center p-4">
        <numbers
          class="overflow-hidden text-xl font-bold bg-gray-200 border border-gray-200 calculator rounded-small text-blue"
          @send="handleButtonClicked"
        />
      </div>
    </div>
    <div class="p-4 mt-2 font-bold">
      <div
        :class="{ disabled: toPay === 0 }"
        class="flex p-4 justify-center text-white uppercase border bg-red border-red-b rounded-small charge"
        @click="charge()"
      >
        <icon
          v-if="paymentMethod"
          :name="paymentMethod"
          class="mr-2 text-white opacity-50"
        />
        {{ $t('checkout.charge') }} {{ toPay | currency }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import { mapState } from 'vuex'
import numberKeysMixin from '@last/core-ui/mixins/numberKeysMixin'
import Numbers from '@/components/checkout/Numbers.vue'
import CheckoutAmounts from '@/components/checkout/CheckoutAmounts.vue'

export default {
  name: 'ChargeCalculator',
  mixins: [numberKeysMixin],
  props: {
    pendingAmount: {
      type: Number,
      default: 0
    },
    paymentMethod: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.prefillToPay()
    this.initNumberKeysListener(
      this.handleNumberClicked.bind(this),
      this.deleteNumber.bind(this),
      this.clear.bind(this),
      this.charge.bind(this)
    )
  },
  computed: {
    ...mapState('config', ['config']),
    currentOperationNumber() {
      if (this.currentOperationFilter) {
        return this.currentOperationFilter(this.currentNumber || 0)
      }
      return this.currentNumber
    }
  },
  watch: {
    pendingAmount(amount) {
      if (this.toPay > amount) {
        this.toPay = amount
      }
    },
    toPay(value) {
      this.toPay = Math.min(value, 100_000_000)
    }
  },
  components: {
    Icon,
    Numbers,
    CheckoutAmounts
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

span.disabled {
  color: darken(white, 50%);
}
</style>
