<template>
  <div class="flex flex-col w-full h-full">
    <top-bar v-if="!showingFloorPlan" show-back @back="$emit('close')">
      <template slot="center">
        <div class="text-xl flex items-center">
          {{ title }}
        </div>
      </template>
    </top-bar>
    <div class="flex w-full h-full">
      <div
        v-if="!showingFloorPlan"
        class="flex flex-col justify-center items-center flex-1 w-0 bg-gray-200"
      >
        <h1
          class="move-products__title font-normal uppercase text-xl text-center mb-4 leading-normal"
        >
          {{ $t('ordering.move-products-title') }}
        </h1>
        <l-modal-button
          type="main"
          :disabled="selectedProducts.length === 0"
          :label="
            $t('ordering.move-products-cta', {
              productsNumber: selectedProducts.length
            })
          "
          @click.native="showingFloorPlan = true"
        />
      </div>
      <div
        v-if="!showingFloorPlan"
        class="side-menu flex flex-col h-full bg-white shadow-md overflow-y-scroll scrolling-touch"
      >
        <template v-for="(seat, index) in tabSeats">
          <l-section-side-menu
            :key="index"
            :section-name="`${
              index === 0
                ? $t('ordering.shared')
                : $t('ordering.seat') + ' ' + index
            }`"
            :section-selected="selectedSeat === index"
            :elements="seat"
            @selectedSection="selectProducts(index, seat)"
            @onAdd="event => onUpdate(index, event)"
            @onUpdate="event => onUpdate(index, event)"
          >
            <div
              v-for="product in seat"
              :key="product.id"
              class="flex items-center"
              @click.stop="
                selectProduct(
                  product,
                  !selectedProducts.some(
                    selectedProduct => selectedProduct.id === product.id
                  )
                )
              "
            >
              <l-checkbox
                class="ml-4"
                :class="{ 'opacity-50': product.notBilledQuantity === 0 }"
                :value="
                  selectedProducts.some(
                    selectedProduct => selectedProduct.id === product.id
                  )
                "
                @input="value => selectProduct(product, value)"
              />
              <tab-product
                :id="product.id"
                class="tab-product"
                :product="product"
                :disabled-quantity-selector="true"
                :disabled-by-parent="true"
              />
            </div>
          </l-section-side-menu>
        </template>
      </div>
      <table-selector
        v-if="showingFloorPlan"
        :title="title"
        :blocked-tables="blockedTables"
        @close="showingFloorPlan = false"
        @tableSelected="selectTable"
      />
      <tab-selector
        v-if="showTabSelector"
        :tab-ids-to-select="toTable.tabIds"
        @tabSelected="tabSelected"
        @close="showTabSelector = false"
      />
      <move-products-confirmation-modal
        v-if="openConfirmationModal"
        :to-table="toTable"
        :to-tab="toTab"
        :from-tab="tabs[fromTabId]"
        :products="selectedProducts"
        @confirm="moveProducts"
        @close="handleCloseModal"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import TopBar from '@/components/core/TopBar.vue'
import kitchenOrdersMixin from '@/components/ordering/kitchenOrdersMixin.js'
import TableSelector from '@/components/tables/TableSelector.vue'
import LSectionSideMenu from '@last/core-ui/components/LSectionSideMenu'
import TabProduct from './TabProduct.vue'
import LModalButton from '@last/core-ui/components/LModalButton'
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import MoveProductsConfirmationModal from '@/components/ordering/MoveProductsConfirmationModal'
import TabSelector from '@/components/tables/TabSelector.vue'

export default {
  name: 'MoveProductsBetweenTabs',
  components: {
    TopBar,
    TableSelector,
    LSectionSideMenu,
    TabProduct,
    LModalButton,
    LCheckbox,
    MoveProductsConfirmationModal,
    TabSelector
  },
  mixins: [kitchenOrdersMixin],
  props: {
    fromTabId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedSeat: null,
      selectedProducts: [],
      showingFloorPlan: false,
      fromTable: { id: null },
      toTable: null,
      toTab: null,
      showTabSelector: false,
      tableWithoutTabs: false,
      tabId: null
    }
  },
  computed: {
    ...mapState('tabs', ['tabs', 'products', 'kitchenOrders']),
    ...mapGetters('tabs', [
      'getSeatProducts',
      'getSharedProducts',
      'getSentToKitchenTime'
    ]),
    ...mapGetters('tables', ['getAllTables']),
    ...mapGetters('reservations', ['getReservation']),
    title() {
      return this.$t('ordering.move-products')
    },
    tabSeats() {
      return [this.tabSharedProducts, ...this.getSeatProducts(this.fromTabId)]
    },
    tabSharedProducts() {
      return this.getSharedProducts(this.fromTabId)
    },
    openConfirmationModal() {
      return this.fromTable && (this.toTab || this.tableWithoutTabs)
    },
    blockedTables() {
      return this.getAllTables
        .filter(table => {
          return (
            table.id === this.fromTable.id ||
            !!this.getReservation(table.id, new Date())
          )
        })
        .map(table => table.id)
    }
  },
  mounted() {
    this.fromTable = this.getAllTables.find(table =>
      table.tabIds.includes(this.fromTabId)
    )
  },
  methods: {
    ...mapActions('tabs', [
      'moveProduct',
      'moveProductTab',
      'openTab',
      'startBilling'
    ]),
    handleCloseModal() {
      this.toTab = null
      this.tableWithoutTabs = false
    },
    tabSelected(tabId) {
      this.toTab = this.tabs[tabId]
    },
    selectProduct(product, value) {
      if (product.notBilledQuantity === 0) return
      if (value) this.selectedProducts.push(product)
      else
        this.selectedProducts.splice(
          this.selectedProducts.findIndex(
            selectedProduct => selectedProduct.id === product.id
          ),
          1
        )
    },
    selectProducts(index, seat) {
      if (this.selectedSeat !== index) {
        this.selectedSeat = index
        this.selectedProducts = [
          ...seat.filter(product => product.notBilledQuantity),
          ...this.selectedProducts.filter(
            product => !seat.some(seatProduct => seatProduct.id === product.id)
          )
        ]
      } else {
        this.selectedSeat = null
        this.selectedProducts = this.selectedProducts.filter(
          product => !seat.some(seatProduct => seatProduct.id === product.id)
        )
      }
    },
    onUpdate(seat, event) {
      let productId = event.item.id
      let position = event.newIndex
      this.moveProduct({ tabId: this.fromTabId, seat, position, productId })
    },
    selectTable(selectedTableId) {
      this.toTable = this.getAllTables.find(
        table => table.id === selectedTableId
      )
      if (this.toTable.tabIds?.length > 1) {
        this.showTabSelector = true
      } else if (this.toTable.tabIds?.length === 1) {
        this.toTab = this.tabs[this.toTable.tabIds[0]]
      } else {
        this.tableWithoutTabs = true
      }
    },
    async getTabId() {
      if (this.toTab) return this.toTab.id
      else {
        let toTabId = await this.openTab({
          tableId: this.toTable.id,
          tab: {}
        })
        return toTabId
      }
    },
    async moveProducts() {
      const toTabId = await this.getTabId()

      let alreadyOrderedProducts = this.selectedProducts.filter(
        product => !!this.getSentToKitchenTime(product.id)
      )

      await Promise.all(
        this.selectedProducts.map(product => {
          this.moveProductTab({
            productId: product.id,
            fromTabId: this.fromTabId,
            toTabId: toTabId
          })
        })
      )

      this.tabId = this.fromTabId
      this.sendKitchenOrderModifications(false)

      this.tabId = toTabId
      this.sendKitchenOrderByProducts(alreadyOrderedProducts, false)

      this.$emit('productsMoved', toTabId)
      // Start billing destination tab if origin tab already has some bill to avoid fraud
      if (
        !this.tabs[toTabId].billingStartedTime &&
        this.tabs[this.fromTabId].billingStartedTime
      ) {
        this.startBilling(toTabId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.side-menu {
  width: 310px;
}
.move-products {
  &__title {
    width: 20rem;
  }
}
</style>
