import Home from '@/mobile/views/Home.vue'
import Login from '@/components/Login.vue'
import EmployeeSelector from '@/components/EmployeeSelector.vue'
import LocationSelector from '@/components/LocationSelector.vue'
import StartShift from '@/mobile/views/StartShift.vue'
import OrderManagement from '@/mobile/views/OrderManagement.vue'
import ProductEditor from '@/mobile/views/ProductEditor.vue'
import ComboEditor from '@/mobile/views/ComboEditor.vue'
import ComboDetails from '@/mobile/views/ComboDetails.vue'
import Cart from '@/mobile/views/Cart.vue'
import TabBrowser from '@/mobile/views/TabBrowser.vue'
import NewTakeAwayTab from '@/mobile/views/NewTakeAwayTab.vue'
import ExtraDeviceWarning from '@/components/ExtraDeviceWarning.vue'
import FreeTrialExpired from '@/components/FreeTrialExpired.vue'
import GracePeriodExpired from '@/components/GracePeriodExpired.vue'
import DemoPos from '@/components/DemoPos.vue'
import PlanSelector from '@/components/PlanSelector.vue'
import Checkout from '@/mobile/views/Checkout.vue'
import BillsList from '@/mobile/views/BillsList.vue'

const mobileRoutes = [
  {
    path: '/',
    redirect: { name: 'employees' }
  },
  {
    name: 'pos',
    path: '/pos',
    props: true,
    component: Home
  },
  {
    name: 'demoPos',
    path: '/demo-pos',
    component: DemoPos
  },
  {
    name: 'login',
    path: '/login',
    component: Login
  },
  {
    name: 'locations',
    path: '/locations',
    component: LocationSelector
  },
  {
    name: 'employees',
    path: '/employees',
    component: EmployeeSelector
  },
  {
    name: 'startShift',
    path: '/start-shift',
    component: StartShift
  },
  {
    name: 'orderManagement',
    path: '/pos/order-management/:tabId',
    component: OrderManagement
  },
  {
    name: 'productEditor',
    path: '/pos/order-management/:tabId/edit-product',
    component: ProductEditor,
    props: true
  },
  {
    name: 'comboEditor',
    path: '/pos/order-management/:tabId/edit-combo',
    component: ComboEditor,
    props: true,
    children: [
      {
        name: 'comboProductEditor',
        path: 'edit-product',
        component: ProductEditor,
        props: true
      },
      {
        name: 'comboDetails',
        path: 'details',
        component: ComboDetails,
        props: true
      }
    ]
  },
  {
    name: 'cart',
    path: '/pos/order-management/:tabId/cart',
    component: Cart
  },
  {
    name: 'checkout',
    path: '/pos/order-management/:tabId/checkout',
    component: Checkout,
    props: true
  },
  {
    name: 'billsList',
    path: '/pos/:tabId/bills-list',
    component: BillsList
  },
  {
    name: 'tabBrowser',
    path: '/pos/browse-tabs',
    component: TabBrowser
  },
  {
    name: 'newTakeAwayTab',
    path: '/pos/new-tab',
    component: NewTakeAwayTab
  },
  {
    name: 'extraDeviceWarning',
    path: '/extra-device',
    component: ExtraDeviceWarning
  },
  {
    name: 'planSelector',
    path: '/change-plan',
    component: PlanSelector
  },
  {
    name: 'freeTrialExpired',
    path: '/free-trial-expired',
    component: FreeTrialExpired
  },
  {
    name: 'gracePeriodExpired',
    path: '/grace-period-expired',
    component: GracePeriodExpired
  }
]

export default mobileRoutes
