import { registerPlugin } from '@capacitor/core'

const SerialPlugin = registerPlugin('SerialPlugin')

async function getSerial() {
  return new Promise((resolve, reject) => {
    SerialPlugin.addListener('serialUpdatedEvent', data => {
      try {
        const serial = data['serial']
        resolve(serial)
      } catch (error) {
        reject(error)
      }
    })
    SerialPlugin.updateSerial()
  })
}

export { getSerial }
