<template>
  <div
    class="bg-white relative rounded-big flex flex-col justify-center items-center py-12 w-1/2"
  >
    <icon name="close" class="text-gray-400 absolute top-0 right-0 mt-4 mr-4" />
    <icon name="error" class="text-error mb-5 w-10 h-10" />
    <div class="text-blue uppercase font-title font-bold text-xl mb-3">
      {{ $t('tabs.shipment-has-been-cancelled') }}
    </div>
    <div class="text-gray-400">
      {{ cancelReason }}
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'CancelledShipmentPopup',
  props: {
    cancelReason: {
      type: String,
      default: ''
    }
  },
  components: {
    Icon
  }
}
</script>

<style scoped></style>
