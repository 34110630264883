<template>
  <div class="flex flex-col" v-if="promotions.length > 0">
    <h3 slot class="px-5">
      <slot name="title">
        {{ title }}
      </slot>
    </h3>
    <div class="relative flex flex-wrap px-3 overflow-scroll scrolling-touch">
      <div
        v-for="promotion in promotions"
        :key="promotion.id"
        class="relative flex flex-col justify-between flex-shrink-0 w-48 px-6 py-2 m-2 bg-white border border-gray-300 menu-product rounded-small cursor-pointer"
        :class="{
          'promotion-selected': promotion.selected,
          'opacity-50 pointer-events-none':
            (!promotion.isValid || promotion.disabled) && !promotion.selected
        }"
        @click="$emit('promotionSelected', promotion)"
      >
        <div
          :class="{
            'promotion-selected': promotion.selected
          }"
          class="text-blue font-body"
        >
          {{ promotion.name }}
        </div>
        <div
          :class="{
            'promotion-selected': promotion.selected
          }"
          class="text-sm text-gray-400"
        >
          {{ promotion.description }}
        </div>
        <div
          :class="{
            'promotion-selected': promotion.selected
          }"
          class="text-sm text-right text-green"
          v-if="promotion.pointsExpense > 0"
        >
          {{ promotion.pointsExpense }} {{ $t('product.points') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PromotionList',
  props: {
    title: {
      type: String,
      default: ''
    },
    promotions: {
      type: Array,
      default: () => []
    },
    promotionsSelected: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState('tabs', ['tabs', 'products']),
    ...mapState('auth', ['locationId']),
    ...mapGetters('tabs', [
      'getCourseProducts',
      'getSeatProducts',
      'getSharedProducts',
      'getTotal',
      'getDeliveryFee',
      'getBills'
    ]),
    tabId() {
      return this.$route.params.tabId
    }
  },
  methods: {
    dayOfWeek() {
      return [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
      ][new Date().getDay()]
    }
  }
}
</script>

<style lang="scss" scoped>
.promotion {
  @apply text-green;
  &-selected {
    @apply text-white bg-green;
  }
  &-icon {
    @apply w-8 h-8;
  }
}
</style>
