import axios from 'axios'

const CACHE_VERSION = 1

function getBase64Image(url) {
  return axios
    .get(url, {
      responseType: 'arraybuffer'
    })
    .then(
      response =>
        'data:image/png;base64,' +
        Buffer.from(response.data, 'binary').toString('base64')
    )
}

function cacheLogo(imageUrl) {
  return getBase64Image(imageUrl).then(image =>
    localStorage.setItem('ticket-logo', image)
  )
}

function getImageUrl(imageId, size) {
  if (size) {
    return `https://res.cloudinary.com/lastpos/image/upload/c_thumb,h_${size.height},w_${size.width},f_auto,q_auto/${imageId}`
  } else {
    return `https://res.cloudinary.com/lastpos/image/upload/f_auto,q_auto/${imageId}`
  }
}

async function cacheCloudinary(type, id, imageId, size) {
  if (!imageId) return
  let imageFlag = `image-cache-${imageId}`
  let cacheVersion = localStorage.getItem(`image-cache-version-${type}`)
  let existingItem = localStorage.getItem(imageFlag)
  if (cacheVersion == CACHE_VERSION && existingItem) return
  return getBase64Image(getImageUrl(imageId, size)).then(image => {
    let imageKey = `${type}-${id}`
    localStorage.setItem(imageKey, image)
    localStorage.setItem(imageFlag, imageKey)
  })
}

async function cacheCloudinaryList(images) {
  await Promise.all(
    images.map(image =>
      cacheCloudinary(image.type, image.id, image.imageId, image.size)
    )
  )
  let imageTypes = [...new Set(images.map(image => image.type))]
  for (let type of imageTypes) {
    localStorage.setItem(`image-cache-version-${type}`, CACHE_VERSION)
  }
}

function getImage(type, id) {
  return localStorage.getItem(`${type}-${id}`)
}

export default {
  cacheLogo,
  cacheCloudinary,
  cacheCloudinaryList,
  getImage
}
