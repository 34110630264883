import Vue from 'vue'
import Vuex from 'vuex'
import tables from './tables'
import tabs from './tabs'
import billing from './billing'
import catalog from './catalog'
import auth from './auth'
import config from './config'
import till from './till'
import status from './status'
import promotions from '@/promotions/promotionsStore'
import couriers from './couriers'
import reservations from './reservations'
import deliveryCompanies from './deliveryCompanies'
import scrapper from './scrapper'
import VuexPersistence from 'vuex-persist'
import appStorage from '@/appStorage.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const vuexPersist = new VuexPersistence({
  strictMode: true,
  storage: appStorage,
  asyncStorage: true,
  modules: [
    'tables',
    'billing',
    'auth',
    'config',
    'till',
    'promotions',
    'reservations',
    'couriers',
    'deliveryCompanies',
    'status',
    'scrapper'
  ],
  filter: mutation => !mutation.type.startsWith('tabs/')
})

if (window.location.href.includes('refreshLocalStorage')) {
  localStorage.clear()
}

const store = new Vuex.Store({
  modules: {
    tables,
    tabs,
    billing,
    catalog,
    auth,
    config,
    till,
    promotions,
    reservations,
    couriers,
    deliveryCompanies,
    status,
    scrapper
  },
  strict: debug,
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION
  },
  plugins: [vuexPersist.plugin]
})

export default store
