<template>
  <div class="h-screen flex flex-col overflow-hidden">
    <top-bar show-back>
      <template slot="center">
        <img src="@/assets/logo-light.svg" class="logo-height" />
      </template>
    </top-bar>
    <div class="bg-white w-full h-full justify-center">
      <div class="w-full bg-gray-400 height-3-5 flex flex-row justify-center">
        <div class="w-1/2 self-center">
          <l-input
            val
            v-model="query"
            icon="search"
            :placeholder="$t('tabs.search-placeholder')"
            small
          />
        </div>
      </div>
      <div
        class="py-12 width-45 m-auto height-500 overflow-y-scroll scrolling-touch"
      >
        <div
          v-for="tab in filteredTabs"
          :key="tab.id"
          class="flex flex-row text-blue justify-between items-center py-3 leading-tight border-b-1 border-gray-200"
          @click="goToTabDetails(tab.id)"
        >
          <div class="flex flex-row items-center">
            <div
              class="rounded-full status w-6 h-6"
              :class="{
                cancelled: isCancelled(tab),
                alert: hasAlert(tab),
                'bg-light-blue': tab.open,
                closed: !tab.open,
                'billing-started': tab.billingStartedTime,
                scheduled: !tab.activationTime
              }"
            />
            <div class="ml-5">
              <div class="flex flex-row">
                <div class="font-bold">{{ tab.code }}</div>
                <div class="mx-1">/</div>
                <div>
                  {{ tab.name }}
                </div>
              </div>
              <div class="text-gray-400">
                {{ tab.source }}
              </div>
            </div>
          </div>
          <div class="text-red">
            {{ tab.activationTime || tab.schedulingTime | time }}
          </div>
        </div>
        <div
          class="transparent-gradient bottom-0 w-1/2 h-20 absolute pointer-events-none"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '../core/TopBar'
import { mapState, mapGetters } from 'vuex'
import LInput from '@last/core-ui/components/LInput'

export default {
  name: 'TabsSearchEngine',
  data() {
    return {
      query: ''
    }
  },
  methods: {
    goToTabDetails(tabId) {
      this.$router.push({ name: 'pos', params: { preSelectedTabId: tabId } })
    },
    isCancelled(tab) {
      return tab.deliveryOrder && tab.deliveryOrder.cancelTime
    },
    hasAlert(tab) {
      return (
        tab.deliveryOrder &&
        tab.deliveryOrder.errorTime &&
        !tab.deliveryOrder.errorResolved
      )
    }
  },
  computed: {
    ...mapGetters('tabs', ['getTotal']),
    ...mapState('tabs', ['tabs']),
    filteredTabs() {
      return Object.values(this.tabs)
        .filter(tab => {
          return (
            tab.code?.toLowerCase().includes(this.query.toLowerCase()) ||
            tab.name?.toLowerCase().includes(this.query.toLowerCase()) ||
            tab.customerInfo?.name
              ?.toLowerCase()
              .includes(this.query.toLowerCase()) ||
            tab.customerInfo?.phoneNumber
              ?.toLowerCase()
              .includes(this.query.toLowerCase()) ||
            this.getTotal(tab.id)
              .toString()
              .includes(this.query.replace('.', '').replace(',', ''))
          )
        })
        .sort((a, b) => new Date(b.activationTime) - new Date(a.activationTime))
    }
  },
  components: {
    TopBar,
    LInput
  }
}
</script>

<style lang="scss" scoped>
.logo-height {
  height: 2rem;
}
.height-3-5 {
  height: 3.5rem;
}

.border-b-1 {
  border-bottom-width: 0.0625rem;
}

.width-45 {
  width: 45%;
}

.height-500 {
  height: 31.25rem;
}

.transparent-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 80%);
}

.position-down {
  bottom: 12%;
}

.status {
  background: #4299e1;
  &.billing-started {
    @apply bg-error;
  }
  &.cancelled {
    background: white;
  }
  &.cancelled.selected:not(.closed) {
    @apply bg-error;
  }
  &.alert {
    background: white;
  }
  &.alert.selected:not(.closed) {
    @apply bg-yellow;
  }
  &.closed {
    background: #a0a0c0;
  }
  &.scheduled {
    @apply bg-blue-100;
  }
}
</style>
