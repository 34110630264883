<template>
  <div class="swiper-container" ref="swiperContainer">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
export default {
  props: {
    totalSlides: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    allowTouchMove: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      swiper: null,
      isBeingDestroyed: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.isBeingDestroyed) {
        return
      }
      this.swiper = new Swiper(this.$refs.swiperContainer, {
        pagination: {
          el: this.$refs.swiperPagination
        },
        allowTouchMove: this.allowTouchMove
      })
      let that = this
      this.swiper.on('slideChange', () => {
        that.$emit('slideChange', that.swiper.activeIndex)
      })
      this.swiper.slideTo(this.index)
    })
  },
  beforeDestroy() {
    this.isBeingDestroyed = true
    if (this.swiper) {
      this.swiper.destroy()
    }
  },
  watch: {
    totalSlides() {
      this.$nextTick(() => this.swiper.update())
    },
    index() {
      if (this.swiper) {
        this.swiper.slideTo(this.index)
      }
    }
  }
}
</script>

<style scoped></style>
