import Vue from 'vue'
import store from '@/store/index.js'
import i18n from '@/i18n.js'
import CashMachineStatus from '@/components/CashMachineStatus.vue'

function open(propsData) {
  const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
  const StatusComponent = vm.extend(CashMachineStatus)
  let container = document.createElement('div')
  document.body.appendChild(container)
  return new StatusComponent({
    el: container,
    store,
    i18n,
    propsData
  })
}

export function cashMachineStatus({ status, onCancel }) {
  let component = open({
    status,
    onCancel
  })
  component.$on('cancel', onCancel)
  component.$mount()
  return component
}

const Plugin = {
  install(Vue) {
    Vue.component('CashMachineStatus', CashMachineStatus)
    Vue.prototype['$cashmachinestatus'] = cashMachineStatus
  }
}

export default Plugin
