import sync from '@/sync/service.js'
import Vue from 'vue'
import { normalize, schema } from 'normalizr'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)

const reservationSchema = new schema.Entity('reservations')

const state = {
  reservations: {}
}

const getters = {
  getNearReservations: (state, _getters, _rootState) => {
    let range = moment.range(
      moment().subtract(240, 'minutes'),
      moment().add(240, 'minutes')
    )
    let nearReservations = Object.entries(state.reservations).reduce(
      (res, [reservationId, reservation]) => {
        if (range.contains(moment(reservation.dateTime))) {
          res[reservationId] = reservation
        }
        return res
      },
      {}
    )
    return nearReservations
  },
  getReservation:
    (state, _getters, _rootState, rootGetters) => (tableId, date) => {
      let range = moment.range(
        moment(date).subtract(15, 'minutes'),
        moment(date).add(rootGetters['config/reservations'].duration, 'minutes')
      )

      return Object.values(state.reservations).find(reservation => {
        let inRange = range.contains(moment(reservation.dateTime))
        let hasTab = reservation.tabId
        let rightTable = reservation.tables?.includes(tableId)

        return !hasTab && rightTable && inRange
      })
    }
}

const actions = {
  createReservation(_, reservation) {
    sync.record('reservationCreated', reservation)
  },
  editReservation(_, reservation) {
    sync.record('reservationChanged', reservation)
  },
  changeReservationTable(_, { reservationId, tableId, tables }) {
    sync.record('reservationTableChanged', { reservationId, tableId, tables })
  },
  cancelReservation(_, reservationId) {
    sync.record('reservationCancelled', { reservationId })
  },
  refreshReservations({ commit }, reservations) {
    commit(
      'refreshReservations',
      normalize(reservations, [reservationSchema]).entities
    )
  }
}

const mutations = {
  createReservation(state, reservation) {
    let { internalSource, ...cleanReservation } = reservation
    Vue.set(state.reservations, reservation.id, cleanReservation)
  },
  cancelReservation(state, { reservationId }) {
    state.reservations[reservationId].cancelled = true
  },
  editReservation(state, reservation) {
    let { internalSource, ...cleanReservation } = reservation
    state.reservations[reservation.id] = {
      ...state.reservations[reservation.id],
      ...cleanReservation
    }
  },
  changeReservationTable(state, { reservationId, tableId, tables }) {
    state.reservations[reservationId].tables = tables

    // Temporal code to be backward compatible
    if (!tables) {
      state.reservations[reservationId].tables = [tableId]
    }
  },
  refreshReservations(state, entities) {
    state.reservations = entities.reservations || {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
