import mqtt from 'mqtt'
import store from '@/store/index.js'
import sync from '@/sync/service.js'
import Logger from '@/logger.js'
import printerDiscovery from '@/printerDiscovery.js'

var client = mqtt.connect(process.env.VUE_APP_MQTT_URL)

function subscribe(locationId) {
  let topic = process.env.VUE_APP_MQTT_TOPIC + '-' + locationId
  client.subscribe(topic, () => {
    store.dispatch('status/updateMqttStatus', true)
  })
  sync.resync()
}

client.on('connect', function () {
  let locationId =
    store && !store.state.config.demoMode && store.state.auth.locationId
  if (locationId) {
    subscribe(locationId)
  }
})

client.on('error', function (error) {
  Logger.info('Mqtt error', error)
})

client.on('end', function () {
  store.dispatch('status/updateMqttStatus', false)
})

client.on('offline', function () {
  store.dispatch('status/updateMqttStatus', false)
})

client.on('disconnect', function () {
  store.dispatch('status/updateMqttStatus', false)
})

client.on('message', function (topic, data) {
  let message = JSON.parse(data)
  switch (message.type) {
    case 'catalogs':
      store.dispatch('catalog/refreshCatalogs', message.catalogs)
      break
    case 'catalog':
      store.dispatch('catalog/refreshCatalog', message.catalog)
      break
    case 'promotions':
      store.dispatch('promotions/refreshPromotions', message.promotions)
      break
    case 'couriers':
      store.dispatch('couriers/refreshCouriers', message.couriers)
      break
    case 'employees':
      store.dispatch('auth/refreshEmployees', message.employees)
      break
    case 'event':
      sync.playRemote(message.event)
      break
    case 'configUpdate':
      store.dispatch('config/refreshConfig', message.config)
      break
    case 'errorMessages':
      store.dispatch('status/refreshErrorMessages', message.errorMessages)
      break
    case 'resync':
      sync.resync()
      break
    case 'discoverPrinters':
      printerDiscovery.discoverPrinters(message.existingPrinters)
      break
  }
})

export default {
  subscribe: subscribe,
  isConnected: client.connected
}
