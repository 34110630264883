<template>
  <div class="h-full flex flex-col overflow-hidden">
    <top-bar show-back>
      <template slot="center">
        <div class="text-xl flex items-center uppercase">
          {{ $t('menu-availability.products') }}
        </div>
      </template>
    </top-bar>
    <div
      class="overflow-y-scroll scrolling-touch bg-gray-200 w-full h-full flex justify-center min-h-screen"
    >
      <div class="mt-10 bg-white rounded-big shadow w-3/5 h-full pb-10">
        <div class="flex flex-row cursor-pointer ml-4 mr-4">
          <div
            v-for="editable in types"
            :key="editable"
            class="flex w-1/4 justify-center text-center items-center uppercase text-gray-400 p-8"
            :class="{
              'text-blue border-b-4 border-blue': editingType === editable
            }"
            @click="setEditingType(editable)"
          >
            <div>
              {{ $t(`menu-availability.${editable.replace('_', '-')}`) }}
            </div>
          </div>
        </div>

        <div class="m-8">
          <l-input
            :placeholder="
              $t(
                `menu-availability.${editingType.replace(
                  '_',
                  '-'
                )}-search-placeholder`
              )
            "
            icon="search"
            icon-class="text-gray-500"
            v-model="searchQuery"
          />
        </div>
        <div class="flex flex-row justify-start ml-8 mt-8 mb-8">
          <div class="flex justify-center text-center items-center">
            <l-pill
              :value="filterBy === 'ALL'"
              @input="setFilterBy('ALL')"
              :label="$t('menu-availability.filter-by-all')"
              class="mr-3"
            />
          </div>
          <div class="flexjustify-center text-center items-center">
            <l-pill
              :value="filterBy === 'ENABLED'"
              @input="setFilterBy('ENABLED')"
              :label="$t('menu-availability.filter-by-enabled')"
              class="mr-3"
            />
          </div>
          <div class="flex justify-center text-center items-center">
            <l-pill
              :value="filterBy === 'DISABLED'"
              @input="setFilterBy('DISABLED')"
              :label="$t('menu-availability.filter-by-disabled')"
              class="mr-3"
            />
          </div>
        </div>
        <div class="flex relative" v-for="item in filteredItems" :key="item.id">
          <div class="pl-8 pt-4 text-blue w-5/6">
            {{ item.name }}
          </div>
          <div class="absolute right-0 mr-8 mt-4">
            <l-switch
              :value="item.enabled"
              @input="enabled => toggleEnabled(item, enabled)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LInput from '@last/core-ui/components/LInput.vue'
import TopBar from '@/components/core/TopBar.vue'
import api from '@/api.js'
import LSwitch from '@last/core-ui/components/LSwitch.vue'
import LPill from '@last/core-ui/components/LPill.vue'
import { mapState } from 'vuex'

export default {
  name: 'MenuAvailability',
  data() {
    return {
      editingType: 'products',
      filterBy: 'ALL',
      products: [],
      modifierGroups: [],
      modifiers: [],
      combos: [],
      searchQuery: ''
    }
  },
  async mounted() {
    let { data } = await api.get('/menu-availability')
    this.products = data.products
    this.combos = data.combos
    this.modifierGroups = data.modifierGroups
    this.modifiers = data.modifiers
  },
  methods: {
    setEditingType(newType) {
      this.editingType = newType
    },
    setFilterBy(newFilterBy) {
      this.filterBy = newFilterBy
    },
    async toggleEnabled(item, enabled) {
      const unit = this.nameToData[this.editingType].find(unit => {
        return unit.id === item.id
      })
      unit.enabled = enabled
      try {
        await api.put(
          `/locations/${this.locationId}/${this.editingType.toLowerCase()}/${
            item.id
          }`,
          { enabled }
        )
      } catch (e) {
        unit.enabled = !enabled
        this.$lnotification.create({
          title: this.$t('menu-availability.error-saving-changes')
        })
      }
    }
  },
  computed: {
    types() {
      return ['products', 'combos', 'modifiers', 'modifier_groups']
    },
    ...mapState('auth', ['locationId']),
    filteredItems() {
      return this.nameToData[this.editingType]
        .filter(
          it =>
            (this.filterBy === 'ENABLED' && it.enabled) ||
            (this.filterBy === 'DISABLED' && !it.enabled) ||
            this.filterBy === 'ALL'
        )
        .filter(item => item.name)
        .filter(item =>
          item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
        .sort((i1, i2) => i1.name.localeCompare(i2.name))
    },
    nameToData() {
      return {
        products: this.products,
        combos: this.combos,
        modifiers: this.modifiers,
        modifier_groups: this.modifierGroups
      }
    }
  },
  components: {
    LInput,
    TopBar,
    LSwitch,
    LPill
  }
}
</script>

<style scoped></style>
