import lastUtils from '@last/core/src/lastUtils.js'

class DevCashMachine {
  async init(inputAmountListener, totalAmountListener) {
    this.inputAmountListener = inputAmountListener
    this.totalAmountListener = totalAmountListener
  }

  async charge(amount) {
    await lastUtils.sleep(1000)
    let inputAmount = 0
    while (inputAmount < amount && !this.cancelled) {
      inputAmount += Math.min(500, amount - inputAmount)
      this.inputAmountListener(inputAmount)
      await lastUtils.sleep(1000)
    }
    if (this.cancelled) {
      this.inputAmountListener(0)
      this.cancelled = false
      return 0
    } else {
      return amount
    }
  }

  async payIn(amount) {
    return this.charge(amount)
  }

  async payOut(amount) {
    await lastUtils.sleep(1000)
    return amount
  }

  async close() {}

  async cancel() {
    this.cancelled = true
  }

  async refreshTotalAmount() {
    await lastUtils.sleep(1000)
    this.totalAmountListener(Math.round(Math.random() * 1000))
  }
}

export default new DevCashMachine()
