import TicketPrinter from '@/ticketPrinter.js'
import { mapState, mapGetters, mapActions } from 'vuex'
import Logger from '@/logger.js'

let mixin = {
  methods: {
    ...mapActions('tabs', [
      'createKitchenOrders',
      'addKitchenOrderVersion',
      'createKitchenOrderByProducts'
    ]),
    printOrders(orderIds) {
      orderIds
        .map(orderId => this.getKitchenOrder(orderId))
        .forEach(order => {
          setTimeout(() => {
            TicketPrinter.printKitchenOrder(order)
          })
        })
    },
    async sendKitchenOrderByProducts(products, print = true) {
      let orderIds = await this.createKitchenOrderByProducts({
        tabId: this.tabId,
        products
      })
      if (print) {
        this.printOrders(orderIds)
      }
      this.showKitchenModal = false
    },
    async sendKitchenOrder(course) {
      if (course === 'all' && this.modifiedKitchenOrders.length > 0) {
        this.sendKitchenOrderModifications()
      }
      let orderIds = await this.createKitchenOrders({
        tabId: this.tabId,
        course
      })
      this.printOrders(orderIds)
      this.showKitchenModal = false
    },
    sendKitchenOrderModifications(print = true) {
      let orderIds = this.modifiedKitchenOrders
      orderIds.forEach(this.addKitchenOrderVersion)
      if (print) {
        this.printOrders(orderIds)
      }
      this.showKitchenModal = false
    },
    getKitchenOrder(id) {
      return this.kitchenOrders[id]
    },
    compareModifiers(oldModifiers = [], newModifiers = []) {
      let oldModifierIds = oldModifiers.map(modifier =>
        String(modifier.catalogModifierId || modifier.tabModifierId)
      )
      let newModifierIds = newModifiers.map(modifier =>
        String(modifier.parentModifierId || modifier.id)
      )
      return (
        [
          ...oldModifierIds.filter(m => !newModifierIds.includes(m)),
          ...newModifierIds.filter(m => !oldModifierIds.includes(m))
        ].length > 0
      )
    }
  },
  computed: {
    ...mapGetters('tabs', ['getAllProducts', 'getUnsentProducts']),
    ...mapState('tabs', ['tabs', 'kitchenOrders', 'products']),
    ...mapState('config', ['config']),
    modifiedKitchenOrders() {
      let kitchenProducts = Object.values(this.products)
        .flatMap(product => {
          if (product.comboProducts && product.comboProducts.length > 0) {
            return product.comboProducts.map(comboProduct => ({
              ...comboProduct,
              quantity: comboProduct.quantity * product.quantity,
              fromCombo: true
            }))
          } else {
            return [product]
          }
        })
        .reduce((res, product) => {
          res[product.id] = product
          return res
        }, {})

      return (this.tabs[this.tabId]?.kitchenOrders || [])
        .map(id => this.kitchenOrders[id])
        .filter(order => {
          let products = order.versions.slice(-1)[0].products
          return products.some(product => {
            let tabProduct = kitchenProducts[product.tabProductId]
            if (!tabProduct) return true

            let productId = product.fromCombo
              ? product.comboId
              : product.tabProductId
            let tabId = this.products[productId]?.tab
            let movedCheck = !!tabId && tabId !== this.tabId
            let quantityCheck = tabProduct.quantity !== product.quantity
            let modifiersCheck = this.compareModifiers(
              product.modifiers,
              tabProduct.modifiers
            )
            let commentsCheck =
              tabProduct.comments !== product.comments &&
              (tabProduct.comments || product.comments)
            if (
              quantityCheck ||
              modifiersCheck ||
              commentsCheck ||
              movedCheck
            ) {
              Logger.info('kitchen order modified', {
                order,
                product,
                tabProduct,
                quantityCheck,
                modifiersCheck,
                commentsCheck,
                movedCheck
              })
            }
            return (
              quantityCheck || modifiersCheck || commentsCheck || movedCheck
            )
          })
        })
        .map(order => order.id)
    },
    ordersNotPrinted() {
      return (
        (this.tabs[this.tabId]?.kitchenOrders || [])
          .map(id => this.kitchenOrders[id])
          .filter(order => !order.printedTime && order.copies > 0).length > 0
      )
    },
    pendingOrders() {
      return (
        this.getUnsentProducts(this.tabId).length > 0 ||
        this.modifiedKitchenOrders.length > 0
      )
    }
  }
}

export default mixin
