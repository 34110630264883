import { render, staticRenderFns } from "./LModalCtas.vue?vue&type=template&id=24f04bce&scoped=true&functional=true&"
import script from "./LModalCtas.vue?vue&type=script&lang=js&"
export * from "./LModalCtas.vue?vue&type=script&lang=js&"
import style0 from "./LModalCtas.vue?vue&type=style&index=0&id=24f04bce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../pos/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "24f04bce",
  null
  
)

export default component.exports