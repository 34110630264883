<template>
  <div class="h-screen flex flex-col bg-white" v-if="tab">
    <top-bar show-back>
      <template slot="center">
        <div class="flex items-center text-xl">
          <div class="flex">
            <div v-if="tab.code" class="tab-code whitespace-no-wrap">
              <span class="font-bold">{{ tab.code }}</span>
              <span v-if="tab.tableName"> / {{ tab.tableName }}</span>
              <span v-else-if="tab.name"> / {{ tab.name }}</span>
            </div>
            <div v-else-if="tab.tableName">{{ tab.tableName }}</div>
            <div v-else>{{ tab.name }}</div>
            <div v-if="tab.activationTime" class="ml-4 text-gray-400">
              {{ tab.activationTime | time }}
            </div>
          </div>
        </div>
      </template>
    </top-bar>
    <div class="flex items-center border-b border-gray-300">
      <div class="flex-1 p-2">
        <l-select :options="paymentMethods" optionValue="name" optionLabel="name" v-model="selectedPaymentMethod"/>
      </div>
      <bill-discount v-if="isPendingBill" :tab-id="tabId" :discount.sync="discount" @remove-discount="removeDiscount">
        <div class="mr-2 w-12 h-12 border border-blue rounded-full flex items-center justify-center" :class="{'bg-red border-red' : hasDiscount}">
          <icon name="discount" class="text-white" :class="{'text-blue': !hasDiscount}" />
        </div>
      </bill-discount>
    </div>
    <div class="flex flex-col h-full">
      <charge-calculator v-if="bill"
        @charge="chargeAmount"
        @close="closeBill"
        :pending-amount="(bill && bill.pending) || 0"
        :payment-method="selectedPaymentMethod && selectedPaymentMethod.toLowerCase()"
      />
      <close-tab
        :tab-id="closeTabId"
        @cancel="closeTabId = null"
        :show-action-text="false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import TopBar from '@/mobile/components/TopBar.vue'
import ChargeCalculator from '@/mobile/components/ChargeCalculator.vue'
import TicketPrinter from '@/ticketPrinter.js'
import Icon from '@last/core-ui/components/Icon.vue'
import LSelect from '@last/core-ui/components/LSelect.vue'
import CloseTab from '@/components/checkout/CloseTab.vue'
import BillDiscount from '@/mobile/components/BillDiscount.vue'
import Bills from '@last/core/src/billsGenerator'
import CheckoutMixin from '@/components/checkout/checkoutMixin'

export default {
  name: 'Checkout',
  mixins: [CheckoutMixin],
  data() {
    return {
      showTabDetails: false,
      disableSwipe: false,
      charging: false,
      closeTabId: null,
      selectedPaymentMethod: null,
      currentDiscount: {},
      selectedBillId: null
    }
  },
  mounted() {
    this.selectedBillId = this.$route.query?.billId
    if (!this.bill) {
      this.closeTabId = this.tabId
    }
    this.selectedPaymentMethod = this.config.paymentMethodsConfig[0].name
    if (this.tabGlobalPromotion) {
      this.currentDiscount = {
        type: this.tabGlobalPromotion.discountType,
        amount: this.tabGlobalPromotion.discountAmount,
        promotionId: this.tabGlobalPromotion.promotionId,
        concept: this.tabGlobalPromotion.concept
      }
    }
  },
  methods: {
    ...mapActions('tabs', [
      'addBill',
      'removeBill',
      'addPayment',
      'regenerateBill',
      'updateTerraceSurchargePercentage'
    ]),
    ...mapActions('promotions', ['updateTabPromotion', 'deleteTabPromotion']),
    chargeAmount(amount) {
      this.charge(amount, this.paymentMethod)
      this.currentDiscount = {}
      this.$lnotification.create({
        title: this.$t('notifications.payment-successful'),
        iconColor: "green"
      })
    },
    closeBill() {
      this.addBill({ tabId: this.tabId, bill: this.bill })
      this.currentDiscount = {}
      if (!this.checkoutCompleteWithPayments) {
        this.$router.push({
          name: 'billsList',
        })
      }
    },
    paymentSucceeded(
      amount,
      change = null,
      paymentMethod,
      paymentId,
      metadata
    ) {
      const billId = this.bill.id
      this.addBill({ tabId: this.tabId, bill: this.bill })
      this.selectedBillId = billId
      this.addPayment({
        billId: this.bill.id,
        change: paymentMethod.type === 'cash' && change,
        tip: paymentMethod.type !== 'cash' && change,
        paymentId,
        amount,
        type: paymentMethod.type,
        metadata,
        tabId: this.tabId
      })
      if (this.bill.pending <= 0) {
        this.closeBill()
        if (((this.tab.tables?.length || 0) === 0 &&
          this.config.organizationConfig.takeawayAutoprint) ||
          this.config.organizationConfig.restaurantAutoprint) {
            TicketPrinter.printBill(this.bill)
        }
      }
      this.currentDiscount = {}
    },
  },
  computed: {
    ...mapState('tabs', ['tabs']),
    ...mapState('config', ['config']),
    ...mapGetters('tabs', [
      'getCourseProducts',
      'getSeatProducts',
      'getSharedProducts',
      'getTotal',
      'getDeliveryFee',
      'getBills',
      'getAllProducts',
      'getPendingBill'
    ]),
    ...mapGetters('billing', ['getCurrentBill']),
    ...mapGetters('promotions', ['getTabGlobalPromotion']),
    isPendingBill() {
      return !this.$query?.billId
    },
    tabId() {
      return this.$route.params.tabId
    },
    tab() {
      return this.tabs[this.tabId]
    },
    bills() {
      return this.getBills(this.tabId)
    },
    bill() {
      const selectedBill = this.bills.find(bill => bill.id === this.selectedBillId)
      if (selectedBill) 
        return selectedBill

      let bill = this.getPendingBill(this.tabId)
      if (!bill) {
        for (let bill of this.bills) {
          if (bill.pending > 0) {
            return bill
          }
        }
        return null
      }
      return Bills.addPaymentInfo(bill, [])
    },
    tabProducts() {
      return this.getAllProducts(this.tabId)
    },
    checkoutCompleteWithPayments() {
      let tabProducts = this.getAllProducts(this.tabId)
      let allProductsBilled =
        tabProducts
          .map(product => product.notBilledQuantity)
          .reduce((total, quantity) => total + quantity, 0) === 0
      let pendingPayments = this.bills
        .map(bill => bill.pending)
        .reduce((sum, pending) => sum + pending, 0)
      return allProductsBilled && pendingPayments === 0
    },
    discount: {
      get() {
        return this.currentDiscount
      },
      set(discount) {
        this.updateTabPromotion({
          tabId: this.tabId,
          promotion: {
            discountType: discount.type,
            discountAmount: discount.amount,
            name: discount.name,
            id: discount.promotionId,
            global: discount.global
          }
        })
        this.currentDiscount = discount
      }
    },
    hasDiscount() {
      return this.discount && this.discount.amount > 0
    },
    tabGlobalPromotion() {
      return this.getTabGlobalPromotion(this.tabId)
    },
    paymentMethods() {
      return this.config.paymentMethodsConfig.map(method => ({
        ...method,
        type: method.name.toLowerCase()
      }))
    },
    paymentMethod() {
      return this.paymentMethods.find(
        method => method.name === this.selectedPaymentMethod
      )
    }
  },
  watch: {
    checkoutCompleteWithPayments(complete) {
      let tabProducts = this.getAllProducts(this.tabId)
      if (complete && tabProducts.length > 0) {
        this.closeTabId = this.tabId
      }
    }
  },
  components: {
    TopBar,
    ChargeCalculator,
    CloseTab,
    Icon,
    LSelect,
    BillDiscount
  }
}
</script>

<style scoped ChargeCalculator lang="scss">
@import '@/variables.scss';
.h-screen {
  height: 100vh;
  min-height: -webkit-fill-available;
}
</style>
