var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-blue-700 h-full w-full px-10 pt-12 text-lg summary-size relative"},[_c('div',{staticClass:"text-white uppercase mb-5"},[_vm._v(" "+_vm._s(_vm.$t('end-shift.shift-summary'))+" ")]),_c('div',{staticClass:"h-full overflow-y-scroll scrolling-touch"},[_vm._l((_vm.groupedTillsReport),function(group,index){return _c('div',{key:index,staticClass:"text-sm mb-5"},[_c('div',{staticClass:"text-gray-400 text-base mb-3"},[_vm._v(" "+_vm._s(_vm.$t('end-shift.total'))+" "+_vm._s(group.type)+" ")]),_c('div',{staticClass:"flex flex-row justify-between text-gray-300"},[_c('div',[_vm._v(_vm._s(_vm.$t('end-shift.expected')))]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(group.expectedEndAmount)))])]),_c('div',{staticClass:"flex flex-row justify-between text-gray-300"},[_c('div',[_vm._v(_vm._s(_vm.$t('end-shift.declared')))]),(group.endAmount || group.endAmount === 0)?_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(group.endAmount))+" ")]):_c('div',[_vm._v(" - ")])]),_c('div',{staticClass:"flex flex-row justify-between text-gray-300"},[_c('div',{staticClass:"text-gray-400"},[_vm._v(_vm._s(_vm.$t('end-shift.difference')))]),(group.endAmount || group.endAmount === 0)?_c('div',{class:{
            'text-green': group.endAmount - group.expectedEndAmount >= 0,
            'text-red': group.endAmount - group.expectedEndAmount < 0
          }},[_vm._v(" "+_vm._s(_vm._f("currency")((group.endAmount - group.expectedEndAmount)))+" ")]):_c('div',[_vm._v(" - ")])])])}),_c('div',{staticClass:"separator bg-gray-400 mb-5 mt-10"}),_c('div',{staticClass:"text-sm text-gray-400 mb-40"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',[_vm._v(_vm._s(_vm.$t('end-shift.total-expected')))]),_c('div',[_vm._v(_vm._s(_vm._f("currency")(_vm.totalGrouped.totalExpectedEndAmount)))])]),_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',[_vm._v(_vm._s(_vm.$t('end-shift.total-declared')))]),(
            _vm.totalGrouped.totalEndAmount || _vm.totalGrouped.totalEndAmount === 0
          )?_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalGrouped.totalEndAmount))+" ")]):_c('div',[_vm._v(" - ")])]),_c('div',{staticClass:"flex flex-row justify-between"},[_c('div',[_vm._v(_vm._s(_vm.$t('end-shift.total-difference')))]),(
            _vm.totalGrouped.totalEndAmount || _vm.totalGrouped.totalEndAmount === 0
          )?_c('div',{class:{
            'text-green': _vm.totalGrouped.totalDifference >= 0,
            'text-red': _vm.totalGrouped.totalDifference < 0
          }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalGrouped.totalDifference))+" ")]):_c('div',[_vm._v("-")])])])],2),_c('div',{staticClass:"transparent-gradient position-down w-10/12 h-16 absolute"}),_c('div',{staticClass:"text-center text-white underline absolute bg-blue-700 h-12 pt-3 bottom-0 cursor-pointer text-sm inset-x-0 flex-shrink-0",on:{"click":function($event){return _vm.$emit('printPreview')}}},[_vm._v(" "+_vm._s(_vm.$t('end-shift.print-shift-summary'))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }