var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((Object.keys(
      _vm.virtualBrand.externalDeliveryCompaniesClosingTimes
    )),function(company){return _c('div',{key:company,staticClass:"flex flex-row ml-8 mt-5 justify-between items-center"},[_c('div',{staticClass:"text-blue text-sm"},[_vm._v(_vm._s(company))]),_c('div',{staticClass:"mr-8 flex flex-row items-center"},[(
          _vm.deliveryIsClosed(
            _vm.virtualBrand.externalDeliveryCompaniesClosingTimes[company]
          )
        )?_c('div',{staticClass:"text-red mr-10 flex flex-row items-center"},[_c('div',[_c('icon',{staticClass:"text-red w-3 h-3",attrs:{"name":"clock"}})],1),_c('div',{staticClass:"ml-1 text-sm"},[_vm._v(" "+_vm._s(_vm.timeFrom( _vm.virtualBrand.externalDeliveryCompaniesClosingTimes[company] ))+" ")])]):_vm._e(),_c('l-switch',{attrs:{"value":!_vm.deliveryIsClosed(
            _vm.virtualBrand.externalDeliveryCompaniesClosingTimes[company]
          )},on:{"input":function (value) { return _vm.setValue(_vm.virtualBrand.id, company, value); }}})],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }