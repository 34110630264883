<template>
  <div class="bg flex bg-gray-200 height-3-5">
    <div class="flex-1 flex height-3-5">
      <div
        v-for="floorplan in floorplans"
        :key="floorplan.id"
        class="uppercase text-sm py-4 px-6 flex items-center text-blue border-r-4 border-gray-200 floorplan-tab whitespace-no-wrap"
        :class="{ 'border-red bg-white': selectedTab === floorplan.id }"
        @click="$emit('update:selectedTab', floorplan.id)"
      >
        {{ floorplan.name + ' (' + tabCount[floorplan.id] + ')' }}
      </div>
    </div>
    <div class="flex-1 flex justify-end">
      <div
        @click="$emit('update:selectedTab', 'delivery')"
        :class="{
          'text-white bg-green': selectedTab === 'delivery',
          'text-blue': selectedTab !== 'delivery'
        }"
        class="px-4 h-full flex items-center"
      >
        <icon name="delivery" class="delivery" />
      </div>
      <div
        @click="$emit('update:selectedTab', 'takeAway')"
        :class="{
          'text-white bg-green': selectedTab === 'takeAway',
          'text-blue': selectedTab !== 'takeAway'
        }"
        class="px-4 h-full flex items-center"
      >
        <icon name="take-away" class="take-away" />
      </div>
      <div
        @click="$emit('update:selectedTab', 'all')"
        class="uppercase text-sm py-4 px-6 bg-gray-300 flex items-center text-blue"
        :class="{ 'text-white bg-green': selectedTab === 'all' }"
      >
        {{ $t('navbar.tabs') + ' (' + tabCount['all'] + ')' }}
      </div>
      <router-link
        :to="{ name: 'tabsSearchEngine' }"
        class="flex items-center px-3 bg-gray-400"
        @click.native="$emit('close')"
      >
        <icon name="zoom" class="text-white zoom-icon" />
      </router-link>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'NavBar',
  props: {
    floorplans: {
      type: Array,
      default: () => []
    },
    tabCount: {
      type: Object,
      default: () => {}
    },
    selectedTab: {
      type: String,
      default: null
    }
  },
  components: {
    Icon
  }
}
</script>

<style scoped>
.height-3-5 {
  height: 3.5rem;
}
.delivery {
  width: 2.438rem;
  height: 1.75rem;
}
.take-away {
  width: 2rem;
  height: 2rem;
}
.zoom-icon {
  @apply w-8 h-8;
}
</style>
