<template>
  <div class="w-full h-full bg-white sm:bg-gray-200 overflow-hidden">
    <categories
      @category-selected="selectCategory"
      :catalog-id="catalogId"
      :show-promotions="arePromotionsAvailable"
    />
    <div
      v-if="selectedCategoryId === 'promotions'"
      class="overflow-y-scroll h-full pt-5"
    >
      <promotion-list
        :title="$t('ordering.available-promotions')"
        :promotions="availablePromotionsWithNoPoints"
        @promotionSelected="updateTabPromotionIntoTab"
      />
      <promotion-list
        :promotions="availablePromotionsWithPoints"
        @promotionSelected="updateTabPromotionIntoTab"
      >
        <template slot="title">
          {{ $t('ordering.available-promotions-with-points') }} -
          <span class="text-green">{{
            $t('ordering.available-points', { points: customerPoints })
          }}</span>
        </template>
      </promotion-list>

      <div
        class="flex flex-col"
        v-if="this.availablePromotionProducts.length > 0"
      >
        <h3 class="px-5">
          {{ $t('ordering.promotions-with-points') }} -
          <span class="text-green">{{
            $t('ordering.available-points', { points: customerPoints })
          }}</span>
        </h3>
        <div
          class="relative flex flex-wrap p-3 pb-48 pt-0 overflow-scroll scrolling-touch"
        >
          <promotion-product
            v-for="product in availablePromotionProducts"
            :key="product.id"
            :product="product"
            @selected="$emit('selectedProduct', product)"
          />
        </div>
      </div>
    </div>
    <product-list
      v-else-if="selectedCategoryId"
      :products="products"
      @selected-product="product => $emit('selectedProduct', product)"
    />
    <div
      v-if="!['promotions'].includes(selectedCategoryId)"
      class="hidden sm:block fixed bottom-0 right-0 p-3 mr-2 bg-white border border-gray-300 rounded-full shadow-lg plus open-product-button"
      @click="newOpenProduct"
    >
      <icon name="plus" class="w-10 h-10 text-blue" />
    </div>
    <open-product
      v-if="showingOpenProduct"
      @close="showingOpenProduct = false"
      @save="saveOpenProduct"
    />
  </div>
</template>

<script>
import PromotionList from '@/components/ordering/PromotionList.vue'
import ProductList from '@/components/ordering/ProductList.vue'
import PromotionProduct from '@/components/ordering/PromotionProduct.vue'
import OpenProduct from '@/components/ordering/OpenProduct.vue'
import Categories from '@/components/ordering/Categories.vue'
import PromotionFilter from '@last/core/src/PromotionFilter'
import Icon from '@last/core-ui/components/Icon.vue'
import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'MenuBrowser',
  props: {
    catalogId: {
      type: String,
      default: null
    },
    customerPoints: {
      type: Number,
      default: 0
    },
    customerUsedPromotions: {
      type: Array,
      default: () => []
    },
    selectedSeat: {
      type: Number,
      default: null
    },
    selectedCourse: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedCategoryId: null,
      showingOpenProduct: false
    }
  },
  mounted() {
    if (this.$route.query.selectedCategoryId) {
      this.selectedCategoryId = this.$route.query.selectedCategoryId
    }
  },
  methods: {
    ...mapActions('promotions', ['updateTabPromotion']),
    async newOpenProduct() {
      const hasPermission = await this.$confirm('OPEN_PRODUCTS_CREATOR')
      if (hasPermission) {
        this.showingOpenProduct = true
      }
    },
    saveOpenProduct(openProduct) {
      let product = {
        ...openProduct,
        categoryId: this.selectedCategoryId
      }
      this.$emit('selectedProduct', product)
      this.showingOpenProduct = false
    },
    selectCategory(id) {
      this.selectedCategoryId = id
      if (this.selectedCategoryId !== this.$route.query.selectedCategoryId) {
        this.$router.replace({
          query: { ...this.$route.query, selectedCategoryId: id }
        })
      }
    },
    async updateTabPromotionIntoTab(promotion) {
      await this.updateTabPromotion({
        promotion,
        tabId: this.$route.params.tabId
      })
    },
    productInCatalog(promotion) {
      if (promotion.products) {
        return promotion.products.some(id => this.catalogProductsMap[id])
      } else if (promotion.categories) {
        return promotion.categories.some(id =>
          this.catalogCategories.includes(id)
        )
      } else {
        return false
      }
    }
  },
  computed: {
    ...mapState('tabs', ['tabs', 'products']),
    ...mapState('catalog', ['catalogs', 'categories']),
    ...mapState('promotions', ['tabPromotions']),
    ...mapState('auth', ['locationId']),
    ...mapGetters('promotions', ['getPromotions', 'getCustomerPromotions']),
    ...mapGetters('tabs', ['getCustomerId', 'getAllProducts', 'getTotal']),
    ...mapGetters('auth', ['currentEmployee']),
    products() {
      return this.categories[this.selectedCategoryId]?.products || []
    },
    catalogCategories() {
      return this.catalogs[this.catalogId].categories
    },
    catalogProductsMap() {
      return this.catalogCategories
        .flatMap(categoryId => this.categories[categoryId].products)
        .reduce((res, product) => {
          res[product.organizationProductId || product.organizationComboId] =
            product
          return res
        }, {})
    },
    arePromotionsAvailable() {
      return this.availablePromotions.length > 0
    },
    availablePromotionsWithNoPoints() {
      return this.availablePromotions
        .filter(promotion => !promotion.pointsExpense)
        .filter(promotion => {
          return ['2x1', 'percentage'].includes(promotion.discountType)
        })
        .map(promotion => ({
          ...promotion,
          selected: (this.tabPromotions[this.$route.params.tabId] || []).some(
            tabPromotion => tabPromotion.promotionId === promotion.id
          )
        }))
    },
    availablePromotionsWithPoints() {
      return this.availablePromotions
        .filter(promotion => promotion.pointsExpense)
        .filter(promotion => {
          return ['2x1', 'percentage'].includes(promotion.discountType)
        })
        .map(promotion => ({
          ...promotion,
          selected: (this.tabPromotions[this.$route.params.tabId] || []).some(
            tabPromotion => tabPromotion.promotionId === promotion.id
          )
        }))
        .map(promotion => ({
          ...promotion,
          disabled: promotion.pointsExpense > this.customerPoints
        }))
    },
    availablePromotionProducts() {
      return this.availablePromotions
        .filter(promotion => promotion.discountType === 'products')
        .flatMap(promotion => {
          let promotionProducts = []
          if (promotion.products) promotionProducts = promotion.products
          else if (promotion.categories)
            promotionProducts = promotion.categories
              .filter(categoryId => !!this.categories[categoryId])
              .flatMap(categoryId =>
                this.categories[categoryId].products.map(product => product.id)
              )

          return promotionProducts
            .filter(id => this.catalogProductsMap[id])
            .map(id => ({
              ...this.catalogProductsMap[id],
              price: 0,
              disabled: promotion.pointsExpense > this.customerPoints,
              isValid: promotion.isValid,
              pointsExpense: promotion.pointsExpense
            }))
        })
    },
    availablePromotions() {
      let customerId = this.getCustomerId(this.$route.params.tabId)
      let promotions = [
        ...this.getPromotions,
        ...this.getCustomerPromotions(customerId)
      ]
        .filter(promotion => {
          return ['2x1', 'percentage', 'products'].includes(
            promotion.discountType
          )
        })
        .filter(this.productInCatalog)

      let today = moment().locale('en').format('dddd').toLowerCase()

      return new PromotionFilter(
        this.getTotal(this.$route.params.tabId),
        this.locationId,
        today,
        this.tabs[this.$route.params.tabId].pickupType,
        moment().format(),
        customerId,
        this.customerUsedPromotions
      )
        .addValidation(promotions)
        .filter(p => p.isVisible)
    }
  },
  components: {
    Icon,
    PromotionList,
    ProductList,
    PromotionProduct,
    OpenProduct,
    Categories
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

#menu-browser {
  height: 100%;
  background: $background;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.open-product-button {
  margin-right: 20.625rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
}
</style>
