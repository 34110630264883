import store from '@/store/index'

function deleteOldTabs() {
  store.dispatch('tabs/deleteOldTabs')
}

function updateTabsActivationTime() {
  store.dispatch('tabs/updateTabsActivationTime')
}

function start() {
  setInterval(() => {
    deleteOldTabs()
  }, 60 * 60 * 1000)
  setInterval(() => {
    updateTabsActivationTime()
  }, 60 * 1000)
}

export default {
  start
}
