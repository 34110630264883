import { CapgoUpdater } from './capgo'
import { ElectronUpdater } from './electron'
import { GenericUpdater } from './generic'

export function getUpdater(platform) {
  if (platform === 'electron') {
    return new ElectronUpdater()
  } else if (platform === 'ios' || platform === 'android') {
    return new CapgoUpdater(platform)
  }
  return new GenericUpdater(platform)
}
