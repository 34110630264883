import { mapGetters, mapActions } from 'vuex'

let mixin = {
  methods: {
    ...mapActions('tabs', ['openTab']),
    async createTakeAwayTab() {
      let tab = {
        ...this.newTab,
        deliveryOrder: {
          source: this.newTab.source,
          status: 'CREATED',
          deliveryOrderStatuses: [
            {
              status: 'CREATED',
              creationTime: new Date()
            }
          ]
        }
      }
      let tabId = await this.openTab({ tableId: null, tab })
      this.$emit('tabCreated', tabId)
      this.$emit('close')
      this.$router.push({
        name: 'orderManagement',
        params: { tabId }
      })
    }
  },
  computed: {
    ...mapGetters('config', ['virtualBrands']),
    enabledBrands() {
      return this.virtualBrands.filter(virtualBrand => virtualBrand.enabled)
    },
    numberOfPeople() {
      let numberOfPeople = []
      for (let i = 0; i < 20; i++) {
        numberOfPeople[i] = {
          label: `${i < 9 ? '0' : ''}${i + 1} ${this.$t(
            'new-delivery.client-number'
          )}`,
          value: i + 1
        }
      }
      return numberOfPeople
    }
  }
}

export default mixin
