<template>
  <l-dropdown
    position="top"
    class="w-full"
    :class="{ 'opacity-50 pointer-events-none': disabled }"
  >
    <template slot="trigger" slot-scope="{ toggle }">
      <l-input @click.native.stop="toggle" icon="calendar">
        <div
          class="appearance-none focus:outline-none h-full w-full rounded-small line-height"
        >
          <template v-if="value">
            <div class="flex items-center">
              <span class="w-full px-4 py-3 leading-tight">{{
                formattedPickupTime
              }}</span>
              <icon
                name="close"
                class="arrow mr-4 icon-size"
                @click.native.stop="remove"
              />
            </div>
          </template>
          <template v-else>
            <div class="flex items-center">
              <span class="text-gray-400 w-full px-4 py-3 leading-tight">
                {{ placeholder || $t('core.datetime') }}
              </span>
              <slot name="icons">
                <icon name="calendar" class="mr-4" />
              </slot>
            </div>
          </template>
        </div>
      </l-input>
    </template>
    <section class="date-time-picker" slot-scope="{ toggle }" @click.stop>
      <div
        v-if="internalValue"
        class="py-2 px-3 flex justify-between text-blue text-xs"
      >
        <span>{{ internalValue.format('DD of MMMM YYYY') }}</span>
        <span class="text-red">{{ internalValue.format('HH:mm') }}</span>
      </div>
      <div>
        <LSmoothScrollPicker
          class="text-blue"
          v-if="internalValue !== null"
          :data="options"
          :change="change"
        />
      </div>
      <div class="flex px-4 justify-center">
        <button
          @click="() => continueSelection(toggle)"
          class="text-white bg-blue uppercase text-xs py-2 w-full rounded"
        >
          {{ $t('ctas.continue') }}
        </button>
      </div>
    </section>
  </l-dropdown>
</template>

<script>
import moment from 'moment-timezone'
import LInput from '@last/core-ui/components/LInput.vue'
import LSmoothScrollPicker from './LSmoothScrollPicker.vue'
import LDropdown from '@last/core-ui/components/LDropdown.vue'
import Icon from './Icon.vue'

export default {
  name: 'LDateTimePicker',
  components: {
    LSmoothScrollPicker,
    LDropdown,
    LInput,
    Icon
  },
  props: {
    value: {
      type: [String, Date, Object],
      default: null
    },
    from: {
      type: [String, Date, Object],
      default: () => new Date()
    },
    placeholder: {
      type: String,
      default: undefined
    },
    maxDays: {
      type: Number,
      default: 7
    },
    timezone: {
      type: String,
      default: 'Europe/Madrid'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rangeOfMinutes: 5,
    internalValue: moment().tz('Europe/Madrid')
  }),
  mounted() {
    this.internalValue = moment(this.value || undefined).tz(this.timezone)
    this.internalValue.minutes(this.buildMinutes(this.internalValue.minutes()))
    this.internalValue.seconds(0)
  },
  computed: {
    formattedPickupTime() {
      return moment(this.value).format('llll')
    },
    options() {
      if (!this.internalValue) return []
      const from = moment(this.from)
      const days = Array.from({ length: this.maxDays }).map((_, index) => {
        const day = moment(from)
        return {
          value: day.add(index, 'days').format('ddd DD MMM'),
          date: day
        }
      })

      const hours = Array.from({ length: 24 }).map((_, index) =>
        this.numberBuilder(index)
      )

      const minutes = Array.from({
        length: 60 / this.rangeOfMinutes
      }).map((_, index) => this.numberBuilder(index * this.rangeOfMinutes))
      return [
        {
          flex: 2,
          list: days,
          currentIndex: days
            .map(day => day.value)
            .indexOf(this.internalValue.format('ddd DD MMM'))
        },
        {
          flex: 1,
          list: hours,
          currentIndex: hours.indexOf(this.internalValue.format('HH'))
        },
        {
          flex: 1,
          list: minutes,
          currentIndex: minutes.indexOf(
            this.buildMinutes(parseInt(this.internalValue.format('mm')))
          )
        }
      ]
    }
  },
  methods: {
    change(GIndex, LIndex) {
      let temporallyValue = moment(this.internalValue)
      const element = this.options[GIndex].list[LIndex]
      switch (GIndex) {
        case 0:
          temporallyValue.date(parseInt(element.date.format('DD')))
          temporallyValue.month(parseInt(element.date.format('MM')) - 1)
          temporallyValue.year(element.date.year())
          break
        case 1:
          temporallyValue.hour(element)
          break
        case 2:
          temporallyValue.minutes(element)
          break
      }

      this.internalValue = moment(temporallyValue)
    },
    updateValue() {
      this.$emit('input', this.internalValue)
      this.$emit('close')
    },
    buildMinutes(minutes) {
      let min = Math.ceil(minutes / 5) * 5
      return String(min).padStart(2, '0')
    },
    numberBuilder(index) {
      return String(index).padStart(2, '0')
    },
    remove() {
      this.$emit('input', undefined)
    },
    continueSelection(toggle) {
      this.updateValue()
      toggle()
    }
  }
}
</script>

<style scoped>
.icon-size {
  @apply w-4 h-4;
}

.line-height {
  line-height: 1.15;
}
</style>
