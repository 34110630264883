<template>
  <l-modal
    v-if="isActive"
    size="small"
    :title="$t('manager-confirmation.title')"
    @close="deactivate(true)"
  >
    <template slot="body">
      <div class="text-center text-blue">
        {{ $t('manager-confirmation.subtitle') }}
      </div>
      <section
        class="flex flex-col justify-center mt-0 md:mt-3 px-2 py-4 md:px-10 md:py-6"
      >
        <div class="flex justify-center">
          <div class="flex justify-center relative">
            <div
              v-for="(_, pos) in 4"
              :key="pos"
              :class="{
                'border-gray-300': !isInvalid,
                'border-red': isInvalid,
                'mr-3': pos !== 3
              }"
              class="flex items-center justify-center bg-white rounded-small pin-square border"
            >
              <div v-if="pin[pos]" class="w-2 h-2 rounded-full bg-red"></div>
            </div>
            <div
              class="flex items-center justify-center absolute top-0 right-0 -mr-6 mt-2 md:-mr-8 md:mt-4"
              v-if="isInvalid"
            >
              <icon
                name="alert"
                class="bg-white text-red rounded-full icon-size"
              />
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <div
            id="numbers-container"
            class="mt-8 overflow-hidden bg-gray-300 rounded-big border border-gray-300"
          >
            <div
              v-for="n in 9"
              :key="n"
              class="flex items-center justify-center text-xl font-bold bg-white text-blue font-title"
              @click="append(n)"
            >
              {{ n }}
            </div>
            <div
              class="flex items-center justify-center text-xl font-bold bg-white text-green font-title"
              @click="deleteNumber()"
            >
              <icon name="delete-arrow" />
            </div>
            <div
              class="flex items-center justify-center text-xl font-bold bg-white text-blue font-title"
              @click="append(0)"
            >
              0
            </div>
            <div
              class="flex items-center justify-center text-xl font-bold bg-white text-green font-title"
              @click="clear()"
            >
              C
            </div>
          </div>
        </div>
      </section>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ManagerConfirmation',
  data() {
    return {
      pin: '',
      isActive: true,
      isInvalid: false
    }
  },
  props: {
    privilege: {
      type: String,
      default: ''
    }
  },
  async mounted() {
    if (!(await this.pinNeeded())) {
      this.$emit('confirmed')
      this.deactivate()
      this.isInvalid = false
    }
  },
  components: {
    LModal,
    Icon
  },
  methods: {
    ...mapActions('auth', ['validateEmployeePinWithPrivilege']),
    async pinNeeded() {
      return await this.askPinForPrivilege(this.privilege)
    },
    deactivate(cancel = false) {
      if (cancel) {
        this.$emit('cancel')
      }
      this.isActive = false
      setTimeout(() => {
        this.$destroy()
        this.$el.remove()
      }, 150)
    },
    append(number) {
      if (this.pin.length < 4) {
        this.pin += number
      }
      if (this.pin.length === 4) {
        this.validateEmployeePinWithPrivilege({
          pin: this.pin,
          privilege: this.privilege
        }).then(status => {
          if (status.acces) {
            if (!status.pin)
              this.$lnotification.create({
                title:
                  this.$t('employees.employee-changed') +
                  ' ' +
                  this.currentEmployee.name,
                icon: 'done',
                iconColor: 'green'
              })
            this.$emit('confirmed')
            this.deactivate()
            this.isInvalid = false
          } else {
            this.isInvalid = true
            this.$lnotification.create({
              title: status.pin
                ? this.$t('employees.not-allowed')
                : this.$t('employees.wrong-pin'),
              icon: 'close',
              iconColor: 'red'
            })
          }
        })
      }
    },
    deleteNumber() {
      this.pin = this.pin.slice(0, -1)
      this.isInvalid = false
    },
    clear() {
      this.pin = ''
      this.isInvalid = false
    }
  },
  computed: {
    ...mapGetters('auth', [
      'currentEmployee',
      'anyEmployeeIsManager',
      'askPinForPrivilege'
    ])
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

.pin-square {
  width: 3.5rem;
  height: 3.5rem;
}

#numbers-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  flex-wrap: wrap-reverse;
  grid-row-gap: 0.062rem;
  grid-column-gap: 0.062rem;
  width: 23.25rem;
  height: 16.25rem;
}

@media screen and (max-width: 768px) {
  .pin-square {
    width: 2rem;
    height: 2rem;
  }
  .icon-size {
    @apply h-4 w-4;
  }
}
</style>
