import { render, staticRenderFns } from "./CouriersReport.vue?vue&type=template&id=dfa5746c&scoped=true&"
import script from "./CouriersReport.vue?vue&type=script&lang=js&"
export * from "./CouriersReport.vue?vue&type=script&lang=js&"
import style0 from "./CouriersReport.vue?vue&type=style&index=0&id=dfa5746c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfa5746c",
  null
  
)

export default component.exports