<template>
  <div v-if="savedCatalogCombo">
    <div
      class="text-blue uppercase font-title font-bold text-xl text-center pt-10 pb-4 shadow"
    >
      {{ $t('combo-editor.combo-details') }}
    </div>

    <div class="absolute" style="top: 2.9rem; right: 1.5rem;">
      <icon
        small
        name="close"
        class="text-gray-400 "
        @click.native="$router.go(-1)"
      />
    </div>

    <div
      v-if="savedCombos.length == 1"
      class="overflow-y-scroll scrolling-touch mt-5 px-6"
    >
      <div
        v-for="(category, index) in savedCatalogCombo.categories"
        :key="category.id"
      >
        <div class="text-blue text-xs uppercase mt-3">
          {{ category.name }}
        </div>
        <combo-product
          v-for="product in mapCategorySelectedProducts[0][index]"
          :key="product.id"
          :product="product"
          @delete="$emit('deleteProduct', 0, product.id)"
          @updateQuantity="
            newQuantity =>
              $emit('updateProductQuantity', product.id, newQuantity, 0, index)
          "
          class="mt-3"
          :quantity-is-maxed="mapCategoryMaxed[0][index]"
          @customClick="$emit('editProduct', 0, product)"
        />
        <div class="separator bg-gray-200 mt-3" />
      </div>
    </div>

    <div v-else class="overflow-y-scroll scrolling-touch mt-5">
      <div
        v-for="(combo, comboIndex) in savedCombos"
        :key="comboIndex"
        class="mt-4 border-b border-gray-200 pb-4"
      >
        <div class="flex justify-between px-6">
          <div class="font-title text-blue text-xl font-bold">
            {{ 'COMBO ' + (comboIndex + 1) }}
          </div>
          <icon
            v-if="showRemoveButton(comboIndex)"
            name="trash"
            class="text-blue"
            @click="$emit('onRemove', comboIndex)"
          />
        </div>
        <div
          v-for="(category, index) in savedCatalogCombo.categories"
          :key="category.id"
          class="px-6"
        >
          <template
            v-if="mapCategorySelectedProducts[comboIndex][index].length > 0"
          >
            <div class="text-blue text-xs uppercase mt-3">
              {{ category.name }}
            </div>
            <combo-product
              v-for="product in mapCategorySelectedProducts[comboIndex][index]"
              :key="product.id"
              :product="product"
              @delete="$emit('deleteProduct', comboIndex, product.id)"
              @updateQuantity="
                newQuantity =>
                  $emit(
                    'updateProductQuantity',
                    product.id,
                    newQuantity,
                    comboIndex,
                    index
                  )
              "
              class="mt-3"
              :quantity-is-maxed="mapCategoryMaxed[comboIndex][index]"
              @customClick="$emit('editProduct', comboIndex, product)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import ComboProduct from '@/components/ordering/ComboProduct.vue'
import comboEditorMixin from '@/components/ordering/comboEditorMixin.js'

export default {
  name: 'ComboDetails',
  mixins: [comboEditorMixin],
  props: {
    mapCategorySelectedProducts: {
      type: Array,
      default: () => []
    },
    mapCategoryMaxed: {
      type: Array,
      default: () => []
    },
    savedCatalogCombo: {
      type: Object,
      default: () => {}
    },
    savedCombos: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  mounted() {
    if (!this.savedCatalogCombo) {
      this.$router.replace({
        name: 'orderManagement',
        params: { tabId: this.tabId }
      })
    }
  },
  methods: {
    showRemoveButton(comboIndex) {
      return (
        !this.mapCategorySelectedProducts[comboIndex] ||
        Object.keys(this.mapCategorySelectedProducts[comboIndex]).length == 0 ||
        Object.values(this.mapCategorySelectedProducts[comboIndex]).every(
          a => a.length == 0
        )
      )
    }
  },
  components: {
    Icon,
    ComboProduct
  }
}
</script>

<style scoped></style>
