import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import Dataphone from '@/integrations/dataphone/dataphone.js'
import { iZettlePlugin } from '@/iZettlePlugin.js'
import { SumUpPlugin } from '@/SumUpPlugin.js'
import uuid4 from 'uuid/v4'
import Printer from '@/printer.js'

export default {
  methods: {
    async charge(amount, paymentMethod) {
      if (!this.bill || this.bill.total === 0) {
        return
      }
      let tipAmount = Math.max(amount - this.bill.pending, 0)
      let formattedAmount = this.$options.filters.currency(tipAmount)
      if (CashMachine.methods.includes(paymentMethod.type)) {
        await this.chargeWithMethod(amount, paymentMethod, tipAmount)
      } else if (paymentMethod.type === 'cash') {
        let change = tipAmount
        this.paymentSucceeded(amount - change, change, paymentMethod)
        if (change > 0) {
          this.$ldialog({
            title: this.$t('checkout.change'),
            content: `${this.$t('checkout.change-info')} ${formattedAmount}`
          })
        }
      } else {
        if (tipAmount > 0) {
          this.$ldialog({
            title: this.$t('checkout.payment-confirmation'),
            content: this.$t('checkout.payment-confirmation-message', {
              amount: formattedAmount
            }),
            defaultLabels: true,
            onConfirm: async () =>
              await this.chargeWithMethod(amount, paymentMethod, tipAmount)
          })
        } else {
          await this.chargeWithMethod(amount, paymentMethod)
        }
      }
      if (paymentMethod.openDrawer) {
        Printer.openCashDrawer()
      }
    },
    async chargeWithMethod(amount, paymentMethod, tipAmount = 0) {
      try {
        let paymentId = uuid4()
        let metadata = null
        if (paymentMethod.type === 'izettle') {
          await iZettlePlugin.charge({ amount })
        } else if (paymentMethod.type === 'sumup') {
          await SumUpPlugin.charge({ amount })
        } else if (CashMachine.methods.includes(paymentMethod.type)) {
          let charged = await CashMachine.charge(amount)
          if (charged === 0) throw new Error('CashMachine charging failed')
        } else if (paymentMethod.type === 'dataphone') {
          if (!Dataphone.initialized()) {
            throw new Error('Dataphone is not initialized')
          } else {
            metadata = await Dataphone.charge({
              amount,
              paymentId,
              tabId: this.tabId
            })
          }
        }
        this.paymentSucceeded(
          amount,
          tipAmount,
          paymentMethod,
          paymentId,
          metadata
        )
      } catch (error) {
        this.$lnotification.create({
          title: `${this.$t('notifications.payment-failed')}${
            error.message ? `: ${error.message}` : ''
          }`
        })
      }
    },
    removeDiscount() {
      this.currentDiscount = {}
      this.deleteTabPromotion({
        tabId: this.tabId,
        tabPromotion: this.tabGlobalPromotion
      })
    }
  }
}
