<template>
  <div
    class="flex flex-col h-screen overflow-y-scroll scrolling-touch bg-gray-200"
  >
    <top-bar show-back @back="goBack()" />
    <div
      v-if="!closingTillId"
      class="flex flex-col items-center w-full h-full bg-gray-200 overflow-y-scroll overflow-hidden"
    >
      <div
        class="pt-10 pb-4 text-2xl font-bold text-center uppercase font-title text-blue"
      >
        {{ $t('end-shift.print-reports') }}
      </div>
      <div
        v-if="closingShift !== null"
        class="absolute top-0 left-0 z-30 flex flex-col items-center justify-center w-screen h-screen blur-background"
      >
        <div>
          <div class="pb-6 text-2xl text-center text-white uppercase">
            {{ $t('end-shift.title') }}
          </div>
          <div
            class="relative p-16 text-xl text-center uppercase bg-white shadow rounded-big text-blue font-title dialog-w"
          >
            {{ $t('report.z-report-confirmation') }}
          </div>
          <div class="flex pt-4">
            <div
              class="flex-1 w-1/2 p-3 px-4 font-bold text-center text-white uppercase border border-blue-700 bg-blue rounded-small"
              :class="{
                'opacity-50': waitingForClosure
              }"
              @click="closeShift"
            >
              {{ $t('end-shift.close-shift') }}
            </div>
            <div
              class="flex-1 p-3 px-4 ml-4 font-bold text-center text-white uppercase border bg-red border-red-b rounded-small"
              :class="{
                'opacity-50': waitingForClosure
              }"
              @click="closeDay"
            >
              {{ $t('end-shift.close-day') }}
            </div>
          </div>
        </div>
      </div>
      <tills-view
        v-else
        @printX="printX"
        @printPreview="printX(true)"
        @printZ="printZ"
        @printXZ="printXAndZ"
        @printTillReport="printTillReport"
        @startClosingTill="startClosingTill"
        @leave="leave"
      />
    </div>

    <close-till
      v-if="closingTillId"
      :till-id="closingTillId"
      @close="closingTillId = null"
    />
  </div>
</template>

<script>
import TopBar from '@/components/core/TopBar.vue'
import TicketPrinter from '@/ticketPrinter.js'
import api from '@/api.js'
import { mapState, mapActions } from 'vuex'
import sync from '@/sync/service.js'
import TillsView from './TillsView'
import CloseTill from './CloseTill.vue'
import moment from 'moment'

export default {
  name: 'EndShift',
  data() {
    return {
      closingTillId: null,
      tillReport: null,
      selectedReport: null,
      closingShift: null,
      multipleTurns: null,
      waitingForClosure: false
    }
  },
  computed: {
    ...mapState('till', ['selectedCashTill', 'startedShifts']),
    closingTillStarted() {
      return this.startedShifts.includes(this.closingTill.id)
    },
    diff() {
      if (this.tillReport) {
        return this.tillReport.endAmount - this.tillReport.expectedEndAmount
      } else if (this.selectedReport.till) {
        return (
          this.selectedReport.till.end -
          this.selectedReport.till.expectedEndAmount
        )
      } else {
        return ''
      }
    }
  },
  async mounted() {
    let today = moment().subtract(2, 'hours')
    let response = await api.get('/shifts/location-shifts-quantity', {
      params: {
        startDate: today.format('YYYY-MM-DD'),
        endDate: today.format('YYYY-MM-DD')
      }
    })
    this.multipleTurns = response.data > 1
  },
  methods: {
    ...mapActions('till', ['endShift']),
    goBack() {
      if (this.startedShifts.length === 0) {
        this.$router.push({ name: 'employees' })
      } else {
        this.$router.go(-1)
      }
    },
    closeReport() {
      this.selectedReport = null
      this.closingTill = null
    },
    async getTillReport(tillId) {
      let response = await api.get('/last-shift', {
        params: {
          tillId
        }
      })
      this.tillReport = response.data
    },
    async continueAction(amount) {
      this.endShift({ amount, tillId: this.closingTill.id })
      sync.observeEnd(async () => {
        await this.getReports(this.closingTill)
      })
    },
    leave() {
      this.closingShift = true
    },
    printTillReport(till, report) {
      TicketPrinter.printTillReport(till, report)
    },
    printX(preview = false) {
      TicketPrinter.printXReport(preview)
    },
    async printZ() {
      await TicketPrinter.printZReport()
    },
    async printXAndZ() {
      await TicketPrinter.printXReport()
      await TicketPrinter.printZReport()
    },
    startClosingTill(till) {
      this.closingTillId = till.id
    },
    closeShift() {
      if (this.waitingForClosure) {
        return
      }
      this.waitingForClosure = true
      this.printX()
      this.waitingForClosure = false
      this.$router.push({ name: 'employees' })
    },
    async closeDay() {
      if (this.waitingForClosure) {
        return
      }
      sync.record('dayLastTillClosed', {})
      this.waitingForClosure = true
      await sync.observeEnd(async () => {
        this.multipleTurns ? await this.printXAndZ() : await this.printZ()
        this.waitingForClosure = false
        this.$router.push({ name: 'employees' })
      })
    }
  },
  components: {
    TillsView,
    TopBar,
    CloseTill
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.dialog-w {
  width: 30.25rem;
}
</style>
