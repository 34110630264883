import zpl from '@/printers/jszpl/jszpl'
import SocketFactory from '@/socket.js'

class ZPL {
  constructor(config) {
    this.config = config
  }

  printImage(imageDataUrl) {
    return new Promise((resolve, reject) => {
      var socket = SocketFactory.getSocket()
      socket.open(
        this.config.address,
        9100,
        () => {
          var graphic = new zpl.Graphic()
          let label = new zpl.Label()
          let width = this.config.ticketWidth || 380
          let height = this.config.ticketHeight || 350
          let paddingLeft = this.config.ticketPaddingLeft || 220
          label.printDensity = new zpl.PrintDensity(
            zpl.PrintDensityName['8dpmm']
          )
          label.width = width
          label.height = height
          var img = new Image()

          img.onload = () => {
            var canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height

            var context = canvas.getContext('2d')
            context.drawImage(img, 0, 0, width, height)

            var imageData = context.getImageData(
              0,
              0,
              canvas.width,
              canvas.height
            )

            var index = 0
            var imageBits = []

            for (var y = 0; y < imageData.height; y++) {
              for (var x = 0; x < imageData.width; x++) {
                var red = imageData.data[index++]
                var green = imageData.data[index++]
                var blue = imageData.data[index++]
                var opacity = imageData.data[index++]

                var value = 0
                if (opacity != 0) {
                  value = (red + green + blue) / 3 < 180 ? 1 : 0
                }

                imageBits.push(value)
              }
            }
            graphic.width = new zpl.Size(width)
            graphic.height = new zpl.Size(height)
            graphic.data = new zpl.GraphicData(width, height, imageBits)

            label.padding = new zpl.Spacing(paddingLeft, 0, 0, 0)
            label.content.push(graphic)
            socket.write(
              Buffer.from(label.generateZPL()),
              () => {
                socket.shutdownWrite(() => {
                  resolve()
                }, reject)
              },
              reject
            )
          }
          img.src = imageDataUrl
        },
        reject
      )
    })
  }

  openCashDrawer() {
    // NO_OP
  }
}

export default ZPL
