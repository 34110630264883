<template>
  <div class="sm:pt-10 h-full overflow-hidden flex flex-col">
    <div class="uppercase text-blue text-xl font-bold font-title">
      {{ modifierGroup.name }}
    </div>
    <div class="flex justify-between items-center">
      <div class="text-sm text-gray-400">{{ subtitle }}</div>
      <div class="text-sm text-red" v-if="modifierGroup.min">*obligatorio</div>
    </div>
    <div
      class="mt-6 flex-1 sm:flex sm:flex-row sm:flex-wrap sm:-mx-1 scrolling-touch overflow-scroll"
    >
      <div
        class="rounded-small border-gray-400 shadow mb-4 flex items-center justify-between mod-option sm:mx-2 mx-auto"
        @click="toggle(modifier.id)"
        :class="{
          'text-gray-300 pointer-events-none opacity-50':
            isMaxed && multiple && !isSelected(modifier.id)
        }"
        v-for="modifier in modifierGroup.modifiers"
        :key="modifier.id"
      >
        <div class="flex flex-row">
          <div
            class="mr-3 font-title font-bold text-xl text-white flex justify-center items-center rounded-l-lg flex-shrink-0"
            :style="{
              background: modifier.color || '#302F66',
              width: '2.8125rem',
              height: '3.5rem',
              color: getTextColor(modifier.color)
            }"
          >
            {{ modifier.shortName || shortName(modifier.name) }}
          </div>
          <div class="flex items-center">
            <l-checkbox
              v-if="multiple"
              :value="isSelected(modifier.id)"
              @input="toggle(modifier.id)"
            />
            <l-radio
              v-else
              :value="isSelected(modifier.id)"
              @input="toggle(modifier.id)"
            />
            <div class="ml-6 modifier text-blue">
              {{ modifierName(modifier.name) }}
            </div>
          </div>
        </div>
        <div class="flex items-center mr-2">
          <div class="text-sm text-red pl-4" v-if="modifier.priceImpact > 0">
            (+{{ modifier.priceImpact | currency }})
          </div>
          <quantity-selector
            class="ml-4"
            v-if="modifierGroup.allowRepeat && isSelected(modifier.id)"
            :quantity="modifiers[modifier.id]"
            @update:quantity="quantity => updateQuantity(modifier.id, quantity)"
            :is-maxed="isMaxed"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import LRadio from '@last/core-ui/components/LRadio.vue'
import QuantitySelector from './QuantitySelector.vue'

export default {
  name: 'ModifierGroup',
  props: {
    modifierGroup: {
      type: Object,
      default: null
    },
    modifiers: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    toggle(id) {
      if (this.multiple) {
        if (id in this.modifiers) {
          let modifiers = {
            ...this.modifiers
          }
          delete modifiers[id]
          this.$emit('update:modifiers', modifiers)
        } else {
          this.$emit('update:modifiers', { ...this.modifiers, [id]: 1 })
        }
      } else {
        this.$emit('update:modifiers', { [id]: 1 })
      }
    },
    updateQuantity(id, quantity) {
      let modifiers = {
        ...this.modifiers
      }
      modifiers[id] = quantity
      this.$emit('update:modifiers', modifiers)
    },
    isSelected(id) {
      return id in (this.modifiers || {})
    },
    shortName(name) {
      if (!name) return ''
      let initials = name
        .split(/[.,/ -]/)
        .map(n => n[0])
        .join('')
        .slice(0, 2)
      if (initials.length < 2) {
        return name.slice(0, 2).toUpperCase()
      } else {
        return initials.toUpperCase()
      }
    },
    modifierName(name) {
      let maxLength = 60
      if (name.length > maxLength) {
        return name.slice(0, maxLength) + '...'
      } else {
        return name
      }
    },
    getTextColor(hexcolor) {
      if (!hexcolor) return 'white'
      let color = hexcolor.substr(1, 6)
      var r = parseInt(color.substr(0, 2), 16)
      var g = parseInt(color.substr(2, 2), 16)
      var b = parseInt(color.substr(4, 2), 16)
      var yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 200 ? '#302F66' : 'white'
    }
  },
  computed: {
    multiple() {
      let min = this.modifierGroup.min || 0
      let max = this.modifierGroup.max
      if (!this.modifierGroup.max) return true
      if (min > 1) return true
      return max - min >= 1
    },
    subtitle() {
      let subtitle = 'Seleccionar'
      if (!this.multiple) {
        return subtitle + ' una opción'
      }
      if (this.modifierGroup.min) {
        subtitle += ` un mínimo de ${this.modifierGroup.min}`
      }
      if (this.modifierGroup.min && this.modifierGroup.max) {
        subtitle += ' y'
      }
      if (this.modifierGroup.max) {
        subtitle += ` un máximo de ${this.modifierGroup.max}`
      }
      return subtitle + ' opciones'
    },
    totalModifiers() {
      if (!this.modifiers) return 0
      return Object.values(this.modifiers).reduce(
        (res, quantity) => res + quantity,
        0
      )
    },
    isMaxed() {
      return (
        !!this.modifierGroup.max &&
        this.totalModifiers === this.modifierGroup.max
      )
    }
  },
  watch: {
    totalModifiers() {
      this.$emit('change', [this.totalModifiers, this.modifierGroup.max])
    }
  },
  components: {
    LCheckbox,
    LRadio,
    QuantitySelector
  }
}
</script>

<style scoped>
.clear-button:disabled {
  @apply bg-gray-200;
  @apply text-gray-400;
}
.finish-button:disabled {
  @apply opacity-50;
}
.mod-option {
  height: 3.5rem;
  min-width: 16.25rem;
  width: 48%;
}
@media screen and (max-width: 640px) {
  .mod-option {
    height: 3.5rem;
    width: 98%;
  }
}
</style>
