import * as Sentry from '@sentry/browser'
import { datadogLogs } from '@datadog/browser-logs'

datadogLogs.init({
  clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
  datacenter: 'us',
  sampleRate: 100
})

class RemoteLogger {
  info(message, data) {
    datadogLogs.logger.info(message, data)
  }
  error(error) {
    Sentry.captureException(error)
  }
}

/*eslint-disable */
class ConsoleLogger {
  info(message, data) {
    console.log(message, data)
  }
  error(error) {
    console.log(error)
  }
}
/*eslint-enable */

let logger = new ConsoleLogger()
if (process.env.NODE_ENV === 'production') {
  logger = new RemoteLogger()
}

export default logger
