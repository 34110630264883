<template>
  <div
    class="flex flex-row items-center px-4 h-16 font-body text-base relative border-b border-gray-200"
    :class="{
      'bg-error text-white': isCancelled(tab),
      'bg-yellow text-white': hasAlert(tab),
      'text-gray-400': !isCancelled(tab) && !hasAlert(tab),
      'bg-gray-200': isSelected && !choosingForShipment
    }"
  >
    <div class="flex items-center w-1/4 xl:w-1/6">
      <l-checkbox
        v-if="choosingForShipment"
        :value="pickedForShipment"
        @input="value => $emit('shipment-pick-toggled', value)"
        class="mr-3"
      />
      <div class="flex flex-row items-center" :class="{ 'ml-8': offset }">
        <div
          class="rounded-full status w-3 h-3"
          :class="{
            cancelled: isCancelled(tab),
            alert: hasAlert(tab),
            'bg-light-blue': tab.open,
            closed: !tab.open,
            'billing-started': tab.billingStartedTime,
            scheduled: !tab.activationTime
          }"
        />
        <div class="text-lg font-bold tab-name ml-3 text-blue">
          <div class="flex flex-row items-center">
            <div
              class="right-0 bg-white rounded-full circle mr-2"
              v-if="tab.pendingKitchenOrders"
            >
              <div class="w-full h-full mr-1 rounded-full bg-yellow" />
            </div>
            <div v-if="tab.code" class="mr-1">{{ tab.code }}</div>
            <div v-if="tab.tableName">/ {{ tab.tableName }}</div>
            <div v-else-if="tab.name">/ {{ tab.name }}</div>
          </div>
          <div class="text-sm text-gray-400">
            {{ tab.source }}
          </div>
        </div>
      </div>
    </div>
    <div class="text-lg font-bold w-1/4">
      <div v-if="isCancelled(tab)" class="uppercase">
        {{ $t('tabs.cancelled') }}
      </div>
      <div v-else-if="hasAlert(tab)" class="uppercase">
        {{ $t('tabs.alert') }}
      </div>
      <div
        v-else-if="!tab.open"
        class="uppercase font-bold text-xl flex items-center"
        :class="{
          'text-error': wrongClosedState,
          'text-light-blue': !wrongClosedState
        }"
      >
        {{ preparationTime }}
        <span class="text-gray-400 text-lg ml-4">{{ $t('tabs.closed') }}</span>
      </div>
      <div v-else-if="!tab.activationTime" class="uppercase">
        {{ $t('tabs.scheduled') }}
      </div>
      <div
        v-else
        class="text-xl flex flex-row items-center font-bold"
        :class="'text-' + stateColor"
      >
        <div>{{ timeFromTab.minutes }}:{{ timeFromTab.seconds }}</div>
        <div v-if="tab.deliveryOrder">
          <div class="text-lg ml-3 uppercase">{{ statusLabel }}</div>
        </div>
      </div>
    </div>
    <div v-if="rowSize !== 'small'" class="flex-1 flex flex-col">
      <div class="font-bold">
        {{ tab.activationTime || tab.schedulingTime | day }}
      </div>
      <div>{{ tab.activationTime || tab.schedulingTime | time }}</div>
    </div>
    <div class="flex-1 flex-col">
      <div class="font-bold">
        {{ deliveryTime | day }}
      </div>
      <div>{{ deliveryTime | time }}</div>
    </div>
    <div class="w-1/4 xl:w-1/5">
      <div class="w-10/12">
        {{ longAddressText }}
      </div>
    </div>
    <div
      v-if="tab.deliveryOrder && tab.deliveryOrder.courierName"
      class="flex-1"
    >
      {{ tab.deliveryOrder.courierName }}
    </div>
    <div
      v-else-if="
        tab.deliveryOrder &&
          tab.deliveryOrder.shipmentId &&
          tab.pickupType === 'ownDelivery'
      "
      class="flex-1"
    >
      {{ tab.deliveryOrder.shipment.provider || '' }}
    </div>
    <div
      v-else-if="tab.deliveryOrder && tab.deliveryOrder.shipmentCancelled"
      class="flex-1 text-error"
    >
      {{ $t('tabs.shipment-cancelled') }}
    </div>
    <div v-else class="flex-1" />
    <div class="flex-1" v-if="rowSize === 'large'">
      {{ tab.customerInfo ? tab.customerInfo.phoneNumber || '' : '' }}
    </div>
    <div
      v-if="
        isSelected && tab.pickupType === 'ownDelivery' && !choosingForShipment
      "
      class="absolute inset-y-0 right-0 items-center flex mr-3"
    >
      <icon name="external" class="text-red" @click="$emit('goToDetails')" />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Icon from '@last/core-ui/components/Icon'
import { mapState } from 'vuex'
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'

export default {
  name: 'LargeTabRow',
  props: {
    tab: {
      type: Object,
      default: null
    },
    rowSize: {
      type: String,
      default: 'small'
    },
    currentTime: {
      type: Date,
      default: null
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    choosingForShipment: {
      type: Boolean,
      default: false
    },
    pickedForShipment: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Icon,
    LCheckbox
  },
  methods: {
    isCancelled(tab) {
      return tab.deliveryOrder && tab.deliveryOrder.cancelTime
    },
    hasAlert(tab) {
      return (
        tab.deliveryOrder &&
        tab.deliveryOrder.errorTime &&
        !tab.deliveryOrder.errorResolved
      )
    },
    addZero(number) {
      return number < 10 ? '0' + number : number
    }
  },
  computed: {
    ...mapState('config', ['config']),
    longAddressText() {
      if (
        this.tab.deliveryOrder?.address &&
        this.tab.deliveryOrder?.postalCode
      ) {
        return `${this.tab.deliveryOrder.address} (${this.tab.deliveryOrder.postalCode})`
      } else if (this.tab.deliveryOrder?.address) {
        return this.tab.deliveryOrder.address
      }
      return ''
    },
    deliveryTime() {
      if (this.tab.schedulingTime) {
        return this.tab.schedulingTime
      } else {
        return moment(this.tab.activationTime).add(30, 'minutes')
      }
    },
    preparationTime() {
      const secondsDiff = Math.round(
        moment(this.tab.closeTime).diff(moment(this.tab.activationTime)) / 1000
      )

      return `${this.addZero(Math.round(secondsDiff / 60))}:${this.addZero(
        secondsDiff % 60
      )}`
    },
    wrongState() {
      return moment(new Date())
        .subtract(30, 'minutes')
        .isAfter(moment(this.tab.activationTime))
    },
    stateColor() {
      if (!this.tab.deliveryOrder || !this.tab.deliveryOrder.status) {
        return 'light-blue'
      }
      let activationTime = this.tab.activationTime
      let now = this.currentTime
      switch (this.tab.deliveryOrder.status) {
        case 'KITCHEN':
          if (
            moment(now)
              .subtract(10, 'minutes')
              .isBefore(moment(activationTime))
          ) {
            return 'light-blue'
          } else if (
            moment(now)
              .subtract(15, 'minutes')
              .isBefore(moment(activationTime))
          ) {
            return 'yellow'
          } else {
            return 'error'
          }
        case 'READY_TO_PICKUP':
          if (
            moment(now)
              .subtract(13, 'minutes')
              .isBefore(moment(activationTime))
          ) {
            return 'light-blue'
          } else if (
            moment(now)
              .subtract(20, 'minutes')
              .isBefore(moment(activationTime))
          ) {
            return 'yellow'
          } else {
            return 'error'
          }
        case 'ON_DELIVERY':
          if (
            moment(now)
              .subtract(28, 'minutes')
              .isBefore(moment(activationTime))
          ) {
            return 'light-blue'
          } else if (
            moment(now)
              .subtract(40, 'minutes')
              .isBefore(moment(activationTime))
          ) {
            return 'yellow'
          } else {
            return 'error'
          }
        default:
          return 'gray-400'
      }
    },
    wrongClosedState() {
      return moment(this.tab.closeTime)
        .subtract(30, 'minutes')
        .isAfter(moment(this.tab.activationTime))
    },
    timeFromTab() {
      let secondsDifference = Math.round(
        (this.currentTime.getTime() -
          new Date(this.tab.activationTime).getTime()) /
          1000
      )
      return {
        minutes: this.addZero(Math.floor(secondsDifference / 60)),
        seconds: this.addZero(secondsDifference % 60)
      }
    },
    statusLabel() {
      if (!this.tab.deliveryOrder) {
        return ''
      }
      switch (this.tab.deliveryOrder.status) {
        case 'KITCHEN':
          return this.$t('tabs.kitchen')
        case 'READY_TO_PICKUP':
          return this.$t('tabs.pickup')
        case 'ON_DELIVERY':
          return this.$t('tabs.delivery')
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  background: #4299e1;
  &.billing-started {
    @apply bg-error;
  }
  &.cancelled {
    background: white;
  }
  &.cancelled.selected:not(.closed) {
    @apply bg-error;
  }
  &.alert {
    background: white;
  }
  &.alert.selected:not(.closed) {
    @apply bg-yellow;
  }
  &.closed {
    background: #a0a0c0;
  }
  &.scheduled {
    @apply bg-blue-100;
  }
}

.font-34px {
  font-size: 34px;
}

.ml-14 {
  margin-left: 3.7rem;
}
.tab-name-source {
  width: 25rem;
}
.circle {
  width: 10px;
  height: 10px;
  padding: 1px;
}
</style>
