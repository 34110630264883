import { Preferences } from '@capacitor/preferences'
let appStorage = null

try {
  const { ipcRenderer } = window.require('electron')
  appStorage = {
    setItem: (key, value) => {
      return ipcRenderer.invoke('storage:set', key, value)
    },
    getItem: key => {
      return ipcRenderer.invoke('storage:get', key)
    },
    removeItem: key => {
      return ipcRenderer.invoke('storage:remove', key)
    },
    keys: () => {
      return ipcRenderer.invoke('storage:keys')
    },
    clear: () => {
      return ipcRenderer.invoke('storage:clear')
    }
  }
} catch (e) {
  let initialization = Preferences.migrate()
  appStorage = {
    setItem: async (key, value) => {
      await initialization
      await Preferences.set({ key, value })
    },
    getItem: async key => {
      await initialization
      let data = await Preferences.get({ key })
      return data.value
    },
    removeItem: async key => {
      await initialization
      await Preferences.remove({ key })
    },
    keys: async () => {
      await initialization
      return await Preferences.keys()
    },
    clear: async () => {
      await initialization
      await Preferences.clear()
      await Preferences.removeOld()
    }
  }
}

export default appStorage
