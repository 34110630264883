import store from '@/store/index'
import PrinterQueue from './printerQueue'

let queues = {}

function loadPrinters(printers) {
  let ipQueues = printers.reduce((res, printer) => {
    try {
      res[printer.ip] = new PrinterQueue(printer)
    } catch (e) {
      // Unsupported printer
    }
    return res
  }, {})
  queues = printers.reduce((res, printer) => {
    res[printer.id] = ipQueues[printer.ip]
    return res
  }, {})
}

function printImage(printerId, imageData, copies = 1, printedEvent) {
  for (let i = 0; i < copies; ++i) {
    let queue = queues[printerId]
    if (queue) {
      queue.addJob({
        type: 'printImage',
        image: imageData,
        printedEvent,
        time: new Date()
      })
    }
  }
}

function openCashDrawer() {
  let printerId =
    (store.state.till.selectedCashTill &&
      store.state.till.selectedCashTill.printerId) ||
    store.state.config.config.defaultBillPrinter
  let queue = queues[printerId]
  if (queue) {
    queue.addJob({
      type: 'openCashDrawer'
    })
  }
}

export default { printImage, openCashDrawer, loadPrinters }
