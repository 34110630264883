<template>
  <div id="tables-content" class="bg-blue-900">
    <top-bar show-back @back="$emit('close')">
      <template slot="center">
        <div class="text-xl flex items-center">
          {{ title }}
        </div>
      </template>
    </top-bar>
    <div class="bg flex bg-gray-200">
      <div
        v-for="floorplan in floorplans"
        :key="floorplan.id"
        class="uppercase text-sm py-4 px-6 flex items-center text-blue border-r-4 border-gray-200 floorplan-tab"
        :class="{ 'border-red bg-white': floorplanId === floorplan.id }"
        @click="floorplanId = floorplan.id"
      >
        {{ floorplan.name }}
      </div>
    </div>
    <swiper
      class="swiper"
      :total-slides="floorplanIds.length"
      @slideChange="updateSelectedFloorplan"
      :index="index"
    >
      <div
        class="swiper-slide"
        v-for="floorplanId in floorplanIds"
        :key="floorplanId"
      >
        <div class="floorplan-container p-4">
          <table-selector-floorplan
            class="floor-plan"
            :floorplan-id="floorplanId"
            :blocked-tables="blockedTables"
            :multiple-select="multipleSelect"
            :selected-tables="internalSelectedTables"
            @tablesSelected="tablesSelected"
          />
          <div
            v-if="multipleSelect"
            class="absolute bottom-0 right-0 mr-12 mb-6 px-12 py-3 shadow-lg text-white bg-red border border-red-b uppercase rounded-small font-bold text-center cursor-pointer"
            @click="emitSelectedTables()"
          >
            {{ $t('reservations.save') }}
          </div>
        </div>
      </div>
    </swiper>
  </div>
</template>

<script>
import TableSelectorFloorplan from './TableSelectorFloorplan.vue'
import Swiper from './Swiper.vue'
import TopBar from '@/components/core/TopBar.vue'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'TableSelector',
  props: {
    title: {
      type: String,
      default: null
    },
    blockedTables: {
      type: Array,
      default: () => []
    },
    selectedTables: {
      type: Array,
      default: () => []
    },
    multipleSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      floorplanId: null,
      index: 0,
      internalSelectedTables: []
    }
  },
  async mounted() {
    this.internalSelectedTables = [...this.selectedTables]
    this.floorplanId = this.floorplanIds[0]
    this.updateIndex()
  },
  computed: {
    ...mapState('tables', ['floorplanIds', 'floorplans'])
  },
  methods: {
    ...mapActions('tabs', ['openTab']),
    addOrDeleteTable(table) {
      let index = this.internalSelectedTables.indexOf(table)
      if (index != -1) {
        this.internalSelectedTables.splice(index, 1)
      } else {
        this.internalSelectedTables = [...this.internalSelectedTables, table]
      }
    },
    tablesSelected(table) {
      if (!this.multipleSelect) {
        this.$emit('tableSelected', table)
      } else {
        this.addOrDeleteTable(table)
      }
    },
    emitSelectedTables() {
      this.$emit('tablesSelected', this.internalSelectedTables)
    },
    updateSelectedFloorplan(index) {
      this.floorplanId = this.floorplanIds[index]
    },
    updateIndex() {
      this.index = Math.max(this.floorplanIds.indexOf(this.floorplanId), 0)
    }
  },
  watch: {
    floorplanId() {
      this.updateIndex()
    }
  },
  components: {
    TableSelectorFloorplan,
    Swiper,
    TopBar
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.floorplan-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#tables-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.swiper {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
}
</style>
