<template>
  <div
    class="fixed top-0 left-0 h-screen w-screen z-30 blur-background overflow-hidden flex flex-col justify-center items-center"
  >
    <div class="text-white uppercase sm:text-2xl text-center pb-6 font-bold">
      {{ $t('cash-machine-status.title') }}
    </div>
    <div class="bg-white rounded-big p-6 text-center">
      <div class="text-blue uppercase">
        {{ $t('cash-machine-status.amount') }}
      </div>
      <section class="p-6 text-3xl">
        {{ status.inputAmount | currency }}
      </section>
    </div>
    <div
      @click="cancel"
      class="bg-blue border border-blue-900 px-10 py-3 text-center text-white rounded-lg mt-8 uppercase font-body text-base items-center relative"
      :class="{
        'pointer-events-none': cancelling
      }"
    >
      {{ $t('ctas.cancel') }}
      <l-loading-spinner v-show="cancelling" class="loading" />
    </div>
  </div>
</template>

<script>
import LLoadingSpinner from '@last/core-ui/components/LLoadingSpinner'

export default {
  name: 'CashMachineStatus',
  components: {
    LLoadingSpinner
  },
  data() {
    return {
      cancelling: false
    }
  },
  props: {
    status: {
      type: Object,
      default: () => ({})
    },
    onCancel: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    async cancel() {
      this.cancelling = true
      await this.onCancel()
    },
    deactivate() {
      this.$destroy()
      this.$el.remove()
    }
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}
.loading {
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
