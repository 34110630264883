import { render, staticRenderFns } from "./DeliverySelector.vue?vue&type=template&id=da999fce&scoped=true&"
import script from "./DeliverySelector.vue?vue&type=script&lang=js&"
export * from "./DeliverySelector.vue?vue&type=script&lang=js&"
import style0 from "./DeliverySelector.vue?vue&type=style&index=0&id=da999fce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da999fce",
  null
  
)

export default component.exports