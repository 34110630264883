<template>
  <div>
    <div
      v-for="company in Object.keys(
        virtualBrand.externalDeliveryCompaniesClosingTimes
      )"
      :key="company"
      class="flex flex-row ml-8 mt-5 justify-between items-center"
    >
      <div class="text-blue text-sm">{{ company }}</div>
      <div class="mr-8 flex flex-row items-center">
        <div
          v-if="
            deliveryIsClosed(
              virtualBrand.externalDeliveryCompaniesClosingTimes[company]
            )
          "
          class="text-red mr-10 flex flex-row items-center"
        >
          <div>
            <icon name="clock" class="text-red w-3 h-3" />
          </div>
          <div class="ml-1 text-sm">
            {{
              timeFrom(
                virtualBrand.externalDeliveryCompaniesClosingTimes[company]
              )
            }}
          </div>
        </div>
        <l-switch
          :value="
            !deliveryIsClosed(
              virtualBrand.externalDeliveryCompaniesClosingTimes[company]
            )
          "
          @input="value => setValue(virtualBrand.id, company, value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import LSwitch from '@last/core-ui/components/LSwitch'
import Icon from '@last/core-ui/components/Icon'

export default {
  name: 'DeliverySelectorCompanies',
  props: {
    virtualBrand: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    setValue(brandId, company, value) {
      if (value) {
        this.$emit('openDelivery', brandId, company)
      } else {
        this.$emit('closeDelivery', brandId, company)
      }
    },
    deliveryIsClosed(time) {
      return time && moment(time).isAfter(moment(this.currentTime))
    },
    timeFrom(date) {
      let momentDate = moment(date)
      let currentTime = moment(this.currentTime)
      if (momentDate.diff(currentTime, 'days') > 365) {
        return this.$t('close-delivery.indefinitely')
      } else {
        return moment(date).from(currentTime)
      }
    }
  },
  components: {
    LSwitch,
    Icon
  }
}
</script>

<style scoped></style>
