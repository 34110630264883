import api from '@/api.js'

class AdyenDataphone {
  constructor(config) {
    this.initialized = true
    this.config = config
  }

  async init() {
    return true
  }

  async charge({ amount, tabId, paymentId }) {
    try {
      let response = await api.post('adyen/payment', {
        amount,
        tabId,
        paymentId,
        poiid: this.config.poiid
      })
      return response.data
    } catch (e) {
      throw new Error(e.response.data)
    }
  }

  async cancel({ paymentId, tabId }) {
    try {
      let response = await api.put('adyen/cancelPayment', {
        paymentId,
        tabId,
        poiid: this.config.poiid
      })
      return response.data
    } catch (e) {
      throw new Error(e.response.data)
    }
  }

  async refund(payment, tabId) {
    let response = await api.post('adyen/refund', {
      tenderReference: payment.metadata.tenderReference,
      pspReference: payment.metadata.pspReference,
      amount: payment.amount,
      tabId,
      poiid: this.config.poiid
    })
    return response.data
  }
}

export default AdyenDataphone
