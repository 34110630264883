<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen z-20 blur-background overflow-hidden flex justify-center items-center"
    @click="$emit('close')"
  >
    <div class="p-20 max-h-full overflow-y-scroll scrolling-touch">
      <div class="text-white uppercase text-2xl text-center pb-6">
        {{ $t('print-bills.title') }}
      </div>
      <div class="bg-white rounded-big shadow p-6 relative" @click.stop>
        <icon
          name="close"
          class="text-gray-400 absolute top-0 right-0 m-4"
          @click.native="previewBill ? (previewBill = null) : $emit('close')"
        />
        <div v-if="previewBill">
          <img :src="canvasImage" style="width: 320px;" />
        </div>
        <div v-else class="py-8">
          <div class="flex items-center" v-if="hasPending">
            <div class="text-red">{{ pendingBill.creationTime | time }}</div>
            <div class="py-3 px-6 flex-1">
              <div class="text-blue">{{ $t('print-bills.pending-bill') }}</div>
              <div class="text-gray-400 text-sm">
                {{ billDescription(pendingBill) }}
              </div>
            </div>
            <icon
              name="eye"
              class="text-green ml-10"
              @click.native="previewBill = pendingBill"
            />
            <icon
              name="printer"
              class="text-red ml-6"
              @click.native="resendBill(pendingBill)"
            />
          </div>
          <div
            v-for="(bill, index) in bills"
            :key="bill.id"
            class="flex items-center"
          >
            <div class="text-red">{{ bill.creationTime | time }}</div>
            <div class="py-3 px-6 flex-1">
              <div class="text-blue">
                {{ $t('print-bills.bill') + ' ' + (index + 1) }}
              </div>
              <div class="text-gray-400 text-sm">
                {{ billDescription(bill) }}
              </div>
            </div>
            <icon
              name="eye"
              class="text-green ml-10"
              @click.native="previewBill = bill"
            />
            <icon
              name="printer"
              class="text-red ml-6"
              @click.native="resendBill(bill)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketPrinter from '@/ticketPrinter.js'
import Icon from '@last/core-ui/components/Icon.vue'
import Bills from '@last/core/src/billsGenerator.js'
import TicketGenerator from '@/tickets/generator.js'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PrintBills',
  props: {
    tabId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      previewBill: null,
      canvasImage: null
    }
  },
  methods: {
    resendBill(bill) {
      TicketPrinter.printBill(bill)
      this.$emit('close')
    },
    billDescription(bill) {
      let description = bill.products.map(product => product.name).join(', ')
      if (description.length > 40) {
        return description.slice(0, 40) + '...'
      }
      return description
    }
  },
  computed: {
    ...mapGetters('tabs', ['getAllProducts', 'getBills', 'getPendingBill']),
    ...mapState('tabs', ['tabs']),
    ...mapState('config', ['config']),
    bills() {
      return this.getBills(this.tabId)
    },
    pendingBill() {
      let bill = this.getPendingBill(this.tabId)
      return bill ? Bills.addPaymentInfo(bill, []) : null
    },
    hasPending() {
      return (
        this.pendingBill &&
        this.pendingBill.total >
          this.pendingBill.deliveryFee + this.pendingBill.minimumBasketSurcharge
      )
    },
    billOptions() {
      return {
        barcode: this.config.organizationConfig.barcodes
      }
    },
    canvasBill() {
      return this.config.featureToggles['canvas_bill']
    }
  },
  components: {
    Icon
  },
  watch: {
    async previewBill() {
      if (this.previewBill) {
        let canvas = await TicketGenerator.bill(
          this.previewBill,
          this.billOptions
        )
        this.canvasImage = canvas.toDataURL('image/png')
      }
    }
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}
</style>
