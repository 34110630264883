import QRCode from 'qrcode'
import { Logo, Label, EmptySeparator } from './components.js'

async function getQRImage(url) {
  return await QRCode.toDataURL(url)
}

async function tbaiInfo(bill) {
  if (!bill.metadata?.tbai) {
    return []
  }
  let qrImage = await getQRImage(bill.metadata.tbai.url)
  return [
    new EmptySeparator(),
    new Label(bill.metadata.tbai.code, {
      align: 'center',
      style: 'bold 14px',
      lineHeight: 24
    }),
    new EmptySeparator({ lineHeight: 20 }),
    new Logo(qrImage, { ratio: 0.5 })
  ]
}

export default tbaiInfo
