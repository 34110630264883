<template>
  <div class="shadow">
    <div class="bg px-4 pt-3 flex text-white items-center h-topbar">
      <div class="flex-1 flex items-center">
        <div v-if="showBack" @click="back" class="pr-6">
          <icon name="arrow-left" />
        </div>
        <div
          v-else
          class="uppercase text-blue bg-gray-300 border-2 border-gray-400 rounded-full w-8 h-8 text-center leading-7 text-sm"
          @click="$router.push({ name: 'employees' })"
        >
          {{ employeeInitials }}
        </div>
      </div>
      <div class="center flex justify-center">
        <slot name="center">
          <img src="../../assets/logo-light.svg" />
        </slot>
      </div>
      <div class="right flex-1 flex justify-end">
        <slot name="right" />
      </div>
    </div>
    <div class="py-1 bg-red text-white flex justify-center" v-if="!isConnected">
      <icon name="loading-error" class="mr-2 flex-shrink-0" />
      {{ $t('topbar.internet-issues') }}
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TopBar',
  props: {
    showBack: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    back() {
      if (this.hasBackListener) {
        this.$emit('back')
      } else {
        this.$router.go(-1)
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['currentEmployee']),
    ...mapGetters('status', ['isConnected']),
    hasBackListener() {
      return this.$listeners && this.$listeners.back
    },
    employeeInitials() {
      if (!this.currentEmployee.name) return ''
      let initials = this.currentEmployee.name
        .split(/[.,/ -]/)
        .map(n => n[0])
        .join('')
        .slice(0, 2)
      if (initials.length < 2) {
        return this.currentEmployee.name.slice(0, 2)
      } else {
        return initials
      }
    }
  },
  components: {
    Icon
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
.bg {
  background: linear-gradient(270deg, #545383 0%, #1d1c48 100%);
  box-shadow: 0 0.25rem 0.375rem -0.062rem rgba(0, 0, 0, 0.1);
}

.h-topbar {
  height: 4.5rem;
}
</style>
