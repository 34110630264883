<template>
  <div
    class="flex items-center tab border-r-4 h-16 border-white justify-between"
    :class="{
      closed: !tab.open,
      cancelled: isCancelled,
      alert: hasAlert,
      selected: tab.id === selectedTabId
    }"
  >
    <div
      class="flex items-center justify-center pl-4 pr-2"
      :class="{ 'ml-8': offset }"
    >
      <div
        class="rounded-full status w-3 h-3 relative flex items-center justify-center"
        :class="{
          'billing-started': tab.billingStartedTime,
          'closed opacity-75': !tab.open,
          cancelled: isCancelled,
          alert: hasAlert,
          selected: tab.id === selectedTabId,
          closed: !tab.open,
          scheduled: !tab.activationTime
        }"
      />
      <div class="flex flex-col p-2">
        <div class="text-lg tab-name flex items-center">
          <div
            class="right-0 bg-white rounded-full circle mr-2"
            v-if="tab.pendingKitchenOrders || ordersNotPrinted"
          >
            <div class="w-full h-full mr-1 rounded-full bg-yellow" />
          </div>
          <div v-if="tab.code" class="tab-code whitespace-no-wrap">
            <span class="font-bold">{{ tab.code }}</span>
            <span v-if="tab.tableName"> / {{ tab.tableName }}</span>
            <span v-else-if="tab.name"> / {{ tab.name }}</span>
          </div>
          <div v-else-if="tab.tableName">{{ tab.tableName }}</div>
          <div v-else>{{ tab.name }}</div>
        </div>
        <div class="text-sm tab-source flex">
          {{ tab.source }}
        </div>
      </div>
    </div>
    <div class="font-bold py-4 pr-4 tab-time relative">
      <div v-if="isCancelled" class="uppercase">
        {{ $t('tabs.cancelled') }}
      </div>
      <div v-else-if="hasAlert" class="uppercase">
        {{ $t('tabs.alert') }}
      </div>
      <div
        v-else-if="!tab.activationTime"
        class="text-blue-100 flex flex-col items-end"
      >
        <div>
          {{ tab.schedulingTime | day }}
        </div>
        <div>
          {{ tab.schedulingTime | time }}
        </div>
      </div>
      <div v-else>{{ tab.activationTime | time }}</div>
    </div>
  </div>
</template>

<script>
import kitchenOrdersMixin from '@/components/ordering/kitchenOrdersMixin.js'

export default {
  name: 'SmallTabRow',
  mixins: [kitchenOrdersMixin],
  props: {
    tab: {
      type: Object,
      default: null
    },
    selectedTabId: {
      type: String,
      default: null
    },
    offset: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isCancelled() {
      return this.tab.deliveryOrder && this.tab.deliveryOrder.cancelTime
    },
    hasAlert() {
      return (
        this.tab.deliveryOrder &&
        this.tab.deliveryOrder.errorTime &&
        !this.tab.deliveryOrder.errorResolved
      )
    },
    tabId() {
      return this.tab.id
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.status {
  background: #4299e1;
  &.billing-started {
    background: $light-red;
  }
  &.cancelled {
    background: white;
  }
  &.cancelled.selected:not(.closed) {
    @apply bg-error;
  }
  &.alert {
    background: white;
  }
  &.alert.selected:not(.closed) {
    @apply bg-yellow;
  }
  &.closed {
    background: #a0a0c0;
  }
  &.scheduled {
    @apply bg-blue-100;
  }
}

.tab-name {
  @apply text-blue;
}

.tab-source {
  @apply text-gray-400;
}

.tab-time {
  @apply text-green;
}

.tab.cancelled:not(.selected):not(.closed) {
  & .tab-name,
  & .tab-source,
  & .tab-time {
    @apply text-white;
  }
  @apply bg-error border-error;
}

.tab.cancelled.selected:not(.closed) {
  & .tab-time {
    @apply text-error;
  }
}

.tab.alert:not(.selected):not(.closed) {
  & .tab-name,
  & .tab-source,
  & .tab-time {
    @apply text-white;
  }
  @apply bg-yellow border-yellow;
}

.tab.alert.selected:not(.closed) {
  & .tab-time {
    @apply text-yellow;
  }
  @apply border-yellow;
}

.tab.closed {
  & .tab-name,
  & .tab-source,
  & .tab-time {
    @apply text-gray-400;
  }
}

.tab.selected {
  @apply bg-gray-200 border-red;
}

.tab-code {
  text-overflow: ellipsis;
  max-width: 170px;
  @apply overflow-hidden;
}
.circle {
  width: 10px;
  height: 10px;
  padding: 1px;
}
</style>
