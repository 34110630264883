<template>
  <div>
    <div
      v-if="deliveryStatus"
      class="flex flex-row text-sm justify-between fixed-w my-3"
    >
      <div class="flex flex-row items-baseline ml-5">
        <div
          class="h-3 w-3 rounded-full"
          :class="'bg-' + deliveryStatus.progressColor"
        />
        <div class="ml-3 uppercase">
          {{ name }}
        </div>
      </div>
      <div class="flex flex-row mr-5">
        <div>
          {{ deliveryStatus.creationTime | time }}
        </div>
        <div
          v-if="deliveryStatus.progressTime !== undefined"
          class="lowercase ml-1"
          :class="'text-' + deliveryStatus.progressColor"
        >
          ({{ deliveryStatus.progressTime }} min)
        </div>
      </div>
    </div>
    <div v-else class="text-sm fixed-w my-3 uppercase opacity-50">
      <div class="flex flex-row items-baseline ml-5">
        <div class="h-3 w-3 rounded-full bg-gray-400" />
        <div class="ml-3">
          {{ name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeliveryRecordRow',
  data() {
    return {}
  },
  props: {
    deliveryStatus: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: ''
    }
  },
  methods: {}
}
</script>

<style scoped>
.fixed-w {
  width: 300px;
}
</style>
