<template>
  <l-modal
    :size="getSize"
    :title="$t('new-tab.new-tab-delivery')"
    @close="$emit('close')"
    disable-click-outside
  >
    <template slot="body">
      <ExternalDeliveryTab
        v-if="!isOwnDelivery && !seeClientDetails"
        :tab.sync="newTab"
        :customer="customer"
        :delivery-companies="deliveryCompanies"
        @nextStep="nextStep"
        @close="$emit('close')"
      />
      <TabDetails
        v-else-if="!isClientTabs && !seeClientDetails"
        :tab.sync="newTab"
        :customer="customer"
        :phone-number.sync="customer.phoneNumber"
        :delivery-companies="deliveryCompanies"
        :source.sync="newTab.source"
        @nextStep="nextStep"
        @close="$emit('close')"
      />
      <ClientDetails
        v-else
        :customer="customer"
        :delivery="delivery"
        :new-tab="newTab"
        :last-customer-tabs="lastCustomerTabs"
        :is-own-delivery="isOwnDelivery"
        @back="backStep"
        @tabCreated="tabId => $emit('tabCreated', tabId)"
        @close="$emit('close')"
      />
    </template>
  </l-modal>
</template>

<script>
import { deliveryCompanies } from '@last/core/src/deliverySources'
import LModal from '@last/core-ui/components/LModal'
import ClientDetails from './ClientDetails'
import ExternalDeliveryTab from './ExternalDeliveryTab'
import TabDetails from './TabDetails'

export default {
  name: 'NewDeliveryTab',
  components: {
    LModal,
    ClientDetails,
    ExternalDeliveryTab,
    TabDetails
  },
  data() {
    return {
      newTab: {
        source: null,
        pickupType: 'ownDelivery'
      },
      customer: {},
      lastCustomerTabs: [],
      isClientTabs: false,
      deliveryCompanies: deliveryCompanies,
      seeClientDetails: false
    }
  },
  mounted() {
    this.newTab.source = this.deliveryCompanies[0].value
  },
  computed: {
    isOwnDelivery() {
      return this.newTab.source === 'OwnDelivery'
    },
    getSize() {
      return this.isClientTabs ? 'large' : 'small'
    },
    delivery() {
      return this.customer.addresses ? this.customer.addresses[0] : {}
    }
  },
  methods: {
    nextStep({ customer, pickupType, tabs }) {
      this.seeClientDetails = true
      this.isClientTabs = true
      this.customer = { ...this.customer, ...customer }
      this.lastCustomerTabs = tabs
      if (pickupType) {
        this.newTab.pickupType = pickupType
      }
    },
    backStep({ customer, tab, delivery }) {
      this.seeClientDetails = false
      this.isClientTabs = false
      this.customer = { ...customer, ...delivery }
      this.newTab = tab
    }
  }
}
</script>
