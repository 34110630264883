<template>
  <div class="h-screen flex flex-col flex-grow-0 bg-white" v-if="tab">
    <top-bar show-back>
      <template slot="center">
        <div class="flex items-center text-xl">
          <div class="flex">
            <icon name="diners" class="mr-2 opacity-50" />
            <div v-if="tab.code" class="tab-code whitespace-no-wrap">
              <span class="font-bold">{{ tab.code }}</span>
              <span v-if="tab.tableName"> / {{ tab.tableName }}</span>
              <span v-else-if="tab.name"> / {{ tab.name }}</span>
            </div>
            <div v-else-if="tab.tableName">{{ tab.tableName }}</div>
            <div v-else>{{ tab.name }}</div>
            <div v-if="tab.activationTime" class="ml-4 text-gray-400">
              {{ tab.activationTime | time }}
            </div>
          </div>
        </div>
      </template>
    </top-bar>
    <div class="text-blue flex flex-col flex-1 overflow-y-scroll">
      <div class="flex flex-row items-center font-title text-xl"></div>

      <div
        class="border border-gray-200 bg-white rounded-lg py-4 flex flex-col flex-1"
      >
        <div class="flex flex-row items-center px-4">
          <div class="font-title text-lg uppercase font-bold flex-1">
            {{ $t('tabs.order-details') }}
          </div>
        </div>
        <template>
          <div class="flex flex-col flex-1">
            <div class="flex flex-col" v-dragscroll="disableDragscroll">
              <div
                v-for="([name, slot], index) in Object.entries(
                  getProductsIndexed
                )"
                :key="index"
              >
                <div class="flex p-4 font-bold text-xl items-center">
                  <icon name="union" originalColor />
                  <p class="ml-4 w-1/2">
                    {{ name }}
                  </p>
                  <p>{{ slot.price | currency }}</p>
                </div>
                <l-section-side-menu
                  :show-select-radio="false"
                  hide-section-name
                  :elements="slot.products"
                  @onAdd="event => onUpdate(slot, event)"
                  @onUpdate="event => onUpdate(slot, event)"
                  @draggableStart="startDraggable"
                  @draggableEnd="endDraggable"
                >
                  <tab-product
                    class="tab-product"
                    v-for="product in slot.products"
                    :key="product.id"
                    :id="product.id"
                    :product="product"
                    :disabled-by-parent="disableSwipe"
                    @selected="selectProduct(product, null)"
                  />
                </l-section-side-menu>
              </div>
            </div>
          </div>
          <div
            class="flex flex-row justify-between px-4 border-t border-gray-200 pt-3 text-gray-400 uppercase"
          >
            <div>{{ $t('total:') }}</div>
            <div class="font-bold text-accent">{{ tabTotal | currency }}</div>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="bill"
      class="border border-gray-200 bg-white rounded-lg mt-2 mb-2 flex flex-row justify-around text-center uppercase"
    >
      <div
        class="flex flex-col py-2 border-r border-gray-200 flex-1 text-accent"
      >
        <div>{{ $t('checkout.pending') }}</div>
        <div class="font-bold">{{ tabAmounts.pendingAmount | currency }}</div>
      </div>
      <div class="flex flex-col py-2 flex-1">
        <div>{{ $t('checkout.paid') }}</div>
        <div class="font-bold">{{ tabAmounts.paidAmount | currency }}</div>
      </div>
    </div>
    <tab-actions :tab-id="tabId" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import TabProduct from '@/components/ordering/TabProduct.vue'
import TopBar from '@/mobile/components/TopBar.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import TabActions from '@/mobile/components/TabActions.vue'
import LSectionSideMenu from '@last/core-ui/components/LSectionSideMenu'
import Bills from '@last/core/src/billsGenerator.js'

export default {
  name: 'Cart',
  data() {
    return {
      showTabDetails: false,
      disableSwipe: false,
      charging: false,
      disableDragscroll: false
    }
  },
  methods: {
    ...mapActions('tabs', ['removeSeat', 'moveProduct', 'updateCourse']),
    selectProduct(product) {
      if (product.comboProducts) {
        const parentProduct = this.$store.getters['catalog/getProductById'](
          product.parentProduct
        )

        if (parentProduct && parentProduct.categories) {
          this.editingCombo = {
            catalog: parentProduct,
            edit: product,
            comboProduct: product.shownCombo
          }
          this.$router.push({
            name: 'comboEditor',
            params: {
              tabId: this.tabId,
              catalogCombo: this.editingCombo.catalog,
              editingCombo: this.editingCombo.edit
            }
          })
        }
      } else {
        const parentProduct = this.$store.getters['catalog/getProductById'](
          product.parentProduct
        )
        let productToEdit = {
          ...parentProduct,
          quantity: product.quantity,
          modifiers: product.modifiers,
          comments: product.comments,
          id: product.id,
          parentProduct: (parentProduct && parentProduct.id) || null,
          course: product.course,
          price: product.price,
          name: product.name,
          discountType: product.discountType,
          discountAmount: product.discountAmount,
          pointsExpense: product.pointsExpense
        }
        this.$router.push({
          name: 'productEditor',
          params: {
            tabId: this.tabId,
            catalogProductId: product.id,
            showQuantity: true,
            product: productToEdit
          }
        })
      }
    },
    onUpdate(slot, event) {
      let productId = event.item.id
      let position = event.newIndex
      if (this.tab.orderingMode === 'seats') {
        this.moveProduct({
          tabId: this.tabId,
          seat: slot.seat,
          position,
          productId
        })
      } else {
        this.updateCourse({
          course: slot.course,
          productId: productId
        })
      }
    },
    startDraggable() {
      this.disableSwipe = true
      this.disableDragscroll = true
    },
    endDraggable() {
      this.disableSwipe = false
      this.disableDragscroll = false
    },
    closeBill() {
      if (!this.selectedBill || this.isExisting(this.selectedBill.id)) {
        return
      }
      this.addBill({ tabId: this.tabId, bill: this.selectedBill })
      this.selectedProductsIds = []
      this.currentDiscount = {}
      if (this.selectedBill.pending === 0 && this.currentBill) {
        this.selectedBillId = this.currentBill.id
      }
    }
  },
  computed: {
    ...mapState('tabs', ['tabs']),
    ...mapState('config', ['config']),
    ...mapGetters('catalog', ['allCourses', 'getCatalogIdByTabVirtualBrandId']),
    ...mapGetters('tabs', [
      'getCourseProducts',
      'getSeatProducts',
      'getSharedProducts',
      'getTotal',
      'getDeliveryFee',
      'getBills',
      'getAllProducts',
      'getPendingBill'
    ]),
    tabAmounts() {
      let pendingAmount = 0
      let paidAmount = 0
      for (const bill of this.getBills(this.tabId)) {
        pendingAmount += bill.pending
        paidAmount += bill.paid
      }
      const pendingBill = this.getPendingBill(this.tabId)
      if (pendingBill) {
        pendingAmount += pendingBill.total
      }
      return { pendingAmount, paidAmount }
    },
    bill() {
      const bills = this.getBills(this.tabId)
      const pendingBills = bills.filter(bill => bill.pending > 0)
      if (pendingBills.length > 0) {
        return pendingBills[0]
      } else {
        let bill = this.getPendingBill(this.tabId)
        if (!bill) {
          return bills[bills.length - 1]
        }
        return Bills.addPaymentInfo(bill, [])
      }
    },
    tabId() {
      return this.$route.params.tabId
    },
    tab() {
      return this.tabs[this.tabId]
    },
    getProductsIndexed() {
      if (this.tab.orderingMode === 'seats') return this.productsBySeats
      else return this.productsByCourse
    },
    productsBySeats() {
      let result = {}
      let [sharedProducts, seatProducts] = [
        this.getSharedProducts(this.tabId),
        this.getSeatProducts(this.tabId)
      ]
      result['Shared'] = {
        seat: null,
        products: sharedProducts,
        price: sharedProducts.reduce((acc, curr) => acc + curr.finalPrice, 0)
      }
      for (let i = 0; i < seatProducts.length; i++) {
        result[`${this.$t('ordering.seat')} ${i + 1}`] = {
          seat: i,
          products: seatProducts[i],
          price: seatProducts[i].reduce((acc, curr) => acc + curr.finalPrice, 0)
        }
      }
      return result
    },
    productsByCourse() {
      let result = {}
      let courseProducts = this.getCourseProducts({
        catalogId: this.catalogId,
        tabId: this.tabId
      })
      for (let { name, products } of courseProducts) {
        result[name] = {
          course: name,
          products,
          price: products.reduce((acc, curr) => acc + curr.finalPrice, 0)
        }
      }
      return result
    },
    tabProducts() {
      return this.getAllProducts(this.tabId)
    },
    catalogId() {
      return this.getCatalogIdByTabVirtualBrandId(this.tab.virtualBrand)
    },
    tabTotal() {
      return this.getTotal(this.tabId)
    }
  },
  watch: {
    bill() {
      if (!this.bill) {
        this.$router.push({
          name: 'orderManagement',
          params: { tabId: this.tabId }
        })
      }
    }
  },
  components: {
    TabProduct,
    TopBar,
    Icon,
    TabActions,
    LSectionSideMenu
  }
}
</script>

<style scoped lang="scss">
@import '@/variables.scss';
.no-border-l {
  border-left: 0px;
}
.h-screen {
  height: 100vh;
  min-height: -webkit-fill-available;
}

.status {
  background: #4299e1;
  &.billing-started {
    background: $light-red;
  }
  &.cancelled {
    background: white;
  }
  &.cancelled.selected:not(.closed) {
    @apply bg-error;
  }
  &.alert {
    background: white;
  }
  &.alert.selected:not(.closed) {
    @apply bg-yellow;
  }
  &.closed {
    background: #a0a0c0;
  }
  &.scheduled {
    @apply bg-blue-100;
  }
}

.transparent-background {
  background-color: rgba(30, 32, 31, 0.9);
}

.height-transition {
  transition: height 3s;
}

.collapse {
  flex-grow: 0.001;
}
</style>
