class KDS {
  constructor(config) {
    this.config = config
  }

  printImage() {
    // Do nothing
    return
  }
}

export default KDS
