import i18n from '@/i18n.js'
import store from '../store'

import {
  Title,
  InfoItem,
  Section,
  Label,
  DoubleLabel,
  EmptySeparator,
  Separator,
  DottedSeparator,
  KitchenProduct,
  Box,
  If
} from './components.js'

function pickupTypeLabel(type) {
  if (!type) return null
  switch (type) {
    case 'delivery':
      return i18n.t('bill.external-delivery')
    case 'ownDelivery':
      return i18n.t('bill.own-delivery')
    case 'takeAway':
      return i18n.t('bill.take-away')
    default:
      return 'restaurant'
  }
}

function products(order) {
  return order.courses.flatMap(course => [
    new Section(course.course),
    ...course.products.flatMap(product => {
      if (product.comboId) {
        for (let i = 1; i < product.products.length; i++) {
          if (
            product.products[i].categoryPosition !=
            product.products[i - 1].categoryPosition
          )
            product.products[i].separator = true
        }

        return [
          new EmptySeparator({ lineHeight: 10 }),
          new Label(product.comboName.toUpperCase(), {
            baseline: 'bottom',
            lineHeight: 30,
            style: 'bold 18px'
          }),
          ...product.products.flatMap(comboProduct => {
            let res = [new KitchenProduct(comboProduct, order.pickupType)]
            if (comboProduct.separator)
              res = [new DottedSeparator({ size: 20, padding: 0 }), ...res]
            return res
          })
        ]
      } else {
        return [new KitchenProduct(product, order.pickupType)]
      }
    })
  ])
}

function generatePaperTicket(order) {
  let kitchenOrderTitle
  if (order.pickupType) {
    kitchenOrderTitle = order.code || order.tabName
  } else {
    kitchenOrderTitle = order.tabName || order.code
  }
  let enabledBrands = store.getters['config/virtualBrands']?.filter(
    virtualBrand => virtualBrand.enabled
  )
  return [
    new EmptySeparator({ lineHeight: 75 }),
    new If(
      order.modifiedOrder,
      new Box(i18n.t('kitchen-order.modified-order'))
    ),
    new If(order.modifiedOrder, new EmptySeparator()),
    new Label(order.printerName?.toUpperCase(), {
      align: 'center',
      style: '25px',
      lineHeight: 30
    }),
    new Label(order.source?.toUpperCase(), {
      align: 'center',
      style: '25px'
    }),
    new If(
      kitchenOrderTitle,
      new Title(kitchenOrderTitle, { height: 90, fontSize: 60 })
    ),
    new If(!kitchenOrderTitle, new EmptySeparator()),
    new If(
      enabledBrands?.length > 1,
      new Label(order.virtualBrandName?.toUpperCase(), {
        style: 'bold 20px',
        lineHeight: 25
      })
    ),
    new If(enabledBrands?.length > 1, new EmptySeparator()),
    new If(
      order.pickupType,
      new InfoItem(order.source, order.tabName, {
        important: true,
        fontSize: 20
      })
    ),
    new Label(mapKitchenOrderTime(order), {
      style: '18px',
      lineHeight: 30,
      valueType: 'date'
    }),
    new InfoItem(
      i18n.t('kitchen-order.person-in-charge-name'),
      order.waiterName
    ),
    new If(
      order.seats,
      new InfoItem(i18n.t('kitchen-order.diners'), order.seats)
    ),
    new InfoItem(i18n.t('kitchen-order.courier-name'), order.courierName),
    new InfoItem(i18n.t('kitchen-order.customer-name'), order.customerName, {
      important: true
    }),
    new If(
      order.pickupTime,
      new InfoItem(i18n.t('kitchen-order.pickup'), order.pickupTime, {
        valueType: 'date'
      })
    ),
    new If(
      order.estimatedDeliveryTime || order.schedulingTime,
      new InfoItem(
        i18n.t('kitchen-order.delivery-time'),
        order.estimatedDeliveryTime || order.schedulingTime,
        {
          valueType: 'date'
        }
      )
    ),
    new If(order.pickupType, new Box(pickupTypeLabel(order.pickupType))),
    ...products(order),
    new If(order.note, new Box(i18n.t('kitchen-order.note'), order.note)),
    new If(
      order.allergyInfo,
      new Box(i18n.t('kitchen-order.allergies'), order.allergyInfo)
    ),
    new EmptySeparator(),
    new EmptySeparator()
  ]
}

function generateStickerTicket(order) {
  let tabCode = order.code
  if (order.tableName) tabCode += ` - ${order.tableName}`
  else if (order.tabName) tabCode += ` - ${order.tabName}`

  if (order.allergyInfo || order.note) tabCode += '*'
  return [
    new EmptySeparator(),
    new DoubleLabel(order.stickerPart, tabCode, {
      firstStyle: '27px Roboto',
      secondStyle: 'bold 30px Roboto'
    }),
    new Separator({ size: 4, padding: 10 }),
    new EmptySeparator(),
    ...order.courses.flatMap(course => [
      ...course.products.flatMap(product => {
        if (product.comboId) {
          return product.products.map(
            comboProduct =>
              new KitchenProduct(
                { ...comboProduct, name: `(C) ${comboProduct.name}` },
                order.pickupType,
                {
                  showQuantity: false,
                  fontSize: 30
                }
              )
          )
        } else {
          return [
            new KitchenProduct(product, order.pickupType, {
              showQuantity: false,
              fontSize: 30
            })
          ]
        }
      })
    ])
  ]
}

function generateKitchenOrder(order, printer) {
  if (printer.type === 'ZPL') {
    return generateStickerTicket(order)
  } else {
    return generatePaperTicket(order)
  }
}

export default generateKitchenOrder

function mapKitchenOrderTime(order) {
  if (order.versions?.length > 0) {
    let numVersions = order.versions.length
    return order.versions[numVersions - 1].sentTime
  }
  return order.activationTime || order.creationTime
}
