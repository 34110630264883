<template>
  <div class="rounded pt-1 absolute left-0 right-0 z-10">
    <div class="bg-white flex rounded-lg p-2 flex-col shadow">
      <l-input icon="zoom" placeholder="Search" v-model="countryName" />
      <div class="flex flex-col scrollable whitespace-no-wrap">
        <div
          v-for="c in filteredCountries"
          :key="c.code"
          class="pb-2"
          @click="selectCountry(c)"
        >
          <div class="ml-2 flex cursor-pointer">
            <icon :name="c.code.toLowerCase()" original-color />
            <div class="text-blue ml-2">{{ c.name }}</div>
            <div class="text-gray-300 ml-3">(+{{ c.prefix }})</div>
          </div>
          <div class="mt-2 m-auto opacity-50 separator bg-gray-300" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LInput from './LInput.vue'
import Icon from './Icon.vue'
import { getCountryCallingCode } from 'libphonenumber-js'
import supportedCountries from '@last/core/src/supportedCountries.js'

export default {
  name: 'CountrySelector',
  data() {
    return {
      countryName: '',
      countries: [],
      preferredCountries: ['ES', 'US', 'GB', 'FR', 'DE', 'IT', 'PT']
    }
  },
  mounted() {
    const regionNames = new Intl.DisplayNames(['es'], { type: 'region' })
    this.countries = this.preferredCountries
      .map(country => {
        return {
          name: regionNames.of(country),
          code: country,
          prefix: getCountryCallingCode(country)
        }
      })
      .concat(
        supportedCountries.sort().flatMap(country => {
          if (this.preferredCountries.includes(country)) return []
          try {
            let prefix = getCountryCallingCode(country)
            return [
              {
                name: regionNames.of(country),
                code: country,
                prefix
              }
            ]
          } catch (e) {
            return []
          }
        })
      )
  },
  methods: {
    selectCountry(country) {
      this.$emit('select', country)
    }
  },
  computed: {
    filteredCountries() {
      if (!this.countryName) return this.countries
      return this.countries.filter(c =>
        c.name.toLowerCase().includes(this.countryName.toLowerCase())
      )
    }
  },
  components: {
    LInput,
    Icon
  }
}
</script>
<style scoped>
.scrollable {
  max-height: 220px;
  overflow-y: scroll;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.separator {
  width: 95.5%;
  height: 2px;
}
</style>
