<template>
  <div>
    <div
      class="flex items-center justify-between w-full p-4 font-bold uppercase rounded-small discount-button"
      :class="{
        'opacity-50': disabled,
        'bg-blue text-white': theme === 'dark',
        'bg-white text-blue': theme === 'light'
      }"
      @click="editDiscount"
    >
      <icon name="discount" :class="{ 'text-green': theme === 'light' }" />
      <div v-if="!small" class="ml-2">
        {{ $t('bill-discount.button') }} {{ formattedDiscount }}
      </div>
    </div>
    <portal to="appRoot" v-if="showModal">
      <div
        class="absolute top-0 left-0 z-30 flex items-center justify-center w-screen h-screen blur-background"
      >
        <div>
          <div class="pb-6 text-2xl text-center text-white uppercase">
            {{ $t('bill-discount.title') }}
          </div>
          <div
            class="relative p-12 text-left whitespace-pre-line bg-white shadow rounded-big text-color-question font-body dialog-w"
            @click.stop
          >
            <div class="flex flex-row">
              {{ $t('bill-discount.select-discount-type') }}
            </div>
            <icon
              name="trash"
              class="absolute top-0 right-0 m-6 text-red"
              @click.native="removeDiscount"
            />
            <div class="flex flex-row items-center">
              <div
                v-for="discountType in discountTypes"
                :key="discountType.type"
                class="flex flex-row items-center py-3 pr-5 radios"
              >
                <l-small-radio
                  :value="editingDiscount.type === discountType.type"
                  class="mr-2"
                  @input="selectType(discountType.type)"
                />
                <div
                  @click="editingDiscount.type = discountType.type"
                  class="text-size text-color-option"
                >
                  {{ discountType.label }}
                </div>
              </div>
            </div>
            <div v-if="editingDiscount.type === 'promotion'">
              <div v-if="promotionsWithoutPoints.length > 0">
                <div class="pt-2">
                  {{ $t('bill-discount.no-points-discounts') }}
                </div>
                <div
                  class="flex items-center mt-3 overflow-scroll scrolling-touch"
                >
                  <div
                    v-for="promotion in promotionsWithoutPoints"
                    :key="promotion.id"
                    class="px-5 py-3 mr-3 whitespace-no-wrap border border-gray-400 rounded-lg width-200"
                    :class="{
                      'bg-red text-white':
                        editingDiscount.promotionId === promotion.id
                    }"
                    @click="promotionSelected(promotion)"
                  >
                    <div
                      :class="{
                        'text-blue':
                          editingDiscount.promotionId !== promotion.id
                      }"
                    >
                      {{ promotion.name }}
                    </div>
                    <div>
                      {{ promotion.description }}
                    </div>
                    <div class="flex flex-row">
                      <div v-if="promotion.discountType === 'percentage'">
                        {{ promotion.discountAmount }}%
                      </div>
                      <div v-else>
                        {{ promotion.discountAmount | currency }}
                      </div>
                      <div class="pl-1">
                        {{ $t('bill-discount.of-discount') }}
                      </div>
                    </div>
                    <p v-if="promotion.remainingRedemptions">
                      {{
                        promotion.remainingRedemptions +
                        ' ' +
                        $t('bill-discount.remaining-redemptions')
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="promotionsWithPoints.length > 0">
                <div class="flex flex-row pt-2">
                  <div>{{ $t('bill-discount.points-discounts') }} -</div>
                  <div class="pl-1 text-green">
                    {{ $t('bill-discount.available-points') }}:
                    {{ customerPoints }}
                  </div>
                </div>
                <div
                  class="flex items-center mt-3 overflow-scroll scrolling-touch"
                >
                  <div
                    v-for="promotion in promotionsWithPoints"
                    :key="promotion.id"
                    class="px-5 py-3 mr-3 whitespace-no-wrap border border-gray-400 rounded-lg width-200"
                    :class="{
                      'bg-red text-white':
                        editingDiscount.promotionId === promotion.id
                    }"
                    @click="promotionSelected(promotion)"
                  >
                    <div
                      :class="{
                        'text-blue':
                          editingDiscount.promotionId !== promotion.id
                      }"
                    >
                      {{ promotion.name }}
                    </div>
                    <div>
                      {{ promotion.description }}
                    </div>
                    <div class="flex flex-row">
                      <div v-if="promotion.discountType === 'percentage'">
                        {{ promotion.discountAmount }}%
                      </div>
                      <div v-else>
                        {{ promotion.discountAmount | currency }}
                      </div>
                      <div class="pl-1">
                        {{ $t('bill-discount.of-discount') }}
                      </div>
                    </div>
                    <div
                      :class="{
                        'text-green':
                          editingDiscount.promotionId !== promotion.id
                      }"
                      class="mt-2 text-right"
                    >
                      {{ promotion.pointsExpense }}
                      {{ $t('bill-discount.points') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="pt-2">
                {{ $t('bill-discount.discount-to-apply') }}
              </div>
              <div class="relative">
                <l-input v-model="editingDiscount.amount" />
                <div class="absolute top-0 right-0 m-3 text-red">
                  <icon
                    v-if="editingDiscount.type === 'percentage'"
                    name="porcentaje"
                  />
                  <icon v-else :name="`currencies/${currencyIcon}`" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex pt-4">
            <div
              class="flex-1 p-3 px-4 font-bold text-center text-white uppercase border border-blue-700 bg-blue rounded-small"
              @click="close"
            >
              {{ $t('dialog.cancel') }}
            </div>
            <div
              class="flex-1 p-3 px-4 ml-4 font-bold text-center text-white uppercase border bg-red border-red-b rounded-small save-button"
              :class="{ 'opacity-50 pointer-events-none': wrongAmount }"
              @click="save"
            >
              {{ $t('bill-discount.save') }}
            </div>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon'
import LSmallRadio from '@last/core-ui/components/LSmallRadio'
import LInput from '@last/core-ui/components/LInput'
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import api from '@/api.js'
import moment from 'moment'
import PromotionFilter from '@last/core/src/PromotionFilter'

export default {
  name: 'BillDiscount',
  props: {
    discount: {
      type: Object,
      default: () => ({})
    },
    originalTotal: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabId: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    }
  },
  data() {
    return {
      showModal: false,
      message: null,
      customerPoints: 0,
      editingDiscount: {},
      customerRestrictedPromotions: [],
      customerUsedPromotions: []
    }
  },
  async mounted() {
    let customerId = this.getCustomerId(this.tabId)
    if (customerId) {
      let response = await api.get(`/customer/${customerId}/promotions`)
      this.customerPoints = response.data.points
      this.customerRestrictedPromotions = response.data.customerPromotions
      this.customerUsedPromotions = response.data.customerUsedPromotions
    }
  },
  methods: {
    selectType(type) {
      this.editingDiscount.type = type
    },
    close() {
      this.$emit('close')
      this.showModal = false
    },
    removeDiscount() {
      this.$emit('remove-discount')
      this.showModal = false
    },
    promotionSelected(promotion) {
      Vue.set(this.editingDiscount, 'promotionId', promotion.id)
      Vue.set(this.editingDiscount, 'promotionType', promotion.discountType)
      Vue.set(this.editingDiscount, 'promotionAmount', promotion.discountAmount)
      Vue.set(this.editingDiscount, 'pointsExpense', promotion.pointsExpense)
      Vue.set(this.editingDiscount, 'promotionName', promotion.name)
      Vue.set(this.editingDiscount, 'freeDelivery', promotion.freeDelivery)
    },
    async editDiscount() {
      if (this.disabled) return
      this.editingDiscount = {
        type: this.discount.promotionId
          ? 'promotion'
          : this.discount.type || 'currency',
        amount:
          this.discount.type === 'currency'
            ? this.discount.amount / 100
            : this.discount.amount,
        promotionId: this.discount.promotionId,
        promotionType: this.discount.type,
        promotionAmount: this.discount.amount,
        pointsExpense: this.discount.pointsExpense,
        freeDelivery: this.discount.freeDelivery
      }
      this.showModal = true
    },
    setPromotion() {
      this.$emit('update:discount', {
        type: this.editingDiscount.promotionType,
        amount: this.editingDiscount.promotionAmount,
        name: this.editingDiscount.promotionName,
        promotionId: this.editingDiscount.promotionId,
        pointsExpense: this.editingDiscount.pointsExpense,
        concept: this.editingDiscount.promotionName,
        freeDelivery: this.editingDiscount.freeDelivery,
        global: true
      })
    },
    async save() {
      const hasPermission = await this.$confirm('DISCOUNT_MANAGER')
      if (!hasPermission) {
        this.$emit('cancel')
        return
      }
      if (this.editingDiscount.type === 'promotion') {
        this.setPromotion()
        this.showModal = false
        return
      }
      let amount = parseInt(this.editingDiscount.amount)
      if (this.editingDiscount.type === 'currency') {
        amount = Math.round(
          String(this.editingDiscount.amount).replace(',', '.') * 100
        )
        if (this.amount > this.originalTotal) {
          return
        }
      }
      this.$emit('update:discount', {
        type: this.editingDiscount.type,
        amount,
        global: true
      })
      this.showModal = false
    },
    checkIfPromotionCanBeShown(promotion) {
      const isNotProductOrCategory =
        !promotion.products && !promotion.categories

      let isCorrectAmount = true
      if (promotion.discountType === 'currency') {
        isCorrectAmount = promotion.discountAmount <= this.originalTotal
      }
      let isCorrectType = ['currency', 'percentage'].includes(
        promotion.discountType
      )

      let today = moment().locale('en').format('dddd').toLowerCase()

      return (
        isNotProductOrCategory &&
        isCorrectType &&
        isCorrectAmount &&
        new PromotionFilter(
          this.getTotal(this.tabId),
          this.locationId,
          today,
          this.tabs[this.tabId].pickupType,
          moment().format(),
          this.getCustomerId(this.tabId),
          this.customerUsedPromotions
        ).isPromotionValid(promotion)
      )
    }
  },
  computed: {
    ...mapState('auth', ['locationId']),
    ...mapState('tabs', ['tabs']),
    ...mapGetters('promotions', [
      'getPromotionsWithPoints',
      'getPromotionsWithoutPoints'
    ]),
    ...mapGetters('tabs', ['getCustomerId', 'getTotal']),
    ...mapGetters('config', ['currencyIcon']),
    formattedDiscount() {
      if (!this.discount || this.discount.amount === 0) return ''
      if (this.discount.type === 'currency') {
        return this.$options.filters.currency(this.discount.amount)
      } else if (this.discount.type === 'percentage') {
        return `${this.discount.amount}%`
      }
      return ''
    },
    wrongAmount() {
      if (this.editingDiscount.type === 'currency') {
        let amount = String(this.editingDiscount.amount).replace(',', '.') * 100
        if (amount > this.originalTotal) {
          return true
        }
      } else if (this.editingDiscount.type === 'percentage') {
        let amount = parseInt(this.editingDiscount.amount)
        if (amount < 0 || amount > 100 || isNaN(amount)) return true
      }
      return false
    },
    promotionsWithPoints() {
      return this.getPromotionsWithPoints
        .concat(this.customerRestrictedPromotions)
        .filter(promotion => {
          return (
            this.checkIfPromotionCanBeShown(promotion) &&
            promotion.pointsExpense <= this.customerPoints
          )
        })
    },
    promotionsWithoutPoints() {
      return this.getPromotionsWithoutPoints
        .concat(this.customerRestrictedPromotions)
        .filter(promotion => {
          return this.checkIfPromotionCanBeShown(promotion)
        })
    },
    discountTypes() {
      let types = [
        {
          type: 'percentage',
          label: this.$t('bill-discount.percentage')
        },
        {
          type: 'currency',
          label: this.$t('bill-discount.cash')
        }
      ]
      if (
        this.promotionsWithPoints.length > 0 ||
        this.promotionsWithoutPoints.length > 0
      ) {
        types.push({
          type: 'promotion',
          label: this.$t('bill-discount.promotion')
        })
      }
      return types
    }
  },
  components: {
    Icon,
    LSmallRadio,
    LInput
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.text-color-question {
  color: #aaaac9;
}

.text-color-option {
  color: #545383;
}

.text-size {
  font-size: 14px;
}

.dialog-w {
  width: 784px;
}
</style>
