import { mapState, mapGetters } from 'vuex'

let mixin = {
  data() {
    return {
      selectedTab: null,
      deliveriesClosed: true,
      tabBrowserSelectedTabId: null
    }
  },
  props: {
    preSelectedTabId: {
      type: String,
      default: null
    }
  },
  async mounted() {
    if (
      this.shiftsEnabled &&
      !this.selectedCashTillIsStarted &&
      this.$router.currentRoute.name !== 'employees'
    ) {
      this.$router.push({ name: 'employees' })
    }
    if (this.floorplanIds.length > 0) {
      this.selectedTab = this.floorplanIds[0]
    } else {
      this.selectedTab = 'delivery'
    }
    if (this.preSelectedTabId) {
      this.tabBrowserSelectedTabId = this.preSelectedTabId
      this.selectedTab = 'all'
    }
  },
  activated() {
    if (
      this.shiftsEnabled &&
      !this.selectedCashTillIsStarted &&
      this.$router.currentRoute.name !== 'employees'
    ) {
      this.$router.push({ name: 'employees' })
    }
    if (this.preSelectedTabId) {
      this.tabBrowserSelectedTabId = this.preSelectedTabId
      this.selectedTab = 'all'
    }
  },
  methods: {
    updateSelectedTab(value) {
      this.selectedTab = value
      this.tabBrowserSelectedTabId = null
    }
  },
  computed: {
    ...mapState('tables', ['floorplanIds', 'floorplans']),
    ...mapGetters('tables', ['getTables']),
    ...mapGetters('deliveryCompanies', ['sortedClosedUntil']),
    ...mapState('tabs', ['tabs']),
    ...mapGetters('till', ['selectedCashTillIsStarted']),
    ...mapState('till', ['shiftsEnabled']),
    ...mapState('config', ['config']),
    floorplanList() {
      return this.config.lastProductPosEnabled ||
        this.config.lastProductBookingsEnabled
        ? this.floorplanIds.map(id => this.floorplans[id])
        : []
    },
    tabCount() {
      let floorplanTabs = this.floorplanIds.map(id => {
        let tabIds = new Set(
          this.getTables(id)
            .flatMap(table => table.tabIds)
            .filter(id => id)
        )
        return {
          count: tabIds.size,
          floorplanId: id
        }
      })

      let count = floorplanTabs.reduce((res, value) => {
        res[value.floorplanId] = value.count
        return res
      }, {})
      count.all = Object.values(this.tabs).filter(tab => tab.open).length
      return count
    },
    showingFloorplan() {
      return (
        (this.config.lastProductPosEnabled ||
          this.config.lastProductBookingsEnabled) &&
        !this.tabBrowserSelectedTabId &&
        !['all', 'delivery', 'takeAway'].includes(this.selectedTab)
      )
    }
  },
  watch: {
    sortedClosedUntil() {
      this.deliveriesClosed = true
    }
  }
}

export default mixin
