<template>
  <div
    class="flex flex-col items-center justify-center h-screen bg-cover bg-image"
  >
    <div
      v-if="shiftsEnabled && selectedCashTill && tills.cash.length > 1"
      class="absolute top-0 sm:w-1/5 text-white"
    >
      <l-select
        :options="tills.cash"
        option-label="name"
        option-value="id"
        :value="selectedCashTill.id"
        @input="selectTill"
        theme="transparent"
        class="white"
        icon="till"
        icon-class="text-white"
      />
    </div>
    <icon
      name="turn-off"
      class="absolute top-0 right-0 m-10 text-white cursor-pointer turn-off"
      v-if="isElectron"
      @click="quit"
    />
    <div
      v-if="selectionType === 'names'"
      class="h-full sm:h-auto pt-20 pb-32 overflow-hidden"
    >
      <div
        class="text-2xl text-center text-white uppercase font-title pb-6 pt-2"
      >
        {{ $t('employees.title') }}
      </div>
      <div
        class="flex flex-col sm:flex-row flex-wrap justify-start sm:justify-center sm:px-24 overflow-scroll scrolling-touch h-full sm:h-auto"
      >
        <div
          v-for="employee in getEmployees"
          class="flex items-center justify-center mb-4 sm:m-5 text-xl font-bold uppercase bg-white shadow-xl employee-box rounded-big font-title text-blue p-4 mx-auto"
          :class="{
            'opacity-50': !shiftStarted && !canStartShift(employee.id)
          }"
          @click="safeSelectEmployee(employee.id)"
          :key="employee.id"
        >
          {{ employee.name }}
        </div>
      </div>
    </div>
    <div v-if="selectionType === 'pin'">
      <div class="text-2xl text-center text-white uppercase font-title">
        {{ $t('employees.pin-title') }}
      </div>
      <section class="flex flex-col justify-center mt-6 keypad">
        <div class="flex justify-center">
          <div
            v-for="(_, pos) in 4"
            :key="pos"
            class="flex items-center justify-center mr-3 bg-white shadow-lg rounded-small pin-square"
          >
            <div v-if="pin[pos]" class="w-2 h-2 rounded-full bg-red"></div>
          </div>
        </div>
        <div
          id="numbers-container"
          class="mt-3 overflow-hidden bg-gray-300 rounded-big"
        >
          <div
            v-for="n in 9"
            :key="n"
            class="flex items-center justify-center text-xl font-bold bg-white text-blue font-title"
            @click="append(n)"
          >
            {{ n }}
          </div>
          <div
            class="flex items-center justify-center text-xl font-bold bg-white text-green font-title"
            @click="deleteNumber()"
          >
            <icon name="delete-arrow" class="p-1" />
          </div>
          <div
            class="flex items-center justify-center text-xl font-bold bg-white text-blue font-title"
            @click="append(0)"
          >
            0
          </div>
          <div
            class="flex items-center justify-center text-xl font-bold bg-white text-green font-title"
            @click="clear()"
          >
            C
          </div>
        </div>
      </section>
    </div>
    <div
      @click="printZReport"
      class="absolute bottom-0 mb-8 sm:mb-12 text-white underline"
    >
      {{ $t('employees.print-last-z') }}
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LSelect from '@last/core-ui/components/LSelect'
import TicketPrinter from '@/ticketPrinter.js'
import app from '@/app.js'
import { mapState, mapGetters, mapActions } from 'vuex'
import numberKeysMixin from '@last/core-ui/mixins/numberKeysMixin'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'

export default {
  name: 'EmployeeSelector',
  mixins: [numberKeysMixin],
  data() {
    return {
      showing: 'tables',
      pin: ''
    }
  },
  async mounted() {
    await this.refreshEmployees()
    this.refreshCatalogs()
    this.refreshErrorMessages()
    this.refreshFloorplans()
    await this.refreshBillingStatus()
    await this.refreshConfig()
    if (this.config.organizationConfig.promotions) {
      this.refreshPromotions()
    }
    if (this.config.organizationConfig.deliveryFleet) {
      this.refreshCouriers()
    }
    this.initNumberKeysListener(
      this.append.bind(this),
      this.deleteNumber.bind(this),
      this.clear.bind(this)
    )
  },
  computed: {
    ...mapGetters('auth', [
      'getEmployees',
      'currentEmployee',
      'currentEmployeePrivileges',
      'hasPrivilege'
    ]),
    ...mapGetters('till', [
      'selectedCashTillIsStarted',
      'askForForTillStartAmount'
    ]),
    ...mapGetters('config', ['tills']),
    ...mapState('config', ['config']),
    ...mapState('till', ['shift', 'selectedCashTill', 'shiftsEnabled']),
    ...mapState('tables', ['floorplanIds']),
    selectionType() {
      return this.config.employees.selectionType
    },
    isElectron() {
      return app.isElectron
    },
    shiftStarted() {
      return !(this.shiftsEnabled && !this.selectedCashTillIsStarted)
    }
  },
  methods: {
    ...mapActions('auth', [
      'selectEmployee',
      'refreshEmployees',
      'refreshPrivileges',
      'refreshBillingStatus'
    ]),
    ...mapActions('config', ['refreshConfig']),
    ...mapActions('till', ['setTill']),
    ...mapActions('catalog', ['refreshCatalogs']),
    ...mapActions('promotions', ['refreshPromotions']),
    ...mapActions('couriers', ['refreshCouriers']),
    ...mapActions('status', ['refreshErrorMessages']),
    ...mapActions('tables', ['refreshFloorplans']),
    printZReport() {
      TicketPrinter.printZReport()
    },
    selectTill(value) {
      let selectedCashTill = this.tills.cash.find(till => till.id === value)
      this.setTill(selectedCashTill)
    },
    async selectEmployeeAndContinue(employeeId) {
      await this.selectEmployee(employeeId)
      if (this.askForForTillStartAmount) {
        if (
          this.selectionType === 'pin' ||
          (await this.$confirm('SHIFT_MANAGER'))
        ) {
          this.$router.push({ name: 'startShift' })
        }
      } else {
        if (
          app.isMobile &&
          (this.floorplanIds.length === 0 ||
            (!this.config.lastProductPosEnabled &&
              !this.config.lastProductBookingsEnabled))
        ) {
          this.$router.push({ name: 'tabBrowser' })
        } else {
          this.$router.push({ name: 'pos' })
        }
      }
    },
    canStartShift(employeeId) {
      return this.hasPrivilege(employeeId, 'SHIFT_MANAGER')
    },
    async safeSelectEmployee(employeeId) {
      if (this.shiftStarted || this.canStartShift(employeeId)) {
        this.selectEmployeeAndContinue(employeeId)
        return true
      } else {
        this.$lnotification.create({
          title: this.$i18n.t('employees.not-shift-starter'),
          icon: 'close',
          iconColor: 'red'
        })
        this.clear()
        return false
      }
    },
    async append(number) {
      if (this.pin.length < 4) {
        this.pin += number
      }
      if (this.pin.length === 4) {
        this.getEmployees.every(async employee => {
          if (this.pin === employee.accessPin) {
            return this.safeSelectEmployee(employee.id)
          }
        })
      }
    },
    deleteNumber() {
      this.pin = this.pin.slice(0, -1)
    },
    clear() {
      this.pin = ''
    },
    async quit() {
      if (CashMachine.isEnabled()) {
        await CashMachine.close()
      }
      app.quit()
    }
  },
  components: {
    Icon,
    LSelect
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

.bg-image {
  background-image: url('../assets/background-employees.jpg');
}

.employee-box {
  width: 10.688rem;
  height: 6.812rem;
}

@media screen and (max-width: 640px) {
  .employee-box {
    width: 90%;
    min-height: 2rem;
    height: auto;
  }
}

.pin-square {
  width: 3.5rem;
  height: 3.5rem;
}

#numbers-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  flex-wrap: wrap-reverse;
  justify-content: center;
  grid-row-gap: 0.062rem;
  grid-column-gap: 0.062rem;
  width: 16.25rem;
  height: 16.25rem;
}

.number-button {
  @include card;
  width: 4.375rem;
  line-height: 4.375rem;
  text-align: center;
  margin: 0.25rem;
  flex-grow: 1;
  &.selected {
    background: $orange;
  }
  &.double-size {
    flex-grow: 8;
  }
}

.blue {
  background: $blue;
  color: white;
}

.red {
  background: $red;
  color: white;
}

.pin-preview {
  font-size: 1.5rem;
  display: flex;
  width: 100%;
  padding: 1.25rem 2.5rem;
  & > span {
    flex: 1;
    text-align: center;
  }
}

.white {
  color: white;
}
</style>
