<template>
  <div class="flex flex-col h-full">
    <div
      class="flex flex-row rounded-big overflow-hidden"
      :class="{ 'container-size': !groupedTillsReportIsEmpty }"
    >
      <tills-view-summary
        v-if="!groupedTillsReportIsEmpty"
        :grouped-tills-report="groupedTillsReport"
        @printPreview="$emit('printPreview')"
      />
      <div
        class="bg-white shadow fixed-w overflow-hidden flex flex-col h-full relative"
      >
        <div class="p-10">
          <div v-if="tills.cash && tills.cash.length > 0">
            <div class="mb-2 font-title text-blue text-base uppercase">
              {{ $t('end-shift.tills') }}
            </div>
            <div class="flex flex-row flex-wrap items-center justify-between">
              <div
                v-for="till in tills.cash"
                :key="till.name"
                class="width-49per p-3 border border-gray-200 rounded-lg flex justify-between my-2 shadow"
                @click="startClosingCashTill(till)"
              >
                <div class="flex">
                  <div v-if="!startedShifts.includes(till.id)">
                    <icon name="done" class="text-green" />
                  </div>
                  <div
                    v-else
                    class="rounded-full h-6 w-6 border-gray-400 border-2"
                  />
                  <div class="ml-3 text-gray-400">{{ till.name }}</div>
                </div>
                <div
                  v-if="!startedShifts.includes(till.id) || !config.blindTill"
                  class="justify-end"
                >
                  <icon
                    @click.native.stop="printCashTillReport(till)"
                    class="text-gray-400"
                    name="printer"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="employeesWithTill && employeesWithTill.length > 0">
            <div class="mb-2 mt-5 font-title text-blue text-base uppercase">
              {{ $t('end-shift.employees') }}
            </div>
            <div class="flex flex-row flex-wrap items-center justify-between">
              <div
                v-for="employee in employeesWithTill"
                :key="employee.name"
                class="width-49per p-3 border border-gray-200 rounded-lg flex justify-between my-2 shadow"
                @click="startClosingEmployeeTill(employee)"
              >
                <div class="flex">
                  <div v-if="!startedShifts.includes(employee.tillId)">
                    <icon name="done" class="text-green" />
                  </div>
                  <div
                    v-else
                    class="rounded-full h-6 w-6 border-gray-400 border-2"
                  />
                  <div class="ml-3 text-gray-400">{{ employee.name }}</div>
                </div>
                <div
                  v-if="!startedShifts.includes(employee.tillId)"
                  class="justify-end"
                >
                  <icon
                    @click.native.stop="printEmployeeTillReport(employee)"
                    class="text-gray-400"
                    name="printer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-32" />
        <div
          class="px-3 py-4 mb-10 mt-10 w-10/12 m-auto text-white bg-green border border-green-b rounded-small text-center uppercase absolute bottom-0 inset-x-0"
          :class="{
            'opacity-50': printDisabled,
            'cursor-pointer': !printDisabled
          }"
          @click="leave"
        >
          {{ $t('end-shift.close-restaurant-shift') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Icon from '@last/core-ui/components/Icon.vue'
import TillsViewSummary from './TillsViewSummary'
import sync from '@/sync/service.js'
import api from '@/api.js'

export default {
  name: 'TillsView',
  data() {
    return {
      groupedTillsReport: {}
    }
  },
  async mounted() {
    if (!this.config.blindTill || this.startedShifts.length === 0) {
      await this.loadShiftGroupedTills()
    }
    this.tryToAutoSelectTill()
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('till', ['startedShifts']),
    ...mapGetters('config', ['tills']),
    ...mapGetters('auth', ['getEmployees']),
    groupedTillsReportIsEmpty() {
      return Object.keys(this.groupedTillsReport).length === 0
    },
    printDisabled() {
      return this.startedShifts.length > 0
    },
    employeesWithTill() {
      return this.getEmployees.filter(
        employee => employee.tillEnabled && employee.tillId
      )
    }
  },
  methods: {
    printCashTillReport(till) {
      this.$emit('printTillReport', till)
    },
    printEmployeeTillReport(employee) {
      let till = { id: employee.tillId, name: employee.name }
      this.$emit('printTillReport', till)
    },
    printNonCashTillReport(method) {
      this.$emit('printTillReport', this.tills[method])
    },
    startClosingCashTill(till) {
      if (!this.startedShifts.includes(till.id)) {
        return
      }
      this.$emit('startClosingTill', till)
    },
    startClosingEmployeeTill(employee) {
      let till = { id: employee.tillId, name: employee.name, type: 'cash' }
      if (!this.startedShifts.includes(till.id)) {
        return
      }
      this.$emit('startClosingTill', till)
    },
    startClosingNonCashPaymentMethods(method) {
      if (!this.startedShifts.includes(this.tills[method].id)) {
        return
      }
      this.$emit('startClosingTill', this.tills[method])
    },
    async loadShiftGroupedTills() {
      let response = await api.get('last-shift-grouped-tills')
      this.groupedTillsReport = response.data
    },
    leave() {
      if (!this.printDisabled) {
        this.$emit('leave')
      }
    },
    tryToAutoSelectTill() {
      if (this.startedShifts.length === 1) {
        let startedShiftId = this.startedShifts[0]
        let till = this.tills.cash.find(till => till.id === startedShiftId)
        if (till) this.startClosingCashTill(till)
      }
    }
  },
  components: {
    Icon,
    TillsViewSummary
  },
  watch: {
    async startedShifts(value) {
      if (value.length === 0) {
        sync.observeEnd(async () => {
          await this.loadShiftGroupedTills()
        })
      }
      this.tryToAutoSelectTill()
    }
  }
}
</script>

<style scoped>
.width-49per {
  width: 49%;
}

.fixed-w {
  width: 32.312rem;
}

.container-size {
  width: 56.5rem;
  height: 34.5rem;
}
</style>
