<template>
  <div class="relative delivery-address-picker">
    <div
      v-if="!formAddress && !hiddeNewAddressCta"
      @click.stop="formAddress = {}"
      class="new-address absolute text-red cursor-pointer"
    >
      {{ $t('new-delivery.new-address') }}
    </div>
    <div v-if="formAddress">
      <div class="flex">
        <l-field
          class="mr-4 flex-2 l-field"
          :label="$t('new-delivery.client-address-label')"
          mandatory
        >
          <div class="flex flex-col w-full">
            <gmap-autocomplete
              :options="autocompleteOptions"
              @place_changed="setPlace"
              :value="formAddress.address"
              class="items-center w-full px-4 py-3 mt-3 leading-tight border border-gray-300 outline-none text-blue rounded-small focus-within:border-blue hover:border-gray-400 line-1"
              :class="{ 'border-red': addressMissing }"
            >
            </gmap-autocomplete>
            <div v-if="addressMissing" class="text-xs text-red mt-1">
              *{{ $t('new-delivery.address-missing') }}
            </div>
          </div>
        </l-field>
        <l-field
          class="flex-1 l-field"
          :label="$t('new-delivery.client-postal-code-label')"
          mandatory
        >
          <l-input
            v-model="formAddress.postalCode"
            :placeholder="$t('new-delivery.client-postal-code-placeholder')"
          />
        </l-field>
      </div>
      <l-field
        class="flex-1 l-field"
        :label="$t('new-delivery.client-address-details-label')"
      >
        <l-input
          v-model="formAddress.details"
          :placeholder="$t('new-delivery.client-address-details-placeholder')"
        />
      </l-field>
      <div class="flex justify-end my-4">
        <div
          v-if="internalAddresses.length > 1 && formAddress.id"
          @click="deleteAddress"
          :class="{ disabled: !isComplete }"
          class="cursor-pointer flex items-center px-2 py-2 mr-4 text-sm font-bold text-red uppercase rounded-small done-button"
        >
          {{ $t('ctas.delete') }}
        </div>
        <div
          v-if="internalAddresses.length > 0"
          class="cursor-pointer flex items-center px-6 py-2 mr-4 text-sm font-bold text-blue uppercase border border-blue-600 bg-white rounded-small done-button"
          @click="formAddress = undefined"
        >
          {{ $t('ctas.cancel') }}
        </div>
        <div
          @click="saveFormAddress"
          :class="{ disabled: !isComplete }"
          class="cursor-pointer flex items-center px-6 py-2 mr-4 text-sm font-bold text-white uppercase border border-blue-600 bg-blue rounded-small done-button"
        >
          {{ $t('ctas.apply') }}
        </div>
      </div>
    </div>
    <div
      v-else-if="showAddressSelected"
      class="relative shadow w-full rounded-lg flex justify-between items-center p-5"
      @click="onAddressSelectedClicked"
    >
      <div class="flex-1 flex flex-col">
        <strong class="font-medium text-blue">{{
          selectedAddress.address
        }}</strong>
        <span class="font-thin text-sm text-gray-400">{{
          selectedAddress.details
        }}</span>
      </div>
      <icon
        v-if="internalAddresses.length > 1"
        class="text-red"
        name="arrow-down"
      />
      <div v-else>
        <icon
          @click.native.stop="formAddress = { ...selectedAddress }"
          class="text-gray-300"
          name="pen"
        />
      </div>
    </div>
    <div v-else-if="showList" class="shadow w-full rounded-lg">
      <div
        v-for="address in internalAddresses"
        :key="address.id"
        class="address-item flex justify-between items-center p-5"
        @click="setSelectedAddress(address)"
      >
        <div class="flex-1 flex flex-col">
          <strong class="font-medium text-blue">{{ address.address }}</strong>
          <span class="font-thin text-sm text-gray-400">{{
            address.details
          }}</span>
        </div>
        <icon
          @click.native.stop="formAddress = address"
          class="text-gray-300"
          name="pen"
          original-color
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import uuid4 from 'uuid/v4'
import LInput from '@last/core-ui/components/LInput'
import LField from '@last/core-ui/components/LField'
import Icon from '@last/core-ui/components/Icon'

export default {
  name: 'DeliveryAddressPicker',
  components: {
    LInput,
    LField,
    Icon
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    hiddeNewAddressCta: {
      type: Boolean,
      default: false
    },
    addressMissing: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedAddress: {},
    formAddress: undefined,
    showList: false,
    showAddressSelected: true,
    internalAddresses: [],
    autocompleteOptions: {
      componentRestrictions: {
        country: ['es', 'ad', 'de']
      },
      fields: ['formatted_address', 'address_component', 'geometry']
    },
    customerId: undefined
  }),
  mounted() {
    if (this.value.length === 0) {
      this.formAddress = {}
      return
    }
    this.customerId = this.value[0].customerId
    this.setSelectedAddress(
      this.value.find(address => address.selected) || this.value[0]
    )
    this.internalAddresses = this.value
  },
  computed: {
    isComplete() {
      return this.formAddress.postalCode && this.formAddress.address
    }
  },
  methods: {
    onAddressSelectedClicked() {
      if (this.internalAddresses.length < 2) return
      this.showAddressSelected = false
      this.showList = true
    },
    setSelectedAddress(newSelectedAddress) {
      this.selectedAddress = newSelectedAddress
      this.showAddressSelected = true
      this.showList = false

      this.internalAddresses = this.internalAddresses.filter(
        address => address.id !== newSelectedAddress.id
      )
      this.internalAddresses.unshift(newSelectedAddress)
      this.internalAddresses = this.internalAddresses.map(address => ({
        ...address,
        selected: false
      }))

      Vue.set(this.internalAddresses, 0, {
        ...this.internalAddresses[0],
        selected: true
      })

      this.$emit('input', this.internalAddresses)
      this.$emit('deliveryAddressSelected', newSelectedAddress)
    },
    saveFormAddress() {
      if (!this.isComplete) return
      if (this.formAddress.id) {
        this.internalAddresses = this.internalAddresses.map(address => {
          if (address.id === this.formAddress.id) {
            return this.formAddress
          } else {
            return address
          }
        })
      } else {
        this.formAddress.id = uuid4()
        this.formAddress.customerId = this.customerId
        this.internalAddresses.push(this.formAddress)
      }
      this.setSelectedAddress(this.formAddress)
      this.$emit('update', this.internalAddresses)
      this.formAddress = undefined
    },
    deleteAddress() {
      this.internalAddresses = this.internalAddresses.filter(
        address => address.id !== this.formAddress.id
      )

      if (this.formAddress.id === this.selectedAddress.id)
        this.setSelectedAddress(this.internalAddresses[0])

      this.$emit('update', this.internalAddresses)
      this.formAddress = undefined
    },
    setPlace(place) {
      if (!(place && place.address_components)) return
      let postalCodeAddressComponent = place.address_components.find(
        component => component.types.includes('postal_code')
      )

      const postalCode = postalCodeAddressComponent
        ? postalCodeAddressComponent.long_name
        : undefined

      Vue.set(this.formAddress, 'address', place.formatted_address)
      Vue.set(this.formAddress, 'postalCode', postalCode)
    }
  }
}
</script>

<style lang="scss" scoped>
.address-item {
  @apply border-b border-gray-200;
  &:first-child {
    @apply bg-gray-100 rounded-tr-lg rounded-tl-lg;
  }
  &:last-child {
    @apply rounded-br-lg rounded-bl-lg border-b-0;
  }
}
.new-address {
  right: 26px;
  top: -48px;
}

.flex-2 {
  flex: 2;
}

.disabled {
  opacity: 0.5;
}
</style>
