<template>
  <div>
    <div
      class="flex flex-col items-center w-full h-full bg-gray-200 overflow-y-scroll overflow-hidden"
    >
      <div
        class="flex flex-row rounded-big overflow-hidden container-size mt-20"
        :class="{ 'opacity-50 pointer-events-none': !currentMethod }"
      >
        <div
          class="bg-blue-700 h-full px-10 pt-12 text-lg summary-size relative"
        >
          <div v-if="!seeingMovements">
            <div class="text-white uppercase font-bold mb-5">
              {{ closingTill.name }} {{ $t('end-shift.shift-summary') }}
            </div>
            <close-till-methods
              :open-methods="openMethods"
              :current-step="currentStep"
              :amounts="amounts"
              @step="value => (currentStep = value)"
              @see-movements="value => seeMovements(value)"
            />
          </div>
          <div v-else>
            <close-till-method-movements
              :movements="movements[currentMethod]"
              :current-method="currentMethod"
              :till-start="currentMethodStart"
              @printMovements="printCurrentMethodMovements"
              @closeMovements="seeingMovements = false"
            />
          </div>
          <div
            v-if="!seeingMovements"
            class="text-white text-center text-sm underline cursor-pointer absolute inset-x-0 mb-5 bottom-0"
            @click="printTillReport(closingTill)"
          >
            {{ $t('end-shift.print-till-report') }}
          </div>
        </div>
        <div class="bg-white">
          <cash-amount
            :key="currentMethod"
            :done-label="doneLabel"
            @done="continueAction"
            :use-cash-machine="useCashMachine"
            :show-bills-counter="currentMethod === 'cash'"
            is-card
            :init-total="amounts[currentMethod]"
          />
        </div>
      </div>
    </div>

    <div
      v-if="selectedReport"
      class="absolute top-0 left-0 z-30 flex items-center justify-center w-screen h-screen blur-background"
    >
      <div class="h-full overflow-y-scroll scrolling-touch report-w">
        <div class="pb-6 mt-12 text-2xl text-center text-white uppercase">
          {{ $t('end-shift.title') }} {{ closingTill.name }}
        </div>
        <div
          class="p-10 mb-8 text-xl font-bold text-center uppercase bg-white shadow rounded-big text-blue"
        >
          {{ $t('report.difference-since') }}:
          {{ selectedReport.start | date }}
          <div
            :class="{ 'text-green': diff === 0, 'text-red': diff != 0 }"
            class="text-3xl"
          >
            <span v-if="diff > 0">+ </span>{{ diff | currency }}
          </div>
        </div>
        <till-report-preview
          v-if="tillReport"
          :till-report="tillReport"
          :name="closingTill.name"
        />
        <div class="flex justify-between">
          <div
            class="p-3 px-6 mt-6 mb-6 font-bold text-center text-white uppercase border border-blue-900 shadow-lg bg-blue rounded-small"
            @click="printShiftMovements()"
          >
            {{ $t('report.print-shift-movements') }}
          </div>
          <div
            class="flex-1 p-3 px-6 mt-6 mb-6 ml-4 font-bold text-center text-white uppercase border shadow-lg bg-red border-red-b rounded-small"
            @click="closeReport"
          >
            {{ $t('report.continue') }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <close-till-confirmation
        v-if="showConfirmationPopup"
        @close="showConfirmationPopup = false"
        @end="sendEndShift"
        :amounts-list="amountsList"
      />
    </div>
  </div>
</template>

<script>
import CashAmount from '@/components/CashAmount.vue'
import TicketPrinter from '@/ticketPrinter.js'
import api from '@/api.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import sync from '@/sync/service.js'
import TillReportPreview from '@/components/TillReportPreview.vue'
import CloseTillMethods from '@/components/CloseTillMethods.vue'
import CloseTillMethodMovements from '@/components/CloseTillMethodMovements.vue'
import CloseTillConfirmation from '@/components/CloseTillConfirmation.vue'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import Vue from 'vue'

export default {
  name: 'CloseTill',
  props: {
    tillId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tillClosed: true,
      closingFullDay: null,
      multipleTurns: false,
      tillReport: null,
      xReport: null,
      zReport: null,
      selectedReport: null,
      openMethods: [],
      movements: [],
      currentStep: 0,
      amounts: {},
      seeingMovements: false,
      showConfirmationPopup: false
    }
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapGetters('config', ['tills']),
    ...mapGetters('auth', ['getEmployees']),
    useCashMachine() {
      return CashMachine.methods.includes(this.currentMethod)
    },
    diff() {
      if (this.tillReport) {
        return this.tillReport.endAmount - this.tillReport.expectedEndAmount
      } else if (this.selectedReport.till) {
        return (
          this.selectedReport.till.end -
          this.selectedReport.till.expectedEndAmount
        )
      } else {
        return ''
      }
    },
    closingTill() {
      let till = this.tills['cash'].find(till => till.id === this.tillId)
      if (!till) {
        let employee = this.getEmployeeByTill()
        if (employee) {
          till = { id: this.tillId, name: employee.name }
        }
      }
      return till
    },
    step() {
      let currentStep = this.currentStep + 1
      let totalSteps = this.openMethods.length
      let currentMethod = this.currentMethod
      return `${currentStep}/${totalSteps} (${currentMethod})`
    },
    currentMethod() {
      if (!this.openMethods[this.currentStep]) {
        return null
      }
      return this.openMethods[this.currentStep].method
    },
    currentMethodStart() {
      if (!this.openMethods[this.currentStep]) {
        return null
      }
      return this.openMethods[this.currentStep].start
    },
    doneLabel() {
      if (this.isLastStep && !this.currentMethodIsFilled) {
        return this.$t('end-shift.end')
      } else {
        return this.$t('end-shift.next')
      }
    },
    totalFilledSteps() {
      return Object.keys(this.amounts).length
    },
    currentMethodIsFilled() {
      return (
        this.amounts[this.currentMethod] ||
        this.amounts[this.currentMethod] === 0
      )
    },
    isLastStep() {
      return this.totalFilledSteps === this.openMethods.length - 1
    },
    amountsList() {
      return Object.keys(this.amounts).map(key => {
        return {
          method: key,
          amount: this.amounts[key]
        }
      })
    }
  },
  async mounted() {
    let response = await api.get('/open-methods-with-current-amount', {
      params: {
        tillId: this.tillId
      }
    })
    this.openMethods = response.data
    if (!this.config.blindTill) {
      let movementsResponse = await api.get(
        '/reporting/last-till-shift-movements',
        {
          params: {
            tillId: this.tillId
          }
        }
      )
      this.movements = movementsResponse.data.reduce((res, movement) => {
        res[movement.method] = [...(res[movement.method] || []), movement]
        return res
      }, {})
    }
  },
  methods: {
    ...mapActions('till', ['endShift']),
    printCurrentMethodMovements() {
      let movements = this.movements[this.currentMethod]
      this.printShiftMovements(
        movements ? movements : null,
        this.closingTill.name + ' ' + this.currentMethod
      )
    },
    getEmployeeByTill() {
      return (
        this.getEmployees.find(employee => employee.tillId === this.tillId) ||
        null
      )
    },
    getNextEmptyStep() {
      let nextStep = this.openMethods.findIndex(
        method =>
          !this.amounts[method.method] && this.amounts[method.method] !== 0
      )
      return nextStep
    },
    printShiftMovements(movements, name) {
      TicketPrinter.printShiftMovements(
        this.closingTill.id,
        name ? name : this.closingTill.name,
        movements ? movements : null
      )
    },
    seeMovements(index) {
      this.currentStep = index
      this.seeingMovements = true
    },
    closeReport() {
      this.selectedReport = null
      this.$emit('close')
    },
    async getTillReport(tillId) {
      let response = await api.get('/last-shift', {
        params: {
          tillId
        }
      })
      this.tillReport = response.data
    },
    async continueAction(amount) {
      Vue.set(this.amounts, this.currentMethod, amount)
      this.amounts[this.currentMethod] = amount
      if (this.totalFilledSteps === this.openMethods.length) {
        this.showConfirmationPopup = true
      } else {
        this.currentStep = this.getNextEmptyStep()
      }
    },
    sendEndShift() {
      this.endShift({
        amounts: this.amountsList,
        tillId: this.closingTill.id
      })
      sync.observeEnd(async () => {
        await this.getReports(this.closingTill)
        this.showConfirmationPopup = false
      })
    },
    async getReports(till) {
      await this.getTillReport(till.id)
      this.printTillReport(till, this.tillReport)
      this.selectedReport = this.tillReport
    },
    printTillReport(till, report) {
      TicketPrinter.printTillReport(till, report)
    }
  },
  components: {
    CloseTillMethods,
    CloseTillMethodMovements,
    CashAmount,
    TillReportPreview,
    CloseTillConfirmation
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.dialog-w {
  width: 30.25rem;
}

.report-w {
  width: 30.25rem;
}

.container-size {
  width: 56.5rem;
  height: 34.5rem;
}

.summary-size {
  width: 28.25rem;
}

.separator {
  width: 150%;
  position: relative;
  right: 1.875rem;
  height: 0.062rem;
}

.transparent-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #1d1c48 100%);
}

.position-down {
  bottom: 8%;
}
</style>
