import Vue from 'vue'
import store from '@/store/index.js'
import i18n from '@/i18n.js'
import PaymentScreen from '@/components/PaymentScreen.vue'

function open() {
  const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
  const paymentScreenComponent = vm.extend(PaymentScreen)
  let container = document.createElement('div')
  document.body.appendChild(container)
  return new paymentScreenComponent({
    el: container,
    store,
    i18n
  })
}

export function paymentScreen() {
  let component = open()
  component.$mount()
  return component
}

const Plugin = {
  install(Vue) {
    Vue.component('PaymentScreen', PaymentScreen)
  }
}

export default Plugin
