function calculateUniqueId(
  catalogProductId,
  tabProductId,
  modifiers,
  comments,
  comboId,
  combineCombo,
  comboName
) {
  let modifierIds = (modifiers || [])
    .map(
      modifier =>
        (modifier.catalogModifierId || modifier.name) + ',' + modifier.quantity
    )
    .sort()
  let id =
    (catalogProductId || tabProductId) +
    modifierIds.join(',') +
    comments +
    (combineCombo ? comboName : comboId)

  return id
}

function group(products) {
  let groupedProducts = products.reduce((res, product) => {
    let { catalogProductId, tabProductId, ...productInfo } = product
    let id = calculateUniqueId(
      catalogProductId,
      tabProductId,
      product.modifiers,
      product.comments,
      product.comboId,
      product.combine,
      product.comboName
    )
    let previous = res[id] || { quantity: 0, tabProductIds: [] }
    res[id] = {
      ...productInfo,
      tabProductIds: [...previous.tabProductIds, tabProductId],
      quantity: product.quantity + previous.quantity
    }
    return res
  }, {})
  return Object.values(groupedProducts).map(product => {
    let { tabProductIds, ...productInfo } = product
    return {
      groupedId: tabProductIds.sort().join(','),
      ...productInfo
    }
  })
}

export default {
  group
}
