<template>
  <div class="flex flex-col">
    <div
      class="bg-white flex flex-col max-h overflow-hidden h-full"
      :class="{
        'min-h': showBillsCounter && !isCard,
        'rounded-big shadow fixed-w-big fixed-w-small': !isCard,
        'fixed-w-small': isCard
      }"
    >
      <tabs
        v-if="tabs.length > 1"
        class="flex-shrink-0"
        :tabs="tabs"
        :selected-tab-idx.sync="selectedTabIdx"
      />
      <div
        v-if="selectedTab.tabType == 'total'"
        class="px-12 py-10"
        :class="{ 'pt-24': tabs.length === 1 && isCard }"
      >
        <div class="flex flex-col justify-center">
          <div
            class="border border-gray-400 p-3 text-center text-green text-xl rounded-small"
          >
            {{ total | currency }}
          </div>
          <div
            id="numbers-container"
            class="border-gray-400 border rounded-small overflow-hidden mt-3 bg-gray-400"
          >
            <div
              v-for="n in numbers"
              :key="n"
              class="bg-white text-blue font-bold flex justify-center items-center font-title text-xl"
              @click="append(n)"
            >
              {{ n }}
            </div>
            <div
              class="bg-white text-green font-bold flex justify-center items-center font-title text-xl"
              @click="deleteNumber()"
            >
              <icon name="delete-arrow" class="p-1" />
            </div>
            <div
              class="bg-white text-blue font-bold flex justify-center items-center font-title text-xl"
              @click="append(0)"
            >
              0
            </div>
            <div
              class="bg-white text-green font-bold flex justify-center items-center font-title text-xl"
              @click="clear()"
            >
              C
            </div>
          </div>
          <div
            v-if="lastShiftEndAmount && !useCashMachine"
            class="uppercase text-white p-4 bg-blue border border-b-blue rounded-small mt-4 font-bold text-center"
            @click="total = lastShiftEndAmount"
          >
            {{ $t('cash-amount.copy-last') }}:
            {{ lastShiftEndAmount | currency }}
          </div>
          <div
            v-if="loading"
            class="text-gray-400 mt-6 text-center"
            @click="total = lastShiftEndAmount"
          >
            {{ $t('cash-amount.loading') }}...
          </div>
        </div>
      </div>
      <div
        v-if="selectedTab.tabType == 'billsTotal'"
        class="flex-1 overflow-hidden flex flex-col"
      >
        <div
          class="mt-10 mx-12 mb-4 border border-gray-400 p-3 text-center text-green text-xl rounded-small"
        >
          {{ billsTotal | currency }}
        </div>
        <div class="flex-1 mx-12 mb-12 overflow-y-scroll scrolling-touch">
          <div class="flex flex-col justify-center">
            <div v-for="(bill, index) in bills" :key="bill" class="mt-2 flex">
              <div
                class="flex-1 border mr-2 border-gray-300 px-1 py-2 text-center text-green text-xl rounded-small my-2 flex flex-row items-center justify-center"
              >
                {{ bill | currency }}
              </div>
              <l-input
                class="flex-1 no-margin input-style"
                type="number"
                v-model="billsAmount[index]"
                big
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="printMovements"
        class="m-auto mb-8 text-red underline font-body text-sm"
        @click="$emit('printMovements')"
      >
        {{ $t('cash-amount.print-shift-movements') }}
      </div>
      <div class="flex justify-end flex-shrink-0 mr-12" v-if="isCard">
        <div
          class="w-2/5 p-3 mb-4 shadow-lg text-white bg-red border border-red-b uppercase rounded-small font-bold text-center"
          @click="done()"
        >
          {{ doneLabel }}
        </div>
      </div>
    </div>
    <div class="flex justify-end flex-shrink-0" v-if="!isCard">
      <div
        v-if="showCancel"
        class="flex-1 mt-6 p-3 px-6 shadow-lg text-white bg-blue border border-blue-900 uppercase rounded-small font-bold text-center"
        @click="$emit('cancel')"
      >
        {{ $t('cash-amount.cancel') }}
      </div>
      <div
        :class="{ 'flex-1': showCancel }"
        class="mt-6 p-3 px-6 shadow-lg text-white bg-red border border-red-b uppercase rounded-small font-bold ml-4 text-center"
        @click="done()"
      >
        {{ doneLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import numberKeysMixin from '@last/core-ui/mixins/numberKeysMixin'

export default {
  name: 'CashAmount',
  mixins: [numberKeysMixin],
  props: {
    lastShiftEndAmount: {
      type: Number,
      default: null
    },
    doneLabel: {
      type: String,
      default: 'Done'
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    printMovements: {
      type: Boolean,
      default: false
    },
    showBillsCounter: {
      type: Boolean,
      default: true
    },
    useCashMachine: {
      type: Boolean,
      default: false
    },
    isCard: {
      type: Boolean,
      default: false
    },
    initTotal: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      tabs: [],
      selectedTabIdx: 0,
      numbers: [7, 8, 9, 4, 5, 6, 1, 2, 3],
      total: 0,
      bills: [
        1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000,
        50000
      ],
      billsAmount: Array(15),
      loading: false
    }
  },
  async mounted() {
    this.tabs = [
      {
        name: this.$t('cash-amount.total'),
        tabType: 'total'
      }
    ]
    if (this.showBillsCounter && !this.useCashMachine) {
      this.tabs.push({
        name: this.$t('cash-amount.bills-total'),
        tabType: 'billsTotal'
      })
    }
    if (this.useCashMachine) {
      if (this.loading) return
      this.loading = true
      await CashMachine.refreshTotalAmount
      this.total = this.machineAmount
      this.loading = false
    }
    this.initNumberKeysListener(
      this.append.bind(this),
      this.deleteNumber.bind(this),
      this.clear.bind(this),
      this.done.bind(this)
    )
    if (this.initTotal) {
      this.total = this.initTotal
    }
  },
  methods: {
    async append(number) {
      this.total = Math.min(this.total * 10 + number, 100_000_000)
    },
    deleteNumber() {
      this.total = Math.floor(this.total / 10)
    },
    clear() {
      this.total = 0
    },
    done() {
      if (this.selectedTab.tabType === 'total') {
        this.$emit('done', this.total)
      } else {
        this.$emit('done', this.billsTotal)
      }
    }
  },
  computed: {
    selectedTab() {
      return this.tabs[this.selectedTabIdx] || {}
    },
    billsTotal() {
      let total = 0
      this.bills.forEach((value, index) => {
        total += value * (this.billsAmount[index] || 0)
      })
      return total
    },
    machineAmount() {
      if (!CashMachine.status) return 0
      return CashMachine.status.totalAmount
    }
  },
  watch: {
    machineAmount(amount) {
      this.total = amount
    }
  },
  components: {
    Tabs,
    Icon,
    LInput
  }
}
</script>

<style scoped>
.fixed-w-big {
  width: 30.25rem;
}

.fixed-w-small {
  width: 28.25rem;
}

#numbers-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-content: center;
  grid-row-gap: 0.062rem;
  grid-column-gap: 0.062rem;
  height: 16.25rem;
}

.max-h {
  max-height: 33.125rem;
}

.min-h {
  min-height: 33.125rem;
}

.no-margin {
  margin: 0;
}

::v-deep .input-style > input {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
}
</style>
