<template>
  <div class="w-3/4">
    <div class="flex justify-between items-center mb-3">
      <div class="text-white font-title uppercase">
        <div>
          <div v-if="!deliveries">
            {{ $t('tabs.stuart-not-assigned') }}
          </div>
          <div v-else-if="!hasCourier">
            {{ $t('tabs.courier-not-assigned') }}
          </div>
          <div v-else-if="!shipmentPickedUp">
            <div class="text-2xl font-bold">
              {{ $t('tabs.pickup') }}
            </div>
            <div class="flex items-center">
              <div>
                {{ $t('tabs.estimated-time-of-pickup') }}
              </div>
              <div class="ml-3 font-bold text-xl">
                {{
                  (currentTabDelivery.etaToOrigin ||
                    tab.deliveryOrder.pickupTime) | time
                }}
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-2xl font-bold">
              {{ $t('tabs.delivery') }}
            </div>
            <div
              class="flex items-center"
              v-if="currentTabDelivery.etaToDestination"
            >
              <div>
                {{ $t('tabs.estimated-time-of-delivery') }}
              </div>
              <div class="ml-3 font-bold text-xl">
                {{ currentTabDelivery.etaToDestination | time }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-2 cursor-pointer">
        <icon name="close" class="text-white" @click.native="$emit('close')" />
      </div>
    </div>
    <div class="rounded-big overflow-hidden relative w-full" @click.stop>
      <div class="w-full relative">
        <l-google-tracker-map
          v-if="job"
          class="w-full map-height"
          ref="map"
          :pickups="pickups"
          :deliveries="deliveries"
          :courier="job.courier"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Icon from '@last/core-ui/components/Icon.vue'
import LGoogleTrackerMap from '@last/core-ui/components/LGoogleTrackerMap.vue'
export default {
  name: 'MapModal',
  props: {
    job: {
      type: Object,
      default: null
    },
    tab: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState('auth', ['locationCoordinates']),
    pickups() {
      return [this.locationCoordinates]
    },
    deliveries() {
      return this.job?.deliveries
    },
    hasCourier() {
      return !!this.job?.courier?.latitude
    },
    currentTabDelivery() {
      return this.job?.deliveries[0]
    }
  },
  components: {
    Icon,
    LGoogleTrackerMap
  },
  methods: {
    autoCenter() {
      this.$refs.map.autoCenter()
    }
  }
}
</script>
<style scoped>
.map-height {
  height: 420px;
}
@media screen and (min-height: 800px) {
  .map-height {
    height: 620px;
  }
}
</style>
