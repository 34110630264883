<template>
  <swipe-out class="w-full" :disabled="isDisabled" ref="swipe">
    <template v-slot>
      <div
        class="flex items-center px-8 py-3 border-r-4 border-white checkout-product"
        :class="{
          'bg-gray-200 border-red': isSelected,
          'bg-gray-100 pr-3 disabled': isDisabled,
          selected: isSelected
        }"
        @click="!isDisabled && $emit('selected')"
      >
        <div class="w-full">
          <div
            class="product-name"
            :class="{ 'text-gray-400': isDisabled, 'text-blue': !isDisabled }"
          >
            {{ product.name }}
          </div>
          <div class="text-gray-400 modifiers">
            {{ description }}
          </div>
          <div class="text-gray-400 comments" v-if="product.comments">
            + {{ product.comments }}
          </div>
        </div>
        <div v-if="product.discount2x1">
          <div class="p-0 text-discounted">2x1</div>
        </div>
        <div v-else-if="product.discountType && product.discountType !== '2x1'">
          <div class="p-0 text-gray-400 text-labeled">
            {{ product.fullPrice | currency }}
          </div>
          <div class="p-0 text-discounted">
            {{ product.finalPrice | currency }}
          </div>
        </div>
        <div v-else class="text-gray-400 product-price">
          {{ product.fullPrice | currency }}
        </div>
        <icon
          v-if="product.paid"
          name="done"
          class="flex-shrink-0 p-1 ml-3 text-green"
        />
        <icon
          v-else-if="isDisabled"
          name="pending-payment"
          class="flex-shrink-0 p-1 ml-3 text-yellow"
        />
      </div>
    </template>
    <template slot="right">
      <div
        class="flex items-center w-full h-20 h-full px-6 swiper-slide bg-red"
        @click="deleteProduct"
      >
        <icon name="trash" class="text-white" />
      </div>
    </template>
    <template slot="left">
      <product-discount
        class="flex-1 h-20 flex items-center px-6 bg-green swiper-slide"
        :product="product"
        @close="closeSwipe"
      >
        <icon name="discount" class="text-white" />
      </product-discount>
    </template>
  </swipe-out>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import ProductDiscount from './ProductDiscount'
import productsMixin from '@/components/productsMixin'
import { SwipeOut } from 'vue-swipe-actions'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'

export default {
  name: 'CheckoutProduct',
  mixins: [productsMixin],
  data() {
    return {
      discount: {
        type: null,
        amount: null,
        concept: null
      }
    }
  },
  props: {
    product: Object,
    isSelected: Boolean,
    isDisabled: Boolean
  },
  methods: {
    deleteProduct() {
      this.deleteProductWithConfirmation({ deleteSingleUnit: true })
      this.closeSwipe()
    },
    closeSwipe() {
      this.$refs.swipe.close()
    }
  },
  components: {
    Icon,
    SwipeOut,
    ProductDiscount
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.product-container {
  cursor: pointer;
  display: flex;
  color: $text-strong;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 20px;
  transition: all 0.1s;
  &.selected {
    background: darken(white, 4%);
  }
}

.modifier-name {
  padding: 0px 20px;
}

.comments {
  padding: 0px 20px;
}

.product-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.text-discounted {
  color: #4ac6d3;
  font-size: 16px;
}

.text-labeled {
  font-size: 12px;
  text-decoration: line-through;
}
</style>
