<template>
  <div class="right-0 fixed floating-list top-0 pt-2 z-50 pointer-events-none">
    <notification-list
      :notifications="notifications"
      @deleteNotification="index => deleteNotification(index)"
      @progress-canceled="index => progressCancelled(index)"
    ></notification-list>
  </div>
</template>

<script>
import NotificationList from './NotificationList.vue'
export default {
  name: 'NotificationFloatingList',
  emits: ['action', 'deleteNotification'],
  components: {
    NotificationList
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    deleteNotification(index) {
      this.$emit('deleteNotification', index)
    }
  }
}
</script>

<style scoped>
@media (min-width: 640px) {
  .floating-list {
    padding-right: 0.5rem;
    top: 3.5rem;
  }
}
@media (max-width: 640px) {
  .floating-list {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 100vw;
  }
}
</style>
