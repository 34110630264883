<template>
  <div
    class="mr-10 flex flex-row text-red"
    v-if="filteredClosedUntilTimes.length > 0"
  >
    <div @click="openPopUp = true" class="flex flex-row cursor-pointer">
      <icon name="close-store" />
      <div class="ml-2">{{ timeNearestClosedDeliveryToCurrentTime }}</div>
    </div>
    <portal to="appRoot" v-if="openPopUp">
      <open-delivery-pop-up
        @close="openPopUp = false"
        :current-time="currentTime"
      />
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Icon from '@last/core-ui/components/Icon.vue'

import moment from 'moment'
import OpenDeliveryPopUp from './OpenDeliveryPopUp'

export default {
  name: 'OpenDelivery',
  data() {
    return {
      currentTime: new Date(),
      openPopUp: false,
      interval: null
    }
  },
  mounted() {
    this.currentTime = new Date()
    this.interval = setInterval(() => (this.currentTime = new Date()), 1000)
    if (this.filteredClosedUntilTimes.length < 1) {
      this.$emit('close')
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  computed: {
    ...mapGetters('deliveryCompanies', ['sortedClosedUntil']),
    filteredClosedUntilTimes() {
      return this.sortedClosedUntil.filter(time =>
        moment(time).isAfter(moment(this.currentTime))
      )
    },
    timeNearestClosedDeliveryToCurrentTime() {
      let nearestClosedUntil = moment(this.filteredClosedUntilTimes[0])
      let currentTime = moment(this.currentTime)
      if (nearestClosedUntil.diff(currentTime, 'days') > 365) {
        return this.$t('close-delivery.indefinitely')
      } else {
        return moment(this.filteredClosedUntilTimes[0]).from(
          moment(this.currentTime)
        )
      }
    }
  },
  watch: {
    filteredClosedUntilTimes(value) {
      if (value.length === 0) {
        this.$emit('close')
      }
    }
  },
  components: {
    OpenDeliveryPopUp,
    Icon
  }
}
</script>

<style scoped></style>
