class NodeSocket {
  constructor() {
    var net = window.require('net')
    this.closed = true
    this.socket = new net.Socket()
  }

  open(address, port, callback, reject) {
    this.socket.on('error', reject)
    this.socket.connect(port, address, callback)
    this.closed = false
  }

  write(data, callback, reject) {
    this.socket.on('error', reject)
    this.socket.write(data, 'utf8', callback)
  }

  shutdownWrite(callback, reject) {
    this.socket.on('error', reject)
    this.socket.end('', 'utf8', callback)
    this.closed = true
  }

  onData(callback) {
    this.socket.on('data', callback)
  }
}

class CapacitorSocket {
  constructor() {
    this.socket = new Socket()
    this.closed = true
    let that = this
    this.socket.onError = () => {
      // add global listener to avoid android issues
    }
    this.socket.onClose = () => {
      // add global listener to avoid android issues
      that.closed = true
    }
    this.socket.onData = () => {
      // add global listener to avoid android issues
    }
  }

  open(address, port, callback, reject) {
    this.socket.open(address, port, callback, reject)
    this.closed = false
  }

  write(data, callback, reject) {
    this.socket.write(data, callback, reject)
  }

  shutdownWrite(callback, reject) {
    this.socket.shutdownWrite(callback, reject)
  }

  onData(callback) {
    this.socket.onData = callback
  }
}

class SocketFactory {
  static getSocket() {
    try {
      window.require('net')
      return new NodeSocket()
    } catch (e) {
      return new CapacitorSocket()
    }
  }
}

export default SocketFactory
