var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.promotions.length > 0)?_c('div',{staticClass:"flex flex-col"},[_c('h3',{staticClass:"px-5",attrs:{"slot":""},slot:"default"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2),_c('div',{staticClass:"relative flex flex-wrap px-3 overflow-scroll scrolling-touch"},_vm._l((_vm.promotions),function(promotion){return _c('div',{key:promotion.id,staticClass:"relative flex flex-col justify-between flex-shrink-0 w-48 px-6 py-2 m-2 bg-white border border-gray-300 menu-product rounded-small cursor-pointer",class:{
        'promotion-selected': promotion.selected,
        'opacity-50 pointer-events-none':
          (!promotion.isValid || promotion.disabled) && !promotion.selected
      },on:{"click":function($event){return _vm.$emit('promotionSelected', promotion)}}},[_c('div',{staticClass:"text-blue font-body",class:{
          'promotion-selected': promotion.selected
        }},[_vm._v(" "+_vm._s(promotion.name)+" ")]),_c('div',{staticClass:"text-sm text-gray-400",class:{
          'promotion-selected': promotion.selected
        }},[_vm._v(" "+_vm._s(promotion.description)+" ")]),(promotion.pointsExpense > 0)?_c('div',{staticClass:"text-sm text-right text-green",class:{
          'promotion-selected': promotion.selected
        }},[_vm._v(" "+_vm._s(promotion.pointsExpense)+" "+_vm._s(_vm.$t('product.points'))+" ")]):_vm._e()])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }