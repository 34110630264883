var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-1 overflow-hidden"},[_c('tab-list',{attrs:{"tabs":_vm.tabsInfo,"full-screen":_vm.fullScreen,"selected-tab-id":_vm.selectedTabId,"tabs-type":_vm.tabsType},on:{"update:selectedTabId":function($event){_vm.selectedTabId=$event},"update:selected-tab-id":function($event){_vm.selectedTabId=$event},"fullScreen":function($event){_vm.fullScreen = true},"squareMenu":function($event){_vm.fullScreen = false},"tabCreated":function (tabId) { return (_vm.selectedTabId = tabId); },"closeTab":function (tabId) { return _vm.closeTab(tabId); }}}),(!_vm.fullScreen)?_c('div',{staticClass:"flex-1 h-full px-6 pt-6 bg-gray-100"},[(_vm.selectedTabId)?_c('div',{staticClass:"flex flex-col h-full overflow-hidden"},[_c('div',{staticClass:"flex items-center"},[(_vm.hasAlert(_vm.selectedTab))?_c('div',{staticClass:"px-4 py-1 m-4 text-sm text-white uppercase cursor-pointer bg-yellow rounded-small",on:{"click":_vm.showAlert}},[_vm._v(" "+_vm._s(_vm.$t('tabs.alert'))+" ")]):_c('div',{staticClass:"flex items-center justify-center px-4 pl-2"},[_c('div',{staticClass:"w-6 h-6 rounded-full status",class:{
              'pending-kitchen-orders': _vm.pendingKitchenOrders(_vm.selectedTabId),
              'closed opacity-75': !_vm.selectedTab.open,
              cancelled:
                _vm.selectedTab.deliveryOrder &&
                _vm.selectedTab.deliveryOrder.cancelTime,
              closed: !_vm.selectedTab.open
            }})]),_c('div',{staticClass:"flex items-center flex-1"},[_c('div',{staticClass:"flex text-2xl font-bold uppercase text-blue"},[(_vm.selectedTab.code)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedTab.code)+" ")]):_vm._e(),(
                _vm.selectedTab.code &&
                (_vm.selectedTab.tableName || _vm.selectedTab.name)
              )?_c('div',{staticClass:"px-2"},[_vm._v(" - ")]):_vm._e(),(_vm.selectedTab.tableName)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedTab.tableName)+" ")]):_c('div',[_vm._v(_vm._s(_vm.selectedTab.name))])])]),(_vm.selectedTab.open)?_c('div',{staticClass:"p-2 rounded-full bg-red",on:{"click":function () { return _vm.closeTab(); }}},[_c('icon',{staticClass:"text-white",attrs:{"name":"trash"}})],1):_vm._e()]),_c('div',{staticClass:"mb-3 mx-3 ml-12 text-gray-400 flex flex-row"},[_c('div',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.selectedTab.source)+" -")]),_c('div',{staticClass:"text-gray-400 relative mr-5"},[_vm._v(" "+_vm._s(_vm._f("time")(_vm.selectedTab.creationTime))+" ")]),(_vm.selectedTab.pickupType === 'ownDelivery')?_c('delivery-record-popup',{attrs:{"tab":_vm.selectedTab}}):_vm._e()],1),_c('div',{staticClass:"flex justify-between h-full overflow-hidden"},[_c('div',{staticClass:"flex flex-col w-3/5 h-full"},[_c('div',{staticClass:"flex-1 p-2 overflow-y-scroll scrolling-touch"},[_c('preview-tab',{staticClass:"preview-tab",attrs:{"tab-id":_vm.selectedTabId},on:{"edit":_vm.editTab}})],1),_c('tab-actions',{staticClass:"pb-6",attrs:{"tab-id":_vm.selectedTabId}})],1),_c('div',{staticClass:"w-2/5 h-full overflow-y-scroll scrolling-touch"},[_c('tab-info',{attrs:{"tab":_vm.selectedTab},on:{"mergeTable":function($event){_vm.mergingTab = true}}})],1)]),(_vm.mergingTab)?_c('merge-tabs',{attrs:{"tabs":_vm.tabsToMerge,"tab-id":_vm.selectedTabId},on:{"close":function($event){_vm.mergingTab = false}}}):_vm._e()],1):_vm._e()]):_vm._e(),_c('close-tab',{attrs:{"tab-id":_vm.closeTabId},on:{"cancel":function($event){_vm.closeTabId = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }