<template>
  <div class="">
    <top-bar class="sticky top-0 z-10">
      <template slot="center">
        <div class="flex items-center text-xl uppercase">
          {{ $t('navbar.tabs') }}
        </div>
      </template>
      <template
        slot="right"
        v-if="floorplanIds.length > 0 && this.config.lastProductPosEnabled"
      >
        <icon name="floorplan" @click.native.stop="goToHome" />
      </template>
    </top-bar>
    <div>
      <div class="flex flex-row cursor-pointer mt-2">
        <div
          class="flex w-1/2 justify-center text-center items-center text-gray-400 p-3"
          :class="{
            'text-blue border-b-4 border-blue': !pickupType
          }"
          @click="setPickupType(null)"
        >
          {{ $t('tabs.restaurant') }}
        </div>
        <div
          class="flex w-1/2 justify-center text-center items-center text-gray-400 p-3"
          :class="{
            'text-blue border-b-4 border-blue': pickupType === 'takeAway'
          }"
          @click="setPickupType('takeAway')"
        >
          {{ $t('tabs.take-away') }}
        </div>
      </div>
      <tab-list :tabs="filteredTabs" @update:selectedTabId="handleSelect" />
      <div class="h-20" />
    </div>
    <floating-button
      v-if="
        pickupType === 'takeAway' &&
        this.config.lastProductPosEnabled &&
        hasCatalog
      "
      :primary-label="$t('new-tab.new-tab')"
      @primary="goToNewTab"
    />
  </div>
</template>

<script>
import TopBar from '@/mobile/components/TopBar.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import TabList from '@/mobile/components/TabList.vue'
import FloatingButton from '@last/core-ui/components/FloatingButton.vue'
import { mapState } from 'vuex'

export default {
  name: 'TabBrowser',
  data() {
    return {
      pickupType: null,
      selectedTabId: null
    }
  },
  methods: {
    setPickupType(pickupType) {
      this.pickupType = pickupType
    },
    goToNewTab() {
      this.$router.push({ name: 'newTakeAwayTab' })
    },
    handleSelect(tabId) {
      this.$router.push({
        name: 'cart',
        params: { tabId }
      })
    },
    goToHome() {
      this.$router.push({ name: 'pos' })
    }
  },
  computed: {
    ...mapState('tabs', ['tabs']),
    ...mapState('tables', ['floorplanIds']),
    ...mapState('config', ['config']),
    ...mapState('catalog', ['catalogs']),
    hasCatalog() {
      return Object.keys(this.catalogs).length > 0
    },
    filteredTabs() {
      if (this.pickupType === 'takeAway') {
        return Object.values(this.tabs)
          .filter(t => t.pickupType === 'takeAway')
          .filter(t => t.open)
          .filter(t => !t.schedulingTime)
      }
      return Object.values(this.tabs)
        .filter(t => !t.pickupType)
        .filter(t => t.open)
    }
  },
  watch: {},
  components: {
    TopBar,
    Icon,
    TabList,
    FloatingButton
  }
}
</script>

<style lang="scss" scoped></style>
