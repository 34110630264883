var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded-lg w-11/12 h-24 shadow absolute bottom-0 left-0 right-0 mb-5 px-5 m-auto flex flex-row items-center text-blue text-center uppercase z-50 bg-white"},[_c('div',{staticClass:"border border-blue mx-2 py-1 flex-1 rounded-lg",class:{
      'bg-blue text-white py-1':
        _vm.selectedTabDeliveryStatuses && _vm.selectedTabDeliveryStatuses.KITCHEN,
      'py-4': !_vm.selectedTabDeliveryStatuses.KITCHEN
    }},[_vm._v(" "+_vm._s(_vm.$t('tabs.kitchen'))+" "),(
        _vm.selectedTabDeliveryStatuses && _vm.selectedTabDeliveryStatuses.KITCHEN
      )?_c('div',{staticClass:"flex flex-row justify-center"},[_vm._v(" "+_vm._s(_vm._f("time")(_vm.selectedTabDeliveryStatuses.KITCHEN.creationTime))+" "),(_vm.selectedTabDeliveryStatuses.KITCHEN.progressTime !== undefined)?_c('div',{staticClass:"lowercase ml-1",class:'text-' + _vm.selectedTabDeliveryStatuses.KITCHEN.progressColor},[_vm._v(" ("+_vm._s(_vm.selectedTabDeliveryStatuses.KITCHEN.progressTime)+" min) ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"border border-blue mx-2 flex-1 rounded-lg",class:{
      'bg-blue text-white py-1':
        _vm.selectedTabDeliveryStatuses &&
        _vm.selectedTabDeliveryStatuses.READY_TO_PICKUP,
      'py-4': !_vm.selectedTabDeliveryStatuses.READY_TO_PICKUP
    },on:{"click":function($event){return _vm.$emit('readyToPickup')}}},[_vm._v(" "+_vm._s(_vm.$t('tabs.pickup'))+" "),(
        _vm.selectedTabDeliveryStatuses &&
        _vm.selectedTabDeliveryStatuses.READY_TO_PICKUP
      )?_c('div',{staticClass:"flex flex-row justify-center"},[_vm._v(" "+_vm._s(_vm._f("time")(_vm.selectedTabDeliveryStatuses.READY_TO_PICKUP.creationTime))+" "),(
          _vm.selectedTabDeliveryStatuses.READY_TO_PICKUP.progressTime !==
          undefined
        )?_c('div',{staticClass:"lowercase ml-1",class:'text-' + _vm.selectedTabDeliveryStatuses.READY_TO_PICKUP.progressColor},[_vm._v(" ("+_vm._s(_vm.selectedTabDeliveryStatuses.READY_TO_PICKUP.progressTime)+" min) ")]):_vm._e()]):_vm._e()]),(_vm.showOnDelivery)?_c('div',{staticClass:"border border-blue mx-2 flex-1 rounded-lg",class:{
      'bg-blue text-white py-1':
        _vm.selectedTabDeliveryStatuses &&
        _vm.selectedTabDeliveryStatuses.ON_DELIVERY,
      'py-4': !_vm.selectedTabDeliveryStatuses.ON_DELIVERY
    },on:{"click":_vm.selectCourier}},[_vm._v(" "+_vm._s(_vm.$t('tabs.on-delivery'))+" "),(
        _vm.selectedTabDeliveryStatuses && _vm.selectedTabDeliveryStatuses.ON_DELIVERY
      )?_c('div',{staticClass:"flex flex-row justify-center"},[_vm._v(" "+_vm._s(_vm._f("time")(_vm.selectedTabDeliveryStatuses.ON_DELIVERY.creationTime))+" "),(
          _vm.selectedTabDeliveryStatuses.ON_DELIVERY.progressTime !== undefined
        )?_c('div',{staticClass:"lowercase ml-1",class:'text-' + _vm.selectedTabDeliveryStatuses.ON_DELIVERY.progressColor},[_vm._v(" ("+_vm._s(_vm.selectedTabDeliveryStatuses.ON_DELIVERY.progressTime)+" min) ")]):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"border border-blue mx-2 flex-1 rounded-lg",class:{
      'bg-blue text-white py-1':
        _vm.selectedTabDeliveryStatuses && _vm.selectedTabDeliveryStatuses.DELIVERED,
      'py-4': !_vm.selectedTabDeliveryStatuses.DELIVERED
    },on:{"click":_vm.finishOrder}},[_vm._v(" "+_vm._s(_vm.$t('tabs.delivered'))+" "),(
        _vm.selectedTabDeliveryStatuses && _vm.selectedTabDeliveryStatuses.DELIVERED
      )?_c('div',[_vm._v(" "+_vm._s(_vm._f("time")(_vm.selectedTabDeliveryStatuses.DELIVERED.creationTime))+" ")]):_vm._e()]),_c('div',{staticClass:"vertical-separator h-12 bg-gray-200 m-auto my-2 mx-3"}),_c('div',{staticClass:"bg-error border-error-b py-4 mx-2 flex-1 rounded-lg text-white",on:{"click":function($event){return _vm.$emit('closeTab')}}},[_vm._v(" "+_vm._s(_vm.$t('tabs.cancel-order'))+" ")]),_c('icon',{staticClass:"text-gray-400 ml-3",attrs:{"name":"close"},on:{"click":function($event){return _vm.$emit('close')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }