<template>
  <div class="fixed bottom-0 mb-5 mobile-save-button w-10/12 flex flex-row">
    <slot>
      <div
        v-if="secondary"
        class="sm:hidden bg-blue text-white text-center border-blue uppercase cursor-pointer shadow-lg rounded-lg py-3 text-lg flex-1 mr-3"
        :class="{ 'opacity-50': secondaryDisabled }"
        @click="handleSecondaryClick"
      >
        {{ secondaryLabel }}
      </div>
      <div
        class="sm:hidden bg-accent bg-red text-white text-center border-red uppercase cursor-pointer shadow-lg rounded-lg py-3 text-lg flex-1 flex items-center justify-center"
        :class="{ 'opacity-50': primaryDisabled }"
        @click="handlePrimaryClick"
      >
        {{ primaryLabel }}
        <l-loading-spinner v-show="loading" class="ml-4" />
      </div>
    </slot>
  </div>
</template>

<script>
import LLoadingSpinner from '@last/core-ui/components/LLoadingSpinner.vue'

export default {
  name: 'FloatingButton',
  props: {
    primaryLabel: {
      type: String,
      default: 'Confirm'
    },
    secondaryLabel: {
      type: String,
      default: 'Cancel'
    },
    primaryDisabled: {
      type: Boolean,
      default: false
    },
    secondaryDisabled: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    handleSecondaryClick() {
      if (!this.secondaryDisabled) {
        this.$emit('secondary')
      }
    },
    handlePrimaryClick() {
      if (!this.primaryDisabled) {
        this.$emit('primary')
      }
    }
  },
  components: {
    LLoadingSpinner
  }
}
</script>

<style scoped>
.mobile-save-button {
  left: 50%;
  transform: translateX(-50%);
}
</style>
