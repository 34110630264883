var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('l-modal',{staticClass:"py-20 font-body",attrs:{"title":_vm.$t('tabs.select-courier'),"size":"small"},on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"body"},[_c('div',{staticClass:"overflow-y-scroll scrolling-touch modal-height"},_vm._l((_vm.couriers),function(courier,index){return _c('div',{key:courier.id,staticClass:"border px-3 py-4 border-gray-300 rounded-lg my-3",class:{
          'bg-green border-green-b text-white': _vm.selectedIndex === index,
          'text-blue': _vm.selectedIndex !== index
        },on:{"click":function($event){_vm.selectedIndex = index}}},[_c('div',{staticClass:"flex flex-row justify-between mx-5"},[_c('div',[_vm._v(_vm._s(courier.name))]),(_vm.courierHasOrdersOnDelivery(courier))?_c('div',{class:{
              'text-white': _vm.selectedIndex === index,
              'text-red': _vm.selectedIndex !== index
            }},[_vm._v(" "+_vm._s(_vm.$t('tabs.on-delivery'))+" ")]):_c('div',{class:{
              'text-white': _vm.selectedIndex === index,
              'text-green': _vm.selectedIndex !== index
            }},[_vm._v(" "+_vm._s(_vm.$t('tabs.free'))+" ")])])])}),0),_c('l-modal-ctas',[_c('l-modal-button',{staticClass:"flex-1 mr-4",attrs:{"type":"secondary","label":_vm.$t('tabs.cancel')},nativeOn:{"click":function($event){return _vm.$emit('close')}}}),_c('l-modal-button',{staticClass:"flex-1",attrs:{"type":"main","label":_vm.$t('tabs.assign'),"disabled":_vm.selectedIndex === null},nativeOn:{"click":function($event){return _vm.assignCourier.apply(null, arguments)}}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }