var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center tab border-r-4 h-16 border-white justify-between",class:{
    closed: !_vm.tab.open,
    cancelled: _vm.isCancelled,
    alert: _vm.hasAlert,
    selected: _vm.tab.id === _vm.selectedTabId
  }},[_c('div',{staticClass:"flex items-center justify-center pl-4 pr-2",class:{ 'ml-8': _vm.offset }},[_c('div',{staticClass:"rounded-full status w-3 h-3 relative flex items-center justify-center",class:{
        'billing-started': _vm.tab.billingStartedTime,
        'closed opacity-75': !_vm.tab.open,
        cancelled: _vm.isCancelled,
        alert: _vm.hasAlert,
        selected: _vm.tab.id === _vm.selectedTabId,
        closed: !_vm.tab.open,
        scheduled: !_vm.tab.activationTime
      }}),_c('div',{staticClass:"flex flex-col p-2"},[_c('div',{staticClass:"text-lg tab-name flex items-center"},[(_vm.tab.pendingKitchenOrders || _vm.ordersNotPrinted)?_c('div',{staticClass:"right-0 bg-white rounded-full circle mr-2"},[_c('div',{staticClass:"w-full h-full mr-1 rounded-full bg-yellow"})]):_vm._e(),(_vm.tab.code)?_c('div',{staticClass:"tab-code whitespace-no-wrap"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.tab.code))]),(_vm.tab.tableName)?_c('span',[_vm._v(" / "+_vm._s(_vm.tab.tableName))]):(_vm.tab.name)?_c('span',[_vm._v(" / "+_vm._s(_vm.tab.name))]):_vm._e()]):(_vm.tab.tableName)?_c('div',[_vm._v(_vm._s(_vm.tab.tableName))]):_c('div',[_vm._v(_vm._s(_vm.tab.name))])]),_c('div',{staticClass:"text-sm tab-source flex"},[_vm._v(" "+_vm._s(_vm.tab.source)+" ")])])]),_c('div',{staticClass:"font-bold py-4 pr-4 tab-time relative"},[(_vm.isCancelled)?_c('div',{staticClass:"uppercase"},[_vm._v(" "+_vm._s(_vm.$t('tabs.cancelled'))+" ")]):(_vm.hasAlert)?_c('div',{staticClass:"uppercase"},[_vm._v(" "+_vm._s(_vm.$t('tabs.alert'))+" ")]):(!_vm.tab.activationTime)?_c('div',{staticClass:"text-blue-100 flex flex-col items-end"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("day")(_vm.tab.schedulingTime))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("time")(_vm.tab.schedulingTime))+" ")])]):_c('div',[_vm._v(_vm._s(_vm._f("time")(_vm.tab.activationTime)))])])])}
var staticRenderFns = []

export { render, staticRenderFns }