<template>
  <l-modal class="font-body" size="small" @close="$emit('close')">
    <template slot="body">
      <div class="flex items-center justify-center">
        <icon name="alert" class="text-red w-10 h-10" />
      </div>
      <div v-for="message in messages" :key="message.id">
        <div class="flex items-center justify-center text-center mt-6">
          <div class="text-blue font-title font-bold text-xl uppercase">
            {{ message.title }}
          </div>
        </div>
        <div
          v-if="message.allLocations === false"
          class="flex items-center justify-center text-center mt-6"
        >
          <div class="text-gray-400 uppercase">
            {{ message.affectedIntegrations.join(', ') }}
          </div>
        </div>
        <div class="flex items-center justify-center text-center mt-6">
          <div class="text-gray-400 text-center">
            {{ message.description }}
          </div>
        </div>
      </div>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal'
import Icon from '@last/core-ui/components/Icon'
export default {
  name: 'ErrorMessagesDialog',
  props: {
    messages: {
      type: Array,
      required: true
    }
  },
  emits: ['close'],
  components: {
    LModal,
    Icon
  }
}
</script>

<style scoped></style>
