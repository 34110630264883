<template>
  <div
    class="flex items-center bg-gray-200 rounded-full px-4 py-2"
    :class="{
      'bg-blue bg-secondary text-white': value,
      'text-blue text-secondary': !value,
      'opacity-50': disabled && !value,
      'cursor-pointer': !disabled && !value
    }"
    @click="click"
  >
    <icon
      v-if="icon"
      :name="icon"
      class="pr-2"
      :class="{ 'text-white': value, 'text-blue text-secondary': !value }"
    />
    <div class="text-sm">
      {{ label }}
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'

export default {
  name: 'LPill',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Icon
  },
  methods: {
    click() {
      this.$emit('input', !this.value)
      this.$emit('click')
    }
  }
}
</script>

<style>
.circle-padding {
  padding: 3px;
}
</style>
