import store from '@/store/index.js'
import TicketPrinter from '@/ticketPrinter.js'
import router from '@/router.js'
import App from '@/app.js'
import { notification } from '@last/core-ui/plugins/notification'
import { normalizeTab } from '@/normalizr'
import i18n from '@/i18n.js'
import moment from 'moment'

export function tabOpened(data) {
  store.commit('tabs/openTab', data)
}

export function tabOpenedWithCustomer(data) {
  store.commit('tabs/openTab', data.tab)
}

export function tabOrderingUpdated(data) {
  store.commit('tabs/updateTabOrdering', data)
}

export function tabCustomerUpdated(data) {
  store.commit('tabs/updateTabCustomerId', data)
}

export function tabCustomerInfoUpdated(data) {
  store.commit('tabs/updateTabCustomerInfo', data)
}

export function productAdded(data) {
  store.commit('tabs/addProduct', data)
}

export function productMoved(data) {
  store.commit('tabs/moveProduct', data)
}

export function productTabMoved(data) {
  store.commit('tabs/moveProductTab', data)
}

export function productModifiersUpdated(data) {
  store.commit('tabs/updateProductModifiers', data)
}

export function productQuantityUpdated(data) {
  store.commit('tabs/updateProductQuantity', data)
}

export function productDiscountUpdated(data) {
  store.commit('tabs/updateProductDiscount', data)
}

export function productRemoved(data) {
  store.commit('tabs/removeProduct', data)
}

export function deliveryOrderStatusUpdated(data) {
  store.commit('tabs/updateDeliveryOrderStatus', data)
}

export function deliveryOrderCourierPhoneUpdated(data) {
  store.commit('tabs/updateDeliveryOrderCourierPhoneNumber', data)
}

export function deliveryOrderShipmentUpdated(data) {
  store.commit('tabs/updateDeliveryOrderShipment', data)
}

export function deliveryOrderAddressUpdated(data) {
  store.commit('tabs/updateDeliveryOrderAddress', data)
}

export function preferredPaymentMethodSelected(data) {
  store.commit('tabs/setPreferredPaymentMethod', data)
}

export function seatRemoved(data) {
  store.commit('tabs/removeSeat', data)
}

export function billAdded(data) {
  store.commit('tabs/addBill', data)
}

export function billRemoved(data) {
  store.commit('tabs/removeBill', data)
}

export function paymentAdded(data) {
  store.commit('tabs/addPayment', data)
  let config = store.state.config
  if (!config.device.mode === 'master') return
  if (!config.config.autoprintReceipts) return
  if (data.payment.type === 'dataphone' && data.payment.metadata?.receipt)
    TicketPrinter.printBankReceipt(data.payment)
}

export function paymentDeleted(data) {
  store.commit('tabs/deletePayment', data)
}

export function tabClosed(data) {
  store.commit('tabs/closeTab', data)
}

export function tabReopened(data) {
  store.commit('tabs/reopenTab', data)
}

export function tabClosedWithInfo(data) {
  let tab = store.getters['tabs/getTab'](data.tabId)

  if (data.showPaidNotification) {
    let payments = tab.bills.flatMap(billId =>
      store.getters['tabs/getBillPayments'](billId)
    )
    let icon = 'card'
    let iconColor = 'green'
    if (payments.some(payment => payment.type === 'sunday')) {
      icon = 'sunday-logo'
      iconColor = 'gray'
    }
    App.playSound('payment')
    notification.create({
      title: i18n.t('notifications.table-already-paid', {
        tableName: tab.tableName
      }),
      icon: icon,
      iconColor: iconColor,
      keepInSidePanel: true,
      onAction: () => {
        router.push({
          name: 'checkout',
          params: { tabId: data.tabId }
        })
      }
    })
  }
  store.commit('tabs/closeTab', data)
}

export function kitchenNoteAdded() {
  // NO_OP
}

export function kitchenOrderAdded(data) {
  store.commit('tabs/addKitchenOrder', data)
}

export function kitchenOrderVersionAdded(data) {
  store.commit('tabs/addKitchenOrderVersion', data)
}

export function tablesAssigned(data) {
  store.commit('tabs/assignTables', data)
}

export function courseUpdated(data) {
  store.commit('tabs/updateCourse', data)
}

export function seatAdded(data) {
  store.commit('tabs/addSeat', data)
}

export function kitchenOrderPrinted(data) {
  store.commit('tabs/kitchenOrderPrinted', data)
}

export function billPrinted(data) {
  store.commit('tabs/billPrinted', data)
}

export function deliveriesClosed(data) {
  store.commit('deliveryCompanies/closeDeliveries', data)
}

export function openDeliveries(data) {
  store.commit('deliveryCompanies/openDeliveries', data)
}

export function deliveryCloseFailed(data) {
  notification.create({
    title: i18n.t('close-delivery.failed-close') + ' ' + data.company
  })
  store.commit('deliveryCompanies/openDelivery', data)
}

export function deliveryOpenFailed(data) {
  notification.create({
    title: i18n.t('close-delivery.failed-open') + ' ' + data.company
  })
  store.commit('deliveryCompanies/closeDelivery', data)
}

export function kitchenOrderAddedToPrintQueue(id) {
  if (store.state.config.device.mode === 'master') {
    let order = store.state.tabs.kitchenOrders[id]
    TicketPrinter.printKitchenOrder(order)
  }
}

export function paymentAddedToPrintQueue(id) {
  if (store.state.config.device.mode === 'master') {
    let payment = store.state.tabs.payments[id]
    TicketPrinter.printBankReceipt(payment)
  }
}

export function billAddedToPrintQueue(billId) {
  if (store.state.config.device.mode === 'master') {
    let bill = store.getters['tabs/getBillById'](billId)
    TicketPrinter.printBill(bill)
  }
}

export function pendingBillAddedToPrintQueue(tabId) {
  if (store.state.config.device.mode === 'master') {
    let bill = store.getters['tabs/getPendingBill'](tabId)
    TicketPrinter.printBill(bill, true)
  }
}

export function mergeTabs(data) {
  store.commit('tabs/mergeTabs', data)
}

export function cancelTab(data) {
  store.commit('tabs/cancelTab', data)
  let config = store.state.config
  if (config.device.mode === 'master') {
    App.playSound('cancel')
    let tab = store.getters['tabs/getTab'](data.tabId)
    TicketPrinter.printCancelTabTicket(tab)
  }
}

export function turnOpened(data) {
  store.commit('till/startShift', data)
}

export function newTillShiftCreated(data) {
  store.commit('till/startShift', data)
}

export function turnClosed(data) {
  store.commit('till/endShift', data)
}

export function dayLastTillClosed() {
  // NO_OP
}

export function shiftStarted(data) {
  store.commit('till/startShift', data.tillId)
}

export function shiftEnded(data, local) {
  store.commit('till/endShift', data.tillId)
  let currentCashTill =
    store.state.till.selectedCashTill &&
    data.tillId === store.state.till.selectedCashTill.id
  let currentEmployeeTill =
    store.getters['auth/currentEmployee'] &&
    data.tillId === store.getters['auth/currentEmployee'].tillId
  if (
    (currentCashTill || currentEmployeeTill) &&
    !local &&
    router.currentRoute.name !== 'employees'
  ) {
    router.push({ name: 'employees' })
  }
}

function addMetadataToBills(bills) {
  let tabs = store.state.tabs

  return bills.map(b => {
    if (tabs.billsMetadata[b.id]) {
      return { ...b, metadata: { ...b.metadata, ...tabs.billsMetadata[b.id] } }
    } else {
      return b
    }
  })
}

export function fullOrderAdded(data) {
  let config = store.state.config
  let isMaster = config.device.mode === 'master'
  data.bills = addMetadataToBills(data.bills)
  let tabData = normalizeTab(data)
  store.commit('tabs/refreshTab', tabData)
  if (data.promotion) {
    store.commit('promotions/addPromotionToTab', {
      tabPromotion: data.promotion
    })
  }
  if (isMaster && data.activationTime) {
    App.playSound('notification')
    if (
      config.config.enableKitchenOrders &&
      config.config.autoprintKitchenOrders
    ) {
      data.kitchenOrders.forEach(order => {
        TicketPrinter.printKitchenOrder(order)
      })
    }
    if (config.config.autoprintBills) {
      data.bills.forEach(bill => {
        TicketPrinter.printBill(bill)
      })
    }
  }
  if (tabData.tabs) {
    Object.values(tabData.tabs).forEach(tab => {
      if (tab.tableName) {
        notification.create({
          title: i18n.t('tabs.new-order-in-table', { table: tab.tableName })
        })
      }
    })
  }
}

export function movementAdded() {
  // NO_OP
}

export function shipmentFailed(data) {
  App.playSound('alert')
  store.commit('tabs/setFailedShipment', data)
}

export function shipmentErrorResolved(data) {
  store.commit('tabs/resolveShipmentError', data)
}

export function billRegenerated(data) {
  store.commit('tabs/regenerateBill', data)
}

export function billFinalized(data) {
  store.commit('tabs/finalizeBill', data)
  TicketPrinter.wakeupPrint(data.billId, 'number')
}

export function billMetadataUpdated(data) {
  store.commit('tabs/updateMetadata', data)
  TicketPrinter.wakeupPrint(data.billId, 'meta')
}

export function customerCreated() {
  // NO_OP
}

export function sendInvoiceEmailRequested() {
  // NO_OP
}

export function customerUpdated() {
  // NO_OP
}

export function tabDetailsUpdated(data) {
  store.commit('tabs/updateTabDetails', data)
}

export function billingStarted(data) {
  store.commit('tabs/startBilling', data)
}

export function comboProductsUpdated(data) {
  store.commit('tabs/updateComboProducts', data)
}

export function updateTabActivationTime(data) {
  store.commit('tabs/updateTabActivationTime', data)
}

function reservationNotification(data, title) {
  notification.create({
    title: i18n.t(title),
    subtitle: moment(data.dateTime).calendar(),
    icon: 'calendar',
    subtitleIcon: data.customerComments ? 'chat' : null,
    iconColor: 'green',
    keepInScreen: true,
    onAction: () => {
      if (!App.isMobile) {
        router.push({
          name: 'reservations'
        })
      }
    }
  })
}

export function reservationCreated(data) {
  reservationNotification(data, 'notifications.new-reservation')
  store.commit('reservations/createReservation', data)
}

export function reservationCancelled(data) {
  let reservation = store.state.reservations.reservations[data.reservationId]
  reservationNotification(reservation, 'notifications.cancelled-reservation')
  store.commit('reservations/cancelReservation', data)
}

export function reservationChanged(data) {
  store.commit('reservations/editReservation', data)
}

export function reservationTableChanged(data) {
  store.commit('reservations/changeReservationTable', data)
}

export function tabCodeGenerated(data) {
  store.commit('tabs/addTabCode', data)
}

export function promotionAddedToTab(data) {
  store.commit('promotions/addPromotionToTab', data)
}

export function promotionDeletedFromTab(data) {
  store.commit('promotions/deletePromotionFromTab', data)
}

export function promotionRedeemed(data) {
  store.commit('promotions/redeemPromotion', data)
}

export function pickupTimeUpdated(data) {
  store.commit('tabs/updatePickupTime', data)
}

export function deliveryCancelled(data) {
  store.commit('tabs/cancelDelivery', data)
}

export function terraceSurchargePercentageUpdated(data) {
  store.commit('tabs/updateTerraceSurchargePercentage', data)
}

export function deviceDeleted(data) {
  store.dispatch('auth/deviceLogout', data)
}

export function updateShipmentProvider(data) {
  store.commit('config/updateShipmentProvider', data)
}

export function updateManualShipment(data) {
  store.commit('config/updateManualShipment', data)
}

export function updateBillingStatus(data) {
  store.commit('auth/updateBillingStatus', data)
}
