<template>
  <div class="h-screen flex flex-col overflow-hidden">
    <table-selector
      v-if="selectingTableForReservationId"
      :title="$t('reservations.title')"
      :multiple-select="true"
      :blocked-tables="blockedTables"
      @close="selectingTableForReservationId = null"
      @tablesSelected="changeTables"
    />
    <div v-else class="h-full">
      <top-bar show-back>
        <template slot="center">
          <div class="text-xl flex items-center">
            {{ $t('reservations.title') }}
          </div>
        </template>
      </top-bar>
      <div
        class="overflow-y-scroll scrolling-touch bg-gray-200 w-full h-full flex justify-center"
      >
        <div class="py-20">
          <div class="bg-white rounded-big shadow fixed-w flex flex-col">
            <div class="flex relative">
              <div class="text-blue font-title font-bold px-8 py-6 uppercase">
                {{ $t('reservations.list') }}
              </div>
              <div
                class="absolute top-0 right-0 p-2 my-4 mr-6 bg-red border border-gray-300 rounded-full cursor-pointer"
                @click="editingReservation = {}"
              >
                <icon name="plus" class="w-4 h-4 text-white" />
              </div>
            </div>
            <table class="w-full">
              <thead class="text-gray-300 border-b border-gray-300 font-normal">
                <tr>
                  <th>{{ $t('reservations.table') }}</th>
                  <th>{{ $t('reservations.diners') }}</th>
                  <th>{{ $t('reservations.time') }}</th>
                  <th>{{ $t('reservations.name') }}</th>
                  <th>{{ $t('reservations.phone-number') }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="text-gray-400">
                <tr
                  v-for="reservation in sortedReservations"
                  :key="reservation.id"
                >
                  <td class="h-full align-middle">
                    <div v-if="areTablesSelected(reservation)" class="py-4">
                      {{ tablesNames(reservation.tables) }}
                    </div>
                    <div v-else>
                      <button
                        @click="handleSelectingTable(reservation)"
                        class="cursor-pointer text-red"
                      >
                        <icon name="external" class="text-red" />
                      </button>
                    </div>
                  </td>
                  <td class="center-text">{{ reservation.diners }}</td>
                  <td>{{ reservation.dateTime | date }}</td>
                  <td>{{ reservation.name }} {{ reservation.surname }}</td>
                  <td>{{ reservation.phoneNumber }}</td>
                  <td class="w-16">
                    <div class="flex justify-end">
                      <l-dropdown class="mr-12">
                        <template slot="trigger" slot-scope="{ toggle }">
                          <icon
                            v-if="reservation.customerComments"
                            name="chat"
                            class="text-blue cursor-pointer"
                            @click="() => toggle()"
                          />
                        </template>
                        <div>"{{ reservation.customerComments }}"</div>
                      </l-dropdown>
                      <icon
                        name="pen"
                        class="cursor-pointer mr-3 text-red"
                        @click.native="editingReservation = reservation"
                      />
                      <icon
                        name="close"
                        class="cursor-pointer text-red"
                        @click.native="checkCancelReservation(reservation.id)"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="h-64 w-full" />
        </div>
      </div>
      <reservation-editor
        v-if="editingReservation"
        :reservation="editingReservation"
        @close="editingReservation = null"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LDropdown from '@last/core-ui/components/LDropdown.vue'
import TopBar from '@/components/core/TopBar.vue'
import ReservationEditor from '@/components/ReservationEditor.vue'
import TableSelector from '@/components/tables/TableSelector.vue'
import reservationMixin from '@/components/reservationMixin.js'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Reservations',
  mixins: [reservationMixin],
  data() {
    return {
      editingReservation: null,
      selectingTableForReservationId: null
    }
  },
  methods: {
    ...mapActions('reservations', [
      'cancelReservation',
      'changeReservationTable'
    ]),
    areTablesSelected(reservation) {
      return reservation.tables?.length
    },
    changeTables(tables) {
      this.changeReservationTable({
        reservationId: this.selectingTableForReservationId,
        tables
      })
      this.selectingTableForReservationId = null
    },
    checkCancelReservation(reservationId) {
      this.$ldialog({
        title: this.$t('reservations.delete-reservation'),
        content: this.$t('reservations.delete-reservation-content'),
        icon: 'trash',
        defaultLabels: true,
        onConfirm: async () => {
          this.cancelReservation(reservationId)
        }
      })
    },
    tablesNames(reservedTables) {
      return reservedTables
        .filter(table => !!this.tables[table])
        .map(table => this.tables[table].name)
        .toString()
    },
    handleSelectingTable(reservation) {
      this.selectingTableForReservationId = reservation.id
      this.setReservation(reservation)
    }
  },
  computed: {
    ...mapState('tables', ['tables'])
  },
  components: {
    Icon,
    TopBar,
    ReservationEditor,
    TableSelector,
    LDropdown
  }
}
</script>

<style scoped>
.fixed-w {
  width: 784px;
}

th {
  @apply py-4 px-4;
  font-weight: inherit;
  color: inherit;
}

th:first-of-type {
  @apply pl-8;
}

th:last-of-type {
  @apply pr-8;
}

td {
  @apply py-4 px-4 border-b border-gray-200;
  font-weight: inherit;
  color: inherit;
}

td:first-of-type {
  @apply pl-8 text-blue py-0;
}

td:last-of-type {
  @apply pr-8;
}

.center-text {
  text-align: center;
}
</style>
