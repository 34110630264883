<template>
  <div>
    <div
      v-for="(method, index) in openMethods"
      :key="index"
      class="bg-white rounded-lg h-20 text-base flex flex-row items-center justify-between mb-3"
      :class="{
        'text-white': currentStep === index,
        'text-green': currentStep !== index,
        'bg-green': currentStep === index
      }"
      @click="$emit('step', index)"
    >
      <div class="flex flex-row ml-5 items-center w-1/2">
        <icon :name="methodIcon(method.method)" />
        <div class="text-blue uppercase inline-block ml-2 w-1/2">
          {{ method.method }}
        </div>
      </div>
      <div
        v-if="!config.blindTill"
        class="flex flex-row justify-between w-7/12 text-sm items-center"
      >
        <div :class="{ 'text-gray-400': currentStep !== index }">
          <div
            v-if="amounts[method.method] !== undefined"
            class="flex flex-col"
          >
            <div class="text-xs">{{ $t('end-shift.expected') }}</div>
            <div class="text-xs">{{ $t('end-shift.declared') }}</div>
            <div :class="{ 'text-blue': currentStep !== index }">
              {{ $t('end-shift.difference') }}
            </div>
          </div>
          <div v-else>{{ $t('end-shift.expected') }}</div>
        </div>
        <div class="flex flex-row items-center">
          <div v-if="amounts[method.method] !== undefined" class="mr-2">
            <div
              class="text-xs"
              :class="{ 'text-gray-400': currentStep !== index }"
            >
              {{ method.currentAmount | currency }}
            </div>
            <div
              class="text-xs"
              :class="{ 'text-gray-400': currentStep !== index }"
            >
              {{ amounts[method.method] | currency }}
            </div>
            <div class="text-base">
              {{ (amounts[method.method] - method.currentAmount) | currency }}
            </div>
          </div>
          <div v-else class="mr-2">
            {{ method.currentAmount | currency }}
          </div>
          <icon
            class="mr-3"
            name="eye"
            @click="$emit('see-movements', index)"
          />
        </div>
      </div>
      <div v-else-if="amounts[method.method] !== undefined" class="mr-8">
        {{ amounts[method.method] | currency }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import { mapState } from 'vuex'

export default {
  name: 'CloseTillMethods',
  data() {
    return {}
  },
  props: {
    openMethods: {
      type: Array,
      default: () => {}
    },
    amounts: {
      type: Object,
      default: () => {}
    },
    currentStep: {
      type: Number,
      default: null
    }
  },
  methods: {
    methodIcon(method) {
      return method === 'cash' || method === 'card' ? method : 'payment-ticket'
    }
  },
  computed: {
    ...mapState('config', ['config'])
  },
  components: {
    Icon
  }
}
</script>

<style scoped></style>
