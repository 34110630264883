<template>
  <div class="w-full" v-click-outside="() => (showingDropdown = false)">
    <div
      class="rounded-small leading-tight flex justify-start items-center pr-3 background-color my-2"
      :class="{ 'border-red border-2': wrongValue || !valid }"
    >
      <div
        class="flex justify-center items-center cursor-pointer ml-2"
        @click="showingDropdown = !showingDropdown"
      >
        <icon :name="flag" original-color class="m-2" />
        <icon
          class="text-violet arrow"
          name="arrow-down"
          :class="{ rotated: showingDropdown }"
        />
        <span class="ml-2 text-gray-500"> {{ prefix }}</span>
      </div>
      <input
        v-model="phoneNumber"
        :placeholder="placeholder"
        type="tel"
        class="ml-2 text-color"
      />
    </div>
    <transition>
      <div v-if="showingDropdown" class="relative">
        <country-selector @select="setPrefix" />
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from './Icon.vue'
import CountrySelector from './CountrySelector.vue'
import {
  parsePhoneNumberFromString,
  getCountryCallingCode
} from 'libphonenumber-js'
import axios from 'axios'
import supportedCountries from '@last/core/src/supportedCountries.js'

export default {
  name: 'LPhoneInput',
  data() {
    return {
      showingDropdown: false,
      country: {},
      invalid: true
    }
  },
  props: {
    placeholder: {
      type: String,
      default: null
    },
    wrongValue: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    defaultCountryCode: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.refreshCountry()
  },
  methods: {
    hasPrefix(value) {
      if (!value.startsWith('+')) return false
      return supportedCountries.find(code => {
        try {
          return value.replace('+', '').startsWith(getCountryCallingCode(code))
        } catch (e) {
          return false
        }
      })
    },
    setPrefix(country) {
      this.country = country
      this.showingDropdown = false
      if (this.valid && this.phoneNumber)
        this.$emit('input', this.prefix + this.phoneNumber)
      else this.$emit('input', this.prefix)
    },
    refreshCountry() {
      const currentCode = parsePhoneNumberFromString(this.value || '')
      if (currentCode) {
        this.country = {
          code: currentCode.country,
          prefix: currentCode.countryCallingCode
        }
        return
      }
      if (this.defaultCountryCode) {
        this.country = {
          code: this.defaultCountryCode,
          prefix: getCountryCallingCode(this.defaultCountryCode)
        }
        return
      }
      axios
        .get('https://ip2c.org/s')
        .then(response => {
          if (this.country?.code) return
          const [status, countryCode] = response.data.split(';')
          if (status === '1') {
            this.country = {
              code: countryCode,
              prefix: getCountryCallingCode(countryCode)
            }
          } else {
            this.country = {
              code: 'ES',
              prefix: getCountryCallingCode('ES')
            }
          }
        })
        .catch(() => {
          this.country = {
            code: 'ES',
            prefix: getCountryCallingCode('ES')
          }
        })
    }
  },
  computed: {
    prefix() {
      if (!this.country.prefix) return ''
      return '+' + this.country.prefix
    },
    flag() {
      return this.country.code?.toLowerCase()
    },
    phoneNumber: {
      get() {
        if (this.value) {
          return (
            parsePhoneNumberFromString(this.value)?.nationalNumber ||
            this.value.replace(this.prefix, '')
          )
        } else return this.value
      },
      set(value) {
        const countryCode = this.hasPrefix(value)
        if (countryCode) {
          this.setPrefix({
            code: countryCode,
            prefix: getCountryCallingCode(countryCode)
          })
          return
        }
        this.$emit('input', this.prefix + value)
      }
    },
    valid() {
      if (this.value) {
        return !!parsePhoneNumberFromString(this.value)
      }
      return true
    }
  },
  components: {
    Icon,
    CountrySelector
  }
}
</script>

<style scoped>
input {
  outline: none;
  background-color: transparent;
  font-family: Roboto;
}

.arrow {
  transition: all 0.5s;
}

.rotated {
  transform: rotate(180deg);
}

.text-color {
  color: #545383;
}

.background-color {
  background-color: #f9f9fb;
}
</style>
