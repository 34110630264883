class StarPrint {
  constructor(config) {
    this.config = config
  }

  printImage(imageData) {
    if (!window.starprnt) return
    var imageObj = {
      base64Image: imageData.split(',')[1],
      width: 576,
      cutReceipt: true
    }

    return new Promise((resolve, reject) => {
      window.starprnt.printBase64Image(
        `TCP:${this.config.address}`,
        this.config.type,
        imageObj,
        resolve,
        reject
      )
    })
  }

  openCashDrawer() {
    if (window.starprnt) {
      window.starprnt.openCashDrawer(
        `TCP:${this.config.address}`,
        this.config.type
      )
    }
  }
}

export default StarPrint
