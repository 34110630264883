<template>
  <div class="p-2">
    <div class="p-6 bg-white shadow rounded-big h-full flex-1">
      <div
        class="flex justify-between pb-4 text-base font-title font-bold uppercase text-blue"
      >
        <span class="flex-1">{{ $t('tabs.order-info') }}</span>
        <icon
          v-if="tab.pickupType"
          name="pen"
          class="opacity-50"
          @click.native="editOrderInfo = true"
          original-color
        />
      </div>
      <div v-if="tab.schedulingTime">
        <h2 class="pt-2 text-sm text-gray-400">
          {{ $t('tabs.scheduled-time') }}
        </h2>
        <div class="text-blue">
          {{ tab.schedulingTime | day }} -
          {{ tab.schedulingTime | time }}
        </div>
      </div>
      <div v-if="tab.deliveryOrder">
        <div v-if="tab.deliveryOrder.pickupTime">
          <div>
            <h2 class="pt-2 text-sm text-gray-400">
              {{ $t('tabs.pickup-time') }}
            </h2>
            <div class="text-blue">
              {{ deliveryPickUpTime }}
            </div>
          </div>
        </div>
        <div
          v-if="tab.deliveryOrder || tab.deliveryOrder.shipmentCancelled"
          class="flex flex-row justify-between items-center"
        >
          <div v-if="tab.deliveryOrder.shipment">
            <h2 class="pt-2 text-sm text-gray-400">
              {{ $t('tabs.shipment-provider') }}
            </h2>
            <div class="text-blue">
              {{ tab.deliveryOrder.shipment.provider }}
            </div>
            <div class="text-error" v-if="tab.deliveryOrder.shipmentCancelled">
              {{ $t('tabs.shipment-cancelled') }}
            </div>
          </div>
        </div>
        <div v-if="tab.deliveryOrder.courierName">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.courier-name') }}
          </h2>
          <div class="text-blue">
            {{ tab.deliveryOrder.courierName }}
          </div>
        </div>
        <div v-if="tab.deliveryOrder.courierPhoneNumber">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.courier-phone-number') }}
          </h2>
          <div class="text-blue">
            {{ tab.deliveryOrder.courierPhoneNumber }}
          </div>
        </div>
        <div
          v-if="tab.deliveryOrder.address"
          class="flex flex-row items-center"
        >
          <div>
            <h2 class="pt-2 text-sm text-gray-400">
              {{ $t('tabs.address') }}
            </h2>
            <div class="text-blue">
              {{ tab.deliveryOrder.address }}
            </div>
          </div>
          <div>
            <l-dropdown v-if="shouldShowMapIcon">
              <template slot="trigger" slot-scope="{ toggle }">
                <icon
                  name="dots"
                  small
                  class="text-gray-400"
                  @click.native.stop="toggle"
                />
              </template>
              <div class="option text-blue" @click="showMap = true">
                {{ $t('tabs.show-map') }}
              </div>
              <div class="separator bg-gray-200" />
              <div class="option text-error" @click="showCancelShipment = true">
                {{ $t('tabs.cancel-shipment') }}
              </div>
            </l-dropdown>
          </div>
        </div>
        <div v-if="tab.deliveryOrder.addressDetails">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.address-details') }}
          </h2>
          <div class="text-blue">
            {{ tab.deliveryOrder.addressDetails }}
          </div>
        </div>
      </div>
      <div v-if="tab.customerInfo">
        <div v-if="tab.customerInfo.name">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-name') }}
          </h2>
          <div class="text-blue flex justify-between items-center relative">
            {{ tab.customerInfo.name }}
            <l-tool-tip
              v-if="tab.customerInfo.internalNote"
              :message="tab.customerInfo.internalNote"
              position="left"
              size="small"
            >
              <icon class="cursor-pointer" name="support"
            /></l-tool-tip>
          </div>
        </div>
        <div v-if="tab.customerInfo.phoneNumber">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-phone-number') }}
          </h2>
          <div class="text-blue">
            {{ tab.customerInfo.phoneNumber }}
          </div>
        </div>
        <div v-if="tab.customerInfo.phoneNumberCode">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.phone-number-code') }}
          </h2>
          <div class="text-blue">
            {{ tab.customerInfo.phoneNumberCode }}
          </div>
        </div>
        <div v-if="tab.customerInfo.email">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-email') }}
          </h2>
          <div class="text-blue">
            {{ tab.customerInfo.email }}
          </div>
        </div>
        <div
          v-if="
            tab.deliveryOrder &&
            (tab.deliveryOrder.status === 'KITCHEN' ||
              (tab.deliveryOrder.status === 'READY_TO_PICKUP' &&
                showOnDelivery))
          "
          class="flex-1 p-3 mt-6 text-center uppercase border border-blue text-blue text-md rounded-small"
          @click="goToNextStep"
        >
          {{ $t('tabs.' + format(nextStep(tab.deliveryOrder.status))) }}
        </div>
      </div>
      <div v-if="reservationLinked">
        <div class="flex justify-between items-center">
          <div>
            <h2 class="pt-2 text-sm text-gray-400">
              {{ $t('reservations.source') }}
            </h2>
            <div class="text-blue">
              {{ reservationLinked.source || 'Restaurant' }}
            </div>
          </div>
          <icon
            name="link-break"
            small
            class="text-red cursor-pointer"
            @click="unlinkTabFromReservation()"
          />
        </div>
        <div v-if="reservationLinked.name">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-name') }}
          </h2>
          <div class="text-blue">
            {{ reservationLinked.name }}
          </div>
        </div>
        <div v-if="reservationLinked.phoneNumber">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-phone-number') }}
          </h2>
          <div class="text-blue">
            {{ reservationLinked.phoneNumber }}
          </div>
        </div>
        <div v-if="reservationLinked.email">
          <h2 class="pt-2 text-sm text-gray-400">
            {{ $t('tabs.customer-email') }}
          </h2>
          <div class="text-blue">
            {{ reservationLinked.email }}
          </div>
        </div>
      </div>
      <div
        v-else-if="tab.source === 'Restaurant' && tab.activationTime"
        class="flex-1 p-3 mt-6 text-center uppercase border border-blue text-blue text-md rounded-small"
        @click="$emit('mergeTable')"
      >
        {{ $t('tabs.merge-tab') }}
      </div>
    </div>
    <div
      v-if="tab.source === 'Glovo'"
      class="p-3 mt-4 text-white uppercase rounded-big bg-yellow"
    >
      <div class="text-center">{{ $t('tabs.delivery-support') }}</div>
      <div class="flex items-center justify-center text-xl font-bold">
        <icon name="phone" class="mr-2" />+34 93 003 80 60
      </div>
    </div>
    <portal to="appRoot">
      <l-modal
        v-if="editOrderInfo === true"
        size="large"
        :title="$t('new-tab.new-tab-delivery-edit')"
        @close="editOrderInfo = false"
      >
        <template slot="body">
          <ClientDetails
            hidde-back-button
            is-edit
            :customer="{
              ...tab.customerInfo,
              id: tab.customerId,
              addresses: addressesFromSelectedTab
            }"
            :delivery="{
              ...tab.deliveryOrder,
              details: tab.deliveryOrder
                ? tab.deliveryOrder.addressDetails
                : undefined
            }"
            :new-tab="tab"
            @close="editOrderInfo = false"
          />
        </template>
      </l-modal>
      <shipment-info
        v-if="showMap || showCancelShipment"
        @close="closeShipmentInfo"
        :tab="tab"
        :cancel="showCancelShipment"
      />
    </portal>
    <courier-selector
      v-if="showCourierPopUp"
      @close="showCourierPopUp = false"
      :open-tabs="openTabs"
      @assignCourier="courier => setCourier(courier)"
    />
  </div>
</template>

<script>
import uuid4 from 'uuid/v4'
import moment from 'moment'
import Icon from '@last/core-ui/components/Icon.vue'
import ClientDetails from './ClientDetails'
import LModal from '@last/core-ui/components/LModal'
import LToolTip from '@last/core-ui/components/LToolTip'
import api from '@/api.js'
import ShipmentInfo from '@/components/tabs/ShipmentInfo.vue'
import LDropdown from '@last/core-ui/components/LDropdown.vue'
import CourierSelector from './CourierSelector'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'TabInfo',
  components: {
    Icon,
    ClientDetails,
    LModal,
    LToolTip,
    ShipmentInfo,
    LDropdown,
    CourierSelector
  },
  props: {
    tab: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      editOrderInfo: false,
      showMap: false,
      showCancelShipment: false,
      showCourierPopUp: false
    }
  },
  computed: {
    ...mapGetters('reservations', ['getNearReservations']),
    ...mapState('config', ['config']),
    ...mapState('tabs', ['tabs']),
    reservationLinked() {
      return Object.values(this.getNearReservations).find(
        reservation => reservation.tabId === this.tab.id
      )
    },
    shouldSendSMS() {
      return (
        (!this.tab.pickupType || this.tab.pickupType === 'takeAway') &&
        this.tab.customerInfo &&
        this.tab.customerInfo.phoneNumber &&
        this.config.organizationConfig.takeawaySms
      )
    },
    shouldShowMapIcon() {
      return (
        this.tab.pickupType === 'ownDelivery' &&
        !!this.tab.deliveryOrder?.address
      )
    },
    addressesFromSelectedTab() {
      return [
        {
          id: uuid4(),
          customerId: this.tab.customerId,
          address: this.tab.deliveryOrder && this.tab.deliveryOrder.address,
          details:
            this.tab.deliveryOrder && this.tab.deliveryOrder.addressDetails,
          postalCode:
            this.tab.deliveryOrder && this.tab.deliveryOrder.postalCode
        }
      ]
    },
    openTabs() {
      return Object.values(this.tabs).filter(tab => tab.open)
    },
    showOnDelivery() {
      return (
        this.tab.pickupType === 'ownDelivery' &&
        (!this.config.shipmentProvider || this.config.manualShipment)
      )
    },
    deliveryPickUpTime() {
      if (
        moment().diff(moment(this.tab.deliveryOrder.pickupTime), 'days') > 0
      ) {
        return moment(this.tab.deliveryOrder.pickupTime).format(
          'DD/MM/YYYY HH:mm'
        )
      } else {
        return moment(this.tab.deliveryOrder.pickupTime).format('HH:mm')
      }
    }
  },
  methods: {
    ...mapActions('tabs', ['updateDeliveryOrderStatus']),
    ...mapActions('reservations', ['editReservation']),
    unlinkTabFromReservation() {
      let reservation = {
        ...this.reservationLinked,
        tabId: null
      }
      this.editReservation(reservation)
    },
    closeShipmentInfo() {
      this.showMap = false
      this.showCancelShipment = false
    },
    format(status) {
      return status.replace(/_/g, '-').toLowerCase()
    },
    nextStep(status) {
      if (status === 'KITCHEN') return 'READY_TO_PICKUP'
      else if (
        status === 'READY_TO_PICKUP' &&
        this.tab.pickupType === 'ownDelivery'
      )
        return 'ON_DELIVERY'
      return ''
    },
    setCourier(selectedCourier) {
      this.showCourierPopUp = false
      this.updateDeliveryOrderStatus({
        tabId: this.tab.id,
        newStatus: 'ON_DELIVERY',
        courier: selectedCourier
      })
    },
    async goToNextStep() {
      let nextStatus = this.nextStep(this.tab.deliveryOrder.status)
      if (
        nextStatus === 'ON_DELIVERY' &&
        (!this.config.shipmentProvider || this.config.manualShipment)
      ) {
        this.showCourierPopUp = true
      } else {
        this.updateDeliveryOrderStatus({
          tabId: this.tab.id,
          newStatus: nextStatus
        })
        if (this.shouldSendSMS && nextStatus === 'READY_TO_PICKUP') {
          this.sendSMS()
        }
      }
    },
    async sendSMS() {
      try {
        await api.post(`/tabs/${this.tab.id}/ready-to-pickup`)
        this.$lnotification.create({
          title: this.$t('notifications.sms-sent')
        })
      } catch (e) {
        // Do nothing
      }
    }
  }
}
</script>

<style>
.separator {
  height: 1px;
}
</style>
