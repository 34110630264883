<template>
  <div class="flex justify-between items-center w-full">
    <div class="flex flex-col p-3 pl-8">
      <div class="text-blue text-base">{{ product.name }}</div>
      <div class="text-gray-400 text-sm">{{ description }}</div>
    </div>
    <div class="flex items-center pr-4">
      <div v-if="product.discount2x1">
        <div class="p-0 text-gray-400">2x1</div>
      </div>
      <div v-else-if="product.discountType && product.discountType !== '2x1'">
        <div class="p-0 text-blue text-labeled">
          {{ product.fullPrice | currency }}
        </div>
        <div class="p-0 text-gray-400">
          {{ product.finalPrice | currency }}
        </div>
      </div>
      <div v-else class="text-blue product-price">
        {{ product.fullPrice | currency }}
      </div>
      <l-dropdown :close-on-click-outside="!isProductDiscountOpen">
        <template slot="trigger" slot-scope="{ toggle }">
          <icon
            @click.native.stop="toggle"
            name="dots"
            class="text-gray-400 icon mx-2"
          />
        </template>
        <div v-if="!someProductBilled" class="option" @click="deleteProduct()">
          {{ $t('checkout.delete') }}
        </div>
        <product-discount
          @click.native="isProductDiscountOpen = true"
          class="option"
          :product="product"
          @close="isProductDiscountOpen = false"
          @updated="$emit('products-modified')"
        >
          <div>
            {{ $t('checkout.product-discount') }}
          </div>
        </product-discount>
      </l-dropdown>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon'
import LDropdown from '@last/core-ui/components/LDropdown'
import ProductDiscount from './ProductDiscount'
import productsMixin from '@/components/productsMixin'

export default {
  name: 'DeliveryCheckoutProducts',
  mixins: [productsMixin],
  components: {
    Icon,
    LDropdown,
    ProductDiscount
  },
  props: {
    product: {
      type: Object
    }
  },
  data() {
    return {
      isProductDiscountOpen: false
    }
  },
  methods: {
    deleteProduct() {
      this.deleteProductWithConfirmation({ deleteSingleUnit: true })
      this.$emit('products-modified')
    }
  },
  computed: {
    someProductBilled() {
      return this.product.notBilledQuantity != this.product.quantity
    }
  }
}
</script>
