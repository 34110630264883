import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data() {},
  methods: {
    ...mapActions('tabs', ['updateProductQuantity', 'removeProduct']),
    deleteProductWithoutConfirmation(deleteSingleUnit = false) {
      if (this.product.quantity > 1 && deleteSingleUnit) {
        this.updateProductQuantity({
          productId: this.product.id,
          quantity: this.product.quantity - 1
        })
      } else {
        this.removeProduct(this.product.id)
      }
    },
    deleteProductWithConfirmation({ deleteSingleUnit, product } = {}) {
      this.product = product || this.product
      if (this.isSelected) {
        this.$emit('selected')
      }
      if (
        this.requiredPrivilege &&
        this.askPinForPrivilege(this.requiredPrivilege.privilege)
      ) {
        this.$ldialog({
          title: this.$t('delete-product.title'),
          content: this.requiredPrivilege.message,
          mainLabel: this.$t('delete-product.enter-pin'),
          icon: 'trash',
          defaultLabels: true,
          onConfirm: async () => {
            const hasPermission = await this.$confirm(this.requiredPrivilege.privilege)
            if (hasPermission) {
              this.deleteProductWithoutConfirmation(deleteSingleUnit)
            }
          }
        })
      } else {
        this.deleteProductWithoutConfirmation(deleteSingleUnit)
      }
    }
  },
  computed: {
    ...mapState('tabs', ['tabs']),
    ...mapGetters('auth', ['askPinForPrivilege']),
    ...mapGetters('tabs', ['getSentToKitchenProducts']),
    hasBeenSentToKitchen() {
      return !!this.getSentToKitchenProducts(this.product.tab).find(
        product => product.productId === this.product.id
      )
    },
    requiredPrivilege() {
      if (this.tabs[this.product.tab].billingStartedTime) {
        return {
          privilege: 'BILLED_PRODUCT_REMOVAL',
          message: this.$t('delete-product.billed-message')
        }
      } else if (this.hasBeenSentToKitchen) {
        return {
          privilege: 'KITCHEN_PRODUCT_REMOVAL',
          message: this.$t('delete-product.ordered-message')
        }
      }
      return false
    },
    description() {
      return (this.product.comboProducts || this.product.modifiers || [])
        .map(modifier => {
          let quantity = modifier.quantity > 1 ? ` x ${modifier.quantity}` : ''
          return modifier.name + quantity
        })
        .join(', ')
    }
  }
}
