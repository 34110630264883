import moment from 'moment'

function isEnabledOn(product, timezonedMoment) {
  if (!product.hasSchedule) {
    return true
  }
  let tabDayName = timezonedMoment.locale('en').format('dddd').toLowerCase()
  let productSchedules = (product.schedules || []).filter(s =>
    s.days.includes(tabDayName)
  )
  let isEnabled = false
  productSchedules.forEach(s => {
    let beforeTime = moment(s.hourFrom, 'HH:mm'),
      afterTime = moment(s.hourTo, 'HH:mm')
    if (
      timezonedMoment.hour() * 60 + timezonedMoment.minutes() >=
        beforeTime.hour() * 60 + beforeTime.minutes() &&
      timezonedMoment.hour() * 60 + timezonedMoment.minutes() <=
        afterTime.hour() * 60 + afterTime.minutes()
    ) {
      isEnabled = true
    }
  })
  return isEnabled
}

export default {
  isEnabledOn
}
