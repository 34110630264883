<template>
  <div class="l-tabs bg-gray-100 overflow-x-scroll scrolling-touch">
    <div
      v-for="(item, index) in tabs"
      :key="item.name"
      @click="select(index)"
      :class="{ 'limit-width': limitWidth }"
      class="l-tabs__item uppercase text-blue py-4 px-6 cursor-pointer text-center relative flex-grow"
    >
      {{ item.name }}
      <div
        class="red-line bg-red"
        :class="selectedTabIdx === index ? 'opacity-100' : 'opacity-0'"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: Array,
    selectedTabIdx: Number,
    limitWidth: Boolean
  },
  methods: {
    select(index) {
      this.$emit('update:selectedTabIdx', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.red-line {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 2px;
}
.l-tabs {
  display: flex;
  &__item {
    white-space: nowrap;
    flex-shrink: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.limit-width {
  max-width: 200px;
}

.l-tabs::-webkit-scrollbar {
  display: none;
}
</style>
