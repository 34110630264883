<template>
  <div class="w-screen h-screen">
    <top-bar class="sticky top-0 z-10">
      <template slot="center">
        <div class="flex items-center text-lg uppercase">
          {{ $t('new-tab.new-tab-take-away') }}
        </div>
      </template>
      <template slot="right">
        <icon name="close" @click.native="goBack" />
      </template>
    </top-bar>
    <div class="p-4">
      <l-field
        class="flex-1 text-blue"
        :label="$t('new-delivery.tab-name-label')"
        mandatory
      >
        <l-input
          v-model="newTab.name"
          :placeholder="$t('new-delivery.tab-name-placeholder')"
        />
      </l-field>
      <l-field
        class="flex-1 whitespace-no-wrap"
        :label="$t('new-delivery.client-number-label')"
      >
        <l-select v-model="newTab.seats" :options="numberOfPeople" />
      </l-field>
      <l-field :label="$t('new-delivery.select-virtual-brand')" mandatory>
        <l-select
          v-model="newTab.virtualBrandId"
          :options="enabledBrands"
          option-label="name"
          option-value="id"
        />
      </l-field>
    </div>
    <floating-button
      :primary-label="$t('new-delivery.cta-continue')"
      @primary="createTakeAwayTab"
      :primary-disabled="!isComplete"
    />
  </div>
</template>

<script>
import TopBar from '@/mobile/components/TopBar.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LField from '@last/core-ui/components/LField'
import LInput from '@last/core-ui/components/LInput'
import LSelect from '@last/core-ui/components/LSelect'
import FloatingButton from '@last/core-ui/components/FloatingButton.vue'
import newTabMixin from '@/components/ordering/newTabMixin.js'

export default {
  name: 'NewTakeAwayTab',
  data() {
    return {
      newTab: {
        name: null,
        pickupType: 'takeAway',
        virtualBrandId: null,
        source: 'Restaurant'
      }
    }
  },
  mixins: [newTabMixin],
  mounted() {
    if (!this.newTab.virtualBrandId) {
      this.newTab.virtualBrandId = this.enabledBrands[0]?.id
    }
  },
  computed: {
    isComplete() {
      return this.newTab.virtualBrandId && this.newTab?.name
    }
  },
  methods: {
    onTabSelected(tabId) {
      this.$emit('update:selectedTabId', tabId)
    },
    goBack() {
      this.$router.push({ name: 'tabBrowser' })
    }
  },
  components: {
    TopBar,
    Icon,
    LField,
    LInput,
    LSelect,
    FloatingButton
  }
}
</script>

<style lang="scss" scoped></style>
