<template>
  <div
    class="z-10 flex flex-col shadow-md"
    :class="{ 'w-list': !fullScreen, 'w-full': fullScreen }"
  >
    <div class="flex flex-row min-width-300 p-4 justify-between items-center">
      <div class="flex flex-row items-center">
        <icon
          :class="{ 'text-blue': !fullScreen }"
          class="flex-shrink-0 text-gray-300"
          name="square-menu"
          @click.native="$emit('squareMenu')"
        />
        <icon
          class="flex-shrink-0 ml-1 text-gray-300"
          :class="{ 'text-blue': fullScreen }"
          name="line-menu"
          @click.native="$emit('fullScreen')"
        />
        <div
          class="flex items-center justify-between pl-4 whitespace-no-wrap text-blue"
        >
          {{ $t('tabs.filter-closed') }}
          <l-switch class="ml-2" v-model="filterClosed" />
        </div>
      </div>
      <div
        v-if="fullScreen && config.manualShipment"
        @click="choosingForShipment = !choosingForShipment"
        class="px-10 py-2 border-blue border rounded-lg uppercase cursor-pointer"
        :class="{
          'bg-blue text-white': choosingForShipment,
          'text-blue': !choosingForShipment
        }"
      >
        {{
          choosingForShipment ? $t('tabs.cancel') : $t('tabs.request-shipments')
        }}
      </div>
    </div>
    <div class="flex flex-row px-4" v-if="fullScreen && choosingForShipment">
      <l-checkbox
        :value="
          filteredNowTabsPickableForShipment.length ===
          pickedTabsForShipment.length
        "
        :half="selectAllIsHalfed"
        @input="value => toggleSelectAll(value)"
      />
      <div class="text-blue ml-3">
        {{ $t('tabs.select-all') }}
      </div>
    </div>
    <div class="flex flex-row items-center p-4" v-if="fullScreen">
      <div
        v-for="header in headers"
        :key="header"
        class="flex-1 text-sm text-gray-400 font-body"
        :class="{
          'w-1/4 xl:w-1/5 flex-none': header === $t('tabs.address'),
          'w-1/4 flex-none xl:w-1/6': header === $t('tabs.tab'),
          'w-1/4 flex-none': header === $t('tabs.time')
        }"
      >
        {{ header }}
      </div>
    </div>
    <div class="flex-1 overflow-y-scroll scrolling-touch">
      <tab-rows
        :tabs="tabs"
        :picked-tabs-for-shipment="pickedTabsForShipment"
        :choosing-for-shipment="choosingForShipment"
        :full-screen="fullScreen"
        :selected-tab-id="selectedTabId"
        :row-size="rowSize"
        :current-time="currentTime"
        :filter-closed="filterClosed"
        @selectTab="selectTab"
        @squareMenu="$emit('squareMenu')"
        @shipmentPickToggled="shipmentPickToggled"
      />
      <div
        v-if="fullScreen && choosingForShipment"
        class="fixed bottom-0 right-0 px-20 py-4 bg-red shadow-md text-white m-8 rounded-lg border-red-b uppercase font-bold cursor-pointer"
        :class="{ 'opacity-50': requestShipmentLoading }"
        @click="requestShipment"
      >
        {{ $t('tabs.request') }}
      </div>
      <delivery-status-popup
        v-if="
          getTab(selectedTabId) &&
          getTab(selectedTabId).deliveryOrder &&
          fullScreen &&
          !choosingForShipment
        "
        :tab="getTab(selectedTabId)"
        @close="$emit('update:selectedTabId', null)"
        @readyToPickup="setToReadyToPickupIfKitchen(getTab(selectedTabId))"
        @selectCourier="showCourierSelector = true"
        @finishOrder="finishOrder(getTab(selectedTabId))"
        @closeTab="closeSelectedTab"
      />
    </div>
    <template v-if="tabsType !== 'delivery' && !fullScreen && hasCatalog">
      <div
        v-if="showCreateTabButton"
        class="p-3 m-5 text-center text-gray-400 uppercase bg-gray-100 border border-gray-200 rounded-small"
        @click="newOnSiteTab"
      >
        + {{ $t('tabs.new') }}
      </div>
      <new-tab
        v-if="configuringTab.onSite && tabsType !== 'takeAway'"
        :is-active="configuringTab.onSite && tabsType !== 'takeAway'"
        @close="configuringTab.onSite = false"
        @tabCreated="tabId => $emit('tabCreated', tabId)"
      />
      <new-take-away-tab
        v-if="configuringTab.onSite && tabsType === 'takeAway'"
        @close="configuringTab.onSite = false"
        @tabCreated="tabId => $emit('tabCreated', tabId)"
      />
    </template>
    <template v-else-if="!fullScreen && hasCatalog">
      <div
        class="p-3 m-5 text-center text-gray-400 uppercase bg-gray-100 border border-gray-200 rounded-small"
        @click="newDeliveryTab"
      >
        + {{ $t('tabs.new-delivery') }}
      </div>
      <new-delivery-tab
        v-if="configuringTab.delivery"
        @close="configuringTab.delivery = false"
        @tabCreated="tabId => $emit('tabCreated', tabId)"
      />
    </template>
    <portal to="appRoot">
      <l-modal
        v-if="showCourierErrorPopup"
        :title="$t('tabs.courier-error-title')"
        size="medium"
        @close="showCourierErrorPopup = false"
        class="py-64"
      >
        <template slot="body">
          <div class="text-xl text-center uppercase text-blue">
            {{ $t('tabs.courier-error-text-1') }}
          </div>
          <div class="mt-2 text-center text-gray-400">
            {{ $t('tabs.courier-error-text-2') }}
          </div>
        </template>
      </l-modal>
      <l-modal
        v-if="showPaymentMethodError"
        :title="$t('tabs.payment-method-error')"
        size="medium"
        @close="showPaymentMethodError = false"
        class="py-64"
      >
        <template slot="body">
          <div class="text-xl text-center uppercase text-blue">
            {{ $t('tabs.payment-method-error-text-1') }}
          </div>
          <div class="mt-2 text-center text-gray-400">
            {{ $t('tabs.payment-method-error-text-2') }}
          </div>
        </template>
      </l-modal>
      <courier-selector
        v-if="showCourierSelector"
        @close="showCourierSelector = false"
        :open-tabs="openTabs"
        @assignCourier="
          courier =>
            setToOnDeliveryIfReadyToPickup(courier, getTab(selectedTabId))
        "
      />
    </portal>
    <portal to="appRoot">
      <div
        v-if="requestShipmentLoading"
        class="fixed top-0 left-0 h-screen w-screen blur-background z-40 flex flex-col items-center justify-center"
      >
        <l-loading-spinner :size="'medium'" />
        <div class="text-white text-xl mt-8 uppercase font-title font-bold">
          {{ $t('tabs.sending-shipment') }}
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import NewTab from './NewTab.vue'
import NewDeliveryTab from './NewDeliveryTab'
import NewTakeAwayTab from './NewTakeAwayTab'
import LSwitch from '@last/core-ui/components/LSwitch.vue'
import Icon from '@last/core-ui/components/Icon'
import DeliveryStatusPopup from './DeliveryStatusPopup'
import CourierSelector from './CourierSelector'
import LModal from '@last/core-ui/components/LModal'
import { EventBus } from '../../eventBus'
import barcodeScanner from '../../barcodeScanner'
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import api from '@/api.js'
import LLoadingSpinner from '@last/core-ui/components/LLoadingSpinner.vue'
import TabRows from './TabRows.vue'
import moment from 'moment'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'

export default {
  name: 'TabList',
  components: {
    LModal,
    NewTab,
    NewDeliveryTab,
    LSwitch,
    NewTakeAwayTab,
    Icon,
    DeliveryStatusPopup,
    CourierSelector,
    LCheckbox,
    LLoadingSpinner,
    TabRows
  },
  props: {
    selectedTabId: {
      type: String,
      default: null
    },
    tabs: {
      type: Array,
      default: () => []
    },
    tabsType: {
      type: String,
      default: 'all'
    },
    fullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      configuringTab: {
        delivery: false,
        onSite: false
      },
      filterClosed: true,
      windowWidth: null,
      currentTime: new Date(),
      recentlyScanned: {},
      recentlyAssigned: {},
      showCourierErrorPopup: false,
      showPaymentMethodError: false,
      showCourierSelector: false,
      choosingForShipment: false,
      requestShipmentLoading: false,
      pickedTabsForShipment: []
    }
  },
  mounted() {
    this.selectFirst()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.currentTime = new Date()
    setInterval(() => (this.currentTime = new Date()), 1000)
    barcodeScanner.initialize()
    EventBus.$on('barcodeScanned', inputString => {
      this.updateStatus(inputString)
    })
  },
  beforeDestroy() {
    barcodeScanner.close()
  },
  computed: {
    ...mapGetters('tabs', ['getTab', 'getBills']),
    ...mapState('config', ['config']),
    ...mapState('couriers', ['couriers']),
    ...mapState('catalog', ['catalogs']),
    selectAllIsHalfed() {
      return (
        this.pickedTabsForShipment.length > 0 &&
        this.pickedTabsForShipment.length <
          this.filteredNowTabsPickableForShipment.length
      )
    },
    codeToTabs() {
      return this.openTabs.reduce((res, tab) => {
        res[tab.code] = tab
        return res
      }, {})
    },
    openTabs() {
      return this.tabs.filter(tab => tab.open)
    },
    filteredNowTabsPickableForShipment() {
      return this.filteredNowTabs.filter(this.rowIsPickableForShipment)
    },
    filteredNowTabs() {
      return this.tabs
        .filter(tab => (tab.open || !this.filterClosed) && tab.activationTime)
        .sort((a, b) => new Date(a.activationTime) - new Date(b.activationTime))
    },
    headers() {
      let result = []
      result.push(this.$t('tabs.tab'))
      result.push(this.$t('tabs.time'))
      if (this.rowSize !== 'small') {
        result.push(this.$t('tabs.ordering-time'))
      }
      result.push(this.$t('tabs.delivery-time'))
      result.push(this.$t('tabs.address'))
      result.push(this.$t('tabs.courier'))
      if (this.rowSize === 'large') {
        result.push(this.$t('tabs.phone-number'))
      }
      return result
    },
    rowSize() {
      let result = 'small'
      if (this.windowWidth > 1224) {
        result = 'medium'
      }
      if (this.windowWidth > 1366) {
        result = 'large'
      }
      return result
    },
    showCreateTabButton() {
      return this.config?.lastProductPosEnabled
    },
    hasCatalog() {
      return Object.keys(this.catalogs).length > 0
    }
  },
  methods: {
    ...mapActions('tabs', [
      'openTab',
      'closeTab',
      'updateDeliveryOrderStatus',
      'addPayment'
    ]),
    rowIsPickableForShipment(tab) {
      return (
        tab.pickupType === 'ownDelivery' &&
        tab.deliveryOrder &&
        !tab.deliveryOrder.shipmentId
      )
    },
    async requestShipment() {
      if (this.pickedTabsForShipment.length === 0) return
      this.requestShipmentLoading = true
      let response
      try {
        response = await api.post('/tabs/requestShipment', {
          tabIds: this.pickedTabsForShipment
        })
        if (response.status === 200) {
          this.pickedTabsForShipment = []
          this.choosingForShipment = false
          this.$lnotification.create({
            title: this.$t('tabs.shipment-request-done'),
            icon: 'done',
            iconColor: 'green'
          })
        }
      } catch (err) {
        this.$lnotification.create({
          title: `${this.$t('tabs.shipment-request-failed')}: ${
            err.response.request.response
          }`,
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.requestShipmentLoading = false
    },
    toggleSelectAll(value) {
      if (value && !this.selectAllIsHalfed) {
        this.pickedTabsForShipment =
          this.filteredNowTabsPickableForShipment.map(tab => tab.id)
      } else if (this.selectAllIsHalfed) {
        this.pickedTabsForShipment = []
      } else {
        this.pickedTabsForShipment = []
      }
    },
    shipmentPickToggled(tabId, value) {
      if (value) {
        this.pickedTabsForShipment.push(tabId)
      } else {
        let index = this.pickedTabsForShipment.indexOf(tabId)
        this.pickedTabsForShipment.splice(index, 1)
      }
    },
    closeSelectedTab() {
      this.$emit('closeTab', this.selectedTabId)
      this.$emit('update:selectedTabId', null)
    },
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    tabCodeIsNotRecentlyAssigned(tabCode) {
      if (!this.recentlyAssigned[tabCode]) {
        return true
      } else if (
        moment().subtract(60, 'second') > moment(this.recentlyAssigned[tabCode])
      ) {
        delete this.recentlyAssigned[tabCode]
        return true
      }
      return false
    },
    courierHasOrdersOnDelivery(courier) {
      return this.openTabs.some(
        tab =>
          tab.deliveryOrder &&
          tab.deliveryOrder.status === 'ON_DELIVERY' &&
          tab.deliveryOrder.courierId === courier.id &&
          this.tabCodeIsNotRecentlyAssigned(tab.code)
      )
    },
    tabIsPaid(tab) {
      let bills = this.getBills(tab.id)
      let pending = bills.reduce((sum, bill) => sum + bill.pending, 0)
      return pending === 0
    },
    async addPaymentIfNeeded(tab) {
      if (!this.tabIsPaid(tab)) {
        let amount = this.getBills(tab.id)
          .flatMap(bill => bill.pending)
          .reduce((sum, billTotal) => sum + billTotal, 0)
        if (
          CashMachine.methods.includes(tab.deliveryOrder.preferredPaymentMethod)
        ) {
          let charged = await CashMachine.charge(amount)
          if (charged === 0) {
            this.$lnotification.create({
              title: this.$t('notifications.payment-failed')
            })
            this.$emit('cancel')
            return
          }
        }
        let paymentData = {
          billId: tab.bills[0],
          amount,
          type: tab.deliveryOrder.preferredPaymentMethod
        }
        this.addPayment({ tabId: tab.id, ...paymentData })
      }
    },
    handleAssignedOrders(courier) {
      this.openTabs
        .filter(tab => {
          return (
            tab.deliveryOrder &&
            tab.deliveryOrder.status === 'ON_DELIVERY' &&
            tab.deliveryOrder.courierId === courier.id &&
            this.tabCodeIsNotRecentlyAssigned(tab.code)
          )
        })
        .forEach(tab => {
          this.finishOrder(tab)
        })
    },
    handleRecentlyScannedForCourier(targetCourier) {
      Object.keys(this.recentlyScanned).forEach(tabCode => {
        if (
          moment().subtract(10, 'second') <
          moment(this.recentlyScanned[tabCode])
        ) {
          this.updateDeliveryOrderStatus({
            tabId: this.codeToTabs[tabCode].id,
            newStatus: 'ON_DELIVERY',
            courier: targetCourier
          })
          this.recentlyAssigned[tabCode] = new Date()
        }
      })
      this.recentlyScanned = {}
    },
    async finishOrder(tab) {
      let tabIsPaid = this.tabIsPaid(tab)
      if (tab.deliveryOrder.preferredPaymentMethod && !tabIsPaid) {
        await this.addPaymentIfNeeded(tab)
      } else if (!tabIsPaid) {
        this.showPaymentMethodError = true
      }
      if (this.tabIsPaid(tab)) {
        this.updateDeliveryOrderStatus({
          tabId: tab.id,
          newStatus: 'DELIVERED'
        })
        this.closeTab({ tabId: tab.id })
      }
      this.$emit('update:selectedTabId', null)
    },
    handleRecentlyScannedForCloseDelivery() {
      Object.keys(this.recentlyScanned).forEach(tabCode => {
        if (
          moment().subtract(10, 'second') <
          moment(this.recentlyScanned[tabCode])
        ) {
          let tabId = this.codeToTabs[tabCode].id
          let tab = this.getTab(tabId)
          this.finishOrder(tab)
        }
      })
      this.recentlyScanned = {}
    },
    selectTab(tabId, stayFullScreen = false) {
      this.$emit('update:selectedTabId', tabId)
      if (this.fullScreen && !stayFullScreen) {
        this.$emit('squareMenu')
      }
    },
    setToReadyToPickupIfKitchen(tab) {
      if (tab.deliveryOrder && tab.deliveryOrder.status === 'KITCHEN') {
        this.updateDeliveryOrderStatus({
          tabId: tab.id,
          newStatus: 'READY_TO_PICKUP'
        })
      }
    },
    setToOnDeliveryIfReadyToPickup(courier, tab) {
      if (
        tab.deliveryOrder &&
        (tab.deliveryOrder.status === 'READY_TO_PICKUP' ||
          tab.deliveryOrder.status === 'ON_DELIVERY')
      ) {
        this.updateDeliveryOrderStatus({
          tabId: tab.id,
          newStatus: 'ON_DELIVERY',
          courier: courier
        })
      }
      this.showCourierSelector = false
    },
    updateStatus(inputString) {
      inputString = inputString.trim()
      let targetCourier = Object.values(this.couriers).find(
        courier => courier.code === inputString
      )
      let targetTab = this.openTabs.find(
        tab => tab.id.slice(-10) === inputString || tab.code === inputString
      )
      if (targetTab) {
        this.recentlyScanned[targetTab.code] = new Date()
        this.setToReadyToPickupIfKitchen(targetTab)
      } else if (targetCourier) {
        if (
          this.courierHasOrdersOnDelivery(targetCourier) &&
          Object.keys(this.recentlyScanned).length > 0
        ) {
          this.showCourierErrorPopup = true
          this.recentlyScanned = {}
          return
        }
        this.handleAssignedOrders(targetCourier)
        this.handleRecentlyScannedForCourier(targetCourier)
      } else if (inputString === 'Z0000') {
        this.handleRecentlyScannedForCloseDelivery()
      }
    },
    selectFirst() {
      if (this.filteredNowTabs.length > 0) {
        this.selectTab(this.filteredNowTabs[0].id, true)
      } else {
        this.selectTab(null)
      }
    },
    async newOnSiteTab() {
      if (this.tabsType === 'takeAway' && !this.config.showTakeAwayTabPopup) {
        let newTab = {
          seats: 0,
          lang: null,
          name: null,
          pickupType: 'takeAway'
        }
        const tabId = await this.openTab({
          tableId: null,
          tab: newTab
        })
        this.$emit('tabCreated', tabId)
        this.$router.push({ name: 'orderManagement', params: { tabId } })
      } else {
        this.configuringTab.onSite = true
      }
    },
    newDeliveryTab() {
      this.configuringTab.delivery = true
    },
    isCancelled(tab) {
      return tab.deliveryOrder && tab.deliveryOrder.cancelTime
    },
    hasAlert(tab) {
      return (
        tab.deliveryOrder &&
        tab.deliveryOrder.errorTime &&
        !tab.deliveryOrder.errorResolved
      )
    }
  },
  watch: {
    fullScreen(value) {
      if (value) {
        this.$emit('update:selectedTabId', null)
      } else if (!this.selectedTabId) {
        this.selectFirst()
      }
    },
    filteredNowTabs(tabs) {
      let tabIds = tabs.map(tab => tab.id)
      if (!tabIds.includes(this.selectedTabId)) {
        this.selectFirst()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.w-list {
  width: 19.375rem;
}

.tab-name-source {
  width: 25rem;
}

.min-width-300 {
  min-width: 18.75rem;
}
</style>
