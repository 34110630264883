<template>
  <section class="bg-gray-200 w-full h-screen flex flex-col">
    <top-bar :show-back="true">
      <template slot="center">
        <div id="tab-name" class="flex">
          {{ tab.tableName }}
        </div>
      </template>
    </top-bar>
    <div class="flex flex-1 p-10">
      <div class="flex flex-col max-w-xs w-full">
        <tab-info class="h-full flex-1 flex flex-col" :tab="tab" />
        <div class="flex flex-row">
          <bill-discount
            small
            class="mt-2 px-2"
            :discount.sync="discount"
            :disabled="!currentBill"
            :original-total="currentBill && currentBill.originalTotal"
            :tab-id="tab.id"
            @remove-discount="removeDiscount"
          />
          <div
            class="w-3/4 text-center border border-blue rounded-small py-4 mt-2 text-blue mx-2 cursor-pointer"
            @click="goToCheckout"
          >
            {{ $t('checkout.more-info') }}
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-1">
        <div class="flex-1 bg-white shadow rounded-big m-2 flex flex-col">
          <div
            class="font-bold text-xl text-blue uppercase px-5 py-4 pt-6 flex justify-between"
          >
            {{ $t('tabs.order-details') }}
          </div>
          <div class="w-full h-px border-b border-gray-200"></div>
          <div class="flex-grow relative overflow-auto min-h-0 bg-gray-100">
            <div class="absolute top-0 left-0 w-full">
              <delivery-checkout-products
                :tab-id="tab.id"
                @products-modified="productsModified = true"
              />
            </div>
          </div>
          <div
            class="border-t border-gray-200 uppercase p-4 flex justify-between items-center"
          >
            <div class="text-blue text-xl font-bold">
              {{ $t('tabs.total') }}
            </div>
            <div class="font-bold text-2xl text-red">
              {{ currentBill.total | currency }}
            </div>
          </div>
        </div>
        <div class="flex w-full overflow-hidden mt-2 px-2">
          <div class="flex-1 w-0 relative">
            <payment-method-selector
              :payment-methods="deliveryPaymentMethods"
              :selected-payment-method="selectedPaymentMethod"
              @select="setPaymentMethod"
            />
            <div
              class="absolute right-transition h-full w-8 right-0 top-0"
            ></div>
          </div>
          <div
            class="w-1/3 btn big text-sm btn border-red-b bg-red text-base shadow"
            @click.stop="onDoneClicked"
            :class="{ 'opacity-50': !isAbleToSendBills }"
          >
            {{ $t('checkout.done') }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import kitchenOrdersMixin from '@/components/ordering/kitchenOrdersMixin.js'
import TicketPrinter from '@/ticketPrinter.js'
import TopBar from '@/components/core/TopBar.vue'
import TabInfo from '@/components/tabs/TabInfo'
import BillDiscount from './BillDiscount.vue'
import DeliveryCheckoutProducts from '@/components/checkout/DeliveryCheckoutProducts'
import PaymentMethodSelector from '@/components/checkout/PaymentMethodSelector.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'DeliveryCheckout',
  mixins: [kitchenOrdersMixin],
  components: {
    TopBar,
    TabInfo,
    BillDiscount,
    DeliveryCheckoutProducts,
    PaymentMethodSelector
  },
  data() {
    return {
      currentDiscount: {},
      previousDiscount: {},
      selectedPaymentMethod: null,
      productsModified: false,
      negativePayments: []
    }
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('tabs', ['tabs']),
    ...mapState('promotions', ['tabPromotions']),
    ...mapGetters('tabs', [
      'getBills',
      'getTotal',
      'getUnsentProducts',
      'getNegativeTabPayments'
    ]),
    ...mapGetters('billing', ['getCurrentBill']),
    ...mapGetters('promotions', ['getTabGlobalPromotion']),
    tab() {
      return {
        ...this.tabs[this.$route.params.tabId],
        total: this.getTotal(this.$route.params.tabId)
      }
    },
    tabGlobalPromotion() {
      return this.getTabGlobalPromotion(this.$route.params.tabId)
    },
    tabId() {
      return this.tab.id
    },
    tabPromotion() {
      return this.tabPromotions[this.tab.id]
        ? this.tabPromotions[this.tab.id].find(promotion => promotion.global)
        : null
    },
    currentBill() {
      if (this.checkoutComplete) return null
      return this.getCurrentBill({
        tabId: this.$route.params.tabId,
        currentDiscount: this.currentDiscount,
        selectedProductIds: this.selectedProductsIds,
        includeAlreadyBilled: true
      })
    },
    discount: {
      get() {
        return this.currentDiscount
      },
      set(discount) {
        this.createTabPromotionForDelivery(discount)
        this.currentDiscount = discount
      }
    },
    isAbleToSendBills() {
      let existingPaymentMethod = this.deliveryPaymentMethods.find(
        method => this.selectedPaymentMethod === method.type
      )
      return (
        (this.tab.pickupType === 'ownDelivery' && existingPaymentMethod) ||
        this.tab.pickupType === 'delivery'
      )
    },
    paymentMethods() {
      return this.config.paymentMethodsConfig.map(method => ({
        ...method,
        type: method.name.toLowerCase()
      }))
    },
    preferredPaymentMethodIsDifferent() {
      return this.getBills(this.tabId).some(
        bill => bill.preferredPaymentMethod !== this.selectedPaymentMethod
      )
    },
    tabHasBills() {
      return this.getBills(this.tabId).length > 0
    },
    discountIsDifferent() {
      return (
        this.previousDiscount.type !== this.currentDiscount.type ||
        this.previousDiscount.amount !== this.currentDiscount.amount
      )
    },
    deliveryPaymentMethods() {
      if (!this.tab) {
        return []
      }
      let sourceAsMethod = {
        name: this.tab.source,
        type: this.tab.source.toLowerCase()
      }
      let methods = this.paymentMethods.filter(
        method => method.availableForDelivery
      )
      if (this.tab.source !== 'OwnDelivery' && this.tab.source !== 'Shop') {
        methods = [sourceAsMethod, ...methods]
      }
      return methods
    },
    pendingKitchenOrders() {
      return this.getUnsentProducts(this.tab.id).length > 0
    }
  },
  mounted() {
    let method =
      this.tab.deliveryOrder.preferredPaymentMethod ||
      this.tab.source.toLowerCase()
    if (method) {
      this.setPaymentMethod(method)
    }
    if (this.tabGlobalPromotion) {
      this.currentDiscount = {
        type: this.tabGlobalPromotion.discountType,
        amount: this.tabGlobalPromotion.discountAmount,
        promotionId: this.tabGlobalPromotion.promotionId,
        concept: this.tabGlobalPromotion.concept,
        freeDelivery: this.tabGlobalPromotion.freeDelivery
      }
      this.previousDiscount = this.currentDiscount
    }
    this.negativePayments = this.getNegativeTabPayments(this.tab.id)
  },
  methods: {
    ...mapActions('tabs', [
      'createKitchenOrders',
      'generatePendingBill',
      'startBilling',
      'removeTabBills',
      'setPreferredPaymentMethod'
    ]),
    ...mapActions('promotions', ['updateTabPromotion', 'deleteTabPromotion']),
    createTabPromotionForDelivery(promotion) {
      this.deleteCurrentTabPromotion()
      this.updateTabPromotion({
        tabId: this.tab.id,
        promotion: {
          discountType: promotion.type,
          discountAmount: promotion.amount,
          id: promotion.promotionId,
          global: promotion.global,
          freeDelivery: promotion.freeDelivery
        }
      })
    },
    deleteCurrentTabPromotion() {
      if (this.tabGlobalPromotion)
        this.deleteTabPromotion({
          tabId: this.tab.id,
          tabPromotion: this.tabGlobalPromotion
        })
    },
    removeDiscount() {
      this.deleteCurrentTabPromotion()
      this.currentDiscount = {}
    },
    onDoneClicked() {
      if (!this.isAbleToSendBills) return
      this.generateBillsAndLeave()
      this.$router.push({
        name: 'pos'
      })
    },
    generateBillsAndLeave() {
      if (
        (!this.tabHasBills ||
          this.pendingKitchenOrders ||
          this.discountIsDifferent ||
          this.productsModified) &&
        this.tabs[this.tab.id].activationTime
      ) {
        this.removeTabBills(this.tab.id)
        this.printBill()
        if (this.config.enableKitchenOrders) {
          this.printKitchenOrder()
        }
      }
    },
    printBill() {
      this.generatePendingBill({
        tabId: this.tab.id,
        bill: this.currentBill,
        payments: this.negativePayments
      })
      let bills = this.getBills(this.tabId)
      bills.forEach(TicketPrinter.printBill)
    },
    printKitchenOrder() {
      this.sendKitchenOrder('all')
    },
    setPaymentMethod(method) {
      this.selectedPaymentMethod = method
      this.setPreferredPaymentMethod({
        tabId: this.tab.id,
        preferredPaymentMethod: method
      })
    },
    goToCheckout() {
      this.generateBillsAndLeave()
      this.$router.push({
        name: 'checkout',
        params: { tabId: this.tabId }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply text-white;
  @apply uppercase;
}

.big {
  @apply p-4;
  @apply rounded-small;
  @apply font-bold;
}

.right-transition {
  background-image: linear-gradient(
    to right,
    rgba(236, 236, 243, 0),
    rgba(236, 236, 243, 1)
  );
}
</style>
