import { render, staticRenderFns } from "./CloseTab.vue?vue&type=template&id=7311a3df&scoped=true&"
import script from "./CloseTab.vue?vue&type=script&lang=js&"
export * from "./CloseTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7311a3df",
  null
  
)

export default component.exports