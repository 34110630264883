<template>
  <div v-click-outside="onClickOutside" class="cursor-pointer">
    <slot name="trigger" :toggle="() => (open = !open)" :open="open"> </slot>
    <div class="relative">
      <transition name="fade">
        <div
          v-if="open"
          @click.stop="open = !open"
          class="rounded-big shadow-md border border-gray-200 py-2 options absolute top-100 mt-4 -mr-3 right-0 bg-white z-10 min-w text-center"
          :style="dropdownStyle"
          :class="{
            'bottom-0 mb-12 top': position === 'top',
            bottom: position === 'bottom'
          }"
        >
          <slot :toggle="() => (open = !open)"> </slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LDropdown',
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    options: {
      type: [Array, Object],
      default: () => []
    },
    optionLabel: {
      type: String,
      default: 'label'
    },
    optionValue: {
      type: String,
      default: 'value'
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    position: {
      type: String,
      default: 'bottom'
    },
    icon: {
      type: String,
      default: null
    },
    small: Boolean,
    closeOnClickOutside: {
      type: Boolean,
      default: true
    },
    dropdownStyle: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      open: false
    }
  },
  methods: {
    select(option) {
      this.$emit('input', option[this.optionValue])
      this.open = false
    },
    onClickOutside() {
      if (!this.closeOnClickOutside) return
      this.open = false
    }
  }
}
</script>

<style>
.arrow {
  transition: all 0.5s;
}

.rotated {
  transform: rotate(180deg);
}

.options:after,
.options:before {
  bottom: 100%;
  right: 0.938rem;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.options:after {
  border-color: rgba(255, 255, 255, 0);
  border-width: 0.5rem;
  right: 1rem;
  background-clip: padding-box;
}

.options:before {
  border-color: rgba(184, 184, 184, 0);
  border-width: 0.562rem;
  background-clip: padding-box;
}

.options.top:after,
.options.top:before {
  top: 100%;
  border-top-color: #e2e8f0;
}

.options.bottom:after,
.options.bottom:before {
  bottom: 100%;
  border-bottom-color: #e2e8f0;
}

.options.top:after {
  border-top-color: #ffffff;
}

.options.top:before {
  border-top-color: #e2e8f0;
}

.options.bottom:after {
  border-bottom-color: #ffffff;
}

.options.bottom:before {
  border-bottom-color: #e2e8f0;
}

.option {
  @apply py-3 cursor-pointer text-sm text-blue w-full;
}

.option:hover {
  @apply bg-gray-200;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.min-w {
  min-width: 12.5rem;
}
</style>
