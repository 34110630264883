import { mapActions } from 'vuex'
import Vue from 'vue'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import catalog from '@last/core/src/catalog'
import moment from 'moment'

let mixin = {
  methods: {
    ...mapActions('tabs', ['addProduct', 'updateComboProducts']),
    preselectSelectedProducts() {
      this.selectedProducts[0] = this.editingCombo.comboProducts.reduce(
        (res, product) => {
          let categoryProduct = this.catalogCombo.categories
            .flatMap(category => category.products)
            .find(
              categoryProduct => categoryProduct.id === product.parentProduct
            )
          res[product.id] = {
            ...product,
            categoryId: categoryProduct?.categoryId,
            modifierGroups: categoryProduct?.modifierGroups
          }
          return res
        },
        {}
      )
    },
    deleteProduct(comboIndex, productId) {
      Vue.delete(this.selectedProducts[comboIndex], productId)
      this.selectedProducts = {
        ...this.selectedProducts
      }
    },
    getCategory(categoryIndex) {
      if (!this.savedCatalogCombo) {
        return null
      }
      return this.savedCatalogCombo.categories[categoryIndex]
    },
    updateProductQuantity(productId, newQuantity, comboIndex, categoryIndex) {
      if (newQuantity === 0) {
        this.deleteProduct(comboIndex, productId)
        return
      }
      let oldProductQuantity =
        this.selectedProducts[comboIndex][productId].quantity
      this.selectedProducts[comboIndex][productId].quantity = newQuantity

      let newTotalProductsQuantity = this.categorySelectedProductsQuantity(
        comboIndex,
        categoryIndex
      )
      if (newTotalProductsQuantity > this.categoryMax(categoryIndex)) {
        this.selectedProducts[comboIndex][productId].quantity =
          oldProductQuantity
      }
    },
    saveCombo() {
      Object.values(this.selectedProducts).forEach(
        (comboSelectedProducts, index) => {
          let combo = {
            parentProduct: this.savedCatalogCombo.id,
            name: this.savedCatalogCombo.name,
            price: this.savedCatalogCombo.price,
            quantity: this.quantity,
            combine: this.savedCombos.length > 1,
            pointsExpense: this.savedCatalogCombo.pointsExpense,
            comboProducts: Object.values(comboSelectedProducts)
              .sort((a, b) => {
                return (
                  this.catalogProductsPosition[a.parentProduct] -
                  this.catalogProductsPosition[b.parentProduct]
                )
              })
              .map(comboProduct => {
                delete comboProduct.categoryId
                delete comboProduct.modifierGroups
                return comboProduct
              }),
            modifiers: [],
            seat: this.seat ?? this.selectedSeat
          }

          let productPricing =
            ProductPriceCalculator.calculateProductPricing(combo)
          if (this.savedEditingCombo && index == 0) {
            this.updateComboProducts({
              comboId: this.savedEditingCombo.id,
              products: combo.comboProducts,
              productPricing
            })
          } else {
            combo = {
              ...combo,
              ...productPricing
            }
            this.addProduct({
              tabId: this.tabId,
              seat: this.seat ?? this.selectedSeat,
              product: combo
            })
          }
        }
      )
      this.close()
    },
    productTotalSelected(comboIndex, parentProductId) {
      if (!this.selectedProducts[comboIndex]) return 0
      let result = Object.values(this.selectedProducts[comboIndex])
        .filter(product => product.parentProduct === parentProductId)
        .reduce((total, product) => {
          total += product.quantity
          return total
        }, 0)
      return result
    },
    categorySelectedProducts(comboIndex, categoryIndex) {
      if (!this.selectedProducts[comboIndex]) return []
      return Object.values(this.selectedProducts[comboIndex]).filter(
        product => product.categoryId === this.getCategory(categoryIndex)?.id
      )
    },
    categorySelectedProductsQuantity(catalogIndex, categoryIndex) {
      return this.categorySelectedProducts(catalogIndex, categoryIndex).reduce(
        (total, product) => {
          total += product.quantity
          return total
        },
        0
      )
    },
    categoryMax(categoryIndex) {
      let category = this.getCategory(categoryIndex)
      if (!category) {
        return 0
      }
      return category.max
    },
    categoryMin(categoryIndex) {
      let category = this.getCategory(categoryIndex)
      if (!category) {
        return 0
      }
      return category.min
    },
    categoryIsMaxed(comboIndex, categoryIndex) {
      return (
        this.categorySelectedProductsQuantity(comboIndex, categoryIndex) >=
        this.categoryMax(categoryIndex)
      )
    },
    allCategoriesReachesMin(comboIndex) {
      return !this.savedCatalogCombo.categories.some(
        (_, categoryIndex) =>
          this.categorySelectedProductsQuantity(comboIndex, categoryIndex) <
          this.categoryMin(categoryIndex)
      )
    },
    isAvailableOnSchedule(product) {
      let maybeSchedulingTime = this.tabs[this.tabId]?.schedulingTime
      return catalog.isEnabledOn(
        product,
        maybeSchedulingTime ? moment(maybeSchedulingTime) : moment()
      )
    }
  },
  computed: {
    products() {
      return this.getCategory(this.selectedCategoryIndex)?.products
    },
    filteredProducts() {
      return (this.products || [])
        .filter(p => p.enabled)
        .filter(p => this.isAvailableOnSchedule(p))
    },
    catalogProductsPosition() {
      return this.savedCatalogCombo.categories.reduce((res, category) => {
        category.products.forEach((product, index) => {
          res[product.id] = category.position * 100 + index
        })
        return res
      }, {})
    }
  }
}

export default mixin
