<template>
  <div class="flex items-center">
    <div
      class="rounded-full w-6 h-6 p-1 mr-3 sm:mr-4"
      :class="[buttonColor, { 'opacity-50': quantity === min }]"
      @click.stop="subtract"
    >
      <icon name="minus" class="w-full h-full" :class="[iconColor]" />
    </div>
    {{ quantity }}
    <div v-if="label" class="ml-1">{{ label }}</div>
    <div
      class="rounded-full w-6 h-6 p-1 ml-3 sm:ml-4"
      :class="[buttonColor, { 'opacity-50': isMaxed }]"
      @click.stop="add"
    >
      <icon name="plus" class="w-full h-full" :class="[iconColor]" />
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'QuantitySelector',
  props: {
    quantity: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: 1
    },
    isMaxed: {
      type: Boolean,
      default: false
    },
    buttonColor: {
      type: String,
      default: 'bg-blue'
    },
    iconColor: {
      type: String,
      default: 'text-white'
    },
    label: {
      type: String,
      default: ''
    },
    step: {
      type: Number,
      default: 1
    }
  },
  methods: {
    add() {
      if (!this.isMaxed) {
        this.$emit('update:quantity', this.quantity + this.step)
      }
    },
    subtract() {
      if (this.quantity > this.min) {
        this.$emit('update:quantity', this.quantity - this.step)
      }
    }
  },
  components: {
    Icon
  }
}
</script>
