var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full h-full"},[(!_vm.showingFloorPlan)?_c('top-bar',{attrs:{"show-back":""},on:{"back":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"center"},[_c('div',{staticClass:"text-xl flex items-center"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])],2):_vm._e(),_c('div',{staticClass:"flex w-full h-full"},[(!_vm.showingFloorPlan)?_c('div',{staticClass:"flex flex-col justify-center items-center flex-1 w-0 bg-gray-200"},[_c('h1',{staticClass:"move-products__title font-normal uppercase text-xl text-center mb-4 leading-normal"},[_vm._v(" "+_vm._s(_vm.$t('ordering.move-products-title'))+" ")]),_c('l-modal-button',{attrs:{"type":"main","disabled":_vm.selectedProducts.length === 0,"label":_vm.$t('ordering.move-products-cta', {
            productsNumber: _vm.selectedProducts.length
          })},nativeOn:{"click":function($event){_vm.showingFloorPlan = true}}})],1):_vm._e(),(!_vm.showingFloorPlan)?_c('div',{staticClass:"side-menu flex flex-col h-full bg-white shadow-md overflow-y-scroll scrolling-touch"},[_vm._l((_vm.tabSeats),function(seat,index){return [_c('l-section-side-menu',{key:index,attrs:{"section-name":("" + (index === 0
              ? _vm.$t('ordering.shared')
              : _vm.$t('ordering.seat') + ' ' + index)),"section-selected":_vm.selectedSeat === index,"elements":seat},on:{"selectedSection":function($event){return _vm.selectProducts(index, seat)},"onAdd":function (event) { return _vm.onUpdate(index, event); },"onUpdate":function (event) { return _vm.onUpdate(index, event); }}},_vm._l((seat),function(product){return _c('div',{key:product.id,staticClass:"flex items-center",on:{"click":function($event){$event.stopPropagation();_vm.selectProduct(
                product,
                !_vm.selectedProducts.some(
                  function (selectedProduct) { return selectedProduct.id === product.id; }
                )
              )}}},[_c('l-checkbox',{staticClass:"ml-4",class:{ 'opacity-50': product.notBilledQuantity === 0 },attrs:{"value":_vm.selectedProducts.some(
                  function (selectedProduct) { return selectedProduct.id === product.id; }
                )},on:{"input":function (value) { return _vm.selectProduct(product, value); }}}),_c('tab-product',{staticClass:"tab-product",attrs:{"id":product.id,"product":product,"disabled-quantity-selector":true,"disabled-by-parent":true}})],1)}),0)]})],2):_vm._e(),(_vm.showingFloorPlan)?_c('table-selector',{attrs:{"title":_vm.title,"blocked-tables":_vm.blockedTables},on:{"close":function($event){_vm.showingFloorPlan = false},"tableSelected":_vm.selectTable}}):_vm._e(),(_vm.showTabSelector)?_c('tab-selector',{attrs:{"tab-ids-to-select":_vm.toTable.tabIds},on:{"tabSelected":_vm.tabSelected,"close":function($event){_vm.showTabSelector = false}}}):_vm._e(),(_vm.openConfirmationModal)?_c('move-products-confirmation-modal',{attrs:{"to-table":_vm.toTable,"to-tab":_vm.toTab,"from-tab":_vm.tabs[_vm.fromTabId],"products":_vm.selectedProducts},on:{"confirm":_vm.moveProducts,"close":_vm.handleCloseModal}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }