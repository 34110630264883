import { paymentScreen } from '@/plugins/paymentScreen.js'
import RedsysDataphone from '@/integrations/dataphone/redsysDataphone.js'
import AdyenDataphone from '@/integrations/dataphone/adyenDataphone.js'
import Vue from 'vue'
import store from '@/store/index.js'
import { Capacitor } from '@capacitor/core'

class Dataphone {
  constructor() {}
  async init(config, preferredConfigId) {
    const platform = Capacitor.getPlatform()
    try {
      if (store.getters['config/currentAdyenDataphone']) {
        this.device = new AdyenDataphone(
          store.getters['config/currentAdyenDataphone']
        )
      } else {
        const preferredDataphone = preferredConfigId
        for (let dataphoneConfig of config.dataphoneConfigs) {
          if (
            dataphoneConfig.id === preferredDataphone ||
            (!preferredDataphone && dataphoneConfig.business) ||
            platform === 'web'
          ) {
            //config preferredDataphone or first dataphone, to avoid duplicating instances, we check if it's already created
            this.device =
              this.device ?? new RedsysDataphone(dataphoneConfig, platform)
            break
          }
        }
        if (!this.device) return
      }
      this.device.init()
    } catch (error) {
      throw new Error('No dataphone capability')
    }
  }

  async charge({ amount, paymentId, tabId }) {
    if (this.initialized()) {
      let component = paymentScreen({})
      try {
        component.$on('cancel', () => {
          this.device.cancel({ paymentId, tabId })
        })
        let metadata = await this.device.charge({ amount, paymentId, tabId })
        Vue.nextTick(() => component.deactivate())
        return metadata
      } catch (e) {
        Vue.nextTick(() => component.deactivate())
        throw e
      }
    }
  }

  async refund(payment, tabId) {
    if (this.initialized()) {
      let res = await this.device.refund(payment, tabId)
      return res
    }
  }

  initialized() {
    return this.device?.initialized || false
  }
}

export default new Dataphone()
