import api from '@/api.js'
import { normalize, schema } from 'normalizr'

const tableSchema = new schema.Entity('tables')
const floorPlanSchema = new schema.Entity('floorplans', {
  tables: [tableSchema]
})

const state = {
  floorplanIds: [],
  floorplans: {},
  tables: {}
}

const getters = {
  getTables: (state, getters, rootState, rootGetters) => floorplanId => {
    return state.floorplans[floorplanId].tables
      .map(tableId => state.tables[tableId])
      .map(table => {
        let tabs = rootGetters['tabs/getByTableId'](table.id)
        return {
          ...table,
          tabIds: tabs.map(tab => tab.id)
        }
      })
  },
  getAllTables: (state, getters, rootState, rootGetters) => {
    return Object.values(state.tables).map(table => {
      let tabs = rootGetters['tabs/getByTableId'](table.id)
      return {
        ...table,
        tabIds: tabs.map(tab => tab.id)
      }
    })
  },
  getTabFloorplan: (state, getters, rootState, rootGetters) => tabId => {
    let tab = rootGetters['tabs/getTab'](tabId)
    let tabTableId = tab?.tables[0]
    if (tabTableId) {
      let tabTable = state.tables[tabTableId]
      return state.floorplans[tabTable.floorplanId]
    }
    return null
  }
}

const actions = {
  async refreshFloorplans({ commit }) {
    let response = await api.get('/floorplans')
    commit('refreshFloorplans', normalize(response.data, [floorPlanSchema]))
  }
}

const mutations = {
  refreshFloorplans(state, data) {
    state.floorplanIds = data.result || []
    state.floorplans = data.entities.floorplans || []
    state.tables = data.entities.tables || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
