<template>
  <div>
    <div v-if="virtualBrandDeliveries.length > 1">
      <l-collapse
        v-for="(virtualBrand, index) in virtualBrandDeliveries"
        :key="virtualBrand.id"
        open
      >
        <template
          v-if="hasDeliveryCompanies(virtualBrand)"
          slot="trigger"
          slot-scope="props"
        >
          <div
            class="text-blue mt-5 flex flex-row items-center justify-between mr-8 flex-1"
          >
            <div class="flex flex-row">
              <icon
                :name="'arrow-right'"
                class="arrow"
                :class="{ rotated: props.open }"
              />
              <div class="ml-2 font-bold text-base">
                {{ virtualBrand.brandName }}
              </div>
            </div>
            <l-switch
              v-if="countDeliveryCompanies(virtualBrand) > 1"
              :value="brandIsEnabled(index)"
              @input="value => setBrandValue(index, value)"
              @click.native.stop
            />
          </div>
        </template>
        <delivery-selector-companies
          :virtual-brand="virtualBrandDeliveries[index]"
          @closeDelivery="
            (brandId, company) => $emit('closeDelivery', brandId, company)
          "
          @openDelivery="
            (brandId, company) => $emit('openDelivery', brandId, company)
          "
        />
      </l-collapse>
    </div>
    <div v-else class="mt-8 -mx-12">
      <delivery-selector-companies
        :virtual-brand="virtualBrandDeliveries[0]"
        @closeDelivery="
          (brandId, company) => $emit('closeDelivery', brandId, company)
        "
        @openDelivery="
          (brandId, company) => $emit('openDelivery', brandId, company)
        "
      />
    </div>
  </div>
</template>

<script>
import LSwitch from '@last/core-ui/components/LSwitch'
import LCollapse from '@last/core-ui/components/LCollapse'
import Icon from '@last/core-ui/components/Icon'
import DeliverySelectorCompanies from './DeliverySelectorCompanies'
import moment from 'moment'

export default {
  name: 'DeliverySelector',
  props: {
    virtualBrandDeliveries: {
      type: Array,
      default: () => []
    },
    currentTime: {
      type: Date,
      default: null
    },
    showClosedUntil: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.$emit('mounted')
  },
  methods: {
    countDeliveryCompanies(virtualBrand) {
      return Object.keys(virtualBrand.externalDeliveryCompaniesClosingTimes)
        .length
    },
    hasDeliveryCompanies(virtualBrand) {
      return this.countDeliveryCompanies(virtualBrand) > 0
    },
    setBrandValue(index, value) {
      if (value) {
        this.$emit('openBrand', this.virtualBrandDeliveries[index].id)
      } else {
        this.$emit('closeBrand', this.virtualBrandDeliveries[index].id)
      }
    },
    brandIsEnabled(index) {
      return Object.values(
        this.virtualBrandDeliveries[index].externalDeliveryCompaniesClosingTimes
      ).some(time => !time || moment(time).isBefore(moment(this.currentTime)))
    }
  },
  components: {
    DeliverySelectorCompanies,
    LSwitch,
    LCollapse,
    Icon
  }
}
</script>

<style scoped>
.arrow {
  transition: all 0.3s;
}

.rotated {
  transform: rotate(90deg);
}
</style>
