import { Updater } from './updater'

export class GenericUpdater extends Updater {
  constructor(platform) {
    super()
    this.platform = platform
  }

  async getVersion() {
    return { name: this.platform, build: '' }
  }

  // Overwrite checkForUpdates with no op
  // This updater is for web or platforms unknown where we don't want to check for updates
  async checkUpdates() {}
}
