class CashGuardDirect {
  init({ port, inputAmountListener, totalAmountListener }) {
    this.ipcRenderer = window.require('electron').ipcRenderer
    this.ipcRenderer.on('cashguard:inputAmountUpdated', (_event, amount) =>
      inputAmountListener(amount)
    )
    this.ipcRenderer.on('cashguard:totalAmountUpdated', (_event, amount) =>
      totalAmountListener(amount)
    )
    return this.ipcRenderer.invoke('cashguard:init', port)
  }

  charge(amount) {
    return this.ipcRenderer.invoke('cashguard:charge', amount)
  }

  payIn(amount) {
    return this.ipcRenderer.invoke('cashguard:payIn', amount)
  }

  payOut(amount) {
    return this.ipcRenderer.invoke('cashguard:payOut', amount)
  }

  cancel() {
    return this.ipcRenderer.invoke('cashguard:cancel')
  }

  close() {
    return this.ipcRenderer.invoke('cashguard:close')
  }
}

export default new CashGuardDirect()
