<template>
  <div
    class="relative flex max-h-full flex-wrap p-3 pb-64 sm:pb-48 overflow-scroll scrolling-touch"
  >
    <menu-product
      v-for="product in filteredProducts"
      :key="product.id"
      :product="product"
      @selected="$emit('selected-product', product)"
      :component-style="style"
      class="w-full sm:w-auto"
      :selected-quantity="productTotalSelected(product.id)"
    />
  </div>
</template>

<script>
import MenuProduct from '@/components/ordering/MenuProduct.vue'
import catalog from '@last/core/src/catalog'
import moment from 'moment'
import app from '@/app.js'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ProductList',
  components: {
    MenuProduct
  },
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    productTotalSelected(parentProductId) {
      let result = Object.values(this.tabAllProducts)
        .filter(product => product.parentProduct === parentProductId)
        .reduce((total, product) => {
          total += product.quantity
          return total
        }, 0)
      return result
    },
    isAvailableOnSchedule(product) {
      let maybeSchedulingTime = this.tabs[this.tabId]?.schedulingTime
      return catalog.isEnabledOn(
        product,
        maybeSchedulingTime ? moment(maybeSchedulingTime) : moment()
      )
    }
  },
  computed: {
    ...mapGetters('tabs', ['getAllProducts']),
    ...mapState('tabs', ['tabs']),
    tabAllProducts() {
      return this.getAllProducts(this.tabId)
    },
    style() {
      return app.isMobile ? 'row' : 'square'
    },
    tabId() {
      return this.$route.params.tabId
    },
    filteredProducts() {
      return (this.products || [])
        .filter(p => p.enabled)
        .filter(p => this.isAvailableOnSchedule(p))
    }
  }
}
</script>
