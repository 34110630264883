<template>
  <div
    class="h-screen flex flex-col justify-center items-center bg-cover bg-image text-white"
  >
    <div class="text-xl">
      {{ $t('start-shift.change-device') }}
    </div>
    <div
      class="mt-6 p-3 px-6 shadow-lg text-white bg-blue border border-blue-900 uppercase rounded-small font-bold text-center"
      @click="$router.go(-1)"
    >
      {{ $t('start-shift.back') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartShift'
}
</script>

<style lang="scss" scoped>
.bg-image {
  background-image: url('../../assets/background-employees.jpg');
}
</style>
