import api from '@/api.js'
import mqtt from '@/sync/mqtt.js'
import { normalizeEmployees } from '@/normalizr'
import * as Sentry from '@sentry/browser'
import { datadogLogs } from '@datadog/browser-logs'
import appStorage from '@/appStorage.js'
import router from '@/router.js'
import moment from 'moment'

const state = {
  accessToken: null,
  employees: {},
  privileges: {},
  currentEmployeeId: null,
  locationId: null,
  locationCoordinates: {},
  organizationId: null,
  billingStatus: {
    stillInTrial: false,
    gracePeriodTriggered: false,
    endsAt: null,
    posEnabled: true
  },
  isLocationManager: false
}

const getters = {
  isAuthenticated: state => !!state.accessToken,
  getEmployees: state => Object.values(state.employees || {}),
  getPrivileges: state => state.privileges,
  currentEmployee: state => state.employees[state.currentEmployeeId],
  currentEmployeePrivileges: state => state.privileges[state.currentEmployeeId],
  anyEmployeeIsManager: (_, getters) => {
    return getters.getEmployees.some(employee => employee.isManager)
  },
  hasPrivilege: (_, getters) => (employeeId, privilege) =>
    !!getters.getPrivileges[employeeId]?.includes(privilege),
  askPinForPrivilege: (_, getters) => privilege => {
    return !getters.getEmployees.every(employee => {
      return getters.getPrivileges[employee.id].includes(privilege)
    })
  },
  trialExpired: state =>
    Boolean(state.billingStatus.stillInTrial && state.billingStatus.expired),
  gracePeriodExpired: state =>
    Boolean(
      state.billingStatus.gracePeriodTriggered && state.billingStatus.expired
    ),
  daysLeft: state => moment(state.billingStatus.endsAt).diff(moment(), 'days')
}

const actions = {
  async login({ commit }, loginData) {
    let response = await api.post('/users/login', loginData)
    if (response.data.locationId)
      commit('setLocationId', response.data.locationId)
    commit('setAccessToken', response.data.token)
  },
  async setAccessToken({ commit }, accessToken) {
    commit('setAccessToken', accessToken)
  },
  async signUp({ dispatch }, signupData) {
    await api.post('/signup', signupData)
    dispatch('login', {
      email: signupData.email,
      password: signupData.password
    })
  },
  async supportLogin(
    { commit },
    { accessToken, selectedOrganizationId, selectedLocationId }
  ) {
    let response = await api.get(`/users/token/${accessToken}/checkSupport`)
    let supportUserIsValid = response.data
    if (supportUserIsValid) {
      commit('setSupportLogin', {
        accessToken,
        selectedOrganizationId,
        selectedLocationId
      })
      return true
    }
    return false
  },
  async refreshCurrentLocation() {
    let response = await api.get('/location')
    let location = response.data
    Sentry.setUser({
      id: location.id,
      username: location.name
    })
    datadogLogs.addLoggerGlobalContext('locationId', location.id)
    datadogLogs.addLoggerGlobalContext('locationName', location.name)
  },
  selectEmployee({ commit }, employeeId) {
    commit('selectEmployee', employeeId)
  },
  async refreshEmployees({ commit }) {
    const employees = (await api.get('/employees')).data
    commit('refreshEmployees', normalizeEmployees(employees))
    const privileges = employees.reduce((acc, employee) => {
      acc[employee.id] = employee.privileges
      return acc
    }, {})
    commit('refreshPrivileges', privileges)
  },
  async refreshBillingStatus({ commit }) {
    const { data } = await api.get('/billing/status')
    commit('updateBillingStatus', data)
  },
  validateEmployeePinWithPrivilege({ commit, getters }, { pin, privilege }) {
    let ret = { pin: false, acces: false }
    if (pin === getters.currentEmployee.accessPin) {
      ret.pin = true
      if (getters.currentEmployeePrivileges.includes(privilege)) {
        ret.acces = true
      }
    } else {
      getters.getEmployees.map(employee => {
        if (
          employee.accessPin === pin &&
          getters.getPrivileges[employee.id].includes(privilege)
        ) {
          ret.acces = true
          commit('selectEmployee', employee.id)
        }
      })
    }
    return ret
  },
  validateManagerPin({ rootState }, pin) {
    let managerPin = rootState.config.config.managerPin
    if (pin && managerPin) {
      return pin === managerPin
    }
    return false
  },
  validateEmployeePin({ getters }, pin) {
    let employeePin = getters.currentEmployee.accessPin
    if (pin && employeePin) {
      return employeePin === pin
    }
    return false
  },
  selectLocation({ commit }, location) {
    datadogLogs.addLoggerGlobalContext('locationId', location.id)
    commit('setLocationId', location.id)
    commit('setLocationCoordinates', location)
    mqtt.subscribe(location.id)
  },
  async logout({ commit }) {
    commit('logout')
    await appStorage.clear()
    router.push({ name: 'login' })
  },
  async deviceLogout({ dispatch, rootState }, { deviceId }) {
    if (rootState.config.device.id === deviceId) {
      dispatch('logout')
    }
  },
  async getDemoAuthData({ commit }) {
    let demoData = await api.get('/demoAuthData')
    commit('setDemoData', demoData.data)
  }
}

const mutations = {
  setDemoData(state, demoData) {
    const { locationId, organizationId } = demoData
    state.locationId = locationId
    state.organizationId = organizationId
    state.accessToken = 'demoToken'
  },
  setAccessToken(state, token) {
    state.accessToken = token
  },
  setSupportLogin(
    state,
    { accessToken, selectedOrganizationId, selectedLocationId }
  ) {
    state.accessToken = accessToken
    state.organizationId = selectedOrganizationId
    state.locationId = selectedLocationId
  },
  selectEmployee(state, employeeId) {
    state.currentEmployeeId = employeeId
  },
  refreshEmployees(state, employeeData) {
    state.employees = employeeData.employees
  },
  refreshPrivileges(state, privileges) {
    state.privileges = privileges
  },
  setLocationId(state, locationId) {
    state.locationId = locationId
  },
  setLocationCoordinates(state, location) {
    if (location.latitude && location.longitude) {
      state.locationCoordinates = {
        latitude: location.latitude,
        longitude: location.longitude
      }
    }
  },
  logout(state) {
    state.accessToken = null
    state.locationId = null
  },
  updateBillingStatus(state, status) {
    state.billingStatus = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
