export const trialPlan = 'pro'

export function map(plans) {
  return Object.keys(plans).map(key => {
    const plan = plans[key]
    let result = {
      key,
      name: key.charAt(0).toUpperCase() + key.slice(1),
      price: plan.price,
      benefits: [
        {
          key: 'pos-software',
          limit: 'devices-limit',
          limitValue: plan.devices
        },
        { key: 'virtual-brands', limit: 'unlimited' }
      ]
    }
    if (plan.integrations)
      result.benefits.push({
        key: 'integrations',
        limit: 'integrations-limit',
        limitValue: plan.integrations
      })
    if (plan.extraTabsLimit)
      result.benefits = [
        ...result.benefits,
        {
          key: 'delivery-hub',
          limit: 'orders-limit',
          limitValue: plan.extraTabsLimit
        },
        {
          key: 'extra-orders',
          price: plan.extraTabsPrice
        }
      ]
    return result
  })
}
