<template>
  <div v-if="internalReservation">
    <div v-if="selectingTable" class="absolute top-0 left-0 w-screen h-full">
      <table-selector
        :title="$t('reservations.title')"
        :blocked-tables="blockedTables"
        :multiple-select="true"
        :selected-tables="internalReservation.tables"
        @close="selectingTable = false"
        @tablesSelected="selectTables"
      />
    </div>
    <reservation-details
      v-else
      @close="$emit('close')"
      :reservation="internalReservation"
      @selectTable="
        newInternalReservation => {
          selectingTable = true
          internalReservation = newInternalReservation
        }
      "
    />
  </div>
</template>

<script>
import TableSelector from '@/components/tables/TableSelector.vue'
import ReservationDetails from '@/components/ReservationDetails.vue'
import reservationMixin from '@/components/reservationMixin.js'

export default {
  name: 'ReservationEditor',
  mixins: [reservationMixin],
  components: {
    TableSelector,
    ReservationDetails
  },
  props: {
    reservation: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      internalReservation: null,
      selectingTable: false
    }
  },
  mounted() {
    this.internalReservation = { ...this.reservation }
    this.setReservation(this.reservation)
  },
  methods: {
    selectTables(tables) {
      this.internalReservation.tables = tables
      // Temporal code to be backward compatible
      this.internalReservation.tableId = tables[0]
      this.selectingTable = false
    }
  }
}
</script>
