import { App as CapacitorApp } from '@capacitor/app'
import { CapacitorUpdater } from '@capgo/capacitor-updater'

import { Updater } from './updater.js'
import { SplashScreen } from '@capacitor/splash-screen'

export class CapgoUpdater extends Updater {
  constructor() {
    super()
    this.nextBundle = null
    this.rejectDownload = null
    // When the checksum returned by the server doesn't match the file one the download, the promise
    // never gets resolved but we are still notified with a 'downloadFailed' event.
    CapacitorUpdater.addListener('downloadFailed', () => {
      if (this.rejectDownload) {
        this.rejectDownload()
        this.rejectDownload = null
      }
    })
  }

  async notifyAppReady() {
    CapacitorUpdater.notifyAppReady()
  }

  async getVersion() {
    // CapacitorUpdater.current() also returns 'native' version,
    // but it should match the version returned by CapacitorApp.getInfo()
    const info = await CapacitorApp.getInfo()
    const { bundle } = await CapacitorUpdater.current()
    return {
      name: bundle.version === 'builtin' ? info.version : bundle.version,
      build: info.build
    }
  }

  async _getPlatform() {
    return 'bundle'
  }

  async _downloadUpdate(info) {
    this.nextBundle = await this.downloadBundle(
      info.url,
      info.version,
      info.checksum
    )

    // Setting next bundle to autoupdate in case the application is restarted
    CapacitorUpdater.next({ id: this.nextBundle.id })
  }

  async installUpdate() {
    SplashScreen.show()
    CapacitorUpdater.set({ id: this.nextBundle.id })
  }

  async downloadBundle(url, version, checksum) {
    const { bundles } = await CapacitorUpdater.list()

    for (let bundle of bundles) {
      if (
        bundle.version === version &&
        bundle.checksum == checksum &&
        ['success', 'pending'].includes(bundle.status)
      ) {
        return bundle
      }
    }

    // Needed to handle the invalid checksum bug
    return new Promise((resolve, reject) => {
      this.rejectDownload = reject
      CapacitorUpdater.download({ url, version, checksum })
        .then(resolve, reject)
        .finally(() => (this.rejectDownload = null))
    })
  }
}
