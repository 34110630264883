import uuid4 from 'uuid/v4'
import Vue from 'vue'
import Bills from '@last/core/src/billsGenerator.js'
import Orders from '@last/core/src/kitchenOrderGenerator.js'
import sync from '@/sync/service.js'
import TicketPrinter from '@/ticketPrinter.js'
import { normalizeTabs, normalizeTab } from '@/normalizr'
import i18n from '@/i18n.js'
import { Howl } from 'howler'
import moment from 'moment'
import { EventBus } from '@/eventBus.js'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import Dataphone from '@/integrations/dataphone/dataphone.js'
import { notification } from '@last/core-ui/plugins/notification'
import localDb from '@/localDb.js'
import Logger from '@/logger.js'

const state = {
  tabs: {},
  products: {},
  bills: {},
  billsMetadata: {},
  payments: {},
  kitchenOrders: {},
  refundablePaymentMethods: ['dataphone']
}

const getters = {
  parseKitchenProducts:
    (state, getters, rootState, rootGetters) => (tabId, products) => {
      return products.map(product => {
        let originalProduct = rootGetters['catalog/getProductById'](
          product.parentProduct
        )

        let categoryPosition = 0
        if (product.fromCombo) {
          let originalCombo = rootGetters['catalog/getProductById'](
            product.combo.parentProduct
          )
          categoryPosition =
            originalCombo?.categories.reduce((res, category, categoryIndex) => {
              category.products.forEach(p => (res[p.id] = categoryIndex))
              return res
            }, {})[originalProduct?.id] || 0
        }
        let modifiers = product.modifiers
        if (
          product.modifiers &&
          originalProduct &&
          originalProduct.modifierGroups
        ) {
          let originalModifiers = originalProduct.modifierGroups
            .flatMap(group => {
              return group.modifiers.map(modifier => {
                return {
                  ...modifier,
                  fullPosition: group.position * 1000 + modifier.position
                }
              })
            })
            .reduce((res, modifier) => {
              res[modifier.id] = modifier
              return res
            }, {})
          modifiers = product.modifiers
            .map(modifier => ({
              ...modifier,
              fullPosition: originalModifiers[modifier.parentModifierId]
                ? originalModifiers[modifier.parentModifierId].fullPosition
                : null,
              kitchenName: originalModifiers[modifier.parentModifierId]
                ? originalModifiers[modifier.parentModifierId].kitchenName
                : null
            }))
            .sort((a, b) => a.fullPosition - b.fullPosition)
        }
        let category = rootState.catalog.categories[product.categoryId]
        let defaultPrinters = []
        if (rootState.config.config.defaultKitchenPrinter) {
          defaultPrinters = [rootState.config.config.defaultKitchenPrinter]
        }
        let printerIds =
          (originalProduct && originalProduct.printerIds) ||
          (category && category.printerIds) ||
          defaultPrinters
        let pickupType = state.tabs[tabId].pickupType
        let tabFloorplan = rootGetters['tables/getTabFloorplan'](tabId)
        let controlPrinter =
          tabFloorplan?.controlPrinter || rootState.config.config.controlPrinter
        if (
          controlPrinter &&
          (!pickupType || state.tabs[tabId].source === 'Restaurant') &&
          (!originalProduct || originalProduct.controlEnabled)
        ) {
          printerIds = [...new Set([...printerIds, controlPrinter])]
        }
        return {
          ...originalProduct,
          ...product,
          modifiers,
          printerIds,
          copies:
            (originalProduct && originalProduct.copies) ??
            (category && category.copies) ??
            1,
          categoryPosition
        }
      })
    },
  getTab: state => id => {
    return state.tabs[id]
  },
  isTabOpen: state => id => {
    return id in state.tabs
  },
  getPayment: state => paymentId => {
    return state.payments[paymentId]
  },
  getPaidProductQuantities: (state, getters) => {
    return Object.values(state.tabs)
      .flatMap(tab => getters.getBills(tab.id))
      .flatMap(bill => {
        if (bill.pending === 0) {
          return bill.products
        } else {
          return []
        }
      })
      .reduce((res, product) => {
        res[product.tabProductId] =
          (res[product.tabProductId] || 0) + product.quantity
        return res
      }, {})
  },
  getBilledProductQuantities: state => {
    return Object.values(state.tabs)
      .flatMap(tab => tab.bills)
      .map(billId => state.bills[billId])
      .flatMap(bill => bill.products)
      .reduce((res, product) => {
        res[product.tabProductId] =
          (res[product.tabProductId] || 0) + product.quantity
        return res
      }, {})
  },
  formatTab:
    (_, getters, rootState) =>
    ({ tab, tableId }) => {
      let tables = (tableId && [tableId]) || []
      let table = rootState.tables.tables[tableId]
      const virtualBrandId =
        tab.virtualBrandId || rootState.config.config.locationBrandId
      const marginTime =
        getters.getActivationMarginPerVirtualBrandId(virtualBrandId)
      const shouldBeActivated =
        !tab.schedulingTime ||
        moment(tab.schedulingTime).isBefore(moment().add(marginTime, 'minutes'))
      let now = Date.now()
      const newTab = {
        ...tab,
        id: tab.id || uuid4(),
        tables,
        open: true,
        source: tab.source || 'Restaurant',
        creationTime: now,
        schedulingTime: tab.schedulingTime,
        activationTime: shouldBeActivated ? now : null,
        tableName: getters.getTableName(tab.name, tab.lang, tables),
        billingStartedTime: null,
        virtualBrandId,
        orderingMode: rootState.config.config.tabOrdering || 'seats',
        terraceSurchargePercentage: table?.terraceSurchargePercentage || 0
      }
      return newTab
    },
  getCourseProducts:
    (state, getters, rootState) =>
    ({ catalogId, tabId }) => {
      let billedQuantities = getters.getBilledProductQuantities
      let paidQuantities = getters.getPaidProductQuantities
      let tab = state.tabs[tabId]
      let tabProducts = [...tab.shared.concat(tab.seats.flat())]
      let catalog = rootState.catalog.catalogs[catalogId]
      if (!tab || !catalog) return []
      let courseProductsMap = {}

      tabProducts.forEach(productId => {
        const product = state.products[productId]
        if (product.comboProducts)
          product.comboProducts.forEach(comboProduct => {
            const finalProduct = {
              ...product,
              shownCombo: comboProduct,
              notBilledQuantity:
                product.quantity - (billedQuantities[productId] || 0),
              notPaidQuantity:
                product.quantity - (paidQuantities[productId] || 0)
            }
            if (courseProductsMap[comboProduct.course])
              courseProductsMap[comboProduct.course].push(finalProduct)
            else courseProductsMap[comboProduct.course] = [finalProduct]
          })
        else {
          const finalProduct = {
            ...product,
            notBilledQuantity:
              product.quantity - (billedQuantities[productId] || 0),
            notPaidQuantity: product.quantity - (paidQuantities[productId] || 0)
          }
          if (courseProductsMap[product.course])
            courseProductsMap[product.course].push(finalProduct)
          else courseProductsMap[product.course] = [finalProduct]
        }
      })

      let courseProducts = []

      catalog.courses.forEach(course => {
        courseProducts.push({
          name: course,
          products: courseProductsMap[course] || []
        })
        delete courseProductsMap[course]
      })

      courseProducts.push({
        name: i18n.t('tabs.other'),
        products: Object.values(courseProductsMap).flat()
      })

      return courseProducts
    },
  getSeatProductsByTabId: (state, getters) => {
    let billedQuantities = getters.getBilledProductQuantities
    let paidQuantities = getters.getPaidProductQuantities
    return Object.values(state.tabs).reduce((res, tab) => {
      res[tab.id] = tab.seats.map(seat => {
        return seat
          .map(productId => state.products[productId])
          .map(product => ({
            ...product,
            notBilledQuantity:
              product.quantity - (billedQuantities[product.id] || 0),
            notPaidQuantity:
              product.quantity - (paidQuantities[product.id] || 0)
          }))
      })
      return res
    }, {})
  },
  getSeatProducts: (_state, getters) => id => {
    return getters.getSeatProductsByTabId[id] || []
  },
  getSharedProductsByTabId: (state, getters) => {
    let billedQuantities = getters.getBilledProductQuantities
    let paidQuantities = getters.getPaidProductQuantities
    return Object.values(state.tabs).reduce((res, tab) => {
      res[tab.id] = tab.shared
        .map(productId => state.products[productId])
        .map(product => ({
          ...product,
          notBilledQuantity:
            product.quantity - (billedQuantities[product.id] || 0),
          notPaidQuantity: product.quantity - (paidQuantities[product.id] || 0)
        }))
      return res
    }, {})
  },
  getSharedProducts: (_state, getters) => id => {
    return getters.getSharedProductsByTabId[id] || []
  },
  getCustomerId: state => id => {
    let tab = state.tabs[id]
    return tab.customerId
  },
  getBillById: state => id => {
    let bill = state.bills[id]
    let payments = bill.payments.map(paymentId => state.payments[paymentId])
    let metadata = state.billsMetadata[id]
    return {
      ...bill,
      payments,
      metadata
    }
  },
  getBills: state => id => {
    let tab = state.tabs[id]
    if (!tab) return []
    return tab.bills
      .map(billId => ({
        ...state.bills[billId],
        payments: state.bills[billId].payments.map(
          paymentId => state.payments[paymentId]
        )
      }))
      .map(bill =>
        Bills.addPaymentInfo(
          bill,
          bill.payments.filter(payment => !!payment)
        )
      )
  },
  getBillsDiscounts: (_, getters) => tabId => {
    let bills = [
      ...getters.getBills(tabId),
      ...(getters.getPendingBill(tabId) ? [getters.getPendingBill(tabId)] : [])
    ]
    return bills
      .map(bill => {
        return {
          ...bill.discount,
          discountTotal: bill.discountTotal
        }
      })
      .filter(
        discount => discount && discount.amount && discount.discountTotal > 0
      )
  },
  getAllProductsByTabId: (state, getters, rootState, rootGetters) => {
    let productsById = rootGetters['catalog/productsById']
    const [getSharedProductsByTabId, getSeatProductsByTabId] = [
      getters.getSharedProductsByTabId,
      getters.getSeatProductsByTabId
    ]

    return Object.keys(state.tabs).reduce((res, tabId) => {
      res[tabId] = [
        ...getSharedProductsByTabId[tabId],
        ...getSeatProductsByTabId[tabId].flat()
      ].map(product => {
        return {
          ...product,
          taxes:
            productsById[product.parentProduct]?.taxes ||
            rootState.config.config.taxRate
        }
      })
      return res
    }, {})
  },
  getAllProducts: (_state, getters) => id => {
    return getters.getAllProductsByTabId[id] || []
  },
  getTotalsByTabId: (state, getters, rootState) => {
    const allProducts = getters.getAllProductsByTabId
    return Object.keys(state.tabs).reduce((res, tabId) => {
      let generatedBill = Bills.generateProductsBill({
        products: allProducts[tabId],
        company: rootState.config.config.company,
        tab: state.tabs[tabId],
        discount: getters.getGlobalDiscountByTabId(tabId),
        taxRate: rootState.config.config.taxRate,
        ticketInfo: rootState.config.config.ticketInfo
      })
      res[tabId] = generatedBill.total
      return res
    }, {})
  },

  getTableName: (state, _, rootState) => (name, lang, tables) => {
    let tableName = tables
      .map(tableId => {
        let table = rootState.tables.tables[tableId]
        return table ? table.name : ''
      })
      .filter(name => name)
      .join(',')
    if (name) {
      return name + (tableName ? ` (${tableName})` : '')
    } else {
      return `${tableName}`
    }
  },
  getSentToKitchenProductsByTabId: state => {
    return Object.keys(state.tabs).reduce((res, tabId) => {
      res[tabId] = state.tabs[tabId].kitchenOrders
        .map(id => state.kitchenOrders[id])
        .flatMap(order =>
          order.versions.slice(-1)[0].products.map(product => {
            return {
              productId: product.tabProductId,
              sent: order.creationTime,
              kitchenOrder: order.id
            }
          })
        )
      return res
    }, {})
  },
  getSentToKitchenProducts: (_, getters) => tabId => {
    return getters.getSentToKitchenProductsByTabId[tabId] || []
  },
  hasSentToKitchenProducts: (_, getters) => tabId => {
    return getters.getSentToKitchenProducts(tabId).length > 0
  },
  getUnsentProductsByTabId: (_, getters) => {
    let sentProductsByTab = getters.getSentToKitchenProductsByTabId
    let productsByTab = getters.getAllProductsByTabId
    return Object.keys(productsByTab).reduce((res, tabId) => {
      let sentProducts = sentProductsByTab[tabId].map(
        product => product.productId
      )
      res[tabId] = productsByTab[tabId]
        .flatMap(product => {
          if (product.comboProducts) {
            return product.comboProducts.map(comboProduct => ({
              ...comboProduct,
              quantity: comboProduct.quantity * product.quantity,
              fromCombo: true,
              combo: product
            }))
          } else {
            return [product]
          }
        })
        .filter(product => !sentProducts.includes(product.id))
      return res
    }, {})
  },
  getUnsentProducts: (_, getters) => tabId => {
    return getters.getUnsentProductsByTabId[tabId] || []
  },
  getSentToKitchenTime: (state, getters) => productId => {
    let product = state.products[productId]
    let productIds = [product.id]
    if (product.comboProducts && product.comboProducts.length > 0) {
      productIds = product.comboProducts.map(product => product.id)
    }
    let sentInfo = getters
      .getSentToKitchenProducts(product.tab)
      .find(data => productIds.includes(data.productId))
    if (sentInfo) {
      return sentInfo.sent
    }
    return null
  },
  getProductKitchenOrderIds: (state, getters) => productId => {
    let product = state.products[productId]
    let productIds = [product.id]
    if (product.comboProducts && product.comboProducts.length > 0) {
      productIds = product.comboProducts.map(product => product.id)
    }
    return getters
      .getSentToKitchenProducts(product.tab)
      .filter(data => productIds.includes(data.productId))
      .map(sentInfo => sentInfo.kitchenOrder)
  },
  getDeliveryFee: (state, getters) => tabId => {
    let deliveryFee = { isFree: false, value: 0 }
    if (!state.tabs[tabId]) return deliveryFee
    let discount = getters.getGlobalDiscountByTabId(tabId)
    let deliveryOrder = state.tabs[tabId].deliveryOrder
    if (discount && discount.freeDelivery) {
      deliveryFee.isFree = true
    } else if (deliveryOrder) {
      deliveryFee.value = deliveryOrder.deliveryFee || 0
    }
    return deliveryFee
  },
  getMinimumBasketSurcharge: state => tabId => {
    return state.tabs[tabId]?.deliveryOrder?.minimumBasketSurcharge || 0
  },
  getTotal: (state, getters, rootState) => tabId => {
    let generatedBill = Bills.generateProductsBill({
      products: getters.getAllProducts(tabId),
      company: rootState.config.config.company,
      tab: state.tabs[tabId],
      discount: getters.getGlobalDiscountByTabId(tabId),
      taxRate: rootState.config.config.taxRate,
      ticketInfo: rootState.config.config.ticketInfo
    })
    return generatedBill.total
  },
  getGlobalDiscountByTabId:
    (state, getters, rootState, rootGetters) => tabId => {
      let promotion = rootGetters['promotions/getTabGlobalPromotion'](tabId)
      return promotion
        ? {
            type: promotion.discountType,
            amount: promotion.discountAmount,
            freeDelivery: promotion.freeDelivery
          }
        : null
    },
  getByTableId: state => tableId => {
    return Object.values(state.tabs)
      .filter(tab => tab.open)
      .filter(tab => tab.tables.includes(tableId))
  },
  getByBillId: state => billId => {
    return Object.values(state.tabs).find(tab => tab.bills.includes(billId))
  },
  getBillPayments: state => billId => {
    if (!state.bills[billId]) return []
    return state.bills[billId].payments.map(
      paymentId => state.payments[paymentId]
    )
  },
  getNegativeTabPayments: state => tabId => {
    const tab = state.tabs[tabId]
    if (!tab) return []
    let negativePayments = []
    for (const billId of tab.bills) {
      const bill = state.bills[billId]
      for (const paymentId of bill.payments) {
        const payment = state.payments[paymentId]
        if (payment.amount < 0) {
          negativePayments.push(payment)
        }
      }
    }
    return negativePayments
  },
  getLastInteraction: state => tabId => {
    let tab = state.tabs[tabId]
    return tab.kitchenOrders
      .map(id => state.kitchenOrders[id])
      .map(order => new Date(order.creationTime))
      .reduce((max, val) => Math.max(max, val), new Date(tab.creationTime))
  },
  getPendingBill: (state, getters, rootState) => tabId => {
    let products = getters
      .getAllProducts(tabId)
      .map(product => {
        return {
          ...product,
          quantity: product.notBilledQuantity ?? 0
        }
      })
      .filter(product => product.quantity > 0)
    if (products.length === 0) {
      return null
    }
    return Bills.generateProductsBill({
      products,
      company: rootState.config.config.company,
      tab: state.tabs[tabId],
      discount: getters.getGlobalDiscountByTabId(tabId),
      taxRate: rootState.config.config.taxRate,
      ticketInfo: rootState.config.config.ticketInfo
    })
  },
  isDeliveryTab: state => tabId => {
    return (
      state.tabs[tabId].deliveryOrder &&
      state.tabs[tabId].pickupType !== 'takeAway'
    )
  },
  getDeliveryTillId: (_, __, rootState, rootGetters) => () => {
    let deliveryTillId = rootState.config.config.deliveryTillId
    if (
      deliveryTillId &&
      rootGetters['config/tills']['cash'].some(
        till => till.id === deliveryTillId
      )
    ) {
      return deliveryTillId
    }
  },
  getPaymentTillId: (_, getters, rootState, rootGetters) => (tabId, type) => {
    if (getters.isDeliveryTab(tabId)) {
      const deliveryTillId = getters.getDeliveryTillId()
      if (deliveryTillId) return deliveryTillId
    }
    if (
      rootGetters['auth/currentEmployee'].tillEnabled &&
      rootGetters['auth/currentEmployee'].tillId &&
      type &&
      type === 'cash'
    ) {
      return rootGetters['auth/currentEmployee'].tillId
    }
    if (rootState.till.selectedCashTill) {
      return rootState.till.selectedCashTill.id
    }
  },
  getTabPreparationMinutes:
    (state, getters, rootState, rootGetters) => virtualBrandId => {
      let virtualBrand = rootGetters['config/getVirtualBrand'](virtualBrandId)
      let preparationMinutes = rootState.config.config.preparationMinutes
      const brandDeliveryConfig = virtualBrand?.deliveryConfig

      return brandDeliveryConfig?.preparationMinutes || preparationMinutes || 0
    },
  getActivationMarginPerVirtualBrandId:
    (_, getters, rootState, rootGetters) => virtualBrandId => {
      let virtualBrand = rootGetters['config/getVirtualBrand'](virtualBrandId)
      const brandDeliveryConfig = virtualBrand?.deliveryConfig

      const preparationMargin = getters.getTabPreparationMinutes(virtualBrandId)
      const schedulingMargin =
        brandDeliveryConfig?.schedulingMarginMinutes ||
        rootState.config.config.schedulingMarginMinutes ||
        0

      return Math.max(preparationMargin, schedulingMargin)
    }
}

const actions = {
  async openTab({ getters }, { tableId, tab }) {
    let newTab = getters.formatTab({ tableId, tab })
    sync.record('tabOpened', newTab)
    return newTab.id
  },
  async openTabWithCustomer({ getters }, { tableId, tab, customer }) {
    let newTab = getters.formatTab({ tableId, tab })
    sync.record('tabOpenedWithCustomer', { tab: newTab, customer })
    return newTab.id
  },
  addProduct(_, { tabId, seat, product }) {
    const newProduct = {
      parentProduct: product.parentProduct,
      id: product.id || uuid4(),
      name: product.name,
      price: product.price,
      fullPrice: product.fullPrice,
      finalPrice: product.finalPrice,
      discountType: product.discountType || null,
      discountAmount: product.discountAmount || null,
      discountConcept: product.discountConcept || null,
      promotionId: product.promotionId || null,
      pointsExpense: product.pointsExpense || 0,
      modifiers: product.modifiers,
      comboProducts: product.comboProducts,
      comments: product.comments,
      quantity: product.quantity || 1,
      tab: tabId,
      course: product.course || 'Main',
      categoryId: product.categoryId,
      combine: product.combine || false,
      seat
    }
    sync.record('productAdded', { tabId, seat, product: newProduct })
    EventBus.$emit('tabProductsUpdated', tabId)
  },

  moveProductTab({ state }, { productId, fromTabId, toTabId }) {
    const product = state.products[productId]
    sync.record('productTabMoved', { product, fromTabId, toTabId })

    let products = state.tabs[fromTabId].seats.reduce((acc, seat) => {
      return acc + seat.length
    }, state.tabs[fromTabId].shared.length)
    if (products === 0) {
      sync.record('tabClosedWithInfo', {
        tabId: fromTabId,
        closeTime: new Date(),
        closedWithPin: false
      })
    }
    EventBus.$emit('tabProductsUpdated', fromTabId)
    EventBus.$emit('tabProductsUpdated', toTabId)
  },
  moveProduct({ state }, { tabId, seat, position, productId }) {
    const product = state.products[productId]
    sync.record('productMoved', { tabId, seat, position, product })
  },
  setPreferredPaymentMethod(_, { tabId, preferredPaymentMethod }) {
    sync.record('preferredPaymentMethodSelected', {
      tabId,
      preferredPaymentMethod
    })
  },
  updateProductModifiers(
    _,
    { productId, modifiers, comments, productPricing }
  ) {
    sync.record('productModifiersUpdated', {
      productId,
      modifiers,
      comments,
      productPricing
    })
  },
  updateComboProducts(_, { comboId, products, productPricing }) {
    sync.record('comboProductsUpdated', {
      comboId,
      products,
      productPricing
    })
  },
  splitProduct({ state, dispatch, getters }, { productId, seat }) {
    let product = state.products[productId]
    if (product.quantity < 2) {
      return
    }
    dispatch('updateProductQuantity', {
      productId,
      quantity: product.quantity - 1
    })
    let splittedProductId = uuid4()
    let splittedProduct = {
      ...product,
      modifiers: product.modifiers?.map(m => {
        return {
          ...m,
          id: uuid4()
        }
      }),
      comboProducts: product.comboProducts?.map(cp => {
        return {
          ...cp,
          id: uuid4(),
          modifiers: cp.modifiers?.map(m => {
            return {
              ...m,
              id: uuid4()
            }
          })
        }
      }),
      id: splittedProductId,
      quantity: 1,
      promotionId: null
    }
    dispatch('addProduct', {
      tabId: product.tab,
      seat: seat,
      product: splittedProduct
    })

    let kitchenOrderIds = getters.getProductKitchenOrderIds(productId)
    kitchenOrderIds
      .map(kitchenOrderId => state.kitchenOrders[kitchenOrderId])
      .forEach(order => {
        let version = Orders.generateOrderVersionForSplittedProduct(
          order.versions.slice(-1)[0].products,
          productId,
          splittedProduct
        )
        sync.record('kitchenOrderVersionAdded', {
          orderId: order.id,
          version
        })
      })

    return splittedProductId
  },
  updateProductQuantity({ state }, { productId, quantity }) {
    sync.record('productQuantityUpdated', { productId, quantity })
    let tabId = state.products[productId].tab
    EventBus.$emit('tabProductsUpdated', tabId)
  },
  updateProductDiscount(_, { productId, discount, productPricing }) {
    sync.record('productDiscountUpdated', {
      productId,
      discount,
      productPricing
    })
  },

  removeProduct({ state }, productId) {
    let tabId = state.products[productId].tab
    sync.record('productRemoved', productId)
    EventBus.$emit('tabProductsUpdated', tabId)
  },
  updateDeliveryOrderStatus(_, { tabId, newStatus, courier }) {
    sync.record('deliveryOrderStatusUpdated', {
      tabId,
      newStatus,
      courier,
      date: new Date()
    })
  },
  removeSeat(_, { tabId, selectedSeatIndex }) {
    sync.record('seatRemoved', { tabId, selectedSeatIndex })
  },
  addBill({ dispatch, state }, { tabId, bill }) {
    if (!state.bills[bill.id]) {
      if (state.tabs[tabId].deliveryOrder?.status === 'CREATED') {
        sync.record('deliveryOrderStatusUpdated', {
          tabId,
          newStatus: 'KITCHEN',
          date: new Date()
        })
      }
      if (bill.discount?.promotionId) {
        sync.record('promotionRedeemed', {
          promotionId: bill.discount?.promotionId
        })
      }
      sync.record('billAdded', { tabId, bill })
      dispatch('startBilling', tabId)
    }
  },
  generatePendingBill(
    { getters, dispatch },
    { tabId, discount, bill = undefined, payments = [] }
  ) {
    if (!bill) bill = getters.getPendingBill(tabId)
    if (bill && discount) {
      bill.discount = discount
    }
    if (bill?.products.length > 0) {
      dispatch('addBill', { tabId, bill })
      for (const payment of payments) {
        dispatch('addPayment', { ...payment, billId: bill.id, tabId })
      }
    }
  },
  removeTabBills({ state }, tabId) {
    let tab = state.tabs[tabId]
    tab.bills.forEach(billId => {
      sync.record('billRemoved', { tabId, billId })
    })
  },
  async removeBill({ state }, { tabId, billId }) {
    let hasPayments = state.bills[billId].payments.some(paymentId => {
      return state.payments[paymentId].deleted !== true
    })
    if (hasPayments) {
      notification.create({
        title: i18n.t('tabs.remove-bill-error'),
        subtitle: i18n.t('tabs.refundable-payments-error'),
        icon: 'close',
        iconColor: 'red'
      })
      return
    }
    let totalCashMachineAmount = state.bills[billId].payments
      .map(paymentId => {
        return state.payments[paymentId]
      })
      .filter(payment => CashMachine.methods.includes(payment.type))
      .reduce((sum, payment) => sum + payment.amount, 0)
    if (totalCashMachineAmount) {
      await CashMachine.payOut(totalCashMachineAmount)
    }
    sync.record('billRemoved', { tabId, billId })
  },
  addPayment(
    { getters },
    { billId, paymentId, amount, change, tip, type, tillId, metadata, tabId }
  ) {
    if (amount == null || isNaN(amount)) {
      const error = new Error('Amount is required')
      Logger.info('paymentAdded with no amount', {
        billId,
        paymentId,
        amount,
        wasNull: amount === null,
        wasUndefined: amount === undefined,
        wasNaN: isNaN(amount),
        change,
        tip,
        type,
        tillId,
        metadata,
        tabId,
        stackTrace: error.stack
      })
    }
    let payment = {
      id: paymentId || uuid4(),
      amount,
      change,
      tip,
      type,
      creationTime: new Date(),
      metadata
    }

    if (tillId) {
      payment.tillId = tillId
    } else {
      payment.tillId = getters.getPaymentTillId(tabId, type)
    }
    sync.record('paymentAdded', { billId, payment })
  },
  async deletePayment({ state }, { billId, paymentId }) {
    let payment = state.payments[paymentId]
    let bill = state.bills[billId]
    if (CashMachine.methods.includes(payment.type)) {
      await CashMachine.payOut(payment.amount)
    }
    if (payment.type === 'dataphone') {
      let { error } = await Dataphone.refund(payment, bill.tabId)
      if (error) {
        notification.create({
          title: i18n.t('tabs.refund-payment-error'),
          icon: 'close',
          iconColor: 'red'
        })
        return
      }
    }
    sync.record('paymentDeleted', { billId, paymentId })
  },
  closeTab({ state, dispatch }, { tabId, closedWithPin }) {
    sync.record('tabClosedWithInfo', {
      tabId,
      closeTime: new Date(),
      closedWithPin
    })
    let tab = state.tabs[tabId]
    if (tab && tab.pickupType === 'ownDelivery' && tab.deliveryOrder) {
      dispatch('updateDeliveryOrderStatus', {
        tabId,
        newStatus: 'CLOSED'
      })
    }
  },
  reopenTab(_, { tabId }) {
    sync.record('tabReopened', {
      tabId
    })
  },
  createKitchenOrderByProducts(
    { rootGetters, state, getters, rootState },
    { tabId, products }
  ) {
    const kitchenProducts = getters
      .parseKitchenProducts(tabId, products)
      .flatMap(product => {
        if (product.comboProducts) {
          return product.comboProducts.map(comboProduct => ({
            ...comboProduct,
            quantity: comboProduct.quantity * product.quantity,
            fromCombo: true,
            combo: product
          }))
        } else {
          return [product]
        }
      })

    const printers = Object.values(rootState.config.config.printers)
    let orderVirtualBrandName = rootGetters['config/virtualBrands'].filter(
      vb => vb.id === state.tabs[tabId].virtualBrandId
    )[0]?.name
    let orders = Orders.generateOrders(
      kitchenProducts,
      state.tabs[tabId],
      state.tabs[tabId].deliveryOrder,
      state.tabs[tabId].tableName,
      rootGetters['auth/currentEmployee'].name,
      printers,
      orderVirtualBrandName
    )
    return orders.map(order => {
      sync.record('kitchenOrderAdded', { tabId, order })
      return order.id
    })
  },
  createKitchenOrders(
    { rootGetters, state, getters, rootState },
    { tabId, course }
  ) {
    let products = getters.parseKitchenProducts(
      tabId,
      getters
        .getUnsentProducts(tabId)
        .filter(product => (course === 'all') | (product.course === course))
    )
    const printers = Object.values(rootState.config.config.printers)
    let orderVirtualBrandName = rootGetters['config/virtualBrands'].filter(
      vb => vb.id === state.tabs[tabId].virtualBrandId
    )[0]?.name
    let orders = Orders.generateOrders(
      products,
      state.tabs[tabId],
      state.tabs[tabId].deliveryOrder,
      state.tabs[tabId].tableName,
      rootGetters['auth/currentEmployee'].name,
      printers,
      orderVirtualBrandName
    )

    let tab = state.tabs[tabId]
    if (tab && tab.deliveryOrder?.status === 'CREATED') {
      sync.record('deliveryOrderStatusUpdated', {
        tabId,
        newStatus: 'KITCHEN',
        date: new Date()
      })
    }
    return orders.map(order => {
      sync.record('kitchenOrderAdded', { tabId, order })
      return order.id
    })
  },
  addKitchenOrderVersion({ state, getters }, orderId) {
    let order = state.kitchenOrders[orderId]
    let productIds = order.versions
      .slice(-1)[0]
      .products.map(product => product.tabProductId)
    let products = getters.parseKitchenProducts(
      order.tabId,
      getters
        .getAllProducts(order.tabId)
        .flatMap(product => {
          if (product.comboProducts && product.comboProducts.length > 0) {
            return product.comboProducts.map(comboProduct => ({
              ...comboProduct,
              quantity: comboProduct.quantity * product.quantity,
              fromCombo: true,
              combo: product
            }))
          } else {
            return [product]
          }
        })
        .filter(product => productIds.includes(product.id))
    )
    let version = Orders.generateOrderVersion(products)
    sync.record('kitchenOrderVersionAdded', { orderId, version })
    return order.id
  },
  async refreshCurrentTabs({ commit, dispatch }, tabs) {
    let tabsData = normalizeTabs(tabs)
    commit('replaceTabs', tabsData)
    dispatch('updateTabsActivationTime', {
      ...state.tabs,
      ...(tabsData && tabsData.tabs)
    })
  },
  async addFullTab({ commit, rootState, dispatch }, tab) {
    let config = rootState.config
    let isMaster = config.device.mode === 'master'
    if (isMaster && tab.activationTime) {
      let sound = new Howl({
        src: ['/notification.mp3']
      })
      sound.play()
      if (config.config.autoprintKitchenOrders) {
        tab.kitchenOrders.forEach(order => {
          TicketPrinter.printKitchenOrder(order)
        })
      }
      if (config.config.autoprintBills) {
        tab.bills.forEach(bill => {
          TicketPrinter.printBill(bill)
        })
      }
    }
    let tabData = normalizeTab(tab)
    commit('refreshTab', tabData)
    dispatch('updateTabsActivationTime', {
      ...state.tabs,
      ...(tabData && tabData.tabs)
    })
  },
  printOldKitchenOrders({ rootState, state }) {
    let config = rootState.config
    let now = new Date()
    let minSeconds = 120
    let maxSeconds = 20 * 60
    let oldOrders = Object.values(state.kitchenOrders)
      .filter(order => !order.printedTime)
      .filter(order => {
        let tab = state.tabs[order.tabId]
        return (
          tab &&
          (tab.source === 'Restaurant' || config.config.autoprintKitchenOrders)
        )
      })
      .filter(order => {
        let diff = (now - new Date(order.creationTime)) / 1000
        return diff > minSeconds && diff < maxSeconds
      })
    oldOrders.forEach(order => TicketPrinter.printKitchenOrder(order))
  },
  printOldBills({ rootState, state }) {
    let config = rootState.config
    let now = new Date()
    let minSeconds = 120
    let maxSeconds = 20 * 60
    let oldBills = Object.values(state.bills)
      .filter(bill => !bill.printedTime)
      .filter(bill => {
        let tab = state.tabs[bill.tabId]
        return (
          tab && (tab.source === 'Restaurant' || config.config.autoprintBills)
        )
      })
      .filter(bill => {
        let diff = (now - new Date(bill.creationTime)) / 1000
        return diff > minSeconds && diff < maxSeconds
      })
    oldBills.forEach(bill => TicketPrinter.printBill(bill))
  },
  assignTables({ state, getters }, { tabId, tables }) {
    let tab = state.tabs[tabId]
    sync.record('tablesAssigned', {
      tabId,
      tables,
      tableName: getters.getTableName(tab.name, tab.lang, tables)
    })
  },
  updateCourse({ state }, { productId, course, comboProductId }) {
    course = course || 'Main'
    if (!comboProductId) {
      sync.record('courseUpdated', { productId, course })
    } else {
      const comboProducts = [...state.products[productId].comboProducts].map(
        comboProduct => ({
          ...comboProduct,
          course:
            comboProduct.id === comboProductId ? course : comboProduct.course
        })
      )

      sync.record('comboProductsUpdated', {
        comboId: productId,
        products: comboProducts,
        productPricing: {
          fullPrice: state.products[productId].fullPrice,
          finalPrice: state.products[productId].finalPrice
        }
      })
    }
  },
  addSeat(_, tabId) {
    sync.record('seatAdded', tabId)
  },
  mergeTabs({ state, getters }, { tabAId, tabBId }) {
    let tabA = state.tabs[tabAId]
    let tabB = state.tabs[tabBId]
    let newTab = {
      id: uuid4(),
      open: true,
      source: 'Restaurant',
      lang: tabA.lang,
      name: [tabA.name, tabB.name].filter(name => name).join(' '),
      pickupType: tabA.pickupType || tabB.pickupType,
      creationTime: new Date(
        Math.min(new Date(tabA.creationTime), new Date(tabB.creationTime))
      ),
      activationTime: new Date(
        Math.min(new Date(tabA.activationTime), new Date(tabB.activationTime))
      ),
      tableName: getters.getTableName(
        [tabA.name, tabB.name].filter(name => name).join(' '),
        tabA.lang,
        [...tabA.tables, ...tabB.tables]
      ),
      terraceSurchargePercentage:
        Math.max(
          tabA.terraceSurchargePercentage,
          tabB.terraceSurchargePercentage
        ) || 0,
      virtualBrandId: tabA.virtualBrandId || tabB.virtualBrandId
    }
    sync.record('mergeTabs', {
      tabAId,
      tabBId,
      newTab
    })
  },
  resolveShipmentError(_, tabId) {
    sync.record('shipmentErrorResolved', { tabId })
  },
  regenerateBill({ state }, { tabId, billId, customerCompany }) {
    let bill = state.bills[billId]
    if (!!bill.customerCompany !== !!customerCompany) {
      bill = Bills.generateInvoice(bill, customerCompany)
    } else {
      bill = {
        ...bill,
        customerCompany
      }
    }
    sync.record('billRegenerated', { tabId, oldBillId: billId, bill })
    return bill.id
  },
  startBilling({ state }, tabId) {
    if (!state.tabs[tabId].billingStartedTime) {
      sync.record('billingStarted', { tabId, timestamp: new Date() })
    }
  },
  deleteOldTabs({ state, commit, rootState }) {
    let now = new Date()
    let minSeconds = 60 * 60 * 24
    let featureToggles = rootState.config.config.featureToggles
    if (featureToggles['fast_tab_deletion']) {
      minSeconds = 60 * 60 * 4
    }
    let oldTabs = Object.values(state.tabs)
      .filter(tab => tab.closeTime)
      .filter(tab => {
        let diff = (now - new Date(tab.closeTime)) / 1000
        return diff > minSeconds
      })
    oldTabs.forEach(tab => commit('deleteTabLocally', { tabId: tab.id }))
  },
  async activateTab(
    { state, rootState, commit, getters, dispatch },
    { tab, sendEvent = false }
  ) {
    const config = rootState.config
    const _tab = {
      ...tab,
      activationTime: moment(tab.schedulingTime).add(
        -config.config.preparationMinutes,
        'minutes'
      )
    }
    if (sendEvent) {
      sync.record('updateTabActivationTime', {
        tabId: _tab.id,
        activationTime: new Date()
      })
      if (config.config.autoprintKitchenOrders) {
        await dispatch('createKitchenOrders', {
          tabId: _tab.id,
          course: 'all'
        })
        state.tabs[_tab.id].kitchenOrders
          .map(orderId => state.kitchenOrders[orderId])
          .forEach(order => TicketPrinter.printKitchenOrder(order))
      }
      if (
        state.tabs[_tab.id].kitchenOrders.length > 0 &&
        tab &&
        tab.deliveryOrder?.status === 'CREATED'
      ) {
        sync.record('deliveryOrderStatusUpdated', {
          tabId: tab.id,
          newStatus: 'KITCHEN',
          date: new Date()
        })
      }
      if (config.config.autoprintBills) {
        await dispatch('generatePendingBill', { tabId: _tab.id })
        let bills = getters.getBills(_tab.id)
        bills.forEach(TicketPrinter.printBill)
      }
    } else {
      commit('updateTabActivationTime', {
        tabId: _tab.id,
        activationTime: new Date()
      })
    }
  },
  updateTabsActivationTime({
    state,
    getters,
    rootState,
    dispatch,
    rootGetters
  }) {
    if (!rootGetters['till/shiftIsStarted'] && rootState.till.shiftsEnabled)
      return

    const now = moment()
    const config = rootState.config

    for (const tab of Object.values(state.tabs)) {
      try {
        if (!tab.schedulingTime || !!tab.activationTime) continue

        const marginTime = getters.getActivationMarginPerVirtualBrandId(
          tab.virtualBrandId
        )
        const activationTime = moment(
          tab.deliveryOrder?.pickupTime || tab.schedulingTime
        ).subtract(marginTime, 'minutes')

        if (now.isAfter(activationTime)) {
          dispatch('activateTab', {
            tab,
            sendEvent: config.device.mode === 'master' && !!config.device.id
          })
        }
      } catch (e) {
        Logger.error('Error updating tab activation time', e)
      }
    }
  },
  tabDetailsUpdated({ commit }, tab) {
    commit('updateTabDetails', tab)
  },
  updateTerraceSurchargePercentage({ rootState, state }, { tabId, enabled }) {
    let table = rootState.tables.tables[state.tabs[tabId].tables[0]]
    if (enabled) {
      sync.record('terraceSurchargePercentageUpdated', {
        tabId,
        newPercentage: table.terraceSurchargePercentage
      })
    } else {
      sync.record('terraceSurchargePercentageUpdated', {
        tabId,
        newPercentage: 0
      })
    }
  },
  updateTabOrdering(_, { tabId, orderingMode }) {
    sync.record('tabOrderingUpdated', { tabId, orderingMode })
  }
}

const mutations = {
  openTab(state, tab) {
    let shared = tab.shared || []
    const newTab = {
      ...tab,
      shared: shared.map(product => product.id),
      seats: Array.from({ length: tab.seats }, () => []),
      bills: [],
      kitchenOrders: []
    }
    const products = shared.reduce((res, product) => {
      res[product.id] = product
      return res
    }, {})
    state.products = { ...state.products, ...products }

    Vue.set(state.tabs, newTab.id, newTab)
    localDb.setItem('tabs:tabs', newTab.id, newTab)
  },
  removeSeat(state, { tabId, selectedSeatIndex }) {
    Vue.delete(state.tabs[tabId].seats, selectedSeatIndex)
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  replaceTabs(state, tabData) {
    state.products = (tabData && tabData.products) || {}
    state.tabs = (tabData && tabData.tabs) || {}
    state.bills = (tabData && tabData.bills) || {}
    state.payments = (tabData && tabData.payments) || {}
    state.kitchenOrders = (tabData && tabData.kitchenOrders) || {}
    localDb.updateTables({
      'tabs:products': state.products,
      'tabs:tabs': state.tabs,
      'tabs:bills': state.bills,
      'tabs:payments': state.payments,
      'tabs:kitchenOrders': state.kitchenOrders
    })
  },
  refreshTab(state, tabData) {
    state.products = { ...state.products, ...tabData.products }
    state.tabs = { ...state.tabs, ...tabData.tabs }
    state.bills = { ...state.bills, ...tabData.bills }
    state.payments = { ...state.payments, ...tabData.payments }
    state.kitchenOrders = { ...state.kitchenOrders, ...tabData.kitchenOrders }

    localDb.patchTables({
      'tabs:products': tabData.products || {},
      'tabs:tabs': tabData.tabs || {},
      'tabs:bills': tabData.bills || {},
      'tabs:payments': tabData.payments || {},
      'tabs:kitchenOrders': tabData.kitchenOrders || {}
    })
  },
  addProduct(state, { tabId, seat, product }) {
    if (seat != null) {
      Vue.set(state.products, product.id, product)
      state.tabs[tabId].seats[seat].push(product.id)
    } else {
      Vue.set(state.products, product.id, product)
      state.tabs[tabId].shared.push(product.id)
    }
    localDb.setItem('tabs:products', product.id, product).then(() => {
      localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
    })
  },
  removeProduct(state, productId) {
    let tabId = state.products[productId].tab
    Vue.delete(state.products, productId)
    state.tabs[tabId].shared = state.tabs[tabId].shared.filter(
      p => p != productId
    )
    state.tabs[tabId].seats = state.tabs[tabId].seats.map(seat =>
      seat.filter(p => p != productId)
    )
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId]).then(() => {
      localDb.removeItem('tabs:products', productId)
    })
  },
  updateProductModifiers(
    state,
    { productId, modifiers, comments, productPricing }
  ) {
    state.products[productId].modifiers = modifiers
    state.products[productId].comments = comments
    state.products[productId].fullPrice = productPricing.fullPrice
    state.products[productId].finalPrice = productPricing.finalPrice
    localDb.setItem('tabs:products', productId, state.products[productId])
  },
  updateDeliveryOrderStatus(state, { tabId, newStatus, courier, date }) {
    state.tabs[tabId].deliveryOrder.status = newStatus
    state.tabs[tabId].deliveryOrder.deliveryOrderStatuses.push({
      status: newStatus,
      tabId,
      creationTime: date
    })
    if (courier) {
      state.tabs[tabId].deliveryOrder.courierName = courier.name
      state.tabs[tabId].deliveryOrder.courierPhoneNumber = courier.phoneNumber
      state.tabs[tabId].deliveryOrder.courierId = courier.id
    }
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  updateDeliveryOrderCourierPhoneNumber(state, { tabId, courierPhoneNumber }) {
    state.tabs[tabId].deliveryOrder.courierPhoneNumber = courierPhoneNumber
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  setPreferredPaymentMethod(state, { tabId, preferredPaymentMethod }) {
    if (state.tabs[tabId] && state.tabs[tabId].deliveryOrder) {
      state.tabs[tabId].deliveryOrder = {
        ...state.tabs[tabId].deliveryOrder,
        preferredPaymentMethod
      }

      localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])

      const tabBills = state.tabs[tabId].bills.map(
        billId => state.bills[billId]
      )
      tabBills.forEach(bill => {
        bill = {
          ...bill,
          preferredPaymentMethod
        }
        localDb.setItem('tabs:bills', bill.id, state.bills[bill.id])
      })
    }
  },
  updateProductQuantity(state, { productId, quantity }) {
    state.products[productId].quantity = quantity
    localDb.setItem('tabs:products', productId, state.products[productId])
  },
  updateProductDiscount(state, { productId, discount, productPricing }) {
    state.products[productId] = {
      ...state.products[productId],
      ...discount,
      ...productPricing
    }
    localDb.setItem('tabs:products', productId, state.products[productId])
  },
  updateBills(state, { tabId, bills }) {
    state.tabs[tabId].bills.forEach(billId => Vue.delete(state.bills, billId))
    bills.forEach(bill => Vue.set(state.bills, bill.id, bill))
    state.tabs[tabId].bills = bills.map(bill => bill.id)
    Promise.all(
      bills.map(bill => localDb.setItem('tabs:bills', bill.id, bill))
    ).then(() => {
      localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
    })
  },
  addBill(state, { tabId, bill }) {
    Vue.set(state.bills, bill.id, bill)
    state.tabs[tabId].bills.push(bill.id)
    localDb.setItem('tabs:bills', bill.id, bill).then(() => {
      localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
    })
  },
  removeBill(state, { tabId, billId }) {
    Vue.delete(state.bills, billId)
    state.tabs[tabId].bills = state.tabs[tabId].bills.filter(id => id != billId)
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId]).then(() => {
      localDb.removeItem('tabs:bills', billId)
    })
  },
  addPayment(state, { billId, payment }) {
    Vue.set(state.payments, payment.id, payment)
    state.bills[billId].payments.push(payment.id)
    localDb.setItem('tabs:payments', payment.id, payment).then(() => {
      localDb.setItem('tabs:bills', billId, state.bills[billId])
    })
  },
  deletePayment(state, { billId, paymentId }) {
    Vue.delete(state.payments, paymentId)
    state.bills[billId].payments = state.bills[billId].payments.filter(
      id => id != paymentId
    )
    localDb.setItem('tabs:bills', billId, state.bills[billId]).then(() => {
      localDb.removeItem('tabs:payments', paymentId)
    })
  },
  closeTab(state, { tabId, closeTime }) {
    state.tabs[tabId].open = false
    state.tabs[tabId].closeTime = closeTime
    if (!state.tabs[tabId].activationTime) {
      state.tabs[tabId].activationTime = closeTime
    }
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  reopenTab(state, { tabId }) {
    state.tabs[tabId].open = true
    state.tabs[tabId].closeTime = null
    state.tabs[tabId].closedWithPin = false
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  addKitchenOrder(state, { tabId, order }) {
    Vue.set(state.kitchenOrders, order.id, order)
    state.tabs[tabId].kitchenOrders.push(order.id)
    localDb.setItem('tabs:kitchenOrders', order.id, order).then(() => {
      localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
    })
  },
  addKitchenOrderVersion(state, { orderId, version }) {
    state.kitchenOrders[orderId].versions.push(version)
    localDb.setItem('tabs:kitchenOrders', orderId, state.kitchenOrders[orderId])
  },
  assignTables(state, { tabId, tables, tableName }) {
    state.tabs[tabId].tables = tables
    state.tabs[tabId].tableName = tableName
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  updateCourse(state, { productId, course }) {
    state.products[productId].course = course
    localDb.setItem('tabs:products', productId, state.products[productId])
  },
  addSeat(state, tabId) {
    state.tabs[tabId].seats = [...state.tabs[tabId].seats, []]
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  kitchenOrderPrinted(state, { orderId, printedTime }) {
    if (state.kitchenOrders[orderId]) {
      Vue.set(state.kitchenOrders[orderId], 'printedTime', printedTime)
      localDb.setItem(
        'tabs:kitchenOrders',
        orderId,
        state.kitchenOrders[orderId]
      )
    }
  },
  billPrinted(state, { billId, printedTime }) {
    if (state.bills[billId]) {
      state.bills[billId].printedTime = printedTime
      localDb.setItem('tabs:bills', billId, state.bills[billId])
    }
  },
  mergeTabs(state, { tabAId, tabBId, newTab }) {
    let tabA = state.tabs[tabAId]
    let tabB = state.tabs[tabBId]
    let mergedTab = {
      ...newTab,
      tables: [...new Set([...tabA.tables, ...tabB.tables])],
      seats: [...tabA.seats, ...tabB.seats],
      shared: [...tabA.shared, ...tabB.shared],
      bills: [...tabA.bills, ...tabB.bills],
      kitchenOrders: [...tabA.kitchenOrders, ...tabB.kitchenOrders]
    }
    Vue.set(state.tabs, mergedTab.id, mergedTab)
    localDb.setItem('tabs:tabs', mergedTab.id, mergedTab)
    let oldIds = [tabA.id, tabB.id]
    Object.values(state.bills)
      .filter(bill => oldIds.includes(bill.tabId))
      .forEach(bill => (bill.tabId = mergedTab.id))
    Object.values(state.kitchenOrders)
      .filter(order => oldIds.includes(order.tabId))
      .forEach(order => (order.tabId = mergedTab.id))
    Object.values(state.products)
      .filter(product => oldIds.includes(product.tab))
      .forEach(product => (product.tab = mergedTab.id))
    oldIds.forEach(id => {
      Vue.delete(state.tabs, id)
      localDb.removeItem('tabs:tabs', id)
    })
  },
  cancelTab(state, { tabId, cancelTime }) {
    state.tabs[tabId].deliveryOrder.cancelTime = cancelTime
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  setFailedShipment(state, { tabId, errorTime, errorMessage }) {
    Vue.set(state.tabs[tabId].deliveryOrder, 'errorTime', errorTime)
    Vue.set(state.tabs[tabId].deliveryOrder, 'errorMessage', errorMessage)
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  resolveShipmentError(state, { tabId }) {
    Vue.set(state.tabs[tabId].deliveryOrder, 'errorResolved', true)
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  regenerateBill(state, { tabId, oldBillId, bill }) {
    if (oldBillId === bill.id) {
      state.bills[bill.id] = bill
    } else {
      Vue.delete(state.bills, oldBillId)
      localDb.removeItem('tabs:bills', oldBillId)
      state.tabs[tabId].bills = state.tabs[tabId].bills.filter(
        id => id != oldBillId
      )
      Vue.set(state.bills, bill.id, bill)
      localDb.setItem('tabs:bills', bill.id, bill)
      state.tabs[tabId].bills.push(bill.id)
      localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
    }
  },
  finalizeBill(state, { billId, number }) {
    state.bills[billId].number = number
    localDb.setItem('tabs:bills', billId, state.bills[billId])
  },
  updateMetadata(state, { billId, metadata }) {
    state.billsMetadata[billId] = {
      ...(state.billsMetadata[billId] || {}),
      ...metadata
    }
    if (!state.bills[billId]) {
      return
    }
    state.bills[billId].metadata = {
      ...metadata,
      ...state.bills[billId].metadata
    }
    localDb.setItem('tabs:bills', billId, state.bills[billId])
  },
  moveProduct(state, { tabId, seat, product }) {
    state.tabs[tabId].shared = state.tabs[tabId].shared.filter(
      productId => productId != product.id
    )
    state.tabs[tabId].seats = state.tabs[tabId].seats.map(seat =>
      seat.filter(productId => productId != product.id)
    )
    if (seat != null) {
      state.tabs[tabId].seats[seat].push(product.id)
    } else {
      state.tabs[tabId].shared.push(product.id)
    }
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  moveProductTab(state, { fromTabId, toTabId, product }) {
    state.products[product.id].tab = toTabId
    state.tabs[fromTabId].shared = state.tabs[fromTabId].shared.filter(
      productId => productId != product.id
    )
    state.tabs[fromTabId].seats = state.tabs[fromTabId].seats.map(seat =>
      seat.filter(productId => productId != product.id)
    )
    state.tabs[toTabId].shared.push(product.id)
    if (state.tabs[toTabId].seats.length === 0) {
      state.tabs[toTabId].seats.push([]) //ensure at least one seat per tab
    }

    localDb.setItem('tabs:tabs', fromTabId, state.tabs[fromTabId]).then(() => {
      localDb.setItem('tabs:tabs', toTabId, state.tabs[toTabId])
    })
  },
  startBilling(state, { tabId, timestamp }) {
    state.tabs[tabId].billingStartedTime = timestamp
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  updateComboProducts(state, { comboId, products, productPricing }) {
    state.products[comboId].comboProducts = products
    state.products[comboId].fullPrice = productPricing.fullPrice
    state.products[comboId].finalPrice = productPricing.finalPrice
    localDb.setItem('tabs:products', comboId, state.products[comboId])
  },
  deleteTabLocally(state, { tabId }) {
    let tab = state.tabs[tabId]
    Vue.delete(state.tabs, tabId)
    localDb.removeItem('tabs:tabs', tabId)
    tab.bills.forEach(billId => {
      let bill = state.bills[billId]
      bill.payments.forEach(paymentId => {
        Vue.delete(state.payments, paymentId)
        localDb.removeItem('tabs:payments', paymentId)
      })
      Vue.delete(state.bills, billId)
      Vue.delete(state.billsMetadata, billId)
      localDb.removeItem('tabs:bills', billId)
    })
    tab.kitchenOrders.forEach(orderId => {
      Vue.delete(state.kitchenOrders, orderId)
      localDb.removeItem('tabs:kitchenOrders', orderId)
    })
    tab.shared.forEach(productId => {
      Vue.delete(state.products, productId)
      localDb.removeItem('tabs:products', productId)
    })
    tab.seats.flat().forEach(productId => {
      Vue.delete(state.products, productId)
      localDb.removeItem('tabs:products', productId)
    })
  },
  updateTabActivationTime(state, { tabId, activationTime }) {
    Vue.set(state.tabs[tabId], 'activationTime', activationTime)
    let tab = state.tabs[tabId]
    state.tabs[tabId].bills.forEach(billId => {
      let bill = state.bills[billId]
      state.bills[billId] = {
        ...bill,
        activationTime,
        schedulingTime: tab?.schedulingTime
      }
    })
    state.tabs[tabId].kitchenOrders.forEach(orderId => {
      let order = state.kitchenOrders[orderId]
      state.kitchenOrders[orderId] = { ...order, activationTime }
    })
    localDb?.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  updateTabDetails(
    state,
    {
      kitchenNote,
      customerNote,
      schedulingTime,
      activationTime,
      customerInfo,
      deliveryOrder,
      id: tabId
    }
  ) {
    const oldTab = state.tabs[tabId]
    Vue.set(state.tabs, tabId, {
      ...oldTab,
      schedulingTime,
      activationTime,
      customerInfo,
      deliveryOrder,
      kitchenNote,
      customerNote
    })
    state.tabs[tabId].bills.forEach(billId => {
      Vue.set(state.bills[billId], 'schedulingTime', schedulingTime)
      Vue.set(state.bills[billId], 'activationTime', activationTime)
    })
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  addTabCode(state, { tabId, code }) {
    Vue.set(state.tabs[tabId], 'code', code)
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  updateTabCustomerId(state, { tabId, customerId }) {
    Vue.set(state.tabs[tabId], 'customerId', customerId)
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  updateTabCustomerInfo(state, { tabId, customerId, customerInfo }) {
    Vue.set(state.tabs[tabId], 'customerId', customerId)
    Vue.set(state.tabs[tabId], 'customerInfo', customerInfo)
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  updatePickupTime(state, { tabId, pickupTime }) {
    if (state.tabs[tabId].deliveryOrder) {
      Vue.set(state.tabs[tabId].deliveryOrder, 'pickupTime', pickupTime)
      localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
    }
  },
  updateDeliveryOrderShipment(state, { tabId, shipment }) {
    if (state.tabs[tabId].deliveryOrder) {
      Vue.set(state.tabs[tabId].deliveryOrder, 'shipmentId', shipment.id)
      Vue.set(state.tabs[tabId].deliveryOrder, 'shipment', shipment)
      Vue.set(state.tabs[tabId].deliveryOrder, 'shipmentCancelled', false)
      localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
    }
  },
  updateDeliveryOrderAddress(
    state,
    { tabId, address, latitude, longitude, postalCode }
  ) {
    if (state.tabs[tabId].deliveryOrder) {
      Vue.set(state.tabs[tabId].deliveryOrder, 'address', address)
      Vue.set(state.tabs[tabId].deliveryOrder, 'latitude', latitude)
      Vue.set(state.tabs[tabId].deliveryOrder, 'longitude', longitude)
      Vue.set(state.tabs[tabId].deliveryOrder, 'postalCode', postalCode)
      localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
    }
  },
  cancelDelivery(state, { tabId }) {
    if (state.tabs[tabId].deliveryOrder) {
      Vue.set(state.tabs[tabId].deliveryOrder, 'shipmentCancelled', true)
      Vue.set(state.tabs[tabId].deliveryOrder, 'shipment', null)
      Vue.set(state.tabs[tabId].deliveryOrder, 'shipmentId', null)
    }
  },
  updateTerraceSurchargePercentage(state, { tabId, newPercentage }) {
    state.tabs[tabId].terraceSurchargePercentage = newPercentage
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  },
  updateTabOrdering(state, { tabId, orderingMode }) {
    state.tabs[tabId].orderingMode = orderingMode
    localDb.setItem('tabs:tabs', tabId, state.tabs[tabId])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
