<template>
  <h2 class="title text-blue">{{ title }}</h2>
</template>

<script>
export default {
  name: 'LModalSectionTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: auto;
  font-family: 'Brandon Text';
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 20px;
}
</style>
