var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{on:{"click":_vm.editDiscount}},[_vm._t("default")],2),(_vm.showModal)?_c('portal',{attrs:{"to":"appRoot"}},[_c('l-modal',{attrs:{"title":_vm.$t('bill-discount.title'),"full-height":""},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',[_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"flex justify-between"},[_c('p',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(_vm.$t('bill-discount.select-discount-type')))]),(_vm.hasAlreadyDiscount)?_c('icon',{staticClass:"text-red",attrs:{"name":"trash"},on:{"click":_vm.removeDiscount}}):_vm._e()],1),_c('l-select',{attrs:{"optionValue":"type","optionLabel":"label","value":_vm.editingDiscount.type,"options":_vm.discountTypes},on:{"input":_vm.updateDiscountType}})],1),(_vm.editingDiscount.type !== 'promotion')?_c('section',[_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(_vm.$t('bill-discount.discount-to-apply')))]),_c('l-input',{attrs:{"icon":_vm.iconName},model:{value:(_vm.editingDiscount.amount),callback:function ($$v) {_vm.$set(_vm.editingDiscount, "amount", $$v)},expression:"editingDiscount.amount"}})],1)]):_c('section',[_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(_vm.$t('bill-discount.points-discounts'))+" ")]),_c('div',{staticClass:"flex flex-wrap w-full gap-6"},_vm._l((_vm.promotionsWithPoints),function(promotion){return _c('div',{key:promotion.id,staticClass:"px-2 py-3 whitespace-no-wrap border border-gray-400 rounded-lg width-200",class:{
                    'bg-red text-white':
                      _vm.editingDiscount.promotionId === promotion.id
                  },on:{"click":function($event){return _vm.promotionSelected(promotion)}}},[_c('div',{class:{
                      'text-blue':
                        _vm.editingDiscount.promotionId !== promotion.id
                    }},[_vm._v(" "+_vm._s(promotion.name)+" ")]),_c('div',{class:{'text-gray-300': _vm.editingDiscount.promotionId === promotion.id,
                'text-gray-400': _vm.editingDiscount.promotionId !== promotion.id}},[_c('div',{staticClass:"flex flex-row text-sm"},[(promotion.discountType === 'percentage')?_c('div',[_vm._v(" "+_vm._s(promotion.discountAmount)+"% ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(promotion.discountAmount))+" ")]),_c('div',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.$t('bill-discount.of-discount'))+" ")])]),(promotion.remainingRedemptions)?_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(promotion.remainingRedemptions + ' ' + _vm.$t('bill-discount.remaining-redemptions'))+" ")]):_vm._e(),_c('div',{staticClass:"mt-2 text-right",class:{
                      'text-green':
                        _vm.editingDiscount.promotionId !== promotion.id
                    }},[_vm._v(" "+_vm._s(promotion.pointsExpense)+" "+_vm._s(_vm.$t('bill-discount.points'))+" ")])])])}),0)]),_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"text-blue"},[_vm._v(" "+_vm._s(_vm.$t('bill-discount.no-points-discounts'))+" ")]),_c('div',{staticClass:"flex flex-wrap w-full gap-6"},_vm._l((_vm.promotionsWithoutPoints),function(promotion){return _c('div',{key:promotion.id,staticClass:"px-2 py-3 whitespace-no-wrap border border-gray-400 rounded-lg width-200",class:{
                    'bg-red text-white':
                      _vm.editingDiscount.promotionId === promotion.id
                  },on:{"click":function($event){return _vm.promotionSelected(promotion)}}},[_c('div',{class:{
                      'text-blue':
                        _vm.editingDiscount.promotionId !== promotion.id
                    }},[_vm._v(" "+_vm._s(promotion.name)+" ")]),_c('div',{class:{'text-gray-300': _vm.editingDiscount.promotionId === promotion.id,
                'text-gray-400': _vm.editingDiscount.promotionId !== promotion.id}},[_c('div',{staticClass:"flex flex-row text-sm"},[(promotion.discountType === 'percentage')?_c('div',[_vm._v(" "+_vm._s(promotion.discountAmount)+"% ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(promotion.discountAmount))+" ")]),_c('div',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(_vm.$t('bill-discount.of-discount'))+" ")])]),(promotion.remainingRedemptions)?_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(promotion.remainingRedemptions + ' ' + _vm.$t('bill-discount.remaining-redemptions'))+" ")]):_vm._e()])])}),0)])])])]},proxy:true},{key:"footer",fn:function(){return [_c('l-button',{staticClass:"bg-accent w-full",on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('bill-discount.save'))+" ")])]},proxy:true}],null,false,1601873232)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }