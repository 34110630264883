<template>
  <div class="flex">
    <div
      v-for="option in options"
      :key="option.value"
      @click.stop="$emit('onSelect', option.value)"
      :class="{
        'option--selected': optionSelected === option.value
      }"
      class="option text-center py-2 flex-1 bg-gray-100 text-base uppercase border-gray-200"
    >
      {{ option.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RowSelector',
  props: {
    options: {
      type: Array,
      required: true
    },
    optionSelected: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.option {
  transition: all 0.3s ease;
  @apply text-gray-400;
  cursor: pointer;
  &:first-child {
    @apply rounded-tl-lg rounded-bl-lg border;
  }
  &:last-child {
    @apply rounded-tr-lg rounded-br-lg border;
  }

  &--selected {
    @apply bg-red border-red-b text-white;
  }
}
</style>
