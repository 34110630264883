import { Updater } from './updater.js'

export class ElectronUpdater extends Updater {
  constructor() {
    super()
    this.ipcRenderer = window.require('electron').ipcRenderer
  }

  async getVersion() {
    const version = await this.ipcRenderer.invoke('updater:version')
    return { name: version, build: '' }
  }

  async _getPlatform() {
    const bucket = await this.ipcRenderer.invoke('updater:bucket')
    return bucket === 'lastpos-updates-32' ? 'win32' : 'windows'
  }

  async _downloadUpdate(info) {
    return this.ipcRenderer.invoke('updater:download', info)
  }

  async installUpdate() {
    return this.ipcRenderer.invoke('updater:install')
  }
}
