<template>
  <div class="relative">
    <slot />
    <span class="wrapper">
      <span class="badge bg-error border-error-b text-error-b" v-if="quantity">{{
        quantity
      }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    quantity: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  components: {}
}
</script>

<style scoped>
.wrapper {
  flex: 0 1;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}
.badge {
  inset: auto auto calc(100% - 12px) calc(100% - 12px);
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  height: 20px;
  letter-spacing: 0;
  min-width: 20px;
  padding: 4px 6px;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
}
</style>
