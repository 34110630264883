import appStorage from '@/appStorage.js'

const PREFIX = 'localDb:'

async function loadTables(tableKeys) {
  try {
    let keysData = await appStorage.keys()
    let values = await Promise.all(
      keysData.keys
        .filter(key =>
          tableKeys.some(tableKey => key.startsWith(PREFIX + tableKey))
        )
        .map(key => key.replace(PREFIX, ''))
        .map(async key => {
          let lastIndex = key.lastIndexOf(':')
          let table = key.substring(0, lastIndex)
          let id = key.slice(lastIndex + 1)
          let value = await getItem(table, id)
          return { table, id, value }
        })
    )
    return values.reduce((res, data) => {
      if (data.id !== 'singleValue') {
        res[data.table] = res[data.table] || {}
        res[data.table][data.id] = data.value
      } else {
        res[data.table] = data.value
      }
      return res
    }, {})
  } catch {
    return {}
  }
}

function mapRows(tables) {
  let rows = []
  for (const [table, data] of Object.entries(tables)) {
    if (typeof data === 'object') {
      for (const [id, value] of Object.entries(data)) {
        rows.push({ table, id, value })
      }
    } else {
      rows.push({ table, id: 'singleValue', value: data })
    }
  }
  return rows
}

function patchTables(tables) {
  return Promise.all(
    mapRows(tables).map(row => setItem(row.table, row.id, row.value))
  )
}

async function updateTables(tables) {
  let tableKeys = Object.keys(tables)
  let keysData = await appStorage.keys()
  let oldKeys =
    keysData.keys.filter(key =>
      tableKeys.some(tableKey => key.startsWith(PREFIX + tableKey))
    ) || []
  let rows = mapRows(tables)
  let newKeys = rows.map(row => `${PREFIX}${row.table}:${row.id}`)
  let deletedKeys = oldKeys.filter(key => !newKeys.includes(key))
  await Promise.all([
    ...rows.map(row => setItem(row.table, row.id, row.value)),
    ...deletedKeys.map(key => appStorage.removeItem(key))
  ])
}

async function getItem(table, id) {
  return JSON.parse(await appStorage.getItem(`${PREFIX}${table}:${id}`))
}

function setItem(table, id, value) {
  return appStorage.setItem(`${PREFIX}${table}:${id}`, JSON.stringify(value))
}

function removeItem(table, id) {
  return appStorage.removeItem(`${PREFIX}${table}:${id}`)
}

async function clearAll() {
  let keysData = await appStorage.keys()
  return Promise.all(
    keysData.keys
      .filter(key => key.startsWith(PREFIX))
      .map(key => appStorage.removeItem(key))
  )
}

async function clearTables(tableKeys) {
  let keysData = await appStorage.keys()
  return Promise.all(
    keysData.keys
      .filter(key =>
        tableKeys.some(tableKey => key.startsWith(PREFIX + tableKey))
      )
      .map(key => appStorage.removeItem(key))
  )
}

export default {
  loadTables,
  updateTables,
  patchTables,
  setItem,
  removeItem,
  clearAll,
  clearTables
}
