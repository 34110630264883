import { mapState } from 'vuex'
import moment from 'moment'

let mixin = {
  data() {
    return {
      internalReservation: null
    }
  },
  methods: {
    setReservation(reservation) {
      this.internalReservation = reservation
    }
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('reservations', ['reservations']),
    ...mapState('tabs', ['tabs']),
    sortedReservations() {
      return Object.values(this.reservations)
        .sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime))
        .filter(
          reservation =>
            moment(reservation.dateTime) > moment().subtract(1, 'hour') &&
            !reservation.cancelled
        )
    },
    blockedTables() {
      const date = this.internalReservation.dateTime
      const range = moment.range(
        moment(date).subtract(this.config.reservations.duration, 'minutes'),
        moment(date).add(this.config.reservations.duration - 1, 'minutes')
      )

      const reservedTables = Object.values(this.sortedReservations)
        .filter(reservation => {
          let inRange = range.contains(moment(reservation.dateTime))
          let current = reservation.id === this.internalReservation.id
          return inRange && !current
        })
        .flatMap(reservation => reservation.tables)

      const currentTabsTables = Object.values(this.tabs)
        .filter(tab => tab.open && range.contains(moment(tab.creationTime)))
        .map(tab => tab.tableId)

      return [...reservedTables, ...currentTabsTables]
    }
  }
}

export default mixin
