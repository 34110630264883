var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sm:pt-10 h-full overflow-hidden flex flex-col"},[_c('div',{staticClass:"uppercase text-blue text-xl font-bold font-title"},[_vm._v(" "+_vm._s(_vm.modifierGroup.name)+" ")]),_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"text-sm text-gray-400"},[_vm._v(_vm._s(_vm.subtitle))]),(_vm.modifierGroup.min)?_c('div',{staticClass:"text-sm text-red"},[_vm._v("*obligatorio")]):_vm._e()]),_c('div',{staticClass:"mt-6 flex-1 sm:flex sm:flex-row sm:flex-wrap sm:-mx-1 scrolling-touch overflow-scroll"},_vm._l((_vm.modifierGroup.modifiers),function(modifier){return _c('div',{key:modifier.id,staticClass:"rounded-small border-gray-400 shadow mb-4 flex items-center justify-between mod-option sm:mx-2 mx-auto",class:{
        'text-gray-300 pointer-events-none opacity-50':
          _vm.isMaxed && _vm.multiple && !_vm.isSelected(modifier.id)
      },on:{"click":function($event){return _vm.toggle(modifier.id)}}},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"mr-3 font-title font-bold text-xl text-white flex justify-center items-center rounded-l-lg flex-shrink-0",style:({
            background: modifier.color || '#302F66',
            width: '2.8125rem',
            height: '3.5rem',
            color: _vm.getTextColor(modifier.color)
          })},[_vm._v(" "+_vm._s(modifier.shortName || _vm.shortName(modifier.name))+" ")]),_c('div',{staticClass:"flex items-center"},[(_vm.multiple)?_c('l-checkbox',{attrs:{"value":_vm.isSelected(modifier.id)},on:{"input":function($event){return _vm.toggle(modifier.id)}}}):_c('l-radio',{attrs:{"value":_vm.isSelected(modifier.id)},on:{"input":function($event){return _vm.toggle(modifier.id)}}}),_c('div',{staticClass:"ml-6 modifier text-blue"},[_vm._v(" "+_vm._s(_vm.modifierName(modifier.name))+" ")])],1)]),_c('div',{staticClass:"flex items-center mr-2"},[(modifier.priceImpact > 0)?_c('div',{staticClass:"text-sm text-red pl-4"},[_vm._v(" (+"+_vm._s(_vm._f("currency")(modifier.priceImpact))+") ")]):_vm._e(),(_vm.modifierGroup.allowRepeat && _vm.isSelected(modifier.id))?_c('quantity-selector',{staticClass:"ml-4",attrs:{"quantity":_vm.modifiers[modifier.id],"is-maxed":_vm.isMaxed},on:{"update:quantity":function (quantity) { return _vm.updateQuantity(modifier.id, quantity); }}}):_vm._e()],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }