<template>
  <div
    class="menu-item"
    :class="{ 'w-1/2': componentStyle === 'row' }"
    @click="$emit('selected')"
  >
    <div
      v-if="componentStyle === 'square'"
      class="menu-product flex flex-col relative bg-white rounded-big shadow flex-shrink-0 px-4 pt-12 pb-3 m-2 items-center justify-center text-center cursor-pointer"
    >
      <div
        class="circle rounded-full absolute top-0 bg-blue-600 border flex items-center justify-center"
        :style="{
          background: background,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderColor: borderColor
        }"
      >
        <div
          v-if="!hasImage"
          :style="{
            color: getTextColor(product.color)
          }"
          class="font-bold font-title uppercase"
        >
          {{ shortName }}
        </div>
      </div>
      <div class="text-gray-400 text-sm max-two-lines">{{ product.name }}</div>
    </div>
    <div
      v-else
      class="flex flex-row bg-white rounded-lg shadow items-center justify-center text-center h-12 m-1 sm:m-2"
    >
      <div class="w-1/5 items-center justify-center flex">
        <div
          class="h-8 w-8 rounded-full bg-blue-600 border flex items-center justify-center"
          :style="{
            background: background,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderColor: borderColor
          }"
        >
          <div
            v-if="!hasImage"
            :style="{
              color: getTextColor(product.color)
            }"
            class="font-bold font-title uppercase"
          >
            {{ shortName }}
          </div>
        </div>
      </div>

      <div
        class="text-blue text-sm flex-1 text-left leading-4 my-2 max-two-lines"
      >
        {{ product.name }}
      </div>
      <div v-if="product.priceImpact" class="text-blue text-sm mr-3 ml-3">
        {{ product.priceImpact | currency }}
      </div>
      <div class="w-2/12 flex justify-center" v-show="selectedQuantity > 0">
        <div
          class="rounded-full bg-red h-6 w-6 text-white flex items-center justify-center"
        >
          {{ selectedQuantity }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageCache from '@/imageCache.js'
import utils from '@last/core/src/lastUtils'
import { mapState } from 'vuex'

export default {
  name: 'MenuProduct',
  props: {
    product: {
      type: Object,
      default: null
    },
    componentStyle: {
      type: String,
      default: 'square'
    },
    selectedQuantity: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getTextColor(hexcolor) {
      if (!hexcolor) return 'white'
      let color = hexcolor.substr(1, 6)
      var r = parseInt(color.substr(0, 2), 16)
      var g = parseInt(color.substr(2, 2), 16)
      var b = parseInt(color.substr(4, 2), 16)
      var yiq = (r * 299 + g * 587 + b * 114) / 1000
      return yiq >= 200 ? '#302F66' : 'white'
    }
  },
  computed: {
    ...mapState('config', ['config']),
    shortName() {
      if (this.product.shortName) {
        return this.product.shortName
      }
      if (!this.product.name) return ''
      let initials = this.product.name
        .split(/[.,/ -]/)
        .map(n => n[0])
        .join('')
        .slice(0, 2)
      if (initials.length < 2) {
        return this.product.name.slice(0, 2)
      } else {
        return initials
      }
    },
    hasImage() {
      return (
        !!imageCache.getImage('product', this.product.id) &&
        this.config.showProductImages
      )
    },
    background() {
      if (this.hasImage) {
        let image = imageCache.getImage('product', this.product.id)
        return `url(${image})`
      } else {
        return this.product.color
      }
    },
    borderColor() {
      return utils.darken(this.product.color)
    }
  }
}
</script>

<style scoped>
.circle {
  height: 4.125rem;
  width: 4.125rem;
  margin-top: -1.562rem;
  font-size: 1.75rem;
}

.menu-product {
  height: 5.75rem;
  width: 9.75rem;
  margin-top: 2.688rem;
  line-height: 1.1em;
}

.max-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>
