import idleJs from 'idle-js'
import router from '@/router'

function createIdle(seconds) {
  return new idleJs({
    idle: seconds * 1000,
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'click'],
    onIdle: () => {
      if (router.currentRoute.name !== 'employees') {
        router.push({ name: 'employees' })
      }
    }
  })
}

const idleMixin = {
  data() {
    return {
      idlejs: null,
      idleViewActive: false
    }
  },
  mounted() {
    if (this.idleTimeoutSeconds) {
      this.idlejs = createIdle(this.idleTimeoutSeconds)
    }
  },
  beforeDestroy() {
    if (this.idlejs) {
      this.idlejs.stop()
    }
  },
  activated() {
    if (this.idlejs) {
      this.idlejs.reset().start()
    }
    this.idleViewActive = true
  },
  deactivated() {
    if (this.idlejs) {
      this.idlejs.stop()
    }
    this.idleViewActive = false
  },
  computed: {
    idleTimeoutSeconds() {
      return this.$store.state.config.config.timeoutSeconds
    }
  },
  watch: {
    idleTimeoutSeconds(seconds) {
      if (this.idlejs) {
        this.idlejs.stop()
        this.idlejs = null
      }
      if (seconds) {
        this.idlejs = createIdle(seconds)
      }
      if (this.idlejs && this.idleViewActive) {
        this.idlejs.start()
      }
    }
  }
}

export default idleMixin
