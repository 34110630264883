<template>
  <div id="app">
    <keep-alive include="Home">
      <router-view />
    </keep-alive>
    <portal-target name="appRoot" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  async mounted() {
    await this.loadRobotoBold()
  },
  methods: {
    async loadRobotoBold() {
      let assetPath = require(`./assets/fonts/Roboto-Bold.ttf`)
      var robotoBoldFontFace = new FontFace('Roboto-Bold', `url(${assetPath})`)
      await robotoBoldFontFace.load()
    }
  },
  computed: {
    ...mapGetters('auth', ['trialExpired', 'gracePeriodExpired'])
  },
  watch: {
    trialExpired() {
      if (this.trialExpired) {
        this.$router.push({ name: 'freeTrialExpired' })
      } else {
        this.$router.push({ name: 'employees' })
      }
    },
    gracePeriodExpired() {
      if (this.gracePeriodExpired) {
        this.$router.push({ name: 'gracePeriodExpired' })
      } else {
        this.$router.push({ name: 'employees' })
      }
    }
  }
}
</script>

<style></style>
