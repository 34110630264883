<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen z-20 blur-background overflow-hidden flex justify-center items-center"
    @click="$emit('close')"
  >
    <div class="p-20 max-h-full overflow-y-scroll">
      <div class="text-white uppercase text-2xl text-center pb-6">
        {{ $t('tabs.merge-tab') }}
      </div>
      <div class="bg-white rounded-big shadow p-6 relative max-w" @click.stop>
        <icon
          name="close"
          class="text-gray-400 absolute top-0 right-0 m-4"
          @click.native="$emit('close')"
        />
        <div class="py-8">
          <option-selector
            :selected.sync="selectedTabId"
            :options="tabOptions"
          />
        </div>
      </div>
      <div class="flex pt-4">
        <div
          class="p-3 px-4 text-white bg-blue border border-blue-700 uppercase rounded-small font-bold flex-1 text-center"
          @click="$emit('close')"
        >
          {{ $t('tabs.cancel') }}
        </div>
        <div
          class="p-3 px-4 text-white bg-red border border-red-b uppercase rounded-small font-bold flex-1 ml-4 text-center"
          @click="merge()"
        >
          {{ $t('tabs.merge') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import OptionSelector from './OptionSelector.vue'
import { mapActions } from 'vuex'

export default {
  name: 'MergeTabs',
  props: {
    tabId: {
      type: String,
      default: null
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedTabId: null
    }
  },
  methods: {
    ...mapActions('tabs', ['mergeTabs']),
    merge() {
      this.mergeTabs({ tabAId: this.tabId, tabBId: this.selectedTabId })
      this.$emit('close')
    }
  },
  computed: {
    tabOptions() {
      return this.tabs.map(tab => ({
        value: tab.id,
        label: tab.tableName || tab.code
      }))
    }
  },
  components: {
    Icon,
    OptionSelector
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}
.max-w {
  max-width: 400px;
}
</style>
