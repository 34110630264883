<template>
  <l-modal
    :title="$t('end-shift.close-till')"
    size="small"
    class="py-32 font-body"
    @close="$emit('close')"
    disable-click-outside
  >
    <template slot="body">
      <div class="text-blue text-lg text-center font-bold">
        {{ $t('end-shift.close-till-confirmation-message') }}:
      </div>
      <div class="mt-8">
        <div v-for="amount in amountsList" :key="amount.method" class="mt-3">
          <div class="flex justify-between">
            <div class="text-blue">
              {{ amount.method }}
            </div>
            <div class="text-green">
              {{ amount.amount | currency }}
            </div>
          </div>
        </div>
      </div>
      <l-modal-ctas>
        <l-modal-button
          type="secondary"
          :label="$t('end-shift.cancel')"
          class="w-1/2 mr-2"
          @click.native="$emit('close')"
        />
        <l-modal-button
          type="main"
          :label="$t('end-shift.end')"
          class="w-1/2 ml-2"
          :disabled="loading"
          :loading="loading"
          @click.native="
            loading = true
            $emit('end')
          "
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'

export default {
  name: 'CloseTillConfirmation',
  components: {
    LModal,
    LModalCtas,
    LModalButton
  },
  data() {
    return {
      loading: false
    }
  },
  props: {
    amountsList: {
      type: Array,
      default: () => []
    }
  },
  methods: {}
}
</script>

<style scoped></style>
