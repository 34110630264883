<template>
  <scale-to-fit>
    <component
      :is="isMobile ? 'pinch-zoom' : 'div'"
      background-color="rgba(0,0,0,0,0)"
      :limit-pan="true"
      overflow="visible"
      :limit-zoom="3"
    >
      <div
        id="plan-container"
        style="width: 580px; height: 400px; position: relative"
        ref="planCanvas"
      >
        <div
          v-for="table in tables"
          @click="selectTable(table)"
          :key="table.id"
          :style="getStyle(table)"
          :parent="true"
          :class="[
            table.type,
            {
              'has-tab': table.tabIds.length > 0,
              'border border-white': pendingKitchenOrders(table),
              'has-bills': hasBills(table),
              'has-reservation':
                table.tabIds.length === 0 && hasReservation(table.id)
            }
          ]"
          class="relative table"
        >
          <div class="flex flex-col justify-between w-full h-full">
            <div
              v-if="table.tabIds.length > 0"
              class="pt-2 text-center line-height"
            >
              {{ ellapsedTime(getLastInteraction(table.tabIds[0])) }}
            </div>
            <div
              v-else-if="hasReservation(table.id)"
              class="flex items-center justify-center pt-2 text-center line-height"
            >
              <icon name="time" class="mr-1 opacity-75 clock-size" />
              <div>{{ timeUntil(reservationTime(table.id)) }}</div>
            </div>
            <div class="flex items-center justify-center h-full">
              <div
                class="text-xs font-bold text-center uppercase font-title line-height"
              >
                {{ name(table) }}
              </div>
            </div>
            <div
              class="flex items-center justify-center pb-2"
              v-if="seats(table) > 1"
            >
              <icon name="diners" class="mr-1 diners-size" />
              {{ seats(table) }}
            </div>
            <div
              class="absolute right-0 bg-white rounded-full circle"
              v-if="pendingKitchenOrders(table)"
            >
              <div class="w-full h-full mr-2 rounded-full bg-yellow" />
            </div>
          </div>
        </div>
      </div>
    </component>
  </scale-to-fit>
</template>

<script>
import ScaleToFit from './ScaleToFit.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import app from '@/app.js'

export default {
  name: 'FloorPlan',
  props: ['floorplanId'],
  data() {
    return {
      selectedTable: {},
      currentDate: new Date(),
      dateInterval: null
    }
  },
  mounted() {
    this.dateInterval = setInterval(() => {
      this.currentDate = new Date()
    }, 60 * 1000)
  },
  beforeDestroy() {
    if (this.dateInterval) {
      clearInterval(this.dateInterval)
    }
  },
  methods: {
    getStyle(table) {
      return {
        position: 'absolute',
        width: table.width + 'px',
        height: table.height + 'px',
        left: table.x + 'px',
        top: table.y + 'px'
      }
    },
    selectTable(table) {
      if (!this.config.lastProductPosEnabled) return
      if (table.type !== 'wall') {
        this.$emit('tableSelected', table)
      }
    },
    name(table) {
      let tab = this.tabs[table.tabIds[0]]
      if (tab && tab.lang) {
        return `${tab.lang} - ${table.name}`
      }
      return table.name
    },
    seats(table) {
      let tab = this.tabs[table.tabIds[0]]
      if (tab) {
        return tab.seats.length
      }
      let reservation = this.getReservation(table.id, this.currentDate)
      if (reservation) {
        return reservation.diners
      }
      return 0
    },
    pendingKitchenOrders(table) {
      if (!this.config.enableKitchenOrders) return false
      return table.tabIds.some(tabId => {
        return (
          this.getUnsentProducts(tabId).length > 0 ||
          this.tabs[tabId].kitchenOrders
            .map(id => this.kitchenOrders[id])
            .filter(order => !order.printedTime && order.copies > 0).length > 0
        )
      })
    },
    hasBills(table) {
      if (table.tabIds.length === 0) return false
      return this.tabs[table.tabIds[0]].billingStartedTime
    },
    hasReservation(tableId) {
      return !!this.getReservation(tableId, this.currentDate)
    },
    reservationTime(tableId) {
      let reservation = this.getReservation(tableId, this.currentDate)
      if (reservation) {
        return reservation.dateTime
      }
    },
    ellapsedTime(date) {
      if (date) {
        var now = moment(this.currentDate)
        var start = moment(date)
        var duration = moment.duration(Math.max(now.diff(start), 0))
        return (
          Math.floor(duration.asHours()) +
          moment.utc(duration.asMilliseconds()).format(':mm')
        )
      }
    },
    timeUntil(date) {
      if (date) {
        var now = moment(this.currentDate)
        var start = moment(date)
        var duration = moment.duration(start.diff(now))
        if (duration.asHours() >= 0) {
          return (
            Math.floor(duration.asHours()) +
            moment.utc(duration.asMilliseconds()).format(':mm')
          )
        } else {
          let minus = '-'
          if (duration.asHours() < -1) minus = ''
          return (
            minus +
            Math.ceil(duration.asHours()) +
            ':' +
            String(-duration.minutes()).padStart(2, '0')
          )
        }
      }
    }
  },
  computed: {
    ...mapGetters('tables', ['getTables']),
    ...mapGetters('tabs', [
      'getLastInteraction',
      'getUnsentProducts',
      'getBills'
    ]),
    ...mapState('tabs', ['tabs', 'kitchenOrders']),
    ...mapState('config', ['config']),
    ...mapGetters('reservations', ['getReservation']),
    tables() {
      return this.getTables(this.floorplanId)
    },
    isMobile() {
      return app.isMobile
    }
  },
  components: {
    ScaleToFit,
    Icon
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.table {
  color: #545383;
  background: #f7fafc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  font-size: 10px;
  &.has-tab {
    color: white;
    background: #4299e1;
    &.has-bills {
      background: #ff7878;
    }
  }
  &.has-reservation {
    @apply bg-yellow text-white;
  }
}

.rectangle {
  border-radius: 4px;
}

.circle {
  border-radius: 500px;
}

.wall {
  background: #100f1b;
  color: #545383;
}

.table-time {
  text-align: center;
}

.line-height {
  line-height: 10px;
}

.diners-size {
  width: 12px;
  height: 12px;
}

.clock-size {
  width: 15px;
  height: 15px;
}

.circle {
  width: 10px;
  height: 10px;
  padding: 2px;
  margin-right: -6px;
  margin-top: -5px;
  top: 50%;
}
</style>
