<template>
  <div class="sm:pt-10 h-full overflow-hidden flex flex-col">
    <div id="new-modifier" class="px-8 py-4 bg-gray-100">
      <div class="text-xl font-bold uppercase text-blue font-title">
        {{ $t('ordering.new-open-modifier') }}
      </div>
      <div class="text-sm text-gray-400">
        {{ $t('ordering.new-open-modifier-subtitle') }}
      </div>
      <div class="flex items-center mb-4">
        <l-field class="w-3/12 mr-2">
          <l-input
            class="p-2 bg-gray-100"
            :placeholder="$t('ordering.name')"
            v-model="openModifier.name"
          />
        </l-field>
        <l-field class="w-2/12 mr-2">
          <l-input
            class="p-2 bg-gray-100"
            :placeholder="$t('ordering.price')"
            v-model="openModifier.priceImpact"
            :icon="`currencies/${currencyIcon}`"
            is-currency
          ></l-input>
        </l-field>
        <div
          @click="createModifier(openModifier)"
          class="flex items-center px-10 text-lg font-bold text-white uppercase bg-blue-600 done-button border border-blue-700 rounded-small h-12 -mt-4"
        >
          {{ $t('ordering.create') }}
        </div>
      </div>
      <div>
        <div
          class="flex items-center justify-between p-4 mx-2 mb-4 border rounded-small option border-red bg-white"
          @click="$emit('openModifier:delete', modifier.id)"
          v-for="modifier in openModifiers"
          :key="modifier.id"
        >
          <div class="flex items-center">
            <l-checkbox
              :value="true"
              @input="$emit('openModifier:delete', modifier.id)"
            />
            <div class="ml-4">{{ modifier.name }}</div>
          </div>
          <div class="flex items-center">
            <div class="pl-4 text-sm text-red" v-if="modifier.priceImpact > 0">
              (+{{ modifier.priceImpact | currency }})
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import LInput from '@last/core-ui/components/LInput'
import LField from '@last/core-ui/components/LField'
import { mapGetters } from 'vuex'
import productEditorMixin from '@/components/ordering/productEditorMixin.js'

export default {
  name: 'OpenModifiers',
  emits: ['openModifier:delete', 'openModifier:create'],
  mixins: [productEditorMixin],
  props: {
    openModifiers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showingOpenModifier: false,
      openModifier: {
        name: null,
        priceImpact: 0
      }
    }
  },
  methods: {
    async createModifier(openModifier) {
      if (await this.$confirm('OPEN_PRODUCTS_CREATOR'))
        this.$emit('openModifier:create', openModifier)
    }
  },
  computed: {
    ...mapGetters('config', ['currencyIcon'])
  },
  components: {
    LInput,
    LField,
    LCheckbox
  }
}
</script>

<style scoped>
.done-button {
  height: 3.5rem;
}
</style>
