<template>
  <div>
    <div v-for="(tabList, index) in filteredNowTabsGrouped" :key="index">
      <div
        v-if="tabList.length === 1"
        @click="$emit('selectTab', tabList[0].id, tabList[0].pickupType)"
      >
        <small-tab-row
          v-if="!fullScreen"
          :tab="tabList[0]"
          :selected-tab-id="selectedTabId"
        />
        <large-tab-row
          v-else-if="
            !choosingForShipment || rowIsPickableForShipment(tabList[0])
          "
          :tab="tabList[0]"
          :row-size="rowSize"
          :current-time="currentTime"
          :row-index="index"
          :is-selected="tabList[0].id === selectedTabId"
          :choosing-for-shipment="choosingForShipment"
          :picked-for-shipment="pickedTabsForShipment.includes(tabList[0].id)"
          @goToDetails="$emit('squareMenu')"
          @shipment-pick-toggled="
            value => $emit('shipmentPickToggled', tabList[0].id, value)
          "
        />
      </div>
      <div v-else-if="!choosingForShipment" class="bg-gray-100 relative">
        <div class="flex flex-row ml-3 py-3">
          <div
            class="bg-blue h-6 w-6 rounded-full flex justify-center items-center"
          >
            <icon name="chain" class="text-white" small />
          </div>
          <div class="text-blue font-body ml-3">
            {{ $t('tabs.bundled-orders') }}
          </div>
        </div>
        <div
          v-for="tab in tabList"
          :key="tab.id"
          @click="$emit('selectTab', tab.id, tab.pickupType)"
        >
          <small-tab-row
            v-if="!fullScreen"
            :tab="tab"
            :selected-tab-id="selectedTabId"
            offset
          />
          <large-tab-row
            v-else
            :tab="tab"
            :row-size="rowSize"
            :current-time="currentTime"
            :is-selected="tab.id === selectedTabId"
            @goToDetails="$emit('squareMenu')"
            offset
          />
        </div>
        <div
          class="w-1 bg-blue absolute top-0 rounded-lg bundle-bar bottom-0 mb-6 mt-10"
        />
      </div>
    </div>
    <div v-if="areScheduleTabs && !choosingForShipment">
      <h2 class="mt-6 mb-1 ml-4 text-base font-bold uppercase">
        Pedidos programados
      </h2>
      <div>
        <div
          v-for="(tab, index) in filteredScheduledTabs"
          :key="tab.id"
          @click="$emit('selectTab', tab.id)"
        >
          <small-tab-row
            v-if="!fullScreen"
            :tab="tab"
            :selected-tab-id="selectedTabId"
          />
          <large-tab-row
            v-else
            :tab="tab"
            :row-size="rowSize"
            :current-time="currentTime"
            :class="{ 'bg-gray-100': index % 2 === 1 }"
            :row-index="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import SmallTabRow from './SmallTabRow.vue'
import LargeTabRow from './LargeTabRow.vue'
import Icon from '@last/core-ui/components/Icon'

export default {
  components: { SmallTabRow, LargeTabRow, Icon },
  name: 'TabRows',
  data() {
    return {}
  },
  props: {
    tabs: {
      type: Array,
      default: null
    },
    pickedTabsForShipment: {
      type: Array,
      default: null
    },
    choosingForShipment: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    filterClosed: {
      type: Boolean,
      default: false
    },
    selectedTabId: {
      type: String,
      default: null
    },
    rowSize: {
      type: String,
      default: null
    },
    currentTime: {
      type: Date,
      default: null
    }
  },
  methods: {
    rowIsPickableForShipment(tab) {
      return (
        tab.pickupType === 'ownDelivery' &&
        tab.deliveryOrder &&
        !tab.deliveryOrder.shipmentId
      )
    }
  },
  computed: {
    groupedTabs() {
      return this.tabs
        .filter(tab => (tab.open || !this.filterClosed) && tab.activationTime)
        .reduce((res, tab) => {
          let key = tab.deliveryOrder?.shipmentId || tab.shopOrderId || 'null'
          res[key] = res[key] ? [...res[key], tab] : [tab]
          return res
        }, {})
    },
    filteredNowTabsGrouped() {
      return Object.keys(this.groupedTabs)
        .flatMap(key => {
          if (key === 'null' || this.groupedTabs[key].length === 1) {
            return this.groupedTabs[key].map(tab => [tab])
          } else {
            return [
              this.groupedTabs[key].sort(
                (a, b) => a.activationTime - b.activationTime
              )
            ]
          }
        })
        .sort((first, second) => {
          let firstActivationTime = first[0].activationTime
          let secondActivationTime = second[0].activationTime
          return moment(firstActivationTime) - moment(secondActivationTime)
        })
    },
    filteredScheduledTabs() {
      return this.tabs
        .filter(
          tab =>
            (tab.open || !this.filterClosed) &&
            !tab.activationTime &&
            tab.schedulingTime
        )
        .sort((a, b) => new Date(a.schedulingTime) - new Date(b.schedulingTime))
    },
    areScheduleTabs() {
      return this.filteredScheduledTabs.length > 0
    }
  }
}
</script>

<style scoped>
.bundle-bar {
  height: auto;
  margin-left: 1.4rem;
}
</style>
