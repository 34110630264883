export const deliveryCompanies = [
  {
    label: 'Own Delivery',
    value: 'OwnDelivery'
  },
  {
    label: 'Glovo',
    value: 'Glovo'
  },
  {
    label: 'Deliveroo',
    value: 'Deliveroo'
  },
  {
    label: 'Uber Eats',
    value: 'Uber'
  },
  {
    label: 'Just Eat',
    value: 'JustEat'
  },
  {
    label: 'Edenred',
    value: 'Edenred'
  },
  {
    label: 'Flipdish',
    value: 'Flipdish'
  },
  {
    label: 'Too Good To Go',
    value: 'TooGoodToGo'
  },
  {
    label: 'Eat Tasty',
    value: 'EatTasty'
  }
]
