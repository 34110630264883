import { render, staticRenderFns } from "./LCheckbox.vue?vue&type=template&id=27dd9367&"
import script from "./LCheckbox.vue?vue&type=script&lang=js&"
export * from "./LCheckbox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../pos/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports