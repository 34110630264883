import uuid4 from 'uuid/v4'

function regenerateIds(product) {
  let kitchenProduct = {
    ...product,
    id: uuid4()
  }
  if (product.modifiers) {
    kitchenProduct.modifiers = product.modifiers.map(modifier => ({
      ...modifier,
      id: uuid4()
    }))
  }
  return kitchenProduct
}

function mapProduct(product) {
  let kitchenProduct = {
    name: product.kitchenName || product.name,
    course: product.course || 'Main',
    comments: product.comments,
    quantity: product.quantity,
    tabProductId: product.id,
    catalogProductId: product.parentProduct,
    id: uuid4(),
    fromCombo: !!product.fromCombo,
    comboId: product.combo?.id,
    comboName: product.combo?.name,
    combine: product.combo?.combine || false,
    categoryPosition: product.categoryPosition
  }
  if (product.modifiers) {
    kitchenProduct.modifiers = product.modifiers.map(modifier => ({
      id: uuid4(),
      name: modifier.kitchenName || modifier.name,
      catalogModifierId: modifier.parentModifierId,
      quantity: modifier.quantity,
      tabModifierId: modifier.id
    }))
  }
  return kitchenProduct
}

export default {
  generateOrders(
    products,
    tab,
    deliveryOrder,
    tabName,
    waiterName,
    printers,
    virtualBrandName
  ) {
    let printersMap = printers.reduce((acc, p) => ({ ...acc, [p.id]: p }), {})

    let groupedProductsMapped = products.reduce((res, product) => {
      let printerIds = product.printerIds || []
      printerIds.map(printerId => {
        let copies = product.copies
        let printer = printersMap[printerId] || {}
        if (printer.splitProducts) {
          for (let quantity = 1; quantity <= product.quantity; ++quantity) {
            res[[product.id, copies, quantity]] = {
              printerId: printerId,
              printerName: printer.name,
              copies: copies,
              products: [mapProduct({ ...product, quantity: 1 })],
              printerType: printer.type
            }
          }
        } else {
          let group = res[[printerId, copies]] || {
            printerId: printerId,
            printerName: printer.name,
            copies: copies,
            products: [],
            printerType: printer.type
          }
          group.products.push(mapProduct(product))
          res[[printerId, copies]] = group
        }
      })
      return res
    }, {})
    let groupedProducts = Object.values(groupedProductsMapped)
    let totalStickers = groupedProducts.filter(
      product => product.printerType === 'ZPL'
    ).length
    let stickerPosition = 0
    const estimatedDeliveryTime =
      deliveryOrder?.estimatedDeliveryTime ?? tab.schedulingTime
    return groupedProducts.map(group => {
      let sentTime = new Date()
      if (group.printerType === 'ZPL') ++stickerPosition
      return {
        id: uuid4(),
        creationTime: sentTime,
        versions: [
          {
            id: uuid4(),
            sentTime,
            products: group.products
          }
        ],
        locationBrandId: tab.virtualBrandId,
        tabId: tab.id,
        allergyInfo: tab.allergyInfo,
        printerId: group.printerId,
        printerName: group.printerName,
        copies: group.copies,
        tabName,
        estimatedDeliveryTime,
        seats: tab.seats.length,
        pickupType: tab.pickupType,
        toGo: tab.pickupType === 'takeAway',
        source: tab.source,
        code: tab.code,
        ...(deliveryOrder && {
          courierName: deliveryOrder.courierName,
          courierPhoneNumber: deliveryOrder.courierPhoneNumber,
          customerName: deliveryOrder.customerName,
          customerPhoneNumber: deliveryOrder.customerPhoneNumber,
          pickupTime: deliveryOrder.pickupTime
        }),
        waiterName,
        note: tab.kitchenNote,
        stickerPart: `${stickerPosition} / ${totalStickers}`,
        activationTime: tab.activationTime,
        virtualBrandName
      }
    })
  },
  generateOrderVersion(products) {
    let sentTime = Date.now()
    return {
      id: uuid4(),
      sentTime,
      products: products.map(product => mapProduct(product))
    }
  },
  generateOrderVersionForSplittedProduct(
    currentVersionProducts,
    splittedProductId,
    newProduct
  ) {
    let currentProductsWithoutSubtractedOne = currentVersionProducts
      .map(product => {
        if (product.tabProductId === splittedProductId) {
          return {
            ...product,
            quantity: product.quantity - 1
          }
        } else {
          return product
        }
      })
      .map(regenerateIds)

    let sentTime = Date.now()
    return {
      id: uuid4(),
      sentTime,
      products: [...currentProductsWithoutSubtractedOne, mapProduct(newProduct)]
    }
  }
}
