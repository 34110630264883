<template>
  <div>
    <div
      v-if="$route.name === 'comboEditor' && savedCatalogCombo"
      class="h-screen flex flex-col items-center relative"
    >
      <div class="w-full h-full pt-10 px-3 overflow-hidden flex flex-col">
        <div class="flex flex-row w-full items-center justify-center relative">
          <div class="absolute top-0 left-0 pr-3 pt-1">
            <icon
              name="arrow-left"
              class="text-gray-400"
              @click.native="close()"
            />
          </div>
          <div class="text-blue uppercase text-xl font-bold font-title mr-3">
            {{ savedCatalogCombo.name }}
          </div>
          <div class="text-blue uppercase text-xl font-title">
            {{ savedCatalogCombo.price | currency }}
          </div>
          <div class="absolute top-0 right-0 p-1 rounded-full bg-green">
            <icon
              small
              name="plus"
              class="text-white"
              @click.native="addCombo"
            />
          </div>
        </div>

        <div class="flex py-1"></div>

        <div class="flex-1 flex flex-row w-full h-full">
          <div
            class="bg-white relative w-full flex flex-col overflow-hidden"
            @click.stop
          >
            <div
              v-horizontal-scroll
              class="w-full flex flex-row overflow-scroll scrolling-touch mt-3"
              :ref="'combo-wrapper'"
            >
              <div
                v-for="(combo, comboIndex) in savedCombos"
                @click="selectComboIndex(comboIndex)"
                :key="comboIndex"
                :label="combo.name"
                class="mr-4 uppercase text-blue font-title px-1"
                :class="{
                  'border-b border-blue': selectedComboIndex === comboIndex
                }"
                :ref="`${comboIndex}-anchor`"
                style="min-width: fit-content"
              >
                {{ $t('combo-editor.combo') + ' ' + (comboIndex + 1) }}
              </div>
            </div>

            <div
              v-horizontal-scroll
              class="w-full flex flex-row overflow-scroll scrolling-touch"
            >
              <template v-if="savedCatalogCombo">
                <l-pill
                  v-for="(category, index) in savedCatalogCombo.categories"
                  @input="selectCategory(index)"
                  :key="category.id"
                  :label="category.name"
                  :value="selectedCategoryIndex === index"
                  class="mr-4"
                />
              </template>
            </div>

            <div
              v-if="manualInterventionIds.length > 0"
              class="text-gray-400 mt-8 -mb-5"
            >
              {{ $t('combo-editor.products-with-required-modifiers-error') }}
            </div>

            <div
              v-if="categoryMin(selectedCategoryIndex) > 0"
              class="text-gray-400 mb-2"
            >
              {{
                $t('combo-editor.select-min-max-products', {
                  min: categoryMin(selectedCategoryIndex),
                  max: categoryMax(selectedCategoryIndex)
                })
              }}
            </div>
            <div 
              v-else
              class="text-gray-400 mt-2"
            >
              {{
                $t('combo-editor.select-max-of-products', {
                  max: categoryMax(selectedCategoryIndex)
                })
              }}
            </div>
            <div
              class="flex flex-wrap w-full overflow-scroll scrolling-touch content-start mt-5 mb-5 flex-1"
            >
              <menu-product
                v-for="product in filteredProducts"
                :key="product.id"
                :product="product"
                class="w-full"
                :class="{
                  'opacity-50': categoryIsMaxed(
                    selectedComboIndex,
                    selectedCategoryIndex
                  )
                }"
                @selected="saveProduct(product)"
                component-style="row"
                :selected-quantity="
                  productTotalSelected(selectedComboIndex, product.id)
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="pb-24 w-full px-8 pt-5 bg-white flex flex-col justify-center items-center"
      >
        <floating-button
          :primary-label="this.$t('ordering.confirm')"
          class="mb-16"
          :class="{
            'opacity-50 pointer-events-none': !allCategoriesReachesMin(
              selectedComboIndex
            )
          }"
          @primary="saveCombo()"
        />
        <div
          class="text-red uppercase fixed bottom-0 mb-5"
          @click="goToComboDetails"
        >
          {{ $t('combo-editor.see-combo-detail') }}
        </div>
      </div>
    </div>
    <router-view
      v-else
      :editing-product="editingProduct"
      :saved-catalog-combo="savedCatalogCombo"
      :map-category-selected-products="mapCategorySelectedProducts"
      :map-category-maxed="mapCategoryMaxed"
      :saved-combos="savedCombos"
      @saveProduct="saveEditedProduct"
      @updateProductQuantity="updateProductQuantity"
      @deleteProduct="deleteProduct"
      @editProduct="editProduct"
      @onRemove="onRemove"
    />
  </div>
</template>

<script>
import MenuProduct from '@/components/ordering/MenuProduct.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import FloatingButton from '@last/core-ui/components/FloatingButton.vue'
import comboEditorMixin from '@/components/ordering/comboEditorMixin.js'
import LPill from '@last/core-ui/components/LPill.vue'
import uuid4 from 'uuid/v4'
import { mapState } from 'vuex'
import Vue from 'vue'

export default {
  name: 'ComboEditor',
  mixins: [comboEditorMixin],
  props: {
    catalogCombo: {
      type: Object,
      default: null
    },
    editingCombo: {
      type: Object,
      default: null
    },
    catalogId: {
      type: String,
      default: null
    },
    tabId: {
      type: String,
      default: null
    },
    seat: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selectedCategoryIndex: 0,
      selectedComboIndex: 0,
      editingProduct: null,
      selectedProducts: {},
      savedCatalogCombo: null,
      savedEditingCombo: null,
      manualInterventionIds: [],
      savedCombos: []
    }
  },
  mounted() {
    if (!this.catalogCombo) {
      this.$router.replace({
        name: 'orderManagement',
        params: { tabId: this.tabId }
      })
    }
    this.savedCatalogCombo = this.catalogCombo
    this.savedEditingCombo = this.editingCombo
    if (this.editingCombo) {
      this.savedCombos = [{ ...this.editingCombo }]
    } else {
      this.savedCombos = [{ ...this.editingCombo }]
    }

    if (this.editingCombo) {
      this.preselectSelectedProducts()
    } else {
      this.preselectSelectedByDefault()
    }
    if (this.seat && this.$route.query.selectedSeat != this.seat) {
      this.$router.replace({
        query: { ...this.$route.query, selectedSeat: this.seat }
      })
    }
  },
  methods: {
    goToComboDetails() {
      this.$router.push({
        name: 'comboDetails'
      })
    },
    close() {
      if (this.$listeners && this.$listeners.close) {
        this.$emit('close')
      } else {
        this.$router.go(-1)
      }
    },
    editProduct(comboIndex, product) {
      this.selectedComboIndex = comboIndex
      this.editingProduct = product
      this.$router.push({
        name: 'comboProductEditor',
        params: {
          product: this.editingProduct,
          catalogCombo: this.savedCatalogCombo
        }
      })
    },
    selectComboIndex(comboIndex) {
      this.selectedComboIndex = comboIndex
      this.goTo(comboIndex)
    },
    onRemove(comboIndex) {
      if (this.savedCombos.length == 1) this.close()

      for (let i = comboIndex; i < this.savedCombos.length - 1; i++) {
        this.selectedProducts[i] = {
          ...this.selectedProducts[i + 1]
        }
      }

      delete this.selectedProducts[this.savedCombos.length - 1]

      Vue.delete(this.savedCombos, comboIndex)

      this.selectedComboIndex = 0
      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      )
        this.selectedComboIndex++
      this.goTo(this.selectedComboIndex)
    },
    addCombo() {
      this.savedCombos.push({ ...this.catalogCombo })
      this.selectedComboIndex = 0
      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      )
        this.selectedComboIndex++
      this.goTo(this.selectedComboIndex)
      this.preselectSelectedByDefault()
    },
    selectCategory(category) {
      this.selectedCategoryIndex = category
      this.selectedComboIndex = 0
      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(this.selectedComboIndex, category)
      )
        this.selectedComboIndex++
      this.goTo(this.selectedComboIndex)
    },
    goTo(comboIndex) {
      this.$nextTick(() => {
        let scrollWrapper = this.$refs['combo-wrapper']
        if (!scrollWrapper) return
        this.scrollingToCategoryId = comboIndex
        let target = this.$refs[`${comboIndex}-anchor`][0]
        scrollWrapper.scrollTo({
          behavior: 'smooth',
          left: target.offsetLeft - scrollWrapper.offsetLeft - 20
        })
      })
    },
    saveEditedProduct(product) {
      this.selectedProducts = {
        ...this.selectedProducts,
        [this.selectedComboIndex]: {
          ...this.selectedProducts[this.selectedComboIndex],
          [product.id]: product
        }
      }
      this.editingProduct = null
      let id = this.manualInterventionIds.findIndex(id => product.id === id)
      this.manualInterventionIds.splice(id, 1)

      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      )
        this.selectedComboIndex++
      if (
        this.selectedComboIndex == this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        ) &&
        this.selectedCategoryIndex !=
          this.savedCatalogCombo.categories.length - 1
      ) {
        this.selectCategory(this.selectedCategoryIndex + 1)
      }
      this.goTo(this.selectedComboIndex)
      this.$router.go(-1)
    },
    saveProduct(product) {
      if (
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      ) {
        return
      }

      let comboProduct = {
        id: uuid4(),
        name: product.name,
        parentProduct: product.id,
        priceImpact: product.priceImpact,
        modifiers: product.modifiers,
        modifierGroups: product.modifierGroups,
        comments: product.comments,
        quantity: 1,
        course: product.course || 'Main',
        categoryId: this.getCategory(this.selectedCategoryIndex).id
      }

      let mandatory = product.modifierGroups.some(group => group.min > 0)
      if (mandatory) {
        this.editingProduct = comboProduct
        this.$router.push({
          name: 'comboProductEditor',
          params: {
            product: this.editingProduct,
            catalogCombo: this.savedCatalogCombo
          }
        })
        return
      } else {
        this.editingProduct = null
        if (!this.selectedProducts[this.selectedComboIndex]) {
          this.selectedProducts[this.selectedComboIndex] = {}
        }

        this.selectedProducts = {
          ...this.selectedProducts,
          [this.selectedComboIndex]: {
            ...this.selectedProducts[this.selectedComboIndex],
            [comboProduct.id]: comboProduct
          }
        }
      }

      while (
        this.selectedComboIndex < this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        )
      )
        this.selectedComboIndex++

      if (
        this.selectedComboIndex == this.savedCombos.length - 1 &&
        this.categoryIsMaxed(
          this.selectedComboIndex,
          this.selectedCategoryIndex
        ) &&
        this.selectedCategoryIndex !=
          this.savedCatalogCombo.categories.length - 1
      ) {
        this.selectCategory(this.selectedCategoryIndex + 1)
      }

      this.goTo(this.selectedComboIndex)
    },
    preselectProduct(product) {
      let mandatory = product.modifierGroups.some(group => group.min > 0)
      if (mandatory) {
        this.manualInterventionIds.push(product.id)
        return
      }
      let comboProduct = {
        id: uuid4(),
        name: product.name,
        parentProduct: product.id,
        priceImpact: product.priceImpact,
        modifiers: product.modifiers,
        modifierGroups: product.modifierGroups,
        comments: product.comments,
        quantity: 1,
        course: product.course || 'Main',
        categoryId: product.categoryId
      }

      this.selectedProducts = {
        ...this.selectedProducts,
        [this.selectedComboIndex]: {
          ...this.selectedProducts[this.selectedComboIndex],
          [comboProduct.id]: comboProduct
        }
      }
    },
    preselectSelectedByDefault() {
      if (!this.catalogCombo) return
      this.catalogCombo.categories
        .filter(category => category.selectedByDefault)
        .flatMap(category => category.products)
        .forEach(categoryProduct => this.preselectProduct(categoryProduct))
    }
  },
  computed: {
    ...mapState('tabs', ['tabs']),
    mapCategoryMaxed() {
      if (!this.savedCatalogCombo) {
        return []
      }
      return this.savedCombos.map((_, comboIndex) => {
        return this.savedCatalogCombo.categories.map((_, index) => {
          return this.categoryIsMaxed(comboIndex, index)
        })
      })
    },
    mapCategorySelectedProducts() {
      if (!this.savedCatalogCombo) {
        return []
      }
      return this.savedCombos.map((_, comboIndex) => {
        return this.savedCatalogCombo.categories.map((_, index) => {
          return this.categorySelectedProducts(comboIndex, index)
        })
      })
    },
    selectedCategoryId() {
      return this.catalogCombo.categories[this.selectedCategoryIndex].id
    },
    selectedSeat() {
      return this.$route.query.selectedSeat
    }
  },
  components: {
    Icon,
    MenuProduct,
    FloatingButton,
    LPill
  }
}
</script>

<style>
.separator {
  height: 1px;
}
</style>
