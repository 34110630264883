<template>
  <div
    class="absolute top-0 left-0 z-20 flex items-center justify-center w-screen h-screen overflow-hidden blur-background"
    @click="$emit('close')"
  >
    <div>
      <div class="pb-6 text-2xl text-center text-white uppercase">
        {{ $t('checkout.fast') }} {{ paymentMethod.name }}
      </div>
      <div class="relative p-16 bg-white shadow rounded-big" @click.stop>
        <icon
          name="close"
          class="absolute top-0 right-0 m-4 text-gray-400"
          @click.native="$emit('close')"
        />
        <div v-if="confirming" class="text-xl text-blue">
          {{ $t('checkout.confirm-tip') }} {{ change | currency }} ?
          <div class="flex pt-10">
            <div
              class="flex-1 p-3 px-4 font-bold text-center text-white uppercase border bg-blue border-blue-b rounded-small"
              @click="confirming = false"
            >
              {{ $t('checkout.cancel') }}
            </div>
            <div
              class="flex items-center justify-center flex-1 p-3 px-4 ml-2 font-bold text-white uppercase border bg-red border-red-b rounded-small"
              @click="charge(true)"
            >
              {{ $t('checkout.charge') }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="flex p-2 mb-3 border border-gray-400 rounded-small">
            <div class="flex-1 text-center text-red" @click="fullAmount()">
              <div class="text-sm uppercase">{{ $t('checkout.pending') }}</div>
              <div class="text-xl font-bold">{{ bill.pending | currency }}</div>
            </div>
            <div
              class="flex-1 text-center text-gray-400 border-l border-r border-gray-300"
            >
              <div class="text-sm uppercase">{{ $t('checkout.to-pay') }}</div>
              <div class="text-xl font-bold">{{ toPay | currency }}</div>
            </div>
            <div class="flex-1 text-center text-green">
              <div
                class="text-sm uppercase"
                v-if="paymentMethod.type === 'cash'"
              >
                {{ $t('checkout.change') }}
              </div>
              <div class="text-sm uppercase" v-else>
                {{ $t('checkout.tip') }}
              </div>
              <div class="text-xl font-bold">{{ change | currency }}</div>
            </div>
          </div>
          <div
            class="overflow-hidden text-xl font-bold bg-gray-400 border border-gray-400 calculator rounded-small text-blue"
          >
            <div
              v-for="n in numbers"
              :key="n"
              :class="'number-' + n"
              class="flex items-center justify-center bg-white"
              @click="append(n)"
            >
              {{ n }}
            </div>
            <div
              class="flex items-center justify-center bg-white double-cero"
              @click="doubleCero()"
            >
              00
            </div>
            <div
              class="flex items-center justify-center bg-white text-green"
              @click="clear()"
            >
              c
            </div>
          </div>
          <div class="flex pt-4">
            <div
              v-if="!isMobile"
              class="p-3 px-4 font-bold text-white uppercase border bg-blue border-blue-b rounded-small mr-2"
              @click="checkout"
              :class="{ 'opacity-50 pointer-events-none': !!charging }"
            >
              {{ $t('checkout.checkout') }}
            </div>
            <div
              class="flex items-center justify-center flex-1 p-3 font-bold text-white uppercase border bg-red border-red-b rounded-small"
              @click="charge()"
              :class="{ 'opacity-50 pointer-events-none': !canCharge }"
            >
              <icon :name="paymentMethod.type" class="mr-2" />
              {{ $t('checkout.charge') }} {{ toCharge | currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <close-tab :tab-id="closeTabId" @cancel="closeTabId = null" />
  </div>
</template>

<script>
import Bills from '@last/core/src/billsGenerator.js'
import { mapState, mapGetters, mapActions } from 'vuex'
import Icon from '@last/core-ui/components/Icon.vue'
import Printer from '@/printer.js'
import CloseTab from './CloseTab.vue'
import Logger from '@/logger.js'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'
import Dataphone from '@/integrations/dataphone/dataphone.js'
import { iZettlePlugin } from '@/iZettlePlugin.js'
import { SumUpPlugin } from '@/SumUpPlugin.js'
import numberKeysMixin from '@last/core-ui/mixins/numberKeysMixin'
import TicketPrinter from '@/ticketPrinter.js'
import uuid4 from 'uuid/v4'
import app from '@/app.js'

export default {
  name: 'FastCheckout',
  mixins: [numberKeysMixin],
  props: {
    tabId: {
      type: String,
      default: null
    },
    paymentMethod: {
      type: Object,
      default: () => {
        return { type: 'cash' }
      }
    }
  },
  data() {
    return {
      numbers: [7, 8, 9, 4, 5, 6, 1, 2, 3, 0],
      confirming: false,
      closeTabId: null,
      isMobile: false,
      charging: false
    }
  },
  mounted() {
    this.isMobile = app.isMobile
    this.prefillToPay()
    this.initNumberKeysListener(
      this.append.bind(this),
      this.delete.bind(this),
      this.clear.bind(this),
      this.charge.bind(this)
    )
  },
  methods: {
    ...mapActions('tabs', ['addBill', 'addPayment', 'closeTab']),
    append(number) {
      if (this.charging) return
      this.toPay = this.toPay * 10 + number
    },
    doubleCero() {
      if (this.charging) return
      this.toPay = this.toPay * 100
    },
    clear() {
      if (this.charging) return
      this.toPay = 0
    },
    delete() {
      if (this.charging) return
      this.toPay = Math.floor(this.toPay / 10)
    },
    prefillToPay() {
      if (this.paymentMethod.type !== 'cash') {
        this.fullAmount()
      } else {
        this.toPay = 0
      }
    },
    fullAmount() {
      this.toPay = this.bill.pending
    },
    checkout() {
      this.$emit('close')
      this.$router.push({
        name: 'checkout',
        params: { tabId: this.tabId }
      })
    },
    async charge(avoidConfirm = false) {
      if (!this.canCharge) return
      this.charging = true
      let toCharge = Math.min(this.toCharge, 100_000_000)
      let paymentId = uuid4()
      let metadata = null
      try {
        if (
          this.paymentMethod.type === 'card' ||
          this.paymentMethod.type === 'izettle' ||
          this.paymentMethod.type === 'sumup'
        ) {
          if (this.change > 0 && !avoidConfirm) {
            this.confirming = true
            this.charging = false
          } else {
            this.chargeWithCard(toCharge, this.paymentMethod)
          }
        } else if (CashMachine.methods.includes(this.paymentMethod.type)) {
          let charged = await CashMachine.charge(toCharge)
          if (charged === 0) throw new Error('CashMachine charging failed')
          this.paymentSucceeded(toCharge, paymentId)
        } else if (this.paymentMethod.type === 'dataphone') {
          if (!Dataphone.initialized()) {
            throw new Error('Dataphone is not initialized')
          } else {
            metadata = await Dataphone.charge({
              amount: toCharge,
              tabId: this.tabId,
              paymentId
            })
            this.paymentSucceeded(toCharge, paymentId, metadata)
          }
        } else {
          this.paymentSucceeded(toCharge, paymentId)
        }
        if (this.paymentMethod.openDrawer) Printer.openCashDrawer()
      } catch (error) {
        this.$lnotification.create({
          title: `${this.$t('notifications.payment-failed')}${
            error.message ? `: ${error.message}` : ''
          }`
        })
      }
    },
    paymentSucceeded(amount, paymentId, metadata) {
      let bill = this.bill
      this.addBill({ tabId: this.tabId, bill })
      this.addPayment({
        billId: bill.id,
        paymentId,
        change: this.paymentMethod.type === 'cash' && this.change,
        tip: this.paymentMethod.type !== 'cash' && this.change,
        amount,
        type: this.paymentMethod.type,
        metadata,
        tabId: this.tabId
      })
      this.closeTab({ tabId: this.tabId, closedWithPin: false })
      if (
        ((this.tabs[this.tabId].tables?.length || 0) === 0 &&
          this.config.organizationConfig.takeawayAutoprint) ||
        this.config.organizationConfig.restaurantAutoprint
      ) {
        TicketPrinter.printBill(bill)
      }
      if (this.$route.name !== 'pos') {
        this.$router.push({ name: 'pos' })
      }
      this.$lnotification.create({
        title: this.$t('notifications.tab-closed')
      })
      this.$emit('close')
    },
    async chargeWithCard(amount, paymentMethod) {
      try {
        if (paymentMethod.type === 'izettle') {
          await iZettlePlugin.charge({ amount })
        } else if (paymentMethod.type === 'sumup') {
          await SumUpPlugin.charge({ amount })
        }
        this.paymentSucceeded(amount)
      } catch (error) {
        Logger.info('iZettle error', error)
      }
    }
  },
  computed: {
    ...mapGetters('tabs', ['getAllProducts', 'getBills', 'getPendingBill']),
    ...mapState('tabs', ['tabs']),
    ...mapState('config', ['config']),
    bill() {
      let pendingBills = this.getBills(this.tabId).filter(
        bill => bill.pending > 0
      )
      if (pendingBills.length > 0) {
        return pendingBills[0]
      } else {
        let bill = this.getPendingBill(this.tabId)
        return Bills.addPaymentInfo(bill, [])
      }
    },
    change() {
      return Math.max(0, this.toPay - this.bill.pending)
    },
    toCharge() {
      if (this.paymentMethod.type === 'cash') {
        return Math.min(this.bill.pending, this.toPay)
      } else {
        return this.toPay
      }
    },
    canCharge() {
      return this.toCharge >= this.bill.pending && !this.charging
    }
  },
  components: {
    Icon,
    CloseTab
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.calculator {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-row-gap: 0.062rem;
  grid-column-gap: 0.062rem;
  width: 22.75rem;
  height: 16.25rem;
}

.double-cero {
  grid-row-start: 4;
  grid-column-start: 1;
}
</style>
