var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full flex flex-col overflow-hidden"},[_c('top-bar',{attrs:{"show-back":""}},[_c('template',{slot:"center"},[_c('div',{staticClass:"text-xl flex items-center uppercase"},[_vm._v(" "+_vm._s(_vm.$t('menu-availability.products'))+" ")])])],2),_c('div',{staticClass:"overflow-y-scroll scrolling-touch bg-gray-200 w-full h-full flex justify-center min-h-screen"},[_c('div',{staticClass:"mt-10 bg-white rounded-big shadow w-3/5 h-full pb-10"},[_c('div',{staticClass:"flex flex-row cursor-pointer ml-4 mr-4"},_vm._l((_vm.types),function(editable){return _c('div',{key:editable,staticClass:"flex w-1/4 justify-center text-center items-center uppercase text-gray-400 p-8",class:{
            'text-blue border-b-4 border-blue': _vm.editingType === editable
          },on:{"click":function($event){return _vm.setEditingType(editable)}}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(("menu-availability." + (editable.replace('_', '-')))))+" ")])])}),0),_c('div',{staticClass:"m-8"},[_c('l-input',{attrs:{"placeholder":_vm.$t(
              ("menu-availability." + (_vm.editingType.replace(
                '_',
                '-'
              )) + "-search-placeholder")
            ),"icon":"search","icon-class":"text-gray-500"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{staticClass:"flex flex-row justify-start ml-8 mt-8 mb-8"},[_c('div',{staticClass:"flex justify-center text-center items-center"},[_c('l-pill',{staticClass:"mr-3",attrs:{"value":_vm.filterBy === 'ALL',"label":_vm.$t('menu-availability.filter-by-all')},on:{"input":function($event){return _vm.setFilterBy('ALL')}}})],1),_c('div',{staticClass:"flexjustify-center text-center items-center"},[_c('l-pill',{staticClass:"mr-3",attrs:{"value":_vm.filterBy === 'ENABLED',"label":_vm.$t('menu-availability.filter-by-enabled')},on:{"input":function($event){return _vm.setFilterBy('ENABLED')}}})],1),_c('div',{staticClass:"flex justify-center text-center items-center"},[_c('l-pill',{staticClass:"mr-3",attrs:{"value":_vm.filterBy === 'DISABLED',"label":_vm.$t('menu-availability.filter-by-disabled')},on:{"input":function($event){return _vm.setFilterBy('DISABLED')}}})],1)]),_vm._l((_vm.filteredItems),function(item){return _c('div',{key:item.id,staticClass:"flex relative"},[_c('div',{staticClass:"pl-8 pt-4 text-blue w-5/6"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"absolute right-0 mr-8 mt-4"},[_c('l-switch',{attrs:{"value":item.enabled},on:{"input":function (enabled) { return _vm.toggleEnabled(item, enabled); }}})],1)])})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }