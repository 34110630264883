<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen z-20 blur-background overflow-hidden flex justify-center items-center"
  >
    <l-modal
      @close="$emit('close')"
      :scrolleable="true"
      :size="isMobile ? 'large' : 'medium'"
      :title="$t('ordering.link-reservation')"
    >
      <template slot="body">
        <div class="popup-height">
          <div class="flex justify-between text-blue space-x-2">
            <l-radio class="w-1/16 invisible" big />
            <div class="flex w-1/12 justify-center" v-if="!isMobile">
              {{ $t('reservations.table') }}
            </div>
            <div class="flex w-3/12 justify-center">
              {{ $t('reservations.source') }}
            </div>
            <div class="flex w-1/12 justify-center">
              {{ $t('reservations.time') }}
            </div>
            <div class="flex w-3/12 justify-center">
              {{ $t('reservations.name') }}
            </div>
            <div class="flex w-3/12 justify-center" v-if="!isMobile">
              {{ $t('reservations.phone-number') }}
            </div>
            <div class="flex w-1/12 justify-center" v-if="!isMobile">
              {{ $t('reservations.tab') }}
            </div>
          </div>
          <div class="separator bg-gray-200 mt-2 -mx-8 mb-4" />
          <div
            v-for="reservation of getNearReservations"
            :key="reservation.id"
            class="flex justify-between items-center text-center text-gray-400 py-3 space-x-2"
          >
            <l-radio
              class="w-1/16"
              big
              :value="selectedReservationId === reservation.id"
              @input="selectedReservationId = reservation.id"
            />
            <div class="flex w-1/12 justify-center" v-if="!isMobile">
              {{ formatTables(reservation.tables) }}
            </div>
            <div class="flex w-3/12 justify-center">
              {{ reservation.source }}
            </div>
            <div class="flex w-1/12 justify-center">
              {{ formatDate(reservation.dateTime) }}
            </div>
            <div class="flex w-3/12 justify-center">
              {{ reservation.name + ' ' + reservation.surname }}
            </div>
            <div class="flex w-3/12 justify-center" v-if="!isMobile">
              {{ formatPhone(reservation.phoneNumber) }}
            </div>
            <div class="flex w-1/12 justify-center" v-if="!isMobile">
              {{ formatTab(reservation.tabId) }}
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <div class="flex justify-end space-x-2">
          <l-modal-button
            type="secondary"
            :label="$t('dialog.cancel')"
            :size="isMobile ? 'small' : 'medium'"
            @click.native="$emit('close')"
            class="flex-1"
          />
          <l-modal-button
            type="main"
            :label="$t('dialog.continue')"
            :size="isMobile ? 'small' : 'medium'"
            :disabled="!selectedReservationId"
            @click.native="save()"
            class="flex-1"
          />
        </div>
      </template>
    </l-modal>
  </div>
</template>

<script>
import LModal from '@last/core-ui/components/LModal.vue'
import LModalButton from '@last/core-ui/components/LModalButton.vue'
import LRadio from '@last/core-ui/components/LRadio.vue'
import moment from 'moment'
import parsePhoneNumberFromString from 'libphonenumber-js'
import app from '@/app.js'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'LinkReservation',
  props: {
    tabId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isMobile: null,
      selectedReservationId: null
    }
  },
  mounted() {
    this.isMobile = app.isMobile
    this.selectedReservationId =
      Object.values(this.getNearReservations).find(
        reservation => reservation.tabId === this.tabId
      ).id || null
  },
  methods: {
    ...mapActions('reservations', ['editReservation']),
    formatDate(date) {
      return moment(date).format('HH:mm')
    },
    formatPhone(phoneNumber) {
      return parsePhoneNumberFromString(phoneNumber).number
    },
    formatTables(reservationTables) {
      return (reservationTables || [])
        .map(table => this.tables[table]?.name)
        .toString()
    },
    formatTab(tab) {
      return tab ? this.tabs[tab]?.code : null
    },
    async save() {
      let tabIdAlreadyLinked = Object.values(this.getNearReservations).find(
        reservation => reservation.tabId === this.tabId
      )
      if (tabIdAlreadyLinked) {
        let oldReservation = {
          ...tabIdAlreadyLinked,
          tabId: null
        }
        this.editReservation(oldReservation)
      }
      let reservation = {
        ...this.getNearReservations[this.selectedReservationId],
        tabId: this.tabId
      }
      this.editReservation(reservation)
      this.$lnotification.create({
        title: this.$t('link-reservation.reservation-linked'),
        icon: 'chain',
        iconColor: 'red'
      })
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters('reservations', ['getNearReservations']),
    ...mapState('tables', ['tables']),
    ...mapState('tabs', ['tabs'])
  },
  components: {
    LModal,
    LModalButton,
    LRadio
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.popup-height {
  height: 490px;
}
</style>
