<template>
  <div class="p-10 bg-white shadow rounded-big">
    <div class="pb-2 text-xl font-bold text-blue">
      {{ $t('report.general') }}
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.first-bill') }}</div>
      <div>{{ report.firstBill || '-' }}</div>
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.last-bill') }}</div>
      <div>{{ report.lastBill || '-' }}</div>
    </div>
    <div class="pt-4 pb-2 text-xl font-bold text-blue">
      {{ $t('report.tabs-per-source') }}
    </div>
    <div
      v-for="(total, source) in report.tabsPerSource"
      class="flex justify-between"
      :key="source"
    >
      <div class="text-blue">{{ source }}</div>
      <div>{{ total }}</div>
    </div>
    <div class="text-blue font-bold text-xl pb-2 pt-4">
      {{ $t('report.breakdown') }}
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.subtotal') }}</div>
      <div>{{ report.breakdown.taxableBase | currency }}</div>
    </div>
    <div
      v-for="(taxRate, taxPercentage) in report.breakdown.taxRates"
      :key="taxPercentage"
      class="flex justify-between"
    >
      <div class="text-blue">
        {{ `${report.breakdown.taxLabel} (${taxPercentage}%)` }}
      </div>
      <div>{{ taxRate.tax | currency }}</div>
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.total') }}</div>
      <div>{{ report.breakdown.total | currency }}</div>
    </div>
    <br />
    <div class="flex justify-between">
      <div
        :class="{ 'font-bold': report.breakdown.pending > 0 }"
        class="text-blue"
      >
        {{ $t('report.pending') }}
      </div>
      <div>{{ report.breakdown.pending | currency }}</div>
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.discounts') }}</div>
      <div>{{ report.breakdown.discounts | currency }}</div>
    </div>
    <div class="pt-4 pb-2 text-xl font-bold text-blue">
      {{ $t('report.payments') }}
    </div>
    <div
      v-for="(amount, method) in report.payments"
      class="flex justify-between"
      :key="method"
    >
      <div class="text-blue">{{ method }}</div>
      <div>{{ amount | currency }}</div>
    </div>
    <div class="pt-4 pb-2 text-xl font-bold text-blue">
      {{ $t('report.tips') }}
    </div>
    <div
      v-for="(amount, method) in report.tips"
      class="flex justify-between"
      :key="method"
    >
      <div class="text-blue">{{ method }}</div>
      <div>{{ amount | currency }}</div>
    </div>
    <div class="pt-4 pb-2 text-xl font-bold text-blue">
      {{ $t('report.payments-without-tips') }}
    </div>
    <div
      v-for="(amount, method) in report.paymentsWithoutTips"
      class="flex justify-between"
      :key="method"
    >
      <div class="text-blue">{{ method }}</div>
      <div>{{ amount | currency }}</div>
    </div>
    <div class="pt-4 pb-2 text-xl font-bold text-blue">
      {{ $t('report.pays-in-and-out') }}
    </div>
    <div>
      <div class="flex justify-between">
        <div class="text-blue">{{ $t('report.pay-in') }}</div>
        <div>{{ report.totalIns | currency }}</div>
      </div>
      <div class="flex justify-between">
        <div class="text-blue">{{ $t('report.pay-out') }}</div>
        <div>{{ report.totalOuts | currency }}</div>
      </div>
    </div>
    <div class="pt-4 pb-2 text-xl font-bold text-blue">
      {{ $t('report.stats') }}
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.total-tabs') }}</div>
      <div>{{ report.stats.totalTabs }}</div>
    </div>
    <div class="flex justify-between">
      <div
        :class="{ 'font-bold': report.stats.openTabs > 0 }"
        class="text-blue"
      >
        {{ $t('report.open-tabs') }}
      </div>
      <div>{{ report.stats.openTabs }}</div>
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.total-seats') }}</div>
      <div>{{ report.stats.totalSeats }}</div>
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.average-seats') }}</div>
      <div>{{ report.stats.averageSeats }}</div>
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.average-per-tab') }}</div>
      <div>{{ report.stats.averagePerTab | currency }}</div>
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('report.average-per-seat') }}</div>
      <div>{{ report.stats.averagePerSeat | currency }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportPreview',
  props: {
    report: {
      type: Object,
      default: null
    }
  }
}
</script>
