import CashGuardDirect from '@/integrations/cashmachine/cashguarddirect.js'
import CashGuardRest from '@/integrations/cashmachine/cashguardrest.js'

class CashGuard {
  init(config) {
    if (config.type === 'ip') {
      this.machine = CashGuardRest
    } else {
      this.machine = CashGuardDirect
    }
    this.machine.init(config)
  }

  charge(amount) {
    return this.machine.charge(amount)
  }

  payIn(amount) {
    return this.machine.payIn(amount)
  }

  payOut(amount) {
    return this.machine.payOut(amount)
  }

  cancel() {
    return this.machine.cancel()
  }

  close() {
    return this.machine.close()
  }
}

export default new CashGuard()
