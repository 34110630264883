<template>
  <div class="bg-gray-100" :class="{ 'p-2': !small }">
    <div v-if="!tab.open" class="flex w-full"></div>
    <div v-else-if="!tab.activationTime" class="flex w-full">
      <div
        :class="{ 'small text-sm': small, big: !small }"
        class="w-1/2 btn border-green-b bg-green cursor-pointer"
        @click="activate"
      >
        {{ $t('tabs.activate') }}
      </div>
    </div>
    <div
      v-else-if="isDeliveryOrOwnDelivery && (!alreadyBilled || small)"
      class="flex w-full"
    >
      <div
        :class="{ 'small text-sm': small, big: !small }"
        class="w-1/2 btn border-red-b bg-red cursor-pointer"
        @click="goToDeliveryCheckout"
      >
        {{ $t('tabs.delivery-checkout') }}
      </div>
    </div>
    <div v-else-if="isDeliveryOrOwnDelivery && small" class="flex w-full">
      <div
        :class="{ 'small text-sm': small, big: !small }"
        class="w-1/2 btn border-red-b bg-red cursor-pointer"
        @click="done"
      >
        {{ $t('tabs.done') }}
      </div>
    </div>
    <div
      v-else-if="
        isDeliveryOrOwnDelivery &&
        !small &&
        alreadyBilled &&
        pendingPaymentsAndPreferredPaymentMethod
      "
      class="flex w-full"
      :class="{ 'mt-2': !small }"
    >
      <div
        v-if="!preferredPaymentMethodIsOwnDelivery"
        class="w-1/2 btn border-red-b bg-red cursor-pointer"
        :class="{ 'small text-sm': small, big: !small }"
        @click="closeTabId = tabId"
      >
        {{ $t('tabs.close-with') }}
        {{ tab.deliveryOrder.preferredPaymentMethod }}
      </div>
    </div>
    <div
      v-else-if="
        (!tab.pickupType === 'takeAway' || fullPaid) && !small && alreadyBilled
      "
      class="flex w-full"
      :class="{ 'mt-2': !small }"
    >
      <div
        class="w-1/2 btn border-red-b bg-red cursor-pointer"
        :class="{ 'small text-sm': small, big: !small }"
        @click="closeTabId = tabId"
      >
        {{ $t('tabs.close') }}
      </div>
    </div>
    <div
      v-else-if="tab.activationTime"
      class="flex w-full"
      :class="{ 'mt-2': !small }"
    >
      <div
        class="w-1/2 btn border-red-b bg-red cursor-pointer"
        :class="{ 'small text-sm': small, big: !small }"
        @click="checkout"
      >
        {{ $t('tabs.checkout') }}
      </div>
      <div
        v-if="fastCheckoutAllowed"
        class="flex w-1/2"
        :class="{ 'ml-1 pl-1': !small }"
      >
        <div
          v-if="quickPaymentMethods.length > 0"
          class="btn border-green-b bg-green cursor-pointer"
          :class="{ 'small text-sm': small, big: !small }"
          @click="fastCheckout = paymentMethods[0]"
        >
          {{ quickPaymentMethods[0].name }}
        </div>
        <div
          v-if="quickPaymentMethods.length > 1"
          class="btn border-light-blue-b bg-light-blue cursor-pointer"
          :class="{ 'small text-sm': small, 'big ml-2': !small }"
          @click="fastCheckout = paymentMethods[1]"
        >
          {{ quickPaymentMethods[1].name }}
        </div>
      </div>
    </div>
    <div
      v-if="!(isDeliveryOrOwnDelivery && small) && tab.activationTime"
      class="flex w-full"
      :class="{ 'mt-2': !small }"
    >
      <div
        v-if="!tab.open"
        class="relative btn border-blue-b bg-red cursor-pointer"
        :class="{ 'small font-bold': small, 'big mr-2': !small }"
        @click="reopenTab({ tabId: tab.id })"
      >
        <icon name="reuse" class="mr-2" />
        {{ $t('reopen') }}
      </div>
      <div
        v-else-if="config.enableKitchenOrders"
        class="relative btn border-blue-b bg-blue cursor-pointer"
        :class="{ 'small font-bold': small, 'big mr-2': !small }"
        @click="order()"
      >
        <icon name="kitchen" class="p-1 mr-2" />
        {{ $t('tabs.order') }}
        <div
          v-if="pendingOrders || ordersNotPrinted"
          class="absolute top-0 right-0 border border-white rounded-full notification-circle bg-yellow"
        />
      </div>
      <div
        class="btn border-blue-b bg-blue cursor-pointer"
        :class="{
          'small font-bold': small,
          'bg-dark-blue': small,
          big: !small
        }"
        @click="bill()"
      >
        <icon name="ticket" class="p-1 mr-2" />
        {{ $t('tabs.bill') }}
      </div>
    </div>
    <fast-checkout
      v-if="fastCheckout"
      :tab-id="tabId"
      @close="fastCheckout = null"
      :payment-method="fastCheckout"
    />
    <resend-kitchen-orders
      v-if="resendKitchenOrders"
      :tab-id="tabId"
      @close="resendKitchenOrders = false"
    />
    <print-bills
      v-if="printBills"
      :tab-id="tabId"
      @close="printBills = false"
    />
    <close-tab
      :tab-id="closeTabId"
      @cancel="closeTabId = null"
      @tab-closed="closeTabId = null"
      :should-add-payment-for-delivery="
        pendingPaymentsAndPreferredPaymentMethod
      "
      :payment-data="paymentData"
    />
    <payment-method-selector
      v-if="selectPaymentMethod"
      @close="selectPaymentMethod = false"
      :payment-methods="deliveryPaymentMethods"
      @select="setPaymentMethod"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Icon from '@last/core-ui/components/Icon.vue'
import FastCheckout from '@/components/checkout/FastCheckout.vue'
import ResendKitchenOrders from '@/components/ordering/ResendKitchenOrders.vue'
import kitchenOrdersMixin from '@/components/ordering/kitchenOrdersMixin.js'
import billsMixin from '@/components/ordering/billsMixin.js'
import PrintBills from '@/components/ordering/PrintBills.vue'
import CloseTab from '@/components/checkout/CloseTab.vue'
import PaymentMethodSelector from '@/components/checkout/PaymentMethodSelector.vue'

export default {
  name: 'TabActions',
  mixins: [kitchenOrdersMixin, billsMixin],
  props: {
    tabId: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fastCheckout: false,
      resendKitchenOrders: false,
      printBills: false,
      closeTabId: null,
      selectPaymentMethod: false,
      selectedPaymentMethod: null
    }
  },
  computed: {
    ...mapState('tabs', ['kitchenOrders']),
    ...mapState('config', ['config']),
    ...mapGetters('tabs', [
      'getTab',
      'getBills',
      'getUnsentProducts',
      'getPendingBill'
    ]),
    paymentMethods() {
      return this.config.paymentMethodsConfig.map(method => ({
        ...method,
        type: method.name.toLowerCase()
      }))
    },
    paymentData() {
      if (!this.pendingPaymentsAndPreferredPaymentMethod) {
        return null
      }
      return {
        billId: this.tab.bills[0],
        amount: this.getBills(this.tabId)
          .flatMap(
            bill =>
              bill.total -
              bill.payments.reduce(
                (total, payment) => payment.amount + total,
                0
              )
          )
          .reduce((sum, billTotal) => sum + billTotal, 0),
        type: this.tab.deliveryOrder.preferredPaymentMethod
      }
    },
    deliveryPaymentMethods() {
      return this.paymentMethods.filter(method => method.availableForDelivery)
    },
    quickPaymentMethods() {
      return this.paymentMethods.slice(0, 2)
    },
    tab() {
      return this.getTab(this.tabId)
    },
    fullPaid() {
      let bills = this.getBills(this.tabId)
      let pendingBill = this.getPendingBill(this.tabId)
      return (
        this.alreadyBilled &&
        (!pendingBill || pendingBill?.total === 0) &&
        !bills.some(bill => bill.pending > 0)
      )
    },
    isDeliveryOrOwnDelivery() {
      return ['delivery', 'ownDelivery'].includes(this.tab.pickupType)
    },
    alreadyBilled() {
      return (
        this.tab.bills.length > 0 &&
        (this.tab.pickupType !== 'takeAway' ||
          this.getBills(this.tabId).filter(bill => bill.pending > 0).length ===
            0)
      )
    },
    pendingPaymentsAndPreferredPaymentMethod() {
      let bills = this.getBills(this.tabId)
      let totalPending = bills.reduce(
        (sum, bill) =>
          sum +
          bill.total -
          bill.payments.reduce((total, payment) => total + payment.amount, 0),
        0
      )
      return (
        totalPending > 0 &&
        this.tab.deliveryOrder &&
        !!this.tab.deliveryOrder.preferredPaymentMethod
      )
    },
    preferredPaymentMethodIsOwnDelivery() {
      return (
        this.tab.deliveryOrder &&
        this.tab.deliveryOrder.preferredPaymentMethod?.toLowerCase() ===
          'owndelivery'
      )
    }
  },
  methods: {
    ...mapActions('tabs', [
      'createKitchenOrders',
      'startBilling',
      'removeTabBills',
      'setPreferredPaymentMethod',
      'activateTab',
      'reopenTab'
    ]),
    goToDeliveryCheckout() {
      this.$router.push({
        name: 'deliveryCheckout',
        params: { tabId: this.tabId }
      })
    },
    checkout() {
      this.$router.push({
        name: 'checkout',
        params: { tabId: this.tabId, referrer: this.$route.name }
      })
    },
    printKitchenOrder() {
      this.sendKitchenOrder('all')
    },
    order() {
      if (this.pendingOrders) {
        this.printKitchenOrder()
        if (this.$route.name !== 'pos') {
          this.$router.push({
            name: 'pos'
          })
        }
      } else {
        this.resendKitchenOrders = true
      }
    },
    done() {
      if (this.deliveryPaymentMethods.length === 1) {
        this.selectedPaymentMethod = this.deliveryPaymentMethods[0].type
      }
      if (
        this.tab.pickupType === 'ownDelivery' &&
        !this.selectedPaymentMethod
      ) {
        this.selectPaymentMethod = true
      } else {
        this.generateBillsAndLeave()
      }
    },
    setPaymentMethod(method) {
      this.selectedPaymentMethod = method
      this.selectPaymentMethod = false
      this.setPreferredPaymentMethod({
        tabId: this.tabId,
        preferredPaymentMethod: method
      })
      this.generateBillsAndLeave()
    },
    generateBillsAndLeave() {
      if (this.pendingOrders && !this.tabs[this.tabId].schedulingTime) {
        this.removeTabBills(this.tabId)
        this.printBill()
        if (this.config.enableKitchenOrders) {
          this.printKitchenOrder()
        }
      }
      this.$router.push({
        name: 'pos'
      })
    },
    activate() {
      this.activateTab({ tab: this.tabs[this.tabId], sendEvent: true })
    }
  },
  components: {
    Icon,
    FastCheckout,
    ResendKitchenOrders,
    PrintBills,
    CloseTab,
    PaymentMethodSelector
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.btn {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply text-white;
  @apply uppercase;
  @apply flex-1;
}

.big {
  @apply p-4;
  @apply rounded-small;
  @apply font-bold;
}

.small {
  @apply py-4;
  @apply px-3;
}

.disabled {
  color: #a0a0c0;
  background: #d3d3e6;
  pointer-events: none;
}

.bg-dark-blue {
  background: #302f66;
}

.notification-circle {
  margin: 0.5rem;
  width: 0.625rem;
  height: 0.625rem;
}
</style>
