import api from '@/api.js'

const state = {
  couriers: {}
}

const getters = {}

const actions = {
  async refreshCouriers({ commit }, couriers) {
    if (!couriers) {
      couriers = (await api.get('/couriers')).data
    }
    commit('updateCouriers', couriers)
  }
}

const mutations = {
  updateCouriers(state, couriers) {
    couriers = couriers.reduce((res, courier) => {
      res[courier.id] = courier
      return res
    }, {})
    state.couriers = couriers
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
