<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen z-20 blur-background overflow-hidden flex justify-center items-center"
  >
    <div v-if="loading" class="flex flex-col justify-center items-center">
      <l-loading-spinner size="medium"></l-loading-spinner>
      <div class="text-white text-center mt-4 uppercase text-xl">
        {{ $t('link-customer.processing') }}
      </div>
    </div>
    <div
      v-if="showManualInput && !loading"
      class="modal max-h-full overflow-y-scroll scrolling-touch relative"
    >
      <div class="flex items-center justify-between">
        <div class="title text-white uppercase text-2xl py-6 text-left">
          {{
            isHotel
              ? $t('link-customer.type-identifier')
              : $t('link-customer.type-phone-number')
          }}
        </div>
        <div @click="$emit('close')" class="flex-end py-6 pl-6 cursor-pointer">
          <icon name="close" class="text-white" />
        </div>
      </div>

      <div class="inner-modal bg-white rounded-big shadow px-10 py-10 relative">
        <l-field
          :label="
            isHotel
              ? $t('link-customer.identifier')
              : $t('link-customer.phone-number')
          "
          class="w-full"
        >
          <l-input
            ref="identifierInput"
            v-model="identifier"
            :icon="isHotel ? '' : phone"
            icon-class="text-blue"
            :placeholder="
              isHotel
                ? $t('link-customer.type-identifier')
                : $t('link-customer.type-phone-number')
            "
          />
        </l-field>
      </div>
      <div class="buttons-container flex mt-5">
        <div
          v-if="deviceHasCamera()"
          class="flex-1 mr-5 uppercase py-4 px-6 bg-blue text-white font-bold rounded-lg text-center cursor-pointer"
          @click="startQRScan()"
        >
          {{ $t('link-customer.scan-qr') }}
        </div>
        <div
          class="flex-1 uppercase py-4 px-6 bg-red text-white font-bold rounded-lg text-center cursor-pointer"
          @click="save(identifier)"
        >
          {{ $t('link-customer.save') }}
        </div>
      </div>
    </div>
    <div v-if="!showManualInput && !loading">
      <q-r-scanner
        @input="value => save(value)"
        @close="$emit('close')"
        @cancel="activateManualInput()"
        @error="
          activateManualInput()
          deviceCameraError = true
        "
      ></q-r-scanner>
    </div>
  </div>
</template>

<script>
import LField from '@last/core-ui/components/LField.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import QRScanner from './QRScanner.vue'
import LLoadingSpinner from '@last/core-ui/components/LLoadingSpinner.vue'
import { Capacitor } from '@capacitor/core'
import api from '@/api'
import { mapState } from 'vuex'

export default {
  name: 'LinkCustomer',
  props: {
    tabId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      currentPlatform: Capacitor.getPlatform(),
      showManualInput: false,
      deviceCameraError: false,
      identifier: '',
      loading: false
    }
  },
  mounted() {
    if (!this.deviceHasCamera()) {
      this.activateManualInput()
    }
  },
  methods: {
    stopQRScan(value) {
      this.identifier = value
      this.activateManualInput()
    },
    startQRScan() {
      this.showManualInput = false
    },
    deviceHasCamera() {
      return (
        (this.currentPlatform === 'android' ||
          this.currentPlatform === 'ios') &&
        !this.deviceCameraError
      )
    },
    async save(value) {
      this.loading = true
      try {
        await api.post(`/tabs/${this.tabId}/customer`, {
          identifier: value,
          isHotel: this.isHotel
        })
        this.$lnotification.create({
          title: this.$t('link-customer.customer-linked'),
          subtitle: this.$t('link-customer.customer-linked-successfully'),
          icon: 'check',
          iconColor: 'green'
        })
        this.$emit('close')
      } catch (e) {
        this.$lnotification.create({
          title: this.$t('link-customer.error'),
          subtitle: this.$t('link-customer.customer-linked-error'),
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.loading = false
    },
    async activateManualInput() {
      this.showManualInput = true
      await this.$nextTick()
      this.$refs.identifierInput.focus()
    }
  },
  computed: {
    ...mapState('config', ['config']),
    isHotel() {
      return this.config.isHotel ?? false
    }
  },
  components: {
    LField,
    Icon,
    LInput,
    QRScanner,
    LLoadingSpinner
  },
  watch: {}
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}
.modal {
  width: 80%;
  max-width: 500px;
}
@media (max-width: 640px) {
  .title {
    text-align: center;
    font-size: 1.25rem;
  }
  .inner-modal {
    padding: 1.2rem 1.2rem;
  }
  .buttons-container {
    margin-bottom: 1.5rem;
  }
}
</style>
