import ProductsGrouper from '@/productsGrouper.js'

function groupByCourse(products) {
  return products.reduce((groupedProducts, product) => {
    groupedProducts[product.course] = [
      ...(groupedProducts[product.course] || []),
      product
    ]
    return groupedProducts
  }, {})
}

function getModifierId(modifier) {
  return modifier.tabModifierId || modifier.catalogModifierId
}

function mapModifiers(oldModifiers, newModifiers) {
  let newModifierIds = newModifiers.map(getModifierId)
  let oldModifierIds = oldModifiers.map(getModifierId)
  let allModifiers = [...oldModifiers, ...newModifiers].reduce(
    (modifiers, modifier) => {
      modifiers[getModifierId(modifier)] = modifier
      return modifiers
    },
    {}
  )
  let orderedIds = [...newModifierIds, ...oldModifierIds].filter(
    (el, i, a) => i === a.indexOf(el)
  )
  return orderedIds
    .map(id => allModifiers[id])
    .map(modifier => ({
      name: modifier.name,
      quantity: modifier.quantity,
      deleted: !newModifierIds.includes(getModifierId(modifier)),
      added: !oldModifierIds.includes(getModifierId(modifier))
    }))
}

function mapProduct(oldProduct, newProduct) {
  let product = { ...oldProduct, ...newProduct }
  let modifiers = mapModifiers(
    (oldProduct && oldProduct.modifiers) || [],
    (newProduct && newProduct.modifiers) || []
  )
  let modified = modifiers.some(modifier => modifier.deleted || modifier.added)
  let quantity = {
    old: (oldProduct && oldProduct.quantity) || product.quantity,
    new: (newProduct && newProduct.quantity) || product.quantity
  }
  if (quantity.new === quantity.old) {
    quantity.current = quantity.new
  } else if (quantity.new > quantity.old && !modified) {
    quantity.current = quantity.new - quantity.old
  }
  let oldComments = oldProduct && oldProduct.comments
  let newComments = newProduct && newProduct.comments
  return {
    quantity,
    id: product.groupedId,
    deleted: !newProduct,
    added: !oldProduct,
    name: product.name,
    modified,
    modifiers,
    fromCombo: product.fromCombo,
    comboId: product.comboId,
    comboName: product.comboName,
    comments: {
      text: product.comments,
      changed: newComments !== oldComments
    },
    combine: product.combine,
    categoryPosition: product.categoryPosition
  }
}

function mapProducts(oldProducts, newProducts) {
  let oldProductIds = oldProducts.map(product => product.groupedId)
  let newProductIds = newProducts.map(product => product.groupedId)
  let allProductIds = [...new Set([...oldProductIds, ...newProductIds])]
  let oldProductsDict = oldProducts.reduce((products, product) => {
    products[product.groupedId] = product
    return products
  }, {})
  let newProductsDict = newProducts.reduce((products, product) => {
    products[product.groupedId] = product
    return products
  }, {})
  return allProductIds.map(id =>
    mapProduct(oldProductsDict[id], newProductsDict[id])
  )
}

function mapCourses(
  oldProducts,
  newProducts,
  onlyModifications,
  sortedCourses
) {
  //Main is the default course if the field is empty
  let oldCourses = Object.keys(oldProducts)
  let newCourses = Object.keys(newProducts)
  let allCourses = [...new Set([...oldCourses, ...newCourses, 'Main'])]
  let missingCourses = allCourses.filter(
    course => !sortedCourses.includes(course)
  )
  sortedCourses = [...sortedCourses, ...missingCourses]
  return sortedCourses
    .map(course => {
      let products = mapProducts(
        ProductsGrouper.group(oldProducts[course] || []),
        ProductsGrouper.group(newProducts[course] || [])
      ).filter(
        product =>
          !onlyModifications ||
          product.modified ||
          product.quantity.old != product.quantity.new ||
          product.deleted ||
          product.comments.changed ||
          product.added
      )
      let combinedComboId = 1
      let combos = products.reduce((res, product, position) => {
        let combo = res[
          product.combine ? combinedComboId : product.comboId || product.id
        ] || {
          comboId: product.comboId,
          comboName: product.comboName,
          position,
          products: []
        }
        combo.products = [...combo.products, product]
        res[
          product.combine ? combinedComboId : product.comboId || product.id
        ] = combo
        return res
      }, {})
      let combosAndProducts = Object.values(combos)
        .sort((c1, c2) => {
          if (!!c1.comboId != !!c2.comboId) {
            if (c1.comboId) return 1
            else return -1
          }
          return c1.position - c2.position
        })
        .flatMap(combo => {
          combo.products = combo.products.sort(
            (a, b) => a.categoryPosition - b.categoryPosition
          )
          if (combo.comboId) {
            return [combo]
          } else {
            return combo.products
          }
        })
      return {
        course,
        products: combosAndProducts
      }
    })
    .filter(course => course.products.length > 0)
}

function format(order, sortedCourses) {
  let newVersion = order.versions.slice(-1)[0]
  let oldVersion = newVersion
  let onlyModifications = false
  if (order.versions.length > 1) {
    oldVersion = order.versions.slice(-2)[0]
    onlyModifications = true
  }
  let newProducts = groupByCourse(newVersion.products)
  let oldProducts = groupByCourse(oldVersion.products)
  let courses = mapCourses(
    oldProducts,
    newProducts,
    onlyModifications,
    sortedCourses
  )
  let modifiedOrder = courses
    .flatMap(course => course.products)
    .flatMap(product => {
      if (product.comboId && !product.combine) {
        return product.products
      } else {
        return [product]
      }
    })
    .some(product => product.modified || product.deleted)
  return {
    ...order,
    courses,
    modifiedOrder
  }
}

export default {
  format
}
