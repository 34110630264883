import { datadogRum } from '@datadog/browser-rum'
import app from './app'
import logger from './logger'

async function init() {
  try {
    const { appVersion } = await app.getDeviceInfo()
    datadogRum.init({
      applicationId: '5a755f2b-36e1-4306-919c-b07cd0c2cb66',
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'pos',
      env: 'prod',
      version: appVersion,
      sessionSampleRate: 10,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowFallbackToLocalStorage: true
    })
    logger.info('Datadog RUM initialized', { appVersion })
  } catch (e) {
    logger.error('Failed to initialize Datadog RUM', e)
  }
}

init()
