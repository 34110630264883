<template>
  <div class="bg-gray-200 text-gray-400 py-4 px-5">
    <div class="flex flex-row mb-3">
      <div class="flex flex-row mr-5">
        <l-radio
          :value="mode === 'seats'"
          :label="$t('tabs.order-by-seats')"
          @input="mode = 'seats'"
          class="mr-2"
        />
      </div>
      <div class="flex flex-row">
        <l-radio
          :value="mode === 'courses'"
          :label="$t('tabs.order-by-courses')"
          @input="mode = 'courses'"
          class="mr-2"
        />
      </div>
    </div>
    <l-select
      v-if="mode === 'seats'"
      :placeholder="$t('tabs.seats')"
      :options="allSeats"
      :value="selectedSeat"
      @input="value => handleSeatSelected(value)"
      option-label="label"
      option-value="value"
    />
    <l-select
      v-else
      :placeholder="$t('tabs.courses')"
      :options="allCourses"
      :value="selectedCourse"
      @input="value => this.$emit('update:selectedCourse', value)"
      option-label="label"
      option-value="value"
    />
  </div>
</template>

<script>
import LSelect from '@last/core-ui/components/LSelect.vue'
import LRadio from '@last/core-ui/components/LRadio.vue'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'OpenTabProductSort',
  data() {
    return {
      course: null,
      mode: 'seats'
    }
  },
  mounted() {
    this.mode =
      ['seats', 'courses'].includes(this.tab.orderingMode) &&
      this.allCourses &&
      this.allCourses.length > 1
        ? this.tab.orderingMode
        : 'seats'
  },
  props: {
    selectedSeat: {
      type: Number,
      default: null
    },
    selectedCourse: {
      type: String,
      default: null
    }
  },
  methods: {
    ...mapActions('tabs', ['updateTabOrdering']),
    handleSeatSelected(value) {
      if (value !== -1) {
        this.$emit('update:selectedSeat', value)
      } else {
        this.$emit('add-seat')
        this.$emit('update:selectedSeat', this.tabSeats.length - 1)
      }
    }
  },
  computed: {
    ...mapState('catalog', ['catalogs']),
    ...mapState('config', ['config']),
    ...mapGetters('catalog', ['allCourses']),
    ...mapGetters('tabs', ['getSeatProducts', 'getTab']),
    tabId() {
      return this.$route.params.tabId
    },
    tab() {
      return this.getTab(this.tabId)
    },
    allSeats() {
      return [
        {
          label: this.$t('ordering.shared').toUpperCase(),
          value: null
        },
        ...this.tabSeats.map((seat, index) => {
          return {
            label: `${this.$t('ordering.seat')} ${index + 1}`.toUpperCase(),
            value: index
          }
        }),
        {
          label: this.$t('ordering.add-seat'),
          value: -1
        }
      ]
    },
    tabSeats() {
      return this.getSeatProducts(this.tabId)
    }
  },
  watch: {
    mode() {
      this.updateTabOrdering({ tabId: this.tabId, orderingMode: this.mode })
      if (this.mode === 'seats') this.$emit('update:selectedCourse', null)
    }
  },
  components: {
    LSelect,
    LRadio
  }
}
</script>

<style scoped></style>
