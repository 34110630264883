<template>
  <l-modal
    :title="$t('open-delivery.title')"
    size="small"
    class="py-32 font-body"
    @close="$emit('close')"
  >
    <template slot="body">
      <div class="text-xl text-center uppercase text-blue">
        {{ $t('open-delivery.text-1') }}
      </div>
      <div class="mt-1 mb-5 text-center text-gray-400">
        {{ $t('open-delivery.text-2') }}
      </div>
      <delivery-selector
        :virtual-brand-deliveries="virtualBrandsWithClosedDeliveries"
        :selected-deliveries.sync="selectedDeliveries"
        :current-time="currentTime"
        show-closed-until
        @openDelivery="(brandId, company) => openDelivery(brandId, company)"
        @openBrand="openBrand"
      />
      <l-modal-ctas>
        <l-modal-button
          type="main"
          :label="$t('open-delivery.cancel')"
          @click.native="$emit('close')"
          class="w-1/3 mr-3"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import { mapState, mapGetters } from 'vuex'
import DeliverySelector from './DeliverySelector'
import sync from '@/sync/service.js'

export default {
  name: 'OpenDeliveryPopUp',
  data() {
    return {
      selectedDeliveries: {}
    }
  },
  props: {
    currentTime: null
  },
  computed: {
    ...mapState('deliveryCompanies', ['virtualBrandsClosingTimes']),
    ...mapGetters('deliveryCompanies', ['virtualBrandsWithClosedDeliveries'])
  },
  methods: {
    openBrand(brandId) {
      let deliveriesToOpen = {}
      deliveriesToOpen[brandId] = Object.keys(
        this.virtualBrandsClosingTimes.find(brand => brand.id === brandId)
          .externalDeliveryCompaniesClosingTimes
      )
      sync.record('openDeliveries', {
        deliveriesToOpen
      })
      this.$lnotification.create({
        title: this.$t('open-delivery.open-brand')
      })
    },
    openDelivery(brandId, company) {
      let deliveriesToOpen = {}
      deliveriesToOpen[brandId] = [company]
      sync.record('openDeliveries', {
        deliveriesToOpen
      })
      this.$lnotification.create({
        title: this.$t('open-delivery.open-delivery') + ' ' + company
      })
    }
  },
  components: {
    DeliverySelector,
    LModal,
    LModalCtas,
    LModalButton
  }
}
</script>

<style scoped>
.arrow {
  transition: all 0.3s;
}

.rotated {
  transform: rotate(90deg);
}
</style>
