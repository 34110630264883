function minutesTimeFrom(fromDate, toDate) {
  if (!fromDate || !toDate) {
    return null
  }
  let secondsDifference = Math.round(
    (new Date(toDate).getTime() - new Date(fromDate).getTime()) / 1000
  )
  return {
    minutes: Math.floor(secondsDifference / 60)
  }
}

function getLastStatus(mappedStatuses) {
  return (
    mappedStatuses.DELIVERED ||
    mappedStatuses.ON_DELIVERY ||
    mappedStatuses.READY_TO_PICKUP ||
    mappedStatuses.KITCHEN ||
    mappedStatuses.CREATED
  )
}

function calculateDeliveryRecord(tab) {
  let mappedStatuses =
    tab?.deliveryOrder?.deliveryOrderStatuses.reduce((res, status) => {
      res[status.status] = status
      return res
    }, {}) || null
  if (!mappedStatuses) {
    return null
  }
  Object.keys(mappedStatuses).forEach(status => {
    let progressTime
    let progressColor
    switch (mappedStatuses[status].status) {
      case 'KITCHEN':
        progressTime = minutesTimeFrom(
          mappedStatuses[status].creationTime,
          mappedStatuses['READY_TO_PICKUP']?.creationTime
        )?.minutes
        if (progressTime > 20) {
          progressColor = 'error'
        } else if (progressTime > 15) {
          progressColor = 'yellow'
        } else {
          progressColor = 'light-blue'
        }
        break
      case 'READY_TO_PICKUP':
        progressTime = minutesTimeFrom(
          mappedStatuses[status].creationTime,
          mappedStatuses['ON_DELIVERY']?.creationTime
        )?.minutes
        if (progressTime === undefined) {
          progressTime = minutesTimeFrom(
            mappedStatuses[status].creationTime,
            mappedStatuses['DELIVERED']?.creationTime
          )?.minutes
        }
        if (progressTime > 10) {
          progressColor = 'error'
        } else if (progressTime > 3) {
          progressColor = 'yellow'
        } else {
          progressColor = 'light-blue'
        }
        break
      case 'ON_DELIVERY':
        progressTime = minutesTimeFrom(
          mappedStatuses[status].creationTime,
          mappedStatuses['DELIVERED']?.creationTime
        )?.minutes
        if (progressTime > 20) {
          progressColor = 'error'
        } else if (progressTime > 12) {
          progressColor = 'yellow'
        } else {
          progressColor = 'light-blue'
        }
        break
      default:
        break
    }
    mappedStatuses[status] = {
      ...mappedStatuses[status],
      progressTime: progressTime,
      progressColor
    }
  })
  return { mappedStatuses, lastStatus: getLastStatus(mappedStatuses) }
}

export default {
  calculateDeliveryRecord
}
