<template>
  <div class="bg-white rounded-big shadow p-10">
    <div class="text-blue font-bold text-xl pb-2">
      {{ $t('till-report.title') }}
      {{ name || tillNameByTillId(tillReport.tillId) }}
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('till-report.start-date') }}</div>
      <div>{{ tillReport.start | date }}</div>
    </div>
    <div class="flex justify-between">
      <div class="text-blue">{{ $t('till-report.end-date') }}</div>
      <div>{{ tillReport.end | date }}</div>
    </div>
    <br />
    <div class="pb-6 text-blue">
      <div class="mt-5">
        <div class="flex flex-row text-sm mb-2">
          <div class="flex-1" />
          <div class="flex-1 mr-5">
            {{ $t('report.theoretical') }}
          </div>
          <div class="flex-1">
            {{ $t('report.real') }}
          </div>
          <div class="flex-1 text-right">
            {{ $t('report.difference') }}
          </div>
        </div>
        <div
          v-for="amount in tillReport.amounts"
          :key="amount.method"
          class="mb-2"
        >
          <div class="flex justify-between">
            <div class="flex-1 font-bold">{{ amount.method }}:</div>
            <div class="flex-1 mr-5">
              {{ amount.expectedEnd | currency }}
            </div>
            <div class="flex-1">
              <div v-if="amount.end !== undefined">
                {{ amount.end | currency }}
              </div>
              <div v-else>
                -
              </div>
            </div>
            <div class="flex-1 text-right">
              <div v-if="amount.end !== undefined">
                {{ (amount.end - amount.expectedEnd) | currency }}
              </div>
              <div v-else>
                -
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="height-1-px border border-gray-300 my-2" />
      <div class="flex flex-row justify-between mt-1">
        <div class="flex-1 font-bold">{{ $t('report.total') }}:</div>
        <div class="flex-1 mr-5">
          {{ tillReport.expectedEndAmount | currency }}
        </div>
        <div class="flex-1">
          {{ tillReport.endAmount | currency }}
        </div>
        <div class="flex-1 text-right">
          {{ (tillReport.endAmount - tillReport.expectedEndAmount) | currency }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ReportPreview',
  props: {
    tillReport: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('config', ['tillNameByTillId']),
    shiftDiff() {
      if (this.tillReport && this.tillReport.endAmount !== undefined) {
        return this.tillReport.endAmount - this.tillReport.expectedEndAmount
      }
      return null
    }
  }
}
</script>
