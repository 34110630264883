import imageCache from '@/imageCache.js'
import tbaiInfo from '@/tickets/tbai.js'
import kassensichvQr from '@/tickets/kassensichv.js'
import i18n from '@/i18n.js'
import {
  Logo,
  Company,
  AdditionalInfo,
  Title,
  InfoItem,
  Section,
  Label,
  DoubleLabel,
  DoubleInfoItem,
  Separator,
  EmptySeparator,
  Product,
  Box,
  PaymentsBox,
  If,
  Total,
  Barcode,
  DottedSeparator,
  TaxesBreakdown
} from './components.js'

function pickupTypeLabel(type) {
  if (!type) return null
  switch (type) {
    case 'delivery':
      return i18n.t('bill.external-delivery')
    case 'ownDelivery':
      return i18n.t('bill.own-delivery')
    case 'takeAway':
      return i18n.t('bill.take-away')
    default:
      return 'restaurant'
  }
}

function paymentLabel(payment) {
  switch (payment.type) {
    case 'cash':
      return i18n.t('bill.cash')
    case 'card':
      return i18n.t('bill.card')
    default:
      return payment.type
  }
}

function header(bill) {
  let invoiceAndDate = []
  let invoiceText = bill.number
    ? `${i18n.t('bill.simplified-invoice')} ${bill.number}`
    : i18n.t('bill.proforma-invoice')
  if (bill.activationTime) {
    invoiceAndDate = [
      new DoubleLabel(invoiceText, bill.activationTime, {
        secondType: 'date'
      })
    ]
  } else {
    invoiceAndDate = [new Label(invoiceText)]
  }

  return [
    new Logo(imageCache.getImage('brand', bill.locationBrandId)),
    new Company(bill.company),
    new AdditionalInfo(bill),
    ...invoiceAndDate,
    new If(
      bill.source !== 'Restaurant' || !bill.tableName || bill.pickupType,
      new Title(bill.code)
    ),
    new If(
      bill.source !== 'Restaurant' && bill.tableName,
      new Label(`${i18n.t('bill.code')} ${bill.source}: ${bill.tableName}`, {
        lineHeight: 28
      })
    ),
    new If(
      bill.source !== 'Restaurant',
      new DoubleInfoItem(
        i18n.t('bill.method'),
        pickupTypeLabel(bill.pickupType),
        i18n.t('bill.diners'),
        bill.diners
      )
    ),
    new If(
      bill.source === 'Restaurant',
      new DoubleInfoItem(
        bill.pickupType ? i18n.t('bill.name') : i18n.t('bill.table'),
        bill.tableName,
        i18n.t('bill.diners'),
        bill.diners
      )
    ),
    new If(
      bill.schedulingTime || bill.pickupType,
      new InfoItem(
        i18n.t('bill.scheduling-time'),
        bill.schedulingTime || i18n.t('bill.asap'),
        {
          keepTogether: true,
          valueType: bill.schedulingTime ? 'date' : 'text'
        }
      )
    )
  ]
}

function customerDetails(bill) {
  let shouldShowCustomerAddress = bill.pickupType === 'ownDelivery'
  return [
    new InfoItem(i18n.t('tabs.customer-name'), bill.customerName, {
      important: true,
      keepTogether: true
    }),
    new If(
      shouldShowCustomerAddress,
      new InfoItem(i18n.t('tabs.address'), bill.customerAddress, {
        keepTogether: true
      })
    ),
    new If(
      shouldShowCustomerAddress,
      new InfoItem(
        i18n.t('tabs.address-details'),
        bill.customerAddressDetails,
        { keepTogether: true }
      )
    ),
    new If(
      shouldShowCustomerAddress,
      new InfoItem(i18n.t('tabs.postal-code'), bill.customerPostalCode, {
        keepTogether: true
      })
    ),
    new InfoItem(i18n.t('tabs.phone-number'), bill.customerPhoneNumber, {
      keepTogether: true
    }),
    new InfoItem(
      i18n.t('tabs.phone-number-code'),
      bill.customerPhoneNumberCode,
      { keepTogether: true }
    )
  ]
}

function invoiceDetails(bill) {
  let companyInfo = []
  if (bill.customerCompany) {
    companyInfo = [
      new Section(i18n.t('bill.invoice-details')),
      new InfoItem(i18n.t('bill.company'), bill.customerCompany.name),
      new InfoItem(i18n.t('bill.tax-id'), bill.customerCompany.taxId),
      new InfoItem(i18n.t('bill.fiscal-address'), bill.customerCompany.address)
    ]
  }
  return companyInfo
}

function products(bill) {
  return [
    new Section(i18n.t('bill.products')),
    ...bill.products.map(product => new Product(product, bill.pickupType)),
    new InfoItem(i18n.t('bill.allergies'), bill.allergyInfo),
    new If(bill.needCutlery, new Box(i18n.t('bill.need-cutlery')))
  ]
}

function breakdown(bill, shouldShowPendingPayment) {
  let taxRates =
    bill.taxRates.length > 0
      ? bill.taxRates
      : [
          {
            taxPercentage: bill.taxPercentage,
            taxableBase: bill.taxableBase,
            tax: bill.tax,
            total: bill.total
          }
        ]
  return [
    new TaxesBreakdown(taxRates, bill.taxLabel),
    new If(
      !shouldShowPendingPayment,
      new Total(bill.total, bill.diners, bill.type),
      new InfoItem(i18n.t('bill.total'), bill.total, {
        valueType: 'currency'
      })
    )
  ]
}

function feesAndDiscounts(bill) {
  let discount = null
  if (bill.discount && bill.discountTotal > 0) {
    let discountName = bill.discount.concept ? bill.discount.concept : ''
    if (bill.discount.type === 'percentage') {
      discount = new InfoItem(
        `${i18n.t('bill.discount')} ${discountName} (${bill.discount.amount}%)`,
        -bill.discountTotal,
        { valueType: 'currency', light: true }
      )
    } else if (bill.discount.type === 'currency') {
      discount = new InfoItem(
        i18n.t('bill.discount') + ' ' + discountName,
        -bill.discountTotal,
        {
          valueType: 'currency',
          light: true
        }
      )
    }
  }

  let deliveryFee = null
  if (bill.deliveryFee) {
    deliveryFee = new InfoItem(i18n.t('bill.delivery-fee'), bill.deliveryFee, {
      valueType: 'currency',
      light: true
    })
  } else if (bill.discount?.freeDelivery) {
    deliveryFee = new InfoItem(
      i18n.t('bill.delivery-fee'),
      i18n.t('bill.free-delivery').toUpperCase(),
      {
        light: true
      }
    )
  }

  return [
    new If(
      bill.terraceSurcharge,
      new InfoItem(
        `${i18n.t('bill.terrace-surcharge')} (${
          bill.terraceSurchargePercentage
        }%)`,
        bill.terraceSurcharge,
        {
          valueType: 'currency',
          light: true
        }
      )
    ),
    new If(deliveryFee, deliveryFee),
    new If(
      bill.minimumBasketSurcharge,
      new InfoItem(
        i18n.t('bill.minimum-basket-surcharge'),
        bill.minimumBasketSurcharge,
        { valueType: 'currency', light: true }
      )
    ),
    new If(discount, discount),
    new If(
      bill.terraceSurcharge ||
        deliveryFee ||
        bill.minimumBasketSurcharge ||
        discount,
      new DottedSeparator()
    )
  ]
}

function payments(bill, shouldShowPendingPayment) {
  let negativePayments = bill.payments.filter(payment => payment.amount < 0)
  let payments = bill.payments.filter(payment => payment.amount > 0)
  let paidAmount = bill.payments.reduce(
    (sum, payment) => sum + payment.amount - (payment.tip || 0),
    0
  )
  let tipAmount =
    bill.payments.reduce((sum, payment) => sum + (payment.tip || 0), 0) +
    Math.max(0, paidAmount - bill.total)

  let sections = []
  if (negativePayments.length > 0) {
    let mappedPayments = negativePayments.map(payment => {
      return {
        label: paymentLabel(payment),
        amount: -payment.amount
      }
    })
    sections.push(
      new PaymentsBox(i18n.t('bill.extra-charges'), mappedPayments, {
        padding: 0,
        border: false
      })
    )
  }
  if (payments.length > 0) {
    let mappedPayments = payments.map(payment => {
      return {
        label: paymentLabel(payment),
        amount: payment.amount,
        change: payment.change,
        tip: payment.tip
      }
    })
    sections.push(
      new PaymentsBox(i18n.t('bill.payments'), mappedPayments, {
        padding: 0,
        border: false
      })
    )
  }
  if (tipAmount > 0) {
    sections.push(
      new InfoItem(i18n.t('bill.tip'), tipAmount, {
        valueType: 'currency',
        light: true
      })
    )
  }
  if (shouldShowPendingPayment) {
    sections.push(
      new PaymentsBox(
        i18n.t('bill.payment-pending'),
        [
          {
            label: paymentLabel({
              type: bill.preferredPaymentMethod || i18n.t('bill.total')
            }),
            amount: bill.total - paidAmount
          }
        ],
        { important: true }
      )
    )
  }
  return sections
}

async function generateBill(bill, options) {
  let paidAmount = bill.payments.reduce(
    (sum, payment) => sum + payment.amount,
    0
  )
  let shouldShowPendingPayment =
    bill.total > 0 &&
    bill.total > paidAmount &&
    (bill.number || bill.preferredPaymentMethod)
  return [
    ...header(bill),
    ...customerDetails(bill),
    ...invoiceDetails(bill),
    new If(bill.note, new Box(i18n.t('bill.note'), bill.note)),
    ...products(bill),
    new Separator(),
    ...feesAndDiscounts(bill),
    ...breakdown(bill, shouldShowPendingPayment),
    ...payments(bill, shouldShowPendingPayment),
    new If(
      bill.code && options.barcode && bill.pickupType,
      new Barcode(bill.tabId ? bill.tabId.slice(-10) : bill.code)
    ),
    ...(await tbaiInfo(bill)),
    ...(await kassensichvQr(bill)),
    new EmptySeparator()
  ]
}

export default generateBill
