<template>
  <div class="container relative">
    <div class="uppercase font-bold p-6 text-xl text-white text-center">
      {{ $t('qr-scanner.scan-qr') }}
    </div>
    <div
      @click="$emit('close')"
      class="absolute top-0 right-0 py-6 cursor-pointer"
    >
      <icon name="close" class="text-white" />
    </div>
    <div
      class="focus-box flex flex-wrap justify-between content-between relative"
    >
      <div
        class="tl-box white-box border-white"
        :class="{ 'border-red': qrDetected }"
      ></div>
      <div
        class="tr-box white-box border-white"
        :class="{ 'border-red': qrDetected }"
      ></div>
      <div
        class="bl-box white-box border-white"
        :class="{ 'border-red': qrDetected }"
      ></div>
      <div
        class="br-box white-box border-white"
        :class="{ 'border-red': qrDetected }"
      ></div>
      <div class="box overflow-hidden absolute">
        <qrcode-stream @decode="onDecode" @init="onInit" />
      </div>
    </div>
    <div class="button-container flex justify-end mt-6">
      <div
        class="button p-4 bg-red text-white text-center uppercase rounded-lg cursor-pointer"
        :class="{ 'pointer-events-none opacity-50': qrDetected }"
        @click="$emit('cancel')"
      >
        {{ $t('qr-scanner.manual-typing') }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import lastUtils from '@last/core/src/lastUtils'
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'QRScanner',
  data() {
    return {
      qrDetected: false
    }
  },
  methods: {
    async onDecode(result) {
      this.qrDetected = true
      await lastUtils.sleep(1000)
      this.qrDetected = false
      this.$emit('input', result)
    },
    async onInit(promise) {
      try {
        await promise
      } catch (e) {
        this.$emit('error')
      }
    }
  },
  components: {
    Icon,
    QrcodeStream
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}
.container {
  max-width: 400px;
  width: 40vw;
}
.box {
  max-width: 330px;
  max-height: 300px;
  width: 33vw;
  height: 33vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10%;
}
.focus-box {
  max-width: 400px;
  max-height: 400px;
  width: 40vw;
  height: 40vw;
  align-content: space-between;
}
.white-box {
  width: 34%;
  height: 34%;
}
.tl-box {
  border-top-left-radius: 30%;
  border-left-width: 4px;
  border-top-width: 4px;
}
.tr-box {
  border-top-right-radius: 30%;
  border-right-width: 4px;
  border-top-width: 4px;
}
.bl-box {
  border-bottom-left-radius: 30%;
  border-left-width: 4px;
  border-bottom-width: 4px;
}
.br-box {
  border-bottom-right-radius: 30%;
  border-right-width: 4px;
  border-bottom-width: 4px;
}
.white-border {
  height: 430px;
  width: 430px;
  border-radius: 1.5rem;
  position: relative;
}
@media (max-width: 640px) {
  .container {
    width: 80vw;
  }
  .focus-box {
    width: 80vw;
    height: 80vw;
  }
  .box {
    width: 65vw;
    height: 65vw;
  }
  .button {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .button-container {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .button {
    width: 100%;
  }
}
</style>
