<template>
  <div>
    <div class="text-white uppercase font-bold flex flex-row items-center">
      <icon name="arrow-left" class="mr-2" @click="$emit('closeMovements')" />
      {{ currentMethod }} {{ $t('end-shift.movements') }}
    </div>
    <div v-if="tillStart != null" class="mt-2 text-gray-400 uppercase text-sm">
      {{ $t('end-shift.start-amount') + ': ' }}{{ tillStart | currency }}
    </div>
    <div class="text-gray-400 text-sm flex flex-row mt-3 mb-2">
      <div class="w-1/4">{{ $t('end-shift.time') }}</div>
      <div class="flex-1 w-1/4">
        {{ $t('end-shift.concept') }}
      </div>
      <div class="w-1/4">{{ $t('end-shift.amount') }}</div>
    </div>
    <div class="separator bg-gray-400 mb-5 -mx-10"></div>
    <div class="overflow-y-scroll scrolling-touch height-movements">
      <div
        v-for="(movement, index) in movements"
        :key="index"
        class="text-gray-300 text-sm flex flex-row mb-2"
      >
        <div class="w-1/4">{{ movement.creationTime | time }}</div>
        <div class="flex-1 w-1/4">
          {{ movement.detail || movement.tabCode }}
        </div>
        <div class="w-1/4">{{ movement.diff | currency }}</div>
      </div>
    </div>
    <div
      v-if="movements"
      class="text-center text-white underline bg-blue-700 h-12 pt-3 cursor-pointer text-sm inset-x-0 flex-shrink-0"
      @click="$emit('printMovements')"
    >
      {{ $t('end-shift.print-movements') }}
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'CloseTillMethodMovements',
  data() {
    return {}
  },
  props: {
    movements: {
      type: Array,
      default: () => []
    },
    currentMethod: {
      type: String,
      default: ''
    },
    tillStart: {
      type: Number,
      default: null
    }
  },
  components: {
    Icon
  }
}
</script>

<style scoped>
.height-movements {
  height: 22rem;
}
</style>
