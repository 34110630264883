<template>
  <div
    @click="$emit('close')"
    v-scroll-lock="true"
    class="fixed top-0 left-0 h-screen w-screen z-30 blur-background overflow-hidden flex flex-col justify-center items-center"
  >
    <l-loading-spinner :size="'medium'" v-if="loading" />
    <div class="relative w-full flex justify-center" v-else>
      <cancel-shipment-popup
        v-if="cancel && (!job || (!job.isDelivered && !job.isCancelled))"
        :courier="jobCourier"
        :tab-id="tab.id"
        @close="$emit('close')"
      />
      <completed-shipment-popup v-else-if="job && job.isDelivered" />
      <cancelled-shipment-popup
        v-else-if="job && job.isCancelled"
        :cancel-reason="job.cancelReason"
      />
      <map-modal ref="mapModal" :job="job" :tab="tab" v-else />
    </div>
  </div>
</template>

<script>
import api from '@/api.js'
import MapModal from './MapModal.vue'
import CancelledShipmentPopup from './CancelledShipmentPopup.vue'
import CompletedShipmentPopup from './CompletedShipmentPopup.vue'
import CancelShipmentPopup from './CancelShipmentPopup.vue'
import LLoadingSpinner from '@last/core-ui/components/LLoadingSpinner.vue'

export default {
  name: 'ShipmentInfo',
  components: {
    MapModal,
    CancelledShipmentPopup,
    CompletedShipmentPopup,
    CancelShipmentPopup,
    LLoadingSpinner
  },
  data() {
    return {
      pollingInterval: null,
      job: null,
      loading: false
    }
  },
  props: {
    tab: {
      type: Object,
      default: () => {}
    },
    cancel: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    if (this.loading) return
    this.loading = true
    this.pollingInterval = setInterval(this.getShipmentInfo, 1000 * 3)
    await this.getDeliveryInfo()
    this.loading = false
  },
  beforeDestroy() {
    clearInterval(this.pollingInterval)
  },
  methods: {
    async getDeliveryInfo() {
      if (this.tab.deliveryOrder.shipment) {
        let response = await api.get(`/tabs/${this.tab.id}/shipment`)
        this.job = response.data !== '' ? response.data : null
      } else {
        this.job = {
          courier: {},
          deliveries: [
            {
              address: this.tab.deliveryOrder.address,
              latitude: this.tab.deliveryOrder.latitude,
              longitude: this.tab.deliveryOrder.longitude
            }
          ],
          shipmentPickedUp: true
        }
      }
    }
  },
  computed: {
    jobCourier() {
      return this.job?.courier
    }
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}
</style>
