<template>
  <div class="w-full h-full overflow-hidden">
    <section
      v-horizontal-scroll
      v-dragscroll="isElectron"
      class="flex items-center scrolling-touch overflow-scroll scroll-bar-hidden"
    >
      <div
        class="btn big btn text-base mx-2 flex-shrink-0 w-64"
        v-for="method in paymentMethods"
        :key="method.type"
        :class="{
          'border-red-b bg-red text-white':
            selectedPaymentMethod === method.type,
          'bg-white text-blue': selectedPaymentMethod !== method.type
        }"
        @click.stop="select(method.type)"
      >
        {{ method.name }}
      </div>
      <div class="w-8 h-1 flex-shrink-0"></div>
    </section>
  </div>
</template>

<script>
import app from '@/app.js'

export default {
  name: 'PaymentMethodSelector',
  props: {
    paymentMethods: {
      type: Array,
      default: () => []
    },
    selectedPaymentMethod: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isElectron() {
      return app.isElectron
    }
  },
  methods: {
    select(method) {
      this.$emit('select', method)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply uppercase;
}

.big {
  @apply p-4;
  @apply rounded-small;
  @apply font-bold;
  @apply w-48;
}

.scroll-bar-hidden::-webkit-scrollbar {
  display: none;
}
</style>
