var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center px-4 h-16 font-body text-base relative border-b border-gray-200",class:{
    'bg-error text-white': _vm.isCancelled(_vm.tab),
    'bg-yellow text-white': _vm.hasAlert(_vm.tab),
    'text-gray-400': !_vm.isCancelled(_vm.tab) && !_vm.hasAlert(_vm.tab),
    'bg-gray-200': _vm.isSelected && !_vm.choosingForShipment
  }},[_c('div',{staticClass:"flex items-center w-1/4 xl:w-1/6"},[(_vm.choosingForShipment)?_c('l-checkbox',{staticClass:"mr-3",attrs:{"value":_vm.pickedForShipment},on:{"input":function (value) { return _vm.$emit('shipment-pick-toggled', value); }}}):_vm._e(),_c('div',{staticClass:"flex flex-row items-center",class:{ 'ml-8': _vm.offset }},[_c('div',{staticClass:"rounded-full status w-3 h-3",class:{
          cancelled: _vm.isCancelled(_vm.tab),
          alert: _vm.hasAlert(_vm.tab),
          'bg-light-blue': _vm.tab.open,
          closed: !_vm.tab.open,
          'billing-started': _vm.tab.billingStartedTime,
          scheduled: !_vm.tab.activationTime
        }}),_c('div',{staticClass:"text-lg font-bold tab-name ml-3 text-blue"},[_c('div',{staticClass:"flex flex-row items-center"},[(_vm.tab.pendingKitchenOrders)?_c('div',{staticClass:"right-0 bg-white rounded-full circle mr-2"},[_c('div',{staticClass:"w-full h-full mr-1 rounded-full bg-yellow"})]):_vm._e(),(_vm.tab.code)?_c('div',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.tab.code))]):_vm._e(),(_vm.tab.tableName)?_c('div',[_vm._v("/ "+_vm._s(_vm.tab.tableName))]):(_vm.tab.name)?_c('div',[_vm._v("/ "+_vm._s(_vm.tab.name))]):_vm._e()]),_c('div',{staticClass:"text-sm text-gray-400"},[_vm._v(" "+_vm._s(_vm.tab.source)+" ")])])])],1),_c('div',{staticClass:"text-lg font-bold w-1/4"},[(_vm.isCancelled(_vm.tab))?_c('div',{staticClass:"uppercase"},[_vm._v(" "+_vm._s(_vm.$t('tabs.cancelled'))+" ")]):(_vm.hasAlert(_vm.tab))?_c('div',{staticClass:"uppercase"},[_vm._v(" "+_vm._s(_vm.$t('tabs.alert'))+" ")]):(!_vm.tab.open)?_c('div',{staticClass:"uppercase font-bold text-xl flex items-center",class:{
        'text-error': _vm.wrongClosedState,
        'text-light-blue': !_vm.wrongClosedState
      }},[_vm._v(" "+_vm._s(_vm.preparationTime)+" "),_c('span',{staticClass:"text-gray-400 text-lg ml-4"},[_vm._v(_vm._s(_vm.$t('tabs.closed')))])]):(!_vm.tab.activationTime)?_c('div',{staticClass:"uppercase"},[_vm._v(" "+_vm._s(_vm.$t('tabs.scheduled'))+" ")]):_c('div',{staticClass:"text-xl flex flex-row items-center font-bold",class:'text-' + _vm.stateColor},[_c('div',[_vm._v(_vm._s(_vm.timeFromTab.minutes)+":"+_vm._s(_vm.timeFromTab.seconds))]),(_vm.tab.deliveryOrder)?_c('div',[_c('div',{staticClass:"text-lg ml-3 uppercase"},[_vm._v(_vm._s(_vm.statusLabel))])]):_vm._e()])]),(_vm.rowSize !== 'small')?_c('div',{staticClass:"flex-1 flex flex-col"},[_c('div',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm._f("day")(_vm.tab.activationTime || _vm.tab.schedulingTime))+" ")]),_c('div',[_vm._v(_vm._s(_vm._f("time")(_vm.tab.activationTime || _vm.tab.schedulingTime)))])]):_vm._e(),_c('div',{staticClass:"flex-1 flex-col"},[_c('div',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm._f("day")(_vm.deliveryTime))+" ")]),_c('div',[_vm._v(_vm._s(_vm._f("time")(_vm.deliveryTime)))])]),_c('div',{staticClass:"w-1/4 xl:w-1/5"},[_c('div',{staticClass:"w-10/12"},[_vm._v(" "+_vm._s(_vm.longAddressText)+" ")])]),(_vm.tab.deliveryOrder && _vm.tab.deliveryOrder.courierName)?_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.tab.deliveryOrder.courierName)+" ")]):(
      _vm.tab.deliveryOrder &&
        _vm.tab.deliveryOrder.shipmentId &&
        _vm.tab.pickupType === 'ownDelivery'
    )?_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.tab.deliveryOrder.shipment.provider || '')+" ")]):(_vm.tab.deliveryOrder && _vm.tab.deliveryOrder.shipmentCancelled)?_c('div',{staticClass:"flex-1 text-error"},[_vm._v(" "+_vm._s(_vm.$t('tabs.shipment-cancelled'))+" ")]):_c('div',{staticClass:"flex-1"}),(_vm.rowSize === 'large')?_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.tab.customerInfo ? _vm.tab.customerInfo.phoneNumber || '' : '')+" ")]):_vm._e(),(
      _vm.isSelected && _vm.tab.pickupType === 'ownDelivery' && !_vm.choosingForShipment
    )?_c('div',{staticClass:"absolute inset-y-0 right-0 items-center flex mr-3"},[_c('icon',{staticClass:"text-red",attrs:{"name":"external"},on:{"click":function($event){return _vm.$emit('goToDetails')}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }