<template>
  <div class="w-full flex">
    <div
      class="w-full border-gray-300 rounded-small focus-within:border-blue flex items-center"
    >
      <div v-for="index in length" :key="index" class="flex mr-3">
        <input
          class="appearance-none focus:outline-none h-14 w-14 px-4 text-center rounded-small leading-tight bg-transparent-gray"
          :class="{
            ' text-white': theme === 'dark',
            'text-blue': theme === 'light',
            pin: type === 'password' && localPin[index - 1]
          }"
          :type="type"
          :ref="'input' + index"
          v-model="localPin[index - 1]"
          @input="number => updatePin(index, number.data)"
        />
      </div>
      <icon
        :name="iconName"
        class="cursor-pointer"
        :class="{
          'text-white': theme === 'light',
          'text-violet': theme === 'dark'
        }"
        @click="changeStatus"
      />
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'

export default {
  name: 'LPinInput',
  props: {
    length: {
      type: Number,
      default: 4
    },
    theme: {
      type: String,
      default: 'dark'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      type: 'password',
      localPin: []
    }
  },
  mounted() {
    this.localPin = this.value.split('')
  },
  computed: {
    iconName() {
      return this.type === 'password' ? 'eye' : 'eye-disabled'
    }
  },
  methods: {
    updatePin(index, number) {
      if (!number) number = ''
      this.localPin[index - 1] = number
      if (number && index < 4) {
        this.$refs['input' + (index + 1)][0].focus()
      }
      if (!number && index > 1) {
        this.$refs['input' + (index - 1)][0].focus()
      }
      this.$emit('input', this.localPin.join(''))
    },
    changeStatus() {
      this.type = this.type === 'password' ? 'text' : 'password'
    }
  },
  components: {
    Icon
  }
}
</script>

<style scoped>
.pin {
  font-size: 30px;
  color: white;
  caret-color: transparent;
}

.h-14 {
  height: 3.5rem;
}

.w-14 {
  width: 3.5rem;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgba(84, 83, 131, var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.bg-transparent-gray {
  background-color: rgba(160, 160, 192, 0.2);
}
</style>
