import convert from 'xml-js'
import i18n from '@/i18n.js'
import { notification } from '@last/core-ui/plugins/notification'
import { Capacitor } from '@capacitor/core'
import HttpFactory from '@/http'

class Glory {
  constructor() {
    this.initialized = false
  }

  async isAvailable() {
    let data = convert.js2xml(
      {
        'soap:Envelope': {
          _attributes: {
            'xmlns:soap': 'http://schemas.xmlsoap.org/soap/envelope/'
          },
          'soap:Body': {
            StatusRequest: {
              _attributes: {
                xmlns: 'http://www.glory.co.jp/bruebox.xsd'
              },
              Id: 0,
              SeqNo: 1,
              Option: {
                _attributes: {
                  type: 0
                }
              }
            }
          }
        }
      },
      {
        indentAttributes: true,
        spaces: 2,
        compact: true
      }
    )

    let response = (
      await this.http.post(
        `http://${this.config.ip}/axis2/services/BrueBoxService`,
        {
          'Content-Type': 'text/xml; charset=utf-8',
          SOAPAction: 'GetStatus'
        },
        data
      )
    ).data

    let result = convert.xml2js(response, { compact: true })
    let statusResponse =
      result['soapenv:Envelope']['soapenv:Body']['n:StatusResponse']
    return statusResponse['Status']['n:Code']['_text'] == '1'
  }

  async init(config) {
    let platform = Capacitor.getPlatform()
    if (platform != 'web') {
      this.http = HttpFactory.getHttp()
      this.config = config
      this.inputAmountListener = config.inputAmountListener
      this.initialized = true
    }
  }

  async status() {
    let data = convert.js2xml(
      {
        'soap:Envelope': {
          _attributes: {
            'xmlns:soap': 'http://schemas.xmlsoap.org/soap/envelope/'
          },
          'soap:Body': {
            StatusRequest: {
              _attributes: {
                xmlns: 'http://www.glory.co.jp/bruebox.xsd'
              },
              Id: 0,
              SeqNo: 1,
              Option: {
                _attributes: {
                  type: 1
                }
              }
            }
          }
        }
      },
      {
        indentAttributes: true,
        spaces: 2,
        compact: true
      }
    )

    let response = (
      await this.http.post(
        `http://${this.config.ip}/axis2/services/BrueBoxService`,
        {
          'Content-Type': 'text/xml; charset=utf-8',
          SOAPAction: 'GetStatus'
        },
        data
      )
    ).data

    let result = convert.xml2js(response, { compact: true })
    let statusResponse =
      result['soapenv:Envelope']['soapenv:Body']['n:StatusResponse']

    let amount = this.getAmountPayed(statusResponse)
    if (isNaN(parseInt(amount))) this.inputAmountListener(0)
    else this.inputAmountListener(parseInt(amount))
  }

  async charge(amount) {
    if (this.initialized) {
      let available = await this.isAvailable()
      if (!available) {
        notification.create({
          title: i18n.t('notifications.temporarily-unavailable')
        })
        return 0
      }
      let data = convert.js2xml(
        {
          'soap:Envelope': {
            _attributes: {
              'xmlns:soap': 'http://schemas.xmlsoap.org/soap/envelope/'
            },
            'soap:Body': {
              ChangeRequest: {
                _attributes: {
                  xmlns: 'http://www.glory.co.jp/bruebox.xsd'
                },
                Id: 0,
                SeqNo: 1,
                Amount: amount,
                Option: {
                  _attributes: {
                    type: 0
                  }
                }
              }
            }
          }
        },
        {
          indentAttributes: true,
          spaces: 2,
          compact: true
        }
      )

      let resolveCharge = async () => {
        let response = (
          await this.http.post(
            `http://${this.config.ip}/axis2/services/BrueBoxService`,
            {
              'Content-Type': 'text/xml; charset=utf-8',
              SOAPAction: 'ChangeOperation'
            },
            data
          )
        ).data
        let result = convert.xml2js(response, { compact: true })
        let changeResponse =
          result['soapenv:Envelope']['soapenv:Body']['n:ChangeResponse']
        let status = changeResponse['_attributes']['n:result']
        if (status == '0') {
          return amount
        } else if (status == '10') {
          notification.create({
            title: i18n.t('notifications.payment-failed')
          })
          let payed = this.getAmountPayed(changeResponse)
          let available
          while (!available) {
            available = await this.isAvailable()
          }
          await this.charge(-parseInt(payed))
          return 0
        } else {
          return 0
        }
      }

      let statusInterval = setInterval(() => this.status(), 1000)
      let that = this
      this.cancelPromise = new Promise((resolve, _reject) => {
        that.cancelPromiseResolve = resolve
      })
      let charged = await Promise.race([resolveCharge(), this.cancelPromise])
      clearInterval(statusInterval)
      return charged
    } else {
      return 0
    }
  }

  async payIn(amount) {
    return this.charge(amount)
  }

  async payOut(amount) {
    return -(await this.charge(-amount))
  }

  async cancel() {
    let data = convert.js2xml(
      {
        'soap:Envelope': {
          _attributes: {
            'xmlns:soap': 'http://schemas.xmlsoap.org/soap/envelope/'
          },
          'soap:Body': {
            ChangeCancelRequest: {
              _attributes: {
                xmlns: 'http://www.glory.co.jp/bruebox.xsd'
              },
              Id: 0,
              SeqNo: 1,
              Option: {
                _attributes: {
                  type: 0
                }
              }
            }
          }
        }
      },
      {
        indentAttributes: true,
        spaces: 2,
        compact: true
      }
    )

    await this.http.post(
      `http://${this.config.ip}/axis2/services/BrueBoxService`,
      {
        'Content-Type': 'text/xml; charset=utf-8',
        SOAPAction: 'ChangeCancelOperation'
      },
      data
    )

    if (this.cancelPromiseResolve) {
      this.cancelPromiseResolve(0)
    }
  }

  getAmountPayed(response) {
    let payed = 0
    if (!Array.isArray(response.Cash || [])) {
      if (!Array.isArray(response.Cash?.Denomination || []))
        payed +=
          response.Cash?.Denomination['_attributes']['n:fv'] *
          response.Cash?.Denomination['n:Piece']['_text']
      else {
        payed += (response.Cash?.Denomination || []).reduce(
          (total, denomination) => {
            total +=
              denomination['_attributes']['n:fv'] *
              denomination['n:Piece']['_text']
            return total
          },
          0
        )
      }
    } else {
      response.Cash.forEach(cash => {
        if (!Array.isArray(cash.Denomination || []))
          payed +=
            cash.Denomination['_attributes']['n:fv'] *
            cash.Denomination['n:Piece']['_text']
        else {
          payed += (cash.Denomination || []).reduce((total, denomination) => {
            total +=
              denomination['_attributes']['n:fv'] *
              denomination['n:Piece']['_text']
            return total
          }, 0)
        }
      })
    }
    return payed
  }
}

export default new Glory()
