<template>
  <div class="w-screen h-screen overflow-hidden order-management">
    <top-bar show-back @back="exitOrderManagement()">
      <template slot="center">
        <div class="flex items-center text-xl">
          <icon name="diners" class="mr-2 opacity-50" />
          {{ tab.tableName }}
          <l-dropdown>
            <template slot="trigger" slot-scope="{ toggle }">
              <icon
                @click.native.stop="toggle"
                name="dropdown-menu"
                class="text-gray-400 ml-2"
              />
            </template>
            <div class="option" @click="linkReservation()">
              {{ $t('ordering.link-reservation') }}
            </div>
            <div class="option" @click="closeTabId = tabId" v-if="tab.open">
              {{ $t('ordering.close') }}
            </div>
          </l-dropdown>
        </div>
      </template>
      <template slot="right">
        <div class="flex items-center">
          <icon
            class="mr-4"
            v-if="tabId"
            name="qr"
            @click.native.stop="linkCustomer()"
            small
          />
          <icon name="calculator" @click.native.stop="goToTabBrowser" />
        </div>
      </template>
    </top-bar>
    <div class="flex w-full h-full overflow-hidden">
      <div class="flex-1 w-0 flex flex-col">
        <open-tab-product-sort
          class="block sm:hidden"
          :selected-seat.sync="selectedSeat"
          :selected-course.sync="selectedCourse"
          @add-seat="addSeat(tabId)"
        />
        <menu-browser
          :catalog-id="catalogId"
          :customer-points="availablePoints"
          :selected-seat.sync="selectedSeat"
          :selected-course.sync="selectedCourse"
          @selectedProduct="product => configureProduct(product)"
        />
      </div>
    </div>
    <close-tab :tab-id="closeTabId" @cancel="closeTabId = null" />
    <combo-editor
      v-if="editingCombo"
      :editing-combo="editingCombo.edit"
      :catalog-combo="editingCombo.catalog"
      :combo-product="editingCombo.comboProduct"
      :catalog-id="catalogId"
      :tab-id="tabId"
      :seat="selectedSeat"
      @close="editingCombo = null"
    />
    <resend-kitchen-orders
      v-if="resendKitchenOrders"
      :tab-id="tabId"
      @close="resendKitchenOrders = false"
    />
    <floating-button
      v-if="productCount > 0"
      :primary-label="$t('ordering.see-order')"
      @primary="$router.push({ name: 'cart' })"
    />
    <link-customer
      v-if="linkCustomerModal"
      @close="linkCustomerModal = false"
      :tab-id="tabId"
    ></link-customer>
    <link-reservation
      v-if="linkReservationModal"
      @close="linkReservationModal = false"
      :tab-id="tabId"
    ></link-reservation>
  </div>
</template>

<script>
import MenuBrowser from '@/components/ordering/MenuBrowser.vue'
import ComboEditor from '@/components/ordering/ComboEditor.vue'
import ResendKitchenOrders from '@/components/ordering/ResendKitchenOrders.vue'
import TopBar from '@/mobile/components/TopBar.vue'
import kitchenOrdersMixin from '@/components/ordering/kitchenOrdersMixin.js'
import Icon from '@last/core-ui/components/Icon.vue'
import LDropdown from '@last/core-ui/components/LDropdown.vue'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import api from '@/api.js'
import FloatingButton from '@last/core-ui/components/FloatingButton.vue'
import OpenTabProductSort from '@/mobile/components/OpenTabProductSort.vue'
import LinkCustomer from '@/components/ordering/LinkCustomer.vue'
import LinkReservation from '@/components/ordering/LinkReservation.vue'
import CloseTab from '@/components/checkout/CloseTab.vue'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'OrderManagement',
  mixins: [kitchenOrdersMixin],
  data() {
    return {
      selectedSeat: null,
      selectedCourse: null,
      editingProduct: null,
      editingCombo: null,
      editingSeat: null,
      resendKitchenOrders: false,
      customerPoints: 0,
      customerUsedPromotions: [],
      moveProducts: false,
      linkCustomerModal: false,
      linkReservationModal: false,
      closeTabId: null
    }
  },
  async mounted() {
    if (!this.isTabOpen(this.tabId)) {
      this.$router.push({ name: 'pos' })
    }
    this.updatePromotionsInfo()
    if (this.$route.query.selectedSeat) {
      this.selectedSeat = parseInt(this.$route.query.selectedSeat)
    }
  },
  methods: {
    ...mapActions('tabs', [
      'addProduct',
      'updateProductModifiers',
      'moveProduct',
      'updateCourse',
      'addSeat'
    ]),
    exitOrderManagement() {
      if (
        this.pendingOrders &&
        this.tab.activationTime &&
        this.config.enableKitchenOrders &&
        this.tab.open
      ) {
        this.$ldialog({
          title: this.$t('unsent-dialog.title'),
          content: this.$t('unsent-dialog.message'),
          defaultLabels: true,
          onConfirm: async () => {
            this.sendKitchenOrder('all')
            this.$router.replace({ name: 'pos' })
          },
          onSecondary: async () => this.$router.replace({ name: 'pos' })
        })
      } else {
        this.$router.replace({ name: 'pos' })
      }
    },
    async updatePromotionsInfo() {
      let points = 0
      if (this.tab?.customerId) {
        const response = await api.get(
          `/customer/${this.tab.customerId}/promotions`
        )
        points = response.data.points
        this.customerUsedPromotions = response.data.customerUsedPromotions
      }
      this.customerPoints = points
    },
    saveProduct(product) {
      let productPricing =
        ProductPriceCalculator.calculateProductPricing(product)
      if (product.id) {
        this.updateProductModifiers({
          productId: product.id,
          modifiers: product.modifiers,
          comments: product.comments,
          productPricing
        })
        this.updateCourse({
          tabId: this.tabId,
          productId: product.id,
          course: product.course
        })
      } else {
        this.addProduct({
          tabId: this.tabId,
          seat: this.selectedSeat,
          product: {
            ...product,
            ...productPricing
          }
        })
      }
      this.editingProduct = null
    },
    configureProduct(product) {
      if (product.categories) {
        this.$router.push({
          name: 'comboEditor',
          params: {
            tabId: this.tabId,
            catalogCombo: product,
            editingCombo: null,
            seat: this.selectedSeat
          }
        })
      } else {
        let newProduct = {
          ...product,
          course: this.selectedCourse || product.course,
          id: null,
          parentProduct: product.id,
          quantity: 1
        }
        let mandatory =
          newProduct.modifierGroups &&
          newProduct.modifierGroups.some(group => group.min > 0)
        if (mandatory) {
          this.$router.push({
            name: 'productEditor',
            params: {
              tabId: this.tabId,
              catalogProductId: product.id,
              showQuantity: true,
              product: newProduct,
              selectedSeat: this.selectedSeat
            }
          })
        } else {
          this.saveProduct({ ...newProduct, modifiers: [] })
        }
      }
    },
    editComboProduct({ product, comboProduct }) {
      const parentProduct = this.$store.getters['catalog/getProductById'](
        product.parentProduct
      )

      if (parentProduct && parentProduct.categories) {
        this.editingCombo = {
          catalog: parentProduct,
          edit: product,
          comboProduct
        }
      }
    },
    editProduct(product) {
      if (!product) {
        this.editingProduct = null
        return
      }
      this.editingSeat = this.selectedSeat
      const parentProduct = this.$store.getters['catalog/getProductById'](
        product.parentProduct
      )

      if (parentProduct && parentProduct.categories) {
        this.editingCombo = {
          catalog: parentProduct,
          edit: product
        }
      } else {
        this.editingProduct = {
          ...parentProduct,
          quantity: product.quantity,
          modifiers: product.modifiers,
          comments: product.comments,
          id: product.id,
          parentProduct: (parentProduct && parentProduct.id) || null,
          course: product.course,
          price: product.price,
          name: product.name,
          discountType: product.discountType,
          discountAmount: product.discountAmount,
          pointsExpense: product.pointsExpense
        }
      }
    },
    goToTabBrowser() {
      this.$router.push({ name: 'tabBrowser' })
    },
    linkCustomer() {
      this.linkCustomerModal = true
    },
    linkReservation() {
      this.linkReservationModal = true
    }
  },
  computed: {
    ...mapGetters('tabs', [
      'isTabOpen',
      'getSeatProducts',
      'getSharedProducts'
    ]),
    ...mapState('tabs', ['tabs']),
    ...mapGetters('catalog', ['getCatalogIdByTabVirtualBrandId']),
    ...mapGetters('promotions', ['usedPointsInTab']),
    tabId() {
      return this.$route.params.tabId
    },
    selectedProductId() {
      if (this.editingProduct) return this.editingProduct.id
      return null
    },
    productCount() {
      return (
        this.getSeatProducts(this.tabId).flat().length +
        this.getSharedProducts(this.tabId).length
      )
    },
    tab() {
      return this.tabs[this.tabId]
    },
    tabCustomerId() {
      return this.tab?.customerId
    },
    catalogId() {
      return this.getCatalogIdByTabVirtualBrandId(
        this.tab.virtualBrandId,
        this.tab.pickupType,
        this.tab.source
      )
    },
    availablePoints() {
      return this.customerPoints - this.usedPointsInTab(this.tabId)
    }
  },
  watch: {
    productCount(oldCount, newCount) {
      if (oldCount != newCount) {
        this.editingProduct = null
      }
    },
    tabCustomerId(oldId, newId) {
      if (oldId != newId) {
        this.updatePromotionsInfo()
      }
    },
    selectedSeat(value) {
      if (value !== this.$route.query.selectedSeat) {
        this.$router.replace({
          query: { ...this.$route.query, selectedSeat: value }
        })
      }
    }
  },
  components: {
    MenuBrowser,
    ResendKitchenOrders,
    TopBar,
    Icon,
    LDropdown,
    ComboEditor,
    FloatingButton,
    OpenTabProductSort,
    LinkCustomer,
    LinkReservation,
    CloseTab
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.order-management {
  background: black;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.order-container {
  display: flex;
  height: 100%;
}

i {
  color: $text-strong;
}

#tab-name {
  font-size: 23px;
}
</style>
