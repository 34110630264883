<template>
  <l-modal
    size="medium"
    :title="title"
    class="sm:pb-10 sm:pt-3 sm:px-40"
    @close="$emit('close')"
  >
    <template slot="body">
      <l-field :label="$t('new-tab.name-label')" class="no-padding">
        <l-input
          v-model="newTab.name"
          :placeholder="$t('new-tab.name-placeholder')"
        />
      </l-field>
      <new-tab-language-selector v-model="newTab.lang" class="pb-2" />
      <new-tab-seats-selector v-model="newTab.seats" @save="saveTab" />
      <l-modal-ctas>
        <l-modal-button
          id="accept-button"
          class="tab-button w-full sm:w-auto mt-6 sm:mt-0"
          type="main"
          :label="$t('ctas.confirm')"
          :disabled="!isComplete"
          @click.native="saveTab()"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import LField from '@last/core-ui/components/LField.vue'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import NewTabLanguageSelector from './NewTabLanguageSelector'
import NewTabSeatsSelector from './NewTabSeatsSelector'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'NewTab',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    table: {
      type: Object,
      default: null
    },
    customer: {
      type: Object,
      default: null
    }
  },
  mounted() {
    if (!this.config.organizationConfig.seatSelector) {
      this.newTab.seats = 1
      this.saveTab()
    }
  },
  data() {
    return {
      newTab: {
        lang: null,
        name: null,
        seats: null
      }
    }
  },
  methods: {
    ...mapActions('tabs', ['openTab', 'openTabWithCustomer']),
    async saveTab() {
      if (!this.isComplete) return
      let tabId = null
      if (this.customer) {
        tabId = await this.openTabWithCustomer({
          tableId: (this.table && this.table.id) || null,
          tab: { ...this.newTab, customerInfo: this.customer },
          customer: this.customer
        })
      } else {
        tabId = await this.openTab({
          tableId: (this.table && this.table.id) || null,
          tab: this.newTab
        })
      }
      this.$emit('update:isActive', false)
      this.$emit('tabCreated', tabId)
      this.$emit('close')
      this.$router.push({ name: 'orderManagement', params: { tabId } })
    },
    initTable() {
      this.newTab = {
        seats: (this.table && this.table.seats) || null,
        lang: null,
        name: null
      }
    }
  },
  computed: {
    ...mapState('config', ['config']),
    title() {
      if (this.table) {
        return this.$i18n.t('new-tab.new-tab-table', { name: this.table.name })
      }
      return this.$i18n.t('new-tab.new-tab')
    },
    isComplete() {
      return this.newTab.seats
    }
  },
  watch: {
    table() {
      this.initTable()
      this.isActive = true
    },
    isActive() {
      this.initTable()
    }
  },
  components: {
    LModal,
    LField,
    LInput,
    LModalCtas,
    LModalButton,
    NewTabLanguageSelector,
    NewTabSeatsSelector
  }
}
</script>

<style lang="scss" scoped>
.tab-button {
  @apply px-16;
}
.no-padding {
  padding: 0;
}
</style>
