<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen z-20 blur-background overflow-hidden flex justify-center items-center"
    @click="$emit('close')"
  >
    <div class="sm:p-20 max-h-full overflow-y-scroll">
      <div class="text-white uppercase text-2xl text-center pb-6">
        {{ $t('resend-kitchen-orders.title') }}
      </div>
      <div class="bg-white rounded-big shadow p-6 relative" @click.stop>
        <icon
          name="close"
          class="text-gray-400 absolute top-0 right-0 m-4"
          @click.native="
            previewOrderId ? (previewOrderId = null) : $emit('close')
          "
        />
        <div class="flex" v-if="previewOrderId">
          <img :src="canvasImage" />
        </div>
        <div v-else class="py-8">
          <div
            v-for="order in orders"
            :key="order.id"
            class="flex items-center"
          >
            <icon
              name="end-shift"
              class="text-gray-400 mr-3 rotate-90"
              v-if="!order.printable"
            />
            <icon
              name="done"
              class="text-green mr-3"
              v-else-if="order.printed"
            />
            <icon name="error" class="text-yellow mr-3" v-else />
            <div class="text-red">{{ order.creationTime | time }}</div>
            <div class="text-blue py-3 px-6 flex-1">
              {{ order.description }}
            </div>
            <icon
              name="eye"
              class="text-green ml-10"
              @click.native="previewOrderId = order.id"
            />
            <icon
              name="printer"
              class="text-red ml-6"
              @click.native="resendOrder(order.id)"
            />
          </div>
        </div>
        <div
          v-if="hasFailedOrders && !previewOrderId"
          @click="resendFailed"
          class="flex justify-center text-white text-lg uppercase px-10 py-3 border rounded-small font-bold done-button flex items-center shadow-lg relative cursor-pointer bg-blue border-blue-b"
        >
          {{ $t('resend-kitchen-orders.resend-failed') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketPrinter from '@/ticketPrinter.js'
import Icon from '@last/core-ui/components/Icon.vue'
import KitchenOrderFormatter from '@/kitchenOrderFormatter.js'
import kitchenOrdersMixin from './kitchenOrdersMixin.js'
import TicketGenerator from '@/tickets/generator.js'

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'ResendKitchenOrders',
  mixins: [kitchenOrdersMixin],
  props: {
    tabId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      printingOrders: null,
      previewOrderId: null,
      canvasImage: null
    }
  },
  methods: {
    ...mapActions('tabs', ['createKitchenOrders']),
    resendOrder(orderId) {
      TicketPrinter.printKitchenOrder(this.getKitchenOrder(orderId))
      this.$emit('close')
    },
    getKitchenOrder(id) {
      return this.kitchenOrders[id]
    },
    resendFailed() {
      this.orders
        .filter(order => !order.printed && order.printable)
        .forEach(order =>
          TicketPrinter.printKitchenOrder(this.getKitchenOrder(order.id))
        )
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters('tabs', ['getAllProducts', 'getUnsentProducts']),
    ...mapGetters('catalog', ['getCatalogIdByTabVirtualBrandId']),
    ...mapState('tabs', ['tabs', 'kitchenOrders', 'products']),
    ...mapState('catalog', ['catalogs']),
    ...mapState('config', ['config']),
    existingCourses() {
      return [
        ...new Set(
          this.getUnsentProducts(this.tabId).map(product => product.course)
        )
      ]
    },
    hasFailedOrders() {
      return (
        this.orders.filter(order => !order.printed && order.printable).length >
        0
      )
    },
    orders() {
      let orders = this.tabs[this.tabId].kitchenOrders.map(
        id => this.kitchenOrders[id]
      )
      return Object.values(orders)
        .map(order => {
          return {
            id: order.id,
            creationTime: order.creationTime,
            description: order.printerName,
            copies: order.copies,
            printed: !!order.printedTime,
            printable: order.copies > 0
          }
        })
        .sort((a, b) => b.creationTime - a.creationTime)
    },
    previewPrinter() {
      return this.kitchenOrders[this.previewOrderId]
        ? this.config.printers[
            this.kitchenOrders[this.previewOrderId].printerId
          ]
        : null
    },
    previewOrder() {
      if (!this.previewOrderId) return null
      let tab = this.tabs[this.tabId]
      let catalogId = this.getCatalogIdByTabVirtualBrandId(
        tab.virtualBrandId,
        tab.pickupType,
        tab.source
      )
      let sortedCourses = this.catalogs[catalogId]?.courses || []
      return KitchenOrderFormatter.format(
        this.kitchenOrders[this.previewOrderId],
        sortedCourses
      )
    }
  },
  components: {
    Icon
  },
  watch: {
    async previewOrder() {
      if (this.previewOrder) {
        let canvas = await TicketGenerator.kitchenOrder(
          this.previewOrder,
          this.previewPrinter
        )
        this.canvasImage = canvas.toDataURL('image/png')
      }
    }
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
</style>
