<template>
  <div class="login-container">
    <div class="login-box">
      <img class="logo" src="../assets/logo-big.png" />
      <div class="field">
        <label class="label">Name</label>
        <div class="control">
          <input
            class="input is-medium"
            type="text"
            placeholder="John Doe"
            v-model="name"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Email</label>
        <div class="control">
          <input
            class="input is-medium"
            type="text"
            placeholder="manager@store.com"
            v-model="email"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Password</label>
        <div class="control">
          <input class="input is-medium" type="password" v-model="password" />
        </div>
      </div>
      <div class="field">
        <label class="label">Store Name</label>
        <div class="control">
          <input class="input is-medium" type="text" v-model="storeName" />
        </div>
      </div>
      <div class="field">
        <label class="label">Store Address</label>
        <div class="control">
          <input class="input is-medium" type="text" v-model="storeAddress" />
        </div>
      </div>
      <div class="field center">
        <button
          class="button is-medium is-success signup-button"
          :class="{ 'is-loading': signingUp }"
          @click="startSignup"
        >
          SignUp
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SignUp',
  data() {
    return {
      email: null,
      password: null,
      name: null,
      storeName: null,
      storeAddress: null,
      signingUp: false
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'pos' })
    }
  },
  methods: {
    ...mapActions('auth', ['signUp', 'refreshCurrentUser']),
    async startSignup() {
      this.signingUp = true
      try {
        this.signUp({
          email: this.email,
          password: this.password,
          name: this.name,
          storeName: this.storeName,
          storeAddress: this.storeAddress
        })
      } catch (error) {
        // show error
      }
      this.signingUp = false
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated'])
  },
  watch: {
    isAuthenticated(authenticated) {
      if (authenticated) {
        this.refreshCurrentLocation()
        this.$router.push({ name: 'employees' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login-box {
  width: 300px;
}

.logo {
  margin-left: 50px;
  width: 200px;
}

.signup-button {
  margin-top: 20px;
  padding: 0 70px;
}

.center {
  display: flex;
  justify-content: center;
}
</style>
