import localDb from '@/localDb.js'
import api from '@/api.js'
import GlovoScrapper from '@last/core/src/glovoScrapper'

const state = {
  orders: {},
  refreshToken: null,
  accessToken: null,
  glovoStores: [],
  scrapper: null
}

const getters = {
  storesHaveChanged: state => stores => {
    if (stores.length === 0) return false
    if (stores.length !== state.glovoStores.length) return true
    return !stores.every(store => {
      state.glovoStores.includes(store)
    })
  }
}

const actions = {
  async initScrapper({ dispatch, state }) {
    await dispatch('initData')
    let { user, password } = state.glovoStores[0].metadata
    if (!this.scrapper) this.scrapper = new GlovoScrapper(user, password)
    let initialData = {
      orders: state.orders,
      refreshToken: state.refreshToken,
      accessToken: state.accessToken,
      storeIds: state.glovoStores.map(store => store.externalId)
    }
    this.scrapper.startScrapping(
      initialData,
      token => dispatch('updateToken', token),
      order => dispatch('removeOrder', order),
      order => dispatch('sendNewOrder', order)
    )
  },
  async initData({ commit }) {
    let token = await localDb.loadTables(['scrapper:token'])
    let orders = await localDb.loadTables(['scrapper:orders'])

    if (token && token['scrapper:token']) {
      let tokenData = token['scrapper:token']
      commit('updateToken', tokenData)
    }

    if (orders && orders['scrapper:orders']) {
      let ordersData = orders['scrapper:orders']
      Object.values(ordersData).forEach(order => {
        commit('addOrder', order)
      })
    }
  },
  updateStores({ commit, dispatch, getters, rootState }, stores) {
    const isMaster = rootState.config.device.mode === 'master'
    if (isMaster && getters.storesHaveChanged(stores)) {
      commit('updateStores', stores)
      dispatch('initScrapper')
    }
  },
  updateToken({ commit }, token) {
    commit('updateToken', token)
  },
  removeOrder({ commit }, order) {
    commit('removeOrder', order)
  },
  sendNewOrder({ commit }, order) {
    try {
      api.post('/scrapper/orders', { order })
      commit('addOrder', order)
    } catch (e) {
      // Ignore exception, but skip commit
    }
  }
}

const mutations = {
  updateStores(state, stores) {
    if (!stores) return
    state.glovoStores = stores
  },
  updateToken(state, token) {
    if (!token) return
    localDb.setItem('scrapper:token', 'refreshToken', token.refreshToken)
    localDb.setItem('scrapper:token', 'accessToken', token.accessToken)
    state.refreshToken = token.refreshToken
    state.accessToken = token.accessToken
  },
  removeOrder(state, order) {
    if (!order) return
    localDb.removeItem('scrapper:orders', order.id)
    delete state.orders[order.id]
  },
  addOrder(state, order) {
    if (!order) return
    localDb.setItem('scrapper:orders', order.id, order)
    state.orders[order.id] = order
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
