import i18n from '@/i18n.js'
import { InfoItem, Section, Label, EmptySeparator } from './components.js'

function movementTypeItem(movement) {
  if (movement.movementType === 'payOut') {
    return new InfoItem(i18n.t('movement.out'), movement.amount, {
      valueType: 'currency'
    })
  }
  return new InfoItem(i18n.t('movement.in'), movement.amount, {
    valueType: 'currency'
  })
}

function payInPayOut(movement) {
  return [
    new Section(i18n.t('movement.movement')),
    new Label(movement.date, {
      valueType: 'date',
      style: 'bold 16px'
    }),
    new EmptySeparator(),
    new InfoItem(i18n.t('movement.employee'), movement.employee),
    movementTypeItem(movement),
    new InfoItem(i18n.t('movement.detail'), movement.detail)
  ]
}

export default payInPayOut
