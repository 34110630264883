var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.loaded)?_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.defaultCenter,"options":{
      clickableIcons: false,
      disableDefaultUI: true,
      styles: _vm.mapStyle
    },"zoom":16}},[_vm._l((_vm.pickups),function(pickup,index){return _c('gmap-marker',{key:'p' + index,attrs:{"position":{
        lat: pickup.latitude,
        lng: pickup.longitude
      },"icon":{
        url: _vm.pickupIcon
      }}})}),(_vm.courier && _vm.courier.latitude)?_c('gmap-marker',{attrs:{"position":{
        lat: _vm.courier.latitude,
        lng: _vm.courier.longitude
      },"icon":{
        url: _vm.courierIcon
      }}}):_vm._e(),_vm._l((_vm.deliveries),function(delivery,index){return _c('gmap-marker',{key:'d' + index,attrs:{"position":{
        lat: delivery.latitude,
        lng: delivery.longitude
      },"icon":{
        url: _vm.deliveryIcon
      }}})})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }