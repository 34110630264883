<template>
  <div class="flex items-center text-blue">
    <div class="px-4 py-2 text-red w-10">
      {{ product.quantity }}
    </div>
    <div class="px-4 py-2 flex-1">
      <div class="product-name">
        {{ product.name }}
      </div>
      <div class="text-sm text-gray-400">
        {{ description }}
      </div>
      <div class="comments text-gray-400" v-if="product.comments">
        + {{ product.comments }}
      </div>
    </div>
    <div class="px-4 py-2">
      {{ price | currency }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewProduct',
  props: ['product', 'isSelected', 'isDisabled'],
  computed: {
    price() {
      return this.product.finalPrice
    },
    description() {
      return (this.product.comboProducts || this.product.modifiers || [])
        .map(modifier => {
          let quantity = modifier.quantity > 1 ? ` x ${modifier.quantity}` : ''
          return modifier.name + quantity
        })
        .join(', ')
    }
  }
}
</script>
