<template>
  <l-dropdown>
    <template slot="trigger" slot-scope="{ toggle }">
      <div
        class="flex items-center justify-center hover:bg-gray-100 h-full w-full text-blue"
        @click="() => toggle()"
      >
        <div class="text-sm uppercase ml-1">
          {{ $t('tabs.delivery') }}
        </div>
        <icon name="info" class="p-1" />
      </div>
    </template>
    <div>
      <delivery-record-row
        :delivery-status="selectedTabDeliveryStatuses.KITCHEN"
        :name="$t('tabs.kitchen')"
      />
      <delivery-record-row
        :delivery-status="selectedTabDeliveryStatuses.READY_TO_PICKUP"
        :name="$t('tabs.pickup')"
      />
      <delivery-record-row
        :delivery-status="selectedTabDeliveryStatuses.ON_DELIVERY"
        :name="$t('tabs.on-delivery')"
      />
      <delivery-record-row
        :delivery-status="selectedTabDeliveryStatuses.DELIVERED"
        :name="$t('tabs.delivered')"
      />
    </div>
  </l-dropdown>
</template>

<script>
import LDropdown from '@last/core-ui/components/LDropdown.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import deliveryRecordCalculator from '@last/core/src/deliveryRecordCalculator'
import DeliveryRecordRow from '@/components/tabs/DeliveryRecordRow.vue'

export default {
  name: 'DeliveryRecordPopup',
  data() {
    return {}
  },
  props: {
    tab: {
      type: Object,
      default: () => {}
    }
  },
  methods: {},
  computed: {
    selectedTabDeliveryStatuses() {
      return (
        deliveryRecordCalculator.calculateDeliveryRecord(this.tab)
          ?.mappedStatuses || {}
      )
    },
    selectedTabLastStatus() {
      return (
        deliveryRecordCalculator.calculateDeliveryRecord(this.tab)
          ?.lastStatus || {}
      )
    }
  },
  components: {
    LDropdown,
    Icon,
    DeliveryRecordRow
  }
}
</script>

<style scoped></style>
