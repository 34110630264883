<template>
  <div
    class="fixed top-0 left-0 h-screen w-screen z-30 blur-background overflow-hidden flex flex-col justify-center items-center"
  >
    <div
      class="relative flex flex-col items-center bg-white rounded-xl payment-screen payment-screen text-center"
    >
      <icon
        name="close"
        class="absolute top-0 right-0 m-4 text-gray-400 cursor-pointer"
        @click.native="cancel"
      />
      <icon :name="'loading-spinner'" class="animate-spin text-blue my-4" />
      <div class="uppercase text-blue text-xl font-bold">
        {{ $t('payment-screen.payment-in-process') }}
      </div>
      <div class="text-gray-400 text-sm mt-3 text-max-w">
        {{ $t('payment-screen.check-the-dataphone') }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
export default {
  name: 'PaymentScreen',
  methods: {
    cancel() {
      this.$emit('cancel')
      this.deactivate()
    },
    deactivate() {
      this.$destroy()
      this.$el.remove()
    }
  },
  components: {
    Icon
  }
}
</script>

<style scoped>
.text-max-w {
  max-width: 14rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}
.payment-screen {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
}
@media (min-width: 640px) {
  .payment-screen {
    width: 600px;
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 2.5rem;
    padding-bottom: 4rem;
  }
}
</style>
