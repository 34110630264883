<template>
  <div
    class="rounded-lg w-11/12 h-24 shadow absolute bottom-0 left-0 right-0 mb-5 px-5 m-auto flex flex-row items-center text-blue text-center uppercase z-50 bg-white"
  >
    <div
      class="border border-blue mx-2 py-1 flex-1 rounded-lg"
      :class="{
        'bg-blue text-white py-1':
          selectedTabDeliveryStatuses && selectedTabDeliveryStatuses.KITCHEN,
        'py-4': !selectedTabDeliveryStatuses.KITCHEN
      }"
    >
      {{ $t('tabs.kitchen') }}
      <div
        v-if="
          selectedTabDeliveryStatuses && selectedTabDeliveryStatuses.KITCHEN
        "
        class="flex flex-row justify-center"
      >
        {{ selectedTabDeliveryStatuses.KITCHEN.creationTime | time }}

        <div
          v-if="selectedTabDeliveryStatuses.KITCHEN.progressTime !== undefined"
          class="lowercase ml-1"
          :class="'text-' + selectedTabDeliveryStatuses.KITCHEN.progressColor"
        >
          ({{ selectedTabDeliveryStatuses.KITCHEN.progressTime }} min)
        </div>
      </div>
    </div>
    <div
      class="border border-blue mx-2 flex-1 rounded-lg"
      :class="{
        'bg-blue text-white py-1':
          selectedTabDeliveryStatuses &&
          selectedTabDeliveryStatuses.READY_TO_PICKUP,
        'py-4': !selectedTabDeliveryStatuses.READY_TO_PICKUP
      }"
      @click="$emit('readyToPickup')"
    >
      {{ $t('tabs.pickup') }}
      <div
        v-if="
          selectedTabDeliveryStatuses &&
          selectedTabDeliveryStatuses.READY_TO_PICKUP
        "
        class="flex flex-row justify-center"
      >
        {{ selectedTabDeliveryStatuses.READY_TO_PICKUP.creationTime | time }}

        <div
          v-if="
            selectedTabDeliveryStatuses.READY_TO_PICKUP.progressTime !==
            undefined
          "
          class="lowercase ml-1"
          :class="
            'text-' + selectedTabDeliveryStatuses.READY_TO_PICKUP.progressColor
          "
        >
          ({{ selectedTabDeliveryStatuses.READY_TO_PICKUP.progressTime }} min)
        </div>
      </div>
    </div>
    <div
      v-if="showOnDelivery"
      class="border border-blue mx-2 flex-1 rounded-lg"
      :class="{
        'bg-blue text-white py-1':
          selectedTabDeliveryStatuses &&
          selectedTabDeliveryStatuses.ON_DELIVERY,
        'py-4': !selectedTabDeliveryStatuses.ON_DELIVERY
      }"
      @click="selectCourier"
    >
      {{ $t('tabs.on-delivery') }}
      <div
        v-if="
          selectedTabDeliveryStatuses && selectedTabDeliveryStatuses.ON_DELIVERY
        "
        class="flex flex-row justify-center"
      >
        {{ selectedTabDeliveryStatuses.ON_DELIVERY.creationTime | time }}

        <div
          v-if="
            selectedTabDeliveryStatuses.ON_DELIVERY.progressTime !== undefined
          "
          class="lowercase ml-1"
          :class="
            'text-' + selectedTabDeliveryStatuses.ON_DELIVERY.progressColor
          "
        >
          ({{ selectedTabDeliveryStatuses.ON_DELIVERY.progressTime }} min)
        </div>
      </div>
    </div>
    <div
      class="border border-blue mx-2 flex-1 rounded-lg"
      :class="{
        'bg-blue text-white py-1':
          selectedTabDeliveryStatuses && selectedTabDeliveryStatuses.DELIVERED,
        'py-4': !selectedTabDeliveryStatuses.DELIVERED
      }"
      @click="finishOrder"
    >
      {{ $t('tabs.delivered') }}
      <div
        v-if="
          selectedTabDeliveryStatuses && selectedTabDeliveryStatuses.DELIVERED
        "
      >
        {{ selectedTabDeliveryStatuses.DELIVERED.creationTime | time }}
      </div>
    </div>
    <div class="vertical-separator h-12 bg-gray-200 m-auto my-2 mx-3" />
    <div
      class="bg-error border-error-b py-4 mx-2 flex-1 rounded-lg text-white"
      @click="$emit('closeTab')"
    >
      {{ $t('tabs.cancel-order') }}
    </div>
    <icon @click="$emit('close')" name="close" class="text-gray-400 ml-3" />
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon'
import deliveryRecordCalculator from '@last/core/src/deliveryRecordCalculator'
import { mapState } from 'vuex'

export default {
  name: 'DeliveryStatusPopup',
  data() {
    return {}
  },
  props: {
    tab: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    selectCourier() {
      if (
        this.tab.deliveryOrder.status === 'READY_TO_PICKUP' ||
        this.tab.deliveryOrder.status === 'ON_DELIVERY'
      ) {
        this.$emit('selectCourier')
      }
    },
    finishOrder() {
      if (
        this.tab.deliveryOrder.status === 'READY_TO_PICKUP' ||
        this.tab.deliveryOrder.status === 'ON_DELIVERY'
      ) {
        this.$emit('finishOrder')
      }
    }
  },
  computed: {
    ...mapState('config', ['config']),
    selectedTabDeliveryStatuses() {
      return deliveryRecordCalculator.calculateDeliveryRecord(this.tab)
        .mappedStatuses
    },
    showOnDelivery() {
      return (
        this.tab.pickupType === 'ownDelivery' &&
        (!this.config.shipmentProvider || this.config.manualShipment)
      )
    }
  },
  components: {
    Icon
  }
}
</script>

<style scoped>
.vertical-separator {
  width: 0.0625rem;
}
</style>
