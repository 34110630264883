<template>
  <div
    class="absolute top-0 left-0 h-screen w-screen blur-background flex justify-center items-center z-30"
  >
    <div>
      <div class="text-white uppercase text-2xl text-center pb-6">
        {{ $t('generate-invoice.title') }}
      </div>
      <div
        class="whitespace-pre-line bg-white rounded-big shadow text-color relative dialog-w"
        @click.stop
      >
        <div v-if="editingCompany" class="p-8">
          <div class="uppercase text-blue text-lg pb-4">
            {{ $t('generate-invoice.new-company') }}
          </div>
          <div class="flex">
            <l-field :label="$t('generate-invoice.tax-id')" class="w-2/5">
              <l-input
                v-model="editingCompany.taxId"
                :wrong-value="!validTaxId"
              />
            </l-field>
            <l-field :label="$t('generate-invoice.name')" class="pl-3 w-3/5">
              <l-input v-model="editingCompany.name" />
            </l-field>
          </div>
          <l-field :label="$t('generate-invoice.address')">
            <l-input v-model="editingCompany.address" />
          </l-field>
        </div>
        <div v-else>
          <div class="pt-8 px-8">
            <l-input
              v-model="query"
              icon="search"
              @input="query => searchCompanies(query)"
            />
          </div>
          <div class="overflow-y-scroll scrolling-touch results-height">
            <div
              v-for="company in companies"
              :key="company.id"
              class="text-sm flex px-8 py-3 items-center list-border"
              :class="{
                'bg-gray-200':
                  selectedCompany && selectedCompany.id === company.id,
                'pointer-events-none': !canBeSelected[company.id]
              }"
              @click="selectCompany(company)"
            >
              <div class="flex-1">{{ company.name }}</div>
              <icon v-if="!canBeSelected[company.id]" name="error" class="mr-2 text-gray-400" />
              <div class="text-gray-400 font-bold pr-2">NIF:</div>
              <div class="text-gray-400">{{ company.taxId }}</div>
              <icon
                name="pen"
                class="text-gray-400 ml-2 mb-1 pointer-events-auto"
                @click.native="edit(company)"
              />
            </div>
          </div>
          <div class="p-8">
            <div
              @click="newCompany"
              class="text-center rounded-small py-3 px-4 bg-blue border border-blue-900 text-white uppercase font-bold cursor-pointer"
            >
              {{ $t('generate-invoice.new-company') }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex pt-4">
        <div
          v-if="editingCompany"
          class="p-3 px-4 text-white bg-blue border border-blue-700 uppercase rounded-small font-bold flex-1 text-center"
          @click="editingCompany = null"
        >
          {{ $t('generate-invoice.back') }}
        </div>
        <div
          v-else
          class="p-3 px-4 text-white bg-blue border border-blue-700 uppercase rounded-small font-bold flex-1 text-center"
          @click="close()"
        >
          {{ $t('generate-invoice.cancel') }}
        </div>
        <div
          :class="{ 'opacity-50': !validData }"
          class="p-3 px-4 text-white bg-red border border-red-b uppercase rounded-small font-bold flex-1 ml-4 text-center"
          @click="confirm()"
        >
          {{ $t('generate-invoice.generate') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LInput from '@last/core-ui/components/LInput.vue'
import LField from '@last/core-ui/components/LField.vue'
import lastUtils from '@last/core/src/lastUtils'
import api from '@/api.js'
import { mapActions } from 'vuex'
import uuid4 from 'uuid/v4'

export default {
  name: 'GenarateInvoice',
  props: {
    tabId: {
      type: String,
      default: null
    },
    billId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      query: null,
      companies: [],
      selectedCompany: null,
      editingCompany: null
    }
  },
  mounted() {
    this.searchCompanies('')
  },
  methods: {
    ...mapActions('tabs', ['regenerateBill']),
    async searchCompanies(query) {
      let response = await api.get('/companies', {
        params: {
          query
        }
      })
      this.companies = response.data
    },
    close() {
      this.$emit('close')
    },
    async confirm() {
      if (!this.validData) return
      let company = this.editingCompany || this.selectedCompany
      let billId = await this.regenerateBill({
        tabId: this.tabId,
        billId: this.billId,
        customerCompany: company
      })
      this.$emit('newBillId', billId)
      this.$emit('close')
    },
    newCompany() {
      this.editingCompany = {
        id: uuid4()
      }
    },
    edit(company) {
      this.editingCompany = {
        taxId: company.taxId,
        name: company.name,
        address: company.address
      }
    },
    selectCompany(company) {
      if (this.canBeSelected[company.id]) this.selectedCompany = company
    } 
  },
  computed: {
    validData() {
      if (!this.editingCompany && this.selectedCompany) return true
      return !!(
        this.editingCompany &&
        this.editingCompany.taxId &&
        this.editingCompany.name &&
        this.editingCompany.address &&
        this.validTaxId
      )
    },
    validTaxId() {
      return (
        !this.editingCompany.taxId ||
        lastUtils.taxIdIsValid(this.editingCompany.taxId)
      )
    },
    canBeSelected() {
      return this.companies.reduce((acc, company) => {
        acc[company.id] = !!(
        company &&
        company.taxId &&
        company.name &&
        company.address &&
        lastUtils.taxIdIsValid(company.taxId)
      ) 
        return acc
      }, {})
    }
  },
  components: {
    Icon,
    LInput,
    LField
  }
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}

.text-color {
  color: #2c3a4c;
}

.dialog-w {
  width: 484px;
}

.list-border {
  @apply border-b;
  @apply border-gray-200;
}

.list-border:last-of-type {
  @apply border-none;
}

.results-height {
  height: 250px;
}
</style>
