<template>
  <l-modal
    :title="$t('ordering.kitchen-note')"
    size="small"
    class="py-20 font-bold"
    @close="$emit('close')"
  >
    <template #body>
      <div class="text-xl text-center uppercase text-blue font-bold">
        {{ $t('ordering.send-kitchen-note') }}
      </div>
      <div
        v-for="course in filteredCourses"
        :key="course"
        class="bg-white border-red border py-3 text-center text-blue rounded-lg mt-4"
        @click="sendKitchenNote(course)"
      >
        {{ course }}
      </div>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal'
import { mapGetters, mapState } from 'vuex'
import TicketPrinter from '@/ticketPrinter.js'
import sync from '@/sync/service.js'

export default {
  name: 'SendKitchenNote',
  props: {
    tabId: {
      type: String,
      required: true
    }
  },
  components: {
    LModal
  },
  computed: {
    ...mapGetters('catalog', ['allCourses']),
    ...mapGetters('tabs', ['getTab']),
    ...mapState('tabs', ['tabs', 'kitchenOrders']),
    filteredCourses() {
      let allCoursesMapped = this.allCourses.map(course => {
        return course.label
      })

      let kitchenOrdersCourses = new Set(
        this.tabs[this.tabId].kitchenOrders.flatMap(id =>
          this.kitchenOrders[id].versions.flatMap(version =>
            version.products.map(prod => prod.course)
          )
        )
      )

      let kitchenOrdersCoursesSorted = new Set(
        allCoursesMapped.filter(course => kitchenOrdersCourses.has(course))
      )

      let kitchenOrdersCoursesNew = new Set(
        [...kitchenOrdersCourses].filter(
          course => !allCoursesMapped.includes(course)
        )
      )
      return new Set([
        ...kitchenOrdersCoursesSorted,
        ...kitchenOrdersCoursesNew
      ])
    }
  },
  methods: {
    sendKitchenNote(courseLabel) {
      let tab = this.getTab(this.tabId)
      TicketPrinter.printKitchenNote(courseLabel, tab)
      sync.record('kitchenNoteAdded', { courseLabel, tab })
      this.$emit('close')
    }
  }
}
</script>

<style></style>
