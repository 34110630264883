<template>
  <div>
    <div class="bg px-4 py-3 flex text-white items-center h-topbar">
      <div class="flex-1 flex items-center">
        <div v-if="showBack" @click="back" class="pr-6">
          <icon name="arrow-left" />
        </div>
        <router-link :to="{ name: 'pos' }" v-if="showHome" class="pr-6">
          <img src="@/assets/home.svg" class="h-home" />
        </router-link>
        <icon name="user" class="mr-2 text-gray-500" />
        {{ currentEmployee.name }}
        <div class="flex" v-if="tills.cash.length > 1">
          <div class="mx-2">|</div>
          <icon name="till" class="mr-2 text-gray-500" />
          {{ selectedCashTill.name }}
        </div>
        <slot name="left" />
      </div>
      <div class="center flex justify-center">
        <slot name="center" />
      </div>
      <div class="right flex-1 flex justify-end text-white items-center">
        <div v-if="trialDaysLeft || gracePeriodDaysLeft" class="mr-5 text-sm">
          {{ trialDaysLeft || gracePeriodDaysLeft }}
        </div>
        <badge
          @click.native="openNotifications"
          :quantity="totalNotifications"
          class="mr-2 cursor-pointer rounded-full"
          :class="{
            'bg-error': totalNotifications > 0
          }"
        >
          <icon
            :key="totalNotifications"
            name="bell"
            class="text-white m-2"
            :class="{
              'shake-top': totalNotifications > 0
            }"
          />
        </badge>
        <slot name="right" />
        <div
          class="w-16 flex justify-center pl-2 cursor-pointer"
          @click="showMenu = true"
        >
          <icon name="burger" />
        </div>
      </div>
    </div>
    <div
      class="py-1 bg-red text-white flex justify-between px-10"
      v-if="!isConnected"
    >
      <div class="flex">
        <icon name="alert" class="mr-2 text-white flex" />
        <div class="uppercase flex">
          {{ formatErrorMessages }}
        </div>
      </div>
    </div>
    <side-menu :open="showMenu" @close="showMenu = false" />
    <error-messages-dialog
      v-if="showErrorsPopup"
      :messages="errorMessages"
      @close="showErrorsPopup = false"
    />
  </div>
</template>

<script>
import SideMenu from '@/components/core/SideMenu.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import Badge from '@/components/Badge.vue'
import { mapGetters, mapState } from 'vuex'
import ErrorMessagesDialog from '../ErrorMessagesDialog.vue'

export default {
  name: 'TopBar',
  props: {
    showBack: {
      type: Boolean,
      default: false
    },
    showHome: {
      type: Boolean,
      default: false
    },
    showChange: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showMenu: false,
      showErrorsPopup: false
    }
  },
  methods: {
    back() {
      if (this.hasBackListener) {
        this.$emit('back')
      } else {
        this.$router.go(-1)
      }
    },
    openNotifications() {
      this.$lnotification.openFixedComponent()
    }
  },
  computed: {
    ...mapGetters('auth', ['currentEmployee']),
    ...mapGetters('till', ['selectedCashTillName']),
    ...mapState('till', ['selectedCashTill']),
    ...mapGetters('config', ['tills']),
    ...mapState('config', ['config']),
    ...mapState('auth', ['billingStatus']),
    ...mapGetters('auth', ['daysLeft']),
    ...mapGetters('status', ['isConnected']),
    ...mapState('status', ['errorMessages']),
    hasBackListener() {
      return this.$listeners && this.$listeners.back
    },
    totalErrors() {
      let errorCount = (this.isConnected ? 0 : 1) + this.errorMessages.length
      return errorCount
    },
    totalNotifications() {
      return this.$lnotification.fixedNotifications.length
    },
    formatErrorMessages() {
      let errorsPreview = ''
      if (this.totalErrors > 2) {
        errorsPreview = this.$t('topbar.n-active-errors', {
          n: this.totalErrors
        })
      } else {
        if (!this.isConnected) {
          errorsPreview = this.$t('topbar.internet-issues')
          if (this.errorMessages.length > 0) {
            errorsPreview += ', '
          }
        }
        if (this.errorMessages.length > 0) {
          errorsPreview += this.errorMessages
            .map(error => error.title)
            .join(', ')
        }
      }
      return errorsPreview
    },
    trialDaysLeft() {
      if (this.billingStatus.stillInTrial)
        return this.$t('topbar.trial-days-left', {
          days: this.daysLeft
        })
      return null
    },
    gracePeriodDaysLeft() {
      if (this.billingStatus.gracePeriodTriggered)
        return this.$t('topbar.grace-period-days-left', {
          days: this.daysLeft
        })
      return null
    }
  },
  components: {
    Icon,
    SideMenu,
    Badge,
    ErrorMessagesDialog
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
.bg {
  background: linear-gradient(270deg, #545383 0%, #1d1c48 100%);
  box-shadow: 0 0.25rem 0.375rem -0.062rem rgba(0, 0, 0, 0.1);
}

.h-topbar {
  height: 3.5rem;
}

.h-home {
  height: 2rem;
}

.shake-top {
  -webkit-animation: shake-top 2.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-top 2.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation-iteration-count: infinite;
}

@-webkit-keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  3% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  7%,
  13%,
  20% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  10%,
  16%,
  23% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  26% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  30% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
  }
  3% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  7%,
  13%,
  20% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  10%,
  16%,
  23% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  26% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  30% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
</style>
