import app from '@/app.js'
import store from '@/store'

class Browser {
  constructor(config) {
    this.config = config
  }

  printImage(imageData) {
    return new Promise((resolve, reject) => {
      const locationId = store.state.auth.locationId
      const demoLocationId = process.env.VUE_APP_DEMO_LOCATION_ID
      const isDemoLocation = locationId === demoLocationId
      if (isDemoLocation) return resolve()

      console.log(`Virtual Print to: ${this.config.address}`)
      if (!app.isElectron) {
        var image = new Image()
        image.src = imageData
        var w = window.open('')
        w.document.write(image.outerHTML)
      }
      resolve()
    })
  }

  openCashDrawer() {
    console.log(`Open Drawer: ${this.config.address}`)
  }
}

export default Browser
