<template>
  <l-modal
    :title="$t('close-delivery.title')"
    size="small"
    class="py-20 font-body"
    @close="$emit('close')"
  >
    <template v-if="!closingBrandsSelected" slot="body">
      <div class="text-xl text-center uppercase text-blue">
        {{ $t('close-delivery.text-1') }}
      </div>
      <div class="mt-1 mb-5 text-center text-gray-400">
        {{ $t('close-delivery.text-2') }}
      </div>
      <div v-if="hasEnabledServices">
        <delivery-selector
          :virtual-brand-deliveries="virtualBrandsClosingTimes"
          :current-time="currentTime"
          show-closed-until
          @closeDelivery="
            (brandId, company) => setDeliveryToClose(brandId, company)
          "
          @openDelivery="(brandId, company) => openDelivery(brandId, company)"
          @closeBrand="setBrandToClose"
          @openBrand="openBrand"
          @mounted="selectedBrandAndCompanies = {}"
        />
        <div
          class="bg-blue border-blue-600 border py-3 text-center text-white rounded-lg mt-8 uppercase"
          @click="setAllDeliveriesToClose"
        >
          {{ $t('close-delivery.close-all') }}
        </div>
      </div>
      <div v-else class="mt-12 mb-4 text-gray-300 text-center ">
        <icon name="bike" class="mx-auto w-10 h-10" />
        <span
          class="inline-block font-bold font-title my-2 uppercase text-xl"
          >{{ 'no tienes servicios de reparto activos' }}</span
        >
      </div>
      <l-modal-ctas>
        <l-modal-button
          type="main"
          :label="$t('close-delivery.close')"
          @click.native="$emit('close')"
          class="w-1/2"
        />
      </l-modal-ctas>
    </template>
    <template v-else slot="body">
      <div class="text-xl text-center uppercase text-blue">
        {{ $t('close-delivery.text-1') }}
      </div>
      <div class="mt-1 mb-5 text-center text-gray-400">
        {{ $t('close-delivery.text-3') }}
      </div>
      <div class="mt-8 text-blue -mx-8 flex flex-row flex-wrap justify-around">
        <div
          v-for="(time, index) in Object.values(closingTimes)"
          :key="time.label"
          @click="selectedClosingIndex = index"
          class="p-3 border border-red rounded-lg my-2"
          :class="{
            'w-full mx-3': index === 4,
            'width-45': index !== 4,
            'bg-red text-white': index === selectedClosingIndex,
            'text-center': index !== 4 || index !== selectedClosingIndex,
            'text-left flex flex-row justify-between':
              index === selectedClosingIndex && index === 4
          }"
        >
          <div
            :class="{ 'ml-8': index === selectedClosingIndex && index === 4 }"
          >
            {{ closingTimes[index].label }}
          </div>
          <quantity-selector
            class="mr-5"
            :label="'min'"
            :button-color="'bg-white'"
            :min="15"
            :icon-color="'text-red'"
            v-if="index === selectedClosingIndex && index === 4"
            :quantity="customQuantity"
            :step="15"
            @update:quantity="
              quantity => {
                if (quantity <= 360) customQuantity = quantity
              }
            "
          />
        </div>
      </div>
      <l-modal-ctas>
        <l-modal-button
          type="secondary"
          :label="$t('close-delivery.back')"
          class="w-1/2 mr-2"
          @click.native="closingBrandsSelected = false"
        />
        <l-modal-button
          type="main"
          :label="$t('close-delivery.accept')"
          class="w-1/2 ml-2"
          @click.native="startClosing"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LModal from '@last/core-ui/components/LModal'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import { mapState } from 'vuex'
import QuantitySelector from './ordering/QuantitySelector'
import DeliverySelector from './DeliverySelector'
import moment from 'moment'
import sync from '@/sync/service.js'

export default {
  name: 'CloseDelivery',
  data() {
    return {
      selectedBrandAndCompanies: {},
      closingBrandsSelected: false,
      currentTime: null,
      selectedClosingIndex: null,
      customQuantity: 15,
      closingTimes: [
        {
          minutes: 30,
          label: this.$t('close-delivery.30-minutes')
        },
        {
          minutes: 60,
          label: this.$t('close-delivery.1-hour')
        },
        {
          minutes: 'untilClosure',
          label: this.$t('close-delivery.until-closure')
        },
        {
          minutes: 'indefinitely',
          label: this.$t('close-delivery.indefinitely')
        },
        {
          label: this.$t('close-delivery.custom')
        }
      ]
    }
  },
  mounted() {
    this.currentTime = new Date()
    this.interval = setInterval(() => (this.currentTime = new Date()), 1000)
  },
  beforeDestroy() {
    window.clearInterval(this.interval)
  },
  methods: {
    setAllDeliveriesToClose() {
      this.virtualBrandsClosingTimes.forEach(brand => {
        this.selectedBrandAndCompanies[brand.id] = Object.keys(
          brand.externalDeliveryCompaniesClosingTimes
        )
      })
      this.closingBrandsSelected = true
    },
    setBrandToClose(brandId) {
      this.selectedBrandAndCompanies[brandId] = Object.keys(
        this.virtualBrandsClosingTimes.find(brand => brand.id === brandId)
          .externalDeliveryCompaniesClosingTimes
      )
      this.closingBrandsSelected = true
    },
    openBrand(brandId) {
      let deliveriesToOpen = {}
      deliveriesToOpen[brandId] = Object.keys(
        this.virtualBrandsClosingTimes.find(brand => brand.id === brandId)
          .externalDeliveryCompaniesClosingTimes
      )
      sync.record('openDeliveries', {
        deliveriesToOpen
      })
    },
    setDeliveryToClose(brandId, company) {
      this.selectedBrandAndCompanies[brandId] = [company]
      this.closingBrandsSelected = true
    },
    openDelivery(brandId, company) {
      let deliveriesToOpen = {}
      deliveriesToOpen[brandId] = [company]
      sync.record('openDeliveries', {
        deliveriesToOpen
      })
    },
    startClosing() {
      if (this.selectedClosingIndex === null) {
        return
      }
      let closingTime =
        this.closingTimes[this.selectedClosingIndex].minutes ||
        this.customQuantity
      let closedUntil
      if (!isNaN(closingTime)) {
        closedUntil = moment(new Date()).add(closingTime, 'minutes')
      } else if (closingTime === 'indefinitely') {
        closedUntil = moment(new Date()).add(10, 'year')
      } else {
        let endTime = this.config.workingTime.end.split(':')
        let endHour = parseInt(endTime[0])
        let endMinute = parseInt(endTime[1])
        closedUntil = moment(new Date())
          .hour(endHour)
          .minute(endMinute)
        if (
          moment(new Date())
            .hour(endHour)
            .minute(endMinute) < moment(new Date())
        ) {
          closedUntil = closedUntil.add(1, 'day')
        }
      }
      closedUntil = closedUntil.toISOString().split('.')[0] + 'Z'

      sync.record('deliveriesClosed', {
        deliveriesToClose: this.selectedBrandAndCompanies,
        closedUntil
      })
      this.selectedBrandAndCompanies = {}
      this.closingBrandsSelected = false
    }
  },
  components: {
    DeliverySelector,
    QuantitySelector,
    LModal,
    LModalCtas,
    LModalButton,
    Icon
  },
  computed: {
    ...mapState('config', ['config']),
    ...mapState('deliveryCompanies', ['virtualBrandsClosingTimes']),
    totalEnabledServices() {
      return Object.values(this.virtualBrandsClosingTimes)
        .map(vb => Object.keys(vb.externalDeliveryCompaniesClosingTimes).length)
        .reduce((total, count) => total + count, 0)
    },
    hasEnabledServices() {
      return this.totalEnabledServices > 0
    }
  }
}
</script>

<style scoped>
.arrow {
  transition: all 0.3s;
}

.rotated {
  transform: rotate(90deg);
}

.width-45 {
  width: 45%;
}
</style>
