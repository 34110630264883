<template>
  <div id="tables-content" class="bg-blue-900">
    <swiper
      class="swiper"
      :total-slides="floorplanIds.length"
      @slideChange="updateSelectedFloorplan"
      :index="index"
      :allow-touch-move="!isMobile"
    >
      <div
        class="swiper-slide"
        v-for="floorplanId in floorplanIds"
        :key="floorplanId"
      >
        <div class="p-4 floorplan-container">
          <floor-plan
            class="floor-plan"
            :floorplan-id="floorplanId"
            :key="floorplanIds[index]"
            @tableSelected="tableSelected"
          />
        </div>
      </div>
    </swiper>
    <div class="flex justify-center p-3 hidden sm:flex">
      <div class="flex items-center text-sm text-gray-400">
        <div class="w-5 h-5 mr-2 bg-white rounded-full" />
        {{ $t('floorplan.free') }}
      </div>
      <div class="flex items-center ml-3 text-sm text-gray-400">
        <div class="w-5 h-5 mr-2 rounded-full bg-light-blue" />
        {{ $t('floorplan.occupied') }}
      </div>
      <div class="flex items-center ml-3 text-sm text-gray-400">
        <div class="w-5 h-5 mr-2 rounded-full bg-red" />
        {{ $t('floorplan.to-pay') }}
      </div>
      <div class="flex items-center ml-3 text-sm text-gray-400">
        <div class="w-5 h-5 mr-2 rounded-full bg-yellow" />
        {{ $t('floorplan.reserved') }}
      </div>
      <template v-if="config.enableKitchenOrders">
        <div class="ml-3">|</div>
        <div class="flex items-center ml-3 text-sm text-gray-400">
          <div class="w-4 h-4 p-1 mr-2 bg-white rounded-full">
            <div class="w-full h-full mr-2 rounded-full bg-yellow" />
          </div>
          {{ $t('floorplan.not-sent-to-kitchen') }}
        </div>
      </template>
    </div>
    <new-tab
      v-if="selectedTable && newTab"
      :table="selectedTable"
      :customer="customer"
      @close="closeModals"
      @tabCreated="linkToReservationIfNeeded"
    />
    <link-tab-with-reservation-modal
      v-if="showLinkTabWithReservationModal"
      @link="linkReservation"
      :reservation="getReservation(selectedTable.id)"
      @use="useTableWithReservation"
      @close="closeModals"
    />
    <tab-selector
      v-if="tabIdsToSelect"
      :tab-ids-to-select="tabIdsToSelect"
      @tabSelected="tabSelected"
      @close="tabIdsToSelect = null"
    />
  </div>
</template>

<script>
import FloorPlan from './FloorPlan.vue'
import NewTab from '@/components/tabs/NewTab.vue'
import LinkTabWithReservationModal from '@/components/tabs/LinkTabWithReservationModal.vue'
import Swiper from './Swiper.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import TabSelector from './TabSelector.vue'
import app from '@/app.js'

export default {
  name: 'TableTabs',
  props: {
    floorplanId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedTable: undefined,
      newTab: null,
      tabIdsToSelect: null,
      index: 0,
      showLinkTabWithReservationModal: false,
      linkTabToReservationNeeded: false,
      isMobile: false
    }
  },
  async mounted() {
    this.isMobile = app.isMobile
    this.updateIndex()
  },
  computed: {
    ...mapState('tables', ['floorplanIds']),
    ...mapState('config', ['config']),
    ...mapGetters('reservations', ['getReservation']),
    customer() {
      if (this.linkTabToReservationNeeded) {
        let reservation = this.getReservation(this.selectedTable.id)
        return {
          name: reservation.name,
          surname: reservation.surname,
          phoneNumber: reservation.phoneNumber,
          email: reservation.email
        }
      }
      return null
    }
  },
  methods: {
    ...mapActions('tabs', ['openTab']),
    ...mapActions('reservations', ['editReservation']),
    tableSelected(table) {
      if (table.tabIds.length === 1) {
        this.$router.push({
          name: 'orderManagement',
          params: { tabId: table.tabIds[0] }
        })
      } else if (table.tabIds.length > 1) {
        this.tabIdsToSelect = table.tabIds
      } else {
        this.selectedTable = table
        if (this.getReservation(table.id)) {
          this.showLinkTabWithReservationModal = true
        } else {
          this.createNewTab()
        }
      }
    },
    tabSelected(tabId) {
      this.tabIdsToSelect = null
      this.$router.push({
        name: 'orderManagement',
        params: { tabId }
      })
    },
    createNewTab() {
      this.newTab = {
        seats: this.selectedTable.seats,
        lang: null,
        name: null
      }
    },
    closeModals() {
      this.selectedTable = undefined
      this.newTab = undefined
      this.showLinkTabWithReservationModal = false
      this.clearReservationConfig()
    },
    linkReservation() {
      this.linkTabToReservationNeeded = true
      this.useTableWithReservation()
    },
    useTableWithReservation() {
      this.showLinkTabWithReservationModal = false
      this.createNewTab()
    },
    clearReservationConfig() {
      this.linkTabToReservationNeeded = false
    },
    linkToReservationIfNeeded(tabId) {
      if (!this.linkTabToReservationNeeded) return

      let reservation = this.getReservation(this.selectedTable.id)

      if (!reservation) return

      reservation.tabId = tabId
      this.editReservation(reservation)
    },
    updateSelectedFloorplan(index) {
      this.$emit('update:floorplanId', this.floorplanIds[index])
    },
    updateIndex() {
      this.index = Math.max(this.floorplanIds.indexOf(this.floorplanId), 0)
    }
  },
  watch: {
    floorplanId() {
      this.updateIndex()
    }
  },
  components: {
    FloorPlan,
    NewTab,
    LinkTabWithReservationModal,
    Swiper,
    TabSelector
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.floorplan-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

#tables-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.swiper {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
}
</style>
