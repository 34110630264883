import moment from 'moment'
import Vue from 'vue'

const state = {
  virtualBrandsClosingTimes: []
}

const getters = {
  sortedClosedUntil: state => {
    return state.virtualBrandsClosingTimes
      .flatMap(brand =>
        Object.values(brand.externalDeliveryCompaniesClosingTimes)
      )
      .filter(time => time !== null)
      .sort((a, b) => moment(a).unix() - moment(b).unix())
  },
  virtualBrandsWithClosedDeliveries: state => {
    return state.virtualBrandsClosingTimes
      .map(brand => {
        return {
          ...brand,
          externalDeliveryCompaniesClosingTimes: Object.entries(
            brand.externalDeliveryCompaniesClosingTimes
          ).reduce((res, [key, value]) => {
            if (value && moment(value).isAfter(moment())) {
              res[key] = value
            }
            return res
          }, {})
        }
      })
      .filter(
        brand =>
          Object.values(brand.externalDeliveryCompaniesClosingTimes).length > 0
      )
  }
}

const actions = {
  refreshClosingTimes({ commit }, virtualBrandsClosingTime) {
    commit('refreshClosingTimes', virtualBrandsClosingTime)
  }
}

const mutations = {
  refreshClosingTimes(state, virtualBrandClosingTimes) {
    state.virtualBrandsClosingTimes = virtualBrandClosingTimes
  },
  closeDeliveries(state, { deliveriesToClose, closedUntil }) {
    Object.keys(deliveriesToClose).forEach(brandId => {
      deliveriesToClose[brandId].forEach(company => {
        let targetBrand = state.virtualBrandsClosingTimes.find(
          brand => brand.id === brandId
        )
        let currentClosedUntil =
          targetBrand.externalDeliveryCompaniesClosingTimes[company]
        if (
          currentClosedUntil &&
          moment(currentClosedUntil).isAfter(moment(closedUntil))
        ) {
          return
        }
        targetBrand.externalDeliveryCompaniesClosingTimes[company] = closedUntil
        Vue.set(state.virtualBrandsClosingTimes, brandId, targetBrand)
      })
    })
  },
  openDeliveries(state, { deliveriesToOpen }) {
    Object.keys(deliveriesToOpen).forEach(brandId => {
      deliveriesToOpen[brandId].forEach(async company => {
        let targetBrand = state.virtualBrandsClosingTimes.find(
          brand => brand.id === brandId
        )
        targetBrand.externalDeliveryCompaniesClosingTimes[company] = null

        Vue.set(state.virtualBrandsClosingTimes, brandId, targetBrand)
      })
    })
  },
  closeDelivery(state, { brandId, company, closedUntil }) {
    let targetBrand = state.virtualBrandsClosingTimes.find(
      brand => brand.id === brandId
    )
    targetBrand.externalDeliveryCompaniesClosingTimes[company] = closedUntil

    Vue.set(state.virtualBrandsClosingTimes, brandId, targetBrand)
  },
  openDelivery(state, { brandId, company }) {
    let targetBrand = state.virtualBrandsClosingTimes.find(
      brand => brand.id === brandId
    )
    targetBrand.externalDeliveryCompaniesClosingTimes[company] = null

    Vue.set(state.virtualBrandsClosingTimes, brandId, targetBrand)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
