<template>
  <div class="h-screen flex flex-col overflow-hidden">
    <top-bar show-back> </top-bar>
    <div class="bg-gray-200 w-full h-full flex items-center justify-center">
      <div
        class="report-w h-full overflow-y-scroll scrolling-touch"
        v-if="report"
      >
        <div class="py-10 px-1">
          <div class="text-center font-bold uppercase text-blue text-2xl pb-6">
            {{ $t('report.real-time-report') }}
          </div>
          <report-preview :report="report" />
          <div class="flex justify-end">
            <div
              class="mt-6 p-3 px-6 shadow-lg text-white bg-red border border-red-b uppercase rounded-small font-bold ml-4 text-center"
              @click="print()"
            >
              {{ $t('report.print') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/core/TopBar.vue'
import ReportPreview from '@/components/ReportPreview.vue'
import moment from 'moment'
import api from '@/api.js'
import TicketPrinter from '@/ticketPrinter.js'
import { mapState } from 'vuex'

export default {
  name: 'RealTimeReport',
  data() {
    return {
      report: null
    }
  },
  mounted() {
    this.refreshReport()
  },
  methods: {
    async refreshReport() {
      let response = await api.get('/reports/z', {
        params: {
          startDate: this.today,
          endDate: this.today
        }
      })
      this.report = response.data
    },
    print() {
      TicketPrinter.printRealTimeReport()
    }
  },
  computed: {
    ...mapState('config', ['config']),
    endOfDay() {
      return this.config.workingTime.end
    },
    today() {
      let endTimeParts = this.endOfDay.split(':')
      let hour = parseInt(endTimeParts[0])
      let minute = parseInt(endTimeParts[1])
      return moment()
        .subtract(hour, 'hours')
        .subtract(minute, 'minutes')
        .format('YYYY-MM-DD')
    }
  },
  components: {
    TopBar,
    ReportPreview
  }
}
</script>

<style>
.report-w {
  width: 484px;
}
</style>
