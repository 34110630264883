import i18n from '@/i18n.js'
import moment from 'moment'
import {
  Label,
  EmptySeparator,
  Separator,
  DoubleLabel,
  Section,
  Total
} from './components.js'

const textStyle = {
  firstStyle: 'bold 20px Roboto',
  secondStyle: '20px Roboto',
  secondType: ''
}

const titleStyle = {
  align: 'left',
  style: 'bold 25px',
  lineHeight: 30
}

function splitDateAndHour(creationTime) {
  return {
    day: moment(creationTime).format('DD/MM/YYYY'),
    hour: moment(creationTime).format('HH:mm')
  }
}

function getStoreAndCardInfo(payment) {
  return [
    new Section(i18n.t('bank-receipt.information')),
    new DoubleLabel(
      i18n.t('bank-receipt.commerce'),
      payment.metadata.receipt.commerce,
      textStyle
    ),
    new DoubleLabel(
      i18n.t('bank-receipt.terminal'),
      payment.metadata.receipt.terminal,
      textStyle
    ),
    new DoubleLabel(
      i18n.t('bank-receipt.client-card'),
      payment.metadata.receipt.clientCard,
      textStyle
    ),
    new DoubleLabel(i18n.t('bank-receipt.expiration'), '0207', textStyle),
    new EmptySeparator()
  ]
}

function getSaleInfo(payment) {
  const { day, hour } = splitDateAndHour(payment.creationTime)
  return [
    new Section(i18n.t('bank-receipt.sale')),
    new DoubleLabel(
      i18n.t('bank-receipt.autorization'),
      payment.metadata.receipt.resCode,
      textStyle
    ),
    new DoubleLabel(
      i18n.t('bank-receipt.order'),
      payment.metadata.receipt.orderNumber,
      textStyle
    ),
    new DoubleLabel(i18n.t('bank-receipt.date'), day, textStyle),
    new DoubleLabel(i18n.t('bank-receipt.hour'), hour, textStyle),
    new EmptySeparator()
  ]
}

function getTransactionInfo(payment) {
  return [
    new Section(payment.metadata.receipt.tagApp),
    new DoubleLabel(
      i18n.t('bank-receipt.application'),
      payment.metadata.receipt.idApp,
      textStyle
    ),
    new DoubleLabel(
      i18n.t('bank-receipt.n-trans'),
      payment.metadata.receipt.transactionNum,
      textStyle
    ),
    new DoubleLabel(
      i18n.t('bank-receipt.response'),
      payment.metadata.receipt.resCodeAuto,
      textStyle
    ),
    new DoubleLabel(
      i18n.t('bank-receipt.tvr'),
      payment.metadata.receipt.resVerification,
      textStyle
    ),
    new EmptySeparator()
  ]
}

function getPaymentInfo(payment) {
  return [new Total(payment.amount)]
}

function generateBankReceipt(payment) {
  return [
    new EmptySeparator(),
    new Label(i18n.t('bank-receipt.title'), titleStyle),
    ...getStoreAndCardInfo(payment),
    ...getSaleInfo(payment),
    ...getTransactionInfo(payment),
    new Separator(),
    ...getPaymentInfo(payment)
  ]
}

export default generateBankReceipt
