<template>
  <div
    class="bg-blue-800 text-white flex flex-col h-screen items-center px-8 text-center"
  >
    <icon
      v-if="showExitButton"
      name="close"
      class="text-white cursor-pointer absolute right-0 top-0 mr-5 mt-5"
      @click="$emit('close')"
    />
    <img v-if="image" :src="imagePath" class="w-40 h-40 mt-12" />
    <div
      class="uppercase font-title text-2xl font-bold mt-4"
      :class="{
        'mt-20': !image
      }"
    >
      {{ title }}
    </div>
    <div class="flex flex-1 mt-4">{{ message }}</div>
    <img class="w-40 my-4" src="@/assets/logo-color.png" />
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'

export default {
  name: 'InfoScreen',
  props: {
    title: {
      type: String,
      default: null,
      required: true
    },
    message: {
      type: String,
      default: null,
      required: true
    },
    showExitButton: {
      type: Boolean,
      default: true
    },
    image: {
      type: String,
      default: null
    }
  },
  computed: {
    imagePath() {
      return require(`@/assets/${this.image}`)
    }
  },
  components: {
    Icon
  }
}
</script>

<style></style>
