<template>
  <div id="wrapper">
    <div
      id="scaled"
      ref="content"
      :style="{ transform: 'scale(' + scale + ')' }"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scale: 1
    }
  },
  mounted: function() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  activated() {
    this.handleResize()
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.xscale = this.$el.clientWidth / this.$refs.content.clientWidth
      this.yscale = this.$el.clientHeight / this.$refs.content.clientHeight
      this.scale = Math.min(this.xscale, this.yscale)
    }
  }
}
</script>

<style scoped>
#wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
#scaled {
  transform-origin: 50% 0;
  position: absolute;
  display: inline-block;
}
</style>
