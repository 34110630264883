<template>
  <div>
    <div @click="editDiscount">
      <slot  />
    </div>
    <portal to="appRoot" v-if="showModal">
      <l-modal @close="close" :title="$t('bill-discount.title')" full-height>
        <template #body>
          <div>
            <div class="mt-2">
              <div class="flex justify-between">
                <p class="text-blue"> {{ $t('bill-discount.select-discount-type') }}</p>
                <icon v-if="hasAlreadyDiscount" name="trash" class="text-red" @click="removeDiscount" />
              </div>
              <l-select optionValue="type" optionLabel="label" :value="editingDiscount.type" :options="discountTypes" @input="updateDiscountType"/>
            </div>
            <section v-if="editingDiscount.type !== 'promotion'">
              <div class="mt-4">
                <p class="text-blue"> {{ $t('bill-discount.discount-to-apply') }}</p>
                <l-input v-model="editingDiscount.amount" :icon="iconName"/>
              </div>
            </section>
            <section v-else>
              <div class="mt-4">
                <p class="text-blue"> {{ $t('bill-discount.points-discounts') }} </p>
                <div class="flex flex-wrap w-full gap-6">
                <div
                    v-for="promotion in promotionsWithPoints"
                    :key="promotion.id"
                    class="px-2 py-3 whitespace-no-wrap border border-gray-400 rounded-lg width-200"
                    :class="{
                      'bg-red text-white':
                        editingDiscount.promotionId === promotion.id
                    }"
                    @click="promotionSelected(promotion)"
                  >
                    <div
                      :class="{
                        'text-blue':
                          editingDiscount.promotionId !== promotion.id
                      }"
                    >
                      {{ promotion.name }}
                    </div>
                    <div :class="{'text-gray-300': editingDiscount.promotionId === promotion.id,
                  'text-gray-400': editingDiscount.promotionId !== promotion.id}">
                      <div class="flex flex-row text-sm">
                        <div v-if="promotion.discountType === 'percentage'">
                          {{ promotion.discountAmount }}%
                        </div>
                        <div v-else>
                          {{ promotion.discountAmount | currency }}
                        </div>
                        <div class="pl-1">
                          {{ $t('bill-discount.of-discount') }}
                        </div>
                      </div>
                      <p class="text-sm" v-if="promotion.remainingRedemptions">
                        {{
                          promotion.remainingRedemptions +
                          ' ' +
                          $t('bill-discount.remaining-redemptions')
                        }}
                      </p>
                      <div
                      :class="{
                        'text-green':
                          editingDiscount.promotionId !== promotion.id
                      }"
                      class="mt-2 text-right"
                      >
                      {{ promotion.pointsExpense }}
                      {{ $t('bill-discount.points') }}
                      </div>
                  </div>
                </div>
                </div>
              </div>
              <div class="mt-4">
                <p class="text-blue"> {{ $t('bill-discount.no-points-discounts') }} </p>
                <div class="flex flex-wrap w-full gap-6">
                <div
                    v-for="promotion in promotionsWithoutPoints"
                    :key="promotion.id"
                    class="px-2 py-3 whitespace-no-wrap border border-gray-400 rounded-lg width-200"
                    :class="{
                      'bg-red text-white':
                        editingDiscount.promotionId === promotion.id
                    }"
                    @click="promotionSelected(promotion)"
                  >
                    <div
                      :class="{
                        'text-blue':
                          editingDiscount.promotionId !== promotion.id
                      }"
                    >
                      {{ promotion.name }}
                    </div>
                    <div :class="{'text-gray-300': editingDiscount.promotionId === promotion.id,
                  'text-gray-400': editingDiscount.promotionId !== promotion.id}">
                      <div class="flex flex-row text-sm">
                        <div v-if="promotion.discountType === 'percentage'">
                          {{ promotion.discountAmount }}%
                        </div>
                        <div v-else>
                          {{ promotion.discountAmount | currency }}
                        </div>
                        <div class="pl-1">
                          {{ $t('bill-discount.of-discount') }}
                        </div>
                      </div>
                      <p class="text-sm" v-if="promotion.remainingRedemptions">
                        {{
                          promotion.remainingRedemptions +
                          ' ' +
                          $t('bill-discount.remaining-redemptions')
                        }}
                      </p>
                  </div>
                </div>
                </div>
              </div>
            </section>
          </div>
        </template>
        <template #footer>
          <l-button @click="save" class="bg-accent w-full">
            {{ $t('bill-discount.save') }}
          </l-button>
        </template>
      </l-modal>
    </portal>
  </div>
</template>

<script>
import LInput from '@last/core-ui/components/LInput'
import { mapGetters, mapState } from 'vuex'
import api from '@/api.js'
import moment from 'moment'
import PromotionFilter from '@last/core/src/PromotionFilter'
import LSelect from '@last/core-ui/components/LSelect'
import LModal from '@last/core-ui/components/LModal'
import LButton from '@last/core-ui/components/LButton'
import Icon from '@last/core-ui/components/Icon'

export default {
  name: 'BillDiscount',
  props: {
    discount: {
      type: Object,
      default: () => ({})
    },
    originalTotal: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabId: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'light'
    }
  },
  data() {
    return {
      showModal: false,
      customerPoints: 0,
      editingDiscount: {},
      customerRestrictedPromotions: [],
      customerUsedPromotions: []
    }
  },
  async mounted() {
    let customerId = this.getCustomerId(this.tabId)
    if (customerId) {
      let response = await api.get(`/customer/${customerId}/promotions`)
      this.customerPoints = response.data.points
      this.customerRestrictedPromotions = response.data.customerPromotions
      this.customerUsedPromotions = response.data.customerUsedPromotions
    }
  },
  methods: {
    updateDiscountType(type) {
      this.editingDiscount.type = type
      this.editingDiscount.amount = ''
    },
    close() {
      this.$emit('close')
      this.showModal = false
    },
    removeDiscount() {
      this.$emit('remove-discount')
      this.showModal = false
    },
    promotionSelected(promotion) {
      this.editingDiscount = {
        ...this.editingDiscount,
        promotionId: promotion.id,
        promotionType: promotion.discountType,
        promotionAmount: promotion.discountAmount,
        pointsExpense: promotion.pointsExpense,
        promotionName: promotion.name,
        freeDelivery: promotion.freeDelivery
      }
    },
    async editDiscount() {
      if (this.disabled) return
      this.editingDiscount = {
        type: this.discount?.promotionId
          ? 'promotion'
          : this.discount?.type || 'currency',
        amount:
          this.discount?.type === 'currency'
            ? this.discount?.amount / 100
            : this.discount?.amount,
        promotionId: this.discount?.promotionId,
        promotionType: this.discount?.type,
        promotionAmount: this.discount?.amount,
        pointsExpense: this.discount?.pointsExpense,
        freeDelivery: this.discount?.freeDelivery
      }
      this.showModal = true
    },
    setPromotion() {
      this.$emit('update:discount', {
        type: this.editingDiscount.promotionType,
        amount: this.editingDiscount.promotionAmount,
        name: this.editingDiscount.promotionName,
        promotionId: this.editingDiscount.promotionId,
        pointsExpense: this.editingDiscount.pointsExpense,
        concept: this.editingDiscount.promotionName,
        freeDelivery: this.editingDiscount.freeDelivery,
        global: true
      })
    },
    async save() {
      const hasPermission = await this.$confirm('DISCOUNT_MANAGER')
      if (!hasPermission) {
        this.emit('close')
        return
      }
      if (this.editingDiscount.type === 'promotion') {
        this.setPromotion()
        this.showModal = false
        return
      }
      let amount = parseInt(this.editingDiscount.amount)
      if (this.editingDiscount.type === 'currency') {
        amount = Math.round(
          String(this.editingDiscount.amount).replace(',', '.') * 100
        )
        if (this.amount > this.originalTotal) {
          return
        }
      }
      this.$emit('update:discount', {
        type: this.editingDiscount.type,
        amount,
        global: true
      })
      this.showModal = false
    },
    checkIfPromotionCanBeShown(promotion) {
      const isNotProductOrCategory =
        !promotion.products && !promotion.categories

      let isCorrectAmount = true
      if (promotion.discountType === 'currency') {
        isCorrectAmount = promotion.discountAmount <= this.originalTotal
      }
      let isCorrectType = ['currency', 'percentage'].includes(
        promotion.discountType
      )

      let today = moment().locale('en').format('dddd').toLowerCase()
      return (
        isNotProductOrCategory &&
        isCorrectType &&
        isCorrectAmount &&
        new PromotionFilter(
          this.getTotal(this.tabId),
          this.locationId,
          today,
          this.tabs[this.tabId].pickupType,
          moment().format(),
          this.getCustomerId(this.tabId),
          this.customerUsedPromotions
        ).isPromotionValid(promotion)
      )
    }
  },
  computed: {
    ...mapState('auth', ['locationId']),
    ...mapState('tabs', ['tabs']),
    ...mapGetters('promotions', [
      'getPromotionsWithPoints',
      'getPromotionsWithoutPoints'
    ]),
    ...mapGetters('tabs', ['getCustomerId', 'getTotal']),
    ...mapGetters('config', ['currencyIcon']),
    wrongAmount() {
      if (this.editingDiscount.type === 'currency') {
        let amount = String(this.editingDiscount.amount).replace(',', '.') * 100
        if (amount > this.originalTotal) {
          return true
        }
      } else if (this.editingDiscount.type === 'percentage') {
        let amount = parseInt(this.editingDiscount.amount)
        if (amount < 0 || amount > 100 || isNaN(amount)) return true
      }
      return false
    },
    promotionsWithPoints() {
      return this.getPromotionsWithPoints
        .concat(this.customerRestrictedPromotions)
        .filter(promotion => {
          return (
            this.checkIfPromotionCanBeShown(promotion) &&
            promotion.pointsExpense <= this.customerPoints
          )
        })
    },
    promotionsWithoutPoints() {
      return this.getPromotionsWithoutPoints
        .concat(this.customerRestrictedPromotions)
        .filter(promotion => {
          return this.checkIfPromotionCanBeShown(promotion)
        })
    },
    discountTypes() {
      let types = [
        {
          type: 'percentage',
          label: this.$t('bill-discount.percentage')
        },
        {
          type: 'currency',
          label: this.$t('bill-discount.cash')
        }
      ]
      if (
        this.promotionsWithPoints.length > 0 ||
        this.promotionsWithoutPoints.length > 0
      ) {
        types.push({
          type: 'promotion',
          label: this.$t('bill-discount.promotion')
        })
      }
      return types
    },
    iconName() {
      return this.editingDiscount.type === 'currency'
        ? this.currencyIcon
        : 'porcentaje'
    },
    hasAlreadyDiscount() {
      return !!this.discount.amount
    }
  },
  components: {
    LSelect,
    LInput,
    LModal,
    LButton,
    Icon
  }
}
</script>

<style scoped>
.gap-6 {
  gap: 6px;
}
</style>
