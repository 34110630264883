<template>
  <div class="flex overflow-scroll">
    <div
      v-for="language in languages"
      :key="language.lang"
      @click="select(language)"
      :class="{ selected: language.value === value }"
      class="item flex-shrink-0 mr-1 sm:flex-none flex items-center p-2 border border-gray-300 rounded-lg text-sm sm:text-md text-blue"
    >
      <img :src="svg(language.icon)" class="mr-2 w-5 sm:w-auto" />
      <div>
        {{ language.lang }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewTabLanguageSelector',
  props: {
    value: String
  },
  methods: {
    select(language) {
      this.$emit('input', language.value)
    },
    svg(name) {
      try {
        return require(`@last/core-ui/components/assets/svg/${name}.svg`)
      } catch (e) {
        return ''
      }
    }
  },
  data: () => ({
    languages: [
      {
        lang: 'ESP',
        value: 'ES',
        icon: 'es'
      },
      {
        lang: 'ITA',
        value: 'IT',
        icon: 'it'
      },
      {
        lang: 'FRA',
        value: 'FR',
        icon: 'fr'
      },
      {
        lang: 'ENG',
        value: 'EN',
        icon: 'en'
      },
      {
        lang: 'DEU',
        value: 'DE',
        icon: 'de'
      },
      {
        lang: 'CAT',
        value: 'CA',
        icon: 'cat'
      }
    ]
  })
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
@import '@/mixins.scss';

.item {
  &.selected {
    background: $blue;
    color: white;
  }
}
::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none;
}
</style>
