export default {
  data: () => ({
    keyUpTrigger: null,
    calculatorInitialized: false,
    toPay: 0,
    currentOperation: null,
    currentOperationSymbol: null,
    currentNumber: null,
    currentOperationFilter: null
  }),
  methods: {
    handleButtonClicked(value) {
      if (!isNaN(parseInt(value))) {
        this.handleNumberClicked(value)
      } else {
        this.handleOperationClicked(value)
      }
    },
    handleNumberClicked(number) {
      const intNumber = parseInt(number)
      if (this.currentOperation) {
        this.currentNumber = (this.currentNumber || 0) * 10 + intNumber
      } else {
        this.toPay = this.toPay * 10 + intNumber
      }
    },
    handleOperationClicked(value) {
      if (value === 'DEL') return this.deleteNumber()
      if (value === 'c') return this.clear()
      if (value === '=') return this.equals()
      if (value === '*') return this.multiply()
      if (value === '/') return this.divide()
      if (value === '+') return this.add()
      if (value === '-') return this.subtract()
      if (value === '/ 2') return this.divideBy(2)
      if (value === '/ 3') return this.divideBy(3)
    },
    deleteNumber() {
      if (this.currentOperation && this.currentNumber) {
        this.currentNumber = Math.floor(this.currentNumber / 10)
        if (this.currentNumber === 0) this.currentNumber = null
      } else if (this.currentOperation) {
        this.cancelOperation()
      } else {
        this.toPay = Math.floor(this.toPay / 10)
      }
    },
    prefillToPay() {
      if (this.paymentMethod?.type !== 'cash') {
        this.fullAmount()
      } else {
        this.toPay = 0
      }
    },
    fullAmount() {
      this.toPay = this.pendingAmount
    },
    charge() {
      if (this.pendingAmount === 0) {
        this.$emit('close')
      }
      if (this.toPay > 0) {
        this.$emit('charge', this.toPay, this.paymentMethod)
      }
    },
    equals() {
      if (this.currentOperation && this.currentNumber) {
        this.toPay = this.currentOperation(this.toPay, this.currentNumber)
      }
      this.cancelOperation()
    },
    cancelOperation() {
      this.currentOperation = null
      this.currentOperationSymbol = null
      this.currentNumber = null
      this.currentOperationFilter = null
    },
    divideBy(number) {
      this.toPay = Math.ceil(this.toPay / number)
    },
    clear() {
      this.toPay = 0
    },
    divide() {
      this.equals()
      this.currentOperation = (a, b) => Math.ceil(a / b)
      this.currentOperationSymbol = '/'
      this.currentOperationFilter = null
    },
    multiply() {
      this.equals()
      this.currentOperation = (a, b) => a * b
      this.currentOperationSymbol = '*'
      this.currentOperationFilter = null
    },
    add() {
      this.equals()
      this.currentOperation = (a, b) => a + b
      this.currentOperationSymbol = '+'
      this.currentOperationFilter = this.$options.filters.currency
    },
    subtract() {
      this.equals()
      this.currentOperation = (a, b) => a - b
      this.currentOperationSymbol = '-'
      this.currentOperationFilter = this.$options.filters.currency
    },
    initNumberKeysListener(
      addNumber,
      removeOneNumber,
      removeAllNumbers,
      onEnterPress
    ) {
      if (this.calculatorInitialized) return
      this.calculatorInitialized = true
      let noop = () => {}
      this.keyUpTrigger = event =>
        this.execKey(
          event,
          addNumber || noop,
          removeOneNumber || noop,
          removeAllNumbers || noop,
          onEnterPress || noop
        )
      window.addEventListener('keyup', this.keyUpTrigger)
    },
    destroyNumberKeysListener() {
      this.calculatorInitialized = false
      window.removeEventListener('keyup', this.keyUpTrigger)
    },
    execKey(event, addNumber, removeOneNumber, removeAllNumbers, onEnterPress) {
      if (document.activeElement.tagName === 'INPUT') return
      if (event.key === 'Backspace') removeOneNumber()
      if (event.key === 'Escape') removeAllNumbers()
      if (event.key === 'Enter') onEnterPress()
      if (!isNaN(parseInt(event.key))) addNumber(parseInt(event.key))
    }
  },
  beforeDestroy() {
    this.destroyNumberKeysListener()
  }
}
