<template>
  <l-modal
    size="medium"
    :title="$t('reservations.new')"
    class="h-full"
    @close="$emit('close')"
  >
    <template slot="body">
      <div class="flex justify-between">
        <l-field class="flex-1 mr-4" :label="$t('reservations.name')">
          <l-input
            v-model="internalReservation.name"
            :placeholder="$t('reservations.name')"
            icon-class="text-grey"
            icon="user"
          />
        </l-field>
        <l-field class="flex-1" :label="$t('reservations.surname')">
          <l-input
            v-model="internalReservation.surname"
            :placeholder="$t('reservations.surname')"
            icon-class="text-grey"
          />
        </l-field>
      </div>
      <l-field
        class="flex-1 w-1/2 pr-2"
        :label="$t('reservations.phone-number')"
      >
        <l-phone-input
          icon="phone"
          icon-class="text-grey"
          :placeholder="$t('reservations.phone-number')"
          :wrong-value="validating && !validPhoneNumber"
          v-model="internalReservation.phoneNumber"
          :default-country-code="config.locationCountryCode"
        />
      </l-field>
      <div class="flex justify-between">
        <l-field class="flex-1 mr-4" :label="$t('reservations.diners')">
          <l-input
            v-model="internalReservation.diners"
            :placeholder="$t('reservations.diners-placeholder')"
            icon="people"
            icon-class="text-grey"
            type="number"
          />
        </l-field>
        <l-field class="flex-1" :label="$t('reservations.time')">
          <l-date-time-picker
            v-model="internalReservation.dateTime"
            icon="time"
            icon-class="text-grey"
            :placeholder="$t('reservations.time-placeholder')"
            :max-days="60"
            :timezone="config.workingTime.timezone"
          />
        </l-field>
      </div>
      <l-field class="w-1/2 pr-2" :label="$t('reservations.table')">
        <div
          v-if="!isReservationEmpty"
          class="w-full text-blue border border-gray-300 rounded-small flex items-center py-2 px-4 my-3 justify-between"
        >
          {{ printTables(internalReservation.tables) }}
          <icon
            name="external"
            @click="$emit('selectTable', internalReservation)"
            class="cursor-pointer text-red"
          />
        </div>
        <div
          v-else
          @click="$emit('selectTable', internalReservation)"
          class="w-full text-gray-400 border border-gray-300 rounded-small hover:border-gray-400 flex items-center py-2 px-4 my-3 justify-between"
        >
          {{ $t('reservations.no-table') }}
          <icon name="external" class="text-red" />
        </div>
      </l-field>
      <l-field class="pr-2" :label="$t('reservations.comments')">
        <l-input
          giant
          v-model="internalReservation.customerComments"
          :placeholder="$t('reservations.comments-placeholder')"
        />
      </l-field>
      <l-modal-ctas>
        <l-modal-button
          type="secondary"
          :label="$t('reservations.cancel')"
          @click.native="$emit('close')"
          class="mr-4 flex-1"
        />
        <l-modal-button
          type="main"
          :label="$t('reservations.accept')"
          :disabled="!isComplete"
          @click.native="save()"
          class="flex-1"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import LField from '@last/core-ui/components/LField'
import LInput from '@last/core-ui/components/LInput'
import LDateTimePicker from '@last/core-ui/components/LDateTimePicker'
import Icon from '@last/core-ui/components/Icon'
import uuid4 from 'uuid/v4'
import { mapActions, mapState } from 'vuex'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import LPhoneInput from '@last/core-ui/components/LPhoneInput.vue'

export default {
  name: 'ReservationDetails',
  components: {
    LModal,
    LModalCtas,
    LModalButton,
    LField,
    LInput,
    LDateTimePicker,
    Icon,
    LPhoneInput
  },
  props: {
    reservation: {
      type: Object,
      default: () => ({
        phoneNumber: ''
      })
    }
  },
  data() {
    return {
      internalReservation: {},
      selectingTable: false,
      validating: false
    }
  },
  mounted() {
    this.internalReservation = {
      ...this.reservation,
      phoneNumber:
        this.reservation.phoneNumber ||
        this.internalReservation.phoneNumber ||
        ''
    }
  },
  methods: {
    ...mapActions('reservations', ['createReservation', 'editReservation']),
    formatNumber(phoneNumber) {
      let parsedNumber = parsePhoneNumberFromString(phoneNumber)
      return parsedNumber ? parsedNumber.number : phoneNumber
    },
    save() {
      this.validating = true
      if (!this.validPhoneNumber) return
      let reservation = {
        ...this.internalReservation,
        phoneNumber: this.formatNumber(this.internalReservation.phoneNumber)
      }
      if (this.internalReservation.id) {
        this.editReservation(reservation)
      } else {
        reservation = {
          id: uuid4(),
          cancelled: false,
          ...reservation
        }
        this.createReservation(reservation)
      }
      this.$emit('close')
    },
    printTables(reservedTables) {
      return reservedTables.map(table => this.tables[table].name).toString()
    }
  },
  computed: {
    ...mapState('tables', ['tables']),
    ...mapState('config', ['config']),
    isComplete() {
      return (
        this.internalReservation.name &&
        this.internalReservation.diners &&
        this.internalReservation.dateTime
      )
    },
    validPhoneNumber() {
      let parsed = parsePhoneNumberFromString(
        this.internalReservation.phoneNumber || ''
      )
      return parsed?.isValid()
    },
    isReservationEmpty() {
      return !this.internalReservation.tables?.length
    }
  },
  watch: {
    reservation(reservation) {
      this.internalReservation = reservation
    }
  }
}
</script>
