<template>
  <div
    class="bg-white relative rounded-big flex flex-col justify-center items-center py-12 w-1/2"
  >
    <icon name="close" class="text-gray-400 absolute top-0 right-0 mt-4 mr-4" />
    <icon name="error" class="text-error mb-5 w-10 h-10" />
    <div class="text-blue uppercase font-title font-bold text-xl mb-3">
      {{ $t('tabs.cancel-shipment-question') }}
    </div>
    <div class="text-gray-400 text-center pb-5">
      {{
        courier
          ? $t('tabs.shipment-with-courier')
          : $t('tabs.shipment-without-courier')
      }}
    </div>
    <div
      class="bg-red px-12 py-3 text-white rounded-lg uppercase shadow cursor-pointer"
      :class="{ 'opacity-50': loading }"
      @click.stop="cancelDelivery"
    >
      {{ $t('tabs.cancel-shipment') }}
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import api from '@/api.js'

export default {
  name: 'CancelShipmentPopup',
  props: {
    tabId: {
      type: String,
      default: null
    },
    courier: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: null
    }
  },
  components: {
    Icon
  },
  methods: {
    async cancelDelivery() {
      if (this.loading) return
      this.loading = true
      try {
        await api.post(`/tabs/${this.tabId}/shipment/cancel`)
        this.$lnotification.create({
          title: this.$t('tabs.shipment-cancel-success')
        })
        this.$emit('close')
      } catch (err) {
        this.$lnotification.create({
          title: `${this.$t('tabs.shipment-cancel-failed')}: ${
            err.response.request.response
          }`,
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.loading = false
    }
  }
}
</script>

<style scoped></style>
