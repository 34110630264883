import Vue from 'vue'
import store from '@/store/index.js'
import i18n from '@/i18n.js'
import ManagerConfirmation from '@/components/core/ManagerConfirmation.vue'

function open(propsData) {
  const vm = typeof window !== 'undefined' && window.Vue ? window.Vue : Vue
  const ConfirmationComponent = vm.extend(ManagerConfirmation)
  let container = document.createElement('div')
  document.body.appendChild(container)
  return new ConfirmationComponent({
    el: container,
    store,
    i18n,
    propsData
  })
}

function confirm(privilege) {
  let component = open({ privilege })
  return new Promise((resolve, _) => {
    component.$on('confirmed', () => resolve(true))
    component.$on('cancel', () => resolve(false))
  })
}

const Plugin = {
  install(Vue) {
    Vue.component('ManagerConfirmation', ManagerConfirmation)
    Vue.prototype['$confirm'] = confirm
  }
}

export default Plugin
