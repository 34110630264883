import { notification } from '@last/core-ui/plugins/notification'
import i18n from '@/i18n.js'

class RedsysDataphone {
  constructor(config, platform) {
    this.initialized = false
    this.initializing = false
    this.config = config
    this.platform = platform
  }
  async init() {
    if (this.platform === 'web') {
      this.initialized = true
      return
    }
    const { ipcRenderer } = window.require('electron')
    this.ipcRenderer = ipcRenderer
    if (!this.initializing) {
      this.initializing = true
      let initResult = await this.ipcRenderer.invoke(
        'dataphone-init',
        this.config
      )
      if (initResult === 0) {
        this.initialized = true
      } else {
        notification.create({
          title: i18n.t('dataphone.bad-config-title'),
          subtitle: i18n.t('dataphone.bad-config-subtitle'),
          icon: 'close',
          iconColor: 'red'
        })
      }
    }
    this.initializing = false
  }

  async charge({ amount, paymentId }) {
    if (this.platform === 'web') return { chargeAuthorised: true }
    if (this.initialized) {
      let res = await this.ipcRenderer.invoke(
        'dataphone-charge',
        amount,
        paymentId
      )
      if (!res?.chargeAuthorised) {
        throw new Error('Dataphone charging failed')
      }
      return res?.metadata
    }
  }

  async cancel() {
    return
  }

  async refund(payment) {
    if (this.platform === 'web') return {}
    let res = await this.ipcRenderer.invoke('dataphone-refund', payment)
    return res
  }
}

export default RedsysDataphone
