<template>
  <l-modal
    :size="getSize"
    :title="$t('new-tab.new-tab-take-away')"
    @close="$emit('close')"
  >
    <template slot="body">
      <TabDetails
        v-if="!isClientTabs"
        :tab.sync="newTab"
        :customer.sync="customer"
        :phone-number.sync="customer.phoneNumber"
        type="takeAway"
        @nextStep="nextStep"
        @close="$emit('close')"
      />
      <ClientDetails
        :is-details-visible="false"
        @close="$emit('close')"
        @tabCreated="tabId => $emit('tabCreated', tabId)"
        @back="backStep"
        :customer="customer"
        :last-customer-tabs="lastTabs"
        :new-tab="newTab"
        v-else
      />
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal.vue'
import ClientDetails from './ClientDetails'
import TabDetails from './TabDetails'
import newTabMixin from '@/components/ordering/newTabMixin.js'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  name: 'NewTakeAwayTab',
  components: {
    LModal,
    ClientDetails,
    TabDetails
  },
  mixins: [newTabMixin],
  data() {
    return {
      newTab: {
        source: null,
        name: null,
        pickupType: 'takeAway'
      },
      customer: {},
      isClientTabs: false,
      lastTabs: []
    }
  },
  computed: {
    getSize() {
      return this.isClientTabs ? 'large' : 'small'
    }
  },
  methods: {
    nextStep(value) {
      let parsedPhoneNumber = parsePhoneNumberFromString(
        value.customer.phoneNumber || ''
      )
      if (parsedPhoneNumber?.isValid()) {
        this.isClientTabs = true
        this.customer = { ...this.customer, ...value.customer }
        this.lastTabs = value.tabs
      } else {
        this.createTakeAwayTab()
      }
    },
    backStep({ customer, tab }) {
      this.isClientTabs = false
      this.customer = customer
      this.newTab = tab
    }
  }
}
</script>
