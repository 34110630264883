import EventEmitter from 'events'

import api from '@/api.js'
import Logger from '@/logger.js'

// type State = "idle" | "checking" | "downloading" | "ready" | "updating"

// abstract class Updater
// Javascript doesn't support abstract classes, so this is simulated throwing "Not Implemented" for abstract methods.
export class Updater extends EventEmitter {
  constructor() {
    super()
    this.state = 'idle' // State
    this.nextVersion = ''
  }

  // type AppInfo = { device: DeviceInfo, channel: string }
  // (AppInfo) => Promise<void>
  async checkUpdates({ device, channel }) {
    if (['checking', 'downloading'].includes(this.state)) {
      Logger.info(
        'Called checkUpdates. No op because it is in state:',
        this.state
      )
      return
    }

    this.state = 'checking'

    try {
      const platform = await this._getPlatform()
      const response = await api.post('/pos/versions/updates', {
        ...device,
        platform,
        channel
      })

      if (!response.data.version) {
        this.state = 'idle'
        return
      }

      if (response.data.version === this.nextVersion) {
        this._updateReady(response.data.version, !!response.data.deprecated)
        return
      }

      this.state = 'downloading'
      await this._downloadUpdate(response.data)
      this._updateReady(response.data.version, !!response.data.deprecated)
    } catch (error) {
      this.state = 'idle'
    }
  }

  _updateReady(nextVersion, deprecated) {
    this.state = 'ready'
    this.nextVersion = nextVersion
    this.emit('updateReady', { deprecated })
  }

  // To call when the app is started to notify that everything is working ok
  // Not abstract because it is only really needed in capgo
  async notifyAppReady() {}

  // type Version = { name: string, build: string}
  // () => Version
  async getVersion() {
    throw new Error('Updater.getVersion(): Not Implemented')
  }

  // () => Promise<string>
  async _getPlatform() {
    throw new Error('Updater._getPlatform(): Not Implemented')
  }

  // type DownloadInfo = { version: string, releaseDate?: string, url: string, size?: number, checksum: string, deprecated?: boolean }
  // (DownloadInfo) => Promise<void>
  async _downloadUpdate(_info) {
    throw new Error('Updater._downloadUpdate(): Not Implemented')
  }

  async installUpdate() {
    throw new Error('Updater.installUpdate(): Not Implemented')
  }
}
