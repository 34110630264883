<template>
  <div
    class="h-screen flex flex-col justify-center items-center bg-cover bg-image overflow-y-scroll scrolling-touch relative w-full overflow-hidden"
  >
    <div
      v-if="selectedCashTill"
      class="flex flex-col items-center absolute transition-position"
      :class="{
        'position-center': !moveModals,
        'slide-left-from-center': moveModals,
        hidden: cashTillOpened
      }"
    >
      <div class="pb-4 font-title text-2xl text-white uppercase text-center">
        1/2 {{ $t('start-shift.title') }}
        {{ selectedCashTill.name }}
      </div>
      <cash-amount
        :done-label="$t('start-shift.start')"
        :show-cancel="true"
        :last-shift-end-amount="lastShiftCashTillAmount"
        :use-cash-machine="useCashMachine"
        @cancel="cancel"
        @done="amount => startShiftAndContinue(amount, selectedCashTill.id)"
      />
      <div
        class="text-white underline text-center pt-6"
        @click="openCashDrawer"
      >
        {{ $t('start-shift.open-till') }}
      </div>
    </div>

    <div
      v-if="!employeeShiftStarted"
      class="flex flex-col items-center absolute"
      :class="{
        'slide-left-to-center': moveModals,
        'position-hidden-right': !moveModals && !cashTillOpened
      }"
    >
      <div class="pb-4 font-title text-2xl text-white uppercase text-center">
        2/2 {{ $t('start-shift.title') }} {{ currentEmployee.name }}
      </div>
      <cash-amount
        :done-label="$t('start-shift.start')"
        :show-cancel="true"
        :last-shift-end-amount="lastShiftEmployeeTillAmount"
        @cancel="cancel"
        @done="amount => startShiftAndContinue(amount, currentEmployee.tillId)"
      />
    </div>
  </div>
</template>

<script>
import CashAmount from '@/components/CashAmount.vue'
import Printer from '@/printer.js'
import api from '@/api.js'
import { mapState, mapGetters, mapActions } from 'vuex'
import CashMachine from '@/integrations/cashmachine/cashmachine.js'

export default {
  name: 'StartShift',
  data() {
    return {
      moveModals: false,
      cashTillOpened: false,
      lastShiftCashTill: null,
      lastShiftEmployeeTill: null
    }
  },
  computed: {
    ...mapState('till', ['shift', 'selectedCashTill']),
    ...mapGetters('till', ['selectedCashTillIsStarted', 'tillIsStarted']),
    ...mapGetters('auth', ['currentEmployee']),
    useCashMachine() {
      return CashMachine.methods.includes(this.selectedCashTill.type)
    },
    employeeShiftStarted() {
      return (
        !this.currentEmployee.tillEnabled ||
        this.tillIsStarted(this.currentEmployee.tillId)
      )
    },
    allShiftsStarted() {
      return (
        this.employeeShiftStarted &&
        (!this.selectedCashTill || this.selectedCashTillIsStarted)
      )
    },
    lastShiftCashTillAmount() {
      if (!this.lastShiftCashTill || !this.lastShiftCashTill.amounts) {
        return null
      } else {
        let cashAmount = this.lastShiftCashTill.amounts.find(
          amount => amount.method === 'cash'
        )
        if (cashAmount) {
          return cashAmount.end
        }
      }
      return null
    },
    lastShiftEmployeeTillAmount() {
      if (!this.lastShiftEmployeeTill || !this.lastShiftEmployeeTill.amounts) {
        return null
      } else {
        let cashAmount = this.lastShiftEmployeeTill.amounts.find(
          amount => amount.method === 'cash'
        )
        if (cashAmount) {
          return cashAmount.end
        }
      }
      return null
    }
  },
  async mounted() {
    if (this.allShiftsStarted) {
      this.$router.push({ name: 'pos' })
    }
    let response
    if (this.selectedCashTill && this.selectedCashTill.id) {
      response = await api.get('/last-shift', {
        params: {
          tillId: this.selectedCashTill.id
        }
      })
      this.lastShiftCashTill = response.data
    }
    if (this.currentEmployee.tillEnabled && this.currentEmployee.tillId) {
      response = await api.get('/last-shift', {
        params: {
          tillId: this.currentEmployee.tillId
        }
      })
      this.lastShiftEmployeeTill = response.data
    }
  },
  beforeMount() {
    if (!this.selectedCashTill || this.selectedCashTillIsStarted) {
      this.cashTillOpened = true
    }
  },
  methods: {
    ...mapActions('till', ['startShift']),
    startShiftAndContinue(amount, tillId) {
      let method = CashMachine.method || 'cash'
      this.startShift({ amount, tillId, method })
      this.moveModals = true
    },
    cancel() {
      this.$router.push({ name: 'employees' })
    },
    openCashDrawer() {
      Printer.openCashDrawer()
    }
  },
  watch: {
    allShiftsStarted(started) {
      if (started) {
        this.$router.push({ name: 'pos' })
      }
    }
  },
  components: {
    CashAmount
  }
}
</script>

<style scoped>
.bg-image {
  background-image: url('../assets/background-employees.jpg');
}

.transition-position {
  transition: left 2s ease-in-out;
}

.position-center {
  left: inherit;
}

.position-hidden-right {
  right: -53.125rem;
  opacity: 0;
}

.slide-left-from-center {
  animation: slide-left-from-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

@keyframes slide-left-from-center {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-53.125rem);
    opacity: 0;
  }
}

.slide-left-to-center {
  animation: slide-left-to-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-left-to-center {
  0% {
    transform: translateX(53.125rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
