<template>
  <div class="flex flex-1 overflow-hidden">
    <tab-list
      :tabs="tabsInfo"
      :full-screen="fullScreen"
      :selected-tab-id.sync="selectedTabId"
      :tabs-type="tabsType"
      @fullScreen="fullScreen = true"
      @squareMenu="fullScreen = false"
      @tabCreated="tabId => (selectedTabId = tabId)"
      @closeTab="tabId => closeTab(tabId)"
    />
    <div v-if="!fullScreen" class="flex-1 h-full px-6 pt-6 bg-gray-100">
      <div class="flex flex-col h-full overflow-hidden" v-if="selectedTabId">
        <div class="flex items-center">
          <div
            v-if="hasAlert(selectedTab)"
            @click="showAlert"
            class="px-4 py-1 m-4 text-sm text-white uppercase cursor-pointer bg-yellow rounded-small"
          >
            {{ $t('tabs.alert') }}
          </div>
          <div v-else class="flex items-center justify-center px-4 pl-2">
            <div
              class="w-6 h-6 rounded-full status"
              :class="{
                'pending-kitchen-orders': pendingKitchenOrders(selectedTabId),
                'closed opacity-75': !selectedTab.open,
                cancelled:
                  selectedTab.deliveryOrder &&
                  selectedTab.deliveryOrder.cancelTime,
                closed: !selectedTab.open
              }"
            />
          </div>
          <div class="flex items-center flex-1">
            <div class="flex text-2xl font-bold uppercase text-blue">
              <div v-if="selectedTab.code">
                {{ selectedTab.code }}
              </div>
              <div
                class="px-2"
                v-if="
                  selectedTab.code &&
                  (selectedTab.tableName || selectedTab.name)
                "
              >
                -
              </div>
              <div v-if="selectedTab.tableName">
                {{ selectedTab.tableName }}
              </div>
              <div v-else>{{ selectedTab.name }}</div>
            </div>
          </div>
          <div
            class="p-2 rounded-full bg-red"
            @click="() => closeTab()"
            v-if="selectedTab.open"
          >
            <icon name="trash" class="text-white" />
          </div>
        </div>
        <div class="mb-3 mx-3 ml-12 text-gray-400 flex flex-row">
          <div class="mr-1">{{ selectedTab.source }} -</div>
          <div class="text-gray-400 relative mr-5">
            {{ selectedTab.creationTime | time }}
          </div>
          <delivery-record-popup
            v-if="selectedTab.pickupType === 'ownDelivery'"
            :tab="selectedTab"
          />
        </div>
        <div class="flex justify-between h-full overflow-hidden">
          <div class="flex flex-col w-3/5 h-full">
            <div class="flex-1 p-2 overflow-y-scroll scrolling-touch">
              <preview-tab
                class="preview-tab"
                :tab-id="selectedTabId"
                @edit="editTab"
              />
            </div>
            <tab-actions :tab-id="selectedTabId" class="pb-6" />
          </div>
          <div class="w-2/5 h-full overflow-y-scroll scrolling-touch">
            <tab-info :tab="selectedTab" @mergeTable="mergingTab = true" />
          </div>
        </div>
        <merge-tabs
          v-if="mergingTab"
          :tabs="tabsToMerge"
          :tab-id="selectedTabId"
          @close="mergingTab = false"
        />
      </div>
    </div>
    <close-tab :tab-id="closeTabId" @cancel="closeTabId = null" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import MergeTabs from '@/components/tabs/MergeTabs.vue'
import PreviewTab from '@/components/tabs/PreviewTab.vue'
import CloseTab from '@/components/checkout/CloseTab.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import TabList from '@/components/tabs/TabList.vue'
import TabActions from '@/components/tabs/TabActions.vue'
import TabInfo from '@/components/tabs/TabInfo.vue'
import DeliveryRecordPopup from '@/components/tabs/DeliveryRecordPopup.vue'

export default {
  name: 'TabBrowser',
  props: {
    tabsType: {
      type: String,
      default: 'all'
    },
    preSelectedTabId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      configuringTab: false,
      mergingTab: false,
      selectedTabId: null,
      closeTabId: null,
      fullScreen: false,
      editOrderInfo: false,
      showDeliveryRecord: false
    }
  },
  mounted() {
    if (this.preSelectedTabId) {
      this.selectedTabId = this.preSelectedTabId
    }
  },
  computed: {
    ...mapState('tabs', ['tabs', 'kitchenOrders']),
    ...mapState('tables', ['tables']),
    ...mapState('config', ['config']),
    ...mapGetters('tabs', ['getTotalsByTabId', 'getUnsentProductsByTabId']),
    totals() {
      return this.getTotalsByTabId
    },
    tabsInfo() {
      let unsentProductsByTab = this.getUnsentProductsByTabId
      const totals = this.getTotalsByTabId
      return Object.values(this.tabs)
        .filter(tab => {
          if (this.tabsType === 'delivery') {
            return (
              ['delivery', 'ownDelivery'].includes(tab.pickupType) ||
              (tab.toGo && tab.source !== 'Restaurant')
            )
          }
          if (this.tabsType === 'takeAway') {
            return tab.pickupType === 'takeAway'
          }
          return true
        })
        .map(tab => {
          return {
            ...tab,
            total: totals[tab.id],
            pendingKitchenOrders: unsentProductsByTab[tab.id]?.length > 0
          }
        })
    },
    selectedTab() {
      return {
        ...this.tabs[this.selectedTabId],
        total: this.totals[this.selectedTabId]
      }
    },
    tabsToMerge() {
      return Object.values(this.tabs).filter(
        tab =>
          tab.open &&
          tab.id !== this.selectedTabId &&
          tab.source === 'Restaurant' &&
          tab.activationTime
      )
    }
  },
  methods: {
    ...mapActions('tabs', ['createKitchenOrders', 'resolveShipmentError']),
    editTab() {
      if (this.selectedTab.open) {
        this.$router.push({
          name: 'orderManagement',
          params: { tabId: this.selectedTabId }
        })
      } else {
        this.checkout()
      }
    },
    checkout() {
      this.$router.push({
        name: 'checkout',
        params: { tabId: this.selectedTabId }
      })
    },
    closeTab(tabId) {
      this.closeTabId = tabId || this.selectedTabId
    },
    pendingKitchenOrders(tabId) {
      return this.getUnsentProductsByTabId[tabId]?.length > 0
    },
    hasAlert(tab) {
      return (
        tab.deliveryOrder &&
        tab.deliveryOrder.errorTime &&
        !tab.deliveryOrder.errorResolved
      )
    },
    showAlert() {
      let message =
        this.$t('tabs.shipment-error-message') +
        ':\n' +
        this.selectedTab.deliveryOrder?.errorMessage
      this.$ldialog({
        title: this.$t('tabs.shipment-error-title'),
        content: message
      })
    }
  },
  components: {
    MergeTabs,
    PreviewTab,
    CloseTab,
    Icon,
    TabList,
    TabActions,
    TabInfo,
    DeliveryRecordPopup
  },
  watch: {
    preSelectedTabId(value) {
      this.selectedTabId = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.status {
  background: #4299e1;
  &.pending-kitchen-orders {
    background: $light-red;
  }
  &.cancelled {
    @apply bg-error;
  }
  &.closed {
    background: #a0a0c0;
  }
}

.w-list {
  width: 19.375rem;
}

.big-button {
  @apply flex;
  @apply justify-center;
  @apply items-center;
  @apply text-white;
  @apply uppercase;
  @apply p-4;
  @apply rounded-small;
  @apply font-bold;
  @apply flex-1;
}
</style>
