<template>
  <div class="home-container">
    <top-bar>
      <template slot="center">
        <img src="../assets/logo-light.svg" class="logo-height" />
      </template>
      <template slot="right" v-if="deliveriesClosed">
        <open-delivery @close="deliveriesClosed = false" />
      </template>
    </top-bar>
    <nav-bar
      :floorplans="floorplanList"
      :tab-count="tabCount"
      :selected-tab="selectedTab"
      @update:selectedTab="value => updateSelectedTab(value)"
    />
    <table-tabs v-if="showingFloorplan" :floorplan-id.sync="selectedTab" />
    <tab-browser
      v-else
      :tabs-type="selectedTab"
      :pre-selected-tab-id="tabBrowserSelectedTabId"
    />
  </div>
</template>

<script>
import TableTabs from '@/components/tables/TableTabs.vue'
import TabBrowser from '@/components/tabs/TabBrowser.vue'
import TopBar from '@/components/core/TopBar.vue'
import NavBar from '@/components/core/NavBar.vue'
import idle from '@/idle.js'
import homeMixin from '@/components/homeMixin.js'
import OpenDelivery from './OpenDelivery'

export default {
  name: 'Home',
  mixins: [idle, homeMixin],
  components: {
    OpenDelivery,
    TableTabs,
    TabBrowser,
    TopBar,
    NavBar
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.button {
  margin-left: 20px;
}
.logo-height {
  height: 2rem;
}
</style>
