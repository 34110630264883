import api from '@/api.js'
import { normalizeCatalogs } from '@/normalizr'
import imageCache from '@/imageCache.js'
import localDb from '@/localDb.js'
import { Capacitor } from '@capacitor/core'

const state = {
  catalogs: {},
  categories: {},
  defaultPosCatalog: null,
  products: {},
  locationBrandCatalogsBySource: {}
}

const getters = {
  productsById: state => {
    let products = Object.values(state.categories).flatMap(category =>
      category.products.map(p => ({ categoryId: category.id, ...p }))
    )
    let newComboProducts = products
      .filter(product => product.type === 'COMBO')
      .flatMap(combo => combo.categories)
      .flatMap(category => category.products)
    return [...products, ...newComboProducts].reduce((res, product) => {
      res[product.id] = product
      return res
    }, {})
  },
  getProductById: (_state, getters) => id => {
    return getters.productsById[id]
  },
  getCatalogIdByTabVirtualBrandId:
    state => (tabVirtualBrandId, pickupType, source) => {
      if (!tabVirtualBrandId) return state.defaultPosCatalog

      let typeOfCatalog
      if (pickupType === 'delivery' || pickupType === 'ownDelivery')
        typeOfCatalog = 'deliveryCatalogId'
      else if (pickupType === 'takeAway') typeOfCatalog = 'takeawayCatalogId'

      let catalogId
      if (typeOfCatalog) {
        const locationVirtualBrandAssignments =
          state.locationBrandCatalogsBySource[tabVirtualBrandId]
        source = source.toLowerCase()

        catalogId =
          locationVirtualBrandAssignments[source]?.[typeOfCatalog] ??
          locationVirtualBrandAssignments['default']?.[typeOfCatalog]
      }

      return catalogId || state.defaultPosCatalog
    },
  allCourses: state => {
    return Object.values(state.catalogs)[0].courses.map(course => ({
      label: course,
      value: course
    }))
  }
}

const actions = {
  async refreshCatalogs({ state, commit, rootGetters, rootState }, catalogs) {
    if (!rootGetters['auth/isAuthenticated']) return
    if (!catalogs) {
      catalogs = (await api.get('/catalogs')).data
      if (!catalogs) return
    }
    const normalizedCatalogs = normalizeCatalogs(catalogs.catalogs)
    commit('updateCatalogs', {
      ...catalogs,
      catalogs: normalizedCatalogs.entities.catalogs,
      categories: normalizedCatalogs.entities.categories
    })
    let device = rootState.config.device
    if (rootState.config.config.showProductImages && device.platform) {
      let images = Object.values(state.categories)
        .flatMap(category => category.products)
        .map(product => ({
          type: 'product',
          id: product.id,
          imageId: product.imageId,
          size: {
            width: 120,
            height: 120
          }
        }))
      imageCache.cacheCloudinaryList(images)
    }

    const products = Object.values(normalizedCatalogs.entities.categories)
      .flatMap(category =>
        category.products.map(p => ({ ...p, categoryId: category.id }))
      )
      .reduce((acc, p) => ({ ...acc, [p.id]: p }), {})

    commit('updateProducts', products)
  },
  async refreshCatalog({ state, commit, rootGetters, rootState }, catalog) {
    if (!rootGetters['auth/isAuthenticated']) return
    let normalizedCatalog = normalizeCatalogs([catalog])
    commit('patchCatalog', {
      catalogs: normalizedCatalog.entities.catalogs,
      categories: normalizedCatalog.entities.categories
    })
    let device = rootState.config.device
    if (rootState.config.config.showProductImages && device.platform) {
      let images = Object.values(state.categories)
        .flatMap(category => category.products)
        .map(product => ({
          type: 'product',
          id: product.id,
          imageId: product.imageId,
          size: {
            width: 120,
            height: 120
          }
        }))
      imageCache.cacheCloudinaryList(images)
    }

    const products = Object.values(normalizedCatalog.entities?.categories || {})
      .flatMap(category =>
        category.products.map(p => ({ ...p, categoryId: category.id }))
      )
      .reduce((acc, p) => ({ ...acc, [p.id]: p }), {})

    commit('updateProducts', products)
  }
}

const mutations = {
  updateCatalogs(
    state,
    { catalogs, categories, defaultPosCatalog, locationBrandCatalogsBySource }
  ) {
    state.catalogs = catalogs || {}
    state.categories = categories || {}
    state.defaultPosCatalog = defaultPosCatalog
    state.locationBrandCatalogsBySource = locationBrandCatalogsBySource
    let platform = Capacitor.getPlatform()
    if (platform !== 'web') {
      localDb.updateTables({
        'catalog:catalogs': state.catalogs,
        'catalog:categories': state.categories,
        'catalog:defaultPosCatalog': state.defaultPosCatalog,
        'catalog:locationBrandCatalogsBySource':
          state.locationBrandCatalogsBySource
      })
    }
  },
  patchCatalog(state, { catalogs, categories }) {
    state.catalogs = { ...state.catalogs, ...catalogs }
    state.categories = { ...state.categories, ...categories }
    let platform = Capacitor.getPlatform()
    if (platform !== 'web') {
      localDb.updateTables({
        'catalog:catalogs': state.catalogs,
        'catalog:categories': state.categories
      })
    }
  },
  updateProducts(state, products) {
    state.products = products
    let platform = Capacitor.getPlatform()
    if (platform !== 'web') {
      localDb.updateTables({
        'catalog:products': state.products
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
