import EscPos from '@/printers/escpos.js'
import ZPL from '@/printers/zpl.js'
import UsbPrinter from '@/printers/usb.js'
import StarPrint from '@/printers/starPrint.js'
import AdyenPrinter from '@/printers/adyenPrinter.js'
import Browser from '@/printers/browser.js'
import KDS from '@/printers/kds.js'
import sync from '@/sync/service.js'
import Logger from '@/logger.js'
import moment from 'moment'

const MAX_RETRIES = 120

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function getPrinter(printer) {
  let config = {
    name: printer.name,
    address: printer.ip,
    type: printer.type,
    metadata: printer.metadata,
    ticketWidth: printer.ticketWidth,
    ticketHeight: printer.ticketHeight,
    ticketPaddingLeft: printer.ticketPaddingLeft
  }

  switch (printer.type) {
    case 'StarGraphic':
      return new StarPrint(config)
    case 'StarPRNT':
      return new StarPrint(config)
    case 'EscPos':
      return new EscPos(config)
    case 'AdyenPrinter':
      return new AdyenPrinter(config)
    case 'EscPosMatrix':
      return new EscPos({ ...config, matrixMode: true })
    case 'ZPL':
      return new ZPL(config)
    case 'Usb':
      return new UsbPrinter(config)
    case 'KDS':
      return new KDS(config)
    default:
      return new Browser(config)
  }
}

async function print(queue) {
  if (queue.printing) return
  queue.printing = true
  while (queue.jobs.length > 0) {
    let job = queue.jobs[0]
    let jobDone = false
    try {
      if (
        job.time &&
        moment(job.time).isBefore(moment().subtract(10, 'minute'))
      ) {
        job.retries = MAX_RETRIES
        throw 'Job is too old'
      }
      if (job.type === 'printImage') {
        await queue.printer.printImage(job.image)
      } else if (job.type === 'openCashDrawer') {
        await queue.printer.openCashDrawer()
      }
      jobDone = true
    } catch (error) {
      Logger.info('Printer queue error', error)
      await sleep(5000)
      if (
        (!job.retries || job.retries < MAX_RETRIES) &&
        job.type !== 'openCashDrawer'
      ) {
        queue.jobs.push({ ...job, retries: (job.retries || 0) + 1 })
      }
    }
    if (job.printedEvent && jobDone) {
      try {
        sync.record(job.printedEvent.name, {
          ...job.printedEvent.data,
          printedTime: new Date()
        })
      } catch (error) {
        Logger.error(error)
      }
    }
    queue.jobs.shift()
  }
  queue.printing = false
}

class PrinterQueue {
  constructor(printer) {
    this.printerId = printer.id
    this.printing = false
    this.printer = getPrinter(printer)
    this.jobs = []
  }

  addJob(job) {
    this.jobs.push(job)
    print(this)
  }
}

export default PrinterQueue
