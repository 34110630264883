import api from '@/api.js'
import store from '@/store/index'
import { Preferences } from '@capacitor/preferences'
import * as eventList from './eventList.js'
import Logger from '@/logger.js'
import lastUtils from '@last/core/src/lastUtils.js'
import uuid4 from 'uuid/v4'

let events = []
let sendingEvents = false
let resyncing = false
let playedEventIds = []
let pendingRemote = []
let currentEventId = 0
let syncErrors = 0
let observers = []
let pendingResync = false

async function storeEvents() {
  if (store.state.config.demoMode) return
  return Preferences.set({ key: 'events', value: JSON.stringify(events) })
}

function appendPlayedEventId(id) {
  playedEventIds.push(id)
}

async function loadPendingEvents() {
  let data = await Preferences.get({ key: 'events' })
  if (data.value) {
    events = JSON.parse(data.value)
  }
}

async function sendEvents() {
  if (sendingEvents) return
  sendingEvents = true
  while (events.length > 0) {
    let event = events[0]
    try {
      let response = await api.post('/events', event)
      appendPlayedEventId(response.data.id)
      events.shift()
      await storeEvents()
    } catch (error) {
      if (!error) break
      if (error.request || error.response) {
        Logger.info('Connection error while sending events', error)
      } else {
        Logger.error(error)
      }
      await lastUtils.sleep(5000)
    }
  }
  sendingEvents = false
  pendingRemote.forEach(playRemote)
  pendingRemote = []
  observers.forEach(callback => callback())
  observers = []
  if (pendingResync) {
    resync()
  }
}

function hasEvent(eventId) {
  return playedEventIds.includes(eventId)
}

async function resync() {
  Logger.info('resync')
  /*eslint-disable */
  if (!store.getters['auth/isAuthenticated'] || !store.state.auth.locationId)
    return
  if (sendingEvents) {
    pendingResync = true
    return
  }
  if (resyncing) return
  resyncing = true
  try {
    const { data } = await api.get('/sync', {
      params: {
        ...(currentEventId > 0 && syncErrors < 3 && { currentEventId })
      }
    })
    const events = data.events
    const tabs = data.tabs
    const virtualBrandsClosingTimes = data.virtualBrandsClosingTimes

    resyncing = false
    if (events) {
      events.forEach(playRemote)
    } else if (tabs) {
      const reservations = data.reservations
      const startedShifts = data.startedShifts
      const startedShiftsWithCashAmount = data.startedShiftsWithCashAmount
      const shiftsEnabled = data.shiftsEnabled
      const tabPromotions = data.tabPromotions

      store.dispatch('tabs/refreshCurrentTabs', tabs)
      store.dispatch('reservations/refreshReservations', reservations)
      store.dispatch('till/refreshStartedShifts', startedShifts)
      store.dispatch(
        'till/refreshStartedShiftsWithCashAmount',
        startedShiftsWithCashAmount
      )
      store.dispatch('till/refreshShiftsEnabled', shiftsEnabled)
      store.dispatch('promotions/refreshTabPromotions', tabPromotions)

      currentEventId = data.currentEventId
    }
    if (virtualBrandsClosingTimes) {
      store.dispatch(
        'deliveryCompanies/refreshClosingTimes',
        virtualBrandsClosingTimes
      )
    }
    pendingRemote.forEach(playRemote)
    pendingRemote = []
    pendingResync = false
    syncErrors = 0
  } catch (error) {
    if (error.name !== 'NetworkError') syncErrors += 1
    resyncing = false
    if (error.request || error.response) {
      Logger.info('Connection error on sync', error)
    } else {
      Logger.error(error)
    }
    setTimeout(resync, 5000)
  }
  /*eslint-enable */
}

function deepClone(data) {
  return JSON.parse(JSON.stringify(data))
}

function play(event, local) {
  var data = deepClone(event.data)
  if (event.name in eventList) {
    eventList[event.name](data, local)
  } else {
    Logger.info("Can't find event " + event.name)
  }
}

function playRemote(event) {
  Logger.info('playRemote', event)
  if (event.id <= currentEventId) return
  if (sendingEvents || resyncing) {
    pendingRemote.push(event)
    return
  }
  if (event.id > currentEventId + 1) {
    resync()
    return
  }
  if (!hasEvent(event.id)) {
    play(event, false)
  }
  playedEventIds = playedEventIds.filter(id => id > event.id)
  currentEventId = event.id
}

loadPendingEvents().then(() => sendEvents())

export default {
  record: function (eventName, data) {
    let event = {
      uuid: uuid4(),
      deviceId: store.state.config.device.id,
      name: eventName,
      timestamp: Date.now(),
      employeeId: store.state.auth.currentEmployeeId,
      data
    }
    play(event, true)
    Logger.info('recordEvent', event)
    events.push(event)
    storeEvents().then(sendEvents)
  },
  isSendingEvents: () => sendingEvents,
  hasEvent,
  isNew: eventId => eventId > (playedEventIds.slice(-1)[0] || 0),
  playRemote,
  resync,
  isInitialized: () => currentEventId > 0,
  observeEnd: callback => {
    if (events.length > 0) {
      observers.push(callback)
    } else {
      callback()
    }
  }
}
