import { render, staticRenderFns } from "./MoveProductsConfirmationModal.vue?vue&type=template&id=4c95babb&scoped=true&"
import script from "./MoveProductsConfirmationModal.vue?vue&type=script&lang=js&"
export * from "./MoveProductsConfirmationModal.vue?vue&type=script&lang=js&"
import style0 from "./MoveProductsConfirmationModal.vue?vue&type=style&index=0&id=4c95babb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c95babb",
  null
  
)

export default component.exports