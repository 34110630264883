<template>
  <div class="shadow">
    <div
      class="bg-gray-100 flex pt-2 px-4 text-blue justify-between items-center"
    >
      <div class="uppercase">{{ $t('checkout.total') }}</div>
      <div class="font-bold total text-xl">
        {{ total | currency }}
      </div>
    </div>
    <div
      class="bg-gray-100 flex pb-2 px-4 text-blue justify-between items-center text-sm"
    >
      <div>{{ $t('ordering.pending-to-pay') }}</div>
      <div>
        {{ totalPending | currency }}
      </div>
    </div>
  </div>
</template>

<script>
import Bills from '@last/core/src/billsGenerator'
import { mapState } from 'vuex'
export default {
  name: 'Balance',
  props: {
    bills: {
      type: Array,
      default: null
    },
    terraceSurchargePercentage: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('config', ['config']),
    total() {
      return this.totalPaid + this.totalPending
    },
    totalPaid() {
      return this.bills
        .map(bill => Math.min(bill.paid, bill.total))
        .reduce((total, paid) => total + paid, 0)
    },
    totalPending() {
      let billsPending = this.bills
        .map(bill => bill.pending)
        .reduce((total, pending) => total + pending, 0)
      return (
        billsPending +
        Bills.generateProductsBill({
          products: [],
          company: {},
          tab: { terraceSurchargePercentage: this.terraceSurchargePercentage },
          taxRate: this.config.taxRate,
          ticketInfo: this.config.ticketInfo
        }).total
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
.paid-pending {
  display: flex;
  border-bottom: 1px solid $separator;
}
.paid-container {
  padding: 10px;
  flex: 1;
  border-right: 1px solid $separator;
}
.pending-container {
  flex: 1;
}
.total-container {
  padding: 14px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
</style>
