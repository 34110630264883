<template>
  <swipe-out class="w-full active:bg-gray-300 product-min-height items-center">
    <template v-slot>
      <div class="flex flex-row items-center justify-center">
        <div @click="editingQuantity = true">
          <quantity-selector
            class="py-3 pl-2 pr-4"
            v-click-outside="() => (editingQuantity = false)"
            v-if="editingQuantity && !disabledQuantitySelector"
            :quantity="product.quantity"
            @update:quantity="updateQuantity"
            :is-maxed="quantityIsMaxed"
            :min="0"
          />
          <div v-else class="pr-3 text-red product-quantity">
            {{ product.quantity }}
          </div>
        </div>
        <div class="flex flex-row w-full" @click="$emit('customClick')">
          <div class="w-full">
            <div class="flex justify-between">
              <div class="text-blue product-name">
                {{ product.name }}
              </div>
              <div class="flex text-blue" v-if="product.priceImpact">
                {{ product.priceImpact | currency }}
              </div>
            </div>
            <div class="text-gray-400 modifiers text-sm">
              {{ description }}
            </div>
            <div class="text-gray-400 comments" v-if="product.comments">
              + {{ product.comments }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="right">
      <div
        class="flex items-center w-full h-full px-6 swiper-slide bg-red"
        @click="deleteProduct"
      >
        <icon name="trash" class="text-white" />
      </div>
    </template>
  </swipe-out>
</template>

<script>
import { SwipeOut } from 'vue-swipe-actions'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'
import Icon from '@last/core-ui/components/Icon.vue'
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import { mapActions } from 'vuex'

export default {
  name: 'ComboProduct',
  props: {
    product: {
      type: Object,
      default: null
    },
    disabledQuantitySelector: {
      type: Boolean,
      default: false
    },
    quantityIsMaxed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editingQuantity: false
    }
  },
  methods: {
    ...mapActions('tabs', ['updateProductQuantity', 'removeProduct']),
    updateQuantity(quantity) {
      this.$emit('updateQuantity', quantity)
    },
    deleteProduct() {
      this.$emit('delete')
    }
  },
  computed: {
    description() {
      return (this.product.comboProducts || this.product.modifiers || [])
        .map(modifier => {
          let quantity = modifier.quantity > 1 ? ` x ${modifier.quantity}` : ''
          return modifier.name + quantity
        })
        .join(', ')
    }
  },
  components: {
    SwipeOut,
    Icon,
    QuantitySelector
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.left-100 {
  left: 100%;
}

.sortable-chosen:active {
  background: white;
  box-shadow: 2px 4px 6px -1px rgba(0, 0, 0, 0.1),
    2px 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.text-labeled {
  font-size: 12px;
  text-decoration: line-through;
}

.product-min-height {
  min-height: 30px;
}
</style>
