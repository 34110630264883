import Vue from 'vue'

Vue.directive('horizontal-scroll', {
  bind(el) {
    el.addEventListener('wheel', function(e) {
      if (e.deltaY > 0) el.scrollLeft += 100
      else el.scrollLeft -= 100
    })
  }
})
