var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-screen flex flex-col overflow-hidden"},[_c('top-bar',{attrs:{"show-back":""}},[_c('template',{slot:"center"},[_c('img',{staticClass:"logo-height",attrs:{"src":require("@/assets/logo-light.svg")}})])],2),_c('div',{staticClass:"bg-white w-full h-full justify-center"},[_c('div',{staticClass:"w-full bg-gray-400 height-3-5 flex flex-row justify-center"},[_c('div',{staticClass:"w-1/2 self-center"},[_c('l-input',{attrs:{"val":"","icon":"search","placeholder":_vm.$t('tabs.search-placeholder'),"small":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)]),_c('div',{staticClass:"py-12 width-45 m-auto height-500 overflow-y-scroll scrolling-touch"},[_vm._l((_vm.filteredTabs),function(tab){return _c('div',{key:tab.id,staticClass:"flex flex-row text-blue justify-between items-center py-3 leading-tight border-b-1 border-gray-200",on:{"click":function($event){return _vm.goToTabDetails(tab.id)}}},[_c('div',{staticClass:"flex flex-row items-center"},[_c('div',{staticClass:"rounded-full status w-6 h-6",class:{
              cancelled: _vm.isCancelled(tab),
              alert: _vm.hasAlert(tab),
              'bg-light-blue': tab.open,
              closed: !tab.open,
              'billing-started': tab.billingStartedTime,
              scheduled: !tab.activationTime
            }}),_c('div',{staticClass:"ml-5"},[_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"font-bold"},[_vm._v(_vm._s(tab.code))]),_c('div',{staticClass:"mx-1"},[_vm._v("/")]),_c('div',[_vm._v(" "+_vm._s(tab.name)+" ")])]),_c('div',{staticClass:"text-gray-400"},[_vm._v(" "+_vm._s(tab.source)+" ")])])]),_c('div',{staticClass:"text-red"},[_vm._v(" "+_vm._s(_vm._f("time")(tab.activationTime || tab.schedulingTime))+" ")])])}),_c('div',{staticClass:"transparent-gradient bottom-0 w-1/2 h-20 absolute pointer-events-none"})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }