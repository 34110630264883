import Home from '@/components/Home.vue'
import OrderManagement from '@/components/ordering/OrderManagement.vue'
import Checkout from '@/components/checkout/Checkout.vue'
import Login from '@/components/Login.vue'
import ExtraDeviceWarning from '@/components/ExtraDeviceWarning.vue'
import SupportLogin from '@/components/SupportLogin.vue'
import SignUp from '@/components/SignUp.vue'
import EmployeeSelector from '@/components/EmployeeSelector.vue'
import PaymentRequest from '@/components/PaymentRequest.vue'
import StartShift from '@/components/StartShift.vue'
import EndShift from '@/components/EndShift.vue'
import PayInPayOut from '@/components/PayInPayOut.vue'
import LocationSelector from '@/components/LocationSelector.vue'
import RealTimeReport from '@/components/RealTimeReport.vue'
import CouriersReport from '@/components/couriersReport/CouriersReport'
import HardwareSelector from '@/components/HardwareSelector.vue'
import TabsSearchEngine from '@/components/tabs/TabsSearchEngine.vue'
import Reservations from '@/components/Reservations.vue'
import DeliveryCheckout from '@/components/checkout/DeliveryCheckout.vue'
import MenuAvailability from '@/components/MenuAvailability.vue'
import FreeTrialExpired from '@/components/FreeTrialExpired.vue'
import GracePeriodExpired from '@/components/GracePeriodExpired.vue'
import PlanSelector from '@/components/PlanSelector.vue'
import DemoPos from '@/components/DemoPos.vue'
const routes = [
  {
    path: '/',
    redirect: { name: 'employees' }
  },
  {
    name: 'pos',
    path: '/pos',
    props: true,
    component: Home
  },
  {
    name: 'demoPos',
    path: '/demo-pos',
    component: DemoPos
  },
  {
    name: 'orderManagement',
    path: '/pos/order-management/:tabId',
    component: OrderManagement
  },
  {
    name: 'checkout',
    path: '/pos/checkout/:tabId',
    component: Checkout
  },
  {
    name: 'deliveryCheckout',
    path: '/pos/checkout/delivery/:tabId',
    component: DeliveryCheckout
  },
  {
    name: 'login',
    path: '/pos/login',
    component: Login
  },
  {
    name: 'supportLogin',
    path: '/support-login',
    component: SupportLogin
  },
  {
    name: 'signUp',
    path: '/pos/signUp',
    component: SignUp
  },
  {
    name: 'employees',
    path: '/employees',
    component: EmployeeSelector
  },
  {
    name: 'paymentRequest',
    path: '/payment-request',
    component: PaymentRequest
  },
  {
    name: 'startShift',
    path: '/start-shift',
    component: StartShift
  },
  {
    name: 'locations',
    path: '/locations',
    component: LocationSelector
  },
  {
    name: 'realTimeReport',
    path: '/real-time-report',
    component: RealTimeReport
  },
  {
    name: 'couriersReport',
    path: '/couriers-report',
    component: CouriersReport
  },
  {
    name: 'tabsSearchEngine',
    path: '/tabs-search-engine',
    component: TabsSearchEngine
  },
  {
    name: 'endShift',
    path: '/end-shift',
    component: EndShift
  },
  {
    name: 'payInPayOut',
    path: '/pay-in-pay-out',
    component: PayInPayOut
  },
  {
    name: 'reservations',
    path: '/reservations',
    component: Reservations
  },
  {
    name: 'products',
    path: '/products',
    component: MenuAvailability
  },
  {
    name: 'extraDeviceWarning',
    path: '/extra-device',
    component: ExtraDeviceWarning
  },
  {
    name: 'planSelector',
    path: '/change-plan',
    component: PlanSelector
  },
  {
    name: 'hardwareSelector',
    path: '/hardware-selector',
    component: HardwareSelector
  },
  {
    name: 'freeTrialExpired',
    path: '/free-trial-expired',
    component: FreeTrialExpired
  },
  {
    name: 'gracePeriodExpired',
    path: '/grace-period-expired',
    component: GracePeriodExpired
  }
]

export default routes
