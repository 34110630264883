var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('top-bar',{staticClass:"sticky top-0 z-10"},[_c('template',{slot:"center"},[_c('div',{staticClass:"flex items-center text-xl uppercase"},[_vm._v(" "+_vm._s(_vm.$t('navbar.tabs'))+" ")])]),(_vm.floorplanIds.length > 0 && this.config.lastProductPosEnabled)?_c('template',{slot:"right"},[_c('icon',{attrs:{"name":"floorplan"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.goToHome.apply(null, arguments)}}})],1):_vm._e()],2),_c('div',[_c('div',{staticClass:"flex flex-row cursor-pointer mt-2"},[_c('div',{staticClass:"flex w-1/2 justify-center text-center items-center text-gray-400 p-3",class:{
          'text-blue border-b-4 border-blue': !_vm.pickupType
        },on:{"click":function($event){return _vm.setPickupType(null)}}},[_vm._v(" "+_vm._s(_vm.$t('tabs.restaurant'))+" ")]),_c('div',{staticClass:"flex w-1/2 justify-center text-center items-center text-gray-400 p-3",class:{
          'text-blue border-b-4 border-blue': _vm.pickupType === 'takeAway'
        },on:{"click":function($event){return _vm.setPickupType('takeAway')}}},[_vm._v(" "+_vm._s(_vm.$t('tabs.take-away'))+" ")])]),_c('tab-list',{attrs:{"tabs":_vm.filteredTabs},on:{"update:selectedTabId":_vm.handleSelect}}),_c('div',{staticClass:"h-20"})],1),(
      _vm.pickupType === 'takeAway' &&
      this.config.lastProductPosEnabled &&
      _vm.hasCatalog
    )?_c('floating-button',{attrs:{"primary-label":_vm.$t('new-tab.new-tab')},on:{"primary":_vm.goToNewTab}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }