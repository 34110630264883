<template>
  <swipe-out class="w-full active:bg-gray-300" :disabled="isDisabled">
    <template v-slot>
      <div
        :class="{ 'opacity-50': sentTime || someProductBilled }"
        class="flex items-center"
      >
        <div @click="editingQuantity = true">
          <quantity-selector
            class="py-3 pl-2 pr-4"
            v-click-outside="() => (editingQuantity = false)"
            v-if="editingQuantity && !disabledQuantitySelector"
            :quantity="product.quantity"
            @update:quantity="updateQuantity"
          />
          <div v-else class="px-6 py-3 text-red product-quantity">
            {{ product.quantity }}
          </div>
        </div>
        <div class="flex flex-row w-full">
          <div class="w-full py-3" @click="select">
            <div class="text-blue product-name">
              {{ product.name }}
            </div>
            <div class="text-gray-400 modifiers">
              {{ description }}
            </div>
            <div class="text-gray-400 comments" v-if="product.comments">
              + {{ product.comments }}
            </div>
            <div class="sent-time text-gray-400" v-if="sentTime">
              {{ $t('ordering.sent') }} {{ sentTime | time }} ({{
                product.course
              }})
            </div>
            <div
              class="text-sm text-green"
              v-if="product.discountType === '2x1'"
            >
              2x1
              <span v-if="product.quantity > 1"
                >({{ product.discountAmount }})</span
              >
            </div>
          </div>
        </div>
        <div>
          <div
            class="flex flex-row items-center content-end self-center p-3 m-0 text-center text-blue"
            :class="{ 'text-gray-400 text-labeled': product.discountType }"
          >
            {{ (product.fullPrice * product.quantity) | currency }}
            <icon
              v-if="paymentIcon"
              :name="paymentIcon"
              class="p-1 ml-1"
              :class="iconColor"
            />
          </div>
          <div
            v-if="product.discountType"
            class="text-blue p-0 text-discounted"
          >
            <div v-if="product.discountType !== '2x1'">
              {{ (product.finalPrice * product.quantity) | currency }}
            </div>
            <div v-else>
              {{
                (product.fullPrice *
                  (product.quantity - product.discountAmount))
                  | currency
              }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template slot="right">
      <div
        class="flex items-center w-full h-20 h-full px-6 swiper-slide bg-red text-white"
        @click="deleteProduct"
      >
        <icon name="trash" />
      </div>
    </template>
  </swipe-out>
</template>

<script>
import { SwipeOut } from 'vue-swipe-actions'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'
import Icon from '@last/core-ui/components/Icon.vue'
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import productsMixin from '@/components/productsMixin'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'TabProduct',
  mixins: [productsMixin],
  props: {
    product: {
      type: Object,
      default: null
    },
    disabledByParent: {
      type: Boolean,
      default: false
    },
    isDelivery: {
      type: Boolean,
      default: false
    },
    disabledQuantitySelector: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editingQuantity: false
    }
  },
  methods: {
    ...mapGetters('auth', ['currentEmployee', 'anyEmployeeIsManager']),
    ...mapActions('tabs', ['updateProductQuantity']),
    updateQuantity(quantity) {
      this.updateProductQuantity({
        productId: this.product.id,
        quantity
      })
    },
    deleteProduct() {
      this.deleteProductWithConfirmation()
    },
    select() {
      if (!this.productsAreBilled) {
        this.$emit('selected')
      }
    }
  },
  computed: {
    ...mapGetters('tabs', ['getSentToKitchenTime']),
    ...mapState('tabs', ['tabs']),
    isDisabled() {
      return this.disabledByParent || this.productsAreBilled
    },
    productsArePaid() {
      const productsPendingToBePayed =
        this.product.discountType === '2x1'
          ? this.product.notPaidQuantity - this.product.discountAmount
          : this.product.notPaidQuantity
      const isA2x1Product =
        this.product.discountType === '2x1' &&
        this.product.quantity === this.product.discountAmount
      return productsPendingToBePayed === 0 && !isA2x1Product
    },
    productsAreBilled() {
      const productsPendingToBeBilled =
        this.product.discountType === '2x1'
          ? this.product.notBilledQuantity - this.product.discountAmount
          : this.product.notBilledQuantity
      const isA2x1Product =
        this.product.discountType === '2x1' &&
        this.product.quantity === this.product.discountAmount
      return (
        productsPendingToBeBilled === 0 && !isA2x1Product && !this.isDelivery
      )
    },
    paymentIcon() {
      if (this.productsArePaid) return 'done'
      if (this.someProductBilled) return 'pending-payment'
      return ''
    },
    iconColor() {
      if (this.productsArePaid) return 'text-green'
      if (this.someProductBilled) return 'text-yellow'
      return ''
    },
    someProductBilled() {
      return this.product.notBilledQuantity != this.product.quantity
    },
    sentTime() {
      return this.getSentToKitchenTime(this.product.id)
    }
  },
  components: {
    SwipeOut,
    Icon,
    QuantitySelector
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.left-100 {
  left: 100%;
}

.paid {
  font-size: 14px;
  color: #21becb;
}

.billed {
  font-size: 14px;
  color: #fdc000;
}

.sortable-chosen:active {
  background: white;
  box-shadow: 2px 4px 6px -1px rgba(0, 0, 0, 0.1),
    2px 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.text-discounted {
  font-size: 16px;
}

.text-labeled {
  font-size: 12px;
  text-decoration: line-through;
}
</style>
