<template>
  <div class="h-screen flex flex-col overflow-hidden">
    <top-bar show-back>
      <template slot="center">
        <div class="text-xl flex items-center">
          {{ $t('hardware-selector.title') }}
        </div>
      </template>
    </top-bar>
    <div
      class="overflow-y-scroll scrolling-touch bg-gray-200 w-full h-full flex flex-col justify-center items-center"
    >
      <div
        class="bg-white rounded-big shadow flex flex-col overflow-visible p-20 h-75 w-2/3"
      >
        <div class="text-blue text-2xl uppercase font-bold">
          {{ $t('hardware-selector.hardware') }}
        </div>
        <div class="flex mt-8">
          <div class="w-1/2 text-gray-400">
            {{ $t('hardware-selector.class') }}
          </div>
          <div class="w-1/2 ml-10 text-gray-400">
            {{ $t('hardware-selector.model') }}
          </div>
        </div>
        <div class="separator bg-gray-200 mt-2" />
        <div class="flex flex-col justify-evenly h-full">
          <div class="flex mt-5">
            <div class="w-1/2 text-lg weight-400 text-blue">
              {{ $t('hardware-selector.till') }}
            </div>
            <div class="w-1/2 ml-10">
              <l-select
                :options="tillsOptions"
                v-model="selectedTill"
                :disabled="
                  (tillsOptions.length <= 1 && !!selectedTill) || !shiftsEnabled
                "
              />
            </div>
          </div>
          <div class="flex mt-5">
            <div class="w-1/2 text-lg text-blue">
              {{ $t('hardware-selector.cash-machine') }}
            </div>
            <div class="w-1/2 ml-10">
              <l-select
                :options="cashMachinesOptions"
                v-model="selectedCashMachine"
                :disabled="
                  cashMachinesOptions.length <= 1 && !!selectedCashMachine
                "
              />
            </div>
          </div>
          <div class="flex mt-5">
            <div class="w-1/2 text-lg text-blue">
              {{ $t('hardware-selector.dataphone') }}
            </div>
            <div class="w-1/2 ml-10">
              <l-select
                :options="dataphonesOptions"
                v-model="selectedDataphone"
                :disabled="dataphonesOptions.length <= 1 && !!selectedDataphone"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/core/TopBar.vue'
import LSelect from '@last/core-ui/components/LSelect.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'HardwareSelector',
  methods: {
    ...mapActions('config', ['updateHardwarePreferences']),
    ...mapActions('till', ['setTill'])
  },
  computed: {
    ...mapGetters('till', ['selectedCashTillIsStarted']),
    ...mapGetters('config', ['tills']),
    ...mapState('till', ['selectedCashTill', 'shiftsEnabled']),
    ...mapState('config', ['device', 'config']),
    cashMachinesOptions() {
      let cashMachines = this.config.paymentMethodsConfig.filter(
        paymentMethod => {
          return paymentMethod.configs
        }
      )
      return cashMachines.reduce((acc, cashMachine) => {
        for (const config of cashMachine.configs) {
          acc.push({
            value: config.id,
            label: `${cashMachine.name} - ${config.name}`
          })
        }
        return acc
      }, [])
    },
    tillsOptions() {
      return this.tills.cash.map(cashTill => {
        return {
          value: cashTill.id,
          label: cashTill.name
        }
      })
    },
    dataphonesOptions() {
      return this.config.dataphoneConfigs.map(dataphone => {
        return {
          value: dataphone.id,
          label: dataphone.name
        }
      })
    },
    selectedTill: {
      get() {
        return this.selectedCashTill?.id
      },
      set(newValue) {
        let selectedTill = this.tills.cash.find(till => till.id === newValue)
        this.setTill(selectedTill)
        if (this.shiftsEnabled && !this.selectedCashTillIsStarted) {
          this.$router.push({ name: 'startShift' })
        }
        this.updateHardwarePreferences({
          preferredTill: newValue
        })
      }
    },
    selectedCashMachine: {
      get() {
        return this.device.preferredCashMachine
      },
      set(newValue) {
        this.updateHardwarePreferences({
          preferredCashMachine: newValue
        })
      }
    },
    selectedDataphone: {
      get() {
        return this.device.preferredDataphone
      },
      set(newValue) {
        this.updateHardwarePreferences({
          preferredDataphone: newValue
        })
      }
    }
  },
  components: {
    TopBar,
    LSelect
  }
}
</script>

<style scoped>
.h-75 {
  height: 75%;
}

.separator {
  width: 100%;
  margin-bottom: 1rem;
  height: 1px;
}
</style>
