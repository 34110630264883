<template>
  <div class="flex flex-col h-screen overflow-hidden">
    <top-bar :show-back="true" @back="goBack">
      <template slot="center">
        <div id="tab-name" class="flex">
          {{ tab.tableName }}
          <div
            class="p-1 ml-3 rounded-full bg-blue"
            @click="closeTabId = tabId"
            v-if="tab.open"
          >
            <icon name="trash" class="p-1" />
          </div>
        </div>
      </template>
    </top-bar>
    <div class="flex flex-1 h-full overflow-hidden">
      <div class="z-10 flex flex-col shadow">
        <checkout-tab
          :selected-product-ids="selectedProductsIds"
          @toggleProduct="toggleProduct"
          class="flex-1 checkout-tab"
          :tab-id="tabId"
        />
        <terrace-surcharge
          v-if="tableHasSurcharge"
          :tab="tab"
          @toggle="value => onTerraceSurchargeToggled(value)"
        />
        <balance
          :terrace-surcharge-percentage="terraceSurchargePercentage"
          :bills="bills"
          :pending-products="pendingProducts"
          class="z-10"
        />
      </div>
      <div class="flex justify-center w-full bg-gray-200">
        <div class="pt-4 mr-6">
          <div class="flex items-center justify-between current-bill-title">
            <div class="flex items-center pb-4">
              <div class="text-xl font-bold uppercase font-title text-blue">
                {{ $t('checkout.total') }}:
              </div>
              <div class="ml-4 text-gray-400" v-if="selectedBill">
                {{ selectedBill.total | currency }}
              </div>
            </div>
          </div>
          <charge-calculator
            @charge="charge"
            @close="closeBill"
            :bill-id="selectedBillId"
            :pending-amount="(selectedBill && selectedBill.pending) || 0"
          />
          <div class="flex mt-2 buttons-width">
            <div
              v-if="selectedBill"
              class="flex justify-center flex-1 p-4 mr-2 font-bold text-white uppercase bg-blue rounded-small"
              @click="printBill"
            >
              <icon name="ticket" class="p-1 mr-2" />
              {{ $t('checkout.print') }}
            </div>
            <bill-discount
              class="flex-1"
              :discount.sync="discount"
              :disabled="isExisting(selectedBillId)"
              :original-total="selectedBill && selectedBill.originalTotal"
              :tab-id="tabId"
              @remove-discount="removeDiscount"
              theme="dark"
            />
          </div>
        </div>
        <bills-list
          class="mt-6"
          :current-bill-id="currentBill && currentBill.id"
          :bills="bills"
          @billSelected="billId => (selectedBillId = billId)"
          :selected-bill-id="selectedBillId"
          @invalidate="invalidateAndRegenerate"
          @cancelInvoice="cancelInvoice"
          @generateInvoice="id => (generateInvoiceBillId = id)"
          :tab-id="tabId"
        />
      </div>
    </div>
    <close-tab :tab-id="closeTabId" @cancel="closeTabId = null" />
    <generate-invoice
      :tab-id="tabId"
      :bill-id="generateInvoiceBillId"
      v-if="generateInvoiceBillId"
      @newBillId="id => (selectedBillId = id)"
      @close="generateInvoiceBillId = null"
    />
  </div>
</template>

<script>
import ChargeCalculator from './ChargeCalculator.vue'
import TicketPrinter from '@/ticketPrinter.js'
import Printer from '@/printer.js'
import Bills from '@last/core/src/billsGenerator'
import BillsList from './BillsList.vue'
import Balance from './Balance.vue'
import CheckoutTab from './CheckoutTab.vue'
import TopBar from '@/components/core/TopBar.vue'
import CloseTab from './CloseTab.vue'
import BillDiscount from './BillDiscount.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import GenerateInvoice from './GenerateInvoice.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import TerraceSurcharge from './TerraceSurcharge.vue'
import CheckoutMixin from '@/components/checkout/checkoutMixin'

export default {
  name: 'Checkout',
  mixins: [CheckoutMixin],
  data() {
    return {
      selectedBillId: null,
      selectedProductsIds: [],
      closeTabId: null,
      currentDiscount: {},
      generateInvoiceBillId: null
    }
  },
  mounted() {
    if (!this.isTabOpen(this.tabId)) {
      this.$router.push({ name: 'pos' })
    }
    if (this.bills && this.bills.length > 0) {
      this.selectedBillId = this.bills[0].id
    }
    if (this.tabGlobalPromotion) {
      this.currentDiscount = {
        type: this.tabGlobalPromotion.discountType,
        amount: this.tabGlobalPromotion.discountAmount,
        promotionId: this.tabGlobalPromotion.promotionId,
        concept: this.tabGlobalPromotion.concept
      }
    }
  },
  methods: {
    ...mapActions('tabs', [
      'addBill',
      'removeBill',
      'addPayment',
      'regenerateBill',
      'updateTerraceSurchargePercentage'
    ]),
    ...mapActions('promotions', ['updateTabPromotion', 'deleteTabPromotion']),
    goBack() {
      if (this.$route.params.referrer === 'orderManagement') {
        this.$router.replace({
          name: 'orderManagement',
          params: { tabId: this.tabId }
        })
      } else {
        this.$router.go(-1)
      }
    },
    printBill() {
      this.addBill({ tabId: this.tabId, bill: this.selectedBill })
      TicketPrinter.printBill(this.selectedBill)
      this.selectedProductsIds = []
      this.currentDiscount = {}
    },
    invalidateAndRegenerate(billId) {
      this.$ldialog({
        title: this.$t('checkout.invalidate-bill'),
        content: this.$t('checkout.invalidate-bill-content'),
        mainLabel: this.$t('ctas.yes'),
        defaultLabels: true,
        onConfirm: () => {
          this.removeBill({ tabId: this.tabId, billId })
          if (this.currentBill) {
            this.selectedBillId = this.currentBill.id
          } else if (this.bills && this.bills.length > 0) {
            this.selectedBillId = this.bills[0].id
          }
        }
      })
    },
    paymentSucceeded(
      amount,
      change = null,
      paymentMethod,
      paymentId,
      metadata
    ) {
      this.addBill({ tabId: this.tabId, bill: this.selectedBill })
      this.addPayment({
        billId: this.selectedBillId,
        change: paymentMethod.type === 'cash' && change,
        tip: paymentMethod.type !== 'cash' && change,
        paymentId,
        amount,
        type: paymentMethod.type,
        metadata,
        tabId: this.tabId,
      })
      if (
        this.selectedBill.pending <= 0 &&
        (((this.tab.tables?.length || 0) === 0 &&
          this.config.organizationConfig.takeawayAutoprint) ||
          this.config.organizationConfig.restaurantAutoprint)
      ) {
        TicketPrinter.printBill(this.selectedBill)
      }
      this.selectedProductsIds = []
      this.currentDiscount = {}
      if (this.selectedBill.pending === 0 && this.currentBill) {
        this.selectedBillId = this.currentBill.id
      }
    },
    closeBill() {
      if (!this.selectedBill || this.isExisting(this.selectedBill.id)) {
        return
      }
      this.addBill({ tabId: this.tabId, bill: this.selectedBill })
      this.selectedProductsIds = []
      this.currentDiscount = {}
      if (this.selectedBill.pending === 0 && this.currentBill) {
        this.selectedBillId = this.currentBill.id
      }
    },
    openCashDrawer() {
      Printer.openCashDrawer()
    },
    isExisting(billId) {
      return this.getBills(this.tabId)
        .map(bill => bill.id)
        .includes(billId)
    },
    toggleProduct(productId) {
      if (
        this.selectedProductsIds
          .map(id => id.uniqueId)
          .includes(productId.uniqueId)
      ) {
        this.selectedProductsIds = this.selectedProductsIds.filter(
          id => id.uniqueId != productId.uniqueId
        )
      } else {
        this.selectedProductsIds = [...this.selectedProductsIds, productId]
      }
      this.selectedBillId = this.currentBill.id
    },
    async cancelInvoice(billId) {
      this.selectedBillId = await this.regenerateBill({
        tabId: this.tabId,
        billId
      })
    },
    onTerraceSurchargeToggled(newValue) {
      this.updateTerraceSurchargePercentage({
        tabId: this.tabId,
        enabled: newValue
      })
    }
  },
  computed: {
    ...mapGetters('tabs', [
      'isTabOpen',
      'getBills',
      'getAllProducts',
      'hasSentToKitchenProducts'
    ]),
    ...mapState('tables', ['tables']),
    ...mapState('tabs', ['tabs']),
    ...mapState('config', ['config']),
    ...mapGetters('billing', ['getCurrentBill']),
    ...mapGetters('promotions', ['getTabGlobalPromotion']),
    tabGlobalPromotion() {
      return this.getTabGlobalPromotion(this.tabId)
    },
    bills() {
      let existingBills = this.getBills(this.tabId)
      if (this.checkoutComplete) {
        return existingBills
      } else {
        let currentBillWithPaymentInfo = Bills.addPaymentInfo(
          this.currentBill,
          []
        )
        return [currentBillWithPaymentInfo, ...existingBills]
      }
    },
    currentBill() {
      if (this.checkoutComplete) return null
      return this.getCurrentBill({
        tabId: this.tabId,
        currentDiscount: this.currentDiscount,
        selectedProductIds: this.selectedProductsIds
      })
    },
    checkoutComplete() {
      let tabProducts = this.getAllProducts(this.tabId)
      return (
        tabProducts
          .map(product => product.notBilledQuantity)
          .reduce((total, quantity) => total + quantity, 0) === 0
      )
    },
    checkoutCompleteWithPayments() {
      let tabProducts = this.getAllProducts(this.tabId)
      let allProductsBilled =
        tabProducts
          .map(product => product.notBilledQuantity)
          .reduce((total, quantity) => total + quantity, 0) === 0
      let pendingPayments = this.bills
        .map(bill => bill.pending)
        .reduce((sum, pending) => sum + pending, 0)
      return allProductsBilled && pendingPayments === 0
    },
    tabId() {
      return this.$route.params.tabId
    },
    selectedBill() {
      return this.bills.find(bill => bill.id === this.selectedBillId)
    },
    bill() {
      return this.selectedBill
    },
    discount: {
      get() {
        if (this.isExisting(this.selectedBillId)) {
          return this.selectedBill.discount || {}
        }
        return this.currentDiscount
      },
      set(discount) {
        this.updateTabPromotion({
          tabId: this.tabId,
          promotion: {
            discountType: discount.type,
            discountAmount: discount.amount,
            name: discount.name,
            id: discount.promotionId,
            global: discount.global
          }
        })
        this.currentDiscount = discount
      }
    },
    pendingProducts() {
      if (this.selectedProductsIds.length === 0) {
        return []
      } else {
        let tabProducts = this.getAllProducts(this.tabId)
        let currentBillProducts = this.selectedProductsIds
          .map(id => id.id)
          .reduce((products, id) => {
            products[id] = products[id] || 0
            products[id] += 1
            return products
          }, {})
        return tabProducts.map(product => {
          let discount2x1 = 0
          if (product.discountType === '2x1') {
            discount2x1 = product.discountAmount
          }
          return {
            ...product,
            quantity:
              product.notBilledQuantity -
              (currentBillProducts[product.id] || 0) -
              discount2x1
          }
        })
      }
    },
    existingBills() {
      return this.getBills(this.tabId)
    },
    tab() {
      return this.tabs[this.tabId]
    },
    tableHasSurcharge() {
      let table = this.tables[this.tabs[this.tabId].tables[0]]
      return table && table.terraceSurchargePercentage > 0
    },
    terraceSurchargePercentage() {
      return this.tabs[this.tabId].terraceSurchargePercentage
    }
  },
  watch: {
    currentBill(bill) {
      if (!this.isExisting(this.selectedBillId)) {
        this.selectedBillId = bill ? bill.id : null
      }
    },
    existingBills() {
      this.selectedProductsIds = []
    },
    checkoutCompleteWithPayments(complete) {
      let tabProducts = this.getAllProducts(this.tabId)
      if (complete && tabProducts.length > 0) {
        this.closeTabId = this.tabId
      }
    }
  },
  components: {
    ChargeCalculator,
    TopBar,
    Balance,
    BillsList,
    CheckoutTab,
    CloseTab,
    BillDiscount,
    Icon,
    GenerateInvoice,
    TerraceSurcharge
  }
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/variables.scss';
@import '@/mixins.scss';

i {
  color: rgba(white, 0.5);
}

.tab-details {
  width: percentage(math.div(1, 3));
  background: $background;
  border-left: 0.062rem solid $separator;
  flex-direction: column;
  display: flex;
}

.checkout-tab {
  flex: 1;
}

#bills-container {
  background: $background;
  height: 100%;
  width: percentage(math.div(2, 3));
  display: flex;
  flex-direction: column;
}

#bill-preview {
  background: white;
  width: 36ch;
}

.invalidate-button {
  background: #d32f2f;
  color: white;
}

#close-button {
  margin: 1.25rem;
}

.current-bill-container {
  flex: 1;
  display: flex;
  overflow: scroll;
}

.current-bill {
  flex: 2;
  padding: 0.938rem;
}

.payments {
  flex: 1;
}

h2 {
  padding: 0.625rem;
  font-size: 1.25rem;
}

.current-bill-title {
  display: flex;
  align-items: center;
}

.print,
.drawer {
  @include card;
  display: inline-block;
  margin: 0.625rem;
  padding: 0.625rem 1.25rem;
}

#tab-name {
  font-size: 1.438rem;
}

.buttons-width {
  width: 22.75rem;
}

.p-topbar {
  padding-bottom: 3.938rem;
}
</style>
