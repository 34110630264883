<template>
  <div>
    <div class="number-button text-green" @click="send">c</div>
    <div class="number-button text-green" @click="send">/ 2</div>
    <div class="number-button text-green" @click="send">/ 3</div>
    <div class="number-button divide text-green" @click="send">/</div>
    <div
      v-for="n in numbers"
      :key="n"
      :class="'number-' + n"
      class="number-button"
      @click="send"
    >
      {{ n }}
    </div>
    <div class="number-button double-cero" @click="send">00</div>
    <div class="number-button multiply text-green" @click="send">*</div>
    <div class="number-button add text-green" @click="send">+</div>
    <div class="number-button subtract text-green" @click="send">-</div>
    <div class="number-button equals text-green" @click="send">=</div>
    <div class="number-button del text-red" @click="send">DEL</div>
  </div>
</template>

<script>
export default {
  name: 'Numbers',
  data() {
    return {
      numbers: [7, 8, 9, 4, 5, 6, 1, 2, 3, 0]
    }
  },
  methods: {
    send(e) {
      this.$emit('send', e.target.textContent.trim())
    }
  }
}
</script>

<style scoped>
.calculator {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-row-gap: 0.062rem;
  grid-column-gap: 0.062rem;
  height: 19rem;
  width: 22.75rem;
  max-width: 100%;
}

.number-button {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multiply {
  grid-row-start: 2;
  grid-column-start: 4;
}

.subtract {
  grid-row-start: 3;
  grid-column-start: 4;
}

.add {
  grid-row-start: 4;
  grid-column-start: 4;
}

.double-cero {
  grid-row-start: 5;
  grid-column-start: 1;
}
</style>
