<template>
  <div
    class="w-6 h-6 rounded-full border-red p-1 border-2"
    @click.stop="$emit('input', !value)"
  >
    <div class="rounded-full bg-red h-full w-full" v-if="value"></div>
  </div>
</template>

<script>
export default {
  name: 'LRadio',
  props: {
    value: Boolean
  }
}
</script>
