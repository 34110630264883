import i18n from '@/i18n.js'
import {
  InfoItem,
  Section,
  Label,
  Separator,
  EmptySeparator,
  Total,
  TaxesBreakdown
} from './components.js'

function payments(report) {
  let sortedPayments = Object.keys(report.payments)
    .map(method => ({
      method,
      amount: report.payments[method]
    }))
    .sort((a, b) => b.amount - a.amount)
  let paymentsArray = []
  for (let i = 0; i < sortedPayments.length; ++i) {
    paymentsArray = [
      ...paymentsArray,
      new InfoItem(
        sortedPayments[i].method.charAt(0).toUpperCase() +
          sortedPayments[i].method.slice(1),
        sortedPayments[i].amount,
        {
          valueType: 'currency'
        }
      )
    ]
  }
  let totalTips = Object.values(report.tips).reduce(
    (total, amount) => (total += amount),
    0
  )
  let result = [
    new Section(i18n.t('report.billing')),
    ...paymentsArray,
    new EmptySeparator(),
    new InfoItem('*' + i18n.t('report.tips'), totalTips, {
      valueType: 'currency'
    })
  ]
  return result
}

function breakdown(reportBreakdown) {
  let taxRates =
    Object.keys(reportBreakdown.taxRates).length > 0
      ? Object.values(reportBreakdown.taxRates)
      : [
          {
            taxPercentage: reportBreakdown.taxPercentage,
            taxableBase: reportBreakdown.taxableBase,
            tax: reportBreakdown.tax,
            total: reportBreakdown.total
          }
        ]
  return [
    new TaxesBreakdown(taxRates, reportBreakdown.taxLabel),
    new Total(reportBreakdown.total)
  ]
}

function generateRealTimeReport(report, config) {
  return [
    new Label(i18n.t('report.real-time-report'), {
      style: 'bold 24px',
      lineHeight: 30
    }),
    new InfoItem(i18n.t('report.date'), report.startDate, {
      valueType: 'dateWithoutTime',
      style: '16px'
    }),
    new EmptySeparator(),
    new Label(config.company.name, { style: 'bold 24px', lineHeight: 30 }),
    new Label(config.company.taxId, { style: '16px', lineHeight: 30 }),
    new Label(config.company.address, { style: '16px', lineHeight: 30 }),
    new Section(i18n.t('report.general')),
    new InfoItem(i18n.t('report.total-tabs'), report.stats.totalTabs),
    new InfoItem(i18n.t('report.discounts'), -report.breakdown.discounts, {
      valueType: 'currency'
    }),
    new Section(i18n.t('report.till-control')),
    new InfoItem(i18n.t('report.pay-in'), report.totalIns, {
      valueType: 'currency'
    }),
    new InfoItem(i18n.t('report.pay-out'), -report.totalOuts, {
      valueType: 'currency'
    }),
    ...payments(report),
    new Separator(),
    new InfoItem(i18n.t('report.taxable-base'), report.breakdown.taxableBase, {
      valueType: 'currency',
      light: true
    }),
    new EmptySeparator(),
    ...breakdown(report.breakdown)
  ]
}

export default generateRealTimeReport
