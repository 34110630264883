<template>
  <div
    class="bg-blue-800 text-center text-white h-screen flex flex-col items-center pt-20 px-4"
  >
    <icon
      name="close"
      class="text-white cursor-pointer absolute right-0 top-0 mr-5 mt-5"
      @click="logout"
    />
    <div v-if="forbidden" class="flex flex-1 flex-col items-center card-width">
      <img src="@/assets/dizzy-robot.svg" class="w-40 h-40 mb-4" />
      <div class="uppercase font-title text-2xl font-bold">
        {{ $t('plans.forbidden-title') }}
      </div>
      <div class="mt-3">{{ $t('plans.forbidden-text') }}</div>
    </div>
    <div v-else-if="showSuccessMessage" class="flex flex-1 flex-col card-width">
      <div class="h-64">
        <lottie-animation path="lottie-success.json" />
      </div>
      <div class="font-title uppercase font-bold text-2xl">
        {{ $t('plans.success') }}
      </div>
      <div class="mt-3">{{ $t('plans.contact-soon') }}</div>
    </div>
    <div
      v-else
      class="flex flex-col text-center items-center card-width flex-1"
    >
      <div class="font-title uppercase text-2xl font-bold">
        {{ $t('plans.limit-title') }}
      </div>
      <div>
        <p class="mt-2">
          {{ $t('plans.limit-text-1') }}
        </p>
        <p class="mt-2">
          {{ $t('plans.limit-text-2') }}
        </p>
      </div>
      <div
        class="border-blue bg-blue-700 rounded-small border-2 w-full cursor-pointer transition-all flex flex-col mt-4 py-4"
      >
        <div class="text-center">
          <div class="text-2xl font-bold pb-1">
            {{ plan.name }}
          </div>
          <div class="text-xs px-6">
            {{ $t(`plans.${plan.key}-description`) }}
          </div>
          <div v-if="plan.price" class="py-10">
            <div class="text-3xl text-red font-bold font-title mb-1">
              {{ plan.price | currency }}
            </div>
            <div class="text-xs">
              <span class="text-red uppercase font-bold mb-2">
                {{ $t('plans.plus-vat') }}
              </span>
              <span>/</span>
              <span class="uppercase">
                {{ $t('plans.price-per-location') }}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div v-if="plan.benefits">
            <div
              v-for="benefit in plan.benefits"
              :key="benefit.key"
              class="flex px-6 pb-3 text-xs items-center"
            >
              <icon name="check" class="text-red mr-4" />
              <div>
                <span class="pr-1">
                  {{ $t(`plans.${benefit.key}`) }}
                </span>
                <span
                  v-if="benefit.limit || benefit.price"
                  class="text-gray-400"
                >
                  <span v-if="benefit.limit">
                    (<span
                      v-html="
                        $t(`plans.${benefit.limit}`, {
                          limit: benefit.limitValue
                        })
                      "
                    />)
                  </span>
                  <span v-if="benefit.price" class="font-bold">
                    ({{ benefit.price | currency }})</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div v-else class="text-center px-6">
            <div class="text-xl font-bold my-10 uppercase">
              {{ $t('plans.need-superior-plan') }}
            </div>
            <div class="text-sm">{{ $t('plans.contact-us') }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-1"></div>
      <l-button
        class="text-white bg-red border-red shadow rounded-lg w-full hover:bg-red mt-5"
        @click="askForUpdatePlanConfirmation"
      >
        {{ $t('plans.change-plan') }}
      </l-button>
      <div class="text-red py-4 cursor-pointer" @click="logout">
        {{ $t('plans.same-plan') }}
      </div>
    </div>
    <img class="w-48 my-4" src="@/assets/logo-color.png" />
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import { map } from '@last/core/src/plansManager.js'
import LButton from '@last/core-ui/components/LButton.vue'
import { mapState, mapActions } from 'vuex'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import api from '@/api.js'

export default {
  name: 'PlanSelector',
  data() {
    return { showSuccessMessage: false, plansInfo: {} }
  },
  async mounted() {
    this.plansInfo = (await api.get('/plans')).data
  },
  methods: {
    ...mapActions('auth', ['logout']),
    askForUpdatePlanConfirmation() {
      this.$ldialog({
        title: this.$t('plans.upgrade-plan-confirmation'),
        icon: 'star',
        defaultLabels: true,
        onConfirm: async () => {
          try {
            await this.updatePlan()
            if (this.plan.key === 'business') {
              this.showSuccessMessage = true
            } else {
              this.$router.push({
                name: 'extraDeviceWarning',
                params: { locationId: this.$route.params.locationId }
              })
            }
          } catch (e) {
            this.$lnotification.create({
              title: this.$t('plans.update-failed'),
              icon: 'close',
              iconColor: 'red-b'
            })
          }
        }
      })
    },
    async updatePlan() {
      await api.post('/plan', {
        plan: this.$route.params.plan
      })
      this.$lnotification.create({
        title: this.$t('plans.plan-updated'),
        iconColor: 'green'
      })
    }
  },
  computed: {
    ...mapState('auth', ['isLocationManager']),
    allPlans() {
      const plans = map(this.plansInfo)
      if (!this.plansInfo['business']) {
        return [
          ...plans,
          {
            key: 'business',
            name: 'Business'
          }
        ]
      }
      return plans
    },
    plan() {
      return this.allPlans[this.selectedPlanIndex] ?? {}
    },
    selectedPlanIndex() {
      const plan = this.$route.params.plan ?? 'essential'
      return this.allPlans.findIndex(p => p.key == plan)
    },
    forbidden() {
      const permission = this.$route.params.permission ?? true
      return permission != true
    }
  },
  components: {
    Icon,
    LButton,
    LottieAnimation
  }
}
</script>

<style>
.card-width {
  max-width: 416px;
}
</style>
