<template>
  <div>
    <transition name="fade">
      <div
        v-if="open"
        class="z-20 transition blur-background absolute top-0 left-0 w-screen h-screen flex justify-end overflow-hidden"
        @click="close"
      >
        <transition name="move">
          <div
            @click.stop
            class="menu bg-white transition menu-background menu-w h-full"
          >
            <div class="relative text-white flex flex-col px-6 h-full">
              <div
                @click="close"
                class="absolute top-0 right-0 p-6 cursor-pointer"
              >
                <icon name="close" class="text-white" />
              </div>
              <div class="flex justify-between items-center mt-20 w-full">
                <div>{{ $t('notifications.notifications') }}</div>
                <div
                  class="text-red cursor-pointer underline text-sm"
                  :class="{
                    'opacity-50 pointer-events-none': !notifications.length > 0
                  }"
                  @click="clearAll"
                >
                  {{ $t('notifications.clear-all') }}
                </div>
              </div>
              <notification-list
                v-if="notifications.length > 0"
                class="my-4"
                :notifications="notifications"
                allow-block-delete
                @deleteNotification="index => deleteNotification(index)"
                @progress-canceled="index => progressCancelled(index)"
              >
              </notification-list>
              <div
                v-else
                class="flex flex-col items-center py-10 text-gray-400"
              >
                <icon name="bell-slash" big />
                <div class="mt-4 text-lg">
                  {{ $t('notifications.empty-notifications') }}
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import NotificationList from './NotificationList.vue'
import Icon from '@last/core-ui/components/Icon.vue'
export default {
  name: 'NotificationFixedList',
  components: {
    NotificationList,
    Icon
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    open: false
  }),
  mounted() {
    this.open = true
  },
  methods: {
    close() {
      this.open = false
      setTimeout(() => {
        this.$emit('close')
        this.$destroy()
        this.$el.parentNode.removeChild(this.$el)
      }, 500)
    },
    deleteNotification(index) {
      this.$emit('deleteNotification', index)
    },
    progressCanceled(index) {
      this.$emit('progressCancelled', index)
    },
    clearAll() {
      this.$emit('clear')
    }
  },
  computed: {}
}
</script>

<style scoped>
.blur-background {
  background: rgba(24, 24, 37, 0.8);
  backdrop-filter: blur(60px);
}
.menu-background {
  background: rgba(44, 44, 69, 0.9);
  backdrop-filter: blur(40px);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter > .menu,
.fade-leave-to > .menu {
  transform: translateX(20rem);
}

.transition {
  transition: all 0.3s;
}

.menu-w {
  width: 30rem;
}
.move-enter-active,
.move-leave-active {
  transition: transform 0.5s;
}
.move-enter,
.move-leave-to {
  transform: translateX(100%);
}
</style>
