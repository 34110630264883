import Vue from 'vue'
import sync from '@/sync/service.js'
import api from '@/api.js'
import { EventBus } from '@/eventBus.js'
import store from '@/store/index'
import uuid4 from 'uuid/v4'
import PromotionApplier from '@last/core/src/PromotionApplier'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import { notification } from '@last/core-ui/plugins/notification'
import i18n from '@/i18n.js'

EventBus.$on('tabProductsUpdated', tabId =>
  store.dispatch('promotions/recalculatePromotionsInProducts', tabId)
)

const state = {
  promotions: [],
  tabPromotions: {},
  customerPromotions: {}
}

const getters = {
  getTabGlobalPromotion: state => tabId => {
    return (
      state.tabPromotions[tabId]?.find(promotion => promotion.global) ||
      undefined
    )
  },
  getPromotions: (state, _getters, rootState) => {
    if (!rootState.config.config.organizationConfig.promotions) return []
    return state.promotions.filter(
      promotion =>
        promotion.enabled &&
        (!promotion.maxRedemptions || promotion.remainingRedemptions > 0)
    )
  },
  getCustomerPromotions: (state, _getters) => customerId => {
    return (state.customerPromotions[customerId] || []).filter(
      promotion =>
        promotion.enabled &&
        (!promotion.maxRedemptions || promotion.remainingRedemptions > 0)
    )
  },
  getPromotionsWithPoints: (_state, getters) =>
    getters.getPromotions.filter(promotion => promotion.pointsExpense),

  getPromotionsWithoutPoints: (_state, getters) =>
    getters.getPromotions.filter(promotion => !promotion.pointsExpense),

  usedPointsInTab: (state, _, rootState, rootGetters) => tabId => {
    let products = rootGetters['tabs/getAllProducts'](tabId)
    let bills = rootGetters['tabs/getBills'](tabId)
    let tabPromotions = state.tabPromotions[tabId]
    let productPoints = products.reduce(
      (sum, product) => sum + product.pointsExpense * product.quantity,
      0
    )
    let billPoints = bills.reduce(
      (sum, bill) =>
        sum + ((bill.discount && bill.discount.pointsExpense) || 0),
      0
    )
    let promotionPoints = (tabPromotions || []).reduce(
      (sum, promotion) => sum + (promotion.pointsExpense || 0),
      0
    )

    return productPoints + billPoints + promotionPoints
  }
}

const actions = {
  async refreshPromotions({ commit }, promotions) {
    if (!promotions) {
      promotions = (await api.get('/promotions')).data
    }
    commit('updatePromotions', promotions)
  },
  async refreshTabPromotions({ commit }, tabPromotions) {
    commit('updateTabPromotions', tabPromotions)
  },
  async updateCustomerPromotions(
    { commit },
    { customerId, customerPromotions }
  ) {
    commit('updateCustomerPromotions', { customerId, customerPromotions })
  },
  async deleteTabPromotion({ dispatch }, { tabPromotion, tabId }) {
    sync.record('promotionDeletedFromTab', {
      tabPromotionId: tabPromotion.id,
      tabId
    })
    dispatch('recalculatePromotionsInProducts', tabId)
  },
  async updateTabPromotion({ state, dispatch }, { promotion, tabId }) {
    const isDiscountManager = await Vue.prototype.$confirm('DISCOUNT_MANAGER')
    if (!isDiscountManager) {
      notification.create({
        title: i18n.t('employees.not-allowed'),
        icon: 'close',
        iconColor: 'red'
      })
      return
    }
    const tabPromotion = (state.tabPromotions[tabId] || []).find(
      tabPromotion => tabPromotion.promotionId === promotion.id
    )
    if (tabPromotion) {
      sync.record('promotionDeletedFromTab', {
        tabPromotionId: tabPromotion.id,
        tabId
      })
    } else {
      const newTabPromotion = {
        id: uuid4(),
        tabId,
        promotionId: promotion.id,
        name: promotion.name,
        description: promotion.description,
        pointsExpense: promotion.pointsExpense,
        discountType: promotion.discountType,
        discountAmount: promotion.discountAmount,
        allowRepeat: promotion.allowRepeat,
        global: promotion.global,
        freeDelivery: promotion.freeDelivery
      }
      sync.record('promotionAddedToTab', { tabPromotion: newTabPromotion })
    }
    dispatch('recalculatePromotionsInProducts', tabId)
  },
  recalculatePromotionsInProducts(
    { state, rootGetters, dispatch, rootState },
    tabId
  ) {
    let customerId = rootGetters['tabs/getCustomerId'](tabId)
    const promotions = [
      ...state.promotions,
      ...(state.customerPromotions[customerId] || [])
    ].reduce((acc, promotion) => {
      acc[promotion.id] = promotion
      return acc
    }, {})

    const availablePromotions = state.tabPromotions[tabId]
      ? state.tabPromotions[tabId].map(tabPromotion => ({
          ...tabPromotion,
          products: promotions[tabPromotion.promotionId]
            ? promotions[tabPromotion.promotionId].products
            : [],
          categories: promotions[tabPromotion.promotionId]
            ? promotions[tabPromotion.promotionId].categories
            : []
        }))
      : []

    const productsToBeUpdated = new PromotionApplier(
      availablePromotions,
      rootState.catalog.products
    ).updateDiscountInProducts(
      rootGetters['tabs/getAllProducts'](tabId).filter(
        product => product.notBilledQuantity > 0
      )
    )

    productsToBeUpdated.forEach(product => {
      let productPromotion = state.promotions.find(
        promotion => promotion.id === product.promotionId
      )
      let productPromotionName = productPromotion ? productPromotion.name : null
      dispatch(
        'tabs/updateProductDiscount',
        {
          productId: product.id,
          discount: {
            discountType: product.discountType,
            discountAmount: product.discountAmount,
            discountConcept: product.discountConcept || productPromotionName,
            promotionId: product.promotionId
          },
          productPricing:
            ProductPriceCalculator.calculateProductPricing(product)
        },
        { root: true }
      )
    })
  }
}

const mutations = {
  updatePromotions(state, promotions) {
    state.promotions = promotions
  },
  updateTabPromotions(state, tabPromotions) {
    state.tabPromotions = tabPromotions
  },
  updateCustomerPromotions(state, { customerId, customerPromotions }) {
    state.customerPromotions[customerId] = customerPromotions
  },
  deletePromotionFromTab(state, { tabPromotionId, tabId }) {
    state.tabPromotions[tabId] = state.tabPromotions[tabId].filter(
      internalTabPromotion => internalTabPromotion.id !== tabPromotionId
    )
  },
  addPromotionToTab(state, { tabPromotion }) {
    if (!state.tabPromotions[tabPromotion.tabId]) {
      Vue.set(state.tabPromotions, tabPromotion.tabId, [])
    }
    state.tabPromotions[tabPromotion.tabId] = [
      ...state.tabPromotions[tabPromotion.tabId],
      tabPromotion
    ]
  },
  redeemPromotion(state, { promotionId }) {
    const promotionIndex = state.promotions.findIndex(
      promotion => promotion.id === promotionId
    )
    if (promotionIndex != -1) {
      const promotion = state.promotions[promotionIndex]
      if (promotion.maxRedemptions) {
        promotion.remainingRedemptions--
      }
      Vue.set(state.promotions, promotionIndex, promotion)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
