<template>
  <l-modal
    :title="$t('tabs.select-courier')"
    size="small"
    class="py-20 font-body"
    @close="$emit('close')"
  >
    <template slot="body">
      <div class="overflow-y-scroll scrolling-touch modal-height">
        <div
          v-for="(courier, index) in couriers"
          :key="courier.id"
          class="border px-3 py-4 border-gray-300 rounded-lg my-3"
          :class="{
            'bg-green border-green-b text-white': selectedIndex === index,
            'text-blue': selectedIndex !== index
          }"
          @click="selectedIndex = index"
        >
          <div class="flex flex-row justify-between mx-5">
            <div>{{ courier.name }}</div>
            <div
              v-if="courierHasOrdersOnDelivery(courier)"
              :class="{
                'text-white': selectedIndex === index,
                'text-red': selectedIndex !== index
              }"
            >
              {{ $t('tabs.on-delivery') }}
            </div>
            <div
              v-else
              :class="{
                'text-white': selectedIndex === index,
                'text-green': selectedIndex !== index
              }"
            >
              {{ $t('tabs.free') }}
            </div>
          </div>
        </div>
      </div>
      <l-modal-ctas>
        <l-modal-button
          type="secondary"
          :label="$t('tabs.cancel')"
          @click.native="$emit('close')"
          class="flex-1 mr-4"
        />
        <l-modal-button
          type="main"
          :label="$t('tabs.assign')"
          @click.native="assignCourier"
          class="flex-1"
          :disabled="selectedIndex === null"
        />
      </l-modal-ctas>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal'
import LModalCtas from '@last/core-ui/components/LModalCtas'
import LModalButton from '@last/core-ui/components/LModalButton'
import { mapState } from 'vuex'

export default {
  name: 'CourierSelector',
  data() {
    return {
      selectedIndex: null
    }
  },
  props: {
    openTabs: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    courierHasOrdersOnDelivery(courier) {
      return this.openTabs.some(
        tab =>
          tab.deliveryOrder &&
          tab.deliveryOrder.status === 'ON_DELIVERY' &&
          tab.deliveryOrder.courierId === courier.id
      )
    },
    assignCourier() {
      if (this.selectedIndex !== null) {
        this.$emit('assignCourier', this.couriers[this.selectedIndex])
      }
    }
  },
  computed: {
    ...mapState('couriers', ['couriers'])
  },
  components: { LModal, LModalCtas, LModalButton }
}
</script>

<style scoped>
.modal-height {
  height: 400px;
}
</style>
