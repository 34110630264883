function calculateModifiersImpact(modifiers) {
  if (!modifiers) return 0
  return modifiers
    .map(modifier => modifier.priceImpact * (modifier.quantity || 1))
    .reduce((total, impact) => total + impact, 0)
}

function calculateProductPricing(product) {
  let fullPrice = product.price
  if (product.modifiers && Object.keys(product.modifiers).length > 0) {
    let modifiersImpact = calculateModifiersImpact(product.modifiers)
    fullPrice = product.price + modifiersImpact
  } else if (
    product.comboProducts &&
    Object.keys(product.comboProducts).length > 0
  ) {
    let productsImpact = product.comboProducts
      .map(
        comboProduct =>
          (comboProduct.priceImpact +
            calculateModifiersImpact(comboProduct.modifiers)) *
          (comboProduct.quantity || 1)
      )
      .reduce((total, impact) => total + impact, 0)

    fullPrice = product.price + productsImpact
  }
  let finalPrice = fullPrice
  if (product.discountType) {
    if (product.discountType === 'percentage') {
      finalPrice = Math.round(fullPrice * (1 - product.discountAmount / 100))
    } else if (['cash', 'currency'].includes(product.discountType)) {
      finalPrice = fullPrice - product.discountAmount
    }
  }
  return {
    fullPrice,
    finalPrice
  }
}

export default {
  calculateProductPricing
}
