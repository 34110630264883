<template>
  <l-modal class="sm:px-40 modal" @close="$emit('close')">
    <template slot="body">
      <div class="card-height flex flex-col overflow-hidden">
        <div class="text-blue text-xl font-title uppercase font-bold">
          {{ $t('tab-selector.multiple-tabs-table') }}
        </div>
        <div class="text-gray-400 text-sm">
          {{ $t('tab-selector.select-tab') }}
        </div>
        <div class="overflow-scroll flex-1 mt-5">
          <div
            v-for="tab in tabsToSelect"
            :key="tab.id"
            class="px-5 py-3 rounded-lg border border-gray-200 mt-5"
            @click="$emit('tabSelected', tab.id)"
          >
            <div class="flex flex-row text-blue font-title uppercase">
              <div class="mr-1 font-bold">
                {{ tab.activationTime | time }} -
              </div>
              <div>
                {{ tab.name || tab.tableName }}
              </div>
            </div>
            <div
              class="flex flex-row text-gray-400 justify-between items-baseline"
            >
              <div class="flex flex-row">
                <div class="mr-1">{{ tab.code }} -</div>
                <div>
                  {{
                    $t('tab-selector.products', {
                      productsQuantity: productsQuantity[tab.id]
                    })
                  }}
                </div>
              </div>
              <div class="text-blue text-xl font-bold font-title">
                {{ totals[tab.id] | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </l-modal>
</template>

<script>
import LModal from '@last/core-ui/components/LModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TabSelector',
  components: {
    LModal
  },
  data() {
    return {}
  },
  props: {
    tabIdsToSelect: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapGetters('tabs', [
      'getTab',
      'getAllProductsByTabId',
      'getTotalsByTabId'
    ]),
    tabsToSelect() {
      return this.tabIdsToSelect.map(tabId => this.getTab(tabId))
    },
    totals() {
      return this.getTotalsByTabId
    },
    products() {
      return this.getAllProductsByTabId
    },
    productsQuantity() {
      const products = this.products
      return this.tabIdsToSelect.reduce((res, tabId) => {
        res[tabId] = products[tabId].reduce((quantity, product) => {
          return quantity + product.quantity
        }, 0)
        return res
      }, {})
    }
  }
}
</script>

<style scoped>
.card-height {
  height: 500px;
}
.modal {
  @apply pt-16;
}
</style>
