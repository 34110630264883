<template>
  <div
    v-if="product"
    class="flex flex-col justify-center overflow-hidden h-screen"
  >
    <div class="pt-10 px-5 overflow-hidden flex flex-col flex-1" @click.stop>
      <div
        class="flex items-center text-xl text-blue uppercase justify-center relative"
      >
        <div
          class="font-bold text-blue uppercase font-title text-center w-full"
        >
          {{ product.name }}
        </div>
        <div class="absolute top-0 right-0 pr-5 pt-1">
          <icon
            name="close"
            class="text-gray-400"
            @click.native="handleClose()"
          />
        </div>
      </div>
      <l-select
        class="mt-5"
        :placeholder="$t('tabs.courses')"
        :options="allCourses"
        v-model="course"
        option-label="label"
        option-value="value"
      />

      <div
        class="flex flex-row overflow-x-scroll scrolling-touch items-center w-full pt-5 flex-shrink-0"
      >
        <div
          v-for="(modGroup, index) in tabs"
          :key="modGroup.id"
          class="border rounded-lg border-gray-300 min-w-1-3 px-2 py-3 flex-shrink-0 min-w-min text-center mr-3 whitespace-no-wrap"
          :class="{
            'bg-blue text-white border-blue':
              modGroup.tabType === tabs[selectedModifierGroupIndex].tabType,
            'text-blue':
              modGroup.tabType !== tabs[selectedModifierGroupIndex].tabType
          }"
          @click="selectedModifierGroupIndex = index"
        >
          {{ modGroup.name }}
        </div>
      </div>
      <div
        class="flex-1 overflow-hidden"
        v-if="modifierGroup.id === tabs[selectedModifierGroupIndex].tabType"
      >
        <modifier-group
          :key="modifierGroup.name"
          :modifier-group="modifierGroup"
          :modifiers.sync="selectedModifiers[indexOfSelectedModifier]"
          @change="tryGotoNext"
        />
      </div>
      <div
        class="flex flex-col overflow-hidden"
        v-if="tabs[selectedModifierGroupIndex].tabType === TAB_OPEN_ATTRIBUTE"
      >
        <div>
          <div class="flex items-center">
            <l-field class="w-8/12 mr-2" :label="$t('ordering.name')">
              <l-input
                class="px-2 bg-white"
                :placeholder="$t('ordering.name')"
                v-model="openModifier.name"
              />
            </l-field>
            <l-field class="mr-2 w-4/12" :label="$t('ordering.price')">
              <l-input
                class="px-2 bg-white"
                :placeholder="$t('ordering.price')"
                v-model="openModifier.priceImpact"
                :icon="`currencies/${currencyIcon}`"
                is-currency
              ></l-input>
            </l-field>
          </div>
          <div
            @click="saveOpenModifier"
            class="flex items-center px-10 justify-center py-2 w-1/2 text-lg text-white uppercase bg-blue-600 border border-blue-700 rounded-small"
          >
            {{ $t('ordering.create') }}
          </div>
        </div>
        <div class="flex-1 scrolling-touch overflow-y-scroll mt-5">
          <div
            class="flex items-center justify-between p-4 mx-2 rounded-small shadow mb-3"
            @click="deleteOpenModifier(modifier.id)"
            v-for="modifier in openModifiers"
            :key="modifier.id"
          >
            <div class="flex items-center">
              <l-checkbox
                :value="true"
                @input="deleteOpenModifier(modifier.id)"
              />
              <div class="ml-4">{{ modifier.name }}</div>
            </div>
            <div class="flex items-center">
              <div
                class="pl-4 text-sm text-red"
                v-if="modifier.priceImpact > 0"
              >
                (+{{ modifier.priceImpact | currency }})
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="tabs[selectedModifierGroupIndex].tabType === TAB_COMMENT">
        <div class="pt-3 pb-4 text-xl font-bold uppercase text-blue font-title">
          {{ $t('ordering.comments') }}
        </div>
        <textarea
          class="w-full p-6 border border-blue rounded-small h-64 sm:h-auto"
          :placeholder="$t('ordering.comments')"
          maxlength="255"
          v-model="comments"
        />
      </template>
    </div>
    <div class="pb-24 px-8 pt-5">
      <div class="flex justify-between w-full bg-white">
        <quantity-selector
          v-if="showQuantity"
          class="text-base"
          :quantity="quantity"
          @update:quantity="updateQuantity"
        />
        <div class="text-xl font-bold text-red uppercase text-blue-light">
          {{ (product.price || product.priceImpact) | currency }}
        </div>
      </div>
      <floating-button
        @primary="saveProduct()"
        :primary-label="this.$t('ordering.confirm')"
        :primary-disabled="saveDisabled"
      />
    </div>
  </div>
</template>

<script>
import LCheckbox from '@last/core-ui/components/LCheckbox.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import LInput from '@last/core-ui/components/LInput'
import LField from '@last/core-ui/components/LField'
import ModifierGroup from '@/components/ordering/ModifierGroup.vue'
import QuantitySelector from '@/components/ordering/QuantitySelector.vue'
import FloatingButton from '@last/core-ui/components/FloatingButton.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import LSelect from '@last/core-ui/components/LSelect.vue'
import ProductPriceCalculator from '@last/core/src/productPriceCalculator.js'
import productEditorMixin from '@/components/ordering/productEditorMixin.js'

const TAB_COMMENT = 'TAB_COMMENT'
const TAB_OPEN_ATTRIBUTE = 'TAB_OPEN_ATTRIBUTE'

export default {
  name: 'ProductEditor',
  mixins: [productEditorMixin],
  components: {
    ModifierGroup,
    QuantitySelector,
    FloatingButton,
    Icon,
    LInput,
    LCheckbox,
    LField,
    LSelect
  },
  props: {
    product: {
      type: Object,
      default: null
    },
    showQuantity: {
      type: Boolean,
      default: false
    },
    showCourse: {
      type: Boolean,
      default: true
    },
    selectedSeat: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selectedModifiers: [],
      openModifiers: [],
      showingOpenModifier: false,
      openModifier: {
        name: null,
        priceImpact: 0
      },
      quantity: 1,
      comments: '',
      priceError: false,
      nameError: false,
      selectedModifierGroup: {},
      course: null,
      TAB_COMMENT: TAB_COMMENT,
      TAB_OPEN_ATTRIBUTE: TAB_OPEN_ATTRIBUTE,
      selectedModifierGroupIndex: 0,
      saveDisabled: false
    }
  },
  computed: {
    ...mapState('catalog', ['catalogs']),
    ...mapGetters('config', ['currencyIcon']),
    tabId() {
      return this.$route.params.tabId
    },
    catalogProductId() {
      return this.$route.params.catalogProductId
    },
    modifierGroup() {
      return (
        this.product?.modifierGroups.find(
          modifierGroup =>
            modifierGroup.id ===
            this.tabs[this.selectedModifierGroupIndex].tabType
        ) || {}
      )
    }
  },
  mounted() {
    if (!this.product) {
      this.$router.replace({
        name: 'orderManagement',
        params: { tabId: this.tabId }
      })
      return
    }
    this.updateSelectedModifiers()
    this.setTab()
  },
  methods: {
    ...mapActions('tabs', [
      'updateProductQuantity',
      'addProduct',
      'updateProductModifiers',
      'moveProduct',
      'updateCourse'
    ]),
    handleClose() {
      if (this.$listeners && this.$listeners.close) {
        this.$emit('close')
      } else {
        this.$router.go(-1)
      }
    },
    setTab() {
      this.selectedModifierGroupIndex = 0
    },
    saveProduct() {
      if (this.saveDisabled) return
      this.saveDisabled = true
      let productToSave = {
        ...this.product,
        quantity: this.quantity,
        modifiers: this.allSelectedModifiers,
        comments: this.comments
      }
      if (this.$listeners && this.$listeners.saveProduct) {
        this.$emit('saveProduct', productToSave)
        return
      } else {
        let productPricing =
          ProductPriceCalculator.calculateProductPricing(productToSave)
        if (this.product.id) {
          this.updateProductModifiers({
            productId: productToSave.id,
            modifiers: productToSave.modifiers,
            comments: productToSave.comments,
            productPricing
          })
          if (this.course) {
            this.updateCourse({
              tabId: this.tabId,
              productId: this.product.id,
              course: this.course
            })
          }
        } else {
          this.addProduct({
            tabId: this.tabId,
            seat: this.selectedSeat,
            product: {
              ...productToSave,
              ...productPricing
            }
          })
        }
        this.$router.go(-1)
      }
    },
    tryGotoNext([selected, max]) {
      if (max && selected === max) {
        if (this.selectedModifierGroupIndex < this.tabs.length) {
          this.selectedModifierGroupIndex += 1
        }
      }
    }
  },
  watch: {
    product(newProduct, oldProduct) {
      if (newProduct.id != oldProduct?.id) {
        this.updateSelectedModifiers()
        this.setTab()
      }
    }
  }
}
</script>

<style scoped>
.min-w-1-3 {
  min-width: 33%;
}
</style>
